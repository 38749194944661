import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import React from "react";
import SPADialogue from "../../../common/spa-dialogue/index.jsx";
import { newRoomFormData } from "./new-room-form-data.js";
import useNewRoom from "./useNewRoom";

export default function NewRoomForm({
  getAllRooms,
  isNewRoomOpen,
  handleClose,
  singleRoomId,
  currentPage,
}) {
  const {
    control,
    methods,
    handleSubmit,
    onSubmit,
    handleCloseForm,
    singleRoomLoader,
    createRoomLoader,
    updateRoomLoader,
    servicesDataLoader,
    servicesData,
    isServices,
  } = useNewRoom(handleClose, singleRoomId, getAllRooms, currentPage);

  return (
    <SPADialogue
      open={isNewRoomOpen}
      handleClose={handleCloseForm}
      dialogTitle={singleRoomId ? "Edit Zone" : `Create Zone`}
      cancelText="Cancel"
      submitText={singleRoomId ? "Save Changes" : "Create Zone"}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      methods={methods}
      loading={createRoomLoader || updateRoomLoader}
    >
      {(singleRoomLoader || servicesDataLoader) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={singleRoomLoader || servicesDataLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          width: "688px",
          display: "flex",
          flexDirection: "column",
          gap: "0px",
        }}
      >
        <Grid container spacing={1}>
          {newRoomFormData(
            singleRoomId,
            isServices,
            servicesData?.result?.data?.data
          ).map((item, index) => {
            return (
              <Grid item lg={item.gridLength} xs={12} key={index}>
                <item.component {...item.otherOptions} control={control} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </SPADialogue>
  );
}
