import {
  Avatar,
  Badge,
  Box,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import UserIcon from "../../assets/svg/user-icon";
import { customColors, fs12, fs16, fw600 } from "../../theme";

/**
 * AvatarUserDetails component.
 * Renders user details with an avatar, full name, and role.
 * Conditionally displayed based on sidebar responsiveness.
 *
 * @returns {JSX.Element} JSX code for rendering the AvatarUserDetails component.
 */

const AvatarUserDetails = () => {
  const isSidebarResponsive = useMediaQuery("(max-width:1165px)");
  return (
    <Box
      sx={{
        display: isSidebarResponsive ? "flex" : "none",
        alignItems: "center",
        gap: { sm: "8px", xs: "5px" },
      }}
    >
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
      >
        <Avatar
          alt="User-img"
          sx={{
            height: "30px",
            width: "30px",
          }}
          src=""
          variant="rounded"
        >
          <UserIcon />
        </Avatar>
      </StyledBadge>
      <Box>
        <Typography
          sx={{
            color: customColors.darkGrey,
            fontSize: fs16,
            fontWeight: fw600,
          }}
        >
          Martha Smith
        </Typography>
        <Typography
          sx={{
            color: customColors.lightGrey,
            fontSize: fs12,
            textTransform: "capitalize",
          }}
        >
          Marthasmith@Email.Com
        </Typography>
      </Box>
    </Box>
  );
};

export default AvatarUserDetails;

// Example Usage
// <AvatarUserDetails/>

/**
 * StyledBadge component.
 * Defines the styles for a badge with a ripple effect.
 */
export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: customColors.success,
    color: customColors.success,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));
