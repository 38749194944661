import { baseAPI } from "../baseApi";

export const ClientsApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllClients: builder.mutation({
      query: (payload) => {
        return {
          url: `/user`,
          method: "PATCH",
          body: payload,
        };
      },
      providesTags: ["Users"],
    }),

    getSingleClient: builder.query({
      query: (clientId) => ({ url: "/user/" + clientId }),
      providesTags: ["singleUser"],
    }),

    updateSingleClient: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `/user/${id}`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["singleUser", "Users"],
    }),
    createClient: builder.mutation({
      query: (bodyData) => ({
        url: "/user",
        method: "POST",
        body: bodyData,
      }),
      invalidatesTags: ["Users"],
    }),
    createAdditionNoteById: builder.mutation({
      query: ({ id, payload }) => ({
        url: `user/add-note/${id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["singleUser", "Users"],
    }),
    updateNoteById: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `note/${id}`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["Users", "singleUser"],
    }),

    updateAddressesById: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `user/update-address/${id}`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["Users", "singleUser"],
    }),

    //global Serach
    getClientAppointment: builder.query({
      query: (search) => {
        return { url: "user/search-client/appointment", params: { search } };
      },

      refetchOnMountOrArgChange: true,
      providesTags: ["UsersAppointment"],
    }),
    updateContraindicationById: builder.mutation({
      query: ({ id, payload }) => ({
        url: `user/update-contraindication/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["singleUser", "Users"],
    }),
    deleteContraindicationById: builder.mutation({
      query: ({ id }) => ({
        url: `user/delete-contraindication/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["singleUser", "Users"],
    }),
    sendClientEmail: builder.mutation({
      query: ({ id, payload }) => ({
        url: `user/send-email/${id}`,
        method: "POST",
        body: payload,
      }),
      invalidateTags: ["singleUser", "ClientEmailSMSHistory", "History"],
    }),
    sendClientSMS: builder.mutation({
      query: ({ id, payload }) => ({
        url: `user/send-sms/${id}`,
        method: "POST",
        body: payload,
      }),
      invalidateTags: ["singleUser", "ClientEmailSMSHistory", "History"],
    }),
    getClientEmailSMSHistory: builder.query({
      query: (clientId) => ({ url: "/user/email-history/" + clientId }),
      providesTags: ["UsersEmailSMSHistory"],
    }),
  }),
});

export const {
  useGetAllClientsMutation,
  useGetSingleClientQuery,
  useUpdateSingleClientMutation,
  useCreateClientMutation,
  useCreateAdditionNoteByIdMutation,
  useUpdateNoteByIdMutation,
  useGetClientAppointmentQuery,
  useUpdateContraindicationByIdMutation,
  useUpdateAddressesByIdMutation,
  useSendClientEmailMutation,
  useSendClientSMSMutation,
  useGetClientEmailSMSHistoryQuery,
  useDeleteContraindicationByIdMutation,
} = ClientsApi;
