import { Navigate } from "react-router-dom";
import CommunicationsSection from "../components/communication";
import { ROLE } from "../enums/roles";
import { AuthGuard } from "../guards/auth-gaurd";
import DashboardLayout from "../layout/dashboard-layout";
import AllComponent from "../pages/all-component";
import ForgotPassword from "../pages/auth/forgot-password";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import ResetPassword from "../pages/auth/reset-password";
import ResetPasswordEmail from "../pages/auth/reset-password-email";
import Calendar from "../pages/calendar";
import Clients from "../pages/clients";
import ResourceManagement from "../pages/resource-management";
import Settings from "../pages/settings";
import { getLocalStorageItem } from "../utils/localStorage";
import Sales from "../pages/sales";
import { PERMISSION } from "../enums/permissions";

const UnProtectedRoutes = ({ Component }) => {
  const token = getLocalStorageItem("auth-token");
  return !token ? <Component /> : <Navigate to="/clients" />;
};

/**
 * Array of routes for the dashboard section.
 * Each route object contains information about the path and corresponding component.
 */
export const routes = [
  // {
  //   path: '/',
  //   element: <AuthLayout />,
  //   children: [
  {
    path: "components",
    element: <AllComponent />,
  },
  // {
  //   path: "/",
  //   element: <Login />,
  // },
  {
    path: "login",
    element: <UnProtectedRoutes Component={Login} />,
  },

  {
    path: "register",
    element: <UnProtectedRoutes Component={Register} />,
  },
  {
    path: "forgot-password",
    element: <UnProtectedRoutes Component={ForgotPassword} />,
  },
  {
    path: "reset-password-email",
    element: <UnProtectedRoutes Component={ResetPasswordEmail} />,
  },
  {
    path: "reset-password",
    element: <UnProtectedRoutes Component={ResetPassword} />,
  },
  {
    path: "unauthorized",
    element: <div>unauthorized</div>,
  },
  {
    path: "*",
    element: <div>page not found</div>,
  },
  //   ],
  // },
  {
    path: "/",
    element: (
      // <AuthGuard allowedRoles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.STAFF]}>
        <DashboardLayout />
      // </AuthGuard>
    ),
    children: [
      {
        path: "clients",

        element: (
          <AuthGuard
            allowedRoles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.STAFF]}
            allowedPermissions="VIEW_CLIENT"
          >
            <Clients />
          </AuthGuard>
        ),
      },
      {
        path: "calendar",
        element: (
          <AuthGuard
            allowedRoles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.STAFF]}
            allowedPermissions={PERMISSION.VIEW_CALENDAR}
          >
            <Calendar />
          </AuthGuard>
        ),
      },
      {
        path: "resource-management",
        element: (
          <AuthGuard
            allowedRoles={[ROLE.SUPER_ADMIN, ROLE.STAFF]}
            allowedPermissions={PERMISSION.SPA_MANAGEMENT}
          >
            <ResourceManagement />
          </AuthGuard>
        ),
      },
      {
        path: "sales",
        element: (
          <AuthGuard
            allowedRoles={[ROLE.SUPER_ADMIN, ROLE.STAFF]}
            allowedPermissions={PERMISSION.VIEW_SALES}
          >
            <Sales />
          </AuthGuard>
        ),
      },
      {
        path: "communications",
        element: (
          <AuthGuard
            allowedRoles={[ROLE.SUPER_ADMIN, ROLE.STAFF]}
            allowedPermissions={PERMISSION.VIEW_COMMUNICATION}
          >
            <CommunicationsSection />
          </AuthGuard>
        ),
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
];
