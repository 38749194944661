import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { validationProfileSchema } from "../../validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetSingleClientQuery,
  useGetAllClientsMutation,
  useUpdateSingleClientMutation,
} from "../../../../../../services/clients";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  calculateTimeDifferenceWithCurrentTime,
  convertDate,
  convertTo12HourFormat,
} from "../../../../../../utils/convertTimeIOSFormat";
import SpaAppointmentCard from "../../../../../common/spa-appointment-card";
import SPAContraindatCard from "../../../../../common/spa-contraditcation-card";
import AdditionalNote from "../../../../../addition-note-card";
import { handleFilterSearch } from "../../../../../../utils/searchFilter";
import { setImageUrl } from "../../../../../../store/features/upload-file";
import { lightenHexColor } from "../../../../../../utils/convertLightenHexColor";

export default function useClientOverview() {
  const clientDetail = useSelector((state) => state.clientDetail.clientDetail);
  const imageUrl = useSelector((state) => state?.uploadFile?.url);

  //form methods for profile
  const methods = useForm({ resolver: yupResolver(validationProfileSchema) });
  const { handleSubmit, control, setValue, watch } = methods;
  const { referralSource } = watch();

  //api working
  const { data: singleClient, isLoading: clientLoading } =
    useGetSingleClientQuery(clientDetail._id);
  const [getAllClients, { data: allClients }] = useGetAllClientsMutation();
  const [updateSingleClient] = useUpdateSingleClientMutation();

  const dispatch = useDispatch();

  // useStates

  const [singleContraindication, setSingleContraindication] = useState([]);
  const [pinnedNote, setPinnedNote] = useState({});
  const [status, setStatus] = useState("ADMIN");
  const [friendReferralArray, setFriendReferralArray] = useState([]);
  const [clientDetailApi, setClientDetailsApi] = useState(null);
  const [searchFriend, setSearchFriend] = useState("");
  const [friendFieldEnable, setFriendFieldEnable] = useState(false);
  const [singleAppointment, setSingleAppointment] = useState([]);
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    let payLoad = {
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth:
        values.dateOfBirth === null
          ? undefined
          : convertDate(values.dateOfBirth),
      occupation: values.occupation,
      company: values.company,
      roleType: "CLIENT",
      gender: values.gender === "" ? undefined : values.gender,
      email: values.email,
      mobileNumber: values.mobileNumber,
      telephone: values.telephone,
      referralSource:
        values.referralSource === "" ? undefined : values.referralSource,
      postalCode: clientDetailApi.postalCode,
      friend: values.friend,
      profilePictureUrl: imageUrl ? imageUrl : "",
    };
    if (status !== clientDetailApi.clientStatus) {
      payLoad = { ...payLoad, clientStatus: status };
    }

    await updateSingleClient({
      id: clientDetail._id,
      payload: payLoad,
    })
      .unwrap()
      .then((response) => {
        if (response.status) {
          toast.success(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.data.error);
      });

    getAllClients({ roleType: "CLIENT" });
  };

  const handleInitialValues = () => {
    if (!singleClient) return;

    const { data } = singleClient.result || {};
    if (!data) return;
    const { upcomingAppointments, user } = data;
    const { notes, clientStatus } = user;
    setClientDetailsApi(user);

    Object.entries(user).forEach(([key, value]) => {
      setValue(key, value);
      if (key === "profilePictureUrl" && !value.includes("example")) {
        dispatch(setImageUrl({ url: value }));
      } else if (key === "profilePictureUrl" && value.includes("example"))
        dispatch(setImageUrl({ url: "" }));
    });

    if (clientStatus) {
      setStatus(clientStatus);
    }

    if (user.contraindication) {
      let contraindicationArray = user.contraindication;

      if (contraindicationArray?.length > 0) {
        const currentContraindication = contraindicationArray.find(
          (item) => item.isLatest
        );
        if (currentContraindication) {
          setSingleContraindication([
            {
              component: SPAContraindatCard,
              otherOptions: {
                isLatest: "Current",
                updated: calculateTimeDifferenceWithCurrentTime(
                  currentContraindication.createdAt
                ),
                text: currentContraindication.text,
                id: currentContraindication._id,
              },
            },
          ]);
        }
      }
    }

    if (notes?.length > 0) {
      const pinnedNote = notes.find((item) => item.isPinned);
      if (pinnedNote) {
        const addedDate = calculateTimeDifferenceWithCurrentTime(
          pinnedNote.createdAt
        );

        setPinnedNote({
          component: AdditionalNote,
          otherOptions: {
            content: pinnedNote.note,
            id: pinnedNote._id,
            isPinned: pinnedNote.isPinned,
            added: addedDate,
          },
        });
      }
    }

    if (upcomingAppointments?.length > 0) {
      upcomingAppointments.map((appointment, index) => {
        const inputDate = new Date(appointment.date);
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        const formattedDate = inputDate.toLocaleDateString("en-US", options);
        const isCurrentContradication =
          user.contraindication.length > 0 &&
          user.contraindication[user.contraindication.length - 1]?.text
            ? true
            : false;
        if (index === 0) {
          setSingleAppointment([
            {
              title: formattedDate,
              children: [
                {
                  otherOptions: {
                    color:
                      appointment.services[0] &&
                      appointment.services[0].category?.color,
                    backgroundColor: lightenHexColor(
                      appointment.services[0] &&
                        appointment.services[0].category?.color,
                      0.9
                    ),
                    locationName: "Sydney",
                    author:
                      appointment.user === null
                        ? "No Staff"
                        : `${appointment.user.firstName} ${appointment.user.lastName}`,
                    time: convertTo12HourFormat(appointment.startTime),
                    appointmentId: appointment?._id,
                    condition: {
                      isDepositPaid: appointment.isPaidFull,
                      isInvoicePaid: false,
                      isBookedOnline: false,
                      isContraindication: isCurrentContradication,
                      isNotes: appointment.notes.length > 0 ? true : false,
                      isRecurringAppointment: appointment.isRecurring,
                      isMaintenance: false,
                      isNewClient:
                        appointment.client.clientStatus === "NEW"
                          ? true
                          : false,
                      isClientAttended:
                        appointment?.status === "COMPLETED" ? true : false,
                      isClientNoShow:
                        appointment?.status === "OPEN" ? true : false,
                      isAppointmentCancelled:
                        appointment?.status === "CANCELLED" ? true : false,
                    },
                  },
                  component: SpaAppointmentCard,
                },
              ],
            },
          ]);
        }
      });
    }
  };

  const onChange = (event) => {
    setSearchFriend(event.target.value);
  };

  useMemo(() => {
    if (searchFriend && allClients) {
      const friendFilterArray = allClients?.result?.data.data.map(
        (selectedItem) => ({
          label: `${selectedItem?.firstName} ${selectedItem?.lastName}`,
          value: `${selectedItem?.firstName} ${selectedItem?.lastName}`,
        })
      );
      setFriendReferralArray(friendFilterArray);
    } else setFriendReferralArray([]);
  }, [allClients]);

  useEffect(() => {
    if (referralSource === "FRIEND") {
      setFriendFieldEnable(true);
    } else setFriendFieldEnable(false);
  }, [referralSource]);

  useEffect(() => {
    if (searchFriend) {
      const payload = handleFilterSearch(
        searchFriend,
        ["firstName", "lastName", "email"],
        "search"
      );
      getAllClients({ payload, roleType: "CLIENT" });
    } else getAllClients({ roleType: "CLIENT" });
  }, [searchFriend]);

  useEffect(() => {
    handleInitialValues();
  }, [singleClient]);

  return {
    methods,
    control,
    pinnedNote,
    status,
    setStatus,
    friendReferralArray,
    dispatch,
    handleSubmit,
    onSubmit,
    friendFieldEnable,
    onChange,
    singleContraindication,
    navigate,
    singleAppointment,
    clientLoading,
    clientDetailApi,
  };
}
