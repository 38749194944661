import * as Yup from "yup";

export const validationSchema = () => {
  let schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    color: Yup.string()
      .required("Color is required")
      .matches(
        /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{4}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/,
        {
          message:
            "Color must start with # and be 3, 4, 6, or 8 hexadecimal characters long",
        }
      ),
  });

  return schema;
};
