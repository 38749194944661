import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Package } from "@phosphor-icons/react";

export default function ProductSaleCard({
  imageSrc,
  category,
  productName,
  seller,
  unitPrice,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        height: "88px",
        paddingRight: "8px",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#F8F7FE",
        justifyContent: "space-between",
        bgcolor: "#FFFFFF",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          width: { md: "464px", sm: "100%" },
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "88px", borderRadius: "8px" }}>
          <img
            src={imageSrc}
            alt="product"
            style={{ borderRadius: "8px 0px 0px 8px" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Box
            sx={{
              padding: "4px 8px",
              background: "#FFF0F6",
              color: "#EB2F96",
              fontSize: "10px",
              textTransform: "uppercase",
              maxWidth: "fit-content",
              borderRadius: "4px",
            }}
          >
            {category}
          </Box>
          <Typography
            sx={{
              color: "#5E6062",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            {productName}
          </Typography>
          {/* <Typography
            sx={{
              color: "#4E45E5",
              fontSize: "12px",
            }}
          >
            {seller}
          </Typography>
          <Typography
            sx={{
              color: "#5E6062",
              fontSize: "12px",
            }}
          >
            Unity Price ${unitPrice}
          </Typography> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "row", md: "column" },
          gap: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#5E6062",
            textAlign: "right",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          ${unitPrice}
        </Typography>
        <Typography
          sx={{
            color: "#5E6062",
            textAlign: "right",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap: "6px",
          }}
        >
          <Package size={12} /> $ 30,000
        </Typography>
        {/* <Box sx={{ display: "flex", gap: "8px" }}>
          <Box
            sx={{
              display: "flex",
              width: "32px",
              height: "32px",
              padding: "0px 8px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              background: "#EDECFC",
            }}
          >
            <Minus size={16} color="#4E45E5" />
          </Box>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid  #DADADB",
              background: "#FFF",
              maxWidth: "32px",
              maxHeight: "32px",
              overflow: "hidden",
            }}
          >
            <Input
              type="text"
              defaultValue={1}
              sx={{
                textAlign: "center",
                paddingLeft: { sm: "10px", xs: "2px" },
              }}
              name="client"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "32px",
              height: "32px",
              padding: "0px 8px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              background: "#EDECFC",
            }}
          >
            <Plus size={16} color="#4E45E5" />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
}
