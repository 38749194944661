import { IdentificationBadge, Pencil } from "@phosphor-icons/react";

//popover mock data
export const rolesPopOverData = (
  setPopoverOpen,
  setIsRoleDialogOpen,
  setIsOpenAddRoleDialog
) => [
  {
    id: "1",
    label: "Edit",
    icon: Pencil,
    onClick: () => {
      setPopoverOpen(null);
      setIsRoleDialogOpen(true);
    },
  },
  // {
  //   id: "2",
  //   label: "Add to Staff Member",
  //   icon: IdentificationBadge,
  //   onClick: () => {
  //     setIsOpenAddRoleDialog(true);
  //     setPopoverOpen(null);
  //   },
  // },
];
