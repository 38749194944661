import { Key, User } from "@phosphor-icons/react";

export const profileData = {
  label: "Profile",
  icon: User,
  link: "Profile",
};

export const changePassword = {
  label: "Change Password",
  icon: Key,
  link: "Change Password",
};
