import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useGetProductsMutation } from "../../../../services/product";
import { useGetProductTypeMutation } from "../../../../services/product-type";
import { useGetQualificationsMutation } from "../../../../services/qualifications";
import { useGetRoomsMutation } from "../../../../services/rooms";
import { useGetServiceCategoriesMutation } from "../../../../services/service-category";
import { useCreateServiceMutation } from "../../../../services/services";
import { handleFilterSearch } from "../../../../utils/searchFilter";
import { treatmentValidationSchema } from "../treatmentValidationSchema";
import { useSelector } from "react-redux";
dayjs.extend(utc);

export default function useNewServices(
  handleClose,
  getAllServices,
  currentPage
) {
  const [searchQualification, setSearchQualification] = useState("");
  const [treatmentStepper, setTreatmentStepper] = useState(1);
  const [isSearchBox, setIsSearchBox] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");
  const [activeItem, setActiveItem] = useState("ALL");
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [butterType, setBufferType] = useState("BEFORE_TREATMENT");
  // States to hold data for each step
  const [stepOneData, setStepOneData] = useState({});
  const [stepTwoData, setStepTwoData] = useState({});
  const [stepThreeData, setStepThreeData] = useState({});
  const [stepFourData, setStepFourData] = useState({});
  const [enabled, setEnabled] = useState(false);
  const [activeHistoryTab, setActiveHistoryTab] = useState(false);
  const imageUrl = useSelector((state) => state?.uploadFile?.url);

  const validationSchemaInstance = treatmentValidationSchema({
    treatmentStepper: treatmentStepper,
  });
  const methods = useForm({ resolver: yupResolver(validationSchemaInstance) });

  const [createService, { isLoading: createServiceLoader }] =
    useCreateServiceMutation();
  const [getAllRooms, { data: roomsData, isLoading: roomsLoader }] =
    useGetRoomsMutation();
  const [
    getAllProducts,
    { data: allProductData, isLoading: productDataLoading },
  ] = useGetProductsMutation();

  const [
    getAllServiceCategory,
    { data: serviceCategoryData, isLoading: serviceCategoryDataLoader },
  ] = useGetServiceCategoriesMutation();
  const [
    getAllProductType,
    { data: productTypeData, isLoading: productTypeDataLoader },
  ] = useGetProductTypeMutation();

  const [
    getAllQualifications,
    { data: qualificationsData, isLoading: qualificationsDataLoader },
  ] = useGetQualificationsMutation();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  console.log(errors);

  const handleCloseForm = () => {
    handleClose();
    reset();
  };

  const handleAddProduct = useCallback((product) => {
    setSelectedProduct((prevSelectedProduct) => {
      const productIndex = prevSelectedProduct.findIndex(
        (item) => item._id === product._id
      );
      if (productIndex === -1)
        return [
          ...prevSelectedProduct,
          { ...product, quantity: 1, isRequired: "OPTIONAL" },
        ];
      const updatedProduct = [...prevSelectedProduct];
      updatedProduct[productIndex].quantity += 1;
      return updatedProduct;
    });
  }, []);
  const handleRemoveProduct = (productId) => {
    const filteredArr = selectedProduct.filter(
      (item) => item._id !== productId
    );
    setSelectedProduct(filteredArr);
  };

  const handleQuantityChange = useCallback((productId, change) => {
    setSelectedProduct((prevSelectedProduct) =>
      prevSelectedProduct
        .map((item) =>
          item._id === productId
            ? { ...item, quantity: item.quantity + change }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  }, []);

  const handleRequiredOptional = useCallback((productId, value) => {
    setSelectedProduct((prevSelectedProduct) =>
      prevSelectedProduct.map((item) =>
        item._id === productId ? { ...item, isRequired: value } : item
      )
    );
  }, []);

  const handleSearchProduct = useCallback(() => {
    const attributeArray = isNaN(searchProduct) ? ["name"] : ["name"];
    const searchPayload = handleFilterSearch(
      searchProduct,
      attributeArray,
      "search"
    );
    getAllProducts(searchProduct ? { ...searchPayload } : {});
  }, [searchProduct, getAllProducts]);

  const handleSearchQualification = () => {
    let attributeArray = [];
    if (searchQualification !== "") {
      attributeArray = ["name"];
      const searchPayload = handleFilterSearch(
        searchQualification,
        attributeArray,
        "search"
      );
      getAllQualifications({ ...searchPayload });
    } else {
      getAllQualifications({});
    }
  };

  useEffect(() => {
    handleSearchQualification();
  }, [searchQualification]);

  useEffect(() => {
    const transformedProducts = selectedProduct?.map((product) => ({
      product: product?._id,
      quantity: product?.quantity,
      isRequired: product?.isRequired,
    }));
    setValue("products", transformedProducts);
  }, [selectedProduct]);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      ["status=ACTIVE"],
      ["status"],
      "filter"
    );
    const productFilterPayload = handleFilterSearch(
      ["type=PROFESSIONAL_PRODUCT"],
      ["type"],
      "filter"
    );
    getAllRooms({ ...searchPayload });
    getAllProducts({ ...productFilterPayload });
    getAllQualifications();
    getAllServiceCategory();
    getAllProductType();
  }, []);

  useEffect(() => {
    handleSearchProduct();
  }, [searchProduct, handleSearchProduct]);

  const handleProductTypeSelection = () => {
    const retailAndProductType = {
      condition: {
        productTypeId: activeHistoryTab,
        type: "PROFESSIONAL_PRODUCT",
      },
    };
    let retailProductsSearchPayload = handleFilterSearch(
      ["type=PROFESSIONAL_PRODUCT"],
      ["type"],
      "filter"
    );

    if (activeItem === "ALL") {
      getAllProducts({ ...retailProductsSearchPayload });
    } else {
      getAllProducts({
        ...retailAndProductType,
      });
    }
  };
  useEffect(() => {
    handleProductTypeSelection();
  }, [activeHistoryTab]);

  // Stepper logic
  const handleNextStep = async (data) => {
    if (treatmentStepper === 1) {
      setStepOneData(data);
      setTreatmentStepper(2);
    } else if (treatmentStepper === 2) {
      setStepTwoData(data);
      setTreatmentStepper(3);
    } else if (treatmentStepper === 3) {
      setStepThreeData(data);
      setTreatmentStepper(4);
    } else if (treatmentStepper === 4) {
      setStepFourData(data);
      await handleSubmitAllSteps();
    }
  };

  const onSubmit = (data) => {
    handleNextStep(data);
  };

  const handleSubmitAllSteps = async () => {
    const combinedData = {
      ...stepOneData,
      ...stepTwoData,
      ...stepThreeData,
      ...stepFourData,
    };
    if (imageUrl) {
      combinedData["profilePictureUrl"] = imageUrl;
    }
    const response = await createService(combinedData);

    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }

    getAllServices({ page: currentPage, pageSize: 10 });
    toast.success(response?.data?.message);
    reset();
    handleClose();
    handleCloseForm();
  };

  const toggleSwitch = () => {
    setEnabled(!enabled);
  };

  const handleRemove = (product) => {
    const newArr = selectedProduct.filter((item) => item._id !== product._id);
    setSelectedProduct(newArr);
  };

  // comment

  const getMeasurementAbbreviation = (measurement) => {
    switch (measurement) {
      case "PACK":
        return "P";
      case "UNIT":
        return "U";
      case "MILLILITRE":
        return "M";
      case "BAG":
        return "EACH";
      case "LITRE":
        return "L";
      default:
        return measurement;
    }
  };

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    createServiceLoader,
    roomsData,
    roomsLoader,
    allProductData,
    productDataLoading,
    serviceCategoryData,
    qualificationsData,
    qualificationsDataLoader,
    treatmentStepper,
    setTreatmentStepper,
    searchQualification,
    setSearchQualification,

    // Product section
    searchProduct,
    setSearchProduct,
    activeItem,
    setActiveItem,
    isSearchBox,
    setIsSearchBox,
    selectedProduct,
    setSelectedProduct,
    handleAddProduct,
    handleQuantityChange,
    butterType,
    setBufferType,
    productTypeData,
    toggleSwitch,
    enabled,
    handleRemove,
    getMeasurementAbbreviation,
    setEnabled,
    handleRequiredOptional,
    setActiveHistoryTab,
    handleRemoveProduct,
  };
}
