import React from 'react';

export default function GreaterThenIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.3535 8.35403L6.35354 13.354C6.30708 13.4005 6.25193 13.4373 6.19124 13.4625C6.13054 13.4876 6.06549 13.5006 5.99979 13.5006C5.93409 13.5006 5.86904 13.4876 5.80834 13.4625C5.74764 13.4373 5.69249 13.4005 5.64604 13.354C5.59958 13.3076 5.56273 13.2524 5.53759 13.1917C5.51245 13.131 5.49951 13.066 5.49951 13.0003C5.49951 12.9346 5.51245 12.8695 5.53759 12.8088C5.56273 12.7481 5.59958 12.693 5.64604 12.6465L10.2929 8.00028L5.64604 3.35403C5.55222 3.26021 5.49951 3.13296 5.49951 3.00028C5.49951 2.8676 5.55222 2.74035 5.64604 2.64653C5.73986 2.55271 5.86711 2.5 5.99979 2.5C6.13247 2.5 6.25972 2.55271 6.35354 2.64653L11.3535 7.64653C11.4 7.69296 11.4369 7.74811 11.4621 7.80881C11.4872 7.86951 11.5002 7.93457 11.5002 8.00028C11.5002 8.06599 11.4872 8.13105 11.4621 8.19175C11.4369 8.25245 11.4 8.30759 11.3535 8.35403Z'
        fill='#4E45E5'
      />
    </svg>
  );
}
