import { configureStore } from "@reduxjs/toolkit";
// import { persistReducer } from 'redux-persist';
// import { combineReducers } from '@reduxjs/toolkit';
import { baseAPI } from "../services/baseApi";
import { countriesBaseAPI } from "../services/contriesBaseApi";
import appointmentReducer from "./features/new-appointment";
import uploadFileReducer from "./features/upload-file";
import notesReducer from "./features/notes";
import clientDetailsReducer from "./features/client-details";
import retailsProductReducer from "./features/products";
import userProfileReducer from "./features/user-profile";
import openSalesModalReducer from "./features/sales";

// Function need to maintain state in future
// const persistConfig = {
//   key: "root",
//   storage,
// };
// const reducer = combineReducers({
// });
// const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
  reducer: {
    [baseAPI.reducerPath]: baseAPI.reducer,
    [countriesBaseAPI.reducerPath]: countriesBaseAPI.reducer,
    appointmentModal: appointmentReducer,
    notesModal: notesReducer,
    uploadFile: uploadFileReducer,
    clientDetail: clientDetailsReducer,
    retailProducts: retailsProductReducer,
    userProfile: userProfileReducer,
    salesModal: openSalesModalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(baseAPI.middleware)
      .concat(countriesBaseAPI.middleware),
});
export default store;
