import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { treatmentGeneralInformation } from "../services-data";

const TreatmentInfo = ({ isView = false, control, serviceCategoryData }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {treatmentGeneralInformation(
        isView,
        serviceCategoryData?.result?.data?.data
      ).map((item) => {
        const IconCom = (IconView) => {
          return <IconView />;
        };
        return (
          <Box
            sx={{
              borderRadius: "8px",
              background: "#F8F7FE",
              display: "flex",
              padding: "16px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "16px",
              minHeight: "200px",
              mb: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", gap: "4px" }}>
                <Box> {IconCom(item?.icon)}</Box>
                <Typography
                  sx={{
                    color: "#4A4C4F",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  {item?.title}
                </Typography>
              </Box>
              {item?.description && (
                <Typography
                  sx={{
                    color: "#5E6062",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  {item?.description}
                </Typography>
              )}
            </Box>
            <Grid container spacing={1}>
              {item?.rowChildren?.map((child, index) => (
                <Grid key={index} item xs={12} sm={12} md={child.gridLength}>
                  {child?.colChildren && (
                    <Grid container spacing={1}>
                      {child?.colChildren?.map((subChild, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sm={12}
                          md={subChild?.gridLength}
                        >
                          {subChild?.colTitle && (
                            <Typography
                              sx={{
                                color: "#5E6062",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                                letterSpacing: "0.035px",
                                textAlign: "center",
                              }}
                            >
                              {subChild?.colTitle}
                            </Typography>
                          )}
                          {subChild?.component && (
                            <Box>
                              <subChild.component
                                control={control}
                                {...subChild?.otherOptions}
                              />
                            </Box>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};

export default TreatmentInfo;
