import { At, ChatText, Envelope } from "@phosphor-icons/react";
import SPAInputField from "../../hook-form/SPAInputField";
import SPASelectField from "../../hook-form/SPASelectField";
import AutoCompleteDetails from "../../common/autocomplete-details/autocomplete-details";

export const historyTabsArray = [
  { title: "ALL", icon: <At size={16} /> },
  {
    title: "EMAIL",
    icon: <Envelope size={16} />,
  },
  { title: "SMS", icon: <ChatText size={16} /> },
];

export const historyFormData = ({
  InsertButton,
  historyType,
  clientData,
  templateData,
}) => {
  let finalData;
  let EmailData = [
    {
      gridLength: 4,
      otherOptions: {
        fullWidth: true,
        name: "client",
        label: "Client",
        placeholder: "Select Client",
        options:
          clientData &&
          clientData?.map((item) => {
            return {
              label: `${item.firstName} ${item.lastName}`,
              value: `${item.firstName} ${item.lastName}`,
              id: item._id,
              mobileNumber: item?.mobileNumber,
              status: item?.clientStatus,
            };
          }),
      },
      component: AutoCompleteDetails,
    },
    {
      gridLength: 4,
      otherOptions: {
        fullWidth: true,
        name: "recipients",
        label: "Client Email",
        placeholder: "example@gmail.com",
      },
      component: SPAInputField,
    },
    {
      gridLength: 4,
      otherOptions: {
        fullWidth: true,
        name: "template",
        label: "Template",
        placeholder: "Select Template",
        labelHelperText: "(Optional)",
        options:
          templateData &&
          templateData.map((item) => {
            return { label: item.name, value: item.name, id: item._id };
          }),
      },
      component: SPASelectField,
    },
    {
      gridLength: 8,
      otherOptions: {
        fullWidth: true,
        name: "subject",
        label: "Subject",
        placeholder: "Write Subject",
      },
      component: SPAInputField,
    },
    // {
    //   gridLength: 4,
    //   otherOptions: {
    //     fullWidth: true,
    //     name: "replyToEmails",
    //     label: "Reply to",
    //     placeholder: "example@gmail.com",
    //     // labelHelperText: "(Optional)",
    //   },
    //   component: SPAInputField,
    // },
    {
      gridLength: 3,
      component: InsertButton,
    },
  ];
  let SMSData = [
    {
      gridLength: 4,
      otherOptions: {
        fullWidth: true,
        name: "client",
        label: "Client",
        placeholder: "Select Client",
        options:
          clientData &&
          clientData?.map((item) => {
            return {
              label: `${item.firstName} ${item.lastName}`,
              value: `${item.firstName} ${item.lastName}`,
              id: item._id,
              mobileNumber: item?.mobileNumber,
              status: item?.clientStatus,
            };
          }),
      },
      component: AutoCompleteDetails,
    },
    {
      gridLength: 4,
      otherOptions: {
        fullWidth: true,
        name: "phoneNumber",
        label: "Client’s Mobile Number",
        placeholder: "",
      },
      component: SPAInputField,
    },
    {
      gridLength: 4,
      otherOptions: {
        fullWidth: true,
        name: "template",
        label: "Template",
        placeholder: "Select Template",
        labelHelperText: "(Optional)",
        options:
          templateData &&
          templateData.map((item) => {
            return { label: item.name, value: item.name, id: item._id };
          }),
      },
      component: SPASelectField,
    },
    // {
    //   gridLength: 12,
    //   otherOptions: {
    //     fullWidth: true,
    //     name: "replyToSms",
    //     label: "Reply to",
    //     placeholder: "+6140056891058",
    //     // labelHelperText: "(Optional)",
    //   },
    //   component: SPAInputField,
    // },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "message",
        label: "Message",
        placeholder: "Write Message",
        type: "textarea",
        multiline: true,
        rows: 12,
      },
      component: SPAInputField,
    },
  ];
  if (historyType === "SMS") {
    finalData = SMSData;
  } else {
    finalData = EmailData;
  }
  return finalData;
};
