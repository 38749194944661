export const cancelBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.light,
  color: theme.palette.primaryColor.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.lightHover,
  },
});
export const SubmitBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.main,
  color: theme.palette.white.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.dark,
  },
});
export const contraModelStyle = {
  calendarHeaderMain: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "400px",
    borderRadius: "8px",
  },
  selectionGrid: {
    display: "flex",
    background: "#EDECFC",
    width: "100%",
    borderRadius: "8px",
  },
  createNoteGrid: {
    display: "flex",
    background: "#FEFFE6",
    width: "100%",
    p: "10px",
    borderRadius: "8px",
  },
};
