import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreateRoleMutation,
  useGetSingleRoleQuery,
  useUpdateRoleMutation,
} from "../../../../../../services/roles";
import { validationSchema } from "./validationSchema";
import { Box, Tooltip } from "@mui/material";
import { Question, UserGear } from "@phosphor-icons/react";

export default function useNewRole(
  handleClose,
  singleRoleId,
  setIsOpenSuccessDialog,
  getAllRoles,
  setSingleRoleId
) {
  const validationSchemaInstance = validationSchema();
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
  });
  const [createRole, { isLoading: createRoleLoader }] = useCreateRoleMutation();
  const [updateSingleRole, { isLoading: updateRoleLoader }] =
    useUpdateRoleMutation();
  const { data: singleRole, isLoading: singleRoleLoader } =
    useGetSingleRoleQuery(singleRoleId, {
      skip: !singleRoleId,
    });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = methods;
  const currentColor = watch("color");

  const permissionsData = [
    {
      id: 1,
      label: "New Sales",
      value: "newSale",
      enumValue: "CREATE_APPOINTMENT",
    },
    {
      id: 2,
      label: "New Appointments",
      value: "newAppointment",
      enumValue: "EDIT_APPOINTMENT",
    },
    {
      id: 3,
      label: "New Busy Time",
      value: "createBusyTime",
      enumValue: "CREATE_CLIENT",
    },
    {
      id: 4,
      label: "Mention",
      value: "mention",
      enumValue: "EDIT_CLIENT",
    },
    {
      id: 4,
      label: "Profile Modification",
      value: "modifyProfile",
      enumValue: "EDIT_CLIENT",
    },
    // {
    //   id: 5,
    //   label: (
    //     <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
    //       <UserGear size={16} color="#8F9193" />
    //       Spa Management
    //       <Tooltip
    //         placement="top"
    //         title={`Granting this permission will provide the staff member with full access to manage various aspects, including staff members, retail and professional-use products, treatments, vouchers, and templates for communication (email & SMS). Additionally, they will have complete control over everything within the mobile app, including articles, products, treatments, points, vouchers, and more."`}
    //         arrow
    //       >
    //         <Question size={16} color="#8F9193" />
    //       </Tooltip>
    //     </Box>
    //   ),
    //   value: "spaManagement",
    //   enumValue: "SPA_MANAGEMENT",
    // },
  ];

  const onSubmit = async (values) => {
    let payload;
    const permissions = permissionsData
      .filter((permission) => values[permission.value])
      .map((permission) => permission.enumValue);

    payload = {
      name: values?.name,
      color: `${values?.color}`,
      permissions,
    };

    let response;
    if (singleRoleId) {
      response = await updateSingleRole({
        id: singleRole?.result?.data?._id,
        payload: payload,
      });
    } else {
      response = await createRole(payload);
    }
    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    setSingleRoleId(response?.data?.result?.data?._id);
    toast.success(response?.data?.message);
    getAllRoles();
    reset();
    setIsOpenSuccessDialog(true);
    handleClose("Success");
  };

  const handleCloseForm = () => {
    handleClose("Deffer");
    reset();
  };

  useEffect(() => {
    if (singleRole) {
      setValue("name", singleRole?.result?.data?.name);
      setValue("color", singleRole?.result?.data?.color);

      permissionsData.map((permission) => {
        return setValue(
          permission.value,
          singleRole?.result?.data?.permissions?.includes(permission.enumValue)
        );
      });
    }
  }, [singleRole]);

  return {
    currentColor,
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    singleRoleLoader,
    createRoleLoader,
    updateRoleLoader,
    permissionsData,
  };
}
