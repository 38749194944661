import * as Yup from "yup";

export const validationProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  role: Yup.string().required("Role is required"),
  note: Yup.string(),
  // note: Yup.string()
  //   .transform((value, originalValue) =>
  //     Array.isArray(originalValue) ? "" : value
  //   )
  //   .default(""),
  dateOfBirth: Yup.string().nullable(),
  gender: Yup.string().required("Gender is required"),
  mobileNumber: Yup.string()
    .matches(
      /^\+?\d{10,14}$/,
      "Invalid format. Provide Valid Format of 10 to 14 digits"
    )
    .test(
      "mobile-format",
      "Invalid format. Provide Valid Format of 10 to 14 digits",
      function (value) {
        if (!value) return true;
        return /^\+?\d{10,14}$/.test(value);
      }
    ),
  telephone: Yup.string()
    .matches(
      /^\+?\d{10,14}$/,
      "Invalid format. Provide Valid Format of 10 to 14 digits"
    )
    .test(
      "mobile-format",
      "Invalid format. Provide Valid Format of 10 to 14 digits",
      function (value) {
        if (!value) return true;
        return /^\+?\d{10,14}$/.test(value);
      }
    ),
  email: Yup.string().email("Invalid email").required("Email is required"),
});
