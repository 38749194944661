import { fs34 } from "../../theme";

export const resourceBox = {
  resourceLabel: {
    display: "flex",
    alignItems: "center",
    pb: "8px",
    fontSize: fs34,
    color: "#070A0E",
  },
  statusInfo: {
    p: "4px 8px",
    width: "fit-content",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontSize: "10px",
  },
  busyTimeHeaderMain: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "8px",
    background: "#F8F7FE",
    mt: "24px",
    ml: "8px",
  },
  selectionGrid: {
    display: "flex",
    background: "#EDECFC",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    p: "2.5px 4px",
    borderRadius: "8px",
  },
  qualificationSelectionGrid: {
    display: "flex",
    background: "#EDECFC",
    alignItems: "center",
    // justifyContent: "space-between",
    width: "100%",
    p: "2.5px 4px",
    borderRadius: "8px",
  },
  repeatOnHeaderMain: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "8px",
    background: "#F8F7FE",
  },
  repeatOnSelectionGrid: {
    display: "flex",
    background: "#EDECFC",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "4px",
    p: "2.5px 4px",
    borderRadius: "8px",
  },
  gridView: {
    borderRadius: "8px",
    cursor: "pointer",
    p: "8px 16px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    fontSize: "14px",
    fontWeight: "600",
  },
};
