import React from "react";
import SPADialogue from "../../../common/spa-dialogue";
import useCreateCoupon from "./useCreateCoupon";
import {
  Backdrop,
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { couponElements, timeData } from "./coupon-form-data";
import { Controller } from "react-hook-form";
import { Warning } from "@mui/icons-material";
import SPAInputField from "../../../hook-form/SPAInputField";

export default function CreateNewCoupon({
  open,
  handleClose,
  couponId,
  couponType,
}) {
  const {
    singleCouponLoader,
    control,
    onSubmit,
    methods,
    handleSubmit,
    buttonLoading,
    endDateStarting,
    listingData,
    customCoupon,
  } = useCreateCoupon(handleClose, couponId, couponType);

  const DiscountToggle = () => {
    return (
      <Box sx={{ position: "relative" }}>
        <Box sx={{ position: "absolute", right: "5px", zIndex: "100" }}>
          {" "}
          <Controller
            name="discountType"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Box>
                  <ToggleButtonGroup
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                    value={value}
                    name="discountType"
                  >
                    <ToggleButton
                      sx={{
                        p: "0px 10px",
                        lineHeight: "1.2rem",
                        color: "#000000",
                        "&.Mui-selected": {
                          backgroundColor: "#4E45E5",
                          color: "#ffffff",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: "#4E45E5",
                          color: "#ffffff",
                        },
                      }}
                      value="DOLLAR"
                    >
                      $
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        p: "0px 10px",
                        lineHeight: "1.2rem",
                        color: "#000000",
                        "&.Mui-selected": {
                          backgroundColor: "#4E45E5",
                          color: "#ffffff",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: "#4E45E5",
                          color: "#ffffff",
                        },
                      }}
                      value="PERCENTAGE"
                    >
                      %
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              );
            }}
          />
        </Box>
        <SPAInputField
          name="discount"
          label="Discount"
          placeholder="Enter Discount"
        />
      </Box>
    );
  };
  const RadioButtons = () => {
    return (
      <Box>
        <Controller
          name="customCoupon"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box>
                <Box>
                  <Typography
                    sx={{ color: "#5E6062", fontSize: "14px", mb: "5px" }}
                  >
                    Discount Code
                  </Typography>
                  <RadioGroup
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                    value={value}
                    row
                    // name="customCoupon"
                  >
                    <FormControlLabel
                      value="auto"
                      control={<Radio />}
                      label="Auto"
                    />
                    <FormControlLabel
                      value="custom"
                      control={<Radio />}
                      label="Custom"
                    />
                  </RadioGroup>
                </Box>
              </Box>
            );
          }}
        />
        {customCoupon === "custom" && (
          <SPAInputField
            name="code"
            placeholder="Enter Code"
            label="Enter Custom Coupon Code"
            control={control}
          />
        )}
      </Box>
    );
  };
  return (
    <>
      {singleCouponLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={singleCouponLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!singleCouponLoader && (
        <SPADialogue
          open={open}
          handleClose={handleClose}
          dialogTitle={couponId ? "Edit Discount Code" : `Create Discount Code`}
          cancelText="Cancel"
          submitText={couponId ? "Save Changes" : `Create Discount Code`}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          methods={methods}
          isLoading={buttonLoading}
        >
          <Grid
            container
            spacing={4}
            sx={{
              width: "688px",
            }}
          >
            {couponElements(
              listingData,
              endDateStarting,
              couponType,
              RadioButtons,
              DiscountToggle
            ).map((item, index) => (
              <Grid item lg={item.gridLength} xs={12} key={index}>
                <item.component {...item.otherOptions} control={control} />
              </Grid>
            ))}
          </Grid>
          {/* </Box> */}
        </SPADialogue>
      )}
    </>
  );
}
