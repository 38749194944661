import {
  CalendarPlus,
  ChatText,
  CreditCard,
  Envelope,
  User,
} from "@phosphor-icons/react";

export const profileData = {
  label: "Profile",
  icon: User,
  link: "profile",
};

export const sidebarData = [
  {
    label: "Appointment",
    icon: CalendarPlus,
    link: "appointment",
  },
  {
    label: "Sale",
    icon: CreditCard,
    link: "sale",
  },
  {
    label: "Email",
    icon: Envelope,
    link: "email",
  },
  {
    label: "SMS",
    icon: ChatText,
    link: "sms",
  },
];
