import { baseAPI } from "../baseApi";

export const BusinessLocationApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessLocations: builder.query({
      query: () => ({ url: "/business-location" }),
      providesTags: ["GetBusinessLocations"],
    }),
    updateBusinessLocationTiming: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `/business-location/${id}`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["GetBusinessLocations"],
    }),
  }),
});

export const {
  useGetBusinessLocationsQuery,
  useUpdateBusinessLocationTimingMutation,
} = BusinessLocationApi;
