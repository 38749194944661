import { Switch, styled, Button } from "@mui/material";

export const TreatmentStyle = {
  addQualificationHeaderMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "8px",
    background: "#F8F7FE",
    p: "16px",
    gap: "8px",
  },
};
export const cancelBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.light,
  color: theme.palette.primaryColor.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.lightHover,
  },
});
export const SubmitBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.main,
  color: theme.palette.white.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.dark,
  },
});

export const labelStyle = (theme) => ({
  fontSize: "14px",
  color: theme.palette.textPrimary900.main,
  marginBottom: "8px",
});

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#4E45E5",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2, // Adjusted border-radius
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export const EnabledButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? "#13A10E" : "transparent",
  color: active ? "white" : "#4E45E5",
  borderRadius: "8px",
  flex: 1,
  "&:hover": {
    backgroundColor: active ? "darkgreen" : "rgba(0,0,0,0.1)",
  },
  border: "none",
  boxShadow: "none",
}));

export const DisabledButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? "#D13438" : "transparent",
  color: active ? "white" : "#4E45E5",
  borderRadius: "8px",

  flex: 1,
  "&:hover": {
    backgroundColor: active ? "darkred" : "rgba(0,0,0,0.1)",
  },
  border: "none",
  boxShadow: "none",
}));
