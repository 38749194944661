import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { customColors, fw600, primary, secondary } from "../../theme";
import RoomsSection from "./Rooms";
import Coupon from "./coupon";
import TreatmentsSection from "./Treatments";
import { resourceManagementTabs } from "./resource-management-data";
import { resourceBox } from "./resourceManagementStyle";
import StaffSection from "./staff";
import ServiceCategorySection from "./service-category";
import ProductSection from "./product";
import ArticlesSection from "./articles";
import MobileApp from "./mobileApp/MobileApp";
import DealsAndBundles from "./dealsAndPackages";
import QualificationSection from "./qualification";
import { TimingsSection } from "./timings";
const ResourceManagementSection = () => {
  const [activeItem, setActiveItem] = useState("Service Category");

  const renderTabContent = (tab) => {
    switch (tab) {
      case "Service Category":
        return <ServiceCategorySection />;
      case "Treatments":
        return <TreatmentsSection />;
      case "Zones":
        return <RoomsSection />;
      case "Staff":
        return <StaffSection />;
      case "Products":
        return <ProductSection />;
      // case "Discount Code":
      //   return <Coupon />;
      case "Articles":
        return <ArticlesSection />;
      case "Mobile App":
        return <MobileApp />;
      case "DealsAndPackages":
        return <DealsAndBundles />;
      case "Qualification":
        return <QualificationSection />;
      case "Timings":
        return <TimingsSection />;

      default:
        return <div>Select a tab</div>;
    }
  };
  return (
    <>
      <Typography sx={resourceBox.resourceLabel}>
        Resources Management
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            backgroundColor: primary.blue,
            borderRadius: "8px",
            width: "100%",
            p: "4px",
          }}
        >
          <Box
            sx={{
              height: "40px",
              display: "flex",
              backgroundColor: primary.blue,
              width: "max-content",
              overflow: "auto",
            }}
          >
            {resourceManagementTabs.map((item, index) => (
              <ListItemButton
                key={index}
                onClick={() => {
                  setActiveItem(item.link);
                }}
                sx={{
                  flexDirection: "row",
                  padding: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  gap: "6px",
                  backgroundColor: activeItem?.includes(item.link)
                    ? secondary.main
                    : primary.blue,
                  color: activeItem?.includes(item.link)
                    ? customColors.white
                    : secondary.main,
                  "&:hover": {
                    backgroundColor: activeItem?.includes(item.link)
                      ? secondary.main
                      : "auto",
                  },
                }}
              >
                {item.icon && (
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                    }}
                  >
                    <item.icon
                      size={20}
                      color={
                        activeItem?.includes(item.link)
                          ? customColors.white
                          : secondary.main
                      }
                    />
                  </ListItemIcon>
                )}

                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: fw600,
                      textTransform: "uppercase",
                      width: "max-content",
                    },
                  }}
                  primary={item.label}
                />
              </ListItemButton>
            ))}
          </Box>
        </Box>
        <Box sx={{ pt: "24px", minHeight: "90%" }}>
          {renderTabContent(activeItem)}
        </Box>
      </Box>
    </>
  );
};

export default ResourceManagementSection;
