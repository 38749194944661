import SPAInputField from "../../../hook-form/SPAInputField";
import SPASelectField from "../../../hook-form/SPASelectField";

export const newQualificationFormData = (serviceCategoryData) => {
  return [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "name",
        label: "Name",
        placeholder: "i.e.: Massagist",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        options: serviceCategoryData?.map((item) => ({
          label: item.name,
          value: item.name,
          id: item._id,
        })),
      },
      component: SPASelectField,
    },
  ];
};
