import SPAInputField from "../../../hook-form/SPAInputField";
import ColorField from "./color-field";

export const newServiceCategoryFormData = [
  {
    gridLength: 12,
    otherOptions: {
      fullWidth: true,
      name: "name",
      label: "Name",
      placeholder: "i.e.: Massage Specialize",
    },
    component: SPAInputField,
  },
  {
    gridLength: 12,
    otherOptions: {
      fullWidth: true,
      name: "color",
      placeholder: "#000000",
    },
    component: ColorField,
  },
];
