import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { articleElements } from "../create-article-form-data";

const CreateArticleView = ({ control, InsertData, handleInsertSelection }) => {
  return (
    <Grid item lg={4} md={4} xs={12}>
      <Box
        sx={{
          borderRadius: "16px",
          bgcolor: "#F8F7FE",
          p: "16px",
          height: "100%",
        }}
      >
        {articleElements().map((item, index) => (
          <Grid item lg={item.gridLength} xs={12} key={index}>
            <item.component {...item.otherOptions} control={control} />
          </Grid>
        ))}
        <Divider sx={{ my: "16px" }} />
        <Typography
          sx={{
            fontSize: "14px",
            color: "#5E6062",
          }}
        >
          Insert
        </Typography>
        <Grid container spacing={"16px"}>
          {InsertData?.map((item) => {
            const IconCom = (IconView) => {
              return <IconView size={24} color="#4E45E5" />;
            };
            return (
              <Grid
                key={item.id}
                item
                lg={6}
                xs={12}
                onClick={() => handleInsertSelection(item.value)}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                    borderRadius: "8px",
                    p: "8px",
                    bgcolor: "#EDECFC",
                    cursor: "pointer",
                  }}
                >
                  {IconCom(item.icon)}
                  <Typography
                    sx={{
                      color: "#4E45E5",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      fontSize: "14px",
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Grid>
  );
};

export default CreateArticleView;
