import SPAInputField from "../../../hook-form/SPAInputField";
import ColorField from "./color-field";

export const newRoleFormData = () => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "name",
        label: "Name",
        placeholder: "i.e.: Manager",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "color",
        placeholder: "#000000",
      },
      component: ColorField,
    },
  ];
};
