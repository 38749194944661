import SPAInputField from "../../hook-form/SPAInputField";
import SPASelectField from "../../hook-form/SPASelectField";

export const cancelAppointmentFormData = (availableType) => {
  let formData = [
    {
      key: "cancelReason",
      gridLength: 12,
      otherOptions: {
        name: "cancelReason",
        label: "Reason",
        options: [
          { label: "Emergency", value: "Emergency" },
          { label: "Feeling Unwell", value: "Feeling Unwell" },
          { label: "Other Commitments", value: "Other Commitments" },
          { label: "Personal Reasons", value: "Personal Reasons" },
          { label: "Schedule Conflict", value: "Schedule Conflict" },
          { label: "Traveling", value: "Traveling" },
          { label: "Weather Conditions", value: "Weather Conditions" },
          { label: "Custom Reason", value: "Custom Reason" },
        ],
        placeholder: "Select Reason",
      },
      component: SPASelectField,
    },
    {
      key: "additionalNotes",
      gridLength: availableType === "Custom Reason" ? 6 : 12,
      otherOptions: {
        name: "additionalNotes",
        label: "Additional Notes",
        labelHelperText: "(Optional)",
        type: "textarea",
        placeholder: "Add any other additional notes here",
      },
      component: SPAInputField,
      type: "input",
    },
    {
      key: "Custom Reason",
      gridLength: availableType === "Custom Reason" ? 6 : 12,
      otherOptions: {
        name: "customReason",
        label: "Custom Reason",
        placeholder: "Add custom reason here",
        type: "textarea",
      },
      component: SPAInputField,
      type: "input",
    },
  ];

  // Filter based on availableType
  if (availableType !== "Custom Reason") {
    formData = formData.filter((item) => item.key !== "Custom Reason");
  }

  return formData;
};
