import { FilterList } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Plus, UserPlus, X } from "@phosphor-icons/react";
import React from "react";
import NoClients from "../../../assets/svg/no-clients";
import { fs14, fs20, secondary } from "../../../theme";
import SPADialogue from "../../common/spa-dialogue";
import SPAPopover from "../../common/spa-popover";
import SPATable from "../../common/spa-table";
import SPATextField from "../../common/spa-textField";
import QualificationsSection from "../qualifications";
import RolesSection from "../roles";
import NewStaffForm from "./new-staff";
import Profile from "./new-staff-view";
import { staffPopOverData } from "./staff-data";
import useStaff from "./useStaff";
import AddQualification from "./add-qualification";
/**
 * StaffSection component.
 *
 * @returns {JSX.Element} JSX code for rendering the StaffSection component.
 */
const StaffSection = () => {
  const {
    staffTableHeader,
    isStaffDialogOpen,
    staffPopoverOpen,
    setStaffPopoverOpen,
    staffPopOverId,
    openStaffPopOver,
    handleNewStaff,
    staffsData,
    staffsDataLoader,
    currentPage,
    setCurrentPage,
    totalRecords,
    isProfileOpen,
    handleOpenProfileOpen,
    handleCloseProfileOpen,
    searchStaff,
    setSearchStaff,
    isRolesDialogOpen,
    setIsRolesDialogOpen,
    isQualificationsDialogOpen,
    setIsQualificationsDialogOpen,
    isOpenAddQualificationDialog,
    setIsOpenAddQualificationDialog,
    setIsStaffDialogOpen,
    qualificationOnSubmit,
    qualificationError,
    qualificationHandleSubmit,
    qualificationsDataLoader,
    qualificationsData,
    searchQualification,
    setSearchQualification,
    QualificationMethods,
    control,
    methods,
    handleSubmit,
    onSubmit,
    createStaffLoader,
    emergencyInfo,
    rolesData,
    qualificationStep,
    handleQualificationCloseDialog,
    handleStaffCloseDialog,
    getAllStaffs,
    getAllRoles,
    currentEmployment,
  } = useStaff();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                background: secondary.main,
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                height: "48px",
                "&:hover": { background: secondary.main },
              }}
              onClick={handleNewStaff}
            >
              <Plus size={24} color="currentColor" />
              Create Staff Member
            </Button>
            <Button
              disableElevation
              variant="contained"
              sx={{
                background: "#EDECFC",
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                height: "48px",
                color: "#4E45E5",
                "&:hover": { background: "#EDECFC", color: "#4E45E5" },
              }}
              onClick={() => setIsRolesDialogOpen(true)}
            >
              Roles
            </Button>
            {/* <Button
              disableElevation
              variant="contained"
              sx={{
                background: "#EDECFC",
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                height: "48px",
                color: "#4E45E5",
                "&:hover": { background: "#EDECFC", color: "#4E45E5" },
              }}
              onClick={() => setIsQualificationsDialogOpen(true)}
            >
              Qualifications
            </Button> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            <SPATextField
              placeholder="Search"
              value={searchStaff}
              sx={{
                width: { md: "328px", xs: "180px" },
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                    <MagnifyingGlass size={24} color={"#C1C2C3"} />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    onClick={() => {
                      setSearchStaff("");
                    }}
                  >
                    {searchStaff && <X size={24} />}
                  </InputAdornment>
                ),
              }}
              onChangeValue={(event) => {
                setSearchStaff(event.target.value);
              }}
            />

            <Box sx={styles.searchbox}>
              <FilterList sx={{ color: secondary.main }} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ pt: "16px" }}>
        {staffsDataLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={staffsDataLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {staffsData &&
          staffsData?.result?.data?.totalRecords > 0 &&
          !staffsDataLoader && (
            <SPATable
              data={staffsData?.result?.data?.data}
              columns={staffTableHeader}
              totalRecords={totalRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        {staffsData &&
          staffsData?.result?.data?.totalRecords === 0 &&
          !staffsDataLoader && (
            <Box
              sx={{
                mt: "18px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <NoClients />
              <Typography sx={{ fontSize: fs20, color: "#595959" }}>
                There’s no staff
              </Typography>
              <Typography sx={{ fontSize: fs14, color: "#595959" }}>
                Create a create staff by clicking on the button Create Staff
              </Typography>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleNewStaff}
              >
                <UserPlus size={24} color="currentColor" />
                Create Staff
              </Button>
            </Box>
          )}
      </Box>
      {isStaffDialogOpen && (
        <NewStaffForm
          getAllStaffs={getAllStaffs}
          handleStaffCloseDialog={handleStaffCloseDialog}
          isNewStaffOpen={isStaffDialogOpen}
          setIsStaffDialogOpen={setIsStaffDialogOpen}
          control={control}
          methods={methods}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          createStaffLoader={createStaffLoader}
          emergencyInfo={emergencyInfo}
          rolesData={rolesData}
          qualificationStep={qualificationStep}
          currentEmployment={currentEmployment}
        />
      )}
      {openStaffPopOver && (
        <SPAPopover
          id={staffPopOverId}
          anchorEl={staffPopoverOpen}
          width="215px"
          isOpen={openStaffPopOver}
          transformHorizontal="right"
          handleClose={() => {
            setStaffPopoverOpen(null);
          }}
          data={staffPopOverData(setStaffPopoverOpen, handleOpenProfileOpen)}
        />
      )}
      {isProfileOpen && (
        <Profile
          isStaffProfileOpen={isProfileOpen}
          handleClose={handleCloseProfileOpen}
        />
      )}
      {isRolesDialogOpen && (
        <SPADialogue
          dialogTitle={"Staff Roles"}
          open={isRolesDialogOpen}
          handleClose={() => {
            setIsRolesDialogOpen(false);
            getAllRoles();
          }}
        >
          <RolesSection />
        </SPADialogue>
      )}
      {isQualificationsDialogOpen && (
        <SPADialogue
          dialogTitle={"Qualifications"}
          open={isQualificationsDialogOpen}
          handleClose={() => {
            setIsQualificationsDialogOpen(false);
            getAllStaffs({ roleType: "STAFF" });
          }}
        >
          <QualificationsSection />
        </SPADialogue>
      )}
      {isOpenAddQualificationDialog && (
        <AddQualification
          handleQualificationCloseDialog={handleQualificationCloseDialog}
          isOpenAddQualificationDialog={isOpenAddQualificationDialog}
          setIsOpenAddQualificationDialog={setIsOpenAddQualificationDialog}
          qualificationOnSubmit={qualificationOnSubmit}
          qualificationError={qualificationError}
          qualificationHandleSubmit={qualificationHandleSubmit}
          qualificationsDataLoader={qualificationsDataLoader}
          qualificationsData={qualificationsData}
          searchQualification={searchQualification}
          setSearchQualification={setSearchQualification}
          QualificationMethods={QualificationMethods}
        />
      )}
    </>
  );
};

export default StaffSection;

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
