import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { User } from "@phosphor-icons/react";
import React from "react";
import useStaffOverview from "./useServiceOverview";
import { OverviewElements, SUPER_ADMIN } from "../../treatment-profile-data";
import theme from "../../../../../../theme/theme";
import FormProvider from "../../../../../hook-form/FormProvider";
import { customColors, fs10, fs16, fw600 } from "../../../../../../theme";
import ProfileImageUploader from "../../../../../profile-image-upload";
import { SubmitBtn } from "../../../../../new-appointment-form/new-appointment-style";
import { useSelector } from "react-redux";
export default function Overview({ singleStaffData, singleStaffRefetching }) {
  const {
    control,
    handleSubmit,
    onSubmit,
    methods,
    staffDetailApi,
    rolesData,
  } = useStaffOverview({ singleStaffData, singleStaffRefetching });

  const viewDataFields = OverviewElements(rolesData?.result?.data?.data);

  return (
    <>
      <Grid container gap={3} sx={{ height: "100%" }}>
        {/* // user information */}

        <Grid
          xs={12}
          md={12}
          style={{ padding: "16px", borderRadius: "8px" }}
          bgcolor={theme.palette.purple10.main}
          container
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} gap={1} mb={2}>
              <User size={24} />
              <Typography fontSize={fs16} fontWeight={fw600}>
                Personal Information
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {viewDataFields.map((item) => (
                <>
                  <Grid item xs={12} md={item.gridLength}>
                    {item.otherOptions.name === "role" && !SUPER_ADMIN ? (
                      <Tooltip
                        title="Only administrators can modify your role."
                        arrow
                        placement="top"
                        slotProps={{
                          popper: {
                            sx: {
                              [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                {
                                  marginTop: "0px",
                                },
                              [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                {
                                  marginBottom: "0px",
                                },
                              [`& .MuiTooltip-tooltip`]: {
                                padding: "12px",
                              },
                            },
                          },
                        }}
                      >
                        <span>
                          <item.component
                            {...item.otherOptions}
                            control={control}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <item.component
                        {...item.otherOptions}
                        control={control}
                      />
                    )}
                  </Grid>
                </>
              ))}
            </Grid>

            <Grid
              container
              bgcolor={customColors.white}
              sx={{
                padding: "4px",
                borderRadius: "8px",
                width: "auto",
                alignItems: "center",
              }}
              rowGap={1}
              xs={12}
              mt={1}
            >
              <Grid xs={3} lg={1.5}>
                {staffDetailApi?.profilePictureUrl ? (
                  <ProfileImageUploader
                    width={"48px"}
                    height={"48px"}
                    uploadBtnText={"edit"}
                    profileUpload={true}
                  />
                ) : (
                  <ProfileImageUploader
                    width={"48px"}
                    height={"48px"}
                    uploadBtnText={"edit"}
                    profileUpload={true}
                    initals={`${staffDetailApi?.firstName} ${staffDetailApi?.lastName}`}
                    initialSize="48px"
                  />
                )}
              </Grid>
              <Grid xs={6} lg={5}>
                <Typography
                  fontSize={fs10}
                  textTransform={"uppercase"}
                >{`id: ${staffDetailApi?._id}`}</Typography>
              </Grid>
            </Grid>

            <Box sx={{ mt: "20px" }}>
              <Button fullWidth sx={SubmitBtn} type={"submit"}>
                <Typography>Save Changes</Typography>
              </Button>
            </Box>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
}
