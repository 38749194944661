import { FilterList } from "@mui/icons-material";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  ArrowLineRight,
  CalendarBlank,
  CalendarPlus,
  CallBell,
  Clock,
  Gift,
  MagnifyingGlass,
  User,
  UserPlus,
  X,
} from "@phosphor-icons/react";
import dayjs from "dayjs";
import React from "react";
import NoClients from "../../assets/svg/no-clients";
import { fs14, fs20, fs34, secondary } from "../../theme";
import { nameInitialsAvatar } from "../../utils/nameInitialsAvatar";
import Loader from "../common/loader";
import SPATable from "../common/spa-table";
import SPATextField from "../common/spa-textField";
import CreateSalesDialogue from "./create-sales";
import EmailReceiptForm from "./email-receipt-form";
import ProductListing from "./product-listing";
import { salePricingDetail, statusDesign } from "./staff-data";
import useSales from "./useSales";
import Profile from "../profile-model";
import { setClientDetails } from "../../store/features/client-details";

/**
 * SalesSection component.
 *
 * @returns {JSX.Element} JSX code for rendering the SalesSection component.
 */
const SalesSection = () => {
  const {
    salesTableHeader,
    handleSaleModel,
    salesData,
    salesDataLoader,
    currentPage,
    setCurrentPage,
    totalRecords,
    searchSales,
    setSearchSales,
    productsData,
    productsDataLoader,
    searchProduct,
    setSearchProduct,
    singleSaleDrawer,
    setSingleSaleDrawer,
    setSingleSaleId,
    singleSaleData,
    singleSaleLoader,
    openNewSalesModal,
    isClientProfileModalOpen,
    handleCloseClientProfile,
    handleOpenClientProfile,
    dispatch,
  } = useSales();

  const voucherData = (info) => {
    return [
      {
        id: 1,
        label: "Voucher Code",
        value: info?.giftVoucher,
        offer: `---`,
      },
      {
        id: 2,
        label: "Discount Code",
        value: info?.discountCode,
        offer: `${info?.coupon[0]?.discount}${
          info?.coupon[0]?.discountType === "PERCENTAGE" ? "%" : "$"
        } OFF ON PRODUCTS`,
      },
    ];
  };

  const saleSummary = (info) => {
    let formData = [
      {
        id: 1,
        title: "Client",
        icon: User,
        bgcolor: false,
        component: (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                handleOpenClientProfile();
                dispatch(setClientDetails(info?.client));
              }}
            >
              {info?.client?.profilePictureUrl ? (
                <Avatar
                  sx={{ width: "24px", height: "24px" }}
                  src={info?.client?.profilePictureUrl}
                  variant="rounded"
                />
              ) : (
                <Avatar
                  {...nameInitialsAvatar(
                    `${info?.client?.firstName} ${info?.client?.lastName}`,
                    "24px"
                  )}
                  variant="rounded"
                />
              )}
              <Typography
                sx={{
                  color: "#4E45E5",
                  fontSize: "12px",
                  fontWeight: "600",
                  width: 100,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {`${info?.client?.firstName} ${info?.client?.lastName}`}
              </Typography>
            </Box>
          </>
        ),
        status: (
          <Box
            sx={{
              backgroundColor: statusDesign[info?.client?.status]?.bgColor,
              color: statusDesign[info?.client?.status]?.color,
              p: "4px 8px",
              width: "fit-content",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {statusDesign[info?.client?.status]?.icon}
            {info?.client?.status}
          </Box>
        ),
      },
      {
        id: 2,
        title: "Date",
        icon: CalendarBlank,
        bgcolor: true,
        component: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              color: "#5E6062",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            {dayjs(info?.buyDate).format("MMM DD, YYYY")}
          </Box>
        ),
        status: "",
      },
      {
        id: 3,
        title: "Time",
        icon: Clock,
        bgcolor: false,
        component: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              color: "#5E6062",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            {dayjs(info?.buyDate).format("hh:mm A")}
          </Box>
        ),
        status: "",
      },
      {
        id: 4,
        title: "Product Bought",
        icon: CallBell,
        bgcolor: true,
        component: (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            {info?.products?.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {}}
                >
                  {item?.product?.imageUrl ? (
                    <Avatar
                      sx={{ width: "24px", height: "24px" }}
                      src={item?.product?.imageUrl}
                      variant="rounded"
                    />
                  ) : (
                    <Avatar
                      {...nameInitialsAvatar(`${item?.product?.name}`, "24px")}
                      variant="rounded"
                    />
                  )}
                  <Typography
                    sx={{
                      color: "#4E45E5",
                      fontSize: "12px",
                      fontWeight: "600",
                      width: 100,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {`${item?.product?.name}`}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        ),
        status: (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            {info?.products?.map((item) => {
              return (
                <Box
                  key={item?._id}
                  sx={{ color: "#4A4C4F", fontSize: "12px", fontWeight: "600" }}
                >
                  ${item?.product?.price}
                </Box>
              );
            })}
          </Box>
        ),
      },
      {
        id: 5,
        title: "Voucher Used",
        icon: Gift,
        bgcolor: false,
        component: (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            {voucherData(info)?.map((item) => {
              return (
                <Box
                  key={item?.id}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography sx={{ color: "#8F9193", fontSize: "12px" }}>
                    {item?.label}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#4A4C4F",
                      fontSize: "12px",
                      fontWeight: "600",
                      display: "flex",
                      gap: "2px",
                      textTransform: "uppercase",
                    }}
                  >
                    {item?.value ?? "---"}
                    {item?.value && (
                      <Typography
                        sx={{
                          color: "#0D469E",
                          fontSize: "10px",
                          bgcolor: "#E7EFFC",
                          borderRadius: "4px",
                          p: "2px",
                        }}
                      >
                        {item?.offer}
                      </Typography>
                    )}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        ),
        status: "",
      },
    ];
    return formData;
  };

  return (
    <Grid container spacing={1}>
      <Grid item lg={8.5} sm={12}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={{ fontSize: fs34 }}>Sales</Typography>
                <Button
                  disableElevation
                  variant="contained"
                  sx={{
                    background: secondary.main,
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    borderRadius: "8px",
                    height: "48px",
                    "&:hover": { background: secondary.main },
                  }}
                  onClick={handleSaleModel}
                >
                  <CalendarPlus size={24} color="currentColor" />
                  Create Sale
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              <SPATextField
                placeholder="Search"
                value={searchSales}
                sx={{
                  width: { md: "328px", xs: "180px" },
                  "& .MuiOutlinedInput-root": {
                    height: "48px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} color={"#C1C2C3"} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingLeft: "10px", cursor: "pointer" }}
                      onClick={() => {
                        setSearchSales("");
                      }}
                    >
                      {searchSales && <X size={24} />}
                    </InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchSales(event.target.value);
                }}
              />

              <Box sx={styles.searchbox}>
                <FilterList sx={{ color: secondary.main }} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ pt: "16px" }}>
          {(salesDataLoader || singleSaleLoader) && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={salesDataLoader || singleSaleLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {salesData &&
            salesData?.result?.data?.data?.length > 0 &&
            !salesDataLoader && (
              <SPATable
                data={salesData?.result?.data?.data}
                columns={salesTableHeader}
                totalRecords={totalRecords}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          {salesData &&
            salesData?.result?.data?.data?.length === 0 &&
            !salesDataLoader && (
              <Box
                sx={{
                  mt: "18px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "24px",
                }}
              >
                <NoClients />
                <Typography sx={{ fontSize: fs20, color: "#595959" }}>
                  There’s no Sales
                </Typography>
                <Typography sx={{ fontSize: fs14, color: "#595959" }}>
                  Create a Sale by clicking on the button Create Sale
                </Typography>
                <Button
                  disableElevation
                  variant="contained"
                  sx={{
                    background: secondary.main,
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    borderRadius: "8px",
                    "&:hover": { background: secondary.main },
                  }}
                  onClick={handleSaleModel}
                >
                  <UserPlus size={24} color="currentColor" />
                  Create Sale
                </Button>
              </Box>
            )}
        </Box>
      </Grid>
      <Grid item lg={3.5} sm={12}>
        <ProductListing
          productsData={productsData}
          productsDataLoader={productsDataLoader}
          searchProduct={searchProduct}
          setSearchProduct={setSearchProduct}
        />
      </Grid>

      {/* sale model  */}
      {openNewSalesModal && <CreateSalesDialogue />}

      {singleSaleDrawer && (
        <Drawer
          anchor="right"
          transitionDuration={500}
          PaperProps={{
            sx: {
              whiteSpace: "nowrap",
              background: "#FFFFFF",
              minHeight: "100vh",
              boxShadow:
                "3px 2px 8px 0px rgba(78, 69, 229, 0.05), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -66px 62px 25px 0px rgba(78, 69, 229, 0.00);",
              boxSizing: "border-box",
              width: { sm: 447, xs: 320 },
            },
          }}
          sx={{
            "& .MuiModal-backdrop": {
              backgroundColor: "transparent",
            },
          }}
          open={singleSaleDrawer}
          onClose={() => {
            setSingleSaleDrawer(false);
            setSingleSaleId("");
          }}
        >
          {singleSaleLoader && <Loader />}
          {singleSaleData && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: { sm: "24px", xs: "24px 12px" },
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <ArrowLineRight
                    // onClick={() => dispatch(handleSingleAppointmentModal())}
                    color="#4A4C4F"
                    style={{ cursor: "pointer" }}
                    size={24}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "#4A4C4F",
                        fontWeight: "400",
                      }}
                    >
                      Sale
                    </Typography>
                    <Typography
                      sx={{
                        width: 170,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: "24px",
                        fontWeight: "400",
                      }}
                    >
                      {`${singleSaleData?.client?.firstName} ${singleSaleData?.client?.lastName}`}
                    </Typography>
                  </Box>
                </Box>
                {/* <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <Typography
                    sx={{
                      color: "#4E45E5",
                      fontWeight: "600",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    View Profile
                  </Typography>
                </Box> */}
              </Box>
              <Divider color="#EDECFC" />
              <Box
                sx={{
                  padding: "0px 24px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#5E6062",
                    marginBottom: "16px",
                    mt: "12px",
                  }}
                >
                  Summary
                </Typography>
                {saleSummary(singleSaleData).map((item) => {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        backgroundColor: item.bgcolor && "#F8F7FE",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "4px 16px",
                        borderRadius: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "48px",
                          justifyContent: {
                            md: "flex-start",
                            xs: "space-between",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: { xs: "92px" },
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            fontSize: "10px",
                          }}
                        >
                          <item.icon size={16} color="#4A4C4F" />
                          {item.title}
                        </Box>
                        <Box>{item.component}</Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {item.status}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Box
                sx={{
                  backgroundColor: "#FAFAFA",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                  margin: "32px 24px 20px 24px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#5E6062",
                      marginBottom: "16px",
                    }}
                  >
                    Sale Details
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {salePricingDetail(singleSaleData)?.map((item) => {
                      return (
                        <Box
                          key={item.id}
                          sx={{
                            display: "flex",
                            fontSize: "14px",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ color: "#8F9193" }}>
                            {item.label}
                          </Typography>
                          <Typography sx={{ color: "#5E6062" }}>
                            {item.value}
                          </Typography>
                        </Box>
                      );
                    })}
                    <Divider color="#DADADB" sx={{ my: "4px" }} />
                    <Box
                      sx={{
                        display: "flex",
                        fontSize: "16px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontWeight: 600 }}>Total</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        ${singleSaleData?.totalPriceWithGST}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mt: "16px" }}>
                    <EmailReceiptForm />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {isClientProfileModalOpen && (
            <Profile
              isClientProfileOpen={isClientProfileModalOpen}
              handleClose={handleCloseClientProfile}
            />
          )}
        </Drawer>
      )}
    </Grid>
  );
};

export default SalesSection;

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
