import React from "react";
import { calendarBox } from "./cardStyle";
import { Box, Typography } from "@mui/material";
import {
  Cloud,
  MapPin,
  Money,
  Note,
  Prohibit,
  SealCheck,
  ShootingStar,
  Warning,
  XCircle,
} from "@phosphor-icons/react";
export default function SpaAppointmentCard({
  color,
  backgroundColor,
  locationName,
  author,
  time,
  condition,
}) {
  const slotIcon = [
    {
      id: 1,
      icon: Money,
      tooltip: "Deposit Paid",
      condition: condition?.isDepositPaid,
    },
    {
      id: 2,
      icon: Cloud,
      tooltip: "Booked Online",
      condition: condition?.isBookedOnline,
    },
    {
      id: 3,
      icon: Warning,
      tooltip: "Contraindication",
      condition: condition?.isContraindication,
    },
    {
      id: 4,
      icon: ShootingStar,
      tooltip: "New Client",
      condition: condition?.isNewClient,
    },
    {
      id: 5,
      icon: SealCheck,
      tooltip: "Client Attended",
      condition: condition?.isClientAttended,
    },
    {
      id: 6,
      icon: XCircle,
      tooltip: "Client didn't show",
      condition: condition?.isClientNoShow,
    },
    {
      id: 7,
      icon: Note,
      tooltip: "Notes",
      condition: condition?.isNotes,
    },
    {
      id: 8,
      icon: Prohibit,
      tooltip: "Cancelled",
      condition: condition?.isAppointmentCancelled,
    },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundColor: { backgroundColor },
          borderRadius: "8px",
          padding: "8px",
          borderLeftWidth: "10px",
          borderLeftColor: { color },
          borderLeftStyle: "solid",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box sx={calendarBox.slotIconBox}>
            {slotIcon.map((item) => {
              const Slot = item.icon;
              return item.condition ? (
                <Slot key={item.id} size={16} color={color} />
              ) : null;
            })}
          </Box>

          <Box display={"flex"} gap={"2px"} alignItems={"center"}>
            <MapPin size={12} color="#8F9193" />
            <Typography sx={calendarBox.eventTitle}>{locationName}</Typography>
          </Box>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={calendarBox.title}>{author}</Typography>
          <Typography sx={calendarBox.time}>{time}</Typography>
        </Box>
      </Box>
    </>
  );
}
