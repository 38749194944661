import React from 'react';
import ResetPasswordEmailComponent from '../../../components/auth/reset-password-email';
import AuthLayout from '../../../layout/auth-layout';

const ResetPasswordEmail = () => {
  return (
    <AuthLayout heading='Check your email !' subHeading=''>
      <ResetPasswordEmailComponent />
    </AuthLayout>
  );
};

export default ResetPasswordEmail;
