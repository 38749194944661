import { Button, styled } from "@mui/material";

export const SubmitBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.main,
  color: theme.palette.white.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.dark,
  },
});

export const InsertBtn = (theme) => ({
  backgroundColor: "#EDECFC",
  color: "#4E45E5",
  paddingInline: "24px",
  paddingBlock: "10px",
  borderRadius: "8px",
  fontWeight: 500,
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    backgroundColor: "#EDECFC",
  },
});

export const templateStyle = {
  ".tox-tinymce": {
    border: "none !important",
  },
};

export const EnabledButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? "#13A10E" : "transparent",
  color: active ? "white" : "#4E45E5",
  borderRadius: "8px",
  flex: 1,
  "&:hover": {
    backgroundColor: active ? "darkgreen" : "rgba(0,0,0,0.1)",
  },
  border: "none",
  boxShadow: "none",
}));

export const DisabledButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? "#D13438" : "transparent",
  color: active ? "white" : "#4E45E5",
  borderRadius: "8px",

  flex: 1,
  "&:hover": {
    backgroundColor: active ? "darkred" : "rgba(0,0,0,0.1)",
  },
  border: "none",
  boxShadow: "none",
}));
