import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import theme from "../../../../../../theme/theme";
import { address } from "../../../../style";
import { MapPin, Plus } from "@phosphor-icons/react";
import {
  customColors,
  fs12,
  fs16,
  fw600,
  secondary,
} from "../../../../../../theme";
import NoNotifications from "../../../../../../assets/svg/no-notifications";
import FormProvider from "../../../../../hook-form/FormProvider";
import useClientAddress from "./useClientAddress";
import Loader from "../../../../../common/loader";

export default function Address() {
  const {
    addressControl,
    addressesArray,
    addNewAddress,
    addressMethods,
    onSubmitAddress,
    handleAddressSubmit,
    selectedAddressId,
    isAddressFieldReadOnly,
    handleEditAddressButtonClick,
    addressLoading,
    addressComponents,
  } = useClientAddress();

  return (
    <>
      {addressLoading ? (
        <Loader />
      ) : (
        <Grid
          xs={12}
          style={{ height: "100%", padding: "16px", borderRadius: "8px" }}
          bgcolor={theme.palette.purple10.main}
        >
          <Box style={address.flexContainer} mb={2}>
            <Box style={address.flexContainer}>
              <MapPin size={24} />
              <Typography
                fontSize={fs16}
                fontWeight={fw600}
                color={theme.palette.textGraySecondary.main}
              >
                Address
              </Typography>
            </Box>

            <Box
              sx={address.flexContainer}
              onClick={() =>
                handleEditAddressButtonClick(addressesArray.length)
              }
            >
              <Plus
                size={24}
                color={theme.palette.primary.main}
                cursor={"pointer"}
              />
              <Box
                sx={{
                  fontSize: fs16,
                  fontWeight: fw600,
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                }}
              >
                Add another Address
              </Box>
            </Box>
          </Box>
          {addressComponents.length > 0 ? (
            <>
              <FormProvider
                methods={addressMethods}
                onSubmit={handleAddressSubmit(onSubmitAddress)}
              >
                {addressComponents.map((item, index) => (
                  <>
                    <Grid
                      container
                      key={index}
                      xs={12}
                      columnGap={1}
                      rowGap={2}
                    >
                      {item.componentArray.map((childComponent, childIndex) => (
                        <>
                          <Grid
                            item
                            key={childIndex}
                            xs={12}
                            md={childComponent.gridLength}
                          >
                            <childComponent.component
                              {...childComponent.otherOptions}
                              control={addressControl}
                              readOnly={isAddressFieldReadOnly}
                            />
                          </Grid>
                        </>
                      ))}
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          mt: "40px",
                        }}
                      >
                        {selectedAddressId === item.id ||
                        (addNewAddress === item.id &&
                          !isAddressFieldReadOnly) ? (
                          <Button
                            sx={{
                              display: "flex",
                              height: "0px 24px",
                              width: "25%",

                              alignItems: "center",
                              justifyItems: "center",
                              gap: "8px",
                              borderRadius: "8px",
                              background: secondary.main,
                              color: customColors.white,
                              "&:hover": {
                                backgroundColor: secondary.main,
                              },
                            }}
                            type="submit"
                          >
                            <>
                              <Typography>Save Changes</Typography>
                            </>
                          </Button>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              height: "40px",
                              width: "25%",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "8px",
                              borderRadius: "8px",
                              background: secondary.main,
                              color: customColors.white,
                              "&:hover": {
                                backgroundColor: secondary.main,
                              },
                            }}
                            onClick={() => handleEditAddressButtonClick(item)}
                          >
                            <Typography>Edit Changes</Typography>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </>
                ))}
              </FormProvider>
            </>
          ) : (
            <>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                overflow={"hidden"}
              >
                <NoNotifications />
                <Typography sx={{ fontSize: fs16, color: "#595959" }}>
                  No Addresses Yet
                </Typography>
                <Typography sx={{ fontSize: fs12, color: "#595959" }}>
                  After creating Addresses, they will be displayed here as Not
                  Addresses.
                </Typography>
              </Box>
            </>
          )}
        </Grid>
      )}
    </>
  );
}
