import { Envelope, HandbagSimple } from "@phosphor-icons/react";
import CalendarIcon from "../../assets/svg/calendar-icon";
import ClientsIcon from "../../assets/svg/clients-icon";
import StaffManagementIcon from "../../assets/svg/staff-management-icon";
import { ROLE } from "../../enums/roles";
import { PERMISSION } from "../../enums/permissions";

export const routePermissions = {
  "/calendar": PERMISSION.VIEW_CALENDAR,
  "/clients": PERMISSION.VIEW_CLIENT,
  "/resource-management": PERMISSION.SPA_MANAGEMENT,
  "/sales": PERMISSION.VIEW_SALES,
  "/communications": PERMISSION.VIEW_COMMUNICATION,
};

export const permissionData = [
  "VIEW_CLIENT",
  "VIEW_SALES",
  "VIEW_COMMUNICATION",
  "VIEW_CALENDAR",
  "DELETE_CLIENT",
  // "CREATE_CLIENT",
  // "EDIT_CLIENT",
  // "SPA_MANAGEMENT",
];

export const sidebarData = [
  {
    label: "Calendar",
    icon: CalendarIcon,
    link: "/calendar",
    toShow: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.STAFF],
  },
  {
    label: "Clients",
    icon: ClientsIcon,
    link: "/clients",
    toShow: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.STAFF],
  },
  {
    label: "Resource-Management",
    icon: StaffManagementIcon,
    link: "/resource-management",
    toShow: [ROLE.SUPER_ADMIN, ROLE.STAFF],
  },
  {
    label: "Sales",
    icon: HandbagSimple,
    link: "/sales",
    toShow: [ROLE.SUPER_ADMIN, ROLE.STAFF],
  },
  {
    label: "Communications",
    icon: Envelope,
    link: "/communications",
    toShow: [ROLE.SUPER_ADMIN, ROLE.STAFF],
  },
];
