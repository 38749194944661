import { fs34 } from "../../theme";

export const resourceBox = {
  resourceLabel: {
    display: "flex",
    alignItems: "center",
    pb: "8px",
    fontSize: fs34,
    color: "#070A0E",
  },
};
