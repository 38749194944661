import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DotsIcon from "../../../assets/svg/dots-icon";
import { useGetQualificationsMutation } from "../../../services/qualifications";
import { useGetRolesMutation } from "../../../services/roles";
import {
  useCreateStaffMutation,
  useGetStaffsMutation,
} from "../../../services/staff";
import { setClientDetails } from "../../../store/features/client-details";
import { setImageUrl } from "../../../store/features/upload-file";
import { fs14, fw600 } from "../../../theme";
import { lightenHexColor } from "../../../utils/convertLightenHexColor";
import { nameInitialsAvatar } from "../../../utils/nameInitialsAvatar";
import { handleFilterSearch } from "../../../utils/searchFilter";
import { resourceBox } from "../resourceManagementStyle";
import {
  qualificationValidationSchema,
  validationSchema,
} from "./validationSchema";
import { toast } from "react-toastify";
import { statusDesign } from "../resource-management-data";

const useStaff = () => {
  const dispatch = useDispatch();
  const [searchStaff, setSearchStaff] = useState("");
  const [staffPopoverOpen, setStaffPopoverOpen] = useState(null);
  const [isStaffDialogOpen, setIsStaffDialogOpen] = useState(false);
  const [singleStaffId, setSingleStaffId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  // Variables for staff popover state and IDs
  const openStaffPopOver = Boolean(staffPopoverOpen);
  const staffPopOverId = openStaffPopOver ? "simple-popover" : undefined;
  const [getAllStaffs, { data: staffsData, isLoading: staffsDataLoader }] =
    useGetStaffsMutation();
  const totalRecords = staffsData?.result?.data?.totalRecords;
  const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false);
  const [isQualificationsDialogOpen, setIsQualificationsDialogOpen] =
    useState(false);
  const [staffInitialFormData, setFormInitialFormData] = useState();
  // Create Staff
  const [therapistDetails, setTherapistDetails] = useState(false);
  const [qualificationStep, setQualificationStep] = useState();
  const validationSchemaInstance = validationSchema();
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
  });
  const imageUrl = useSelector((state) => state?.uploadFile?.url);
  const [getAllRoles, { data: rolesData, isLoading: rolesDataLoader }] =
    useGetRolesMutation();
  const [isOpenAddQualificationDialog, setIsOpenAddQualificationDialog] =
    useState(false);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const isTherapist = watch("role");
  const emergencyInfo = watch("emergencyInfo");
  const currentEmployment = watch("currentEmployment");

  const onSubmit = async (values) => {
    let payload;
    payload = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      role: values?.role,
      email: values?.email,
      password: values?.password,
      mobileNumber: values?.mobileNumber,
      status: values?.status,
      roleType: "STAFF",
      currentEmployment: values?.currentEmployment,
      ...(values?.employmentStartDate && {
        employmentStartDate:
          new Date(values?.employmentStartDate).toISOString() ?? undefined,
      }),
      ...(values?.employmentEndDate && {
        employmentEndDate:
          new Date(values?.employmentEndDate).toISOString() ?? undefined,
      }),
      addresses: [
        {
          suburb: values?.suburb,
          state: values?.state,
          postalCode: Number(values?.postalCode),
          street: values?.street,
        },
      ],
      gender: values.gender === "" ? undefined : values.gender,
      profilePictureUrl: values?.profilePictureUrl,
      dateOfBirth: values?.dateOfBirth ?? undefined,
      // ...(values?.note && {
      //   note: {
      //     note: values?.note,
      //     isPinned: false,
      //     inCalendar: false,
      //   },
      // }),
      // ...(emergencyInfo && {
      //   emergencyContact: [
      //     {
      //       contactFirstName: values?.contactFirstName,
      //       contactLastName: values?.contactLastName,
      //       relationship: values?.relationship,
      //       phoneNumber: values?.contactPhoneNumber,
      //       email: values?.contactEmail,
      //     },
      //   ],
      // }),
    };
    if (imageUrl) {
      payload["profilePictureUrl"] = imageUrl;
    }
    setIsStaffDialogOpen(false);
    setIsOpenAddQualificationDialog(true);
    setFormInitialFormData(payload);
    setQualificationStep(true);
  };

  const handleCloseForm = () => {
    setIsStaffDialogOpen(false);
    reset();
    setQualificationStep(false);
  };

  const handleQualificationCloseDialog = () => {
    setIsOpenAddQualificationDialog(false);
  };

  useEffect(() => {
    dispatch(setImageUrl({ url: "" }));
    getAllRoles();
  }, []);

  const staffTableHeader = [
    {
      name: "firstName",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;
          const lastName = rowData[tableMeta?.rowIndex]?.lastName;
          const firstName = rowData[tableMeta?.rowIndex]?.firstName;
          const imgSource = rowData[tableMeta?.rowIndex]?.profilePictureUrl;
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSingleStaffId(rowData[tableMeta?.rowIndex]?._id);
                dispatch(setClientDetails(rowData[tableMeta?.rowIndex]));
                handleOpenProfileOpen();
              }}
            >
              {imgSource ? (
                <Avatar
                  sx={{ width: "48px", height: "48px", cursor: "pointer" }}
                  src={imgSource}
                  onClick={() => {}}
                  variant="rounded"
                />
              ) : (
                <Avatar
                  {...nameInitialsAvatar(`${firstName} ${lastName}`)}
                  onClick={() => {}}
                  variant="rounded"
                />
              )}
              <Typography
                sx={{
                  color: "#070A0E",
                  fontSize: fs14,
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
                onClick={() => {}}
              >
                {value} {lastName}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "roleDetails",
      label: "Role",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: lightenHexColor(
                    value?.color ?? "#000000",
                    0.9
                  ),
                  color: value?.color ?? "#000000",
                  ...resourceBox.statusInfo,
                }}
              >
                {value?.name ?? "---"}
              </Box>
            </Box>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                backgroundColor: statusDesign[value ? value : "DRAFT"]?.bgColor,
                color: statusDesign[value ? value : "DRAFT"]?.color,
                ...resourceBox.statusInfo,
              }}
            >
              {value ? value : "---"}
            </Box>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: fs14,
                  fontWeight: "400",
                  cursor: "pointer",
                }}
              >
                {value ?? "---"}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: fs14,
                  fontWeight: "400",
                  cursor: "pointer",
                }}
              >
                {value ?? "---"}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                setStaffPopoverOpen(e.currentTarget);
                dispatch(setClientDetails(rowData[tableMeta?.rowIndex]));
                setSingleStaffId(rowData[tableMeta?.rowIndex]?._id);
              }}
            >
              <DotsIcon />
            </Box>
          );
        },
      },
    },
  ];

  const handleSearchStaff = () => {
    let attributeArray = [];
    if (searchStaff !== "") {
      if (isNaN(searchStaff))
        attributeArray = [
          "firstName",
          "lastName",
          "roles",
          "email",
          "mobileNumber",
        ];
      else {
        attributeArray = [
          "firstName",
          "lastName",
          "roles",
          "email",
          "mobileNumber",
        ];
      }

      const searchPayload = handleFilterSearch(
        searchStaff,
        attributeArray,
        "search"
      );
      getAllStaffs({ ...searchPayload, roleType: "STAFF" });
    } else getAllStaffs({ roleType: "STAFF" });
  };

  useEffect(() => {
    handleSearchStaff();
  }, [searchStaff]);

  const handleNewStaff = () => {
    setIsStaffDialogOpen(true);
  };

  const handleOpenProfileOpen = () => {
    setIsProfileOpen(true);
  };
  const handleCloseProfileOpen = () => {
    setIsProfileOpen(false);
    getAllStaffs({ roleType: "STAFF" });
  };

  useEffect(() => {
    getAllStaffs({ roleType: "STAFF" });
  }, []);

  useEffect(() => {
    getAllStaffs({ page: currentPage, pageSize: 10, roleType: "STAFF" });
  }, [currentPage]);

  // Qualification
  const [searchQualification, setSearchQualification] = useState("");
  const qualificationValidationSchemaInstance = qualificationValidationSchema();
  const QualificationMethods = useForm({
    resolver: yupResolver(qualificationValidationSchemaInstance),
    mode: "onChange",
    defaultValues: {
      qualification: [],
    },
  });
  const [createStaff, { isLoading: createStaffLoader }] =
    useCreateStaffMutation();
  const [
    getAllQualifications,
    { data: qualificationsData, isLoading: qualificationsDataLoader },
  ] = useGetQualificationsMutation();

  const {
    handleSubmit: qualificationHandleSubmit,
    control: qualificationControl,
    reset: qualificationReset,
    formState: { errors: qualificationError },
  } = QualificationMethods;

  const qualificationOnSubmit = async (values) => {
    let payload = staffInitialFormData;
    payload["qualification"] = values?.qualification;
    let response;
    response = await createStaff(payload);
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      reset();
      qualificationReset();
      setIsOpenAddQualificationDialog(false);
      getAllStaffs({ roleType: "STAFF" });
      return;
    }
  };

  const handleQualificationCloseForm = () => {
    setIsOpenAddQualificationDialog(false);
    qualificationReset();
  };

  const handleStaffCloseDialog = () => {
    setIsStaffDialogOpen(false);
    reset();
    setQualificationStep(false);
  };

  const handleSearchQualification = () => {
    let attributeArray = [];
    if (searchQualification !== "") {
      if (isNaN(searchQualification)) attributeArray = ["name", "category"];
      else {
        attributeArray = ["name", "category"];
      }

      const searchPayload = handleFilterSearch(
        searchQualification,
        attributeArray,
        "search"
      );
      getAllQualifications({ ...searchPayload });
    } else getAllQualifications({});
  };

  useEffect(() => {
    handleSearchQualification();
  }, [searchQualification]);

  useEffect(() => {
    getAllQualifications();
  }, []);

  return {
    searchStaff,
    setSearchStaff,
    singleStaffId,
    setSingleStaffId,
    staffTableHeader,
    staffsData,
    staffsDataLoader,
    getAllStaffs,
    currentPage,
    setCurrentPage,
    totalRecords,
    isProfileOpen,
    handleOpenProfileOpen,
    handleCloseProfileOpen,
    isStaffDialogOpen,
    setIsStaffDialogOpen,
    staffPopoverOpen,
    setStaffPopoverOpen,
    staffPopOverId,
    openStaffPopOver,
    handleNewStaff,
    isRolesDialogOpen,
    setIsRolesDialogOpen,
    isQualificationsDialogOpen,
    setIsQualificationsDialogOpen,

    // Add Qualification
    handleQualificationCloseForm,
    qualificationOnSubmit,
    qualificationError,
    qualificationReset,
    qualificationControl,
    qualificationHandleSubmit,
    qualificationsDataLoader,
    qualificationsData,
    searchQualification,
    setSearchQualification,
    QualificationMethods,
    isOpenAddQualificationDialog,
    setIsOpenAddQualificationDialog,
    handleQualificationCloseDialog,

    // Create Staff
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    createStaffLoader,
    isTherapist,
    emergencyInfo,
    rolesData,
    handleStaffCloseDialog,
    getAllRoles,
    qualificationStep,
    currentEmployment,
  };
};

export default useStaff;
