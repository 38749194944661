import { Box, Typography } from "@mui/material";
import { Warning } from "@phosphor-icons/react";
import { Editor } from "@tinymce/tinymce-react";
import { Controller } from "react-hook-form";

const EditorField = ({ control, setEditorInstance }) => {
  const allowedValues = ["FIRSTNAME", "LASTNAME", "EMAIL"];
  return (
    <Box sx={{ height: "40vh" }}>
      <Controller
        name="message"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box>
            <Box
              sx={{
                border: error ? "1px solid #FFAA33" : "1px solid #ced4da",
                borderRadius: "4px",
                overflow: "hidden",
                height: "40vh",
                "& .tox-tinymce": {
                  border: "none",
                  "& .tox-edit-area::before": { border: "none" },
                },
              }}
            >
              <Editor
                apiKey="skb6117e0zohgmjyg8fws6ytucbykv0bcevzf4ntqhlwtt2f"
                value={value}
                onInit={(evt, editor) => {
                  editor.setContent(value || "");
                  setEditorInstance(editor);
                }}
                onEditorChange={(content, editor) => {
                  onChange(content);
                }}
                init={{
                  height: "100%",
                  menubar: false,
                  statusbar: false,
                  plugins: [
                    "advlist autolink charmap print preview anchor",
                    "searchreplace visualblocks fullscreen",
                    "insertdatetime media table paste help wordcount styleButton | styles",
                    "hr",
                    "image",
                    "bullist",
                    "lists",
                    "code",
                    "link",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor forecolor underline | align code blockquote hr " +
                    "bullist numlist outdent indent | link image " +
                    "styles styleButton",
                  formats: {
                    custom_highlight: {
                      inline: "span",
                      classes: "highlighted",
                    },
                  },
                  content_style:
                    ".highlighted { background-color: #E9FBF6; color: #1AA37A; }",
                  setup: (editor) => {
                    editor.on("keydown", (e) => {
                      // Check the position and context, possibly refining when to remove formatting
                      if (!["Shift", "Control", "Alt"].includes(e.key)) {
                        // Possibly check for more specific conditions here if needed
                        editor.formatter.remove("custom_highlight");
                      }
                    });
                    const onAction = (autocompleteApi, rng, value) => {
                      editor.selection.setRng(rng);
                      const isAllowedValue = allowedValues.includes(
                        value.toUpperCase()
                      ); // Check if the value is in the allowed list.

                      if (isAllowedValue) {
                        // Insert the content and apply formatting
                        editor.undoManager.transact(() => {
                          // Use undoManager for better undo/redo management
                          editor.selection.setContent(
                            `<span class="highlighted">@${value}</span>`
                          );
                        });
                      } else {
                        // Just insert the content without any formatting
                        editor.selection.setContent(`@${value}`);
                      }
                      autocompleteApi.hide();
                    };

                    const getMatchedChars = (pattern) => {
                      return [
                        { value: "FIRSTNAME" },
                        { value: "LASTNAME" },
                        { value: "EMAIL" },
                      ].filter(
                        (char) =>
                          char.value
                            ?.toLocaleLowerCase()
                            .indexOf(pattern?.toLocaleLowerCase()) !== -1
                      );
                    };

                    /**
                     * An autocompleter that allows you to insert special characters.
                     * Items are built using the CardMenuItem.
                     */
                    editor.ui.registry.addAutocompleter(
                      "specialchars_cardmenuitems",
                      {
                        trigger: "@",
                        minChars: 1,
                        columns: 1,
                        highlightOn: ["char_name"],
                        onAction: onAction,
                        fetch: (pattern) => {
                          return new Promise((resolve) => {
                            const results = getMatchedChars(pattern).map(
                              (char) => ({
                                type: "cardmenuitem",
                                value: char.value,
                                items: [
                                  {
                                    type: "cardcontainer",
                                    direction: "vertical",
                                    items: [
                                      {
                                        type: "cardtext",
                                        text: char.value,
                                      },
                                    ],
                                  },
                                ],
                              })
                            );
                            resolve(results);
                          });
                        },
                      }
                    );
                  },
                }}
              />
            </Box>
            {error && (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  fontSize: "12px",
                  color: "#FFAA33",
                }}
              >
                <Warning size={16} weight="fill" /> {error.message}
              </Typography>
            )}
          </Box>
        )}
      />
    </Box>
  );
};

export default EditorField;
