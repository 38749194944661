import { CallBell, Clock, User, UserFocus } from "@phosphor-icons/react";
import SPAInputField from "../../hook-form/SPAInputField";
import SPASelectField from "../../hook-form/SPASelectField";
import ProfileImageUploader from "../../profile-image-upload";

//popover mock data
export const servicesPopOverData = (setPopoverOpen, handleOpenProfileOpen) => [
  {
    id: "1",
    label: "View Profile",
    icon: User,
    onClick: () => {
      setPopoverOpen(null);
      handleOpenProfileOpen();
    },
  },
];

export const treatmentGeneralInformation = (isView, serviceCategoryData) => {
  let initialFormData = [
    {
      title: "General Information",
      icon: CallBell,
      rowChildren: [
        {
          gridLength: 7.3,
          colChildren: [
            {
              gridLength: 12,
              otherOptions: {
                fullWidth: true,
                name: "name",
                label: "Treatment Name",
                placeholder: "i.e.: Massage",
              },
              component: SPAInputField,
            },
            {
              gridLength: 12,
              otherOptions: {
                fullWidth: true,
                name: "hours",
                label: "Duration",
                options: [
                  { label: "1 hour", value: 1 },
                  { label: "2 hours", value: 2 },
                  { label: "3 hours", value: 3 },
                  { label: "4 hours", value: 4 },
                ],
                placeholder: "Select Duration",
              },
              component: SPASelectField,
            },
          ],
        },
        {
          gridLength: 4.7,
          colChildren: [
            {
              colTitle: "Treatment Picture",
              isRequired: true,
              otherOptions: {
                width: "100px",
                height: "100px",
                uploadBtnText: "upload",
                name: "profilePictureUrl",
                profileUpload: true,
                isRequired: true,
              },
              component: ProfileImageUploader,
            },
          ],
        },
        {
          gridLength: 12,
          colChildren: [
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "category",
                label: "Category",
                options:
                  serviceCategoryData &&
                  serviceCategoryData?.map((item) => {
                    return { label: item.name, value: item.name, id: item._id };
                  }),
                placeholder: "Select Category",
              },
              component: SPASelectField,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "price",
                label: "Price Per Session",
                placeholder: "i.e.: $5.00",
              },
              component: SPAInputField,
            },
            {
              key: "description",
              gridLength: 12,
              otherOptions: {
                name: "description",
                label: "Description",
                labelHelperText: "(Optional)",
                type: "textarea",
                placeholder: "Add the treatment description here",
              },
              component: SPAInputField,
            },
          ],
        },
      ],
    },
  ];
  let finalFormData = isView ? [initialFormData[1]] : initialFormData;
  return finalFormData;
};

export const productCategoryTabs = [
  { label: "All", value: "ALL", id: "ALL" },
  { label: "Skin Care", value: "SKIN_CARE", id: "SKIN_CARE" },
  { label: "Hair Care", value: "HAIR_CARE", id: "HAIR_CARE" },
  { label: "Nails Art", value: "NAILS_ART", id: "NAILS_ART" },
];
