import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetServiceCategoriesMutation } from "../../../../../../../../services/service-category";
import { useUpdateSingleServiceMutation } from "../../../../../../../../services/services";
import { treatmentValidationSchema } from "../../../../../treatmentValidationSchema";
import { useSelector } from "react-redux";
import { setImageUrl } from "../../../../../../../../store/features/upload-file";
import { treatmentOverviewValidationSchema } from "../../validationSchema";

const useServiceOverview = ({ singleServiceData, singleServiceRefetching }) => {
  const [isOpenDisableConfirmDialog, setIsOpenDisableConfirmDialog] =
    useState(false);
  const [isOpenNotDisableDialog, setIsOpenNotDisableConfirmDialog] =
    useState(false);
  const imageUrl = useSelector((state) => state?.uploadFile?.url);

  const methods = useForm({
    resolver: yupResolver(treatmentOverviewValidationSchema()),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(true);

  const toggleSwitch = () => {
    if (enabled === true) {
      setIsOpenDisableConfirmDialog(true);
    } else {
      setEnabled(true);
    }
  };

  const handleDisableConfirm = async () => {
    // setEnabled(false);
    const response = await updateSingleService({
      id: singleServiceData?._id,
      payload: {
        clientInteraction: enabled === false ? "ENABLED" : "DISABLED",
      },
    });
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      singleServiceRefetching();
      setEnabled(
        response?.data?.result?.data?.clientInteraction === "ENABLED"
          ? true
          : response?.data?.result?.data?.clientInteraction === "DISABLED"
          ? false
          : null
      );
      setIsOpenDisableConfirmDialog(false);
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const [updateSingleService, { isLoading: updateSingleServiceLoader }] =
    useUpdateSingleServiceMutation();

  const [
    getAllServiceCategory,
    { data: serviceCategoryData, isLoading: serviceCategoryDataLoader },
  ] = useGetServiceCategoriesMutation();

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      clientInteraction: enabled === true ? "ENABLED" : "DISABLED",
    };
    if (imageUrl) {
      payload["profilePictureUrl"] = imageUrl;
    }
    const response = await updateSingleService({
      id: singleServiceData?._id,
      payload: payload,
    });
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      if (
        response?.data?.message ===
        "Cannot disable service as it is being used in appointments, coupons or articles."
      ) {
        setIsOpenDisableConfirmDialog(true);
        return;
      }
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      singleServiceRefetching();
    }
  };

  const handleInitialValues = () => {
    if (!singleServiceData) return;
    setValue("name", singleServiceData?.name);
    setValue("hours", singleServiceData?.hours);
    setValue("price", singleServiceData?.price);
    setValue("description", singleServiceData?.description);
    setValue("category", singleServiceData?.category?._id);
    setValue(
      "beforeBufferTimeMinutes",
      singleServiceData?.beforeBufferTimeMinutes
    );
    setValue(
      "afterBufferTimeMinutes",
      singleServiceData?.afterBufferTimeMinutes
    );
    setValue(
      "afterBufferTimeDescription",
      singleServiceData?.afterBufferTimeDescription
    );
    setValue(
      "beforeBufferTimeDescription",
      singleServiceData?.beforeBufferTimeDescription
    );
    setEnabled(
      singleServiceData?.clientInteraction === "ENABLED" ? true : false
    );
    dispatch(setImageUrl({ url: singleServiceData?.profilePictureUrl }));
  };

  useEffect(() => {
    if (singleServiceData) {
      handleInitialValues();
    }
  }, [singleServiceData]);

  useEffect(() => {
    getAllServiceCategory();
  }, []);

  return {
    methods,
    control,
    handleSubmit,
    onSubmit,
    serviceCategoryData,
    serviceCategoryDataLoader,
    navigate,
    dispatch,
    toggleSwitch,
    enabled,
    handleDisableConfirm,
    setIsOpenDisableConfirmDialog,
    isOpenDisableConfirmDialog,
    isOpenNotDisableDialog,
    setIsOpenNotDisableConfirmDialog,
  };
};

export default useServiceOverview;
