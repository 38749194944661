export const TreatmentStyle = {
  addQualificationHeaderMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "8px",
    background: "#F8F7FE",
    p: "16px",
    gap: "8px",
  },
};
