import { customColors, fs34, fw600, secondary } from "../../theme";

export const HeadingContent =(theme)=> ({
  fontFamily: "Open Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  color:theme.palette.textPrimary900.main,
});



export const calendarBox = {
  calendar: {
    ".rbc-day-slot .rbc-event": {
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "8px",
    },
    ".rbc-event-label": {
      display: "none",
    },
    ".rbc-event": {
      zIndex: "999 !important",
      // left: "0% !important",
      minWidth: "50% !important",
    },
    ".rbc-event:focus": {
      outline: "none",
    },
    ".rbc-day-slot": {
      cursor: "pointer",
    },
    ".rbc-timeslot-group ": {
      cursor: "pointer",
    },
    ".rbc-time-view": {
      border: "none",
    },
    ".rbc-time-header-content": {
      border: "none",
    },
    ".rbc-header": {
      border: "none",
    },
    ".rbc-time-header-content > .rbc-row.rbc-row-resource": {
      border: "none",
    },
    ".rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter":
      {
        border: "none",
      },
    ".rbc-timeslot-group": {
      border: "none",
      flexFlow: "column nowrap",
      display: "flex",
      alignItems: "flex-start",
      minHeight: "53px",
      paddingRight: "14px",
    },
    ".rbc-time-view .rbc-allday-cell": {
      display: "none",
    },
    ".rbc-time-content > * + * > *": {
      border: "none",
      borderBottom: "1px solid #F0F0F1",
    },
    ".rbc-time-content": {
      border: "none",
      maxHeight: "75vh",
    },
    ".rbc-time-view-resources .rbc-time-header": {
      height: "60px",
      display: "flex",
      alignItems: "flex-start",
      marginTop: "15px",
      border: "none",
    },
    ".rbc-label": {
      padding: "13px",
      color: "#8f9193",
      fontSize: "16px",
      fontWeight: "600",
    },
    ".rbc-today": {
      background: "transparent",
    },
    ".rbc-time-header-gutter": {
      padding: "0 !important",
    },
  },
  drawerStyle: {
    "& .MuiModal-backdrop": {
      backgroundColor: "transparent",
    },
  },
  appointmentDrawer: { display: "flex", flexDirection: "column", gap: "4px" },
  appointmentDrawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: { sm: "24px", xs: "24px 12px" },
  },
  appointmentDrawerTitle: { display: "flex", alignItems: "center", gap: "4px" },
  appointmentTitleBox: { display: "flex", flexDirection: "column" },
  appointmentType: { fontSize: "10px", color: "#4A4C4F", fontWeight: "400" },
  appointmentClient: { fontSize: "24px", fontWeight: "400" },
  addSalesBtn: { display: "flex", alignItems: "center", gap: "4px" },
  appointmentDetailBox: {
    margin: {
      sm: "24px",
      xs: "24px 16px",
    },
  },
  appointmentCard: {
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
  },
  appointmentCardBox: {
    margin: {
      sm: "16px 24px",
      xs: "10px 16px",
    },
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  appointmentCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  appointmentCardTitle: { display: "flex", gap: "4px" },
  appointmentTitle: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#4A4C4F",
  },
  appointmentClientBox: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color: "#4E45E5",
    fontSize: "14px",
    fontWeight: "600",
  },
  appointmentServiceBox: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color: "#5E6062",
    fontSize: "14px",
    fontWeight: "600",
  },
  appointmentPricingHeader: {
    backgroundColor: "#FAFAFA",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    margin: "32px 24px 20px 24px",
  },
  appointmentPriceBox: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  appointmentSummaryBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4px 16px",
    borderRadius: "8px",
  },
  summaryLabel: { display: "flex", alignItems: "center", gap: "4px" },
  appointmentStatusLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  appointmentSummary: {
    fontSize: "20px",
    color: "#5E6062",
    marginBottom: "16px",
  },
  priceDetail: {
    fontSize: "20px",
    color: "#5E6062",
    marginBottom: "16px",
  },
  pricingDetailBox: { display: "flex", flexDirection: "column", gap: "8px" },
  priceLabel: {
    display: "flex",
    fontSize: "14px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  priceTotal: {
    display: "flex",
    fontSize: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  viewNoteBtn: {
    fontSize: "14px",
    color: "#4E45E5",
    display: "flex",
    gap: "4px",
    textTransform: "capitalize",
  },
  appointmentDesc: {
    fontSize: "14px",
    fontWeight: "400",
    textWrap: "wrap",
    marginTop: "9px",
    color: "#4A4C4F",
  },
  appointmentCardFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "16px",
    marginTop: "8px",
  },
  appointmentEditBtn: {
    fontSize: "14px",
    color: "#4E45E5",
    display: "flex",
    gap: "4px",
    textTransform: "capitalize",
  },
  appointmentBtnBox: {
    display: "flex !important",
    gap: "4px",
    alignItems: "flex-end",
  },
  appointmentDate: { fontSize: "12px", color: "#4A4C4F" },
  appointmentStatus: { fontSize: "10px !important", color: "#8F9193" },
  summaryBox: {
    padding: "0px 24px",
  },
  drawerPaperProps: {
    whiteSpace: "nowrap",
    background: "#FFFFFF",
    minHeight: "100vh",
    boxShadow:
      "3px 2px 8px 0px rgba(78, 69, 229, 0.05), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -66px 62px 25px 0px rgba(78, 69, 229, 0.00);",
    boxSizing: "border-box",
    width: { sm: 447, xs: 320 },
  },
  slotDetailPopover: {
    display: "flex",
    // pointerEvents: "none",
  },
  slotDetailBox: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  slotDetailsStatus: {
    display: "flex",
    justifyContent: "space-between",
  },
  slotDetailsName: { fontSize: "20px", color: "#5E6062" },
  slotDetailsStatusInfo: {
    p: "4px 8px",
    width: "fit-content",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  slotDetailsDuration: { display: "flex", justifyContent: "space-between" },
  slotDetailsTime: { display: "flex", alignItems: "center", gap: "2px" },
  slotDetailsLabel: {
    backgroundColor: "#F8F7FE",
    p: "4px 8px",
    color: "#5E6062",
    width: "fit-content",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontSize: "10px",
  },
  slotDetailsValue: { fontSize: "14px", color: "#5E6062" },
  caretIcon: {
    width: "fit-content",
    minWidth: "fit-content",
    border: "1px solid #F0F0F1",
    borderRadius: "8px",
    py: "8px",
  },
  currentDate: { color: "#070A0E", fontSize: "16px", fontWeight: "600" },
  mobileDatePickerStyle: {
    paddingBlock: "0 !important",
    flexBasis: "100%",
    width: "220px",
    display: "flex",
    alignItems: "center",
    "& div": {
      border: `1px solid #F0F0F1 !important`,
      outline: "none !important",
      "&:hover": {
        border: `1px solid #F0F0F1 !important`,
        outline: "none !important",
      },
    },
    "& input": {
      marginTop: "0",
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "32px",
      borderRadius: "6px",
      color: "#070A0E !important",
      fontWeight: "600 !important",
    },
    "& fieldset": {
      border: `none !important`,
      outline: "none !important",
      "&:hover": {
        border: `none !important`,
        outline: "none !important",
      },
    },
  },
  dateBox: {
    display: "flex",
    alignItems: "center",
    pb: "8px",
    gap: 2,
    justifyContent: "center",
  },
  calendarLabel: { fontSize: fs34, color: "#070A0E" },
  selectionGrid: {
    display: "flex",
    background: "#EDECFC",
    p: "2.5px 4px",
    borderRadius: "8px",
  },
  selectionBox: {
    display: "flex",
    alignItems: "flex-start",
    gap: "16px",
    width: "100%",
  },
  calendarHeaderMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  calendarBtnView: { display: "flex", alignItems: "center", gap: "16px" },
  busyBtn: {
    height: "48px",
    fontWeight: fw600,
    background: "#EDECFC",
    display: "flex",
    color: secondary.main,
    gap: "8px",
    alignItems: "center",
    borderRadius: "8px",
    "&:hover": { background: "#EDECFC" },
  },
  appointmentBtn: {
    height: "48px",
    fontWeight: fw600,
    background: "#4E45E5",
    display: "flex",
    color: customColors.white,
    gap: "8px",
    alignItems: "center",
    borderRadius: "8px",
    "&:hover": { background: "#4E45E5" },
  },
  tabGrid: {
    width: "fit-content",
    minWidth: "fit-content",
    display: "flex",
    background: "#EDECFC",
    p: "4px",
    borderRadius: "8px",
  },
  calendarHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  eventHandleBox: {
    height: "100%",
    borderRadius: "8px",
    p: "8px 4px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  slotIconBox: { display: "flex", alignItems: "center", gap: "4px" },
  eventTitle: { fontWeight: "600", fontSize: "12px", color: "#5E6062" },
  eventDesc: {
    fontWeight: "400",
    fontSize: "10px",
    color: "#5E6062",
    textTransform: "uppercase",
  },
  eventTag: {
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    fontSize: "10px",
    color: "#8F9193",
    textTransform: "uppercase",
    marginTop: "20px"

  },
  dateSlot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dateStart: { fontWeight: "400", fontSize: "12px", color: "#5E6062" },
  dateRange: { fontWeight: "400", fontSize: "10px", color: "#8F9193" },
  resourceBox: { display: "flex", flexDirection: "column" },
  resourceTitle: {
    color: "#8F9193",
    fontSize: "10px",
    fontWeight: "400",
    textTransform: "uppercase",
  },
  resourceContent: {
    color: "#5E6062",
    fontSize: "14px",
    fontWeight: "600",
  },
  gridView: { borderRadius: "8px", cursor: "pointer", p: "8px 16px" },
};