import React from "react";
import CalendarSection from "../../components/calendar";
/**
 * Calendar component.
 * Renders the Calendar section page.
 *
 * @returns {JSX.Element} JSX code for rendering the Calendar component.
 */
const Calendar = () => {
  return <CalendarSection />;
};
export default Calendar;
