import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useResetPasswordMutation } from "../../../../services/staff";
const validationSchema = yup.object().shape({
  currentPassword: yup.string().required("Current Password is required"),
  newPassword: yup.string().required("New Password is required"),
  confirmedPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required(),
});

const useLogin = () => {
  const [responseError, setResponseError] = useState(null);
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const navigate = useNavigate();
  const [resetPassword] = useResetPasswordMutation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = async (values) => {
    const response = await resetPassword({ payload: values });

    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      return;
    }
  };

  return {
    methods,
    handleSubmit,
    onSubmit,
    control,
    errors,
    responseError,
  };
};

export default useLogin;
