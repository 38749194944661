import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetRolesMutation } from "../../../../../../services/roles";
import {
  useGetStaffsMutation,
  useUpdateSingleStaffMutation,
} from "../../../../../../services/staff";
import { setImageUrl } from "../../../../../../store/features/upload-file";
import { validationProfileSchema } from "./profileSchemaValidation";

const useStaffOverview = ({ singleStaffData, singleStaffRefetching }) => {
  const imageUrl = useSelector((state) => state?.uploadFile?.url);
  const methods = useForm({ resolver: yupResolver(validationProfileSchema) });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods;
  const [getAllStaffs, { isLoading: staffsDataLoader }] =
    useGetStaffsMutation();
  const [updateSingleStaff, { isLoading: updatedLoader }] =
    useUpdateSingleStaffMutation();
  const [getAllRoles, { data: rolesData, isLoading: rolesDataLoader }] =
    useGetRolesMutation();

  const dispatch = useDispatch();
  const [staffDetailApi, setStaffDetailApi] = useState(null);

  const onSubmit = async (values) => {

    let payLoad = {
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender === "" ? undefined : values.gender,
      email: values.email,
      mobileNumber: values.mobileNumber,
      telephone: values.telephone,
      profilePictureUrl: imageUrl ? imageUrl : "",
      // role: values.role,
      dateOfBirth: values?.dateOfBirth ?? undefined,
      ...(values?.note && {
        note: {
          note: values?.note,
          isPinned: false,
          inCalendar: false,
        },
      }),
    };

    await updateSingleStaff({
      id: singleStaffData?._id,
      payload: payLoad,
    })
      .unwrap()
      .then((response) => {
        if (response.status) {
          toast.success(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.data.error);
      });
    singleStaffRefetching();
    getAllStaffs({ roleType: "STAFF" });
  };

  const handleInitialValues = () => {
    if (!singleStaffData) return;
    const user = singleStaffData || {};
    setStaffDetailApi(user);
    Object.entries(user).forEach(([key, value]) => {
      setValue(key, value);
      if (key === "profilePictureUrl" && !value.includes("example")) {
        dispatch(setImageUrl({ url: value }));
      } else if (key === "profilePictureUrl" && value.includes("example"))
        dispatch(setImageUrl({ url: "" }));
    });
    setValue("note", singleStaffData?.notes[0]?.note);
  };

  useEffect(() => {
    if (singleStaffData) {
      handleInitialValues();
      if (singleStaffData?.role === "SUPER_ADMIN") {
        setValue("role", "SUPER_ADMIN");
      } else {
        setValue("role", singleStaffData?.roleDetails?._id);
        // setValue("role", singleStaffData?.role);
      }
    }
  }, [singleStaffData]);

  useEffect(() => {
    getAllRoles();
  }, []);

  return {
    methods,
    control,
    handleSubmit,
    onSubmit,
    staffDetailApi,
    rolesData,
  };
};

export default useStaffOverview;
