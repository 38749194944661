import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { Circle, Lightning } from "@phosphor-icons/react";
import React from "react";
import SPADialogue from "../../../common/spa-dialogue/index.jsx";
import SPASwitch from "../../../common/spa-switch/index.jsx";
import SPAInputField from "../../../hook-form/SPAInputField.jsx";
import { newRoleFormData } from "./new-role-form-data.jsx";
import useNewRole from "./useNewRole.js";

export default function NewRoleForm({
  isNewRoleOpen,
  handleClose,
  singleRoleId,
  setIsOpenSuccessDialog,
  getAllRoles,
  setSingleRoleId,
}) {
  const {
    control,
    methods,
    handleSubmit,
    onSubmit,
    handleCloseForm,
    singleRoleLoader,
    createRoleLoader,
    updateRoleLoader,
    permissionsData,
  } = useNewRole(
    handleClose,
    singleRoleId,
    setIsOpenSuccessDialog,
    getAllRoles,
    setSingleRoleId
  );

  return (
    <>
      {singleRoleLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={singleRoleLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!singleRoleLoader && (
        <SPADialogue
          open={isNewRoleOpen}
          handleClose={handleCloseForm}
          dialogTitle={singleRoleId ? "Edit Role" : `Create Role`}
          cancelText="Cancel"
          submitText={singleRoleId ? "Save Changes" : "Create Role"}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          methods={methods}
          loading={createRoleLoader || updateRoleLoader}
        >
          <Box
            sx={{
              width: "688px",
              display: "flex",
              flexDirection: "column",
              gap: "0px",
            }}
          >
            <Grid container spacing={1}>
              {newRoleFormData().map((item, index) => {
                return (
                  <Grid item lg={item.gridLength} xs={12} key={index}>
                    <item.component
                      methods={methods}
                      {...item.otherOptions}
                      control={control}
                    />
                  </Grid>
                );
              })}
              <Grid
                item
                lg={12}
                xs={12}
                sx={{
                  bgcolor: "#F8F7FE",
                  borderRadius: "8px",
                  mt: "16px",
                  p: "16px",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    color: "#4A4C4F",
                    fontWeight: "600",
                  }}
                >
                  <Lightning size={16} color="#5E6062" /> Permissions
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                    mt: "8px",
                  }}
                >
                  {permissionsData?.map((item) => {
                    return (
                      <Box
                        key={item.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#5E6062", fontSize: "14px" }}>
                          {item.label}
                        </Typography>{" "}
                        <SPASwitch name={item.value} control={control} />
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </SPADialogue>
      )}
    </>
  );
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
