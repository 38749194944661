import React from "react";

import {  secondary, customColors } from "../../../../theme";
import {
  appointmentNotificationTabsArray,
} from "../../notification-data";
import { Box, Typography } from "@mui/material";
import { notificationStyle } from "../../style";

export default function CalendarNotifcationTab({setCurrentCalendarTabView, currentCalendarTabView}) {
  return (
    <Box sx={notificationStyle.tabGrid}>
      {appointmentNotificationTabsArray.map((item) => {
        return (
          <Box
            key={item?.label}
            onClick={() => setCurrentCalendarTabView(item.value)}
            sx={{
              background:
                currentCalendarTabView === item.value ? secondary.main : "",
              color:
                currentCalendarTabView === item.value
                  ? customColors.danger
                  : secondary.darkest,
              ...notificationStyle.gridView,
            }}
          >
            <Typography
              sx={{
                color:
                  currentCalendarTabView === item.value
                    ? customColors.white
                    : secondary.main,
                ...notificationStyle.gridLabel,
              }}
            >
              {item.title}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
