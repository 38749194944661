import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Plus } from "@phosphor-icons/react";
import dayjs from "dayjs";
import React from "react";
import NoNotifications from "../../../../assets/svg/no-notifications";
import { secondary } from "../../../../theme";
import SPATabs from "../../../common/spa-tabs";
import SPATextField from "../../../common/spa-textField";
import { onboardingTabsArray } from "./onboardData";
import useOnboard from "./useOnboard";
import { CreateNewOnboarding } from "./newOnboarding/CreateNewOnboarding";
import { ViewOnboarding } from "./viewOnboarding/ViewOnboarding";

const Onboarding = () => {
  const {
    methods,
    control,
    handleSubmit,
    onSubmit,
    data,
    currentIndex,
    onboardId,
    handleCreateOnBoard,
    handleSelectOnBoard,
    showCreateOnboarding,
    onboardingArray,
    singleOnboardingData,
    activeHistoryTab,
    handleTabSelection,
    getAllOnboardingsLoading,
    UpdateSingleOnboardingLoading,
    createOnboardingLoading,
    setSearchOnboarding,
    getSingleOnboardingLoading,
    getOrdinalSuffix,
    handleSortPositionHandling,
    isOpenCreationDialogue,
    setIsOpenCreationDialogue,

    enabled,
    toggleSwitch,
    handleOpenOnboardingDialogue,
    isOpenEditOnboardingDialogue,
    handleCloseOnboardingDialogue,
    handleSort,
    dragItem,
    dragOverItem,
    isOpenDisabledDialogue,
    handleDisableConfirm,
    setIsOpenDisabledDialogue,
    sortedOnboardingArray,
    handleEnableNow,
    handleEnableLater,
  } = useOnboard();

  return (
    <>
      {(getAllOnboardingsLoading ||
        createOnboardingLoading ||
        UpdateSingleOnboardingLoading ||
        getSingleOnboardingLoading) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            getAllOnboardingsLoading ||
            createOnboardingLoading ||
            UpdateSingleOnboardingLoading ||
            getSingleOnboardingLoading
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box>
        <Grid container>
          <Grid
            xs={11.75}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              marginBottom: "16px",
              // gap: "16px",
            }}
          >
            <Button
              disableElevation
              variant="contained"
              sx={{
                background: secondary.main,
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                height: "48px",
                "&:hover": { background: secondary.main },
              }}
              onClick={handleCreateOnBoard}
            >
              <Plus size={24} color="currentColor" />
              Create ONBOARDING
            </Button>

            <Box>
              <SPATextField
                placeholder="Search"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "48px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingLeft: "10px", cursor: "pointer" }}
                      onClick={() => {}}
                    ></InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchOnboarding(event?.target?.value);
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container columnGap={3}>
          {/* ONBOARDING LISTING */}
          <Grid item xs={4}>
            <SPATabs
              tabsNameArray={onboardingTabsArray}
              activeTab={activeHistoryTab}
              setActiveTab={handleTabSelection}
            />
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                maxHeight: "66vh",
                overflowY: "auto",
                mb: 2,
              }}
            >
              {data?.map((item, index) => (
                <Box
                  key={item?._id}
                  sx={{
                    borderRadius: "16px",
                    bgcolor: onboardId === item?._id ? "#4E45E5" : "#F8F7FE",
                    padding: "16px",
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() => handleSelectOnBoard(item?._id)}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: onboardId === item?._id ? "#FFFFFF" : "#4A4C4F",
                        fontWeight: "600",
                        borderRight: "2px solid #C1C2C3",
                        p: "8px",
                        m: "8px",
                      }}
                    >
                      {item.isEnableOnboarding === "ENABLED"
                        ? `${getOrdinalSuffix(item.positionArrangment)}`
                        : "Hidden"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color:
                            onboardId === item?._id ? "#25272B" : "#1AA37A",
                          bgcolor:
                            item?.isEnableOnboarding != "ENABLED"
                              ? "#FAEBEB"
                              : "#E9FBF6",
                          p: "4px",
                          borderRadius: "4px",
                        }}
                      >
                        {item?.isEnableOnboarding}
                      </Typography>
                      <Box>
                        <Typography
                          color={
                            onboardId === item?._id ? "#FFFFFF" : "#8F9193"
                          }
                          fontSize={"10px"}
                          textTransform={"uppercase"}
                        >
                          Created at:{" "}
                          {dayjs(item?.createdAt).format("DD MMM, YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: onboardId === item?._id ? "#FFFFFF" : "#4A4C4F",
                        mt: "8px",
                        fontWeight: "600",
                      }}
                    >
                      {item?.title}
                    </Typography>
                  </Box>
                </Box>
              ))}
              {data?.length === 0 && (
                <Box
                  sx={{
                    mt: "18px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "24px",
                  }}
                >
                  <NoNotifications />
                  <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                    No Onboardings Yet
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                    After creating onbaording, they will be displayed here as
                    onboarding.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={7.5}
            sx={{
              borderRadius: "16px",
              bgcolor: "#FFFFFF",
              flexGrow: "1 !important",
            }}
          >
            <Box>
              {showCreateOnboarding || !onboardId ? (
                <CreateNewOnboarding
                  onboardId={onboardId}
                  singleOnboardingData={singleOnboardingData}
                  methods={methods}
                  control={control}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  data={data}
                  currentIndex={currentIndex}
                  getAllOnboardingsLoading={getAllOnboardingsLoading}
                  isOpenCreationDialogue={isOpenCreationDialogue}
                  setIsOpenCreationDialogue={setIsOpenCreationDialogue}
                  handleEnableNow={handleEnableNow}
                  handleEnableLater={handleEnableLater}
                />
              ) : (
                <ViewOnboarding
                  onboardId={onboardId}
                  singleOnboardingData={singleOnboardingData}
                  methods={methods}
                  control={control}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  data={data}
                  currentIndex={currentIndex}
                  handleSortPositionHandling={handleSortPositionHandling}
                  getAllOnboardingsLoading={getAllOnboardingsLoading}
                  enabled={enabled}
                  toggleSwitch={toggleSwitch}
                  handleOpenOnboardingDialogue={handleOpenOnboardingDialogue}
                  isOpenEditOnboardingDialogue={isOpenEditOnboardingDialogue}
                  handleCloseOnboardingDialogue={handleCloseOnboardingDialogue}
                  handleSort={handleSort}
                  dragItem={dragItem}
                  dragOverItem={dragOverItem}
                  isOpenDisabledDialogue={isOpenDisabledDialogue}
                  handleDisableConfirm={handleDisableConfirm}
                  setIsOpenDisabledDialogue={setIsOpenDisabledDialogue}
                  sortedOnboardingArray={sortedOnboardingArray}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Onboarding;
