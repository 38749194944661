import { Box, Button, Grid, Typography } from "@mui/material";
import { CalendarBlank, User } from "@phosphor-icons/react";
import React from "react";
import NoNotifications from "../../../../../../../../assets/svg/no-notifications";
import {
  handleSingleAppointmentData,
  handleSingleAppointmentModal,
} from "../../../../../../../../store/features/new-appointment";
import {
  customColors,
  fs10,
  fs12,
  fs16,
  fw600,
} from "../../../../../../../../theme";
import theme from "../../../../../../../../theme/theme";
import Loader from "../../../../../../../common/loader";
import FormProvider from "../../../../../../../hook-form/FormProvider";
import ProfileImageUploader from "../../../../../../../profile-image-upload";
import { SubmitBtn, overview } from "../../../../style";
import { OverviewElements } from "../../staff-profile-data";
import useStaffOverview from "./useStaffOverview";

export default function Overview({ singleStaffData, singleStaffRefetching }) {
  const {
    control,
    singleAppointment,
    handleSubmit,
    onSubmit,
    methods,
    navigate,
    dispatch,
    componentloading,
    staffDetailApi,
    rolesData,
    currentEmployment,
  } = useStaffOverview({ singleStaffData, singleStaffRefetching });

  const viewDataFields = currentEmployment
    ? OverviewElements(rolesData?.result?.data?.data)?.filter(
        (item) => item?.otherOptions?.name !== "employmentEndDate"
      )
    : OverviewElements(rolesData?.result?.data?.data);

  return (
    <>
      {componentloading ? (
        <Box
          sx={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Grid container gap={3} sx={{ height: "100%" }}>
          {/* // user information */}

          <Grid
            xs={12}
            md={6}
            style={{ padding: "16px", borderRadius: "8px" }}
            bgcolor={theme.palette.purple10.main}
            container
          >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box display={"flex"} gap={1} mb={2}>
                <User size={24} />
                <Typography fontSize={fs16} fontWeight={fw600}>
                  Profile Information
                </Typography>
              </Box>
              <Grid container spacing={2}>
                {viewDataFields.map((item) => (
                  <>
                    <Grid item xs={12} md={item.gridLength}>
                      <item.component
                        {...item.otherOptions}
                        control={control}
                        methods={methods}
                      />
                    </Grid>
                  </>
                ))}
              </Grid>

              <Grid
                container
                bgcolor={customColors.white}
                sx={{
                  padding: "4px",
                  borderRadius: "8px",
                  width: "auto",
                  alignItems: "center",
                }}
                rowGap={1}
                xs={12}
                mt={1}
              >
                <Grid xs={3} lg={1.5}>
                  {staffDetailApi?.profilePictureUrl ? (
                    <ProfileImageUploader
                      width={"48px"}
                      height={"48px"}
                      uploadBtnText={"Upload"}
                      profileUpload={true}
                    />
                  ) : (
                    <ProfileImageUploader
                      width={"48px"}
                      height={"48px"}
                      uploadBtnText={"Upload"}
                      profileUpload={true}
                      initals={`${staffDetailApi?.firstName} ${staffDetailApi?.lastName}`}
                      initialSize="48px"
                    />
                  )}
                </Grid>
                <Grid xs={6} lg={5}>
                  <Typography
                    fontSize={fs10}
                    textTransform={"uppercase"}
                  >{`id: ${staffDetailApi?._id}`}</Typography>
                </Grid>
              </Grid>

              <Box sx={{ mt: "20px" }}>
                <Button fullWidth sx={SubmitBtn} type={"submit"}>
                  <Typography>Save Changes</Typography>
                </Button>
              </Box>
            </FormProvider>
          </Grid>

          {/* Notification */}
          <Grid
            xs={12}
            md={5.7}
            style={{
              padding: "16px",
              borderRadius: "8px",
              height: "800px",
              overflow: "auto",
            }}
            bgcolor={theme.palette.purple10.main}
          >
            <Box display={"flex"} gap={"4px"}>
              <CalendarBlank size={24} />
              <Typography fontSize={fs16} fontWeight={fw600}>
                Next Appointments
              </Typography>
            </Box>
            {singleAppointment && singleAppointment.length > 0 ? (
              singleAppointment.map((item, index) => {
                return (
                  <>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={"8px"}
                      key={index}
                    >
                      <Typography sx={overview.appointmentCardTitle}>
                        {item.title}
                      </Typography>
                      {item.children.map((itemChild, c_index) => (
                        <Box
                          key={`${index} ${c_index}`}
                          onClick={() => {
                            navigate("/calendar");
                            dispatch(handleSingleAppointmentModal());
                            dispatch(
                              handleSingleAppointmentData(
                                itemChild.otherOptions?.appointmentId
                              )
                            );
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          <itemChild.component {...itemChild.otherOptions} />
                        </Box>
                      ))}
                    </Box>
                  </>
                );
              })
            ) : (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  overflow={"hidden"}
                >
                  <NoNotifications />
                  <Typography sx={{ fontSize: fs16, color: "#595959" }}>
                    No Appointment Yet
                  </Typography>
                  <Typography sx={{ fontSize: fs12, color: "#595959" }}>
                    After creating appointments or sales, they will be displayed
                    here as Appointment.
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
