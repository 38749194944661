import React from "react";
import SPADialogue from "../common/spa-dialogue";
import {
  Backdrop,
  Box,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import SPATextField from "../common/spa-textField";
import { MagnifyingGlass, X } from "@phosphor-icons/react";
import useGlobalSearch from "./useGlobalSearch";
import { fs12, fs16, primary } from "../../theme";
import CLoseIcon from "../../assets/svg/close-icon";
import { HeadingContent } from "./global-style";
import ClientCard from "./client-card";
import AppointmentCard from "./appointment-card";
import Profile from "../profile-model";
import {
  handleSingleAppointmentData,
  handleSingleAppointmentModal,
} from "../../store/features/new-appointment";
import NoNotifications from "../../assets/svg/no-notifications";

export default function GlobalSearch({ searchModel, handleCloseSearchModel }) {
  const {
    navigate,
    dispatch,
    searchClient,
    setSearchClient,
    searchDataArray,
    isClientProfileModalOpen,
    handleCloseClientProfile,
    handleOpenClientProfile,
    clientAppointmentLoader,
    isClientAppointmentFetching,
  } = useGlobalSearch(handleCloseSearchModel);

  return (
    <>
      <SPADialogue open={searchModel} handleClose={handleCloseSearchModel}>
        {clientAppointmentLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={clientAppointmentLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Box
          sx={{
            height: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            p: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <SPATextField
              placeholder="Search"
              value={searchClient}
              sx={{
                width: { md: "650px", xs: "180px" },
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                    <MagnifyingGlass size={24} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ paddingLeft: "10px", cursor: "pointer" }}
                  >
                    {searchClient && (
                      <X
                        size={24}
                        onClick={() => {
                          setSearchClient("");
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              onChangeValue={(event) => {
                setSearchClient(event.target.value);
              }}
            />

            <Box
              sx={{
                marginLeft: "10px",
                cursor: "pointer",
                width: "48px",
                height: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingX: "8px",
                backgroundColor: primary.blue,
                borderRadius: "8px",
              }}
              onClick={handleCloseSearchModel}
            >
              <CLoseIcon />
            </Box>
          </Box>
          {/* searchDataEmpty */}

          {searchDataArray?.clients.length === 0 &&
            searchDataArray?.appointments.length === 0 && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                overflow={"hidden"}
                marginBottom={"5px"}
              >
                <NoNotifications />
                <Typography sx={{ fontSize: fs16, color: "#595959" }}>
                  No Data Yet
                </Typography>
                <Typography sx={{ fontSize: fs12, color: "#595959" }}>
                  After searching appointments or clients, they will be
                  displayed here as Data.
                </Typography>
              </Box>
            )}
          {isClientAppointmentFetching ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isClientAppointmentFetching}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <Box>
              {/* Client list */}
              {searchDataArray?.clients &&
                searchDataArray?.clients.length > 0 && (
                  <Box>
                    <Typography sx={HeadingContent}>Clients</Typography>
                  </Box>
                )}
              <Box
                sx={{
                  display:
                    searchDataArray?.clients.length > 0 ? "flex" : "none",
                  flexDirection: "column",
                  gap: "16px",
                  height: "auto",
                  maxHeight: "300px",
                  overflow: "scroll",
                }}
              >
                {searchDataArray?.clients.map((item) => (
                  <ClientCard
                    item={item}
                    onClick={() => {
                      handleOpenClientProfile(item.id);
                    }}
                  />
                ))}
              </Box>

              {/* appointments list */}
              {searchDataArray?.appointments &&
                searchDataArray?.appointments.length > 0 && (
                  <Box>
                    <Typography sx={HeadingContent}>Appointments</Typography>
                  </Box>
                )}

              <Box
                sx={{
                  display:
                    searchDataArray?.appointments.length > 0 ? "flex" : "none",
                  flexDirection: "column",
                  gap: "16px",
                  height: "auto",
                  maxHeight: "300px",
                  overflow: "scroll",
                }}
              >
                {searchDataArray?.appointments.map((item, index) => (
                  <AppointmentCard
                    cardDetail={item}
                    id={index}
                    onClick={() => {
                      navigate("/calendar");
                      dispatch(handleSingleAppointmentModal());
                      dispatch(handleSingleAppointmentData(item?.id));
                      handleCloseSearchModel();
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </SPADialogue>
      {isClientProfileModalOpen && (
        <Profile
          isClientProfileOpen={isClientProfileModalOpen}
          handleClose={handleCloseClientProfile}
        />
      )}
    </>
  );
}
