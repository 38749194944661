import React from "react";

const TreatmentEmptyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="183"
      height="190"
      viewBox="0 0 183 190"
      fill="none"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M47.6362 7.00833C49.2434 8.58809 50.9361 10.0743 52.8174 11.3153C62.4125 17.6451 90.9501 32.8549 136.093 26.4224C192.498 18.3854 184.435 75.1592 176.372 90.313C168.309 105.467 138.549 133.303 136.093 147.202C133.638 161.102 144.516 198.998 119.322 187.71C94.1287 176.422 79.4979 149.814 46.498 153.039C13.498 156.264 -9.88491 141.724 4.62861 115.533C19.1421 89.3419 36.5486 72.4088 14.1384 43.3816C-7.64981 15.1603 28.7681 -11.5363 47.6362 7.00833Z"
          fill="#CAC7F7"
        />
        <g id="Group_2">
          <path
            id="Vector_2"
            d="M19.8369 78.8475L135.747 56.272L131.288 33.3755C131.137 32.6012 130.835 31.8641 130.399 31.2064C129.964 30.5487 129.403 29.9833 128.749 29.5424C128.094 29.1014 127.36 28.7937 126.587 28.6367C125.813 28.4798 125.017 28.4766 124.243 28.6274L17.6094 67.4106L19.8369 78.8475Z"
            fill="#2F2989"
          />
          <path
            id="Vector_3"
            d="M17.6797 67.7734L144.438 43.0859C146.26 42.731 148.149 43.1145 149.688 44.1521C151.228 45.1897 152.292 46.7963 152.647 48.6186L167.225 123.47C167.58 125.292 167.197 127.181 166.159 128.721C165.121 130.26 163.515 131.324 161.693 131.679L41.8051 155.029C39.9828 155.383 38.0942 155 36.5547 153.962C35.0152 152.925 33.9509 151.318 33.596 149.496L17.6797 67.7734Z"
            fill="#716AEA"
          />
          <path
            id="Vector_4"
            d="M111.908 79.4988L156.218 70.8691C158.04 70.5142 159.929 70.8977 161.468 71.9353C163.008 72.9728 164.072 74.5795 164.427 76.4018L167.718 93.2987C168.073 95.121 167.689 97.0096 166.652 98.5491C165.614 100.089 164.008 101.153 162.185 101.508L117.876 110.138C117.527 110.205 117.166 110.132 116.871 109.934C116.576 109.735 116.373 109.428 116.305 109.079L110.85 81.0696C110.782 80.7209 110.855 80.3595 111.054 80.065C111.252 79.7704 111.56 79.5667 111.908 79.4988Z"
            fill="#3730A0"
          />
          <path
            id="Vector_5"
            d="M129.436 99.1288C133.383 99.1288 136.582 95.9297 136.582 91.9833C136.582 88.037 133.383 84.8379 129.436 84.8379C125.49 84.8379 122.291 88.037 122.291 91.9833C122.291 95.9297 125.49 99.1288 129.436 99.1288Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export default TreatmentEmptyIcon;
