import { Check, WarningAmber } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  CalendarBlank,
  NoteBlank,
  PencilSimple,
  User,
} from "@phosphor-icons/react";
import React from "react";
import NoNotifications from "../../../../../../assets/svg/no-notifications";
import {
  handleSingleAppointmentData,
  handleSingleAppointmentModal,
} from "../../../../../../store/features/new-appointment";
import {
  customColors,
  fs10,
  fs12,
  fs16,
  fw400,
  fw600,
  secondary,
} from "../../../../../../theme";
import theme from "../../../../../../theme/theme";
import Loader from "../../../../../common/loader";
import FormProvider from "../../../../../hook-form/FormProvider";
import ProfileImageUploader from "../../../../../profile-image-upload";
import StatusMenu from "../../../../../status-menu";
import { overview } from "../../../../style";
import { OverviewElements } from "../../client-profile-data";
import useClientOverview from "./useClientOverview";
import { PermissionProtected } from "../../../../../../guards/permission-protected";
import { PERMISSION } from "../../../../../../enums/permissions";

export default function Overview({ setActiveItem }) {
  const {
    control,
    pinnedNote,
    singleAppointment,
    status,
    setStatus,
    friendReferralArray,
    handleSubmit,
    onSubmit,
    methods,
    navigate,
    dispatch,
    friendFieldEnable,
    onChange,
    singleContraindication,
    clientLoading,
    clientDetailApi,
  } = useClientOverview();

  const viewDataFields = OverviewElements(
    friendReferralArray,
    friendFieldEnable,
    onChange
  );

  return (
    <>
      {clientLoading && (
        <Box
          sx={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </Box>
      )}
      <Grid container rowGap={5} sx={{ height: "100%" }}>
        {/* // user information */}

        <Grid
          xs={12}
          md={5}
          xl={6}
          style={{ padding: "16px", borderRadius: "8px" }}
          bgcolor={theme.palette.purple10.main}
          container
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} gap={1} mb={2}>
              <User size={24} />
              <Typography fontSize={fs16} fontWeight={fw600}>
                Profile Information
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {viewDataFields.map((item) =>
                item.gridLength === 0 ? (
                  <></>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={item.gridLength}
                      mt={item?.margin && 2}
                    >
                      <item.component
                        {...item.otherOptions}
                        control={control}
                      />
                    </Grid>
                  </>
                )
              )}
            </Grid>

            <Grid
              container
              bgcolor={customColors.white}
              style={{
                padding: "4px",
                borderRadius: "8px",
                width: "auto",
                alignItems: "center",
              }}
              rowGap={1}
              xs={12}
              mt={2}
            >
              <Grid xs={3} lg={1.5}>
                {clientDetailApi?.profilePictureUrl ? (
                  <ProfileImageUploader
                    width={"48px"}
                    height={"48px"}
                    uploadBtnText={"edit"}
                    profileUpload={true}
                  />
                ) : (
                  <ProfileImageUploader
                    width={"48px"}
                    height={"48px"}
                    uploadBtnText={"edit"}
                    profileUpload={true}
                    initals={`${clientDetailApi?.firstName} ${clientDetailApi?.lastName}`}
                    initialSize="48px"
                  />
                )}
              </Grid>
              <Grid xs={6} lg={5}>
                <Typography
                  fontSize={fs10}
                  textTransform={"uppercase"}
                >{`id: ${clientDetailApi?._id}`}</Typography>
              </Grid>
              {status !== "ADMIN" && (
                <Grid
                  xs={6}
                  lg={5}
                  justifyContent={"flex-end"}
                  display={"flex"}
                >
                  <StatusMenu
                    selectedStatus={status}
                    setSelectedStatus={setStatus}
                    disabled={true}
                  />
                </Grid>
              )}
            </Grid>

            <Box sx={{ mt: "20px" }}>
              <PermissionProtected permission={PERMISSION.EDIT_CLIENT} disabled>
                <Button
                  fullWidth
                  sx={{
                    display: "flex",
                    height: "0px 24px",
                    alignItems: "center",
                    justifyItems: "center",
                    gap: "8px",
                    borderRadius: "8px",
                    background: secondary.main,
                    color: customColors.white,
                    "&:hover": {
                      backgroundColor: secondary.main,
                    },
                    "&:disabled": {
                      color: "#757575",
                      bgcolor: "#e0e0e0",
                    },
                  }}
                  type={"submit"}
                >
                  <Typography>Save Changes</Typography>
                </Button>
              </PermissionProtected>
            </Box>
          </FormProvider>
        </Grid>
        {/* Notes */}
        <Grid xs={12} md={4} xl={3} paddingInline={"24px"}>
          {/* Contraindications note */}
          <Box style={overview.ContraindicationsStyle} mb={"30px"}>
            <Box display={"flex"} gap={"4px"}>
              <WarningAmber sx={{ fontSize: "20px" }} />

              <Typography fontSize={fs16} fontWeight={fw600}>
                Contraindications
              </Typography>
            </Box>

            <Typography
              fontSize={fs12}
              fontWeight={fw400}
              textAlign={"justify"}
              height={225}
              width="100%" // Set width to allow text wrapping
              wrap="wrap"
              sx={{ wordBreak: "break-word" }}
            >
              {singleContraindication[0]?.otherOptions.text
                ? singleContraindication[0]?.otherOptions.text
                : "You can Edit it and add new contraindication for yourself"}
            </Typography>
            {/* )} */}

            <Box display={"flex"} justifyContent={"space-between"}>
              <Box display={"flex"} gap={"2px"} sx={{ cursor: "pointer" }}>
                <PencilSimple
                  size={16}
                  color={secondary.main}
                  onClick={() => setActiveItem("AdditionalNotes")}
                />
                <Typography
                  fontSize={fs12}
                  fontWeight={fw400}
                  color={secondary.main}
                  onClick={() => setActiveItem("AdditionalNotes")}
                >
                  Edit
                </Typography>
              </Box>

              <Box display={"flex"} gap={"4px"} alignItems={"end"}>
                <Typography
                  textTransform={"uppercase"}
                  fontSize={fs10}
                  fontWeight={fw400}
                  color="#8F9193"
                >
                  {singleContraindication[0]?.updated ? "Edited" : "Create"}
                </Typography>

                <Typography
                  fontSize={fs12}
                  fontWeight={fw400}
                  color={theme.palette.textGraySecondary.main}
                >
                  {singleContraindication[0]?.updated
                    ? singleContraindication[0]?.updated
                    : "Today"}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* pinned note */}
          <Box style={overview.pinnedStyle}>
            <Box display={"flex"} gap={"4px"}>
              <NoteBlank size={24} />

              <Typography fontSize={fs16} fontWeight={fw600}>
                Pinned Note
              </Typography>
            </Box>

            <Typography
              fontSize={fs12}
              fontWeight={fw400}
              textAlign={"justify"}
              height={270}
              width="100%" // Set width to allow text wrapping
              wrap="wrap"
              sx={{ wordBreak: "break-word" }}
            >
              {Object.keys(pinnedNote).length > 0
                ? `${pinnedNote?.otherOptions?.content}`
                : "You can view all notes in Notes tab"}
            </Typography>

            <Box display={"flex"} justifyContent={"space-between"}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveItem("AdditionalNotes");
                }}
              >
                <Typography
                  fontSize={fs12}
                  fontWeight={fw400}
                  color={secondary.main}
                >
                  View All notes
                </Typography>
              </Box>
              <Box>
                <Typography
                  fontSize={fs12}
                  fontWeight={fw400}
                  color={theme.palette.textGraySecondary.main}
                >
                  {Object.keys(pinnedNote).length > 0
                    ? `${pinnedNote?.otherOptions?.added}`
                    : "Today"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Notification */}
        <Grid
          xs={12}
          md={3}
          style={{
            padding: "16px",
            borderRadius: "8px",
            height: "800px",
            overflow: "auto",
          }}
          bgcolor={theme.palette.purple10.main}
        >
          <Box display={"flex"} gap={"4px"}>
            <CalendarBlank size={24} />
            <Typography fontSize={fs16} fontWeight={fw600}>
              Next Appointments
            </Typography>
          </Box>
          {singleAppointment.length > 0 ? (
            singleAppointment.map((item, index) => {
              return (
                <>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"8px"}
                    key={index}
                  >
                    <Typography sx={overview.appointmentCardTitle}>
                      {item.title}
                    </Typography>
                    {item.children.map((itemChild, c_index) => (
                      <Box
                        key={`${index} ${c_index}`}
                        onClick={() => {
                          navigate("/calendar");
                          dispatch(handleSingleAppointmentModal());
                          dispatch(
                            handleSingleAppointmentData(
                              itemChild.otherOptions?.appointmentId
                            )
                          );
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <itemChild.component {...itemChild.otherOptions} />
                      </Box>
                    ))}
                  </Box>
                </>
              );
            })
          ) : (
            <>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                overflow={"hidden"}
              >
                <NoNotifications />
                <Typography sx={{ fontSize: fs16, color: "#595959" }}>
                  No Appointment Yet
                </Typography>
                <Typography sx={{ fontSize: fs12, color: "#595959" }}>
                  After creating appointments or sales, they will be displayed
                  here as Appointment.
                </Typography>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
