import { List, ListItem, ListItemText } from "@mui/material";
import Popover from "@mui/material/Popover";
import { CaretRight } from "@phosphor-icons/react";
import * as React from "react";
import { customColors, fs14, secondary } from "../../../theme";
import SPAPopover from "../spa-popover";

/**
 * SPANestedMenu component.
 * Renders a nested menu using Material-UI Popover.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isOpen - If true, the popover is open.
 * @param {HTMLElement} props.anchorEl - The anchor element for the popover.
 * @param {Function} props.handleClose - The function to close the popover.
 * @param {Function} props.setFilteredList - The function to set the filtered list.
 * @param {Array} props.data - The array of items to display in the popover list.
 * @param {string} props.id - The unique identifier for the popover.
 * @param {string} [props.width='200px'] - The width of the popover.
 * @param {string} [props.transformHorizontal='left'] - The horizontal transformation origin of the popover.
 * @param {string} [props.transformVertical='top'] - The vertical transformation origin of the popover.
 * @param {string} [props.anchorOriginHorizontal='left'] - The horizontal anchor origin of the popover.
 * @param {string} [props.anchorOriginVertical='bottom'] - The vertical anchor origin of the popover.
 * @returns {JSX.Element} JSX code for rendering the SPANestedMenu component.
 */

const SPANestedMenu = (props) => {
  const {
    isOpen,
    anchorEl,
    handleClose,
    setFilteredList,
    data,
    id,
    width = "200px",
    transformHorizontal = "left",
    transformVertical = "top",
    anchorOriginHorizontal = "left",
    anchorOriginVertical = "bottom",
  } = props;
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(null);
  const [subMenuArray, setSubMenuArray] = React.useState([]);
  const [activeItem, setActiveItem] = React.useState("");

  const openSubMenuPopOver = Boolean(isSubMenuOpen);
  const subMenuPopOverId = openSubMenuPopOver ? "simple-popover" : undefined;

  /**
   * Handles the click event on a menu item.
   * If the clicked item has children, opens the submenu popover.
   * Otherwise, adds the item to the filtered list.
   * @param {Event} event - The click event.
   * @param {Object} item - The clicked menu item.
   */
  const handleItemClick = (event, item) => {
    setActiveItem(item.label);
    if (item?.children?.length > 0) {
      setIsSubMenuOpen(event.currentTarget);
      setSubMenuArray(item.children);
    } else {
      setFilteredList((prev) => [...prev, item]);
      setIsSubMenuOpen(null);
    }
  };
  return (
    <>
      <Popover
        id={id}
        disableScrollLock
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transformVertical,
          horizontal: transformHorizontal,
        }}
        PaperProps={{ sx: styles.popOverStyle(width) }}
      >
        <List sx={styles.listWrapper}>
          {data?.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <ListItem
                  sx={styles.listItem(activeItem, item.label)}
                  onClick={(event) => handleItemClick(event, item)}
                  // onMouseEnter={(event) => handleItemClick(event, item)}
                  // onMouseLeave={handleClose}
                >
                  <ListItemText
                    sx={{
                      "& span": {
                        fontSize: fs14,
                      },
                    }}
                    primary={item.label}
                  />
                  <CaretRight size={24} color="currentColor" />
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      </Popover>
      <SPAPopover
        popoverSx={{ marginLeft: "16px", mt: "0 !important" }}
        id={subMenuPopOverId}
        anchorOriginVertical="top"
        anchorOriginHorizontal="right"
        transformVertical="top"
        transformHorizontal="left"
        handleItemClick={handleItemClick}
        isOpen={openSubMenuPopOver}
        anchorEl={isSubMenuOpen}
        handleClose={handleClose}
        data={subMenuArray}
      />
    </>
  );
};

export default SPANestedMenu;

const styles = {
  popOverStyle: (width) => ({
    borderRadius: "16px !important",
    mt: "8px",
    // p: "16px",
    width: width,
    boxShadow:
      "-3px 2px 8px 0px rgba(78, 69, 229, 0.05), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -66px 62px 25px 0px rgba(78, 69, 229, 0.00)",
  }),
  listWrapper: {
    width: "100%",
    // maxWidth: 360,
    p: "8px",
    m: 0,
  },
  listItem: (activeItem, label) => ({
    cursor: "pointer",
    // gap: "11px",
    justifyContent: "space-between",
    p: "12px 16px !important",
    backgroundColor: activeItem === label ? `${secondary.main} !important` : "",
    color: activeItem === label ? customColors.white : secondary.main,
    borderRadius: "8px !important",
    "& .MuiListItemText-root": {
      "& span": {
        color: activeItem === label ? customColors.white : secondary.main,
      },
    },
    "&:hover": {
      backgroundColor: `#EDECFC !important`,
      color: "#4E45E5",
      "& .MuiListItemText-root": {
        "& span": {
          color: "#4E45E5",
        },
      },
    },
  }),
};
