import { FilterList } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  Alarm,
  Archive,
  ChatText,
  CursorClick,
  Envelope,
  Image,
  MagnifyingGlass,
  Plus,
  X,
} from "@phosphor-icons/react";
import dayjs from "dayjs";
import React, { useState } from "react";
import NoNotifications from "../../../assets/svg/no-notifications";
import NoTemplates from "../../../assets/svg/no-templates";
import { secondary } from "../../../theme";
import SPASwitch from "../../common/spa-switch";
import SPATabs from "../../common/spa-tabs";
import SPATextField from "../../common/spa-textField";
import FormProvider from "../../hook-form/FormProvider";
import EditorField from "./EditorField";
import {
  remindersDetailsFormData,
  remindersFormData,
  remindersTabsArray,
} from "./reminders-form-data";
import { AntSwitch, InsertBtn, SubmitBtn } from "./remindersStyle";
import useReminders from "./useReminders";

export default function RemindersSection() {
  const {
    editorRef,
    methods,
    handleSubmit,
    onSubmit,
    control,
    activeReminderTab,
    reminderType,
    setReminderType,
    handleTabSelection,
    remindersData,
    reminderId,
    remindersDataLoader,
    handleSingleReminder,
    singleReminderLoader,
    handleCreateReminder,
    searchReminder,
    setSearchReminder,
  } = useReminders();

  const [editorInstance, setEditorInstance] = useState(null);
  const handleInsertButton = () => {
    if (editorInstance) {
      const content =
        '<button style="background-color: #4E45E5; color: #FFFFFF;height: 40px; padding: 8px; border: none; border-radius: 8px;">Call To Action</button>';
      editorInstance.insertContent(content);
    }
  };
  const InsertButton = () => {
    return (
      <Box>
        {" "}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "8px",
          }}
        >
          <Typography sx={{ color: "#5E6062", fontSize: "14px", mb: "4px" }}>
            Insert
          </Typography>
        </Box>
        <Box
          sx={{
            borderRadius: "8px",
            width: "100%",
            display: "flex",
            gap: "8px",
          }}
        >
          <Button sx={InsertBtn}>
            <Image size={24} />
            Image
          </Button>
          <Button sx={InsertBtn} onClick={() => handleInsertButton()}>
            {" "}
            <CursorClick size={24} />
            Button
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  height: "48px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleCreateReminder}
              >
                <Plus size={24} color="currentColor" />
                Create Reminder
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              <SPATextField
                placeholder="Search"
                value={searchReminder}
                sx={{
                  width: { md: "328px", xs: "180px" },
                  "& .MuiOutlinedInput-root": {
                    height: "48px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} color={"#C1C2C3"} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingLeft: "10px", cursor: "pointer" }}
                      onClick={() => {
                        setSearchReminder("");
                      }}
                    >
                      {searchReminder && <X size={24} />}
                    </InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchReminder(event.target.value);
                }}
              />

              <Box sx={styles.searchbox}>
                <FilterList sx={{ color: secondary.main }} />
              </Box>
            </Box>
          </Box>
        </Box>

        {(remindersDataLoader || singleReminderLoader) && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={remindersDataLoader || singleReminderLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Grid
          container
          columnGap={3}
          sx={{
            height: "75vh",
            gap: "16px",
          }}
        >
          <Grid item xs={4}>
            <SPATabs
              tabsNameArray={remindersTabsArray}
              activeTab={activeReminderTab}
              setActiveTab={handleTabSelection}
            />
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                maxHeight: "66vh",
                overflowY: "auto",
                mb: 2,
              }}
            >
              {remindersData?.result?.data?.map((item) => {
                return (
                  <Box
                    key={item?._id}
                    sx={{
                      borderRadius: "16px",
                      bgcolor: reminderId === item?._id ? "#CAC7F7" : "#F8F7FE",
                      padding: "16px",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSingleReminder(item)}
                  >
                    <Box
                      sx={{
                        width: "20%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "4px" }}>
                        <Alarm
                          size={24}
                          color={
                            reminderId === item?._id ? "#4E45E5" : "#8F9193"
                          }
                        />
                        <AntSwitch
                          checked={
                            item?.isEnableReminder === true ? true : false
                          }
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        {item?.hours} Hours{" "}
                        {item?.when === "BEFORE_APPOINTMENT"
                          ? "Before Appointment"
                          : "After Appointment"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "80%",
                        px: "8px",
                      }}
                    >
                      <Typography sx={{ fontSize: "20px", color: "#4A4C4F" }}>
                        {item?.name}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "14px", color: "#4A4C4F", mt: "8px" }}
                      >
                        {item?.subject ?? "---"}
                      </Typography>
                      <Typography sx={{ fontSize: "12px", color: "#5E6062" }}>
                        {/* {item?.type === "EMAIL" && (
                            <Box
                              sx={{
                                overflow: "hidden",
                                height: "50px",
                                bgcolor:
                                  reminderId === item?._id
                                    ? "#CAC7F7"
                                    : "#F8F7FE",
                                "& .tox-tinymce": {
                                  border: "none",
                                  bgcolor:
                                    reminderId === item?._id
                                      ? "#CAC7F7"
                                      : "#F8F7FE",
                                  "& .tox-edit-area::before": {
                                    border: "none",
                                  },
                                  "& .tox-edit-area__iframe": {
                                    backgroundColor:
                                      reminderId === item?._id
                                        ? "#CAC7F7"
                                        : "#F8F7FE",
                                    height: "100px",
                                  },
                                },
                              }}
                            >
                              <Editor
                                apiKey="skb6117e0zohgmjyg8fws6ytucbykv0bcevzf4ntqhlwtt2f"
                                disabled={true}
                                initialValue={item?.message}
                                init={{
                                  // height: "100%",
                                  menubar: false,
                                  statusbar: false,
                                  toolbar: false,
                                }}
                              />
                            </Box>
                          )}{" "} */}
                        {item?.type === "SMS" && item?.message}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "16px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <Envelope color="#8F9193" size={16} />

                          <Typography
                            fontSize={"10px"}
                            color={"#8F9193"}
                            textTransform={"uppercase"}
                          >
                            {`Via ${item?.type}`}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            color={"#8F9193"}
                            fontSize={"10px"}
                            textTransform={"uppercase"}
                          >
                            CREATED{" "}
                            {dayjs(item?.createAt).format("DD MMM, YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              {remindersData?.result?.data?.length === 0 && (
                <Box
                  sx={{
                    mt: "18px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "24px",
                  }}
                >
                  <NoNotifications />
                  <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                    No Reminders Yet
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                    After creating reminder, they will be displayed here as
                    reminder.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={7.8}
            sx={{ borderRadius: "16px", bgcolor: "#F8F7FE", mb: 2 }}
          >
            {!reminderType ? (
              <Box
                sx={{
                  mt: "40px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                  height: "60vh",
                }}
              >
                <NoTemplates />

                <Typography sx={{ fontSize: "34px", color: "#5E6062" }}>
                  New Reminder
                </Typography>
                <Typography sx={{ fontSize: "20px", color: "#5E6062" }}>
                  This Reminder Will Be Used For:
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{
                      background: secondary.main,
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      borderRadius: "8px",
                      "&:hover": { background: secondary.main },
                    }}
                    onClick={() => setReminderType("EMAIL")}
                  >
                    <Envelope size={24} color="currentColor" />
                    EMAIL
                  </Button>
                  <Typography sx={{ fontSize: "20px", color: "#8F9193" }}>
                    or
                  </Typography>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{
                      background: secondary.main,
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      borderRadius: "8px",
                      "&:hover": { background: secondary.main },
                    }}
                    onClick={() => setReminderType("SMS")}
                  >
                    <ChatText size={24} color="currentColor" />
                    SMS
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  p: "24px",
                }}
              >
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={1}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        borderRadius: "16px",
                        p: "8px 16px 16px 16px",
                        bgcolor: "#EDECFC",
                        mt: "8px",
                        ml: "8px",
                      }}
                    >
                      <Grid
                        item
                        lg={0.8}
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <Alarm size={24} color={"#4E45E5"} />
                          <SPASwitch
                            name="isEnableReminder"
                            control={control}
                          />
                          {/* <Controller
                            name="isEnableReminder"
                            control={control}
                            render={({ field }) => (
                              <AntSwitch
                                inputProps={{ "aria-label": "ant design" }}
                                {...field}
                              />
                            )}
                          /> */}
                        </Box>
                      </Grid>
                      {remindersDetailsFormData().map((item, index) => {
                        return (
                          <Grid item lg={item.gridLength} xs={12} key={index}>
                            <item.component
                              {...item.otherOptions}
                              control={control}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>

                    {remindersFormData({
                      InsertButton,
                      reminderType,
                    }).map((item, index) => {
                      return (
                        <Grid item lg={item.gridLength} xs={12} key={index}>
                          <item.component
                            {...item.otherOptions}
                            control={control}
                          />
                        </Grid>
                      );
                    })}
                    {reminderType === "EMAIL" && (
                      <Grid item lg={12} xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#5E6062",
                              fontSize: "14px",
                              mb: "4px",
                            }}
                          >
                            Message
                          </Typography>
                        </Box>
                        <EditorField
                          control={control}
                          setEditorInstance={setEditorInstance}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      p: "24px 0px 12px 0px",
                    }}
                  >
                    <Button type="submit" sx={SubmitBtn}>
                      <Archive size={24} />
                      {reminderId ? "Update Reminder" : "Create Reminder"}
                    </Button>
                  </Box>
                </FormProvider>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
