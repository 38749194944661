import { At, ChatText, Envelope } from "@phosphor-icons/react";
import SPAInputField from "../../hook-form/SPAInputField";

export const templatesTabsArray = [
  { title: "ALL", icon: <At size={16} /> },
  {
    title: "EMAIL",
    icon: <Envelope size={16} />,
  },
  { title: "SMS", icon: <ChatText size={16} /> },
];

export const templateFormData = ({ InsertButton, templateType }) => {
  let finalData;
  let EmailData = [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "name",
        label: "Template Name",
        placeholder: "Template Name",
      },
      component: SPAInputField,
    },
    {
      gridLength: 8,
      otherOptions: {
        fullWidth: true,
        name: "subject",
        label: "Subject",
        placeholder: "Write Subject",
      },
      component: SPAInputField,
    },
    // {
    //   gridLength: 4,
    //   otherOptions: {
    //     fullWidth: true,
    //     name: "replyTo",
    //     label: "Reply to",
    //     placeholder: "example@gmail.com",
    //     labelHelperText: "(Optional)",
    //   },
    //   component: SPAInputField,
    // },
    {
      gridLength: 4,
      component: InsertButton,
    },
  ];
  let SMSData = [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "name",
        label: "Template Name",
        placeholder: "Template Name",
      },
      component: SPAInputField,
    },
    // {
    //   gridLength: 6,
    //   otherOptions: {
    //     fullWidth: true,
    //     name: "replyTo",
    //     label: "Reply to",
    //     placeholder: "+91234567890",
    //     labelHelperText: "(Optional)",
    //   },
    //   component: SPAInputField,
    // },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "message",
        label: "Message",
        placeholder: "Write Message",
        type: "textarea",
        multiline: true,
        rows: 12,
      },
      component: SPAInputField,
    },
  ];
  if (templateType === "SMS") {
    finalData = SMSData;
  } else {
    finalData = EmailData;
  }
  return finalData;
};
