import { Box, Dialog, Typography, useMediaQuery } from "@mui/material";
import { X } from "@phosphor-icons/react";
import React, { useState } from "react";
import { customColors, fs24 } from "../../theme";

import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import Appointment from "./components/appointment";
import ClientProfile from "./components/client-profile";
import Sales from "./components/sales";
import SideBar from "./components/side-bar";
import SMS from "./components/sms";
import Email from "./components/email";
import { truncateText } from "../../utils/truncateText";

const Profile = ({ isClientProfileOpen, handleClose }) => {
  const truncateFullName = truncateText();
  const [activeTabSideBar, setActiveTabSideBar] = useState("profile");
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("md"));
  const { clientDetail } = useSelector((state) => state.clientDetail);

  const renderTabContent = (tab) => {
    switch (tab) {
      case "profile":
        return <ClientProfile isResponsive={isResponsive} />;
      case "appointment":
        return <Appointment clientDetail={clientDetail} />;
      case "sale":
        return <Sales clientDetail={clientDetail} />;
      case "sms":
        return <SMS clientDetail={clientDetail} />;
      case "email":
        return <Email clientDetail={clientDetail} />;
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <Dialog
      open={isClientProfileOpen}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // maxWidth="lg"
      PaperProps={{
        sx: {
          maxWidth: "1232px",
          overflowY: "hidden",
          minWidth: { xs: "auto", lg: "1232px" },
          height: "90vh",
          borderRadius: "16px",
        },
      }}
    >
      {/* <ClientModal> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: customColors.darkestBlue,
          p: "24px",
        }}
      >
        <Typography sx={{ fontSize: fs24, color: customColors.white }}>
          {truncateFullName(
            `${clientDetail?.firstName} ${clientDetail?.lastName}`
          )}
        </Typography>
        <X
          size={24}
          style={{ cursor: "pointer" }}
          color={customColors.white}
          onClick={handleClose}
        />
      </Box>
      {/* side bar and other panel */}
      <Box sx={{ overflowY: "auto", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <SideBar
            setActiveItem={setActiveTabSideBar}
            activeItem={activeTabSideBar}
            isResponsive={isResponsive}
          />
          <Box sx={{ width: "100%" }}>{renderTabContent(activeTabSideBar)}</Box>
        </Box>
      </Box>
    </Dialog>
  );
};
export default Profile;
