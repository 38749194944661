import React from 'react';
import AuthLayout from '../../../layout/auth-layout';
import LoginComponent from '../../../components/auth/login';

const Login = () => {
  return (
    <AuthLayout heading='Welcome!' subHeading='Please log in'>
      <LoginComponent />
    </AuthLayout>
  );
};

export default Login;
