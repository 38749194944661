import { Box, FormLabel, Grid, Stack, Typography } from "@mui/material";
import { fs14, fw400, fw600 } from "../../theme";
import theme from "../../theme/theme";
import SPACheckBox from "../hook-form/SPACheckBox";
import SPAInputField from "../hook-form/SPAInputField";
import { setupClientFormData } from "./new-client-form-data";
import {
  additionBoxFlex,
  itemFlex,
  legendStyle,
} from "./new-client-form-style";
import { HelperText } from "../../styleComponents/style";
import useCreateClient from "./useCreateClient";
import SPADialogue from "../common/spa-dialogue";

/**
 * NewClientForm component.
 * Renders a form for creating a new client with various input fields.
 *
 * @returns {JSX.Element} JSX code for rendering the NewClientForm component.
 */

export const NewClientForm = ({
  getAllClients,
  isDialogOpen,
  setDialogOpen,
  setAlertOpen,
  setClientFullName,
  setDialogExistClient,
}) => {
  const {
    handleSubmit,
    control,
    onSubmit,
    methods,
    handleCloseClientDialog,
    friendReferralArray,
    countriesArray,
    onChange,
    friendFieldEnable,
  } = useCreateClient(
    setDialogOpen,
    setAlertOpen,
    setClientFullName,
    setDialogExistClient,
    getAllClients
  );

  const formData = setupClientFormData(
    countriesArray,
    friendReferralArray,
    friendFieldEnable,
    onChange
  );
  return (
    <SPADialogue
      open={isDialogOpen}
      handleClose={handleCloseClientDialog}
      dialogTitle="New Client"
      cancelText="Cancel"
      submitText="Create Client"
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      methods={methods}
    >
      <Box>
        <Grid container spacing={"24px"}>
          {formData.map((item) => (
            <Grid item md={item.gridLength} xs={12}>
              {item?.title && (
                <Typography
                  sx={{
                    color: "#070A0E",
                    fontFamily: '"Open Sans"',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.024px",
                  }}
                >
                  {item.title}
                </Typography>
              )}
              {item?.component && (
                <Box>
                  <item.component control={control} {...item?.otherOptions} />
                </Box>
              )}
              {item.children && (
                <Grid container spacing="16px">
                  {item.children.map((childItem) => (
                    <Grid item md={childItem.gridLength} xs={12}>
                      {childItem?.title && (
                        <Typography
                          sx={{
                            color: "#070A0E",
                            fontFamily: '"Open Sans"',
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            letterSpacing: "0.024px",
                          }}
                        >
                          {childItem.title}
                        </Typography>
                      )}

                      {childItem?.component && (
                        <childItem.component
                          control={control}
                          {...childItem?.otherOptions}
                        />
                      )}

                      {childItem.children && (
                        <Grid container spacing="16px">
                          {childItem.children.map((nestedChildItem) => (
                            <Grid item md={nestedChildItem.gridLength} xs={12}>
                              {nestedChildItem?.title && (
                                <Typography sx={{ legendStyle }}>
                                  {nestedChildItem.title}
                                </Typography>
                              )}
                              <Box sx={{ mt: "16px" }}>
                                {nestedChildItem?.component && (
                                  <nestedChildItem.component
                                    control={control}
                                    {...nestedChildItem?.otherOptions}
                                  />
                                )}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography sx={legendStyle}>Notification Preferences</Typography>
            <Grid container spacing={3}>
              <Grid
                item
                lg={6}
                xs={12}
                sx={{ paddingTop: "0 !important", marginTop: "35px" }}
              >
                <Box sx={itemFlex}>
                  <Typography
                    fontSize={fs14}
                    fontWeight={fw600}
                    color={theme.palette.textGraySecondary.main}
                  >
                    Promotions
                  </Typography>
                  <Stack direction="row">
                    <SPACheckBox
                      name="promotionSMS"
                      label=" Via SMS"
                      control={control}
                    />
                    <SPACheckBox
                      fullWidth
                      name="promotionEmail"
                      label=" Via Email"
                      control={control}
                    />
                  </Stack>
                </Box>
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
                sx={{ paddingTop: "0 !important", marginTop: "35px" }}
              >
                <Box sx={itemFlex}>
                  <Typography
                    fontSize={fs14}
                    fontWeight={fw600}
                    color={theme.palette.textGraySecondary.main}
                  >
                    Newsletter
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <SPACheckBox
                      name="newsLetter"
                      label=" Via Email"
                      control={control}
                    />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mt={1}>
            <Typography sx={additionBoxFlex}>
              <FormLabel
                sx={{
                  color: "#5E6062",
                  fontWeight: fw400,
                  fontSize: fs14,
                  marginTop: "14px",
                }}
              >
                Additional Note
                {/* <HelperText> (Optional)</HelperText> */}
              </FormLabel>
              <SPACheckBox
                name="isPinned"
                label=" Pin Note"
                control={control}
              />
            </Typography>
            <SPAInputField
              name="note"
              type="textarea"
              placeholder="Add any other additional notes here"
              control={control}
            />
          </Grid>
        </Grid>
      </Box>
    </SPADialogue>
  );
};
