import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import UserPlusIcon from "../../assets/svg/UserPlus-icon";
import { useCreateAppointmentMutation } from "../../services/appointment";
import { useGetAllClientsMutation } from "../../services/clients";
import { useGetRoomsMutation } from "../../services/rooms";
import { useGetServicesMutation } from "../../services/services";
import { handleOpenCreateAppointmentAlert } from "../../store/features/new-appointment";
import { handleFilterSearch } from "../../utils/searchFilter";
import { startTimeData } from "./new-appointment-form-data";
import { validationSchema } from "./validationSchema ";
import { useGetStaffsMutation } from "../../services/staff";

dayjs.extend(utc);

export default function useNewAppointment({ handleClose, getAllClients }) {
  const [fullName, setFullName] = useState("");
  const [clientArray, setClientArray] = useState([]);
  const [newClientPopup, setNewClientPopup] = useState(false);
  const [startTimeArray, setStartTimeArray] = useState(startTimeData);
  const [searchFriendName, setSearchFriendName] = useState("");
  const [endTime, setEndTime] = useState("");
  const dispatch = useDispatch();
  const [
    getAllServices,
    { data: servicesData, isLoading: servicesDataLoader },
  ] = useGetServicesMutation();
  const [getAllRooms, { data: roomData, isLoading: roomsDataLoader }] =
    useGetRoomsMutation();
  const [getAllUsers, { data: usersData, isLoading: usersDataLoader }] =
    useGetStaffsMutation();
  const [getClients, { data: allClients, isLoading: clientsDataLoader }] =
    useGetAllClientsMutation();
  const validationSchemaInstance = validationSchema(newClientPopup);
  const methods = useForm({ resolver: yupResolver(validationSchemaInstance) });
  const [createAppointment, { isLoading }] = useCreateAppointmentMutation();
  const clientDetail = useSelector(
    (state) => state?.appointmentModal?.clientDetails
  );

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  const { client, startTime, date } = watch();

  // FILTERED

  const filteredStartTimeArray = startTimeData.map((time) => {
    const [hours, minutesPeriod] = time.value.split(":");
    const [minutes, period] = minutesPeriod.split(" ");

    let parsedHours = parseInt(hours);
    if (period === "PM" && parsedHours !== 12) {
      parsedHours += 12;
    } else if (period === "AM" && parsedHours === 12) {
      parsedHours = 0;
    }

    const selectedTime = dayjs().hour(parsedHours).minute(parseInt(minutes));
    const isSameDay = selectedTime.isSame(date, "day");
    const isPast = isSameDay && selectedTime.isBefore(dayjs());

    return {
      ...time,
      disabled: isPast,
    };
  });

  const convertToISO = (date, selectedTime) => {
    const [time, modifier] = selectedTime.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    let dateObj = new Date(date);
    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);
    return dateObj.toISOString();
  };

  const onSubmit = async (values) => {
    const {
      client,
      firstName,
      lastName,
      postalCode,
      date,
      isPaidFull,
      startTime,
      endTime,
      service,
      email,
      mobileNumber,
      password,
      ...rest
    } = values;

    const startTimeFormat = convertToISO(date, startTime);
    const endTimeFormat = convertToISO(date, endTime);

    let payload = {};
    let clientName = "";

    if (client === "New Client") {
      payload = {
        ...rest,
        newClient: {
          firstName,
          lastName,
          email,
          mobileNumber,
          password,
          addresses: [
            {
              postalCode,
            },
          ],
        },
        isPaidFull: isPaidFull ? isPaidFull : false,
        date: startTimeFormat,
        startTime: startTimeFormat,
        endTime: endTimeFormat,
        services: service,
      };
      clientName = firstName + " " + lastName;
    } else {
      payload = {
        ...rest,
        client: (clientDetail && clientDetail._id) || client,
        date: startTimeFormat,
        isPaidFull: isPaidFull ? isPaidFull : false,
        startTime: startTimeFormat,
        endTime: endTimeFormat,
        services: service,
      };
      if (!clientDetail) {
        const foundClient = allClients?.result.data.data.find(
          (singleClient) => singleClient._id === client
        );
        clientName = foundClient
          ? foundClient.firstName + " " + foundClient.lastName
          : null;
      } else clientName = fullName;
    }

    const response = await createAppointment(payload);
    if (response?.error?.data?.status === false || !response?.data?.status) {
      toast.error(response?.error?.data?.error ?? response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    reset();
    setEndTime("");
    setNewClientPopup(false);
    handleClose();
    getAllClients();
    dispatch(
      handleOpenCreateAppointmentAlert({
        clientName,
        date: `${dayjs(response?.data?.result?.data?.date).format(
          "DD/MM/YYYY"
        )} at ${dayjs(response?.data?.result?.data?.startTime).format(
          "hh:mm A"
        )}`,
      })
    );
  };

  useMemo(() => {
    const { data: clientData } = allClients?.result.data || { data: [] };
    let filteredArray = [];
    filteredArray = clientData.map(
      ({ firstName, lastName, _id, mobileNumber, clientStatus }) => {
        return {
          label: `${firstName} ${lastName}`,
          value: `${firstName} ${lastName}`,
          id: _id,
          mobileNumber: mobileNumber,
          status: clientStatus,
        };
      }
    );

    const newClientArray = [
      {
        label: "New Client",
        value: "New Client",
        icon: <UserPlusIcon />,
        divider: true,
      },
      ...filteredArray,
    ];

    setClientArray(newClientArray);
  }, [allClients]);

  const handleCloseForm = () => {
    handleClose();
    reset();
  };

  const handleEndTime = (serviceArray, startTime) => {
    const serviceCompleteData = servicesData?.result?.data?.data;
    if (startTime && serviceArray.length > 0) {
      const findService = serviceArray.map((serviceId) => {
        const service = serviceCompleteData?.find(
          (singleService) => singleService?._id === serviceId
        );
        return service ? service.hours : 0;
      });

      setValue("endTime", "");
      const endStartTime = new Date(`01/01/2000 ${startTime}`);
      endStartTime.setMinutes(
        endStartTime.getMinutes() +
          findService.reduce((acc, curr) => acc + curr, 0) * 60
      );

      const formattedEndTime = endStartTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      setEndTime(formattedEndTime?.toUpperCase());
      setValue("endTime", formattedEndTime?.toUpperCase());
    }
  };

  const onChangeClientText = (event) => {
    setSearchFriendName(event.target.value);
  };

  useEffect(() => {
    if (searchFriendName) {
      const payload = handleFilterSearch(
        searchFriendName,
        ["firstName", "lastName", "email"],
        "search"
      );
      getClients({ payload, roleType: "CLIENT" });
    } else;
  }, [searchFriendName]);

  useEffect(() => {
    getClients({ roleType: "CLIENT" });
    const searchPayload = handleFilterSearch(
      ["status=ACTIVE"],
      ["status"],
      "filter"
    );
    getAllUsers({ ...searchPayload, roleType: "STAFF" });
  }, []);

  useEffect(() => {
    if (clientDetail?.appointmentType === "calendarSlot") {
      setValue("date", `${clientDetail?.date}`);
      setValue("startTime", `${clientDetail?.start}`);
      setValue(
        clientDetail?.type === "staff" ? "user" : clientDetail?.type,
        `${clientDetail?.resourceId}`
      );
    } else {
      if (clientDetail) {
        setFullName(`${clientDetail?.firstName} ${clientDetail?.lastName}`);
        setValue(
          "client",
          `${clientDetail?.firstName} ${clientDetail?.lastName}`
        );
      }
    }
  }, [clientDetail]);

  useEffect(() => {
    if (client && client.includes("New Client")) setNewClientPopup(true);
    else setNewClientPopup(false);
  }, [client]);

  useEffect(() => {
    const serviceArray = getValues().service;
    handleEndTime(serviceArray, startTime);
  }, [startTime, getValues().service]);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      ["status=ACTIVE"],
      ["status"],
      "filter"
    );
    const servicesFilterPayload = handleFilterSearch(
      ["clientInteraction=ENABLED"],
      ["clientInteraction"],
      "filter"
    );
    getAllServices({ ...servicesFilterPayload });
    getAllRooms({ ...searchPayload });
  }, []);

  return {
    control,
    handleSubmit,
    isLoading,
    onSubmit,
    methods,
    fullName,
    clientArray,
    startTimeArray,
    startTime,
    endTime,
    newClientPopup,
    errors,
    handleCloseForm,
    onChangeClientText,
    servicesData: servicesData?.result?.data?.data,
    usersData,
    roomData: roomData?.result?.data?.data,
    filteredStartTimeArray,
    clientsDataLoader,
    usersDataLoader,
    roomsDataLoader,
    servicesDataLoader,
  };
}
