import { useTheme } from "@emotion/react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import React, { useState } from "react";
import {
  useDeleteServiceMutation,
  useGetSingleServiceQuery,
} from "../../../../services/services";
import { customColors, fs24 } from "../../../../theme";
import Appointment from "./components/appointment";
import Articles from "./components/articles";
import BusyTime from "./components/busy-time";
import TreatmentDetails from "./components/treatment-overview";
import DealAndBundles from "./components/deal-and-bundles";
import DiscountCode from "./components/discount-code";
import SideBar from "./components/side-bar";
import { toast } from "react-toastify";
import SPAAlertDialog from "../../../common/spa-alert-dialogue";

const TreatmentView = ({
  isStaffProfileOpen,
  handleClose,
  singleServiceId,
  getAllServices,
}) => {
  const [activeTabSideBar, setActiveTabSideBar] = useState("treatment");
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenExistedDialog, setIsOpenExistedDialog] = useState(false);
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("md"));
  const {
    data: singleServiceData,
    isLoading: singleServiceDataLoader,
    refetch: singleServiceRefetching,
  } = useGetSingleServiceQuery(singleServiceId, {
    skip: !singleServiceId,
  });

  const [deleteService, { data: deleteData, isLoading: deleteServiceLoader }] =
    useDeleteServiceMutation();

  const renderTabContent = (tab) => {
    switch (tab) {
      case "treatment":
        return (
          <TreatmentDetails
            singleTreatmentDetails={singleServiceData?.result?.data}
            isResponsive={isResponsive}
            singleServiceRefetching={singleServiceRefetching}
          />
        );
      case "appointment":
        return <Appointment />;
      case "busy":
        return <BusyTime />;
      case "articles":
        return <Articles />;
      case "discountCode":
        return <DiscountCode />;
      case "dealAndBundles":
        return <DealAndBundles />;
      default:
        return <div>Select a tab</div>;
    }
  };

  const handleRemoveItem = () => {
    setIsOpenDeleteDialog(true);
  };

  const handleDeleteService = async () => {
    let response;
    if (singleServiceId) {
      response = await deleteService(singleServiceId);
    }

    if (response?.data?.status === false) {
      if (
        response?.data?.message ===
          "Cannot delete service as it is used in active coupon." ||
        response?.data?.message ===
          "Cannot delete service as it is used in active appointments."
      ) {
        setIsOpenDeleteDialog(false);
        setIsOpenExistedDialog(true);
      }
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    handleClose();
    getAllServices();
  };

  const handleExistedResponse = () => {
    setIsOpenExistedDialog(false);
  };

  return (
    <Dialog
      open={isStaffProfileOpen}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: "1232px",
          overflowY: "hidden",
          minWidth: { xs: "auto", lg: "1232px" },
          height: "90vh",
          borderRadius: "16px",
        },
      }}
    >
      {(deleteServiceLoader || singleServiceDataLoader) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={deleteServiceLoader || singleServiceDataLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: customColors.darkestBlue,
          p: "24px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography
            sx={{
              fontSize: "24px",
              color: customColors.white,
            }}
          >
            {`${singleServiceData?.result?.data?.name}`}
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ bgcolor: "#FFFFFF" }}
          />
          <Typography
            sx={{
              fontSize: "24px",
              color: "#716AEA",
            }}
          >
            Treatment
          </Typography>
        </Box>
        <X
          size={24}
          style={{ cursor: "pointer" }}
          color={customColors.white}
          onClick={handleClose}
        />
      </Box>
      {/* side bar and other panel */}
      <Box sx={{ overflowY: "auto", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <SideBar
            setActiveItem={setActiveTabSideBar}
            activeItem={activeTabSideBar}
            handleRemoveItem={handleRemoveItem}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {renderTabContent(activeTabSideBar)}
          </Box>
        </Box>
      </Box>
      {isOpenExistedDialog && (
        <SPAAlertDialog
          open={isOpenExistedDialog}
          handleClose={() => setIsOpenExistedDialog(false)}
          handleNewTab={handleExistedResponse}
          title={"Can’t Remove This Treatment"}
          contentText={`You cannot remove this treatment because there are pending appointments, discount codes, articles, bundles, or deals associated with it. Please cancel those pending actions before proceeding with the removal of the treatment.`}
          disagreeText={`Go to the treatment`}
          agreeText={`Cancel`}
          type="error"
        />
      )}
      {isOpenDeleteDialog && (
        <SPAAlertDialog
          open={isOpenDeleteDialog}
          handleClose={() => setIsOpenDeleteDialog(false)}
          handleNewTab={handleDeleteService}
          title={"Treatment Removal Confirmation"}
          contentText={`Removing this treatment will permanently delete it from the system. This action cannot be undone. Are you sure you want to proceed with the removal?`}
          disagreeText={`Permanently Remove Treatment`}
          agreeText={`Cancel`}
          type="error"
        />
      )}
    </Dialog>
  );
};
export default TreatmentView;
