import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, Typography } from "@mui/material";
import Invoice from "./Invoice";
import {
  CheckCircle,
  Envelope,
  PaperPlaneTilt,
  Printer,
} from "@phosphor-icons/react";
import SPAInputField from "../../hook-form/SPAInputField";

// Validation schema for the form
const validationSchema = Yup.object().shape({
  emailReceipt: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});

const EmailReceiptForm = ({ invoicePayload }) => {
  const [isEmailReceipt, setIsEmailReceipt] = useState(false);
  const [isSuccessMsg, setIsSuccessMsg] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = (values) => {
    setIsEmailReceipt(false);
    setIsSuccessMsg(true);
    reset();
  };

  useEffect(() => {
    setIsEmailReceipt(false);
    setIsSuccessMsg(false);
  }, []);

  const payload = {
    ccv: "1234",
    expireDate: "2024-07-17T19:00:00.000Z",
    cardNumber: "1234567891234567",
    cardName: "ghjkl",
    buyTime: "2024-07-11T07:06:25.000Z",
    buyDate: "2024-07-11T07:06:25.000Z",
    client: "6686ac061fb46b3adc3d7dc9",
    totalPriceWithGST: "286.00",
    gst: "26.00",
    products: [
      { product: "668b9899a756ee001eb30a99", quantity: 1 },
      { product: "6686824c2ee4676b032de915", quantity: 1 },
      { product: "667eaa3b2f06ee001e757586", quantity: 1 },
      { product: "6675ae49c644cf001e2b2efc", quantity: 1 },
    ],
    businessLocation: "6666de95437e106a50d956ae",
  };

  const handlePrintClick = () => {
    setShowInvoice(true);
  };

  return (
    <>
      <Box component="form">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "16px",
          }}
        >
          <Button
            sx={{
              display: "flex",
              height: "48px",
              padding: "0px 24px",
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              background: "#EDECFC",
              color: "#4E45E5",
            }}
            onClick={handlePrintClick}
          >
            <Printer size={24} color="#4E45E5" />
            <Typography
              sx={{ color: "#4E45E5", fontSize: "14px", fontWeight: 600 }}
            >
              Print receipt
            </Typography>
          </Button>
          <Button
            onClick={() => {
              setIsEmailReceipt(!isEmailReceipt);
            }}
            sx={{
              display: "flex",
              height: "48px",
              padding: { xs: "0px 10px", sm: "0px 24px" },
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              background: isEmailReceipt ? "#A7A2F2" : "#EDECFC",
              "&:hover": {
                background: isEmailReceipt ? "#A7A2F2" : "#EDECFC",
                color: "#FFF",
              },
            }}
          >
            <Envelope size={24} color="#4E45E5" />
            <Typography
              sx={{ color: "#4E45E5", fontSize: "14px", fontWeight: 600 }}
            >
              Email receipt
            </Typography>
          </Button>
        </Box>
        {isEmailReceipt && (
          <Box sx={{ display: "flex", gap: "16px", mt: "16px" }}>
            <SPAInputField
              name="emailReceipt"
              placeholder="Email"
              prefixIcon={<Envelope size={"24px"} color="#C1C2C3" />}
              control={control}
              error={errors.emailReceipt?.message}
            />
            <Button
              sx={{
                display: "flex",
                width: "54px",
                height: "44px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                background: "#4E45E5",
                "&:hover": {
                  backgroundColor: "#4E45E5",
                },
              }}
              onClick={handleSubmit(onSubmit)}
            >
              <PaperPlaneTilt size={24} color="#fff" />
            </Button>
          </Box>
        )}
        {isSuccessMsg && (
          <Typography
            sx={{
              color: "#13A10E",
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              justifyContent: "center",
              mt: "16px",
            }}
          >
            Send <CheckCircle size={24} color="#11930D" weight="fill" />
          </Typography>
        )}
      </Box>
      {/* {showInvoice && <Invoice invoicePayload={invoicePayload} />} */}
    </>
  );
};

export default EmailReceiptForm;
