import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useGetAllTemplatesQuery,
  useGetSingleTemplateQuery,
  useGetTemplatesMutation,
  useUpdateTemplateMutation,
} from "../../../services/templates";
import { handleFilterSearch } from "../../../utils/searchFilter";
import { validationSchema } from "./validationSchema";

export default function useTemplatesLibrary() {
  const editorRef = useRef([]);
  const [searchTemplate, setSearchTemplate] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [activeTemplateTab, setActiveTemplateTab] = useState(0);
  const [templateDataType, setTemplateDataType] = useState("");
  const [isSuccessMsg, setIsSuccessMsg] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const validationSchemaInstance = validationSchema(templateType);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [deleteTemplateData, setDeleteTemplateData] = useState(null);
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
  });
  const [
    getAllTemplates,
    { data: templatesData, isLoading: templatesDataLoader },
  ] = useGetTemplatesMutation();

  const [createTemplate, { isLoading: createTemplateLoader }] =
    useCreateTemplateMutation();
  const { data: singleTemplate, isLoading: singleTemplateLoader } =
    useGetSingleTemplateQuery(templateId?._id, {
      skip: !templateId?._id,
    });
  const [updateTemplate] = useUpdateTemplateMutation();
  const [
    deleteTemplate,
    { data: deleteData, isLoading: deleteTemplateLoader },
  ] = useDeleteTemplateMutation();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const onSubmit = async (values) => {
    setIsSuccessMsg(false);
    let payload;
    if (templateType === "SMS") {
      payload = {
        name: values?.name,
        message: values?.message,
        type: "SMS",
      };
    }
    if (templateType === "EMAIL") {
      payload = {
        name: values?.name,
        message: values?.message,
        subject: values?.subject,
        type: "EMAIL",
      };
    }
    let response;

    if (singleTemplate?.result?.data?._id && templateId?._id) {
      response = await updateTemplate({
        id: singleTemplate?.result?.data?._id,
        payload,
      });
    } else {
      response = await createTemplate(payload);
    }

    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    // refetchTemplatesData();
    getAllTemplates();
    toast.success(response?.data?.message);
    setIsSuccessMsg(true);
    reset({
      name: "",
      message: "",
      subject: "",
    });
  };

  const handleDeleteTemplate = async () => {
    let response;
    if (templateId?._id) {
      response = await deleteTemplate(templateId?._id);
    }
    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    // refetchTemplatesData();
    getAllTemplates();
    setIsOpenDeleteDialog(false);
    setTemplateId("");
  };
  const handleTabSelection = (item) => {
    setActiveTemplateTab(item);
    switch (item) {
      case 1:
        setTemplateType("EMAIL");
        setTemplateDataType("EMAIL");
        break;
      case 2:
        setTemplateType("SMS");
        setTemplateDataType("SMS");
        break;
      default:
        setTemplateType("");
        setTemplateDataType("");
        break;
    }
  };

  const handleSingleTemplate = (item) => {
    setTemplateId(item);
    setTemplateType(item?.type);
  };
  const handleCreateTemplate = () => {
    reset({
      name: "",
      message: "",
      // replyTo: "",
      subject: "",
    });
    setTemplateId("");
  };

  const handleSearchTemplate = () => {
    let attributeArray = [];
    if (searchTemplate !== "") {
      if (isNaN(searchTemplate)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchTemplate,
        attributeArray,
        "search"
      );
      getAllTemplates({ ...searchPayload });
    } else {
      getAllTemplates({});
    }
  };

  useEffect(() => {
    handleSearchTemplate();
  }, [searchTemplate]);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      [`type=${templateDataType}`],
      ["type"],
      "filter",
      "and"
    );

    if (templateDataType !== "") {
      getAllTemplates({ ...searchPayload });
    } else {
      getAllTemplates();
    }
  }, [templateDataType]);

  useEffect(() => {
    if (singleTemplate && templateId?._id) {
      setValue("message", singleTemplate?.result?.data?.message);
      setValue("subject", singleTemplate?.result?.data?.subject ?? "");
      setValue("name", singleTemplate?.result?.data?.name);
    } else {
      setValue("message", "");
      setValue("subject", "");
      setValue("name", "");
    }
  }, [singleTemplate, templateId?._id]);

  useEffect(() => {
    getAllTemplates();
  }, []);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    isSuccessMsg,
    setIsSuccessMsg,
    createTemplateLoader,
    templatesData,
    singleTemplateLoader,
    templatesDataLoader,
    activeTemplateTab,
    setActiveTemplateTab,
    templateType,
    setTemplateType,
    handleTabSelection,
    setTemplateId,
    templateId,
    handleSingleTemplate,
    handleCreateTemplate,
    reset,
    editorRef,
    handleDeleteTemplate,
    isOpenDeleteDialog,
    setIsOpenDeleteDialog,
    deleteTemplateLoader,
    searchTemplate,
    setSearchTemplate,
  };
}
