import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import SPADialogue from "../../common/spa-dialogue/index.jsx";
import { cancelAppointmentFormData } from "./cancelAppointmentData.js";
import useCancelAppointment from "./useCancelAppointment";
export default function CancelAppointmentModel({
  cancelAppointmentModel,
  handleCancelAppointmentModel,
  rowData,
}) {
  const {
    control,
    methods,
    handleSubmit,
    onSubmit,
    availableType,
    errors,
    cancelAppointmentLoader,
  } = useCancelAppointment(rowData, handleCancelAppointmentModel);

  const formData = cancelAppointmentFormData(availableType);
  return (
    <SPADialogue
      open={cancelAppointmentModel}
      handleClose={handleCancelAppointmentModel}
      dialogTitle={
        <>
          Cancel Appointment with{" "}
          {`${rowData?.client?.firstName} ${rowData?.client?.lastName}`}
        </>
      }
      cancelText="Close"
      submitText="Cancel Appointment"
      loading={cancelAppointmentLoader}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      methods={methods}
    >
      <Box
        sx={{
          width: "688px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          pr: "8px",
        }}
      >
        <Grid container spacing={4}>
          {formData.map((item, index) => {
            return (
              <Grid item lg={item.gridLength} xs={12} key={index}>
                <item.component
                  {...item.otherOptions}
                  control={control}
                  error={item.type !== "input" ? errors : ""}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </SPADialogue>
  );
}
