import { useEffect, useState } from "react";
import { useGetClientAppointmentQuery } from "../../services/clients";
import { convertTo12HourFormat } from "../../utils/convertTimeIOSFormat";
import { useDispatch } from "react-redux";
import { setClientDetails } from "../../store/features/client-details";
import { useNavigate } from "react-router-dom";

export default function useGlobalSearch(handleCloseSearchModel) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchClient, setSearchClient] = useState("");
  const [searchDataArray, setSearchDataArray] = useState({
    clients: [],
    appointments: [],
  });
  const [isClientProfileModalOpen, setIsClientProfileModalOpen] =
    useState(false);

  const {
    data: ClientsAppointmentData,
    refetch: refetchData,
    isLoading: clientAppointmentLoader,
    isFetching: isClientAppointmentFetching,
  } = useGetClientAppointmentQuery(searchClient);

  const handleClientAppointmentArray = () => {
    if (ClientsAppointmentData?.result?.data !== null) {
      const clientArray = ClientsAppointmentData?.result?.data?.clients;
      const appointmentArray =
        ClientsAppointmentData?.result?.data?.appointments;

      let clientsData = [];
      let appointmentsData = [];
      if (clientArray && clientArray.length > 0) {
        clientsData = clientArray.map((client) => {
          return {
            id: client?._id,
            clientStatus: client.clientStatus,
            name: `${client.firstName} ${client.lastName}`,
            imgSource: client.profilePictureUrl,
          };
        });
      }

      if (appointmentArray && appointmentArray.length > 0) {
        appointmentsData = appointmentArray.map((appointment) => {
          const isCurrentContradication =
            appointment.client.contraindication.length > 0 &&
            appointment.client.contraindication[
              appointment.client.contraindication.length - 1
            ]?.text
              ? true
              : false;

          return {
            id: appointment._id,
            name: `${appointment.client.firstName} ${appointment.client.lastName}`,
            services: appointment.services[0].name,
            serviceStartTime: convertTo12HourFormat(appointment.startTime),
            roomName: appointment.room.name,
            serviceTime: appointment.services[0].hours,
            color: appointment.services[0].category?.color,
            condition: {
              isDepositPaid: appointment.isPaidFull,
              isInvoicePaid: false,
              isBookedOnline: false,
              isContraindication: isCurrentContradication,
              isNotes: appointment.client?.notes.length > 0 ? true : false,
              isRecurringAppointment: appointment.isRecurring,
              isMaintenance: false,
              isNewClient:
                appointment.client.clientStatus === "NEW" ? true : false,
              isClientAttended:
                appointment?.status === "COMPLETED" ? true : false,
              isClientNoShow: appointment?.status === "NO_SHOW" ? true : false,
              isAppointmentCancelled:
                appointment?.status === "CANCELLED" ? true : false,
            },
          };
        });
      }
      setSearchDataArray({
        clients: clientsData,
        appointments: appointmentsData,
      });
    }
  };

  // handle client profile modal
  const handleOpenClientProfile = (id) => {
    if (
      ClientsAppointmentData?.result?.data &&
      ClientsAppointmentData?.result?.data?.clients.length > 0
    ) {
      const client = ClientsAppointmentData?.result?.data?.clients.filter(
        (client) => client._id === id
      );
      dispatch(setClientDetails(client[0]));
    }
    setIsClientProfileModalOpen(true);
  };

  const handleCloseClientProfile = () => {
    setIsClientProfileModalOpen(false);
    handleCloseSearchModel();
  };

  useEffect(() => {
    if (!searchClient) setSearchDataArray({ clients: [], appointments: [] });
    refetchData();
  }, [searchClient]);

  useEffect(() => {
    handleClientAppointmentArray();
  }, [ClientsAppointmentData]);

  return {
    navigate,
    dispatch,
    searchClient,
    setSearchClient,
    searchDataArray,
    handleOpenClientProfile,
    handleCloseClientProfile,
    isClientProfileModalOpen,
    clientAppointmentLoader,
    isClientAppointmentFetching,
  };
}
