import { Navigate } from "react-router-dom";
import { ROLE } from "../enums/roles";
import { getLocalStorageItem } from "../utils/localStorage";
import { permissionData } from "../layout/dashboard-layout/sidebar.data";

// To-do: fix protected routes can't access unprotected routes
export const AuthGuard = ({ children, allowedRoles, allowedPermissions }) => {
  let token = getLocalStorageItem("auth-token");

  if (!token) {
    return <Navigate to="/login" />;
  }
  let userDetails = getLocalStorageItem("userData");
  const allRoles = Object.values(ROLE);
  const combinedPermissions = permissionData.concat(
    userDetails?.rolesDetails?.permissions
  );
  if (allowedRoles.indexOf(userDetails?.role) !== -1) {
    // && (combinedPermissions.includes(allowedPermissions)
    if (
      userDetails?.role !== "SUPER_ADMIN" &&
      combinedPermissions.includes(allowedPermissions)
    ) {
      return <>{children}</>;
    } else {
      return <>{children}</>;
    }
  }

  if (
    allowedRoles.indexOf(userDetails?.role) === -1 ||
    allRoles.indexOf(userDetails?.role) === -1
    // || !permissionData.includes(allowedPermissions)
  ) {
    if (
      userDetails?.role !== "SUPER_ADMIN" &&
      !combinedPermissions.includes(allowedPermissions)
    ) {
      return <Navigate to="/unauthorized" />;
    } else {
      return <Navigate to="/unauthorized" />;
    }
  }
};
