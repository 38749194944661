import * as Yup from "yup";

export const validationSchema = () => {
  let schema = Yup.object().shape({
    client: Yup.string().required("Client is required"),
    products: Yup.string().required("Product is required"),

    // products: Yup.object()
    //   .shape({
    //     label: Yup.string(),
    //   })
    //   .nullable()
    //   .test(
    //     "is-object",
    //     (value) => value !== null && typeof value === "object" && value.label
    //   ),
  });

  return schema;
};
