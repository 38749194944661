import { Box, Typography } from "@mui/material";
import { Circle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import DotsIcon from "../../../assets/svg/dots-icon";
import { useGetRolesMutation } from "../../../services/roles";
import { fw600 } from "../../../theme";
import { handleFilterSearch } from "../../../utils/searchFilter";

const useRoles = () => {
  const [searchRole, setSearchRole] = useState("");
  const [rolesPopoverOpen, setRolesPopoverOpen] = useState(null);
  const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false);
  const [singleRoleId, setSingleRoleId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);
  const [isOpenAddRoleDialog, setIsOpenAddRoleDialog] = useState(false);
  // Variables for Roles popover state and IDs
  const openRolesPopOver = Boolean(rolesPopoverOpen);
  const rolesPopOverId = openRolesPopOver ? "simple-popover" : undefined;
  const [getAllRoles, { data: rolesData, isLoading: rolesDataLoader }] =
    useGetRolesMutation();
  const rolesTableHeader = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#4A4C4F",
                  fontSize: "14px",
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                <Circle
                  size={24}
                  weight="fill"
                  color={rowData[tableMeta?.rowIndex]?.color}
                />
                {value}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                setRolesPopoverOpen(e.currentTarget);
                setSingleRoleId(rowData[tableMeta?.rowIndex]?._id);
              }}
            >
              <DotsIcon />
            </Box>
          );
        },
      },
    },
  ];
  const handleNewRole = () => {
    setIsRolesDialogOpen(true);
  };

  const handleCloseSuccessPopup = () => {
    setIsOpenSuccessDialog(false);
  };

  const handleAddRole = async () => {
    // setIsOpenAddRoleDialog(true);
    handleCloseSuccessPopup();
  };

  const handleSearchStaff = () => {
    let attributeArray = [];
    if (searchRole !== "") {
      if (isNaN(searchRole)) attributeArray = ["name", "color"];
      else {
        attributeArray = ["name", "color"];
      }

      const searchPayload = handleFilterSearch(
        searchRole,
        attributeArray,
        "search"
      );
      getAllRoles({ ...searchPayload });
    } else getAllRoles({});
  };

  useEffect(() => {
    handleSearchStaff();
  }, [searchRole]);

  useEffect(() => {
    getAllRoles({});
  }, []);

  return {
    singleRoleId,
    setSingleRoleId,
    rolesTableHeader,
    isRolesDialogOpen,
    setIsRolesDialogOpen,
    rolesPopoverOpen,
    setRolesPopoverOpen,
    rolesPopOverId,
    openRolesPopOver,
    handleNewRole,
    rolesData,
    rolesDataLoader,
    currentPage,
    setCurrentPage,
    isOpenSuccessDialog,
    setIsOpenSuccessDialog,
    handleCloseSuccessPopup,
    handleAddRole,
    searchRole,
    setSearchRole,
    isOpenAddRoleDialog,
    setIsOpenAddRoleDialog,
    getAllRoles,
  };
};

export default useRoles;
