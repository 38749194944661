import { Box, Button } from "@mui/material";
import React from "react";
import SPADialogue from "../../../common/spa-dialogue/index.jsx";
import AddQualification from "../add-qualification/index.jsx";
import ProductStep from "../product-step/index.jsx";
import TreatmentInfo from "../treatment-info/index.jsx";
import { SubmitBtn, cancelBtn } from "./new-services-style.js";
import useNewServices from "./useNewServices";
import BufferTime from "../buffer-time/index.jsx";

export default function NewServicesForm({
  isNewServicesOpen,
  handleClose,
  getAllServices,
  currentPage,
}) {
  const {
    errors,
    control,
    methods,
    handleSubmit,
    onSubmit,
    handleCloseForm,
    createServiceLoader,
    serviceCategoryData,
    treatmentStepper,
    setTreatmentStepper,
    qualificationsDataLoader,
    qualificationsData,
    searchQualification,
    setSearchQualification,

    // Product section
    searchProduct,
    setSearchProduct,
    activeItem,
    setActiveItem,
    isSearchBox,
    setIsSearchBox,
    selectedProduct,
    setSelectedProduct,
    handleAddProduct,
    handleQuantityChange,
    allProductData,
    productDataLoading,
    butterType,
    setBufferType,
    productTypeData,
    handleRemove,
    toggleSwitch,
    enabled,
    setEnabled,
    getMeasurementAbbreviation,
    handleRequiredOptional,
    setActiveHistoryTab,
    handleRemoveProduct,
  } = useNewServices(handleClose, getAllServices, currentPage);

  return (
    <>
      <SPADialogue
        open={isNewServicesOpen}
        handleClose={handleCloseForm}
        dialogTitle={`Create Treatment (Step ${treatmentStepper} of 4)`}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        methods={methods}
      >
        {treatmentStepper === 1 && (
          <TreatmentInfo
            serviceCategoryData={serviceCategoryData}
            control={control}
          />
        )}

        {treatmentStepper === 2 && (
          <BufferTime
            serviceCategoryData={serviceCategoryData}
            control={control}
            butterType={butterType}
            setBufferType={setBufferType}
          />
        )}

        {treatmentStepper === 3 && (
          <Box sx={{ width: "633px" }}>
            <AddQualification
              errors={errors}
              qualificationsDataLoader={qualificationsDataLoader}
              qualificationsData={qualificationsData}
              searchQualification={searchQualification}
              setSearchQualification={setSearchQualification}
              control={control}
              methods={methods}
            />
          </Box>
        )}
        {treatmentStepper === 4 && (
          <ProductStep
            handleRequiredOptional={handleRequiredOptional}
            handleQuantityChange={handleQuantityChange}
            handleAddProduct={handleAddProduct}
            selectedProduct={selectedProduct}
            allProductData={allProductData}
            productDataLoading={productDataLoading}
            setActiveItem={setActiveItem}
            activeItem={activeItem}
            isSearchBox={isSearchBox}
            setIsSearchBox={setIsSearchBox}
            setSearchProduct={setSearchProduct}
            searchProduct={searchProduct}
            errors={errors}
            productCategoryData={productTypeData?.result?.data?.data}
            handleRemove={handleRemove}
            enabled={enabled}
            setEnabled={setEnabled}
            toggleSwitch={toggleSwitch}
            getMeasurementAbbreviation={getMeasurementAbbreviation}
            setActiveHistoryTab={setActiveHistoryTab}
            handleRemoveProduct={handleRemoveProduct}
          />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "4px",
            paddingInline: "12px",
            py: 1.5,
            position: "sticky",
            bottom: 0,
            background: "#fff",
            zIndex: 1,
          }}
        >
          <Box>
            {treatmentStepper > 1 && (
              <Button
                sx={cancelBtn}
                onClick={() => setTreatmentStepper(treatmentStepper - 1)}
              >
                Back
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "4px",
              paddingInline: "12px",
              py: 1.5,
              position: "sticky",
              bottom: 0,
              background: "#fff",
              zIndex: 1,
            }}
          >
            <Button onClick={() => handleCloseForm()} sx={cancelBtn}>
              Cancel
            </Button>
            <Button type={"submit"} sx={SubmitBtn}>
              {treatmentStepper === 4 ? "Create Treatment" : "Next"}
            </Button>
          </Box>
        </Box>
      </SPADialogue>
    </>
  );
}
