import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAddNoteAppointmentMutation } from "../../../services/appointment";
import {
  useCreateRoomNoteMutation,
  useCreateUserNoteMutation,
  useUpdateNoteMutation,
} from "../../../services/notes";
import { useGetRoomsMutation } from "../../../services/rooms";
import { useGetServicesMutation } from "../../../services/services";
import { handleCloseNoteModal } from "../../../store/features/notes";
import { notesValidationSchema } from "../validationSchema";
import { handleFilterSearch } from "../../../utils/searchFilter";
import { useGetStaffsMutation } from "../../../services/staff";

export default function useCreateNote(handleCreateNoteModel) {
  const openNoteModal = useSelector(
    (state) => state?.notesModal?.openNewNoteModal
  );
  const noteDetails = useSelector((state) => state?.notesModal?.noteDetails);
  const [createNoteType, setCreateNoteType] = useState("calendar");
  const [availableType, setAvailableType] = useState(
    noteDetails?.selectedSlotType?.toUpperCase() ?? "ROOM"
  );

  const dispatch = useDispatch();
  const [repeatOn, setRepeatOn] = useState([""]);
  const [addAppointmentNote] = useAddNoteAppointmentMutation();
  const validationSchemaInstance = notesValidationSchema(
    availableType,
    noteDetails?.noteType
  );
  const methods = useForm({ resolver: yupResolver(validationSchemaInstance) });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;
  const [
    getAllServices,
    { data: servicesData, isLoading: servicesDataLoader },
  ] = useGetServicesMutation();
  const [getAllUsers, { data: usersData, isLoading: usersDataLoader }] =
    useGetStaffsMutation();
  const [getAllRooms, { data: roomData, isLoading: roomDataLoader }] =
    useGetRoomsMutation();
  const [createUserNote] = useCreateUserNoteMutation();
  const [createRoomNote] = useCreateRoomNoteMutation();
  const [updateNote] = useUpdateNoteMutation();

  const onSubmit = async (values) => {
    // Combine date and time
    const timeFormatter = (date, time) => {
      // Or, if time is just a time without a date:
      const dateFormat = dayjs(date).format("YYYY-MM-DD");
      const timeFormat = dayjs()
        .set("hour", dayjs(time).hour())
        .set("minute", dayjs(time).minute())
        .set("second", dayjs(time).second())
        .format("HH:mm:ss.SSS");
      return `${dateFormat}T${timeFormat}Z`;
    };

    let payload = { note: values.notes, isPinned: values?.isPinned };

    if (
      noteDetails?.rowData?._id &&
      noteDetails?.editType &&
      noteDetails?.noteType === "appointment"
    ) {
      const response = await updateNote({
        id: noteDetails?.rowData?._id,
        payload,
      });
      if (response?.error?.data?.status === false) {
        toast.error(response?.error?.data?.error);
        return;
      }
      toast.success(response?.data?.message);
      reset();
      handleCreateNoteModel();
      dispatch(handleCloseNoteModal());
    } else if (noteDetails?.noteType === "appointment") {
      const response = await addAppointmentNote({
        id: noteDetails?.rowData?._id,
        payload,
      });
      if (response?.error?.data?.status === false) {
        toast.error(response?.error?.data?.error);
        return;
      }
      toast.success(response?.data?.message);
      reset();
      handleCreateNoteModel();
      dispatch(handleCloseNoteModal());
    } else {
      payload = {
        from: {
          date: new Date(values?.startDate),
          time: timeFormatter(values?.startDate, values?.startTime),
        },
        to: {
          date: new Date(values?.endDate),
          time: timeFormatter(values?.endDate, values?.endTime),
        },
        replacePinned: false,
        ...payload,
      };

      if (values?.user) {
        const response = await createUserNote({
          id: values?.user,
          payload,
        });
        if (response?.error?.data?.status === false) {
          toast.error(response?.error?.data?.error);
          return;
        }
        handleCreateNoteModel();
        dispatch(handleCloseNoteModal());
        reset();
      }
      if (values?.room) {
        const response = await createRoomNote({
          id: values?.room,
          payload,
        });
        if (response?.error?.data?.status === false) {
          toast.error(response?.error?.data?.error);
          return;
        }
        handleCreateNoteModel();
        dispatch(handleCloseNoteModal());
        reset();
      }
    }
    reset();
  };

  useEffect(() => {
    if (noteDetails?.selectedSlotType?.toLowerCase() === "staff") {
      setValue("user", noteDetails?.selectedSlotData?.resourceId);
    }
    if (noteDetails?.selectedSlotType?.toLowerCase() === "room") {
      setValue("room", noteDetails?.selectedSlotData?.resourceId);
    }
    setValue("startDate", noteDetails?.selectedSlotData?.start);
    setValue("endDate", noteDetails?.selectedSlotData?.end);
    setValue("startTime", noteDetails?.selectedSlotData?.slots[0]);
    setValue("endTime", noteDetails?.selectedSlotData?.slots?.at(-1));
    setValue("notes", noteDetails?.rowData?.note);
    setValue("isPinned", noteDetails?.rowData?.isPinned);
    setValue("isNotifyEveryone", noteDetails?.rowData?.isNotifyEveryone);
  }, [noteDetails]);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      ["status=ACTIVE"],
      ["status"],
      "filter"
    );
    getAllServices();
    getAllRooms({ ...searchPayload });
    getAllUsers({ roleType: "STAFF" });
  }, []);

  return {
    control,
    errors,
    handleSubmit,
    onSubmit,
    methods,
    createNoteType,
    setCreateNoteType,
    availableType,
    setAvailableType,
    repeatOn,
    setRepeatOn,
    servicesData: servicesData?.result?.data?.data,
    usersData,
    roomData: roomData?.result?.data?.data,
    openNoteModal,
    noteDetails,
  };
}
