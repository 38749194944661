import { fs12, fs48 } from "../theme";

// add the color to the background and Characters of 2 initials
const stringToColor = (string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string?.length; i += 1) {
    hash = string?.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `45${value?.toString(16)}`.slice(-2);
  }
  return color;
};

// use to convert the complete name into initials

export const nameInitialsAvatar = (name, size) => {
  return {
    sx: {
      width: size ? size : "48px",
      height: size ? size : "48px",
      fontWeight: "700",
      align: "center",
      cursor: "pointer",
      fontSize: size ? fs48 : fs12,
    },
    src: `https://ui-avatars.com/api/?background=${stringToColor(
      name
    )}&rounded=false&font-size=0.30&name=${name}`,
  };
};
