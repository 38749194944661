import * as Yup from "yup";

export const productValidationSchema = () => {
  let schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    productType: Yup.string().required("Product Type is required"),
    description: Yup.string(),
    measurement: Yup.string().required("Measurement is required"),
    stock: Yup.number().typeError("Stock must be a number").nullable(),
    addStock: Yup.number().typeError("Stock must be a number").nullable(),
    removeStock: Yup.number().typeError("Stock must be a number").nullable(),
  });

  return schema;
};

export const qualificationValidationSchema = () => {
  let schema = Yup.object().shape({
    qualification: Yup.array()
      .of(Yup.string())
      .min(1, "At least one Qualification must be selected")
      .required("Selection of  Qualification is required."),
  });

  return schema;
};
