export const labelStyle = (theme) => ({
  fontSize: "14px !important",
  color: "#5E6062 !important",
  marginBottom: "8px",
});

export const datePickerWrapper = {
  paddingTop: "0 !important",
  "& > div": { width: "100% !important" },
};

export const datePickerStyle = (theme) => ({
  paddingBlock: "0 !important",
  flexBasis: "100%",
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #FFAA33  !important",
  },
  "& div": {
    // border: `1px solid ${theme.palette.borderGray100.main} !important`,
    // outline: "none !important",
    borderRadius: "6px !important",
    background: "white !important",
    // "&:hover": {
    //   border: `1px solid ${theme.palette.borderGray100.main} !important`,
    //   outline: "none !important",
    // },
  },
  "& .MuiFormHelperText-root": {
    marginLeft: "0 !important",
  },
  "& input": {
    margintTop: "0",
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "40px",
    borderRadius: "6px",
  },
  "& fieldset": {
    // border: `none !important`,
    // outline: "none !important",
    // "&:hover": {
    //   border: `none !important`,
    //   outline: "none !important",
    // },
  },
});
