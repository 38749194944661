import { Children, isValidElement, cloneElement } from "react";
import { getLocalStorageItem } from "../utils/localStorage";

export function PermissionProtected(props) {
  const { children, permission, disabled } = props;
  const userDetails = getLocalStorageItem("userData");
  if (!permission) {
    const singleChild = Children.only(children);
    return isValidElement(singleChild) ? singleChild : null;
  }
  const hasPermission = Boolean(
    userDetails?.rolesDetails?.permissions.includes(permission)
  );
  if (!hasPermission && userDetails?.role !== "SUPER_ADMIN") {
    if (disabled) {
      return (
        <>
          {Children.map(children, (child) => {
            return isValidElement(child)
              ? cloneElement(child, { disabled: true })
              : child;
          })}
        </>
      );
    }
    return null;
  }

  return (
    <>
      {Children.toArray(children).map((child) => {
        return isValidElement(child) ? child : null;
      })}
    </>
  );
}
