import dayjs from "dayjs";

export const convertTimeIosFormat = (str) => {
  try {
    const date = new Date(str);

    const utcDate = new Date(date.toISOString());

    const year = utcDate.getUTCFullYear();
    const month = ("0" + (utcDate.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + utcDate.getUTCDate()).slice(-2);

    return [year, month, day].join("-");
  } catch (error) {
    console.error("Error occurred during date conversion:", error);
    throw error;
  }
};

export const convertTo12HourFormat = (time) => {
  const [hours, minutes] = time.split(":");
  const hours12 = hours % 12 || 12;
  const amPm = hours < 12 ? "AM" : "PM";
  return `${hours12}:${minutes} ${amPm}`;
};

export const calculateTimeDifference = (startTime, endTime) => {
  const hours1 = Number(dayjs(startTime)?.format("HH"));
  const minutes1 = Number(dayjs(startTime)?.format("mm"));
  const hours2 = Number(dayjs(endTime)?.format("HH"));
  const minutes2 = Number(dayjs(endTime)?.format("mm"));
  // const [hours1, minutes1] = startTime.split(":").map(Number); // Convert to numbers
  // const [hours2, minutes2] = endTime.split(":").map(Number); // Convert to numbers

  // Calculate the difference in minutes
  return Math.abs(hours1 * 60 + minutes1 - (hours2 * 60 + minutes2));
};

export const calculateTimeDifferenceWithCurrentTime = (dateString) => {
  const currentDate = new Date();
  const providedDate = new Date(dateString);

  const timeDifference = currentDate - providedDate;

  const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);
  const monthsDifference = Math.floor(daysDifference / 30);

  let result;
  if (monthsDifference > 0) {
    result = `${monthsDifference} ${
      monthsDifference === 1 ? "month" : "months"
    }`;
  } else if (daysDifference > 0) {
    result = `${daysDifference} ${daysDifference === 1 ? "day" : "days"}`;
  } else if (hoursDifference > 0) {
    result = `${hoursDifference} ${hoursDifference === 1 ? "hour" : "hours"}`;
  } else {
    result = `${minutesDifference} ${
      minutesDifference === 1 ? "minute" : "minutes"
    }`;
  }

  return result;
};

export const calculateDayDifference = (date) => {
  const currentDate = dayjs();
  const givenDate = dayjs(date);
  return givenDate.diff(currentDate, "day");
};

export const convertTo24HourFormat = (time) => {
  const date = new Date(time);
  const hours = date.getUTCHours(); // Using getUTCHours to get the hours in UTC
  const minutes = date.getUTCMinutes();

  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
};

export const convertDate = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};
