import {
  ArrowClockwise,
  CalendarCheck,
  CallBell,
  Cloud,
  Door,
  HandHeart,
  Money,
  Note,
  NoteBlank,
  Prohibit,
  SealCheck,
  ShootingStar,
  Storefront,
  UsersFour,
  Warning,
  XCircle,
} from "@phosphor-icons/react";
import SPASelectField from "../hook-form/SPASelectField";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
export const gridView = [
  { label: "DAY", value: "day" },
  { label: "WEEK", value: "week" },
  { label: "MONTH", value: "month" },
];

export const busyTimeTypeData = [
  { label: "ONE-TIME", value: "ONE_TIME", icon: CalendarCheck },
  { label: "RECURRING", value: "RECURRING", icon: ArrowClockwise },
];

export const createNoteTypeData = [
  { label: "CALENDAR", value: "calendar", icon: CalendarCheck },
  { label: "TEAM NOTE", value: "team-note", icon: UsersFour },
];

export const availableSlotType = [
  { label: "ENTIRE LOCATION", value: "ENTIRE_LOCATION", icon: Storefront },
  { label: "ROOM", value: "ROOM", icon: Door },
  { label: "STAFF", value: "STAFF", icon: HandHeart },
  { label: "SERVICE", value: "SERVICES", icon: CallBell },
];

export const noteAddType = [
  { label: "ENTIRE LOCATION", value: "ENTIRE_LOCATION", icon: Storefront },
  { label: "ROOM", value: "ROOM", icon: Door },
  { label: "STAFF", value: "STAFF", icon: HandHeart },
];

export const weekDays = [
  { label: "MON", value: "MON" },
  { label: "TUE", value: "TUE" },
  { label: "WED", value: "WED" },
  { label: "THU", value: "THU" },
  { label: "FRI", value: "FRI" },
  { label: "SAT", value: "SAT" },
  { label: "SUN", value: "SUN" },
];

export const currentView = [
  { value: "Staff", icon: HandHeart },
  { value: "Room", icon: Door },
];

export const appointmentPricingDetail = (data) => {
  return [
    { id: 1, label: "Price", value: `$ ${data?.services[0]?.price}` },
    {
      id: 2,
      label: "Discount (1nd82h8h8s2) 10%",
      value: `$${data?.services[0]?.discount ?? "0.00"}`,
    },
    { id: 3, label: "Gift Voucher", value: "$0.00" },
  ];
};

export const singleSlotDetails = (info) => {
  return [
    { id: 1, label: "CONTRAINDICATIONS", value: "Open to view" },
    { id: 2, label: "NOTE", value: "Open to view" },
    { id: 3, label: "ROOM", value: info?.event?.room },
    { id: 4, label: "PAID", value: "$0.0" },
  ];
};

export const slotCategory = {
  CONTRAINDICATIONS: {
    icon: Warning,
  },
  NOTE: {
    icon: Note,
  },
  ROOM: {
    icon: Door,
  },
  PAID: {
    icon: Money,
  },
};

export const appointmentDetailsData = [
  {
    id: 1,
    appointmentTitle: "Contraindications",
    appointmentDesc:
      "Ergonomic executive chair upholstered in bonded black leather and PVC padded seat and back for all-day comfort and support",
    enableNotes: false,
    appointmentStatus: "Edited",
    appointmentDate: "3 Days Ago",
  },
];

export const appointmentStatusDesign = {
  Contraindications: {
    icon: Warning,
    bgColor: "#FFF2E0",
  },
  "Pinned Note": {
    icon: NoteBlank,
    bgColor: "#EDECFC",
  },
};

export const slotIcon = [
  { id: 1, icon: Money, tooltip: "Deposit Paid", condition: "isDepositPaid" },
  { id: 2, icon: Cloud, tooltip: "Booked Online", condition: "isBookedOnline" },
  {
    id: 3,
    icon: Warning,
    tooltip: "Contraindication",
    condition: "isContraindication",
  },
  {
    id: 4,
    icon: ShootingStar,
    tooltip: "New Client",
    condition: "isNewClient",
  },
  {
    id: 5,
    icon: SealCheck,
    tooltip: "Client Attended",
    condition: "isClientAttended",
  },
  {
    id: 6,
    icon: XCircle,
    tooltip: "Client didn't show",
    condition: "isClientNoShow",
  },
  {
    id: 7,
    icon: Note,
    tooltip: "Notes",
    condition: "isNotes",
  },
  {
    id: 8,
    icon: Prohibit,
    tooltip: "Cancelled",
    condition: "isAppointmentCancelled",
  },
];

export const updateOptionsFormData = (
  serviceData,
  usersData,
  roomData,
  availableType,
  updateValue
) => {
  let formData = [
    {
      key: "Room",
      gridLength: 12,
      otherOptions: {
        name: "room",
        label: "Room",
        value: updateValue,
        options:
          roomData &&
          roomData?.map((item) => ({
            label: item.name,
            value: item.name,
            id: item._id,
          })),
        placeholder: "Select Room",
      },
      component: SPASelectField,
    },
    {
      key: "Staff",
      gridLength: 12,
      otherOptions: {
        name: "user",
        label: "Staff",
        value: updateValue,
        options:
          usersData &&
          usersData?.map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: `${item.firstName} ${item.lastName}`,
            id: item._id,
          })),
        placeholder: "Select Staff",
      },
      component: SPASelectField,
    },
    {
      key: "Service",
      gridLength: 12,
      otherOptions: {
        name: "service",
        label: "Service",
        value: updateValue,
        options:
          serviceData &&
          serviceData?.map((item) => ({
            label: item.name,
            value: item.name,
            id: item._id,
          })),
        placeholder: "Select Service",
      },
      component: SPASelectField,
    },
    {
      key: "Extended Time Allowance",
      gridLength: 12,
      otherOptions: {
        name: "endTime",
        label: "This session can be extended as much as",
        options: [
          { id: "15", value: "15", label: "15 Minutes" },
          { id: "30", value: "30", label: "30 Minutes" },
          { id: "45", value: "45", label: "45 Minutes" },
          { id: "60", value: "60", label: "60 Minutes" },
        ],
        placeholder: "Select Extended Time",
      },
      component: SPASelectField,
    },
  ];

  // Filter based on availableType
  if (availableType) {
    formData = formData.filter((item) => item.key === availableType);
  }

  return formData;
};

export const renderColors = {
  warning: {
    color: "#FADB14",
    bgColor: "#FEFFE6",
  },
  success: {
    color: "#20C997",
    bgColor: "#E9FBF6",
  },
  appointmentPending: {
    color: "#EB2F96",
    bgColor: "#FFF0F6",
  },
  urgentAttention: {
    color: "#20C997",
    bgColor: "#E9FBF6",
  },
  externalEvent: {
    color: "#20C997",
    bgColor: "#E9FBF6",
  },
  informationUpdate: {
    color: "#20C997",
    bgColor: "#E9FBF6",
  },
  rescheduleNeeded: {
    color: "#FFAA33",
    bgColor: "#FFF9F0",
  },
  confirmedAppointment: {
    color: "#13A10E",
    bgColor: "#E7F6E7",
  },
  newClient: {
    color: "#1363DF",
    bgColor: "#E7EFFC",
  },
  cancellation: {
    color: "#EB2F96",
    bgColor: "#FFF0F6",
  },
};

export const transformEventData = (inputData, resourceTitle) => {
  let finalData;
  if (resourceTitle !== "Room") {
    finalData = inputData
      ?.filter((item) => item?.firstName)
      .map((list) => {
        return {
          resourceId: list?._id,
          resourceTitle: resourceTitle,
          resourceContent: `${list.firstName} ${list.lastName}`,
        };
      });
  } else {
    finalData = inputData?.map((item, index) => ({
      resourceId: item?._id,
      resourceTitle: resourceTitle,
      resourceContent: item?.name,
    }));
  }
  return finalData;
};

const formatDate = (date, time) => {
  const hours = new Date(time).getHours();
  const minutes = new Date(time).getMinutes();

  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const day = dateObj.getDate();

  return {
    year,
    month,
    day,
    hours,
    minutes,
  };
};

export const roomsEvents = (eventDetails, currentTabView) => {
  const combinedEvents = [
    ...(eventDetails?.appointments?.map((item) => ({
      ...item,
      eventType: "appointment",
    })) || []),
    ...(eventDetails?.busyTimes?.map((item) => ({
      ...item,
      eventType: "busyTime",
    })) || []),
    ...(eventDetails?.calendarNotes
      .filter((item) => item.from && item.to)
      .map((item) => ({
        ...item,
        eventType: "calendarNote",
      })) || []),
  ];

  return combinedEvents?.map((item) => {
    // Conditional handling based on the type of the event (appointment or busyTime or calendarNotes)
    const isAppointment = item?.eventType === "appointment";
    const isCalendarNote = item?.eventType === "calendarNote";
    const startDate = formatDate(
      isAppointment ? item?.date : item?.from?.date,
      isAppointment ? item?.startTime : item?.from?.time
    );
    const startDateFormat = new Date(
      startDate?.year,
      startDate?.month,
      startDate?.day,
      startDate?.hours,
      startDate?.minutes,
      0
    );

    const endDate = formatDate(
      isAppointment ? item?.date : item?.to?.date ?? undefined,
      isAppointment ? item?.endTime : item?.to?.time ?? undefined
    );
    const endDateFormat = new Date(
      endDate.year,
      endDate.month,
      endDate.day,
      endDate.hours,
      endDate.minutes,
      0
    );

    const minutesDifference = dayjs(item?.to?.time).diff(
      dayjs(item?.from?.time),
      "minutes"
    );
    const hoursDifference = minutesDifference / 60; // This will give you a decimal representation of hours
    const hoursDifferenceFormatted = parseFloat(hoursDifference?.toFixed(2));
    return {
      id: item?._id,
      title: isAppointment
        ? `${item?.client?.firstName} ${item?.client?.lastName}`
        : isCalendarNote
        ? item?.note
        : item?.reason,
      start: startDateFormat,
      end: endDateFormat,
      resourceId:
        currentTabView === "Room"
          ? isAppointment
            ? item?.room?._id
            : null
          : isAppointment
          ? item?.user?._id
          : item?.user?._id,
      service: isAppointment ? item?.services[0]?.name : undefined,
      staff: `${item?.user?.firstName} ${item?.user?.lastName}`,
      room: item?.room?.name,
      hours: isAppointment
        ? item?.services[0]?.hours
        : isCalendarNote
        ? undefined
        : hoursDifferenceFormatted,
      startTime: isAppointment ? item?.startTime : item.from.time,
      endTime: isAppointment ? item?.endTime : item.to.time,
      type: isAppointment ? "appointment" : "busyTime",
      color: isAppointment
        ? item?.services[0]?.category?.color
        : isCalendarNote
        ? "#A7A2F2"
        : "#25272B",
      serviceDetails: item?.services,
      status: isAppointment ? item?.client?.clientStatus : undefined,
      reason: isAppointment ? item?.reason : undefined,
      note: item?.note ?? undefined,
      isDepositPaid: item?.isPaidFull ? item?.isPaidFull : false,
      isInvoicePaid: false,
      isBookedOnline: false,
      isContraindication: item?.client?.contraindication?.text ? true : false,
      isNotes:
        item?.notes?.length > 0 || item?.client?.notes?.length > 0
          ? true
          : false,
      isRecurringAppointment: item?.isRecurring ? item?.isRecurring : false,
      isMaintenance: false,
      isNewClient: item?.client?.clientStatus === "NEW" ? true : false,
      isClientAttended: item?.status === "COMPLETED" ? true : false,
      isClientNoShow: item?.status === "NO_SHOW" ? true : false,
      isAppointmentCancelled: item?.status === "CANCELLED" ? true : false,
    };
  });
};
