import * as Yup from "yup";

export const validationSchema = () => {
  let schema = Yup.object().shape({
    discountCodeName: Yup.string().required("Name is required"),
    promoCode: Yup.string().required("Promo Code is required"),
    percentageAmount: Yup.string().required("Discount Percentage is required"),
    taxRate: Yup.string().required("Tax rate is required"),
    maxPerCustUsage: Yup.string(),
    maxPerTotalUsage: Yup.string(),
    minSpend: Yup.string(),
    maxTotalDiscountAmount: Yup.string(),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
    startTime: Yup.string().required("Start Time is required"),
    endTime: Yup.string().required("End Time is required"),
  });
  return schema;
};
