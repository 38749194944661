import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Plus, UserPlus, X } from "@phosphor-icons/react";
import React from "react";
import NoClients from "../../../assets/svg/no-clients";
import { fs14, fs20, secondary } from "../../../theme";
import SPAPopover from "../../common/spa-popover";
import SPATable from "../../common/spa-table";
import NewServicesForm from "./new-service";
import { servicesPopOverData } from "./services-data";
import useServices from "./useServices";
import { FilterList } from "@mui/icons-material";
import SPATextField from "../../common/spa-textField";
import TreatmentView from "./treatment-view";
/**
 * TreatmentsSection component.
 *
 * @returns {JSX.Element} JSX code for rendering the TreatmentsSection component.
 */
const TreatmentsSection = () => {
  const {
    searchService,
    setSearchService,
    singleServiceId,
    setSingleServiceId,
    servicesTableHeader,
    isServicesDialogOpen,
    setIsServicesDialogOpen,
    servicesPopoverOpen,
    setServicesPopoverOpen,
    servicesPopOverId,
    openServicesPopOver,
    handleNewServices,
    servicesData,
    servicesDataLoader,
    getAllServices,
    currentPage,
    setCurrentPage,
    handleCloseProfileOpen,
    handleOpenProfileOpen,
    isProfileOpen,
  } = useServices();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            disableElevation
            variant="contained"
            sx={{
              background: secondary.main,
              display: "flex",
              gap: "8px",
              alignItems: "center",
              borderRadius: "8px",
              height: "48px",
              "&:hover": { background: secondary.main },
            }}
            onClick={handleNewServices}
          >
            <Plus size={24} color="currentColor" />
            Create Treatment
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            <SPATextField
              placeholder="Search"
              value={searchService}
              sx={{
                width: { md: "328px", xs: "180px" },
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                    <MagnifyingGlass size={24} color={"#C1C2C3"} />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    onClick={() => {
                      setSearchService("");
                    }}
                  >
                    {searchService && <X size={24} />}
                  </InputAdornment>
                ),
              }}
              onChangeValue={(event) => {
                setSearchService(event.target.value);
              }}
            />

            <Box sx={styles.searchbox}>
              <FilterList sx={{ color: secondary.main }} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ pt: "16px" }}>
        {servicesDataLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={servicesDataLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {servicesData?.result?.data?.data?.length > 0 &&
          !servicesDataLoader && (
            <SPATable
              data={servicesData?.result?.data?.data}
              columns={servicesTableHeader}
              totalRecords={servicesData?.result?.data?.totalRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        {servicesData?.result?.data?.data?.length === 0 &&
          !servicesDataLoader && (
            <Box
              sx={{
                mt: "18px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <NoClients />
              <Typography sx={{ fontSize: fs20, color: "#595959" }}>
                There’s no treatment
              </Typography>
              <Typography sx={{ fontSize: fs14, color: "#595959" }}>
                Create a create treatment by clicking on the button Create
                Treatment'
              </Typography>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleNewServices}
              >
                <UserPlus size={24} color="currentColor" />
                Create Treatment{" "}
              </Button>
            </Box>
          )}
      </Box>

      {isServicesDialogOpen && (
        <NewServicesForm
          currentPage={currentPage}
          getAllServices={getAllServices}
          singleServiceId={singleServiceId}
          isNewServicesOpen={isServicesDialogOpen}
          handleClose={() => {
            setIsServicesDialogOpen(false);
            setSingleServiceId("");
          }}
        />
      )}
      {openServicesPopOver && (
        <SPAPopover
          id={servicesPopOverId}
          anchorEl={servicesPopoverOpen}
          width="215px"
          isOpen={openServicesPopOver}
          transformHorizontal="right"
          handleClose={() => {
            setServicesPopoverOpen(null);
          }}
          data={servicesPopOverData(
            setServicesPopoverOpen,
            handleOpenProfileOpen
          )}
        />
      )}
      {isProfileOpen && (
        <TreatmentView
          singleServiceId={singleServiceId}
          isStaffProfileOpen={isProfileOpen}
          handleClose={handleCloseProfileOpen}
          getAllServices={getAllServices}
        />
      )}
    </>
  );
};

export default TreatmentsSection;

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
