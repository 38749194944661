import * as Yup from "yup";

export const validationProfileSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  hours: Yup.number()
    .typeError("Duration must be a number")
    .required("Duration is required"),
  price: Yup.string()
    .required("Price Per Session is required")
    .min(0, "Price must be at least 0")
    .max(10000000, "Price must be at most 10000000"),
  description: Yup.string(),
  category: Yup.string().required("Category is required"),
});

export const productsValidationSchema = () => {
  let schema;
  schema = Yup.object().shape({
    products: Yup.array()
      .of(
        Yup.object().shape({
          product: Yup.string().required("Product name is required"),
          quantity: Yup.number()
            .typeError("Quantity must be a number")
            .required("Quantity is required")
            .min(1, "Quantity must be at least 1"),
        })
      )
      .min(1, "At least one Product must be selected")
      .required("Selection of Product is required."),
  });
  return schema;
};

export const qualificationValidationSchema = () => {
  let schema = Yup.object().shape({
    qualification: Yup.array()
      .of(Yup.string())
      .min(1, "At least one Qualification must be selected")
      .required("Selection of  Qualification is required."),
  });

  return schema;
};

export const treatmentOverviewValidationSchema = () => {
  let schema;
  schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    hours: Yup.number()
      .typeError("Duration must be a number")
      .required("Duration is required"),
    price: Yup.number()
      .typeError("Price must be a number")
      .required("Price Per Session is required")
      .min(0, "Price must be at least 0")
      .max(10000000, "Price must be at most 10000000"),
    description: Yup.string(),
    category: Yup.string().required("Category is required"),

    beforeBufferTimeMinutes: Yup.string().required("Duration is required"),
    afterBufferTimeMinutes: Yup.string().required("Duration is required"),
    afterBufferTimeDescription: Yup.string(),
    beforeBufferTimeDescription: Yup.string(),
  });
  return schema;
};

export const treatmentRequirementValidationSchema = () => {
  let schema;
  schema = Yup.object().shape({
    qualification: Yup.array()
      .of(Yup.string())
      .min(1, "At least one Qualification must be selected")
      .required("Selection of  Qualification is required."),

    products: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().required("Product name is required"),
        quantity: Yup.number()
          .typeError("Quantity must be a number")
          .required("Quantity is required")
          .min(1, "Quantity must be at least 1"),
        isRequired: Yup.string(),
      })
    ),
  });
  return schema;
};
