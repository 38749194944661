import * as Yup from "yup";

export const validationSchema = () => {
  let schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    category: Yup.string().required("Category is required"),
    description: Yup.string(),
    user: Yup.array().of(Yup.string()),
    services: Yup.array().of(Yup.string()),
  });
  return schema;
};
