import { Box, Typography } from "@mui/material";
import { Circle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import DotsIcon from "../../../assets/svg/dots-icon";
import { useGetServiceCategoriesMutation } from "../../../services/service-category";
import { fw600 } from "../../../theme";
import { handleFilterSearch } from "../../../utils/searchFilter";

const useServiceCategory = () => {
  const [searchServiceCategory, setSearchServiceCategory] = useState("");
  const [serviceCategoryPopoverOpen, setServiceCategoryPopoverOpen] =
    useState(null);
  const [isServiceCategoryDialogOpen, setIsServiceCategoryDialogOpen] =
    useState(false);
  const [singleServiceCategoryId, setSingleServiceCategoryId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // Variables for ServiceCategory popover state and IDs
  const openServiceCategoryPopOver = Boolean(serviceCategoryPopoverOpen);
  const ServiceCategoryPopOverId = openServiceCategoryPopOver
    ? "simple-popover"
    : undefined;
  const [
    getAllServiceCategory,
    { data: serviceCategoryData, isLoading: serviceCategoryDataLoader },
  ] = useGetServiceCategoriesMutation();
  const serviceCategoryTableHeader = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#4A4C4F",
                  fontSize: "16px",
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                {" "}
                <Circle
                  size={24}
                  weight="fill"
                  color={rowData[tableMeta?.rowIndex]?.color}
                />{" "}
                {value}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                setServiceCategoryPopoverOpen(e.currentTarget);
                setSingleServiceCategoryId(rowData[tableMeta?.rowIndex]?._id);
              }}
            >
              <DotsIcon />
            </Box>
          );
        },
      },
    },
  ];
  
  const handleNewServiceCategory = () => {
    setIsServiceCategoryDialogOpen(true);
  };

  const handleSearchServiceCategory = () => {
    let attributeArray = [];
    if (searchServiceCategory !== "") {
      if (isNaN(searchServiceCategory)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchServiceCategory,
        attributeArray,
        "search"
      );
      getAllServiceCategory({ ...searchPayload });
    } else getAllServiceCategory({});
  };

  useEffect(() => {
    handleSearchServiceCategory();
  }, [searchServiceCategory]);

  useEffect(() => {
    getAllServiceCategory({ page: currentPage, pageSize: 10 });
  }, [currentPage]);

  useEffect(() => {
    getAllServiceCategory();
  }, []);

  return {
    singleServiceCategoryId,
    setSingleServiceCategoryId,
    serviceCategoryTableHeader,
    isServiceCategoryDialogOpen,
    setIsServiceCategoryDialogOpen,
    serviceCategoryPopoverOpen,
    setServiceCategoryPopoverOpen,
    ServiceCategoryPopOverId,
    openServiceCategoryPopOver,
    handleNewServiceCategory,
    serviceCategoryData,
    serviceCategoryDataLoader,
    currentPage,
    setCurrentPage,
    getAllServiceCategory,
  };
};

export default useServiceCategory;
