import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { customColors, fw600, primary, secondary } from "../../theme";
import HistorySection from "./History";
import RemindersSection from "./Reminders";
import TemplatesLibrarySection from "./Templates-Library";
import { communicationsTabs } from "./communications-data";
import { resourceBox } from "./communicationsStyle";

const CommunicationsSection = () => {
  const [activeItem, setActiveItem] = useState("History");

  const renderTabContent = (tab) => {
    switch (tab) {
      case "History":
        return <HistorySection />;
      case "Reminders":
        return <RemindersSection />;
      case "Templates Library":
        return <TemplatesLibrarySection />;
      default:
        return <div>Select a tab</div>;
    }
  };
  return (
    <>
      <Typography sx={resourceBox.resourceLabel}>Communications</Typography>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            backgroundColor: primary.blue,
            borderRadius: "8px",
            width: "100%",
            p: "4px",
          }}
        >
          <Box
            sx={{
              height: "40px",
              display: "flex",
              backgroundColor: primary.blue,
              width: "max-content",
              overflow: "auto",
            }}
          >
            {communicationsTabs.map((item, index) => (
              <ListItemButton
                key={index}
                onClick={() => {
                  setActiveItem(item.link);
                }}
                sx={{
                  flexDirection: "row",
                  padding: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  gap: "6px",
                  backgroundColor: activeItem?.includes(item.link)
                    ? secondary.main
                    : primary.blue,
                  color: activeItem?.includes(item.link)
                    ? customColors.white
                    : secondary.main,
                  "&:hover": {
                    backgroundColor: activeItem?.includes(item.link)
                      ? secondary.main
                      : "auto",
                  },
                }}
              >
                {item.icon && (
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                    }}
                  >
                    <item.icon
                      size={20}
                      color={
                        activeItem?.includes(item.link)
                          ? customColors.white
                          : secondary.main
                      }
                    />
                  </ListItemIcon>
                )}

                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: fw600,
                      textTransform: "uppercase",
                      width: "max-content",
                    },
                  }}
                  primary={item.label}
                />
              </ListItemButton>
            ))}
          </Box>
        </Box>
        <Box sx={{ pt: "24px", minHeight: "90%" }}>
          {renderTabContent(activeItem)}
        </Box>
      </Box>
    </>
  );
};

export default CommunicationsSection;
