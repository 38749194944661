import React from 'react';
import SalesSection from '../../components/sales';

/**
 * Sales component.
 * Renders the Resource management page.
 *
 * @returns {JSX.Element} JSX code for rendering the Sales component.
 */

const Sales = () => {
  return <SalesSection />;
};

export default Sales;
