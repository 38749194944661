import { baseAPI } from "../baseApi";

export const RemindersApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllReminders: builder.query({
      query: ({ type, searchQuery }) => ({
        url: `/reminder?type=${type}&search=${searchQuery}`,
      }),
      providesTags: ["Reminders"],
    }),
    getReminders: builder.mutation({
      query: (body) => ({ url: "/reminder", method: "PATCH", body }),
      providesTags: ["Reminders"],
    }),
    createReminder: builder.mutation({
      query: (payload) => ({
        url: "/reminder",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Reminders"],
    }),
    getSingleReminder: builder.query({
      query: (reminderId) => ({ url: "/reminder/" + reminderId }),
      providesTags: ["singleReminder"],
    }),
    updateReminder: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/reminder/" + id,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["Reminders"],
    }),
  }),
});

export const {
  useGetAllRemindersQuery,
  useGetSingleReminderQuery,
  useCreateReminderMutation,
  useUpdateReminderMutation,
  useGetRemindersMutation,
} = RemindersApi;
