import {
  Box,
  ClickAwayListener,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Heart, MagnifyingGlass, Warning, X } from "@phosphor-icons/react";
import React from "react";
import ProductCard from "../../product-card";
import ProductCategoryTab from "../../../Treatments/products-tab";
import SPATextField from "../../../../common/spa-textField";
import { truncateText } from "../create-article-form-data";

const ProductComponent = ({
  isSearchBox,
  setIsSearchBox,
  selectedProduct,
  searchProduct,
  setSearchProduct,
  activeItem,
  setActiveItem,
  allProductData,
  productDataLoading,
  productCategoryData,
  handleAddProduct,
  errors,
  index,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        flexDirection: "column",
      }}
    >
      <Grid
        item
        lg={12}
        xs={12}
        sx={{
          position: "relative",
          width: "100%",
        }}
      >
        <SPATextField
          fullWidth
          name="search"
          placeholder="Search Products"
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "48px",
            },
          }}
          onClick={() => setIsSearchBox((prev) => ({ ...prev, [index]: true }))}
          onFocus={() => setIsSearchBox((prev) => ({ ...prev, [index]: true }))}
          value={searchProduct[index] || ""}
          onChangeValue={(event) => {
            setSearchProduct((prev) => ({
              ...prev,
              [index]: event.target.value,
            }));
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                <MagnifyingGlass size={24} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ paddingLeft: "10px", cursor: "pointer" }}
                onClick={() => {
                  setSearchProduct((prev) => ({ ...prev, [index]: "" }));
                }}
              >
                {searchProduct[index] && <X size={24} />}
              </InputAdornment>
            ),
          }}
        />
        {isSearchBox[index] && (
          <ClickAwayListener
            onClickAway={() => {
              setIsSearchBox((prev) => ({ ...prev, [index]: false }));
              setActiveItem("ALL");
            }}
          >
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                width: "100%",
                paddingRight: "16px",
                gap: "16px",
                borderRadius: "16px",
                background: "#FAFAFA",
                boxShadow:
                  "-66px 62px 25px 0px rgba(78, 69, 229, 0.00), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -3px 2px 8px 0px rgba(78, 69, 229, 0.05)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "164px",
                  padding: "8px",
                  flexDirection: "column",
                  gap: "4px",
                  borderRadius: "16px 0px 0px 16px",
                  background: "#FFF",
                }}
              >
                <ProductCategoryTab
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  tabsData={productCategoryData?.result?.data?.data}
                />
              </Box>
              <ProductCard
                productsData={allProductData?.result?.data?.data}
                productLoader={productDataLoading}
                handleSingleProductSelect={(item) =>
                  handleAddProduct(item, index)
                }
              />
            </Box>
          </ClickAwayListener>
        )}
        {errors[`product${index}`] && (
          <Typography
            sx={{
              color: "#FFAA33",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              mt: "5px",
            }}
            color="error"
          >
            <Warning color={"#FFAA33"} size={16} />
            {errors[`product${index}`]?.message}
          </Typography>
        )}
      </Grid>
      {selectedProduct[index] && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            paddingRight: "8px",
            alignItems: "center",
            gap: "8px",
            backgroundColor: "#FCF5F3",
            justifyContent: "space-between",
            overflow: "hidden",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              backgroundColor: "#FCF5F3",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                maxWidth: "85px",
                overflow: "hidden",
                height: "90px",
                flexShrink: 0,
              }}
            >
              <img
                src={selectedProduct[index]?.product?.imageUrl}
                alt="product"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  padding: "2px 4px",
                  background: "#070A0E",
                  color: "#FAFAFA",
                  fontSize: "10px",
                  textTransform: "uppercase",
                  maxWidth: "fit-content",
                  fontWeight: "500",
                }}
              >
                {selectedProduct[index]?.product?.productType?.name}
              </Box>
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: "14px",
                  fontWeight: "600",
                  letterSpacing: "0.014px",
                }}
              >
                {selectedProduct[index]?.product?.name}
              </Typography>
              {selectedProduct[index]?.product?.description && (
                <Typography
                  sx={{
                    color: "#5E6062",
                    fontSize: "12px",
                    letterSpacing: "0.048px",
                    display: "-webkit-box",
                    webkitBoxOrient: "vertical",
                    webkitLineClamp: 3,
                    overflow: "hidden",
                    width: "200px",
                  }}
                >
                  {truncateText(
                    `${selectedProduct[index]?.product?.description}`,
                    10
                  )}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: "1.5rem",
            }}
          >
            <Heart size={24} color="#5E6062" />
            {selectedProduct[index]?.product?.price && (
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: "14px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                ${selectedProduct[index]?.product?.price}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProductComponent;
