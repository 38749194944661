import { List, ListItem, ListItemText, useMediaQuery } from "@mui/material";
import Popover from "@mui/material/Popover";
import * as React from "react";
import { fs14, secondary } from "../../../theme";

/**
 * SPAPopover component.
 * Renders a Material-UI Popover with a list of items.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isOpen - If true, the popover is open.
 * @param {Function} props.handleClose - The function to close the popover.
 * @param {Array} props.data - The array of items to display in the popover list.
 * @param {string} props.id - The unique identifier for the popover.
 * @param {string} [props.popoverSx] - Additional styles for the popover.
 * @param {Function} [props.handleItemClick] - The function to handle item click events.
 * @param {string} [props.width='200px'] - The width of the popover.
 * @param {string} [props.anchorOriginVertical='bottom'] - The vertical origin for the anchor.
 * @param {string} [props.anchorOriginHorizontal='center'] - The horizontal origin for the anchor.
 * @param {string} [props.transformVertical='top'] - The vertical position for the popover.
 * @param {string} [props.transformHorizontal='center'] - The horizontal position for the popover.
 * @returns {JSX.Element} JSX code for rendering the SPAPopover component.
 */
export default function SPAPopover(props) {
  const {
    isOpen,
    anchorEl,
    handleClose,
    data,
    id,
    popoverSx,
    handleItemClick = () => {},
    width = "200px",
    anchorReference,
    anchorPosition,
    anchorOriginVertical = "bottom",
    anchorOriginHorizontal = "center",
    transformVertical = "top",
    transformHorizontal = "center",
  } = props;
  const isSidebarResponsive = useMediaQuery("(max-width:1165px)");
  return (
    <Popover
      id={id}
      disableScrollLock
      open={isOpen}
      anchorReference={anchorReference ? anchorReference : false}
      anchorPosition={anchorPosition ? anchorPosition : false}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: anchorOriginVertical,
        horizontal: anchorOriginHorizontal,
      }}
      transformOrigin={{
        vertical: transformVertical,
        horizontal: transformHorizontal,
      }}
      PaperProps={{ sx: { ...styles.popOverStyle(width, popoverSx) } }}
    >
      <List sx={styles.listWrapper}>
        {data.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item.component ? (
                <ListItem
                  sx={{
                    p: "12px 16px !important",
                    display: isSidebarResponsive ? "flex" : "none",
                    "&:hover": {
                      backgroundColor: "#F2F4F7 !important",
                      borderRadius: "4px !important",
                    },
                  }}
                >
                  {item.component}
                </ListItem>
              ) : (
                <ListItem
                  key={item.id}
                  onClick={
                    item.onClick
                      ? item.onClick
                      : (e) => handleItemClick(e, item)
                  }
                  sx={styles.listItem}
                >
                  {item.icon && (
                    <item.icon
                      size={24}
                      color={item?.color ? `${item?.color}` : "currentColor"}
                    />
                  )}
                  <ListItemText
                    sx={{
                      color: "red",
                      "& span": {
                        fontSize: fs14,
                        color: `${item?.color} !important`,
                      },
                    }}
                    primary={item?.label}
                  />
                </ListItem>
              )}
            </React.Fragment>
          );
        })}
      </List>
    </Popover>
  );
}

const styles = {
  popOverStyle: (width, popoverSx) => ({
    borderRadius: "16px !important",
    maxHeight: "428px",
    overflowY: "auto",
    mt: "8px",
    // p: "16px",
    width: width,
    boxShadow:
      "-3px 2px 8px 0px rgba(78, 69, 229, 0.05), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -66px 62px 25px 0px rgba(78, 69, 229, 0.00)",
    ...popoverSx,
  }),
  listWrapper: {
    width: "100%",
    // maxWidth: 360,
    p: "8px",
    m: 0,
  },
  listItem: {
    cursor: "pointer",
    gap: "11px",
    p: "12px 16px !important",
    borderRadius: "8px !important",
    color: secondary.main,
    "& .MuiListItemText-root": {
      "& span": {
        color: secondary.main,
      },
    },
    "&:hover": {
      backgroundColor: `#EDECFC !important`,
      color: "#4E45E5",
      "& .MuiListItemText-root": {
        "& span": {
          color: "#4E45E5",
        },
      },
    },
  },
};

// Example usage of SPAPopover with SPAButton
// const ExampleSPAPopover = () => {
// const [popoverOpen, setPopoverOpen] = useState(null);
// //popover mock data
// const popOverData = [
//   {
//     id: "1",
//     label: "View Profile",
//   },
//   {
//     id: "2",
//     label: "Change Password",
//   },
//   {
//     id: "3",
//     label: "Logout",
//   },
// ];

// const handleItemClick = (event) => {
//   setPopoverOpen(event.currentTarget);
// };

// const openPopOver = Boolean(popoverOpen);
// const popOverId = openPopOver ? "simple-popover" : undefined;

//     return (
//       <div>
//        // <ExpandMore
//     id={popOverId}
//     style={{ color: customColors.darkGrey, cursor: "pointer" }}
//     onClick={(event) => setPopoverOpen(event.currentTarget)}
//     />
//     <SPAPopover
//     id={popOverId}
//     isOpen={openPopOver}
//     anchorEl={popoverOpen}
//     handleClose={() => {
//         setPopoverOpen(null);
//     }}
//     data={popOverData}
//     onItemClick={handleItemClick}
// />
//       </div>
//     );
//   };
