import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { cardStyle, flexItem } from "./style";
import { fs14, fw400, fs10, fs12 } from "../../theme";
import theme from "../../theme/theme";
import PinnedIcon from "../../assets/svg/pinned-icon";

/**
 * AdditionalNote component.
 * Renders a card with additional note content and options.
 *
 * @returns {JSX.Element} JSX code for rendering the AdditionalNote component.
 */
const AdditionalNote = ({ content, isPinned, added, id, handlePinNotes }) => {
  const cardStl = cardStyle(theme, isPinned);
  

  return (
    <Box sx={cardStl}>
      {isPinned && (
        <Box sx={{ display: "flex", gap: "4px" }}>
          <PinnedIcon />
          <Typography
            fontSize={fs12}
            fontWeight={fw400}
            color={theme.palette.textGraySecondary.main}
            mb={1}
          >
            Pinned Note
          </Typography>
        </Box>
      )}
      <Typography
        fontSize={fs14}
        fontWeight={fw400}
        color={theme.palette.textGraySecondary.main}
        mb={2}
      >
        {content}
      </Typography>
      <Box sx={flexItem}>
        <Button fontSize={fs10} fontWeight={fw400} onClick={handlePinNotes} sx={{padding: "0px !important"}}>
          {isPinned ? "unPin Note" : "Pin Note"}
        </Button>
        <Typography  fontSize={fs12}>
          {added === 0 ? "Recently Added" : `Added ${added} ago`}
        </Typography>
      </Box>
    </Box>
  );
};

export default AdditionalNote;
