import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useGetAllCouponQuery,
  useCreateCouponMutation,
  useGetSingleCouponQuery,
} from "../../../../../../../../services/coupon";
import { validationSchema } from "./validationSchema";

export default function useDiscountCode({ singleProductDetails }) {
  const [activeCouponTab, setActiveCouponTab] = useState(0);
  const [isSuccessMsg, setIsSuccessMsg] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [couponId, setCoupnId] = useState("");
  const [openDialogCreateCoupon, setOpenDialogCreateCoupon] = useState(false);
  const [isOpenEditPromoCodeDialog, setIsOpenEditPromoCodeDialog] =
    useState(false);

  const toggleSwitch = () => {
    setEnabled(!enabled);
  };
  const handleSelectCoupon = (id) => {
    setCoupnId(id);
  };
  const handleOpenEditPromoCodeDialog = () => {
    setIsOpenEditPromoCodeDialog(true);
  };
  const handleOpenCreateCoupon = () => {
    setOpenDialogCreateCoupon(true);
  };
  const handleCloseCreateCoupon = () => {
    setOpenDialogCreateCoupon(false);
  };

  const handleClosePromoCodeDialog = () => {
    setIsOpenEditPromoCodeDialog(false);
  };
  const methods = useForm({
    resolver: yupResolver(validationSchema()),
  });

  const {
    data: couponData,
    isLoading: couponDataLoader,
    refetch: couponDataRefetching,
  } = useGetAllCouponQuery(singleProductDetails?.productType?.type);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const [createCoupon, { isLoading: createCouponLoading }] =
    useCreateCouponMutation();

  const onSubmit = async (values) => {
    let payload;
    // payload = {
    //   name: values?.name,
    // };

    couponDataRefetching();
    reset(values);
  };

  const handleTabSelection = (item) => {
    setActiveCouponTab(item);
  };

  // const handleCreateCommunication = () => {
  //   reset({
  //     name: "",
  //   });
  // };

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    isSuccessMsg,
    setIsSuccessMsg,
    couponData,
    // activeHistoryTab,
    // historyType,
    // setHistoryType,
    handleTabSelection,
    // historyId,
    enabled,
    setEnabled,
    toggleSwitch,
    handleSelectCoupon,
    couponId,
    isOpenEditPromoCodeDialog,
    // handleEditPromoCodeDialog,
    // editPromoCodeHandleSubmit,
    // editPromoCodeOnSubmit,
    // editPromoCodeMethods,
    handleOpenEditPromoCodeDialog,
    handleClosePromoCodeDialog,
    reset,
    openDialogCreateCoupon,
    handleCloseCreateCoupon,
    handleOpenCreateCoupon,
    // selectedCoupon,
    // couponType,
  };
}
