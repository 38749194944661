import { baseAPI } from "../baseApi";

export const CouponApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllCoupon: builder.query({
      query: (couponType) => ({ url: "/coupon?type=" + couponType }),
      providesTags: ["allCoupons"],
    }),

    createCoupon: builder.mutation({
      query: (payload) => {
        return {
          url: `/coupon`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["allCoupons"],
    }),

    getSingleCoupon: builder.query({
      query: (id) => ({ url: `/coupon/${id}` }),
      providesTags: ["singleCoupon"],
    }),

    updateSingleCoupon: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/coupon/${id}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["allCoupons", "singleCoupon"],
    }),

    deleteSingleCoupon: builder.mutation({
      query: (id) => ({ url: `/coupon/${id}`, method: "DELETE" }),
      invalidatesTags: ["allCoupons"],
    }),
  }),
});

export const {
  useGetAllCouponQuery,
  useCreateCouponMutation,
  useGetSingleCouponQuery,
  useUpdateSingleCouponMutation,
  useDeleteSingleCouponMutation,
} = CouponApi;
