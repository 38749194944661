import * as Yup from "yup";

export const validationSchema = () => {
  let schema = Yup.object().shape({
    startTime: Yup.string().required("Start Time is required"),
    endTime: Yup.string().required("End Time is required"),
  });

  return schema;
};
