import {
  Archive,
  Broom,
  CallBell,
  Desktop,
  Door,
  FolderUser,
  HandHeart,
  HandbagSimple,
  UserCircle,
  UserGear,
  Tag,
  Book,
  Newspaper,
  DeviceMobile,
  SealPercent,
  Certificate,
  Clock,
} from "@phosphor-icons/react";

export const resourceManagementTabs = [
  {
    label: "Service Category",
    icon: CallBell,
    link: "Service Category",
  },
  {
    label: "Treatments",
    icon: CallBell,
    link: "Treatments",
  },
  {
    label: "Zones",
    icon: Door,
    link: "Zones",
  },
  {
    label: "Staff",
    icon: FolderUser,
    link: "Staff",
  },
  {
    label: "Products",
    icon: HandbagSimple,
    link: "Products",
  },
  // {
  //   label: "Discount Code",
  //   icon: Tag,
  //   link: "Discount Code",
  // },
  {
    label: "Articles",
    icon: Newspaper,
    link: "Articles",
  },
  {
    label: "Mobile App",
    icon: DeviceMobile,
    link: "Mobile App",
  },
  {
    label: "Deals & Packages",
    icon: Newspaper,
    link: "DealsAndPackages",
  },
  {
    label: "Qualification",
    icon: Certificate,
    link: "Qualification",
  },
  {
    label: "Timings",
    icon: Clock,
    link: "Timings",
  },
];

export const statusDesign = {
  ENABLED: {
    color: "#0D720A",
    bgColor: "#E7F6E7",
  },
  DISABLED: {
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  ACTIVE: {
    color: "#0D720A",
    bgColor: "#E7F6E7",
  },
  INACTIVE: {
    color: "#115ACB",
    bgColor: "#E7EFFC",
  },
  DRAFT: {
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  ON_LEAVE: {
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  PUBLISHED: {
    color: "#0D720A",
    bgColor: "#E7F6E7",
  },
  UNPUBLISHED: {
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  ARCHIVED: {
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
};

export const roleDesign = {
  STAFF: {
    color: "#9E1068",
    bgColor: "#FFF0F6",
    icon: HandHeart,
  },
  RECEPTIONIST: {
    color: "#5B8C00",
    bgColor: "#FCFFE6",
    icon: Desktop,
  },
  MANAGER: {
    color: "#0D469E",
    bgColor: "#E7EFFC",
    icon: UserCircle,
  },
  CLEANING: {
    color: "#0D469E",
    bgColor: "#E7EFFC",
    icon: Broom,
  },
  SPA_FAIRY: {
    color: "#0D469E",
    bgColor: "#E7EFFC",
    icon: Broom,
  },
  ADMIN: {
    color: "#AD8B00",
    bgColor: "#FEFFE6",
    icon: UserGear,
  },
  Admin: {
    color: "#AD8B00",
    bgColor: "#FEFFE6",
    icon: UserGear,
  },
};
