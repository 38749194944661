import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import {
  customColors,
  fw600,
  primary,
  secondary,
} from "../../../../../../../../theme";
import { Articles, DealsAndBundles, Overview, Package } from "./components";
import PromoCodeSection from "./components/promo-code";
import { TreatmentViewNavBarData } from "./treatment-profile-data";
import useTreatmentView from "./useTreatmentView";

export default function ProductDetails({
  isResponsive,
  singleProductDetails,
  singleProductRefetching,
}) {
  const { activeItem, setActiveItem } = useTreatmentView(singleProductDetails);
  const renderTabContent = (tab) => {
    switch (tab) {
      case "Overview":
        return (
          <Overview
            singleProductData={singleProductDetails}
            singleProductRefetching={singleProductRefetching}
          />
        );
      // case "Publishing":
      //   return (
      //     <Publishing
      //       singleProductData={singleProductDetails}
      //       singleProductRefetching={singleProductRefetching}
      //     />
      //   );
      case "Promo Codes":
        return (
          <PromoCodeSection
            singleProductDetails={singleProductDetails}
            singleProductRefetching={singleProductRefetching}
          />
        );

      case "Packages":
        return (
          <Package
            singleProductDetails={singleProductDetails}
            singleProductRefetching={singleProductRefetching}
          />
        );
      case "Articles":
        return <Articles />;
      case "DealsAndBundles":
        return <DealsAndBundles />;
      default:
        return <div>Select a tab</div>;
    }
  };
  return (
    <Box sx={{ width: "100%", p: "0px 24px 24px 24px" }}>
      <Box
        sx={{
          backgroundColor: primary.blue,
          borderRadius: "8px",
          width: "100%",
          p: "4px",
          position: "sticky",
          top: "24px",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            height: "48px",
            display: "flex",
            backgroundColor: primary.blue,
            width: "max-content",
            overflow: "auto",
          }}
        >
          {TreatmentViewNavBarData.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setActiveItem(item.link);
              }}
              sx={{
                flexDirection: "row",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                gap: "6px",
                backgroundColor: activeItem?.includes(item.link)
                  ? secondary.main
                  : primary.blue,
                color: activeItem?.includes(item.link)
                  ? customColors.white
                  : secondary.main,
                "&:hover": {
                  backgroundColor: activeItem?.includes(item.link)
                    ? secondary.main
                    : "auto",
                },
              }}
            >
              {item.icon && (
                <ListItemIcon
                  sx={{
                    minWidth: "auto",
                  }}
                >
                  <item.icon
                    size={20}
                    color={
                      activeItem?.includes(item.link)
                        ? customColors.white
                        : secondary.main
                    }
                  />
                </ListItemIcon>
              )}

              {!isResponsive && (
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: fw600,
                      textTransform: "uppercase",
                      width: "max-content",
                    },
                  }}
                  primary={item.label}
                />
              )}
            </ListItemButton>
          ))}
        </Box>
      </Box>
      <Box sx={{ pt: "38px", maxHeight: "72vh", overflowY: "auto" }}>
        {renderTabContent(activeItem)}
      </Box>
    </Box>
  );
}
