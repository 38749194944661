import { Bell, CalendarPlus, CreditCard } from "@phosphor-icons/react";
import dayjs from "dayjs";

export const notificationTabsArray = [
  { title: "ALL", icon: <Bell size={16} /> },
  {
    title: "NEW APPOINTMENT",
    icon: <CalendarPlus size={16} />,
  },
  { title: "SALES", icon: <CreditCard size={16} /> },
];

export const appointmentNotificationTabsArray = [
  {
    value: "Notes",
    title: "Notes",
  },
  { value: "Notification", title: "Notification" },
];
// we can delete this array as get data from api and make the array with same keys and colors according to type SCHEDULE and PURCHASE
//     // for purchased Colors
//     {
//       backgroundColor: "#E7EFFC",
//       color: "#082A5E",}

//     // for schedule Colors
//     {
//       backgroundColor: "#E9FBF6",
//       color: "#0F6148",}
export const notificationsElementArray = (dataArray) => {
  return dataArray;
};

export const filteredNotifyArray = (type, dataArray) => {
  const completeNotificationArray = notificationsElementArray(dataArray);

  if (type === 1)
    return completeNotificationArray.filter(
      (item) => item.type === "Appointment"
    );
  else if (type === 2)
    return completeNotificationArray.filter(
      (item) => item.type === "Purchased"
    );

  return completeNotificationArray;
};
const notesArray = (notesData) => {
  return notesData?.map((item) => ({
    isPinned: item?.isPinned,
    headerContent: item?.note,
    authorName: "John Doe",
    addOn: dayjs(item?.createdAt).format("DD/MM/YYYY"),
    appointmentId: item?.appointmentId,
  }));
};

export const arrangeNotesByPinned = (notesData) => {
  // Separate pinned and unpinned notes
  const pinnedNotes =
    notesArray(notesData)?.filter((note) => note.isPinned) ?? [];
  const unpinnedNotes =
    notesArray(notesData)?.filter((note) => !note.isPinned) ?? [];

  // Concatenate pinned notes on top of unpinned notes
  return [...pinnedNotes, ...unpinnedNotes];
};
