import { ExpandMore, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormLabel,
  InputAdornment,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useState } from "react";
import NoClients from "../../../assets/svg/no-clients";
import { HelperText } from "../../../styleComponents/style";
import {
  customColors,
  fs14,
  fs16,
  fw400,
  fw500,
  secondary,
} from "../../../theme";

/**
 * SPATextField component.
 * Renders a text field with customizable styles using Material-UI TextField component.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} [props.sx] - Additional styles using Material-UI sx prop.
 * @param {string} props.label - The label for the text field.
 * @param {ReactNode} pr/ops.children - The children for the text field.
 * @param {,...any} [props.rest] - Additional props to be passed to the underlying Material-UI TextField component.
 * @returns {JSX.Element} JSX code for rendering the SPATextField component.
 */

const SPATextField = ({
  sx,
  label,
  options,
  fullWidth = false,
  children,
  labelHelperText,
  readOnly = false,
  defaultValue = "",
  name,
  type,
  onChangeValue,
  onKeyDownValue,
  suffixIcon,
  prefixIcon,
  incDecButton = false,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(
    type === "password" ? true : false
  );
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: `1px solid ${secondary.main}`,
              },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${secondary.main}`,
            },
            "& .Mui-error .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #FFAA33  !important",
            },
            "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #FFAA33 !important",
              },
            "::placeholder": {
              fontFamily: "Open Sans",
              color: "#D0D5DD",
              opacity: 1,
              fontSize: "14px",
              fontWeight: "400px",
            },
            "& .MuiOutlinedInput-root": {
              backgroundColor: customColors.white,
              height: type === "textarea" ? "auto" : "44px",
              borderRadius: "6px",
            },
            "& .Mui-disabled": {
              backgroundColor: customColors.silverGrey,
              borderRadius: "6px",
            },
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              gap: "8px",
            },
            "& .MuiFormHelperText-root": { margin: 0 },
            "& input:-webkit-autofill": {
              boxShadow: "0 0 0 30px white inset !important",
              "-webkit-box-shadow": "0 0 0 30px white inset !important",
              "-webkit-text-fill-color": "#000 !important",
              borderRadius: "inherit",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
            fontSize: fs16,
            fontWeight: fw500,
            color: customColors.foundationBlack,
          },
        },
      },
    },
  });

  const rows = type === "textarea" ? 3 : null;

  const renderLabel = (selectedValue) => {
    const selectedOption = options?.find(
      (option) => option.value === selectedValue || option?.id === selectedValue
    );
    return selectedOption ? (
      selectedOption.label
    ) : (
      <span style={{ color: "#D0D5DD" }}>{rest.placeholder}</span>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        width: fullWidth ? "100%" : "auto",
      }}
    >
      {label && (
        <FormLabel
          sx={{
            color: "#5E6062",
            fontWeight: fw400,
            fontSize: fs14,
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            mb: labelHelperText && "4px",
          }}
        >
          {label}{" "}
          {!labelHelperText && (
            <Typography sx={{ color: "#D13438", pl: "3px" }}> *</Typography>
          )}
          {/* {labelHelperText && <HelperText>{labelHelperText}</HelperText>} */}
        </FormLabel>
      )}
      <ThemeProvider theme={theme}>
        <TextField
          onChange={onChangeValue}
          onKeyDown={onKeyDownValue}
          sx={{ ...sx }}
          multiline={type === "textarea"}
          rows={rows}
          style={{
            width: incDecButton && "auto",
            textAlign: incDecButton && "center",
          }}
          fullWidth={fullWidth}
          name={name}
          type={showPassword ? "password" : "text"}
          variant="outlined"
          {...(defaultValue && { defaultValue })}
          InputProps={{
            readOnly,
            ...((suffixIcon || type === "password") && {
              endAdornment: (
                <InputAdornment position="end">
                  {type !== "password" && suffixIcon}
                  {type === "password" && (
                    <Box
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </Box>
                  )}
                </InputAdornment>
              ),
            }),
            ...(prefixIcon && {
              startAdornment: (
                <InputAdornment position="start">{prefixIcon}</InputAdornment>
              ),
            }),
          }}
          SelectProps={{
            IconComponent: ExpandMore,
            MenuProps: { PaperProps: { style: { maxHeight: "250px" } } },
            displayEmpty: true,
            renderValue: (value) =>
              value === "" ? (
                <span style={{ color: defaultValue ? "black" : "#D0D5DD" }}>
                  {defaultValue ? defaultValue : rest.placeholder}
                </span>
              ) : (
                renderLabel(value)
              ),
          }}
          MenuProps={{
            PaperProps: {
              style: {
                background: customColors.white,
                border: "1px solid #DADADB",
                borderRadius: "8px",
                boxShadow: "0px 12px 16px -4px #10182814",
              },
            },
          }}
          {...rest}
        >
          {options?.length === 0 && (
            <MenuItem disabled value={null}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "15vh",
                  width: "100%",
                }}
              >
                <NoClients />
                <Typography sx={{ fontSize: "16px", color: "#595959" }}>
                  No Data Found
                </Typography>
              </Box>
            </MenuItem>
          )}
          {options &&
            options.map((option, index) => [
              <MenuItem
                key={option?.id || option.value}
                sx={{
                  fontSize: fs14,
                  fontWeight: fw400,
                  color: secondary.main,
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
                value={option?.id || option.value}
                disabled={option?.disabled}
              >
                {option?.icon && option.icon}
                {option.label}
              </MenuItem>,
              option.divider && index < options.length - 1 && (
                <Divider key={`divider-${index}`} />
              ),
            ])}
          {children}
        </TextField>
      </ThemeProvider>
    </Box>
  );
};

export default SPATextField;

// Example Usage
// <SPATextField
//       label="Example Label"
//       sx={{ width: "300px" }}
//       placeholder="Type something..."
//     />
