import { UserFocus } from "@phosphor-icons/react";
import SPAInputField from "../../../../../hook-form/SPAInputField";
import SPASelectField from "../../../../../hook-form/SPASelectField";
import ProfileImageUploader from "../../../../../profile-image-upload";

export const weekDays = [
  { label: "MON", value: "MON" },
  { label: "TUE", value: "TUE" },
  { label: "WED", value: "WED" },
  { label: "THU", value: "THU" },
  { label: "FRI", value: "FRI" },
  { label: "SAT", value: "SAT" },
  { label: "SUN", value: "SUN" },
];

export const createRetailsProductFormData = (
  productCategoryData,
  measurementValue
) => {
  return [
    {
      title: "General Information",
      icon: UserFocus,
      rowChildren: [
        {
          gridLength: 7.3,
          colChildren: [
            {
              gridLength: 12,
              otherOptions: {
                fullWidth: true,
                name: "name",
                label: "Product Name",
                placeholder: "i.e.: Soap for Face",
              },
              component: SPAInputField,
            },
            {
              gridLength: 12,
              otherOptions: {
                name: "productType",
                label: "Product Type",
                options:
                  productCategoryData &&
                  productCategoryData?.map((item) => {
                    return { id: item._id, label: item.name, value: item.name };
                  }),
                placeholder: "Select Product Type",
              },
              component: SPASelectField,
            },
          ],
        },
        {
          gridLength: 4.7,
          colChildren: [
            {
              colTitle: "Product Picture",
              isRequired: true,
              otherOptions: {
                width: "100px",
                height: "100px",
                uploadBtnText: "upload",
                name: "imageUrl",
                profileUpload: true,
                isRequired: true,
              },
              component: ProfileImageUploader,
            },
          ],
        },
        {
          gridLength: 12,
          colChildren: [
            {
              gridLength: 3,
              otherOptions: {
                fullWidth: true,
                name: "measurement",
                label: "Measurement",
                placeholder: "",
                options: [
                  { label: "Units", value: "UNIT" },
                  { label: "Pack", value: "PACK" },
                  { label: "Millilitre", value: "MILLILITRE" },
                  { label: "Each", value: "EACH" },
                  { label: "Litre", value: "LITRE" },
                ],
              },
              component: SPASelectField,
            },
            {
              gridLength: 3,
              otherOptions: {
                fullWidth: true,
                name: "stock",
                label: "Stock",
                placeholder: "0",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "price",
                label: `Price ${
                  measurementValue ? `Per ${measurementValue}` : ""
                }`,
                placeholder: "i.e.: $5.00",
              },
              component: SPAInputField,
            },
            {
              key: "description",
              gridLength: 12,
              otherOptions: {
                name: "description",
                label: "Description",
                labelHelperText: "(Optional)",
                type: "textarea",
                placeholder: "Add description here",
              },
              component: SPAInputField,
            },
          ],
        },
      ],
    },
  ];
};

export const updateRetailsProductFormData = (productCategoryData) => {
  return [
    {
      title: "General Information",
      icon: UserFocus,
      rowChildren: [
        {
          gridLength: 12,
          colChildren: [
            {
              colTitle: "Product Picture",
              isRequired: true,
              otherOptions: {
                width: "100px",
                height: "100px",
                uploadBtnText: "upload",
                name: "imageUrl",
                profileUpload: true,
                isRequired: true,
              },
              component: ProfileImageUploader,
            },
          ],
        },
        {
          gridLength: 12,
          colChildren: [
            {
              gridLength: 12,
              otherOptions: {
                fullWidth: true,
                name: "name",
                label: "Product Name",
                placeholder: "i.e.: Soap for Face",
              },
              component: SPAInputField,
            },
            {
              gridLength: 12,
              otherOptions: {
                name: "productType",
                label: "Product Type",
                options:
                  productCategoryData &&
                  productCategoryData?.map((item) => {
                    return { id: item._id, label: item.name, value: item.name };
                  }),
                placeholder: "Select Product Type",
              },
              component: SPASelectField,
            },
          ],
        },

        {
          gridLength: 12,
          colChildren: [
            // {
            //   gridLength: 6,
            //   otherOptions: {
            //     fullWidth: true,
            //     name: "price",
            //     label: "Unity Price",
            //     placeholder: "i.e.: $5.00",
            //   },
            //   component: SPAInputField,
            // },
            // {
            //   gridLength: 6,
            //   otherOptions: {
            //     fullWidth: true,
            //     name: "stock",
            //     label: "Stock",
            //     placeholder: "0",
            //   },
            //   component: SPAInputField,
            // },
            {
              key: "description",
              gridLength: 12,
              otherOptions: {
                name: "description",
                label: "Description",
                labelHelperText: "(Optional)",
                type: "textarea",
                placeholder: "Add description here",
              },
              component: SPAInputField,
            },
          ],
        },
      ],
    },
  ];
};
