import { Box, Typography } from "@mui/material";
import { Package } from "@phosphor-icons/react";
import React from "react";

const ProductCard = ({
  productsData,
  productLoader,
  handleSingleProductSelect,
  handleSingleProductDeselect,
  selectedProducts,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        p: "16px 0px",
        width: "100%",
      }}
    >
      {productsData?.length > 0 &&
        productsData?.map((item) => {
          const isSelected = selectedProducts
            ? selectedProducts.findIndex(
                (selected) => selected._id === item._id
              ) !== -1
            : false;
          return (
            <Box
              onClick={() => {
                if (selectedProducts) {
                  selectedProducts?.findIndex(
                    (selected) => selected._id === item._id
                  ) !== -1
                    ? handleSingleProductDeselect(item._id)
                    : handleSingleProductSelect(item);
                }
              }}
              key={item?._id}
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                width: "100%",
                height: "48px !important",
                paddingRight: "8px",
                alignItems: "center",
                gap: "8px",
                borderRadius: "8px",
                background: "#FFF",
                justifyContent: "space-between",
                overflow: "hidden",
                cursor: "pointer",
                bgcolor: isSelected ? "#E7EFFC" : "#FFFFFF",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "48px",
                    overflow: "hidden",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    src={
                      item?.imageUrl
                        ? item?.imageUrl
                        : "https://qa-temple-day-spa-s3.s3.ap-southeast-2.amazonaws.com/e69da1af-ceff-49d5-b780-20bcd96e607d-images.jpg"
                    }
                    alt="product"
                  />
                </Box>
                <Box>
                  <Box
                    sx={{
                      padding: "4px 8px",
                      background: "#FAFAFA",
                      color: "#25272B",
                      fontSize: "10px",
                      textTransform: "uppercase",
                      maxWidth: "fit-content",
                      borderRadius: "4px",
                    }}
                  >
                    {item?.productType?.name}
                  </Box>
                  <Typography
                    sx={{
                      color: "#5E6062",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {item?.name}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "row",
                    md: "column",
                  },
                }}
              >
                {/* {item?.price && (
                  <Typography
                    sx={{
                      color: "#5E6062",
                      fontSize: "14px",
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    ${item?.price}
                  </Typography>
                )} */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <Package size={12} color="#8F9193" />
                  <Typography
                    sx={{
                      color: "#8F9193",
                      fontSize: "10px",
                      textTransform: "uppercase",
                    }}
                  >
                    {item?.stock}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      {productsData?.length === 0 && !productLoader && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Box>
            <img src="/images/cart_icon.svg" alt="cart" />
          </Box>
          <Typography
            sx={{
              color: "#5E6062",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Not Products Listing Yet
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProductCard;
