import * as Yup from "yup";
import dayjs from "dayjs";

export const validationSchema = (newClientPopup) => {
  const today = dayjs().startOf("day").toDate();

  let schema = Yup.object().shape({
    client: Yup.string().required("Client Name is required"),
    room: Yup.string().required("Select any Room"),
    user: Yup.string().required("Select any Staff"),
    service: Yup.array()
      .min(1, "Select at least one Service")
      .required("Select any Service"),
    date: Yup.date()
      .min(today, "Date cannot be in the past")
      .required("Date is required"),
    startTime: Yup.string().required("Select Start Time"),
    endTime: Yup.string().required("Select End Time"),
    isPaidFull: Yup.boolean(),
    giftVoucher: Yup.string(),
  });

  if (newClientPopup) {
    schema = schema.shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      postalCode: Yup.number(),
      // .matches(/^\d{4}$/, "Invalid Format. Valid Format Four Characters 0000")
      mobileNumber: Yup.string()
        .matches(
          /^\+?\d{10,14}$/,
          "Invalid format. Provide Valid Format of 10 to 14 digits"
        )
        .test(
          "mobile-format",
          "Invalid format. Provide Valid Format of 10 to 14 digits",
          function (value) {
            if (!value) return true;
            return /^\+?\d{10,14}$/.test(value);
          }
        )
        .required("Mobile number is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    });
  }

  return schema;
};
