import { CallBell, HandHeart, Pencil } from "@phosphor-icons/react";

//popover mock data
export const qualificationsPopOverData = (
  setQualificationsPopoverOpen,
  setIsQualificationsDialogOpen,
  setIsOpenAddQualificationDialog,
  setStaffUpdateType
) => [
  {
    id: "1",
    label: "Edit",
    icon: Pencil,
    onClick: () => {
      setQualificationsPopoverOpen(null);
      setIsQualificationsDialogOpen(true);
    },
  },
  {
    id: "2",
    label: "Add to a Staff",
    icon: HandHeart,
    onClick: () => {
      setIsOpenAddQualificationDialog(true);
      setStaffUpdateType("Staff");
      setQualificationsPopoverOpen(null);
    },
  },
  {
    id: "3",
    label: "Add to a Treatment",
    icon: CallBell,
    onClick: () => {
      setIsOpenAddQualificationDialog(true);
      setStaffUpdateType("Treatment");
      setQualificationsPopoverOpen(null);
    },
  },
];
