import { Box, Grid, Typography } from "@mui/material";
import { Clock } from "@phosphor-icons/react";
import React from "react";
import SPASelectField from "../../../hook-form/SPASelectField";
import SPAInputField from "../../../hook-form/SPAInputField";

const BufferTime = ({
  isView = false,
  control,
  serviceCategoryData,
  butterType,
  setBufferType,
}) => {
  const IconCom = (IconView) => {
    return <IconView />;
  };

  const bufferTimeData = [
    {
      id: 1,
      label: "Before The Treatment",
      durationInput: "beforeBufferTimeMinutes",
      descriptionInput: "beforeBufferTimeDescription",
    },
    {
      id: 2,
      label: "After The Treatment",
      durationInput: "afterBufferTimeMinutes",
      descriptionInput: "afterBufferTimeDescription",
    },
  ];

  return (
    <Box
      sx={{
        // width: "688px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          background: "#F8F7FE",
          display: "flex",
          padding: "16px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
          minHeight: "200px",
          mb: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", gap: "4px" }}>
            <Box> {IconCom(Clock)}</Box>
            <Typography
              sx={{
                color: "#4A4C4F",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {" "}
              Buffer Time
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#5E6062",
              fontSize: "12px",
            }}
          >
            {" "}
            Add extra time to the treatment to prepare it for the next
            appointment.
          </Typography>
        </Box>
        <Grid
          container
          sx={{ display: "flex", alignItems: "center", gap: "16px" }}
        >
          {bufferTimeData?.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  p: "16px",
                  borderRadius: "8px",
                  bgcolor: "#FFFFFF",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", fontWeight: "600", mb: "4px" }}
                >
                  {item.label}
                </Typography>
                <Grid container spacing={1} sx={{ width: "100%" }}>
                  <Grid item xs={12} sm={12} md={6}>
                    <SPASelectField
                      control={control}
                      fullWidth={true}
                      name={item?.durationInput}
                      label="Buffer Duration"
                      placeholder="Select Duration"
                      options={[
                        { label: "0 minute", value: 0 },
                        { label: "5 minutes", value: 5 },
                        { label: "10 minutes", value: 10 },
                        { label: "15 minutes", value: 15 },
                        { label: "20 minutes", value: 20 },
                        { label: "25 minutes", value: 25 },
                        { label: "30 minutes", value: 30 },
                        { label: "35 minutes", value: 35 },
                        { label: "40 minutes", value: 40 },
                        { label: "45 minutes", value: 45 },
                        { label: "50 minutes", value: 50 },
                        { label: "55 minutes", value: 55 },
                        { label: "60 minutes", value: 60 },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <SPAInputField
                      control={control}
                      name={item?.descriptionInput}
                      label="Description"
                      labelHelperText="(Optional)"
                      type="textarea"
                      placeholder="Add the buffer time description here"
                    />
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default BufferTime;
