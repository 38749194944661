import { baseAPI } from "../baseApi";

export const ServiceCategoryApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllServiceCategory: builder.query({
      query: () => ({ url: "/service-category" }),
      providesTags: ["ServiceCategor"],
    }),
    getServiceCategories: builder.mutation({
      query: (payload) => ({
        url: "/service-category",
        method: "PATCH",
        body: payload,
      }),
      providesTags: ["ServiceCategory"],
    }),
    createServiceCategory: builder.mutation({
      query: (payload) => ({
        url: "/service-category",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["ServiceCategory"],
    }),
    getSingleServiceCategory: builder.query({
      query: (serviceCategoryId) => ({
        url: "/service-category/" + serviceCategoryId,
      }),
      providesTags: ["singleServiceCategory"],
    }),
    updateServiceCategory: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/service-category/" + id,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["ServiceCategory"],
    }),
  }),
});

export const {
  useGetServiceCategoriesMutation,
  useGetAllServiceCategoryQuery,
  useGetSingleServiceCategoryQuery,
  useCreateServiceCategoryMutation,
  useUpdateServiceCategoryMutation,
} = ServiceCategoryApi;
