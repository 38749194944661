import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import React from "react";
import SPADialogue from "../../../common/spa-dialogue/index.jsx";
import { newServiceCategoryFormData } from "./new-service-category-form-data.js";
import useNewServiceCategory from "./useNewServiceCategory.js";

export default function NewServiceCategoryForm({
  isNewServiceCategoryOpen,
  handleClose,
  singleServiceCategoryId,
}) {
  const {
    control,
    methods,
    handleSubmit,
    onSubmit,
    handleCloseForm,
    singleServiceCategoryLoader,
    createServiceCategoryLoader,
    updateServiceCategoryLoader,
  } = useNewServiceCategory(handleClose, singleServiceCategoryId);

  return (
    <>
      {singleServiceCategoryLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={singleServiceCategoryLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!singleServiceCategoryLoader && (
        <SPADialogue
          open={isNewServiceCategoryOpen}
          handleClose={handleCloseForm}
          dialogTitle={
            singleServiceCategoryId
              ? "Edit Service Category"
              : `Create Service Category`
          }
          cancelText="Cancel"
          submitText={
            singleServiceCategoryId ? "Save Changes" : "Create Service Category"
          }
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          methods={methods}
          loading={createServiceCategoryLoader || updateServiceCategoryLoader}
        >
          <Box
            sx={{
              width: "688px",
              display: "flex",
              flexDirection: "column",
              gap: "0px",
            }}
          >
            <Grid container spacing={1}>
              {newServiceCategoryFormData.map((item, index) => {
                return (
                  <Grid item lg={item.gridLength} xs={12} key={index}>
                    <item.component
                      methods={methods}
                      {...item.otherOptions}
                      control={control}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </SPADialogue>
      )}
    </>
  );
}
