import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { NoteBlank } from "@phosphor-icons/react";
import React from "react";
import { customColors, secondary } from "../../../theme/pallete.js";
import SPADialogue from "../../common/spa-dialogue/index.jsx";
import SPACheckBox from "../../hook-form/SPACheckBox.jsx";
import SPAInputField from "../../hook-form/SPAInputField.jsx";
import { createNoteTypeData, noteAddType, weekDays } from "../calendarData.js";
import {
  createNoteFormData,
  oneTimeFormData,
  recurringFormData,
} from "./createNoteData.js";
import { notesModelStyle, repeatOnBoxStyle } from "./createNoteStyle.js";
import useCreateNote from "./useCreateNote.js";

export default function CreateNoteModel({ handleCreateNoteModel }) {
  const {
    control,
    errors,
    servicesData,
    usersData,
    roomData,
    methods,
    handleSubmit,
    onSubmit,
    createNoteType,
    setCreateNoteType,
    availableType,
    setAvailableType,
    repeatOn,
    setRepeatOn,
    openNoteModal,
    noteDetails,
  } = useCreateNote(handleCreateNoteModel);

  const formData = createNoteFormData(
    servicesData,
    usersData?.result?.data?.data,
    roomData,
    availableType
  );

  return (
    <SPADialogue
      open={openNoteModal}
      handleClose={handleCreateNoteModel}
      dialogTitle={
        <>
          {noteDetails?.editType ? "Update" : "Create"} A Note
          <Typography
            sx={{ fontSize: "14px", fontWeight: "400", color: "#5E6062" }}
          >
            You can add notes to either view it on the calendar or only in the
            side panel.
          </Typography>
        </>
      }
      cancelText="Cancel"
      submitText={noteDetails?.editType ? "Save Changes" : "Create Note"}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      methods={methods}
    >
      <Box
        sx={{
          mb: 1.5,
          ...notesModelStyle.calendarHeaderMain,
        }}
      >
        <Box
          sx={{
            ...notesModelStyle.createNoteGrid,
          }}
        >
          <Grid item xs={12} mt={1}>
            <Typography>
              <FormLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#5E6062",
                  fontWeight: "600",
                  fontSize: "16px",
                  marginBottom: "8px",
                }}
              >
                <NoteBlank size={24} /> Note
              </FormLabel>
            </Typography>
            <SPAInputField
              name="notes"
              type="textarea"
              // errors={errors}
              placeholder="Write the annotation here"
              control={control}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <SPACheckBox
                name="isPinned"
                label=" Pin Note"
                control={control}
              />
              <SPACheckBox
                name="isNotifyEveryone"
                label=" Notify Everyone"
                control={control}
              />
            </Box>
          </Grid>
        </Box>
      </Box>
      {noteDetails?.noteType !== "appointment" && (
        <Box sx={notesModelStyle.calendarHeaderMain}>
          <Box sx={notesModelStyle.selectionGrid}>
            {createNoteTypeData.map((item) => {
              return (
                <Box
                  key={item.value}
                  onClick={() => {
                    setCreateNoteType(item.value);
                  }}
                  sx={{
                    background:
                      createNoteType === item.value ? secondary.main : "",
                    color:
                      createNoteType === item.value
                        ? customColors.white
                        : secondary.main,
                    ...notesModelStyle.gridView,
                  }}
                >
                  <item.icon size={24} /> {item.label}
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              width: "688px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              p: "16px 12px",
            }}
          >
            <Grid container spacing={4}>
              {(createNoteType === "calendar"
                ? oneTimeFormData()
                : recurringFormData()
              )?.map((item, index) => {
                return (
                  <Grid item lg={item.gridLength} xs={12} key={index}>
                    <item.component {...item.otherOptions} control={control} />
                  </Grid>
                );
              })}
              <Grid>
                {createNoteType === "team-note" && (
                  <Box sx={{ ml: "32px", mt: "16px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#5E6062",
                        mb: "8px",
                      }}
                    >
                      Repeat On
                    </Typography>
                    <Box sx={repeatOnBoxStyle.calendarHeaderMain}>
                      <Box sx={repeatOnBoxStyle.selectionGrid}>
                        {weekDays.map((item) => {
                          const isSelected = repeatOn.includes(item.value);
                          return (
                            <Box
                              key={item.value}
                              onClick={() => {
                                if (isSelected) {
                                  setRepeatOn(
                                    repeatOn.filter((day) => day !== item.value)
                                  );
                                } else {
                                  setRepeatOn([...repeatOn, item.value]);
                                }
                              }}
                              sx={{
                                background: isSelected ? secondary.main : "",
                                color: isSelected
                                  ? customColors.white
                                  : secondary.main,
                                ...repeatOnBoxStyle.gridView,
                              }}
                            >
                              {item.label}
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Box sx={{ ml: 4 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    m: "24px 0px 8px 0px",
                  }}
                >
                  Add To
                </Typography>
                <Box sx={notesModelStyle.calendarHeaderMain}>
                  <Box sx={notesModelStyle.selectionGrid}>
                    {noteAddType.map((item) => {
                      return (
                        <Box
                          key={item.value}
                          onClick={() => {
                            setAvailableType(item.value);
                          }}
                          sx={{
                            background:
                              availableType === item.value
                                ? secondary.main
                                : "",
                            color:
                              availableType === item.value
                                ? customColors.white
                                : secondary.main,
                            ...notesModelStyle.gridView,
                          }}
                        >
                          <item.icon size={24} /> {item.label}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "668px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    mt: "24px",
                  }}
                >
                  <Grid container spacing={4}>
                    {formData.map((item, index) => {
                      return (
                        <Grid item lg={item.gridLength} xs={12} key={index}>
                          <item.component
                            {...item.otherOptions}
                            control={control}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>
      )}
    </SPADialogue>
  );
}
