import { Box } from "@mui/material";
import React from "react";

const Appointment = ({ clientDetail }) => {
  return (
    <Box
      sx={{
        bgcolor: "#F8F7FE",
        m: "24px",
        p: "24px",
        borderRadius: "16px",
        height: "93%",
      }}
    >
      Appointment Section
    </Box>
  );
};
export default Appointment;
