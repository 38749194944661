import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useUpdateSingleStaffMutation } from "../../../../../../services/staff";
import { validationAddressSchema } from "./validationSchema";

export default function useStaffAddress({
  singleStaffData,
  singleStaffRefetching,
}) {
  const methods = useForm({
    resolver: yupResolver(validationAddressSchema),
  });
  const { handleSubmit, control, setValue, reset } = methods;
  const [updateSingleStaff, { isLoading: updatedLoader }] =
    useUpdateSingleStaffMutation();

  const onSubmit = async (values) => {

    let payload = {
      addresses: [
        {
          state: values?.state,
          street: values?.street,
          postalCode: values?.postalCode,
          suburb: values?.suburb,
        },
      ],
    };

    const response = await updateSingleStaff({
      id: singleStaffData?._id,
      payload: payload,
    });

    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success("Successfully Address Added!");
    singleStaffRefetching();
  };

  useEffect(() => {
    if (singleStaffData) {
      setValue("postalCode", singleStaffData?.addresses?.[0]?.postalCode);
      setValue("state", singleStaffData?.addresses?.[0]?.state);
      setValue("street", singleStaffData?.addresses?.[0]?.street);
      setValue("suburb", singleStaffData?.addresses?.[0]?.suburb);
    }
  }, [singleStaffData]);

  return {
    control,
    onSubmit,
    handleSubmit,
  };
}
