import { baseAPI } from "../baseApi";

export const ProductApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    updateAllProduct: builder.mutation({
      query: (payload) => {
        return {
          url: `/product`,
          method: "PATCH",
          body: payload,
        };
      },
    }),

    getProducts: builder.mutation({
      query: (body) => ({ url: "/product", method: "PATCH", body }),
      providesTags: ["allProduct"],
    }),

    getSingleProduct: builder.query({
      query: (id) => ({ url: `/product/${id}` }),
      providesTags: ["singleProduct"],
    }),

    updateSingleProduct: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/product/${id}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["allProduct", "singleProduct"],
    }),

    deleteSingleProduct: builder.mutation({
      query: (id) => ({ url: `/product/${id}`, method: "DELETE" }),
      invalidatesTags: ["allProduct"],
    }),

    deleteProduct: builder.mutation({
      query: (productId) => ({
        url: "/product/" + productId,
        method: "DELETE",
      }),
      providesTags: ["Product"],
    }),

    createProduct: builder.mutation({
      query: (payload) => {
        return {
          url: `/product`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["allProduct"],
    }),
    addProductToService: builder.mutation({
      query: ({ payload, productId }) => ({
        url: "/product/" + productId,
        method: "PATCH",
        body: payload,
      }),
      providesTags: ["allProduct"],
    }),
    getSingleProductPackage: builder.query({
      query: ({ productId, searchQuery, type }) => ({
        url: "/product/" + productId + `?search=${searchQuery}&type=${type}`,
      }),
      providesTags: ["singleProduct"],
    }),
  }),
});

export const {
  useGetSingleProductPackageQuery,
  useUpdateAllProductMutation,
  useGetSingleProductQuery,
  useUpdateSingleProductMutation,
  useDeleteSingleProductMutation,
  useCreateProductMutation,
  useGetProductsMutation,
  useDeleteProductMutation,
  useAddProductToServiceMutation,
} = ProductApi;
