import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import { customColors, primary, secondary } from "../../../theme";
import { productsTabs } from "./products-data";
import RetailsProducts from "./retails-products";
import ProfessionalProducts from "./professional-products";

const ProductSection = () => {
  const [activeItem, setActiveItem] = useState("Retails Products");

  const renderTabContent = (tab) => {
    switch (tab) {
      case "Retails Products":
        return <RetailsProducts />;
      case "Professional Product":
        return <ProfessionalProducts />;
      default:
        return <div>Select a tab</div>;
    }
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#F8F7FE",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            backgroundColor: primary.blue,
            borderRadius: "8px",
            width: "100%",
            p: "4px",
          }}
        >
          <Box
            sx={{
              height: "40px",
              display: "flex",
              backgroundColor: primary.blue,
              width: "max-content",
              overflow: "auto",
            }}
          >
            {productsTabs.map((item, index) => (
              <ListItemButton
                key={index}
                onClick={() => {
                  setActiveItem(item.link);
                }}
                sx={{
                  flexDirection: "row",
                  padding: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  gap: "6px",
                  backgroundColor: activeItem?.includes(item.link)
                    ? secondary.main
                    : primary.blue,
                  color: activeItem?.includes(item.link)
                    ? customColors.white
                    : secondary.main,
                  "&:hover": {
                    backgroundColor: activeItem?.includes(item.link)
                      ? secondary.main
                      : "auto",
                  },
                }}
              >
                {item.icon && (
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                    }}
                  >
                    <item.icon
                      size={20}
                      color={
                        activeItem?.includes(item.link)
                          ? customColors.white
                          : secondary.main
                      }
                    />
                  </ListItemIcon>
                )}

                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      width: "max-content",
                    },
                  }}
                  primary={item.label}
                />
              </ListItemButton>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            p: "24px",
            minHeight: "90%",
          }}
        >
          {renderTabContent(activeItem)}
        </Box>
      </Box>
    </>
  );
};

export default ProductSection;
