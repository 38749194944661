import React from "react";
import NoNotifications from "../../assets/svg/no-notifications";
import { CalendarNotifcationTab, AppointmentCard } from "./components";
import SPATabs from "../common/spa-tabs";
import { fs14, fs20, fs24 } from "../../theme";
import {
  arrangeNotesByPinned,
  filteredNotifyArray,
  notificationTabsArray,
} from "./notification-data";
import { Box, Button, Typography } from "@mui/material";
import { notificationStyle, collpaseButton } from "./style";
import useNotification from "./useNotification";
import ArrowRightIcon from "../../assets/svg/arrow-right-icon";
import Loader from "../common/loader";
import { Plus } from "@phosphor-icons/react";
import { handleOpenNoteModal } from "../../store/features/notes";

export default function NotificationPanel({ setIsOpened }) {
  const {
    isCalendarRoute,
    currentCalendarTabView,
    setCurrentCalendarTabView,
    activeNotifyTab,
    setActiveNotifyTab,
    notificationLoading,
    dataArray,
    dispatch,
    notesData,
  } = useNotification();

  let elementArray =
    currentCalendarTabView === "Notes" && isCalendarRoute
      ? arrangeNotesByPinned(notesData?.result?.data)
      : filteredNotifyArray(activeNotifyTab, dataArray);

  return (
    <>
      {!isCalendarRoute && (
        <Box sx={{ display: "flex" }}>
          <Box onClick={() => setIsOpened(false)} sx={collpaseButton}>
            <ArrowRightIcon />
          </Box>
          <Typography sx={{ fontSize: fs24, color: "#070A0E", pb: "24px" }}>
            Notification Center
          </Typography>
        </Box>
      )}

      {isCalendarRoute && (
        <Box sx={{ display: "flex" }}>
          <Box onClick={() => setIsOpened(false)} sx={collpaseButton}>
            <ArrowRightIcon />
          </Box>
          <Box sx={notificationStyle.panel}>
            <CalendarNotifcationTab
              setCurrentCalendarTabView={setCurrentCalendarTabView}
              currentCalendarTabView={currentCalendarTabView}
            />
          </Box>
        </Box>
      )}
      {isCalendarRoute && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              fontSize: "14px",
              color: "#4E45E5",
              display: "flex",
              gap: "4px",
              textTransform: "capitalize",
            }}
            onClick={() => dispatch(handleOpenNoteModal())}
          >
            <Plus color="#4E45E5" size={16} />
            Add Note
          </Button>
        </Box>
      )}
      {currentCalendarTabView === "Notification" || !isCalendarRoute ? (
        <SPATabs
          tabsNameArray={notificationTabsArray}
          activeTab={activeNotifyTab}
          setActiveTab={setActiveNotifyTab}
        />
      ) : null}

      {notificationLoading ? (
        <Loader />
      ) : (
        <>
          {elementArray?.length > 0 ? (
            <Box sx={{ height: "75vh", overflowY: "auto" }}>
              {elementArray?.map((notifyItem, i) => (
                <AppointmentCard key={i} notifyItem={notifyItem} />
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                mt: "18px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                gap: "24px",
              }}
            >
              <NoNotifications />
              <Typography sx={{ fontSize: fs20, color: "#595959" }}>
                No Notifications Yet
              </Typography>
              <Typography sx={{ fontSize: fs14, color: "#595959" }}>
                After creating appointments or sales, they will be displayed
                here as notifications.
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
}
