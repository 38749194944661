export const cardStyle = (customColors, isLatest) => ({
  padding: '16px',
  borderRadius: '16px',
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  mb: 3,
  backgroundColor: isLatest==="Current"
  ? customColors.milkyWhite
  : customColors.white,
});

export const flexItem = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};
