import { useTheme } from "@emotion/react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DiscountCode from "./components/change-password";
import SideBar from "./components/side-bar";
import StaffDetails from "./components/treatment-overview";
import { customColors } from "../../theme";
import { handleCloseUserProfileViewModal } from "../../store/features/user-profile";
// import { useGetSingleProductQuery } from "../../services/product";
import ChangePassword from "./components/change-password";
import { useGetSingleStaffQuery } from "../../services/staff";
import { getLocalStorageItem } from "../../utils/localStorage";

const UserProfileView = () => {
  const userProfileDetails = useSelector(
    (state) => state?.userProfile?.userProfileDetails
  );
  const openUserProfileViewModal = useSelector(
    (state) => state?.userProfile?.openUserProfileViewModal
  );
  let userDetails = getLocalStorageItem("userData");
  const dispatch = useDispatch();
  const [activeTabSideBar, setActiveTabSideBar] = useState("Profile");
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("md"));
  const {
    data: singleStaffData,
    isLoading: singleStaffDataLoader,
    refetch: singleStaffRefetching,
  } = useGetSingleStaffQuery(userDetails?.id);

  const renderTabContent = (tab) => {
    switch (tab) {
      case "Profile":
        return (
          <StaffDetails
            singleStaffDetails={singleStaffData?.result?.data}
            isResponsive={isResponsive}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      case "Change Password":
        return (
          <ChangePassword
            singleStaffDetails={singleStaffData?.result?.data?.user}
            isResponsive={isResponsive}
            singleStaffRefetching={singleStaffRefetching}
          />
        );

      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <Dialog
      open={openUserProfileViewModal}
      onClose={() => dispatch(handleCloseUserProfileViewModal())}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: "1232px",
          overflowY: "hidden",
          minWidth: { xs: "auto", lg: "1232px" },
          height: "90vh",
          borderRadius: "16px",
        },
      }}
    >
      {singleStaffDataLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={singleStaffDataLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: customColors.darkestBlue,
          p: "24px",
        }}
      >
        <Typography sx={{ fontSize: "24px", color: customColors.white }}>
          {`Profile`}
        </Typography>
        <X
          size={24}
          style={{ cursor: "pointer" }}
          color={customColors.white}
          onClick={() => dispatch(handleCloseUserProfileViewModal())}
        />
      </Box>
      {/* side bar and other panel */}
      <Box sx={{ overflowY: "auto", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <SideBar
            setActiveItem={setActiveTabSideBar}
            activeItem={activeTabSideBar}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {renderTabContent(activeTabSideBar)}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserProfileView;
