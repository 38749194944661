import { useEffect, useState } from "react";

import { useLocation } from "react-router";
import { useGetNotificationsQuery } from "../../services/notification";
import { calculateTimeDifferenceWithCurrentTime } from "../../utils/convertTimeIOSFormat";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllNotesQuery } from "../../services/notes";

export default function useNotification() {
  const [currentCalendarTabView, setCurrentCalendarTabView] = useState("Notes");
  const [activeNotifyTab, setActiveNotifyTab] = useState(0);
  const [dataArray, setDataArray] = useState([]);
  const isCalendarRoute = useLocation().pathname.includes("calendar");
  const { data: allNotifications, isLoading: notificationLoading } =
    useGetNotificationsQuery();
  const dispatch = useDispatch();
  const calendarCurrentView = useSelector(
    (state) => state?.appointmentModal.calendarCurrentView
  );

  const [notesType, setNotesType] = useState(
    calendarCurrentView?.toUpperCase()
  );
  const { data: notesData } = useGetAllNotesQuery(notesType, {
    skip: !notesType,
  });
  useEffect(() => {
    setNotesType(calendarCurrentView?.toUpperCase());
  }, [calendarCurrentView]);

  //function to handle array for notification
  const handleNotificationArray = () => {
    // Working on only appointment but in future we have 2 Purchased and Appointment
    //then we can adjust our background according to it
    // you can check notification-data.js file for colors accoriding to condition

    const notificationArray = allNotifications?.result?.data;

    if (notificationArray && notificationArray.length > 0) {
      const finalArray = notificationArray.map((notification) => {
        const appointmentDate = new Date(notification.appointmentTime);
        return {
          backgroundColor: "#E9FBF6",
          color: "#0F6148",
          headerContent: notification.message,
          subContentRight: `With ${notification.userName} (${notification.room})`,
          subContentLeft: appointmentDate.toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }),
          id: notification._id,
          imgURL: "",
          authorName: "Jane Doe",
          appointmentCreated:
            "Added " +
            calculateTimeDifferenceWithCurrentTime(
              notification?.appointmentCreationDate
                ? notification?.appointmentCreationDate
                : notification?.appointmentDate
            ) +
            " ago",
          type: "Appointment",
          appointmentId: notification?.appointmentId,
        };
      });

      setDataArray(finalArray);
    }
  };

  useEffect(() => {
    handleNotificationArray();
  }, [allNotifications]);

  return {
    isCalendarRoute,
    currentCalendarTabView,
    setCurrentCalendarTabView,
    activeNotifyTab,
    setActiveNotifyTab,
    notificationLoading,
    allNotifications,
    dataArray,
    dispatch,
    notesData,
  };
}
