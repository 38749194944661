import { yupResolver } from "@hookform/resolvers/yup";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import SPAInputField from "../../../hook-form/SPAInputField";

// Validation schema
const templateNameSchema = Yup.object().shape({
  templateName: Yup.string().required("Template name is required"),
});

const TemplateNameForm = ({ createTemplateLoader, onSaveTemplate }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(templateNameSchema),
  });

  const onSubmit = (data) => {
    onSaveTemplate(data.templateName);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!createTemplateLoader && (
        <Box sx={{ display: "flex", gap: "16px" }}>
          <Box>
            <SPAInputField
              control={control}
              name="templateName"
              placeholder="Name Your Template"
            />
          </Box>
          <Button
            type="submit"
            sx={{
              display: "flex",
              height: "48px",
              padding: { xs: "0px 10px", sm: "0px 24px" },
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              color: "#ffffff",
              background: "#4E45E5",
              "&:hover": {
                backgroundColor: "#4E45E5",
              },
            }}
          >
            Save
          </Button>
        </Box>
      )}
      {createTemplateLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={createTemplateLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </form>
  );
};

export default TemplateNameForm;
