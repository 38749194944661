import {
  Broom,
  Desktop,
  HandHeart,
  Storefront,
  UserCircle,
  UserGear,
} from "@phosphor-icons/react";

export const productsTabs = [
  {
    label: "Retails Products",
    icon: Storefront,
    link: "Retails Products",
  },
  {
    label: "Professional Product",
    icon: Storefront,
    link: "Professional Product",
  },
];

export const statusDesign = {
  ACTIVE: {
    color: "#0D720A",
    bgColor: "#E7F6E7",
  },
  INACTIVE: {
    color: "#115ACB",
    bgColor: "#E7EFFC",
  },
  DRAFT: {
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  PUBLISHED: {
    color: "#0D720A",
    bgColor: "#E7F6E7",
  },
  UNPUBLISHED: {
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
};

export const roleDesign = {
  STAFF: {
    color: "#9E1068",
    bgColor: "#FFF0F6",
    icon: HandHeart,
  },
  RECEPTIONIST: {
    color: "#5B8C00",
    bgColor: "#FCFFE6",
    icon: Desktop,
  },
  MANAGER: {
    color: "#0D469E",
    bgColor: "#E7EFFC",
    icon: UserCircle,
  },
  CLEANING: {
    color: "#0D469E",
    bgColor: "#E7EFFC",
    icon: Broom,
  },
  SPA_FAIRY: {
    color: "#0D469E",
    bgColor: "#E7EFFC",
    icon: Broom,
  },
  ADMIN: {
    color: "#AD8B00",
    bgColor: "#FEFFE6",
    icon: UserGear,
  },
  Admin: {
    color: "#AD8B00",
    bgColor: "#FEFFE6",
    icon: UserGear,
  },
};
