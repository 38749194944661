export const notificationStyle = {
  panel: { width: "100%", mb: "24px" },
  tabGrid: {
    display: "flex",
    background: "#EDECFC",
    p: "5px",
    borderRadius: "8px",
    height: "48px",
  },
  gridView: {
    borderRadius: "8px",
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridLabel: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "uppercase",
  },
};

export const NotifyCard = {
  card: {
    borderRadius: "16px",
    p: "16px",
    mb: "24px",
  },
  headerContent: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
  subContent: {
    background: "#070A0E0D",
    borderRadius: "8px",
    p: "4px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    my: "8px",
  },
};

export const Noted = { pinNote: { display: "Flex" } };

export const profileImgStyle = {
  width: "24px",
  height: "24px",
  objectFit: "contain",
  borderRadius: "50%",
};

export const collpaseButton = {
  mr: "10px",
  backgroundColor: "#EDECFC",
  height: "48px",
  width: "48px",
  borderRadius: "8px",
  p: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer !important",
};
