import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useGetProductsMutation } from "../../../../../../../../services/product";
import { useGetQualificationsMutation } from "../../../../../../../../services/qualifications";
import { useGetServiceCategoriesMutation } from "../../../../../../../../services/service-category";
import { useUpdateSingleStaffMutation } from "../../../../../../../../services/staff";
import { handleFilterSearch } from "../../../../../../../../utils/searchFilter";
import { treatmentValidationSchema } from "../../../../../treatmentValidationSchema";
import { toast } from "react-toastify";
import { useUpdateSingleServiceMutation } from "../../../../../../../../services/services";
import { useGetProductTypeMutation } from "../../../../../../../../services/product-type";
import { treatmentRequirementValidationSchema } from "../../validationSchema";

export const UseRequirement = ({
  singleServiceData,
  singleServiceRefetching,
}) => {
  const [searchQualification, setSearchQualification] = useState("");
  const [isSearchBox, setIsSearchBox] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");
  const [activeItem, setActiveItem] = useState("ALL");
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [enabled, setEnabled] = useState(null);
  const [activeHistoryTab, setActiveHistoryTab] = useState(false);
  const methods = useForm({
    resolver: yupResolver(treatmentRequirementValidationSchema()),
  });

  const [
    getAllQualifications,
    { data: qualificationsData, isLoading: qualificationsDataLoader },
  ] = useGetQualificationsMutation();
  const [
    getAllProducts,
    { data: allProductData, isLoading: productDataLoading },
  ] = useGetProductsMutation();
  const [updateService] = useUpdateSingleServiceMutation();
  const [
    getAllServiceCategory,
    { data: serviceCategoryData, isLoading: serviceCategoryDataLoader },
  ] = useGetServiceCategoriesMutation();
  const [
    getAllProductCategory,
    { data: productCategoryData, isLoading: productCategoryDataLoader },
  ] = useGetProductTypeMutation();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = methods;
  console.log(errors);
  const handleAddProduct = useCallback((product) => {
    setSelectedProduct((prevSelectedProduct) => {
      const productIndex = prevSelectedProduct.findIndex(
        (item) => item._id === product._id
      );
      if (productIndex === -1)
        return [
          ...prevSelectedProduct,
          { ...product, quantity: 1, isRequired: "OPTIONAL" },
        ];
      const updatedProduct = [...prevSelectedProduct];
      updatedProduct[productIndex].quantity += 1;
      return updatedProduct;
    });
  }, []);
  const handleRemoveProduct = (productId) => {
    const filteredArr = selectedProduct.filter(
      (item) => item._id !== productId
    );
    setSelectedProduct(filteredArr);
  };

  const handleQuantityChange = useCallback((productId, change) => {
    setSelectedProduct((prevSelectedProduct) =>
      prevSelectedProduct
        .map((item) =>
          item._id === productId
            ? { ...item, quantity: item.quantity + change }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  }, []);

  const handleRequiredOptional = useCallback((productId, value) => {
    setSelectedProduct((prevSelectedProduct) =>
      prevSelectedProduct.map((item) =>
        item._id === productId ? { ...item, isRequired: value } : item
      )
    );
  }, []);

  const handleSearchProduct = useCallback(() => {
    const attributeArray = isNaN(searchProduct) ? ["name"] : ["name"];
    const searchPayload = handleFilterSearch(
      searchProduct,
      attributeArray,
      "search"
    );
    getAllProducts(searchProduct ? { ...searchPayload } : {});
  }, [searchProduct, getAllProducts]);

  const handleSearchQualification = () => {
    let attributeArray = [];
    if (searchQualification !== "") {
      attributeArray = ["name"];
      const searchPayload = handleFilterSearch(
        searchQualification,
        attributeArray,
        "search"
      );
      getAllQualifications({ ...searchPayload });
    } else {
      getAllQualifications({});
    }
  };

  useEffect(() => {
    handleSearchQualification();
  }, [searchQualification]);

  useEffect(() => {
    const transformedProducts = selectedProduct?.map((product) => ({
      product: product?._id,
      quantity: product?.quantity,
      isRequired: product?.isRequired,
    }));
    setValue("products", transformedProducts);
  }, [selectedProduct]);

  const onSubmit = async (values) => {
    console.log({ values });
    const response = await updateService({
      id: singleServiceData?._id,
      payload: values,
    });

    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    singleServiceRefetching();
  };

  useEffect(() => {
    const productFilterPayload = handleFilterSearch(
      ["type=PROFESSIONAL_PRODUCT"],
      ["type"],
      "filter"
    );
    getAllProducts({ ...productFilterPayload });
    getAllQualifications();
    getAllServiceCategory();
    getAllProductCategory();
  }, []);

  useEffect(() => {
    handleSearchProduct();
  }, [searchProduct, handleSearchProduct]);

  const handleInitialValues = () => {
    if (!singleServiceData) return;
    const initialProducts = singleServiceData?.products?.map((initProd) => {
      const fullProductData = allProductData?.result?.data?.data?.find(
        (item) => {
          return item._id === initProd?.product?._id;
        }
      );

      return {
        ...fullProductData,
        quantity: initProd?.quantity,
        isRequired: initProd?.isRequired,
      };
    });

    setSelectedProduct(initialProducts);
    setValue(
      "qualification",
      singleServiceData?.qualification?.map((item) => item._id)
    );
  };

  useEffect(() => {
    if (singleServiceData && allProductData) {
      handleInitialValues();
    }
  }, [singleServiceData, allProductData]);

  const toggleSwitch = () => {
    setEnabled(!enabled);
  };

  const handleRemove = (product) => {
    const newArr = selectedProduct.filter((item) => item._id !== product._id);
    setSelectedProduct(newArr);
  };

  const getMeasurementAbbreviation = (measurement) => {
    switch (measurement) {
      case "PACK":
        return "P";
      case "UNIT":
        return "U";
      case "BOX":
        return "B";
      case "BAG":
        return "G";
      case "CASE":
        return "C";
      default:
        return measurement;
    }
  };

  const handleProductTypeSelection = () => {
    const retailAndProductType = {
      condition: {
        productTypeId: activeHistoryTab,
        type: "PROFESSIONAL_PRODUCT",
      },
    };
    let retailProductsSearchPayload = handleFilterSearch(
      ["type=PROFESSIONAL_PRODUCT"],
      ["type"],
      "filter"
    );

    if (activeItem === "ALL") {
      getAllProducts({ ...retailProductsSearchPayload });
    } else {
      getAllProducts({
        ...retailAndProductType,
      });
    }
  };
  useEffect(() => {
    handleProductTypeSelection();
  }, [activeHistoryTab]);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    allProductData,
    productDataLoading,
    qualificationsData,
    qualificationsDataLoader,
    searchQualification,
    setSearchQualification,

    searchProduct,
    setSearchProduct,
    activeItem,
    setActiveItem,
    isSearchBox,
    setIsSearchBox,
    selectedProduct,
    setSelectedProduct,
    handleAddProduct,
    handleQuantityChange,
    serviceCategoryData,
    productCategoryData,
    getMeasurementAbbreviation,
    handleRemove,
    toggleSwitch,
    handleRequiredOptional,
    setActiveHistoryTab,
    handleRemoveProduct,
  };
};
