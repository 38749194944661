import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { validationSchema } from "./validationSchema";
import { useUpdateBusinessLocationTimingMutation } from "../../../services/business-location";
import { toast } from "react-toastify";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../utils/localStorage";
import { useEffect } from "react";

const useTimings = () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema()),
    mode: "onChange",
  });
  const [updateTiming, { isLoading: updateTimingLoader }] =
    useUpdateBusinessLocationTimingMutation();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods;
  const businessLocationData = getLocalStorageItem("businessLocation");

  const onSubmit = async (values) => {
    const payload = {
      startTime: new Date(values?.startTime)?.toISOString(),
      endTime: new Date(values?.endTime)?.toISOString(),
    };

    let response;
    response = await updateTiming({
      id: businessLocationData[0]?._id,
      payload: payload,
    });

    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      setLocalStorageItem("businessLocation", [response?.data?.result?.data]);
      setValue("startTime", response?.data?.result?.data?.startTime);
      setValue("endTime", response?.data?.result?.data?.endTime);
      return;
    }
  };

  useEffect(() => {
    if (businessLocationData[0]?._id) {
      setValue("startTime", businessLocationData[0]?.startTime);
      setValue("endTime", businessLocationData[0]?.endTime);
    }
  }, [businessLocationData]);

  return {
    handleSubmit,
    control,
    onSubmit,
    methods,
    updateTimingLoader,
  };
};

export default useTimings;
