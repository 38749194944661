import React from 'react';
import ResetPasswordComponent from '../../../components/auth/reset-password';
import AuthLayout from '../../../layout/auth-layout';

const ResetPassword = () => {
  return (
    <AuthLayout heading='Welcome!' subHeading='Please reset your password'>
      <ResetPasswordComponent />
    </AuthLayout>
  );
};

export default ResetPassword;
