import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientDetail: {},
};

export const clientDetailSlice = createSlice({
  name: "clientDetail",
  initialState,
  reducers: {
    setClientDetails: (state, action) => {
      state.clientDetail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setClientDetails } = clientDetailSlice.actions;

export default clientDetailSlice.reducer;
