import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreateQualificationMutation,
  useGetQualificationsMutation,
  useGetSingleQualificationQuery,
  useUpdateSingleQualificationMutation,
} from "../../../services/qualifications";
import { useGetServiceCategoriesMutation } from "../../../services/service-category";
import { validationSchema } from "./validationSchema";
import { handleFilterSearch } from "../../../utils/searchFilter";
import { useGetStaffsMutation } from "../../../services/staff";

export default function useQualification() {
  const [searchQualification, setSearchQualification] = useState("");
  const [searchTherapist, setSearchTherapist] = useState("");
  const [searchTreatment, setSearchTreatment] = useState("");
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const [activeHistoryTab, setActiveHistoryTab] = useState(0);
  const [isSuccessMsg, setIsSuccessMsg] = useState(false);
  const [singleQualificationId, setSingleQualificationId] = useState(null);
  const [staffUpdateType, setStaffUpdateType] = useState("");
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);
  const [selectedServicesData, setSelectedServicesData] = useState([]);
  const [selectedUsersData, setSelectedUsersData] = useState([]);

  const [isOpenAddQualificationDialog, setIsOpenAddQualificationDialog] =
    useState(false);
  const [addQualificationData, setAddQualificationData] = useState();
  const [isOpenDisableConfirmDialog, setIsOpenDisableConfirmDialog] =
    useState(false);
  const [enabled, setEnabled] = useState(null);

  const toggleSwitch = () => {
    if (enabled === true) {
      setIsOpenDisableConfirmDialog(true);
    } else {
      setEnabled(true);
    }
  };

  const validationSchemaInstance = validationSchema();
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
  });
  const [
    getAllServiceCategory,
    { data: serviceCategoryData, isLoading: serviceCategoryDataLoader },
  ] = useGetServiceCategoriesMutation();
  const [
    getAllQualifications,
    { data: qualificationsData, isLoading: qualificationsDataLoader },
  ] = useGetQualificationsMutation();
  const [getAllUsers, { data: usersData, isLoading: usersDataLoader }] =
    useGetStaffsMutation();

  const [createQualification, { isLoading: createQualificationLoader }] =
    useCreateQualificationMutation();
  const [updateSingleQualification, { isLoading: updateQualificationLoader }] =
    useUpdateSingleQualificationMutation();
  const {
    data: singleQualification,
    isLoading: singleQualificationLoader,
    refetch: refetchSingleQualification,
  } = useGetSingleQualificationQuery(singleQualificationId, {
    skip: !singleQualificationId,
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const userListingData = watch("user");
  const servicesListingData = watch("services");
  const qualificationNameValue = watch("name");

  useEffect(() => {
    if (staffUpdateType === "Staff") {
      setSelectedUsersData(addQualificationData?.user ?? []);
      setValue(
        "user",
        addQualificationData?.user?.map((item) => item?._id)
      );
    }
    if (staffUpdateType === "Treatment") {
      setSelectedServicesData(addQualificationData?.services ?? []);
      setValue(
        "services",
        addQualificationData?.services?.map((item) => item?._id)
      );
    }
  }, [addQualificationData]);

  const onSubmit = async (values) => {
    let payload;
    payload = {
      name: values?.name,
      category: values?.category,
      description: values?.description ?? undefined,
      user: values?.user ?? undefined,
      services: values?.services ?? undefined,
    };
    let response;
    if (singleQualificationId) {
      response = await updateSingleQualification({
        id: singleQualification?.result?.data?._id,
        payload: {
          ...payload,
          showQualification: enabled === true ? "ENABLED" : "DISABLED",
        },
      });
    } else {
      response = await createQualification(payload);
    }
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    getAllQualifications({});
    setSingleQualificationId("");
    setSelectedServicesData([]);
    setSelectedUsersData([]);
    reset({
      name: "",
      description: "",
      category: "",
    });
  };

  const handleCloseSuccessPopup = () => {
    setIsOpenSuccessDialog(false);
  };

  const handleAddQualification = async () => {
    setIsOpenAddQualificationDialog(true);
    handleCloseSuccessPopup();
  };

  useEffect(() => {
    getAllServiceCategory();
  }, []);

  const handleTabSelection = (item) => {
    setActiveHistoryTab(item);
    const finalFilter = item === 1 ? "ENABLED" : item === 2 ? "DISABLED" : "";
    const searchPayload = handleFilterSearch(
      [`showQualification=${finalFilter}`],
      ["showQualification"],
      "filter"
    );
    if (item === 0) {
      getAllQualifications({});
    } else {
      getAllQualifications({ ...searchPayload });
    }
  };

  const removeSelectedService = (id) => {
    setSelectedServicesData((prev) => prev.filter((item) => item._id !== id));
    const filterData = servicesListingData?.filter((item) => item !== id);
    setValue("services", filterData);
  };

  const removeSelectedUser = (id) => {
    setSelectedUsersData((prev) => prev.filter((item) => item._id !== id));
    const filterData = userListingData?.filter((item) => item !== id);
    setValue("user", filterData);
  };

  const handleSingleQualification = (item) => {
    setSingleQualificationId(item?._id);
  };

  const handleCreateCommunication = () => {
    reset({
      name: "",
      description: "",
      category: "",
      user: "",
      services: "",
    });
    setSingleQualificationId("");
    setSelectedServicesData([]);
    setSelectedUsersData([]);
  };

  useEffect(() => {
    if (singleQualification && singleQualificationId) {
      const singleQualificationFormat = singleQualification?.result?.data;
      setValue("name", singleQualificationFormat?.name);
      setValue("category", singleQualificationFormat?.category?._id);
      setValue("description", singleQualificationFormat?.description);
      setValue(
        "services",
        singleQualificationFormat?.services?.map((item) => item?._id)
      );
      setEnabled(
        singleQualificationFormat?.showQualification === "ENABLED"
          ? true
          : false
      );
      setSelectedServicesData(singleQualificationFormat?.services);
      setSelectedUsersData(singleQualificationFormat?.user);
      setValue(
        "user",
        singleQualificationFormat?.user?.map((item) => item?._id)
      );
    }
  }, [singleQualification, singleQualificationId]);

  const handleSearchQualification = () => {
    let attributeArray = [];
    if (searchQualification !== "") {
      if (isNaN(searchQualification)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchQualification,
        attributeArray,
        "search"
      );
      getAllQualifications({ ...searchPayload });
    } else getAllQualifications({});
  };

  useEffect(() => {
    handleSearchQualification();
  }, [searchQualification]);

  useEffect(() => {
    getAllQualifications({});
  }, []);

  useEffect(() => {
    if (singleQualificationId) {
      refetchSingleQualification();
    }
  }, [singleQualificationId]);

  const handleDisableConfirm = async () => {
    setEnabled(false);
    setIsOpenDisableConfirmDialog(false);
  };

  const handleSearchStaff = () => {
    if (searchTherapist) {
      const filteredUsers = selectedUsersData?.filter((user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(searchTherapist.toLowerCase())
      );
      setSelectedUsersData(filteredUsers);
    } else {
      setSelectedUsersData(addQualificationData?.user ?? []);
    }
  };

  useEffect(() => {
    handleSearchStaff();
  }, [searchTherapist]);

  const handleSearchTreatment = () => {
    if (searchTreatment) {
      const filteredTreatment = selectedServicesData?.filter((user) =>
        `${user.name}`.toLowerCase().includes(searchTreatment.toLowerCase())
      );
      setSelectedServicesData(filteredTreatment);
    } else {
      setSelectedServicesData(addQualificationData?.services ?? []);
    }
  };

  useEffect(() => {
    handleSearchTreatment();
  }, [searchTreatment]);

  useEffect(() => {
    getAllUsers({ roleType: "STAFF" });
  }, []);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    isSuccessMsg,
    setIsSuccessMsg,
    qualificationsData,
    activeHistoryTab,
    handleTabSelection,
    singleQualificationId,
    handleCreateCommunication,
    handleSingleQualification,
    reset,
    serviceCategoryData,
    qualificationsDataLoader,
    singleQualificationLoader,
    setSearchQualification,
    handleAddQualification,
    usersData,
    usersDataLoader,
    selectedStaffIds,
    searchTherapist,
    setSearchTherapist,
    setIsOpenSuccessDialog,
    isOpenAddQualificationDialog,
    setIsOpenAddQualificationDialog,
    setSingleQualificationId,
    staffUpdateType,
    setStaffUpdateType,
    setAddQualificationData,
    servicesListingData,
    userListingData,
    selectedServicesData,
    selectedUsersData,
    toggleSwitch,
    enabled,
    qualificationNameValue,
    removeSelectedService,
    removeSelectedUser,
    setIsOpenDisableConfirmDialog,
    isOpenDisableConfirmDialog,
    handleDisableConfirm,

    searchTreatment,
    setSearchTreatment,
  };
}
