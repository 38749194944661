import SPAInputField from "../../../hook-form/SPAInputField";
import SPAMobileDatePicker from "../../../hook-form/SPAMobileDatePicker";
import SPASelectField from "../../../hook-form/SPASelectField";

export const couponElements = (
  listingData,
  endDateStarting,
  couponType,
  RadioButtons,
  DiscountToggle
) => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        name: couponType === "Product" ? "products" : "services",
        label: couponType === "Product" ? "Product" : "Service",
        type: "multiple",
        isChips: true,
        options:
          listingData && listingData?.length > 0
            ? listingData.map((item) => {
                return { label: item.name, value: item.name, id: item._id };
              })
            : [],
        placeholder: `Select ${
          couponType === "Product" ? "Product" : "Service"
        }`,
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      component: DiscountToggle,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "startDate",
        label: "Start Date",
        isCalenderShow: true,

        disablePast: true,
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "endDate",
        label: "End Date",
        isCalenderShow: true,
        disablePast: true,
        minDate: endDateStarting,
        disableHighlightToday: true,
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 12,
      component: RadioButtons,
    },
  ];
};

export const timeData = [
  { label: "08:00 AM", value: "08:00 AM" },
  { label: "08:30 AM", value: "08:30 AM" },
  { label: "09:00 AM", value: "09:00 AM" },
  { label: "09:30 AM", value: "09:30 AM" },
  { label: "10:00 AM", value: "10:00 AM" },
  { label: "10:30 AM", value: "10:30 AM" },
  { label: "11:00 AM", value: "11:00 AM" },
  { label: "11:30 AM", value: "11:30 AM" },
  { label: "12:00 PM", value: "12:00 PM" },
  { label: "12:30 PM", value: "12:30 PM" },
  { label: "01:00 PM", value: "01:00 PM" },
  { label: "01:30 PM", value: "01:30 PM" },
  { label: "02:00 PM", value: "02:00 PM" },
  { label: "02:30 PM", value: "02:30 PM" },
  { label: "03:00 PM", value: "03:00 PM" },
  { label: "03:30 PM", value: "03:30 PM" },
  { label: "04:00 PM", value: "04:00 PM" },
  { label: "04:30 PM", value: "04:30 PM" },
  { label: "05:00 PM", value: "05:00 PM" },
  { label: "05:30 PM", value: "05:30 PM" },
  { label: "06:00 PM", value: "06:00 PM" },
  { label: "06:30 PM", value: "06:30 PM" },
  { label: "07:00 PM", value: "07:00 PM" },
  { label: "07:30 PM", value: "07:30 PM" },
  { label: "08:00 PM", value: "08:00 PM" },
  { label: "08:30 PM", value: "08:30 PM" },
];
