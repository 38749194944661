import * as Yup from "yup";

export const validationSchema = (type) => {
  let schema;
  if (type === "Treatment") {
    schema = Yup.object().shape({
      serviceIds: Yup.array()
        .of(Yup.string())
        .min(1, "At least one Treatment must be selected")
        .required("Selection of  Treatment is required."),
    });
  } else {
    schema = Yup.object().shape({
      userIds: Yup.array()
        .of(Yup.string())
        .min(1, "At least one staff must be selected")
        .required("Selection of staff is required."),
    });
  }

  return schema;
};
