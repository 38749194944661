import * as Yup from "yup";

export const validationSchema = (type) => {
  let schema;
  schema = Yup.object().shape({
    productIds: Yup.array()
      .of(Yup.string())
      .min(1, "At least one Product must be selected")
      .required("Selection of  Product is required."),
  });

  return schema;
};
