import { baseAPI } from "../baseApi";

export const UploadApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (bodyData) => {
        return {
          url: "/upload",
          method: "POST",
          body: bodyData,
        };
      },
    }),
  }),
});

export const { useUploadFileMutation } = UploadApi;
