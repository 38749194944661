import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCancelAppointmentMutation } from "../../../services/appointment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

export default function useCancelAppointment(
  rowData,
  handleCancelAppointmentModel
) {
  const [availableType, setAvailableType] = useState("");
  const validationSchema = (customReason) => {
    let schema = yup.object().shape({
      cancelReason: yup.string().required("Cancel Reason is required"),
      additionalNotes: yup.string(),
    });
    if (customReason === "Custom Reason") {
      schema = schema.shape({
        customReason: yup.string().required("Custom Reason is required"),
      });
    }
    return schema;
  };
  const methods = useForm({
    resolver: yupResolver(validationSchema(availableType)),
  });

  const [cancelAppointment, { isLoading: cancelAppointmentLoader }] =
    useCancelAppointmentMutation();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const reason = watch("cancelReason");

  useEffect(() => {
    setAvailableType(watch("cancelReason"));
  }, [reason]);

  const onSubmit = async (values) => {
    const response = await cancelAppointment({
      id: rowData?._id,
      payload: values,
    });
    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    toast.success(
      response?.data?.message ?? "Appointment Successfully Cancelled"
    );
    handleCancelAppointmentModel(response);
    reset();
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    methods,
    availableType,
    errors,
    cancelAppointmentLoader,
  };
}
