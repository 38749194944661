import React from 'react';

const RightArrow = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.2806 12.531L14.5306 19.281C14.3899 19.4218 14.199 19.5008 14 19.5008C13.801 19.5008 13.6101 19.4218 13.4694 19.281C13.3286 19.1403 13.2496 18.9494 13.2496 18.7504C13.2496 18.5514 13.3286 18.3605 13.4694 18.2198L18.9397 12.7504H4.25C4.05109 12.7504 3.86032 12.6714 3.71967 12.5307C3.57902 12.3901 3.5 12.1993 3.5 12.0004C3.5 11.8015 3.57902 11.6107 3.71967 11.4701C3.86032 11.3294 4.05109 11.2504 4.25 11.2504H18.9397L13.4694 5.78104C13.3286 5.64031 13.2496 5.44944 13.2496 5.25042C13.2496 5.05139 13.3286 4.86052 13.4694 4.71979C13.6101 4.57906 13.801 4.5 14 4.5C14.199 4.5 14.3899 4.57906 14.5306 4.71979L21.2806 11.4698C21.3504 11.5394 21.4057 11.6222 21.4434 11.7132C21.4812 11.8043 21.5006 11.9019 21.5006 12.0004C21.5006 12.099 21.4812 12.1966 21.4434 12.2876C21.4057 12.3787 21.3504 12.4614 21.2806 12.531Z'
        fill='white'
      />
    </svg>
  );
};

export default RightArrow;
