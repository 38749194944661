import * as Yup from "yup";

export const validationSchema = (reminderType) => {
  let schema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
    appliesTo: Yup.string().required("Applies To is required"),
    when: Yup.string().required("When is required"),
    hours: Yup.number()
      .typeError("Time is required")
      .required("Time is required"),
    isEnableReminder: Yup.boolean(),
  });
  if (reminderType === "EMAIL") {
    schema = schema.shape({
      subject: Yup.string().required("Subject is required"),
    });
  }
  return schema;
};
