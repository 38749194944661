import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useGetProductsMutation } from "../../../../../../services/product";
import { useAddProductTypeInProductMutation } from "../../../../../../services/product-type";
import { handleFilterSearch } from "../../../../../../utils/searchFilter";
import { validationSchema } from "./validationSchema";

export default function useUpdateStaff(
  handleClose,
  singleQualificationId,
  setIsOpenSuccessDialog,
  type
) {
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const [searchTherapist, setSearchTherapist] = useState("");
  const validationSchemaInstance = validationSchema(type);
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
    defaultValues: {
      productIds: [],
    },
  });

  const [
    getAllProducts,
    { data: productsData, isLoading: productsDataLoader },
  ] = useGetProductsMutation();

  const [addProductTypeToProduct, { isLoading: updateProductTypeLoader }] =
    useAddProductTypeInProductMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (values) => {
    let response;
    if (singleQualificationId && type === "Product") {
      response = await addProductTypeToProduct({
        id: singleQualificationId,
        payload: values,
      });
    }
    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    toast.success(response?.data?.message);
    reset();
    setIsOpenSuccessDialog(false);
    handleClose("Success");
  };

  const handleCloseForm = () => {
    handleClose("Deffer");
    reset();
  };

  const handleSearchProduct = () => {
    let attributeArray = [];
    if (searchTherapist !== "") {
      if (isNaN(searchTherapist)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchTherapist,
        attributeArray,
        "search"
      );
      getAllProducts({ ...searchPayload });
    } else getAllProducts({});
  };

  useEffect(() => {
    if (type === "Product") {
      handleSearchProduct();
    }
  }, [searchTherapist]);

  useEffect(() => {
    getAllProducts();
  }, []);

  const toggleStaffSelection = (id) => {
    setSelectedStaffIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return {
    searchTherapist,
    setSearchTherapist,
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    selectedStaffIds,
    toggleStaffSelection,
    productsData,
    productsDataLoader,
  };
}
