import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  CaretUp,
  Gift,
  MagnifyingGlass,
  Minus,
  Percent,
  Plus,
  Trash,
  X,
} from "@phosphor-icons/react";
import React from "react";
import SuccessCard from "../../../assets/images/cards.png";
import GreaterThenIcon from "../../../assets/svg/greaterthen-icon";
import SPADialogue from "../../common/spa-dialogue";
import SPATextField from "../../common/spa-textField";
import SPAInputField from "../../hook-form/SPAInputField";
import EmailReceiptForm from "../email-receipt-form";
import ProductCard from "../product-card";
import ProductCategoryTab from "../products-tab";
import { checkoutDetailsFormData, newSalesFormData } from "../staff-data";
import useCreateSales from "./useCreateSales";
import Invoice from "./Invoice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { resourceBox } from "../salesStyle";
import SPASwitch from "../../common/spa-switch";

const CreateSalesDialogue = () => {
  const {
    isSuccessView,
    handleSubmit,
    onSubmit,
    methods,
    productsData,
    productsDataLoader,
    clientsData,
    control,
    setActiveItem,
    activeItem,
    productsSummary,
    searchProduct,
    setSearchProduct,
    salesDialogueClose,
    isFormEnable,
    setIsSearchBox,
    isSearchBox,
    handleAddProduct,
    selectedProduct,
    handleQuantityChange,
    handleContinue,
    calculateTotal,
    isVoucherField,
    setIsVoucherField,
    isDiscountField,
    setIsDiscountField,
    productCategoryData,
    setActiveHistoryTab,
    openNewSalesModal,
    productCategoryDataLoader,
    clientsDataLoader,
    handleRemoveProduct,
    invoicePayload,
    markAsPaid,
  } = useCreateSales();

  return (
    <SPADialogue
      open={openNewSalesModal}
      handleClose={salesDialogueClose}
      dialogTitle="Create Sale"
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      methods={methods}
      sx={{ width: { xs: "80%", lg: "60rem" } }}
    >
      <Box>
        {(productsDataLoader ||
          productCategoryDataLoader ||
          clientsDataLoader) && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={
              productsDataLoader ||
              productCategoryDataLoader ||
              clientsDataLoader
            }
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {!isSuccessView && (
          <Grid container spacing={1}>
            <Grid item lg={7} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  padding: "16px",
                  flexDirection: "column",
                  gap: "16px",
                  borderRadius: "16px",
                  background: "#F8F7FE",
                  height: "100%",
                }}
              >
                <Grid container spacing={1}>
                  {newSalesFormData(
                    clientsData?.result?.data?.data,
                    isFormEnable
                  ).map((item, index) => {
                    return (
                      <Grid item lg={item.gridLength} xs={12} key={index}>
                        <item.component
                          {...item.otherOptions}
                          control={control}
                        />
                      </Grid>
                    );
                  })}
                  {isFormEnable && (
                    <Box
                      sx={{
                        ...resourceBox.paidAllHeaderMain,
                        bgcolor: "#FFFFFF",
                      }}
                    >
                      <Box sx={resourceBox.selectionGrid}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            p: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#4A4C4F",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            Mark As Paid
                          </Typography>
                          <Typography
                            sx={{
                              color: "#5E6062",
                              fontSize: "12px",
                            }}
                          >
                            Will be shown in the paid sales
                          </Typography>
                        </Box>
                        <Box>
                          <SPASwitch name={"markAsPaid"} control={control} />
                        </Box>
                      </Box>
                      {!markAsPaid && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            p: "16px 12px",
                            width: "100%",
                          }}
                        >
                          <Grid container spacing={1}>
                            {checkoutDetailsFormData().map((item, index) => {
                              return (
                                <Grid
                                  item
                                  lg={item.gridLength}
                                  xs={12}
                                  key={index}
                                >
                                  <item.component
                                    {...item.otherOptions}
                                    control={control}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  )}
                  {!isFormEnable && (
                    <Grid item lg={12} xs={12} sx={{ position: "relative" }}>
                      <SPATextField
                        name="search"
                        label="Search Products"
                        placeholder="Search Products"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: "48px",
                          },
                        }}
                        onClick={() => setIsSearchBox(true)}
                        onFocus={() => setIsSearchBox(true)}
                        value={searchProduct}
                        onChangeValue={(event) => {
                          setSearchProduct(event?.target?.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ paddingLeft: "10px" }}
                            >
                              <MagnifyingGlass size={24} />
                            </InputAdornment>
                          ),

                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ paddingLeft: "10px", cursor: "pointer" }}
                              onClick={() => {
                                setSearchProduct("");
                              }}
                            >
                              {searchProduct && <X size={24} />}
                            </InputAdornment>
                          ),
                        }}
                      />
                      {isSearchBox && (
                        <ClickAwayListener
                          onClickAway={() => {
                            setIsSearchBox(false);
                            setActiveItem("ALL");
                            setActiveHistoryTab("");
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              position: "absolute",
                              width: "100%",
                              paddingRight: "16px",
                              gap: "16px",
                              borderRadius: "16px",
                              background: "#FAFAFA",
                              zIndex: "100",
                              boxShadow:
                                "-66px 62px 25px 0px rgba(78, 69, 229, 0.00), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -3px 2px 8px 0px rgba(78, 69, 229, 0.05)",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                width: "164px",
                                padding: "8px",
                                flexDirection: "column",
                                gap: "4px",
                                borderRadius: "16px 0px 0px 16px",
                                background: "#FFF",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "35vh",
                                  overflowY: "auto",
                                }}
                              >
                                <ProductCategoryTab
                                  activeItem={activeItem}
                                  setActiveItem={setActiveItem}
                                  tabsData={
                                    productCategoryData?.result?.data?.data
                                  }
                                  setActiveHistoryTab={setActiveHistoryTab}
                                />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                height: "35vh",
                                overflowY: "auto",
                                width: "100%",
                              }}
                            >
                              <ProductCard
                                productsData={productsData?.result?.data?.data}
                                productLoader={productsDataLoader}
                                handleSingleProductSelect={(item) =>
                                  handleAddProduct(item)
                                }
                                handleSingleProductDeselect={(item) =>
                                  handleRemoveProduct(item)
                                }
                                selectedProducts={selectedProduct}
                              />
                            </Box>
                          </Box>
                        </ClickAwayListener>
                      )}
                    </Grid>
                  )}
                  {!isFormEnable && (
                    <Grid item lg={12} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          p: "16px 0px",
                          width: "100%",
                          height: "35vh",
                          overflowY: "auto",
                        }}
                      >
                        {selectedProduct?.length > 0 &&
                          selectedProduct.map((item) => {
                            return (
                              <Box
                                key={item?._id}
                                sx={{
                                  display: "flex",
                                  flexDirection: { xs: "column", md: "row" },
                                  flexShrink: 0,
                                  width: "100%",
                                  height: "88px",
                                  paddingRight: "8px",
                                  alignItems: "center",
                                  gap: "8px",
                                  borderRadius: "8px",
                                  background: "#FFF",
                                  justifyContent: "space-between",
                                  overflow: "hidden",
                                  cursor: "pointer",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      maxWidth: "88px",
                                      overflow: "hidden",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    <img
                                      src={
                                        item?.imageUrl
                                          ? item?.imageUrl
                                          : "https://qa-temple-day-spa-s3.s3.ap-southeast-2.amazonaws.com/e69da1af-ceff-49d5-b780-20bcd96e607d-images.jpg"
                                      }
                                      alt="product"
                                    />
                                  </Box>
                                  <Box>
                                    <Box
                                      sx={{
                                        padding: "4px 8px",
                                        background: "#FFF0F6",
                                        color: "#EB2F96",
                                        fontSize: "10px",
                                        textTransform: "uppercase",
                                        maxWidth: "fit-content",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {item?.productType?.name}
                                    </Box>
                                    <Typography
                                      sx={{
                                        color: "#5E6062",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {item?.name}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        color: "#5E6062",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Unit Price ${item?.price}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "8px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "8px",
                                      flexDirection: {
                                        xs: "row",
                                        md: "column",
                                      },
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#5E6062",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        textTransform: "uppercase",
                                        textAlign: "end",
                                      }}
                                    >
                                      ${item?.price?.toFixed(2)}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "2px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          width: "32px",
                                          height: "32px",
                                          padding: "0px 8px",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          gap: "8px",
                                          borderRadius: "8px",
                                          background: "#EDECFC",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleQuantityChange(item._id, -1)
                                        }
                                      >
                                        <Minus size={16} color="#4E45E5" />
                                      </Box>
                                      <Box
                                        sx={{
                                          borderRadius: "8px",
                                          border: "1px solid  #DADADB",
                                          background: "#FFF",
                                          maxWidth: "40px",
                                          maxHeight: "32px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <Input
                                          type="number"
                                          value={item.quantity}
                                          readOnly
                                          sx={{
                                            textAlign: "center",
                                            paddingLeft: {
                                              sm: "10px",
                                              xs: "2px",
                                            },
                                          }}
                                          name="client"
                                        />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          width: "32px",
                                          height: "32px",
                                          padding: "0px 8px",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          gap: "8px",
                                          borderRadius: "8px",
                                          background: "#EDECFC",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleQuantityChange(item._id, 1)
                                        }
                                      >
                                        <Plus size={16} color="#4E45E5" />
                                      </Box>
                                    </Box>
                                  </Box>

                                  <Trash
                                    size={16}
                                    color="#D13438"
                                    onClick={() =>
                                      handleRemoveProduct(item._id)
                                    }
                                  />
                                </Box>
                              </Box>
                            );
                          })}
                        {selectedProduct?.length === 0 && (
                          <Box
                            sx={{
                              display: "flex",
                              height: "339px",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "16px",
                            }}
                          >
                            <Box>
                              <img src="/images/cart_icon.svg" alt="cart" />
                            </Box>
                            <Typography
                              sx={{
                                color: "#5E6062",
                                textAlign: "center",
                                fontSize: "20px",
                              }}
                            >
                              No Products Selected Yet
                            </Typography>
                            <Typography
                              sx={{
                                color: "#5E6062",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Search for products to add to the client's sale.
                            </Typography>
                            <Button
                              onClick={() => setIsSearchBox(true)}
                              sx={{
                                backgroundColor: "#4E45E5",
                                color: "#FFFFFF",
                                paddingInline: "24px",
                                paddingBlock: "12px",
                                borderRadius: "8px",
                                fontWeight: 500,
                                "&:hover": {
                                  backgroundColor: "#4E45E5",
                                },
                              }}
                            >
                              Search
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={5} xs={12} sx={{ height: "auto" }}>
              <Box
                sx={{
                  height: { lg: !isFormEnable ? "89%" : "90%", xs: "auto" },
                  borderRadius: "16px",
                  background: "#F8F7FE",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#5E6062",
                      fontSize: "20px",
                      padding: "16px",
                    }}
                  >
                    Summary
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        px: "16px",
                      }}
                    >
                      {productsSummary(
                        calculateTotal()?.total,
                        calculateTotal()?.gst
                      )?.map((item) => {
                        return (
                          <Box
                            key={item?.id}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#5E6062",
                                fontSize: "16px",
                              }}
                            >
                              {item?.label}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#4A4C4F",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              {item?.value}
                            </Typography>
                          </Box>
                        );
                      })}
                      <Box
                        onClick={() => setIsDiscountField(!isDiscountField)}
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Percent size={16} color="#4E45E5" />
                        <Typography
                          sx={{
                            color: "#4E45E5",
                            fontSize: "10px",
                            textTransform: "uppercase",
                          }}
                        >
                          Enter Discount Code
                        </Typography>
                        {isDiscountField ? <CaretUp /> : <GreaterThenIcon />}
                      </Box>
                      {isDiscountField && (
                        <Box sx={{ height: "32px", marginBottom: "12px" }}>
                          <SPAInputField
                            name="discountCode"
                            control={control}
                            sx={{
                              background: "#DADADB",
                              borderRadius: "16px",
                            }}
                            placeholder="Enter Discount Code"
                          />
                        </Box>
                      )}
                      <Box
                        onClick={() => {
                          setIsVoucherField(!isVoucherField);
                        }}
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Gift size={16} color="#4E45E5" />
                        <Typography
                          sx={{
                            color: "#4E45E5",
                            fontSize: "10px",
                            textTransform: "uppercase",
                          }}
                        >
                          % Enter Gift Voucher Code
                        </Typography>
                        {isVoucherField ? <CaretUp /> : <GreaterThenIcon />}
                      </Box>
                      {isVoucherField && (
                        <Box sx={{ height: "32px" }}>
                          <SPAInputField
                            name="giftVoucher"
                            control={control}
                            sx={{
                              background: "#DADADB",
                              borderRadius: "16px",
                            }}
                            placeholder="Enter Voucher Code"
                          />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "16px",
                        padding: "16px",
                        marginTop: "16px",
                        alignItems: "center",
                        background: "#EDECFC",
                      }}
                    >
                      <Typography>Total</Typography>
                      <Typography
                        sx={{
                          color: "#2F2989",
                          fontSize: { md: "24px", xs: "16px" },
                        }}
                      >
                        ${calculateTotal()?.totalWithGst}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {isFormEnable && (
                <Button type={"submit"} sx={resourceBox?.SubmitBtn}>
                  {"Create Sales"}
                </Button>
              )}
              {!isFormEnable && (
                <Button
                  type={"button"}
                  onClick={handleContinue}
                  sx={resourceBox?.SubmitBtn}
                >
                  {"Continue"}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        {isSuccessView && (
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  padding: "16px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                  borderRadius: "16px",
                  background: "#F8F7FE",
                }}
              >
                <Box>
                  <img src={SuccessCard} width="100%" alt="card" />
                </Box>
                <Typography
                  sx={{
                    color: "#5E6062",
                    fontSize: "34px",
                  }}
                >
                  Purchase Complete
                </Typography>
                <Typography
                  sx={{
                    color: "#5E6062",
                    fontSize: "20px",
                  }}
                >
                  Your sale has been successfully processed.
                </Typography>
                <EmailReceiptForm invoicePayload={invoicePayload} />
                <PDFDownloadLink
                  document={<Invoice invoiceData={invoicePayload} />}
                  fileName="invoice.pdf"
                >
                  {({ loading }) =>
                    loading ? "Loading document..." : "Download Invoice"
                  }
                </PDFDownloadLink>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </SPADialogue>
  );
};

export default CreateSalesDialogue;
