import { Box } from '@mui/system';
import React, { useState } from 'react';
import SPASelectField from '../../hook-form/SPASelectField';
import SPATextField from '../../common/spa-textField';
import { Button, Divider, InputAdornment, Typography } from '@mui/material';
import { Gift, MagnifyingGlass, Percent } from '@phosphor-icons/react';
import ProductSaleCard from '../product-sale-card';
import SPAMobileDatePicker from '../../hook-form/SPAMobileDatePicker';
import GreaterThenIcon from '../../../assets/svg/greaterthen-icon';

// sale card products
const products = [
  {
    id: 1,
    imageSrc: '/images/alecs.png',
    category: 'Electronics',
    productName: 'Smartphone',
    seller: 'ABC Electronics',
    unitPrice: 499.99,
  },
  {
    id: 2,
    imageSrc: '/images/alecs.png',
    category: 'Clothing',
    productName: 'T-Shirt',
    seller: 'XYZ Fashion',
    unitPrice: 19.99,
  },
  // Add more products as needed
];

export default function SaleModelContent({ showCart }) {
  const [showSaleCard, setShowSaleCard] = useState(false);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: {
          lg: '624px 313px',
          md: '624px 253px',
          sm: '1fr',
        },
        gridTemplateRows: { lg: '1fr', md: '1fr', sm: '1fr 1fr' },
        gridColumnGap: '24px',
        gridRowGap: '24px',
      }}
    >
      {/* search col */}
      <Box
        sx={{
          display: 'flex',
          width: { md: '624px', sm: '100%' },
          padding: '16px',
          flexDirection: 'column',
          gap: '16px',
          borderRadius: '16px',
          background: '#F8F7FE',
        }}
      >
        <SPASelectField
          name='client'
          label='Client'
          sx={{ opacity: 0.6, background: '#DADADB', borderRadius: '16px' }}
          options={[
            {
              label: 'New Client',
              value: 'New Client',
            },
          ]}
          placeholder='Jane Doe'
        />

        {/* items part  */}
        {!showCart ? (
          <>
            {' '}
            <Box>
              <SPATextField
                name='client'
                label='Search Products'
                placeholder='Search Products'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '48px',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position='start'
                      sx={{ paddingLeft: '10px' }}
                    >
                      <MagnifyingGlass size={24} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position='end'
                      sx={{ paddingLeft: '10px', cursor: 'pointer' }}
                      onClick={() => {
                        // setSearchClient("");
                      }}
                    >
                      {/* {searchClient && <X size={24} />} */}
                    </InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  // setSearchClient(event.target.value);
                }}
              />
            </Box>
            {/* car box */}
            {showSaleCard ? (
              products.map((product) => (
                <ProductSaleCard
                  key={product.id}
                  imageSrc={product.imageSrc}
                  category={product.category}
                  productName={product.productName}
                  seller={product.seller}
                  unitPrice={product.unitPrice}
                />
              ))
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  height: '339px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Box>
                  <img src='/images/cart_icon.svg' alt='cart' />
                </Box>
                <Typography
                  sx={{
                    color: '#5E6062',
                    textAlign: 'center',
                    fontFamily: '"Open Sans"',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    letterSpacing: '0.03px',
                  }}
                >
                  Not Products Selected Yet
                </Typography>
                <Typography
                  sx={{
                    color: '#5E6062',
                    textAlign: 'center',
                    fontFamily: '"Open Sans"',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    letterSpacing: '0.035px',
                  }}
                >
                  Search for products to add to the client’ sale.
                </Typography>
                <Button
                  onClick={() => setShowSaleCard(true)}
                  type='submit'
                  sx={{
                    backgroundColor: '#4E45E5',
                    color: '#FFFFFF',
                    paddingInline: '24px',
                    paddingBlock: '12px',
                    borderRadius: '8px',
                    fontWeight: 500,
                    '&:hover': {
                      backgroundColor: '#4E45E5',
                    },
                  }}
                >
                  Search
                </Button>
              </Box>
            )}{' '}
          </>
        ) : (
          <>
            {/* cart part  */}
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <SPAMobileDatePicker
                isCalenderShow={true}
                name='date'
                label='Buy Date'
              />
              <SPAMobileDatePicker
                isCalenderShow={true}
                name='date'
                label='Buy Time'
              />
            </Box>
            <Box>
              <SPATextField
                name='textarea'
                type='textarea'
                placeholder='Add any other additional notes here'
                label='Additional Notes (Optional)'
              />
            </Box>
            <Divider />

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <Box>
                <SPATextField
                  name='name'
                  placeholder='i.e.: Jane Doe'
                  label='Card Full Name'
                />
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { md: '345px 245px', xs: '1fr' },
                  gap: '16px',
                }}
              >
                <SPATextField
                  name='name'
                  placeholder='i.e.: 1234 5678 9010 1112'
                  label='Card Number'
                />
                <Box sx={{ display: 'flex', gap: '16px' }}>
                  <SPATextField
                    name='name'
                    placeholder='mm/yy'
                    label='Expire Date'
                  />
                  <SPATextField name='name' placeholder='***' label='CCV' />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '16px',
          flexDirection: 'column',
          gap: '16px',
          borderRadius: '16px',
          background: '#F8F7FE',
        }}
      >
        <Typography
          sx={{
            color: '#5E6062',
            fontFamily: '"Open Sans"',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.03px',
          }}
        >
          Summary
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={{
                  color: '#5E6062',
                  fontFamily: '"Open Sans"',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  letterSpacing: '0.08px',
                }}
              >
                Products
              </Typography>
              <Typography
                sx={{
                  color: '#4A4C4F',
                  fontFamily: '"Open Sans"',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  letterSpacing: '0.024px',
                }}
              >
                $3,242.44
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  color: '#5E6062',
                  fontFamily: '"Open Sans"',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  letterSpacing: '0.08px',
                }}
              >
                GST (10%)
              </Typography>
              <Typography
                sx={{
                  color: '#4A4C4F',
                  fontFamily: '"Open Sans"',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  letterSpacing: '0.024px',
                }}
              >
                $3,242.44
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <Percent size={16} color='#4E45E5' />
              <Typography
                sx={{
                  color: '#4E45E5',
                  fontFamily: '"Open Sans"',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  letterSpacing: '0.15px',
                  textTransform: 'uppercase',
                }}
              >
                Enter Discount Code
              </Typography>
              <GreaterThenIcon />
            </Box>
            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <Gift size={16} color='#4E45E5' />
              <Typography
                sx={{
                  color: '#4E45E5',
                  fontFamily: '"Open Sans"',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  letterSpacing: '0.15px',
                  textTransform: 'uppercase',
                }}
              >
                % Enter Gift Voucher Code
              </Typography>
              <GreaterThenIcon />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: '16px',
              padding: '16px',
              marginTop: '16px',
              alignItems: 'center',
              background: '#EDECFC',
            }}
          >
            <Typography>Total</Typography>
            <Typography
              sx={{
                color: '#2F2989',
                fontFamily: '"Open Sans"',
                fontSize: { md: '24px', xs: '16px' },
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}
            >
              $3,242.44
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
