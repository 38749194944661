import { useState } from "react";
import {
  AdditionalNotes,
  Overview,
  EmailSms,
  AppointmentSale,
  Address,
} from "./components";
import { getLocalStorageItem } from "../../../../utils/localStorage";
export default function useClientProfile() {
  const [activeItem, setActiveItem] = useState(
    getLocalStorageItem("activeTab")
      ? getLocalStorageItem("activeTab")
      : "Overview"
  );
  const componentMap = {
    AdditionalNotes,
    Overview,
    EmailSms,
    AppointmentSale,
    Address,
  };

  return {
    activeItem,
    setActiveItem,
    componentMap,
  };
}
