import { useTheme } from "@emotion/react";
import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import SideBar from "./SideBar";
import Onboarding from "./onboarding/Onboarding";

const MobileApp = () => {
  const [activeTabSideBar, setActiveTabSideBar] = useState("onboarding");
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("md"));
  const renderTabContent = (tab) => {
    switch (tab) {
      case "home":
        return "Home";
      case "notifications":
        return "Notifications";
      case "onboarding":
        return <Onboarding />;
      default:
        return <div>Select a tab</div>;
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          // gap: "24px",
        }}
      >
        <SideBar
          setActiveItem={setActiveTabSideBar}
          activeItem={activeTabSideBar}
          isResponsive={isResponsive}
        />
        <Box
          sx={{
            width: "75%",
            border: "1px solid #EDECFC",
            paddingBlock: "24px",
            paddingLeft: "24px",
            borderRadius: "0px 8px 8px 0px",
          }}
        >
          {renderTabContent(activeTabSideBar)}
        </Box>
      </Box>
    </>
  );
};

export default MobileApp;
