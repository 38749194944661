import { Box, Tab, Tabs } from "@mui/material";
import { Children, useState } from "react";
import { customColors, fs12, secondary } from "../../../../../../../../theme";

/**
 * SPATabs component.
 * Renders a tab component with customizable styles.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string[]} [props.tabsNameArray=[]] - An array containing names for each tab.
 * @param {ReactNode} props.children - The content of the tabs.
 * @param {Object} [props.tabsProps] - Additional props for the Tabs component.
 * @param {Object} [props.tabProps] - Additional props for each Tab component.
 * @param {string} [props.orientation='vertical'] - The orientation of the tabs ('horizontal' or 'vertical').
 * @returns {JSX.Element} JSX code for rendering the CustomTabs component.
 */

const DiscountTab = (props) => {
  const {
    tabsNameArray = [],
    children,
    tabsProps,
    tabProps,
    orientation = "horizontal",
    activeTab = 0,
    setActiveTab = () => {},
  } = props;

  const [value, setValue] = useState(activeTab);

  const arrayChildren = Children.toArray(children);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        flexDirection: orientation === "vertical" ? "row" : "column",
        alignItems: "flex-start",
        flexWrap: "wrap", // Initial flex-wrap property
        "@media (min-width: 1250px)": {
          flexWrap: "nowrap", // Update flex-wrap when device width is above 1250px
        },
      }}
    >
      <Tabs
        selectionFollowsFocus
        orientation={orientation}
        variant="standard"
        sx={{
          minWidth: "100%",
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
            gap: "3px", // Add a gap of 16px between tab buttons
          },
        }}
        TabIndicatorProps={{ style: { display: "none" } }}
        value={value}
        onChange={handleChange}
        {...tabsProps}
      >
        {tabsNameArray.map((item) => (
          <Tab
            // onClick={() => setActiveTab(item?.title)}
            sx={styles.tabStyle}
            icon={item.icon && item.icon}
            disableRipple
            key={item?.title}
            label={item?.title}
            {...tabProps}
          />
        ))}
      </Tabs>
      <Box sx={{ width: "100%" }}>
        {arrayChildren.map((child, index) => (
          <Box key={index}>{value === index && child}</Box>
        ))}
      </Box>
    </Box>
  );
};

export default DiscountTab;

// Example Usage

/* <SPATabs tabsNameArray={["View Profile", "Change Password"]}>
  SPA tabs children here
</SPATabs> */

const styles = {
  tabStyle: {
    p: "4px",
    mb: "7px",
    borderRadius: "8px",
    flexGrow: 1,
    maxWidth: "100%",
    textTransform: "initial",
    fontSize: fs12,
    minWidth: "38px",
    // width: { xs: "100%", sm: "auto", md: "100%", lg: "min-content" },
    minHeight: "22px",
    color: secondary.main,
    background: "#F8F7FE",
    "&.Mui-selected": {
      color: customColors.white,
      background: secondary.main,
      border: `none`,
    },
    "&:hover": {
      color: customColors.white,
      backgroundColor: secondary.main,
      border: `none`,
    },
  },
};
