import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DotsIcon from "../../../../../assets/svg/dots-icon";
import {
  useAddProductToServiceMutation,
  useCreateProductMutation,
  useGetProductsMutation,
} from "../../../../../services/product";
import { useGetProductTypeMutation } from "../../../../../services/product-type";
import { setImageUrl } from "../../../../../store/features/upload-file";
import { fs14, fw600 } from "../../../../../theme";
import { nameInitialsAvatar } from "../../../../../utils/nameInitialsAvatar";
import { handleFilterSearch } from "../../../../../utils/searchFilter";
import { productValidationSchema } from "./validationSchema";
import SPALinearProgress from "../../../../common/spa-linear-progress";
import { Package } from "@phosphor-icons/react";
import { handleOpenProfessionalProductViewModal } from "../../../../../store/features/products";
import { statusDesign } from "../../../resource-management-data";
import { staffStyle } from "./staffStyle";

const useProfessionalProduct = ({ getStockProducts }) => {
  const dispatch = useDispatch();
  const [searchStaff, setSearchStaff] = useState("");
  const [staffPopoverOpen, setStaffPopoverOpen] = useState(null);
  const [isStaffDialogOpen, setIsStaffDialogOpen] = useState(false);
  const [singleProductId, setSingleProductId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  // Variables for staff popover state and IDs
  const openStaffPopOver = Boolean(staffPopoverOpen);
  const staffPopOverId = openStaffPopOver ? "simple-popover" : undefined;
  const [
    getAllProducts,
    { data: productsData, isLoading: productsDataLoader },
  ] = useGetProductsMutation();

  const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false);
  const [isQualificationsDialogOpen, setIsQualificationsDialogOpen] =
    useState(false);
  const [therapistDetails, setTherapistDetails] = useState();
  const [isOpenAddTreatmentDialog, setIsOpenAddTreatmentDialog] =
    useState(false);
  const [addTreatmentData, setAddTreatmentData] = useState();

  const [isOpenSuccessProductDialog, setIsOpenSuccessProductDialog] =
    useState(false);

  const methods = useForm({
    resolver: yupResolver(productValidationSchema()),
    mode: "onChange",
  });

  const imageUrl = useSelector((state) => state?.uploadFile?.url);
  const [
    getAllProductType,
    { data: productTypeData, isLoading: productTypeDataLoader },
  ] = useGetProductTypeMutation();
  const [createProfessionalProduct] = useCreateProductMutation();
  const [addProductToServices] = useAddProductToServiceMutation();
  const [createdProductData, setCreatedProductData] = useState();

  const openProfessionalProductViewModal = useSelector(
    (state) => state?.retailProducts?.openProfessionalProductViewModal
  );

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const onSubmit = async (values) => {
    let payload = { type: "PROFESSIONAL_PRODUCT", ...values };
    if (imageUrl) {
      payload["imageUrl"] = imageUrl;
    }
    const response = await createProfessionalProduct(payload);
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      setCreatedProductData(response?.data?.result?.data);
      setIsStaffDialogOpen(false);
      const searchPayload = handleFilterSearch(
        ["type=PROFESSIONAL_PRODUCT"],
        ["type"],
        "filter"
      );
      getAllProducts({ page: currentPage, pageSize: 10, ...searchPayload });
      getStockProducts({ ...searchPayload });
      reset();
      setIsOpenSuccessProductDialog(true);
    }
  };

  useEffect(() => {
    dispatch(setImageUrl({ url: "" }));
    const searchPayload = handleFilterSearch(
      ["type=PROFESSIONAL_PRODUCT"],
      ["type"],
      "filter"
    );
    getAllProducts({ page: currentPage, pageSize: 10, ...searchPayload });
  }, []);

  const staffTableHeader = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;
          const imgSource = rowData[tableMeta?.rowIndex]?.imageUrl;
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSingleProductId(rowData[tableMeta?.rowIndex]?._id);
                handleOpenProfileOpen();
                dispatch(
                  handleOpenProfessionalProductViewModal({
                    singleProductId: rowData[tableMeta?.rowIndex]?._id,
                    getAllProducts: getAllProducts,
                    getStockProducts: getStockProducts,
                  })
                );
              }}
            >
              {imgSource ? (
                <Avatar
                  sx={{ width: "48px", height: "48px", cursor: "pointer" }}
                  src={imgSource}
                  onClick={() => {}}
                  variant="rounded"
                />
              ) : (
                <Avatar
                  variant="rounded"
                  {...nameInitialsAvatar(`${value}`)}
                  onClick={() => {}}
                />
              )}
              <Typography
                sx={{
                  color: "#070A0E",
                  fontSize: fs14,
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
                onClick={() => {}}
              >
                {value}
              </Typography>
              {rowData[tableMeta?.rowIndex]?.isEnableProduct && (
                <Box
                  sx={{
                    backgroundColor:
                      statusDesign[
                        rowData[tableMeta?.rowIndex]?.isEnableProduct
                          ? rowData[tableMeta?.rowIndex]?.isEnableProduct
                          : "ENABLED"
                      ]?.bgColor,
                    color:
                      statusDesign[
                        rowData[tableMeta?.rowIndex]?.isEnableProduct
                          ? rowData[tableMeta?.rowIndex]?.isEnableProduct
                          : "ENABLED"
                      ]?.color,
                    ...staffStyle.statusInfo,
                  }}
                >
                  {rowData[tableMeta?.rowIndex]?.isEnableProduct
                    ? rowData[tableMeta?.rowIndex]?.isEnableProduct
                    : "ENABLED"}
                </Box>
              )}
            </Box>
          );
        },
      },
    },
    {
      name: "productType",
      label: "Product Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: fs14,
                  fontWeight: "400",
                  cursor: "pointer",
                }}
              >
                {value?.name ?? "---"}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "stock",
      label: "Stock",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const totalStock = value || 0;
          const usedStock = 0;
          const availableStock = totalStock - usedStock;
          const stockPercentage = (availableStock / totalStock) * 100;
          return (
            <Box
              sx={{
                // display: "flex",
                // gap: "16px",
                // alignItems: "center",
                width: "100%",
                height: "100%",
                // border: "1px solid red",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                  width: "100%",
                }}
              >
                <Package size={12} color="#8F9193" />
                <Typography sx={{ color: "#5E6062", fontSize: "10px" }}>
                  {value}
                </Typography>
              </Box>
              <SPALinearProgress value={stockPercentage} color="#4E45E5" />
            </Box>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                setStaffPopoverOpen(e.currentTarget);
                setSingleProductId(rowData[tableMeta?.rowIndex]?._id);
              }}
            >
              <DotsIcon />
            </Box>
          );
        },
      },
    },
  ];

  const handleSearchProduct = () => {
    let attributeArray = [];
    if (searchStaff !== "") {
      if (isNaN(searchStaff)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchStaff,
        attributeArray,
        "search"
      );

      getAllProducts({ ...searchPayload });
    } else {
      const searchPayload = handleFilterSearch(
        ["type=PROFESSIONAL_PRODUCT"],
        ["type"],
        "filter"
      );
      getAllProducts({ ...searchPayload });
    }
  };

  useEffect(() => {
    handleSearchProduct();
  }, [searchStaff]);

  useEffect(() => {
    const addProductAsync = async () => {
      if (addTreatmentData && createdProductData?._id) {
        let finalData = addTreatmentData?.services?.map((item) => item?._id);
        let payload = {
          serviceIds: finalData,
        };

        const response = await addProductToServices({
          payload: payload,
          productId: createdProductData?._id,
        });
        if (response?.data?.status === false) {
          toast.error(response?.data?.message);
        }
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          setCreatedProductData(null);
          setIsOpenAddTreatmentDialog(false);
        }
      }
    };

    addProductAsync();
  }, [addTreatmentData]);

  const handleNewStaff = () => {
    setIsStaffDialogOpen(true);
  };

  const handleAddTreatment = () => {
    setIsOpenAddTreatmentDialog(true);
    setIsOpenSuccessProductDialog(false);
  };

  const handleOpenProfileOpen = () => {
    dispatch(
      handleOpenProfessionalProductViewModal({
        singleProductId: singleProductId,
        getAllProducts: getAllProducts,
        getStockProducts: getStockProducts,
      })
    );
  };
  const handleCloseProfileOpen = () => {
    setIsProfileOpen(false);
    const searchPayload = handleFilterSearch(
      ["type=PROFESSIONAL_PRODUCT"],
      ["type"],
      "filter"
    );
    getStockProducts({ ...searchPayload });
    getAllProducts({ page: currentPage, pageSize: 10, ...searchPayload });
  };

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      ["type=PROFESSIONAL_PRODUCT"],
      ["type"],
      "filter"
    );
    getAllProducts({ ...searchPayload });
    getAllProductType();
  }, []);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      ["type=PROFESSIONAL_PRODUCT"],
      ["type"],
      "filter"
    );
    getAllProducts({ page: currentPage, pageSize: 10, ...searchPayload });
  }, [currentPage]);

  const handleStaffCloseDialog = () => {
    setIsStaffDialogOpen(false);
    reset();
    const searchPayload = handleFilterSearch(
      ["type=PROFESSIONAL_PRODUCT"],
      ["type"],
      "filter"
    );
    getStockProducts({ ...searchPayload });
  };

  return {
    searchStaff,
    setSearchStaff,
    singleProductId,
    setSingleProductId,
    staffTableHeader,
    productsData,
    productsDataLoader,
    getAllProducts,
    currentPage,
    setCurrentPage,
    isProfileOpen,
    handleOpenProfileOpen,
    handleCloseProfileOpen,
    isStaffDialogOpen,
    setIsStaffDialogOpen,
    staffPopoverOpen,
    setStaffPopoverOpen,
    staffPopOverId,
    openStaffPopOver,
    handleNewStaff,
    isRolesDialogOpen,
    setIsRolesDialogOpen,
    isQualificationsDialogOpen,
    setIsQualificationsDialogOpen,
    // Create Staff
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    productTypeData,
    therapistDetails,
    handleStaffCloseDialog,
    getAllProductType,
    dispatch,
    openProfessionalProductViewModal,

    isOpenAddTreatmentDialog,
    setIsOpenAddTreatmentDialog,
    setAddTreatmentData,

    handleAddTreatment,
    setIsOpenSuccessProductDialog,
    isOpenSuccessProductDialog,
    setCreatedProductData,
  };
};

export default useProfessionalProduct;
