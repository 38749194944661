import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import React from "react";
import TreatmentEmptyIcon from "../../../../assets/svg/treatment-empty-icon";
import {
  MagnifyingGlass,
  Minus,
  Package,
  Plus,
  Trash,
  Warning,
  X,
} from "@phosphor-icons/react";
import ProductCard from "../product-card";
import ProductCategoryTab from "../products-tab";
import SPATextField from "../../../common/spa-textField";
import { productCategoryTabs } from "../services-data";
import { DisabledButton, EnabledButton } from "./product-card-style";

const ProductStep = ({
  handleRequiredOptional,
  handleQuantityChange,
  handleAddProduct,
  selectedProduct,
  allProductData,
  productDataLoading,
  setActiveItem,
  activeItem,
  isSearchBox,
  setIsSearchBox,
  setSearchProduct,
  searchProduct,
  errors,
  isRequirements = false,
  productCategoryData,
  toggleSwitch,
  enabled,
  handleRemove,
  getMeasurementAbbreviation,
  setEnabled,
  setActiveHistoryTab,
  handleSingleProductDeselect,
  handleRemoveProduct,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "16px",
        flexDirection: "column",
        gap: "16px",
        borderRadius: "16px",
        background: "#F8F7FE",
        height: "100%",
      }}
    >
      <Grid container spacing={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: "12px 0px 0px 12px",
          }}
        >
          <Typography
            sx={{
              color: "#4A4C4F",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            Professional Products Usage
          </Typography>
          <Typography
            sx={{
              color: "#5E6062",
              fontSize: "12px",
            }}
          >
            These are the products that this treatment uses PER SESSION.
          </Typography>
        </Box>
        <Grid item lg={12} xs={12} sx={{ position: "relative" }}>
          <SPATextField
            name="search"
            // label="Search Products"
            placeholder="Search Products"
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "48px",
              },
            }}
            onClick={() => setIsSearchBox(true)}
            onFocus={() => setIsSearchBox(true)}
            value={searchProduct}
            onChangeValue={(event) => {
              setSearchProduct(event?.target?.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                  <MagnifyingGlass size={24} />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ paddingLeft: "10px", cursor: "pointer" }}
                  onClick={() => {
                    setSearchProduct("");
                  }}
                >
                  {searchProduct && <X size={24} />}
                </InputAdornment>
              ),
            }}
          />
          {isSearchBox && (
            <ClickAwayListener
              onClickAway={() => {
                setIsSearchBox(false);
                setActiveItem("ALL");
                setActiveHistoryTab("");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  width: "100%",
                  paddingRight: "16px",
                  gap: "16px",
                  borderRadius: "16px",
                  background: "#FAFAFA",
                  zIndex: "100",
                  boxShadow:
                    "-66px 62px 25px 0px rgba(78, 69, 229, 0.00), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -3px 2px 8px 0px rgba(78, 69, 229, 0.05)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "164px",
                    padding: "8px",
                    flexDirection: "column",
                    gap: "4px",
                    borderRadius: "16px 0px 0px 16px",
                    background: "#FFF",
                  }}
                >
                  <Box
                    sx={{
                      height: "35vh",
                      overflowY: "auto",
                    }}
                  >
                    <ProductCategoryTab
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                      tabsData={productCategoryData}
                      setActiveHistoryTab={setActiveHistoryTab}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "35vh",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  <ProductCard
                    productsData={allProductData?.result?.data?.data}
                    productLoader={productDataLoading}
                    handleSingleProductSelect={(item) => handleAddProduct(item)}
                    handleSingleProductDeselect={(item) =>
                      handleRemoveProduct(item)
                    }
                    selectedProducts={selectedProduct}
                  />
                </Box>
              </Box>
            </ClickAwayListener>
          )}
        </Grid>

        <Grid item lg={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              p: "16px 0px",
              width: "100%",
              height: "45vh",
              overflowY: "auto",
            }}
          >
            {selectedProduct?.length > 0 &&
              selectedProduct.map((item) => {
                return (
                  <Box
                    sx={{
                      borderRadius: "8px",
                    }}
                  >
                    <Grid container>
                      <Grid xs={12}>
                        <Box
                          key={item?._id}
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            flexShrink: 0,
                            width: "100%",
                            // height: "48px",
                            paddingRight: "8px",
                            alignItems: "center",
                            gap: "8px",
                            borderRadius: "8px 8px 0px 0px",
                            background: "#FFF",
                            justifyContent: "space-between",
                            overflow: "hidden",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                              borderRadius: "8px",
                            }}
                          >
                            <Box sx={{ p: "8px" }}>
                              <Box
                                sx={{
                                  padding: "4px 8px",
                                  background: "#FAFAFA",
                                  color: "#25272B",
                                  fontSize: "10px",
                                  textTransform: "uppercase",
                                  maxWidth: "fit-content",
                                  borderRadius: "4px",
                                }}
                              >
                                {item?.productType?.name}
                              </Box>
                              <Box sx={{ display: "flex", gap: "4px" }}>
                                <Typography
                                  sx={{
                                    color: "#070A0E",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item?.name}
                                </Typography>
                                <Box sx={{ display: "flex", gap: "2px" }}>
                                  <Package />
                                  <Typography
                                    sx={{
                                      color: "#070A0E",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item?.stock}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              flexDirection: {
                                xs: "row",
                                md: "column",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <Minus
                                size={16}
                                color="#4E45E5"
                                onClick={() =>
                                  handleQuantityChange(item._id, -1)
                                }
                              />
                              <Box
                                sx={{
                                  borderRadius: "8px",
                                  border: "1px solid  #DADADB",
                                  background: "#FFF",
                                  maxWidth: "62px",
                                  maxHeight: "32px",
                                  overflow: "hidden",
                                }}
                              >
                                <Input
                                  type="text"
                                  value={`${
                                    item.quantity
                                  } ${getMeasurementAbbreviation(
                                    item.measurement
                                  )}`}
                                  readOnly
                                  inputProps={{
                                    style: { textAlign: "center" },
                                  }}
                                  sx={{
                                    paddingLeft: {
                                      sm: "10px",
                                      xs: "2px",
                                    },
                                  }}
                                  name="client"
                                />
                              </Box>
                              <Plus
                                size={16}
                                color="#4E45E5"
                                onClick={() =>
                                  handleQuantityChange(item._id, 1)
                                }
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid xs={12}>
                        <Box
                          key={item?._id}
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            flexShrink: 0,
                            width: "100%",
                            paddingRight: "8px",
                            alignItems: "center",
                            gap: "8px",
                            borderRadius: "0px 0px 8px 8px",
                            background: "#FFF",
                            justifyContent: "space-between",
                            overflow: "hidden",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                              borderRadius: "8px",
                            }}
                          >
                            <Box sx={{ p: "8px" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "5px",
                                  backgroundColor: "#EDECFC",
                                  borderRadius: "8px  ",
                                }}
                              >
                                <EnabledButton
                                  active={
                                    item?.isRequired === "REQUIRED"
                                      ? true
                                      : null
                                  }
                                  onClick={() => {
                                    handleRequiredOptional(
                                      item?._id,
                                      "REQUIRED"
                                    );
                                    toggleSwitch();
                                  }}
                                >
                                  Required
                                </EnabledButton>
                                <DisabledButton
                                  active={
                                    item?.isRequired === "OPTIONAL"
                                      ? true
                                      : null
                                  }
                                  onClick={() => {
                                    handleRequiredOptional(
                                      item?._id,
                                      "OPTIONAL"
                                    );
                                    toggleSwitch();
                                  }}
                                >
                                  Optional
                                </DisabledButton>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              flexDirection: {
                                xs: "row",
                                md: "column",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                              onClick={() => {
                                handleRemove(item);
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#D13438",
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                REMOVE
                              </Typography>
                              <Trash size={16} color="#D13438" />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            {selectedProduct?.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  height: isRequirements ? "10vh" : "40vh",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                  mt: "10px",
                }}
              >
                {!isRequirements && (
                  <Box>
                    <TreatmentEmptyIcon />
                  </Box>
                )}
                <Typography
                  sx={{
                    color: "#5E6062",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  There are no professional products selected
                </Typography>
                <Typography
                  sx={{
                    color: "#5E6062",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  Select products to add those to the treatment requirement.
                </Typography>
                <Button
                  onClick={() => setIsSearchBox(true)}
                  sx={{
                    backgroundColor: "#4E45E5",
                    color: "#FFFFFF",
                    paddingInline: "24px",
                    paddingBlock: "12px",
                    borderRadius: "8px",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: "#4E45E5",
                    },
                  }}
                >
                  Select Products
                </Button>
              </Box>
            )}
            {selectedProduct?.length === 0 && errors.products && (
              <Typography
                sx={{
                  color: "#FFAA33",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  mt: "5px",
                }}
                color="error"
              >
                <Warning color={"#FFAA33"} size={16} />{" "}
                {errors.products.message}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductStep;
