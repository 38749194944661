import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useGetProductTypeMutation } from "../../../../services/product-type";
import {
  useCreatePromoCodeMutation,
  useGetPromoCodesMutation,
  useGetSinglePromoCodeQuery,
  useUpdateSinglePromoCodeMutation,
} from "../../../../services/promo-code";
import { useGetServiceCategoriesMutation } from "../../../../services/service-category";
import { handleFilterSearch } from "../../../../utils/searchFilter";
import { appliesToFormData } from "./promocode-form-data";
import { validationSchema } from "./validationSchema";

export default function usePromoCode() {
  const [searchPromoCode, setSearchPromoCode] = useState("");
  const [activePromoCodeTab, setActivePromoCodeTab] = useState(0);
  const [singlePromoCodeId, setSinglePromoCodeId] = useState(null);
  const [contentTypeValue, setContentTypeValue] = useState(null);
  const [singleContentId, setSingleContentId] = useState(null);
  const [isOpenDisableConfirmDialog, setIsOpenDisableConfirmDialog] =
    useState(false);
  const [enabled, setEnabled] = useState(null);
  const [discountTypeValue, setDiscountTypeValue] = useState("PERCENTAGE");
  const [isOpenAddContentDialog, setIsOpenAddContentDialog] = useState(false);
  const [addContentData, setAddContentData] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});

  const [selectedAllData, setSelectedAllData] = useState({
    services: [],
    products: [],
    serviceCategory: [],
    productType: [],
    staff: [],
    client: [],
  });

  const [appliesToTabValue, setAppliesToTabValue] = useState(
    appliesToFormData().reduce((acc, item) => {
      acc[item.type] = item.options[0].value;
      return acc;
    }, {})
  );

  const toggleSwitch = () => {
    if (enabled === true) {
      setIsOpenDisableConfirmDialog(true);
    } else {
      setEnabled(true);
    }
  };

  const validationSchemaInstance = validationSchema();
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
  });
  const [
    getAllServiceCategory,
    { data: serviceCategoryData, isLoading: serviceCategoryDataLoader },
  ] = useGetServiceCategoriesMutation();

  const [
    getAllProductType,
    { data: productTypeData, isLoading: productTypeDataLoader },
  ] = useGetProductTypeMutation();

  const [
    getAllPromoCode,
    { data: promoCodeData, isLoading: promoCodeDataLoader },
  ] = useGetPromoCodesMutation();

  const [createPromoCode] = useCreatePromoCodeMutation();
  const [updateSinglePromoCode] = useUpdateSinglePromoCodeMutation();

  const {
    data: singlePromoCode,
    isLoading: singlePromoCodeLoader,
    refetch: refetchSinglePromoCode,
  } = useGetSinglePromoCodeQuery(singlePromoCodeId, {
    skip: !singlePromoCodeId,
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const couponNameValue = watch("name");

  const handleCheckboxChange = (event, type) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [type]: event.target.checked,
    }));
  };

  const onSubmit = async (values) => {
    let payload = {
      ...values,
      ...appliesToTabValue,
      discountType: discountTypeValue,
      tax: values?.tax ?? undefined,
      startDate: new Date(values?.startDate).toISOString(),
      endDate: new Date(values?.endDate).toISOString(),
      validForAppointmentStartDate: new Date(
        values?.validForAppointmentStartDate
      ).toISOString(),
      validForAppointmentEndDate: new Date(
        values?.validForAppointmentEndDate
      ).toISOString(),
    };

    const filteredPayload = {};
    Object.keys(payload).forEach((key) => {
      if (
        [
          "allClients",
          "allProductTypes",
          "allProducts",
          "allServiceCategories",
          "allServices",
          "allStaff",
        ].includes(key)
      ) {
        if (checkedItems[key]) {
          filteredPayload[key] = payload[key];
        }
      } else {
        filteredPayload[key] = payload[key];
      }
    });

    // Ensure that if related keys are unchecked, their values are removed from the payload
    if (!checkedItems.allStaff) {
      delete filteredPayload.staff;
    }
    if (!checkedItems.allClients) {
      delete filteredPayload.client;
    }
    if (!checkedItems.allServices) {
      delete filteredPayload.services;
    }
    if (!checkedItems.allProducts) {
      delete filteredPayload.products;
    }
    if (!checkedItems.allProductTypes) {
      delete filteredPayload.productType;
    }
    if (!checkedItems.allServiceCategories) {
      delete filteredPayload.serviceCategory;
    }

    let response;
    if (singlePromoCodeId) {
      response = await updateSinglePromoCode({
        id: singlePromoCode?.result?.data?._id,
        payload: {
          ...filteredPayload,
          couponStatus:
            enabled === true
              ? "ENABLED"
              : enabled === false
              ? "DISABLED"
              : undefined,
        },
      });
    } else {
      response = await createPromoCode(filteredPayload);
    }
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    getAllPromoCode({});
    handleCreatePromoCode();
  };

  useEffect(() => {
    switch (contentTypeValue) {
      case "Products":
        setSelectedAllData((prevState) => ({
          ...prevState,
          products: addContentData?.products ?? [],
        }));
        setValue(
          "products",
          addContentData?.products?.map((item) => item?._id)
        );
        break;
      case "Treatments":
        setSelectedAllData((prevState) => ({
          ...prevState,
          services: addContentData?.services ?? [],
        }));
        setValue(
          "services",
          addContentData?.services?.map((item) => item?._id)
        );
        break;
      case "Staff":
        setSelectedAllData((prevState) => ({
          ...prevState,
          staff: addContentData?.staff ?? [],
        }));
        setValue(
          "staff",
          addContentData?.staff?.map((item) => item?._id)
        );
        break;
      case "Customers":
        setSelectedAllData((prevState) => ({
          ...prevState,
          client: addContentData?.client ?? [],
        }));
        setValue(
          "client",
          addContentData?.client?.map((item) => item?._id)
        );
        break;
      case "Treatment Categories":
        setSelectedAllData((prevState) => ({
          ...prevState,
          serviceCategory: addContentData?.serviceCategory ?? [],
        }));
        setValue(
          "serviceCategory",
          addContentData?.serviceCategory?.map((item) => item?._id)
        );
        break;
      case "Product Types":
        setSelectedAllData((prevState) => ({
          ...prevState,
          productType: addContentData?.productType ?? [],
        }));
        setValue(
          "productType",
          addContentData?.productType?.map((item) => item?._id)
        );
        break;
      default:
        break;
    }
  }, [addContentData]);

  useEffect(() => {
    getAllServiceCategory();
    getAllProductType();
  }, []);

  const handleTabSelection = (item) => {
    setActivePromoCodeTab(item);
    const finalFilter = item === 1 ? "ENABLED" : item === 2 ? "DISABLED" : "";
    const searchPayload = handleFilterSearch(
      [`couponStatus=${finalFilter}`],
      ["couponStatus"],
      "filter"
    );
    if (item === 0) {
      getAllPromoCode({});
    } else {
      getAllPromoCode({ ...searchPayload });
    }
  };

  const handleSinglePromoCode = (item) => {
    setSinglePromoCodeId(item?._id);
  };

  const handleCreatePromoCode = () => {
    reset({
      name: "",
      coupon: "",
      tax: "",
      discount: "",
      maximumLimitPerCostumer: "",
      maximumTotalLimit: "",
      minimumSpend: "",
      maximumDiscountAmount: "",
      combineOtherOffer: false,
      startDate: "",
      endDate: "",
      validForAppointmentStartDate: "",
      validForAppointmentEndDate: "",
    });
    setSinglePromoCodeId("");
    setSelectedAllData({
      services: [],
      products: [],
      serviceCategory: [],
      productType: [],
      staff: [],
      client: [],
    });
    setCheckedItems({});

    setAppliesToTabValue(
      appliesToFormData().reduce((acc, item) => {
        acc[item.type] = item.options[0].value;
        return acc;
      }, {})
    );
  };

  useEffect(() => {
    if (singlePromoCode && singlePromoCodeId) {
      const singlePromoCodeFormat = singlePromoCode?.result?.data;
      setValue("name", singlePromoCodeFormat?.name);
      setValue("coupon", singlePromoCodeFormat?.coupon);
      setValue("tax", singlePromoCodeFormat?.tax);
      setValue("discount", singlePromoCodeFormat?.discount);
      setValue(
        "maximumLimitPerCostumer",
        singlePromoCodeFormat?.maximumLimitPerCostumer
      );
      setValue("maximumTotalLimit", singlePromoCodeFormat?.maximumTotalLimit);
      setValue("minimumSpend", singlePromoCodeFormat?.minimumSpend);
      setValue(
        "maximumDiscountAmount",
        singlePromoCodeFormat?.maximumDiscountAmount
      );
      setValue("startDate", singlePromoCodeFormat?.startDate);
      setValue("endDate", singlePromoCodeFormat?.endDate);
      setValue("combineOtherOffer", singlePromoCodeFormat?.combineOtherOffer);
      setValue(
        "validForAppointmentStartDate",
        singlePromoCodeFormat?.validForAppointmentStartDate
      );
      setValue(
        "validForAppointmentEndDate",
        singlePromoCodeFormat?.validForAppointmentEndDate
      );
      setDiscountTypeValue(singlePromoCodeFormat?.discountType);
      setValue(
        "products",
        singlePromoCodeFormat?.products?.map((item) => item?._id)
      );
      setValue(
        "services",
        singlePromoCodeFormat?.services?.map((item) => item?._id)
      );
      setValue(
        "productType",
        singlePromoCodeFormat?.productType?.map((item) => item?._id)
      );
      setValue(
        "serviceCategory",
        singlePromoCodeFormat?.serviceCategory?.map((item) => item?._id)
      );
      setValue(
        "client",
        singlePromoCodeFormat?.client?.map((item) => item?._id)
      );
      setValue(
        "staff",
        singlePromoCodeFormat?.staff?.map((item) => item?._id)
      );
      setSelectedAllData({
        products: singlePromoCodeFormat?.products,
        services: singlePromoCodeFormat?.services,
        productType: singlePromoCodeFormat?.productType,
        serviceCategory: singlePromoCodeFormat?.serviceCategory,
        client: singlePromoCodeFormat?.client,
        staff: singlePromoCodeFormat?.staff,
      });

      setEnabled(
        singlePromoCodeFormat?.couponStatus === "ENABLED"
          ? true
          : singlePromoCodeFormat?.couponStatus === "DISABLED"
          ? false
          : null
      );
      let appliesToInitialData = {
        allClients: singlePromoCodeFormat?.allClients,
        allStaff: singlePromoCodeFormat?.allStaff,
        allServices: singlePromoCodeFormat?.allServices,
        allProducts: singlePromoCodeFormat?.allProducts,
        allProductTypes: singlePromoCodeFormat?.allProductTypes,
        allServiceCategories: singlePromoCodeFormat?.allServiceCategories,
      };
      setAppliesToTabValue(appliesToInitialData);

      // Set initial checkbox values
      const initialCheckedItems = {};
      Object.keys(appliesToInitialData).forEach((key) => {
        initialCheckedItems[key] = appliesToInitialData[key] !== undefined;
      });

      setCheckedItems(initialCheckedItems);
    }
  }, [singlePromoCode, singlePromoCodeId]);

  const handleSearchPromoCode = () => {
    let attributeArray = [];
    if (searchPromoCode !== "") {
      if (isNaN(searchPromoCode)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchPromoCode,
        attributeArray,
        "search"
      );
      getAllPromoCode({ ...searchPayload });
    } else getAllPromoCode({});
  };

  useEffect(() => {
    handleSearchPromoCode();
  }, [searchPromoCode]);

  useEffect(() => {
    if (singlePromoCodeId) {
      refetchSinglePromoCode();
    }
  }, [singlePromoCodeId]);

  useEffect(() => {
    getAllPromoCode({});
  }, []);

  useEffect(() => {
    if (singlePromoCodeId) {
      refetchSinglePromoCode();
    }
  }, [singlePromoCodeId]);

  const handleDisableConfirm = async () => {
    setEnabled(false);
    setIsOpenDisableConfirmDialog(false);
  };

  const selectedValueCounter = (item) => {
    if (item) {
      const keyMap = {
        Treatments: "services",
        Staff: "staff",
        Customers: "client",
        "Treatment Categories": "serviceCategory",
        Products: "products",
        "Product Types": "productType",
      };
      const key = keyMap[item] || "";
      return {
        data: selectedAllData[key],
      };
    }
  };

  return {
    methods,
    handleSubmit,
    onSubmit,
    control,
    activePromoCodeTab,
    handleTabSelection,
    promoCodeData,
    singlePromoCodeId,
    handleCreatePromoCode,
    handleSinglePromoCode,
    serviceCategoryData,
    productTypeData,
    promoCodeDataLoader,
    singlePromoCodeLoader,
    setSearchPromoCode,
    toggleSwitch,
    enabled,
    couponNameValue,
    setIsOpenDisableConfirmDialog,
    isOpenDisableConfirmDialog,
    handleDisableConfirm,

    discountTypeValue,
    setDiscountTypeValue,

    contentTypeValue,
    setContentTypeValue,
    isOpenAddContentDialog,
    setIsOpenAddContentDialog,
    setAddContentData,
    singleContentId,
    selectedValueCounter,
    appliesToTabValue,
    setAppliesToTabValue,
    handleCheckboxChange,
    checkedItems,
  };
}
