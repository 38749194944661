import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Pagination, PaginationItem } from "@mui/material";
import React from "react";
import { customColors, fs14, fw500, secondary } from "../../../theme";

/**
 * SPAPagination component.
 * Renders a Material-UI Pagination component with customized styles.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.count - The total number of pages.
 * @param {function} props.onChange - The function which used to change page.
 * @returns {JSX.Element} JSX code for rendering the CustomPagination component.
 */

const SPAPagination = ({ count, onChange, ...rest }) => {
  return (
    <Pagination
      count={count}
      onChange={onChange}
      variant="outlined"
      shape="rounded"
      showFirstButton
      showLastButton
      renderItem={(item) => (
        <PaginationItem
          sx={{ fontSize: fs14, fontWeight: fw500 }}
          slots={{
            first: KeyboardDoubleArrowLeft,
            last: KeyboardDoubleArrowRight,
          }}
          {...item}
        />
      )}
      sx={styles.paginationStyles}
      {...rest}
    />
  );
};

export default SPAPagination;

// Styles for the SPAPagination component
const styles = {
  paginationStyles: {
    ".MuiButtonBase-root": {
      borderRadius: "8px",
      color: "#4A4C4F",
      bgcolor: customColors.white,
      border: `1px solid #F0F0F1`,
    },
    ".Mui-selected": {
      bgcolor: `#EDECFC !important`,
      color: secondary.main,
      border: `1px solid #EDECFC`,
      "&:hover": {
        border: `1px solid #EDECFC`,
        bgcolor: `#EDECFC !important`,
      },
    },
  },
};

// Example Usage
// <SPAPagination count={10} />
