import React from 'react';
import AuthLayout from '../../../layout/auth-layout';
import RegisterComponent from '../../../components/auth/register';

const Register = () => {
  return (
    <AuthLayout
      heading='Create an account'
      subHeading='Please enter your details'
    >
      <RegisterComponent />
    </AuthLayout>
  );
};

export default Register;
