import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import * as React from "react";

export default function SPALinearProgress({
  value = 50,
  color = "#4E45E5",
  bgcolor = "#EDECFC",
}) {
  return (
    <>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          height: 12,
          borderRadius: "16px",
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: bgcolor,
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: "16px",
            backgroundColor: color,
          },
        }}
      />
    </>
  );
}
