import { styled,  useMediaQuery } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import {  secondary } from "../../theme";

const drawerWidth = 337;
export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  const isSidebarResponsive = useMediaQuery("(max-width:1165px)");
  return {
    //   zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    minHeight: isSidebarResponsive ? "auto" : "96px",
    height: "auto",
    // width: `100%`,
    width: `calc(100% - ${"127px"})`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(10)})`,
    },
    boxShadow: "0px 0px 5px 0px #00000040",
    background: "#F8F7FE",
    ...(open && {
      marginLeft: drawerWidth,
      width: isSidebarResponsive
        ? `calc(100% - 127px)`
        : `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("sm")]: {
        width: `calc(100% - ${theme.spacing(10)}px)`,
      },
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),

  };
});

export const SPADrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  return {
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      background: secondary.main,
      minHeight: "100vh",
      padding: "24px 31px",
      boxShadow: "0px 0px 5px 0px #33303026",
      width: drawerWidth,
      [theme.breakpoints.down("sm")]: {
        width: 280,
        padding: "24px 8px",
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: "127px",
        padding: "24px 31px",
        [theme.breakpoints.down("sm")]: {
          width: theme.spacing(10),
          padding: "24px 8px",
        },
      }),
    },
  };
});

export const StyledBadge = (theme) => ({
  width:"48px",
  height: "48px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background:  "#EDECFC",
  borderRadius: "8px",
});
