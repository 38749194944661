import { Button, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "../../../assets/svg/check-circle-icon";
import { fs16, fs20, fw400 } from "../../../theme";
import theme from "../../../theme/theme";
import { closeButtonFiled, linkButtonLight } from "./spa-dialogue-styles";
import { Warning, WarningCircle, WarningOctagon } from "@phosphor-icons/react";

export default function SPAAlertDialog({
  open,
  handleClose,
  handleNewTab,
  title,
  contentText,
  disagreeText,
  agreeText,
  type = "success",
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            maxWidth: "500px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ paddingTop: "24px" }}>
          <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
            {type === "info" && (
              <WarningCircle size={24} weight="fill" color="#115ACB" />
            )}
            {type === "success" && <CheckCircleIcon />}
            {type === "error" && (
              <WarningOctagon size={24} weight="fill" color="#BE2F33" />
            )}
            {type === "warning" && (
              <Warning size={24} weight="fill" color="#FA9200" />
            )}
            <Typography
              fontSize={fs20}
              fontWeight={fw400}
              color={theme.palette.textGrayPrimary900.main}
            >
              {title}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            fontSize={fs16}
            fontWeight={fw400}
            color={theme.palette.textGraySecondary.main}
            sx={{ paddingLeft: type === "normal" ? "0px" : "24px" }}
          >
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingInline: "24px", paddingBottom: "24px" }}>
          {disagreeText && (
            <Button onClick={handleNewTab} sx={linkButtonLight}>
              {disagreeText}
            </Button>
          )}
          {agreeText && (
            <Button sx={closeButtonFiled} onClick={handleClose} autoFocus>
              {agreeText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
