import { baseAPI } from "../baseApi";

export const AppointmentApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createAppointment: builder.mutation({
      query: (payload) => ({
        url: "/appointment",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Calendar", "allNotes"],
    }),
    getSingleAppointment: builder.query({
      query: (appointmentId) => ({ url: "/appointment/" + appointmentId }),
      providesTags: ["singleAppointment"],
    }),
    updateSingleAppointment: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/appointment/" + id,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["singleAppointment", "Calendar", "allNotes"],
    }),
    addNoteAppointment: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/appointment/add-note/" + id,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["singleAppointment", "Calendar"],
    }),
    cancelAppointment: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/appointment/cancel/" + id,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["singleAppointment", "Calendar"],
    }),
    createBusyTime: builder.mutation({
      query: ({ payload }) => {
        return {
          url: "/busy-time",
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Calendar"],
    }),
    updateBusyTime: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/busy-time/" + id,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Calendar"],
    }),
  }),
});

export const {
  useCreateAppointmentMutation,
  useGetSingleAppointmentQuery,
  useUpdateSingleAppointmentMutation,
  useAddNoteAppointmentMutation,
  useCancelAppointmentMutation,
  useCreateBusyTimeMutation,
  useUpdateBusyTimeMutation,
} = AppointmentApi;
