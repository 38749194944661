import { Box, Button, Grid, Typography } from "@mui/material";
import { MapPin } from "@phosphor-icons/react";
import React from "react";
import { fs16, fw600 } from "../../../../../../../../theme";
import theme from "../../../../../../../../theme/theme";
import Loader from "../../../../../../../common/loader";
import FormProvider from "../../../../../../../hook-form/FormProvider";
import { SubmitBtn, address } from "../../../../style";
import { addressFormData } from "../../staff-profile-data";
import useStaffAddress from "./useStaffAddress";

export default function Address({ singleStaffData, singleStaffRefetching }) {
  const { onSubmit, handleSubmit, addressLoading, control } = useStaffAddress({
    singleStaffData,
    singleStaffRefetching,
  });

  return (
    <>
      {addressLoading ? (
        <Loader />
      ) : (
        <Grid
          xs={12}
          style={{ height: "100%", padding: "16px", borderRadius: "8px" }}
          bgcolor={theme.palette.purple10.main}
        >
          <Box style={address.flexContainer} mb={2}>
            <Box style={address.flexContainer}>
              <MapPin size={24} />
              <Typography
                fontSize={fs16}
                fontWeight={fw600}
                color={theme.palette.textGraySecondary.main}
              >
                Address
              </Typography>
            </Box>
          </Box>
          <>
            <FormProvider onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                {addressFormData().map((item, index) => {
                  return (
                    <Grid item lg={item.gridLength} xs={12} key={index}>
                      <item.component
                        {...item.otherOptions}
                        control={control}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  mt: "8px",
                }}
              >
                <Button type="submit" sx={SubmitBtn}>
                  Save Changes
                </Button>
              </Box>
            </FormProvider>
          </>
        </Grid>
      )}
    </>
  );
}
