import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRegisterMutation } from '../../../services/authApi';
import * as yup from 'yup';
import { setLocalStorageItem } from '../../../utils/localStorage';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const validationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
});

const useRegister = () => {
  const [responseError, setResponseError] = useState(null);
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;
  const [registerTrigger] = useRegisterMutation();

  const onSubmit = (values) => {
    // try {
    //   const res = await registerTrigger(values).unwrap();
    //   if (res.status === false) {
    //     setResponseError(res.message);
    //   } else {
    //     setLocalStorageItem('auth-token', res?.result?.data?.accessToken);
    //     setLocalStorageItem('userData', res?.result?.data?.user);
    //     navigate('/clients');
    //   }
    // } catch (error) {
    //   const errMsg = error?.message;
    // }
  };
  return {
    methods,
    handleSubmit,
    onSubmit,
    control,
    responseError,
  };
};

export default useRegister;
