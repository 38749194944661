import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  Input,
  InputAdornment,
  Popover,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ArrowLineRight,
  ArrowRight,
  CalendarBlank,
  CalendarPlus,
  CalendarX,
  CallBell,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  Circle,
  Clock,
  CreditCard,
  Door,
  Envelope,
  HandHeart,
  LinkBreak,
  Note,
  PaperPlaneTilt,
  PencilSimple,
  Plus,
  Printer,
  Prohibit,
  ShootingStar,
  SketchLogo,
  StarHalf,
  User,
  XCircle,
} from "@phosphor-icons/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { Calendar, Views, dayjsLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import NewAppointmentForm from "../../components/new-appointment-form";
import {
  useGetSingleAppointmentQuery,
  useUpdateBusyTimeMutation,
  useUpdateSingleAppointmentMutation,
} from "../../services/appointment";
import { useGetCalendarInfoQuery } from "../../services/calendar";
import { useGetRoomsMutation } from "../../services/rooms";
import { useGetServicesMutation } from "../../services/services";
import { setClientDetails } from "../../store/features/client-details";
import {
  handleCalendarCurrentView,
  handleCloseAppointmentModal,
  handleCloseCreateAppointmentAlert,
  handleCloseEditContraModal,
  handleOpenAppointmentModal,
  handleOpenEditContraModal,
  handleSingleAppointmentModal,
} from "../../store/features/new-appointment";
import {
  handleCloseNoteModal,
  handleOpenNoteModal,
} from "../../store/features/notes";
import { customColors, fs14, fw600, secondary } from "../../theme";
import { lightenHexColor } from "../../utils/convertLightenHexColor";
import { nameInitialsAvatar } from "../../utils/nameInitialsAvatar";
import { timeDifference } from "../../utils/timeDifference";
import Loader from "../common/loader";
import SPAAlertDialog from "../common/spa-alert-dialogue";
import SPADialogue from "../common/spa-dialogue";
import SPAPopover from "../common/spa-popover";
import Profile from "../profile-model";
import { calendarBox, styles } from "./calendar-style";
import {
  appointmentPricingDetail,
  appointmentStatusDesign,
  currentView,
  roomsEvents,
  singleSlotDetails,
  slotCategory,
  slotIcon,
  transformEventData,
  updateOptionsFormData,
} from "./calendarData";
import CancelAppointmentModel from "./cancel-appointment";
import CreateBusyTimesModel from "./create-busy-times";
import CreateNoteModel from "./create-note";
import EditContraindicationModel from "./edit-contraindication";
import SaleModelContent from "./sale-model-content";
import { handleFilterSearch } from "../../utils/searchFilter";
import { PermissionProtected } from "../../guards/permission-protected";
import { PERMISSION } from "../../enums/permissions";
import { useGetStaffsMutation } from "../../services/staff";
import { truncateText } from "../../utils/truncateText";
import { useGetBusinessLocationsQuery } from "../../services/business-location";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage";
import { handleOpenSalesModal } from "../../store/features/sales";
dayjs.extend(utc);
const DnDCalendar = withDragAndDrop(Calendar);
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "300px",
    maxHeight: "307px",
    bgcolor: "#ffffff !important",
  },
});

/**
 * CalendarSection Component
 *
 * Implements a calendar interface using `react-big-calendar` with `dayjs` for date handling. Supports
 * day, week, and month views, event and resource display with custom rendering, and date navigation.
 * Utilizes state for current view and date selection.
 *
 * Features custom event and resource rendering functions, and placeholders for event and slot interaction.
 *
 * @returns {JSX.Element} Renders the calendar with dynamic styling and functionality.
 */
const CalendarSection = () => {
  const truncateFullName = truncateText();

  const calendarCurrentView = useSelector(
    (state) => state?.appointmentModal.calendarCurrentView
  );
  const [currentDate, setCurrentDate] = useState(dayjs());
  const localizer = dayjsLocalizer(dayjs);
  const [busyTimeModel, setBusyTimeModel] = useState(false);
  const dispatch = useDispatch();
  const [isAlertDialogOpen, setAlertDialogOpen] = useState(false);
  const [profilePopoverOpen, setProfilePopoverOpen] = useState(null);
  const profileOpenPopOver = Boolean(profilePopoverOpen);
  const profilePopOverId = profileOpenPopOver ? "simple-popover" : undefined;
  const [slotPopoverOpen, setSlotPopoverOpen] = useState(null);
  const slotOpenPopOver = Boolean(slotPopoverOpen);
  const slotPopOverId = slotOpenPopOver ? "simple-popover" : undefined;
  const [emptySlotPopoverOpen, setEmptySlotPopoverOpen] = useState(null);
  const [successCreateModel, setSuccessCreateModel] = useState(false);
  const [dropConfirmationModel, setDropConfirmationModel] = useState(false);
  const [dropConfirmationData, setDropConfirmationData] = useState("");
  const emptySlotOpenPopOver = Boolean(emptySlotPopoverOpen);
  const emptySlotPopOverId = emptySlotOpenPopOver
    ? "simple-popover"
    : undefined;
  const [changeOption, setChangeOption] = useState("");
  const [updateValue, setUpdateValue] = useState("");
  const [changeOptionModel, setChangeOptionModel] = useState(null);
  const methods = useForm();
  const [cancelAppointmentModel, setCancelAppointmentModel] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [emptyAnchorPosition, setEmptyAnchorPosition] = useState(null);
  const [selectedSlotData, setSelectedSlotData] = useState();
  const [successNoShowModel, setSuccessNoShowModel] = useState(false);
  const [addSalesModel, setAddSalesModel] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showCompleteOrder, setShowCompleteOrder] = useState(false);
  const [isClientProfileModalOpen, setIsClientProfileModalOpen] =
    useState(false);

  const {
    data: getAllBusinessLocation,
    isLoading: getAllBusinessLocationLoader,
  } = useGetBusinessLocationsQuery();

  useEffect(() => {
    if (getAllBusinessLocation?.result?.data) {
      setLocalStorageItem(
        "businessLocation",
        getAllBusinessLocation?.result?.data
      );
    }
  }, [getAllBusinessLocation?.result?.data]);

  const handleSaleModelSubmit = () => {
    setShowCart(true);
  };

  const handleOpenClientProfile = () => {
    setIsClientProfileModalOpen(true);
  };
  const handleCloseClientProfile = () => {
    setIsClientProfileModalOpen(false);
  };

  const openAppointmentAlert = useSelector(
    (state) => state?.appointmentModal.openCreatedAlertModal
  );
  const createdClientDetail = useSelector(
    (state) => state?.appointmentModal.createdClientDetails
  );
  const openAppointmentModal = useSelector(
    (state) => state?.appointmentModal?.openNewAppointmentModal
  );
  const singleAppointmentModel = useSelector(
    (state) => state?.appointmentModal?.singleAppointmentModel
  );
  const singleAppointmentData = useSelector(
    (state) => state?.appointmentModal?.singleAppointmentData
  );
  const [singleAppointmentId, setSingleAppointmentId] = useState(
    singleAppointmentData
  );

  useEffect(() => {
    setSingleAppointmentId(singleAppointmentData);
  }, [singleAppointmentData]);
  const openNoteModal = useSelector(
    (state) => state?.notesModal?.openNewNoteModal
  );
  const openEditContraindicationModal = useSelector(
    (state) => state?.appointmentModal?.openEditContraModal
  );
  const businessLocationData = getLocalStorageItem("businessLocation");
  const minTime = new Date();
  minTime.setHours(
    dayjs(businessLocationData[0]?.startTime).format("H") ?? 9,
    dayjs(businessLocationData[0]?.startTime).format("m") ?? 0,
    0
  );
  const maxTime = new Date();
  maxTime.setHours(
    dayjs(businessLocationData[0]?.endTime).format("H") ?? 23,
    dayjs(businessLocationData[0]?.endTime).format("m") ?? 59,
    0,
    0
  );
  const { handleSubmit, control, reset, setValue } = methods;
  const [
    getAllServices,
    { data: servicesData, isLoading: servicesDataLoader },
  ] = useGetServicesMutation();
  const [getAllUsers, { data: usersData, isLoading: usersDataLoader }] =
    useGetStaffsMutation();
  const [getAllRooms, { data: roomData, isLoading: roomDataLoader }] =
    useGetRoomsMutation();
  const {
    data: calendarData,
    refetch: refetchCalendarData,
    isLoading: calendarDataLoader,
  } = useGetCalendarInfoQuery(currentDate?.toISOString(), {
    skip: !currentDate,
  });
  const {
    data: singleAppointment,
    refetch: refetchSingleAppointment,
    isLoading: singleAppointmentLoader,
  } = useGetSingleAppointmentQuery(singleAppointmentId, {
    skip: !singleAppointmentId,
  });

  useEffect(() => {
    async function fetchData() {
      await refetchSingleAppointment();
    }
    if (singleAppointmentId) {
      fetchData();
    }
  }, [singleAppointmentId]);

  const [
    updateSingleAppointment,
    { isLoading: updateSingleAppointmentLoader },
  ] = useUpdateSingleAppointmentMutation();
  const [updateBusyTime, { isLoading: updateBusyTimeLoader }] =
    useUpdateBusyTimeMutation();
  const onSubmit = async (values) => {
    setChangeOptionModel(false);
    let finalData;
    let payload;
    switch (changeOption) {
      case "Staff":
        finalData = usersData?.result?.data?.data?.filter(
          (user) => user?._id === values.user
        );
        payload = { user: finalData[0]?._id };
        break;
      case "Room":
        finalData = roomData?.result?.data?.data?.filter(
          (room) => room?._id === values.room
        );
        payload = { room: finalData[0]?._id };
        break;
      case "Service":
        finalData = servicesData?.result?.data?.data?.filter(
          (service) => service?._id === values.service
        );
        payload = { services: [finalData[0]?._id] };
        break;
      case "Extended Time Allowance":
        function calculateEndTime(startTime, duration) {
          const startDateTime = dayjs(startTime);
          const endDateTime = startDateTime.add(duration, "minute");
          const formattedEndTime = endDateTime?.toISOString();
          return formattedEndTime;
        }

        const endTime = calculateEndTime(
          singleAppointment?.result?.data?.endTime,
          values.endTime
        );
        payload = { endTime };
        break;
      default:
        console.error("Invalid option selected");
        break;
    }

    const response = await updateSingleAppointment({
      id: singleAppointment?.result?.data?._id,
      payload,
    });
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    reset();
    refetchSingleAppointment();
  };

  const calendarResource = transformEventData(
    calendarCurrentView === "Room"
      ? roomData?.result?.data?.data
      : usersData?.result?.data?.data,
    calendarCurrentView
  );

  const updateOptionForm = updateOptionsFormData(
    servicesData?.result?.data?.data,
    usersData?.result?.data?.data,
    roomData?.result?.data?.data,
    changeOption,
    updateValue
  );

  const profilePopOverData = [
    {
      id: "1",
      label: "Mark As No Show",
      icon: XCircle,
      color: "#D13438",
      onClick: () => {
        setProfilePopoverOpen(null);
        setAlertDialogOpen(true);
      },
    },
    {
      id: "2",
      label: "Cancel Appointment",
      icon: CalendarX,
      color: "#D13438",
      onClick: () => {
        setProfilePopoverOpen(null);
        setCancelAppointmentModel(true);
      },
    },
  ];

  const slotPopOverData = [
    {
      id: "1",
      label: "Create Appointment",
      icon: CalendarPlus,
      onClick: () => {
        setSlotPopoverOpen(null);
        setEmptySlotPopoverOpen(null);
        dispatch(
          handleOpenAppointmentModal({
            appointmentType: "calendarSlot",
            type: calendarCurrentView?.toLowerCase(),
            resourceId: selectedSlotData?.resourceId,
            start: dayjs(selectedSlotData?.start).format("hh:mm A"),
            end: dayjs(selectedSlotData?.end).format("hh:mm A"),
            date: selectedSlotData?.start,
          })
        );
      },
    },
    {
      id: "2",
      label: "Create Busy Hours",
      icon: Prohibit,
      onClick: () => {
        setSlotPopoverOpen(null);
        setEmptySlotPopoverOpen(null);
        setBusyTimeModel(true);
      },
    },
    {
      id: "3",
      label: "Create Note",
      icon: Note,
      onClick: () => {
        setSlotPopoverOpen(null);
        setEmptySlotPopoverOpen(null);
        dispatch(
          handleOpenNoteModal({
            noteType: "slot",
            selectedSlotData: selectedSlotData,
            selectedSlotType: calendarCurrentView,
          })
        );
      },
    },
  ];

  const statusDesign = {
    OPEN: {
      icon: <ShootingStar size={16} />,
      color: "#1AA37A",
      bgColor: "#E9FBF6",
    },
    ACTIVE: {
      icon: <ShootingStar size={16} />,
      color: "#1AA37A",
      bgColor: "#E9FBF6",
    },
    VIP: {
      icon: <SketchLogo size={16} />,
      color: "#FA9200",
      bgColor: "#FFF9F0",
    },
    NEW: {
      icon: <LinkBreak size={16} />,
      color: "#4A4C4F",
      bgColor: "#FAFAFA",
    },
    LOST: {
      icon: <LinkBreak size={16} />,
      color: "#4A4C4F",
      bgColor: "#FAFAFA",
    },
    RETURN: {
      icon: <StarHalf size={16} />,
      color: "#115ACB",
      bgColor: "#E7EFFC",
    },
  };

  /**
   * Renders the content for a calendar event with dynamic styling based on event type.
   * It displays event icons, title, description, tag, start time, and duration.
   * @param {Object} info - Contains event details
   */
  const eventContentHandler = (info) => {
    return (
      <CustomWidthTooltip
        title={
          <Box sx={calendarBox.slotDetailBox}>
            <Box sx={calendarBox.slotDetailsStatus}>
              <Typography sx={calendarBox.slotDetailsName}>
                {info.title}
              </Typography>
              {info?.event?.status && (
                <Box
                  sx={{
                    backgroundColor:
                      statusDesign[
                        info?.event?.status ? info?.event?.status : "NEW"
                      ].bgColor,
                    color:
                      statusDesign[
                        info?.event?.status ? info?.event?.status : "NEW"
                      ].color,
                    ...calendarBox.slotDetailsStatusInfo,
                  }}
                >
                  {
                    statusDesign[
                      info?.event?.status ? info?.event?.status : "NEW"
                    ].icon
                  }
                  {info?.event?.status ? info?.event?.status : "NEW"}
                </Box>
              )}
            </Box>
            {info?.event?.room && (
              <Typography sx={calendarBox.eventTag}>
                <Door color="#8F9193" size={12} /> {info?.event?.room}
              </Typography>
            )}
            <Box sx={calendarBox.slotDetailsDuration}>
              {info?.event?.hours && (
                <Box
                  sx={{
                    ...calendarBox.slotDetailsTime,
                    ...calendarBox.dateStart,
                  }}
                >
                  <CallBell color="#EB2F96" size={16} />
                  {dayjs(info?.event?.startTime).format("hh:mm A")}
                  <ArrowRight size={16} />{" "}
                  {dayjs(info?.event?.endTime).format("hh:mm A")}
                </Box>
              )}
              {info?.event?.hours && (
                <Typography sx={calendarBox.dateRange}>
                  ({info?.event?.hours} HOUR)
                </Typography>
              )}
            </Box>
            <Divider sx={{ my: 1 }} />
            {info?.event?.room &&
              singleSlotDetails(info)?.map((item) => {
                const SlotIcon = slotCategory[item?.label]?.icon;
                return (
                  <Box sx={calendarBox.slotDetailsDuration}>
                    <Box sx={calendarBox.slotDetailsLabel}>
                      <SlotIcon size={16} />
                      {item?.label}
                    </Box>
                    <Typography sx={calendarBox.slotDetailsValue}>
                      {item?.value}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        }
        sx={{
          "& .MuiTooltip-tooltip": {
            bgcolor: "#ffffff",
            borderRadius: "16px",
            padding: "32px",
            boxShadow:
              "3px 2px 8px 0px rgba(78, 69, 229, 0.05), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -66px 62px 25px 0px rgba(78, 69, 229, 0.00);",
          },
        }}
      >
        <Box
          sx={{
            background: "#ffffff",
            bgcolor: lightenHexColor(info?.event?.color, 0.9),
            borderLeft: `8px solid ${info?.event?.color}`,
            ...calendarBox.eventHandleBox,
          }}
        >
          <Box>
            {info?.event?.type === "appointment" && (
              <Box sx={calendarBox.slotIconBox}>
                {slotIcon.map((item) => {
                  // Check if the condition for this icon is true in info.event
                  if (info?.event[item.condition]) {
                    const SlotIcon = item.icon;
                    return (
                      <Tooltip key={item.id} title={item.tooltip}>
                        <SlotIcon color={info?.event.color} size={16} />
                      </Tooltip>
                    );
                  }
                  // If the condition is not met, don't render the icon
                  return null;
                })}
              </Box>
            )}
            {info.title && (
              <Typography sx={calendarBox.eventTitle}>{info.title}</Typography>
            )}

            <Typography sx={calendarBox.eventDesc}>
              {info?.event?.service}
            </Typography>
          </Box>
          <Box>
            {info?.event?.room && (
              <Typography sx={calendarBox.eventTag}>
                <Door color="#8F9193" size={12} /> {info?.event?.room}
              </Typography>
            )}
            <Box sx={calendarBox.dateSlot}>
              {info?.event?.hours && (
                <Typography sx={calendarBox.dateStart}>
                  {dayjs(info?.event?.startTime).format("hh:mm A")}
                </Typography>
              )}
              {info?.event?.hours && (
                <Typography sx={calendarBox.dateRange}>
                  ({info?.event?.hours} HOUR)
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </CustomWidthTooltip>
    );
  };

  /**
   * Renders the content for a resource item in a calendar view, displaying its title and content.
   * @param {Object} resource - Object containing the resource details, with a nested `resource` object
   */
  const resourceContentHeader = (resource) => {
    return (
      <Box sx={calendarBox.resourceBox}>
        <Typography sx={calendarBox.resourceContent}>
          {resource?.resource?.resourceContent}
        </Typography>
      </Box>
    );
  };

  const handleSlotClick = (slotInfo) => {
    setSelectedSlotData(slotInfo);
    setEmptySlotPopoverOpen(true);
    const anchorPosition = {
      top: slotInfo?.bounds?.y ?? slotInfo?.box?.clientY,
      left: slotInfo?.bounds?.x ?? slotInfo?.box?.clientX,
    };
    setAnchorPosition(anchorPosition);
    const emptyAnchorPosition = {
      top: (slotInfo?.bounds?.y ?? slotInfo?.box?.clientY) - 37,
      left: (slotInfo?.bounds?.x ?? slotInfo?.box?.clientX) - 78,
    };
    setEmptyAnchorPosition(emptyAnchorPosition);
    setAnchorPosition(anchorPosition);
    setSelectedSlot(slotInfo);
  };

  const timeSlotContentHeader = (info) => {
    return (
      <Box sx={calendarBox.tabGrid}>
        {currentView?.map((item) => {
          const TabIcon = item?.icon;
          return (
            <Box
              key={item?.label}
              onClick={() => dispatch(handleCalendarCurrentView(item?.value))}
              sx={{
                background:
                  calendarCurrentView === item?.value ? secondary.main : "",
                color:
                  calendarCurrentView === item?.value
                    ? customColors.white
                    : secondary.darkest,
                ...calendarBox.gridView,
              }}
            >
              <TabIcon />
            </Box>
          );
        })}
      </Box>
    );
  };

  const appointmentSummary = (info) => {
    let formData = [
      {
        id: 1,
        title: "Client",
        icon: User,
        bgcolor: false,
        component: (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                handleOpenClientProfile();
                dispatch(setClientDetails(info?.client));
              }}
            >
              {info?.client?.profilePictureUrl ? (
                <Avatar
                  sx={{ width: "32px", height: "32px" }}
                  src={info?.client?.profilePictureUrl}
                  variant="rounded"
                />
              ) : (
                <Avatar
                  {...nameInitialsAvatar(
                    `${info?.client?.firstName} ${info?.client?.lastName}`,
                    "32px"
                  )}
                  variant="rounded"
                />
              )}
              <Typography
                sx={{
                  color: "#4E45E5",
                  fontSize: fs14,
                  fontWeight: fw600,
                  width: 100,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {truncateFullName(`${info?.client?.firstName} ${info?.client?.lastName}
          `)}
              </Typography>
            </Box>
          </>
        ),
        status: (
          <Box
            sx={{
              backgroundColor: statusDesign[info?.client?.status]?.bgColor,
              color: statusDesign[info?.client?.status]?.color,
              ...calendarBox.slotDetailsStatusInfo,
            }}
          >
            {statusDesign[info?.client?.status]?.icon}
            {info?.client?.status}
          </Box>
        ),
      },
      {
        id: 2,
        title: "Staff",
        icon: HandHeart,
        bgcolor: true,
        component: (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              {info?.user?.profilePictureUrl ? (
                <Avatar
                  sx={{ width: "32px", height: "32px" }}
                  src={info?.user?.profilePictureUrl}
                  variant="rounded"
                />
              ) : (
                <Avatar
                  {...nameInitialsAvatar(
                    `${info?.user?.firstName} ${info?.user?.lastName}`,
                    "32px"
                  )}
                  variant="rounded"
                />
              )}
              <Typography
                sx={{ color: "#4E45E5", fontSize: fs14, fontWeight: fw600 }}
              >
                {truncateFullName(
                  `${info?.user?.firstName} ${info?.user?.lastName}`
                )}
              </Typography>
            </Box>
          </>
        ),
        status: (
          <PencilSimple
            color="#4E45E5"
            size={16}
            onClick={() => {
              setChangeOption("Staff");
              setUpdateValue(info?.user?.firstName);
              setValue("user", info?.user?._id);
              setChangeOptionModel(true);
            }}
          />
        ),
      },
      {
        id: 3,
        title: "Service",
        icon: CallBell,
        bgcolor: false,
        component: (
          <Box sx={calendarBox.appointmentServiceBox}>
            <Circle
              size={16}
              weight="fill"
              color={info?.services[0]?.category?.color}
            />
            {info?.services[0]?.name}
          </Box>
        ),
        status: (
          <Plus
            onClick={() => {
              setChangeOption("Service");
              setUpdateValue(info?.services[0]?.name);
              setValue("service", info?.services[0]?.name);
              setChangeOptionModel(true);
            }}
            color="#4E45E5"
            size={16}
          />
        ),
      },
      {
        id: 4,
        title: "Room",
        icon: Door,
        bgcolor: true,
        component: (
          <Box sx={calendarBox.appointmentServiceBox}>{info?.room?.name}</Box>
        ),
        status: (
          <PencilSimple
            color="#4E45E5"
            size={16}
            onClick={() => {
              setChangeOption("Room");
              setUpdateValue(info?.room?.name);
              setValue("room", info?.room?.name);
              setChangeOptionModel(true);
            }}
          />
        ),
      },
      {
        id: 5,
        title: "Date",
        icon: CalendarBlank,
        bgcolor: false,
        component: (
          <Box sx={calendarBox.appointmentServiceBox}>
            {dayjs(info?.date).format("MMM DD, YYYY")}
          </Box>
        ),
        status: "",
      },
      {
        id: 6,
        title: "Time",
        icon: Clock,
        bgcolor: true,
        component: (
          <Box sx={calendarBox.appointmentServiceBox}>
            {dayjs(info?.startTime).format("hh:mm A")}
            <ArrowRight color="#8F9193" size={24} />{" "}
            {dayjs(info?.endTime).format("hh:mm A")}
          </Box>
        ),
        status: (
          <PencilSimple
            color="#4E45E5"
            size={16}
            onClick={() => {
              setChangeOption("Extended Time Allowance");
              setUpdateValue(info?.endTime);
              setChangeOptionModel(true);
            }}
          />
        ),
      },
    ];

    return formData;
  };
  const AppointmentIcon = appointmentStatusDesign["Contraindications"].icon;
  const handleCancelAppointmentModel = () => {
    setCancelAppointmentModel(false);
  };

  const handleCreateNoteModel = () => {
    dispatch(handleCloseNoteModal());
  };

  const handleContraModel = () => {
    dispatch(handleCloseEditContraModal());
  };
  const handleConfirmationDrop = (data) => {
    handleEventDrop(dropConfirmationData);
    setDropConfirmationModel(false);
  };

  const handleEventDrop = async (data) => {
    if (data?.event?.type === "busyTime") {
      await updateBusyTime({
        id: data?.event?.id,
        payload: {
          from: {
            date: dayjs(data?.start).toISOString(),
            time: dayjs(data?.start).toISOString(),
          },
          to: {
            date: dayjs(data?.end).toISOString(),
            time: dayjs(data?.end).toISOString(),
          },
          [calendarCurrentView?.toLowerCase() === "staff" ? "user" : "room"]:
            data?.resourceId,
        },
      });
      return;
    }

    await updateSingleAppointment({
      id: data?.event?.id,
      payload: {
        startTime: dayjs(data?.start).toISOString(),
        endTime: dayjs(data?.end).toISOString(),
        [calendarCurrentView?.toLowerCase() === "staff" ? "user" : "room"]:
          data?.resourceId,
      },
    });
  };

  useEffect(() => {
    if (singleAppointment?.result?.data?.date) {
      setCurrentDate(dayjs(singleAppointment?.result?.data?.date));
    } else {
      setCurrentDate(dayjs());
    }
  }, [singleAppointment?.result?.data?.date]);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      ["status=ACTIVE"],
      ["status"],
      "filter"
    );
    const serviceFilterPayload = handleFilterSearch(
      ["clientInteraction=ENABLED"],
      ["status"],
      "filter"
    );
    getAllServices({ ...serviceFilterPayload });
    getAllRooms({ ...searchPayload });
    getAllUsers({ ...searchPayload, roleType: "STAFF" });
  }, []);

  return (
    <>
      {(updateSingleAppointmentLoader ||
        calendarDataLoader ||
        updateBusyTimeLoader ||
        calendarDataLoader ||
        usersDataLoader ||
        roomDataLoader ||
        getAllBusinessLocationLoader) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            updateSingleAppointmentLoader ||
            calendarDataLoader ||
            updateBusyTimeLoader ||
            calendarDataLoader ||
            usersDataLoader ||
            roomDataLoader ||
            getAllBusinessLocationLoader
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box>
        <Box sx={calendarBox.calendarHeader}>
          <Box sx={calendarBox.selectionBox}>
            <Box sx={calendarBox.calendarHeaderMain}>
              <Box sx={calendarBox.calendarBtnView}>
                <PermissionProtected
                  permission={PERMISSION.CREATE_APPOINTMENT}
                  disabled
                >
                  <Button
                    disableElevation
                    variant="contained"
                    sx={calendarBox.appointmentBtn}
                    onClick={() => {
                      dispatch(handleOpenAppointmentModal());
                    }}
                  >
                    <CalendarPlus size={24} color="currentColor" />
                    NEW APPOINTMENT
                  </Button>
                </PermissionProtected>
                <Button
                  disableElevation
                  variant="contained"
                  sx={calendarBox.busyBtn}
                  onClick={() => setBusyTimeModel(true)}
                >
                  <Prohibit size={24} color="currentColor" />
                  SET BUSY TIMES
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              mr: "30px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "95%",
              }}
            >
              <Box sx={calendarBox.dateBox}>
                <Button
                  sx={calendarBox.caretIcon}
                  size="small"
                  variant="outlined"
                  onClick={() => setCurrentDate(currentDate.subtract(7, "day"))}
                >
                  <CaretDoubleLeft color="#4A4C4F" size={16} />
                </Button>
                <Button
                  sx={calendarBox.caretIcon}
                  size="small"
                  variant="outlined"
                  onClick={() => setCurrentDate(currentDate.subtract(1, "day"))}
                >
                  <CaretLeft color="#4A4C4F" size={16} />
                </Button>
                <Typography sx={calendarBox.currentDate}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      size="small"
                      value={currentDate}
                      sx={calendarBox.mobileDatePickerStyle}
                      onChange={(newValue) => setCurrentDate(newValue)}
                      format="dddd DD MMM YYYY"
                    />
                  </LocalizationProvider>
                </Typography>

                <Button
                  sx={calendarBox.caretIcon}
                  size="small"
                  variant="outlined"
                  onClick={() => setCurrentDate(currentDate.add(1, "day"))}
                >
                  <CaretRight color="#4A4C4F" size={16} />
                </Button>
                <Button
                  sx={calendarBox.caretIcon}
                  size="small"
                  variant="outlined"
                  onClick={() => setCurrentDate(currentDate.add(7, "day"))}
                >
                  <CaretDoubleRight color="#4A4C4F" size={16} />
                </Button>
                <Button
                  sx={{
                    width: "fit-content",
                    minWidth: "fit-content",
                    border: "1px solid #F0F0F1",
                    borderRadius: "8px",
                  }}
                  size="small"
                  variant="outlined"
                  onClick={() => setCurrentDate(dayjs())}
                >
                  Today
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                width: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={calendarBox.dateBox}>
                <Button
                  sx={calendarBox.caretIcon}
                  size="small"
                  variant="outlined"
                >
                  <CaretLeft color="#4A4C4F" size={16} />
                </Button>
                <Button
                  sx={calendarBox.caretIcon}
                  size="small"
                  variant="outlined"
                >
                  <CaretRight color="#4A4C4F" size={16} />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        {!calendarDataLoader && !usersDataLoader && !roomDataLoader && (
          <Box sx={calendarBox.calendar}>
            <DnDCalendar
              events={roomsEvents(
                calendarData?.result?.data,
                calendarCurrentView
              )}
              view={Views.DAY}
              localizer={localizer}
              resourceIdAccessor="resourceId"
              resources={calendarResource}
              resourceTitleAccessor="resourceTitle"
              descAccessor="service"
              date={currentDate}
              onSelectEvent={(event) => {
                if (event?.type === "appointment") {
                  setSingleAppointmentId(event?.id);
                  dispatch(handleSingleAppointmentModal());
                }
              }}
              onSelectSlot={handleSlotClick}
              components={{
                event: eventContentHandler,
                toolbar: () => null,
                resourceHeader: resourceContentHeader,
                timeGutterHeader: timeSlotContentHeader,
                timeSlotWrapper: function timeSlotWrapper(
                  timeSlotWrapperProps
                ) {
                  var style = {
                    display:
                      timeSlotWrapperProps.value.getMinutes() === 30
                        ? "none"
                        : "flex",
                  };

                  return (
                    <>
                      {timeSlotWrapperProps.value && (
                        <div style={style}>{timeSlotWrapperProps.children}</div>
                      )}
                    </>
                  );
                },
              }}
              step={30}
              onEventDrop={(event) => {
                setDropConfirmationData(event);
                setDropConfirmationModel(true);
              }}
              timeslots={1}
              selectable={true}
              min={minTime}
              max={maxTime}
              resizable
            />
          </Box>
        )}
        {singleAppointmentModel && (
          <Drawer
            anchor="right"
            transitionDuration={500}
            PaperProps={{
              sx: calendarBox.drawerPaperProps,
            }}
            sx={calendarBox.drawerStyle}
            open={singleAppointmentModel}
            onClose={() => dispatch(handleSingleAppointmentModal())}
          >
            {singleAppointmentLoader && <Loader />}
            {!singleAppointmentLoader && (
              <Box sx={calendarBox.appointmentDrawer}>
                <Box sx={calendarBox.appointmentDrawerHeader}>
                  <Box sx={calendarBox.appointmentDrawerTitle}>
                    <ArrowLineRight
                      onClick={() => dispatch(handleSingleAppointmentModal())}
                      color="#4A4C4F"
                      style={{ cursor: "pointer" }}
                      size={24}
                    />
                    <Box sx={calendarBox.appointmentTitleBox}>
                      <Typography sx={calendarBox.appointmentType}>
                        APPOINTMENT
                      </Typography>
                      <Typography
                        sx={{
                          width: 170,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          ...calendarBox.appointmentClient,
                        }}
                      >
                        {singleAppointment?.result?.data?.client?.firstName}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ ...calendarBox.addSalesBtn }}>
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{
                        ...calendarBox.appointmentBtn,
                        padding: { xl: "12px 8px", xs: "12px 8px" },
                      }}
                      onClick={() => {
                        dispatch(
                          handleOpenSalesModal({
                            clientId:
                              singleAppointment?.result?.data?.client?._id,
                            getAllSales: () => {},
                          })
                        );
                      }}
                    >
                      <CreditCard size={24} color="currentColor" />
                      ADD SALES
                    </Button>
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{
                        cursor: "pointer",
                        ...calendarBox.appointmentBtn,
                        width: "50px",
                      }}
                      onClick={(event) =>
                        setProfilePopoverOpen(event.currentTarget)
                      }
                    >
                      {profileOpenPopOver ? (
                        <CaretUp size={24} color="currentColor" />
                      ) : (
                        <CaretDown size={24} color="currentColor" />
                      )}
                    </Button>
                    <SPAPopover
                      width="230px"
                      id={profilePopOverId}
                      isOpen={profileOpenPopOver}
                      anchorEl={profilePopoverOpen}
                      handleClose={() => {
                        setProfilePopoverOpen(null);
                      }}
                      data={profilePopOverData}
                    />
                  </Box>
                </Box>
                <Divider color="#EDECFC" />
                <Box sx={calendarBox.appointmentCardBox}>
                  {singleAppointment?.result?.data?.client?.contraindication[
                    singleAppointment?.result?.data?.client?.contraindication
                      ?.length - 1
                  ]?.text ? (
                    <Box
                      sx={{
                        backgroundColor:
                          appointmentStatusDesign["Contraindications"].bgColor,
                        ...calendarBox.appointmentCard,
                      }}
                    >
                      <Box sx={calendarBox.appointmentCardHeader}>
                        <Box sx={calendarBox.appointmentCardTitle}>
                          <AppointmentIcon color="#5E6062" size={24} />
                          <Typography sx={calendarBox.appointmentTitle}>
                            Contraindication
                          </Typography>
                        </Box>
                      </Box>
                      <Typography sx={calendarBox.appointmentDesc}>
                        {
                          singleAppointment?.result?.data?.client
                            ?.contraindication[
                            singleAppointment?.result?.data?.client
                              ?.contraindication?.length - 1
                          ]?.text
                        }
                      </Typography>
                      <Box sx={calendarBox.appointmentCardFooter}>
                        <Box sx={calendarBox.appointmentBtnBox}>
                          <Button
                            sx={calendarBox.appointmentEditBtn}
                            onClick={() => {
                              dispatch(
                                handleOpenEditContraModal({
                                  rowData:
                                    singleAppointment?.result?.data?.client,
                                  getUpdatedData: () => {
                                    refetchCalendarData();
                                    refetchSingleAppointment();
                                  },
                                })
                              );
                            }}
                          >
                            <PencilSimple color="#4E45E5" size={16} />
                            Edit
                          </Button>
                        </Box>
                        <Box sx={calendarBox.appointmentBtnBox}>
                          <Typography sx={calendarBox.appointmentDate}>
                            {dayjs(
                              singleAppointment?.result?.data?.client
                                ?.contraindication?.updatedAt
                            ).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        sx={calendarBox.appointmentEditBtn}
                        onClick={() => {
                          dispatch(
                            handleOpenEditContraModal({
                              rowData: singleAppointment?.result?.data?.client,
                              getUpdatedData: () => {
                                refetchCalendarData();
                                refetchSingleAppointment();
                              },
                            })
                          );
                        }}
                      >
                        <Plus color="#4E45E5" size={16} />
                        Add Contraindication
                      </Button>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      sx={calendarBox.appointmentEditBtn}
                      onClick={() =>
                        dispatch(
                          handleOpenNoteModal({
                            noteType: "appointment",
                            rowData: singleAppointment?.result?.data,
                          })
                        )
                      }
                    >
                      <Plus color="#4E45E5" size={16} />
                      Add Note
                    </Button>
                  </Box>
                  {singleAppointment?.result?.data?.notes?.length > 0 &&
                    singleAppointment?.result?.data?.notes
                      ?.filter((item) => item.isPinned)
                      .map((item) => {
                        const AppointmentIcon =
                          appointmentStatusDesign["Pinned Note"]?.icon;
                        return (
                          <Box
                            key={item.id}
                            sx={{
                              backgroundColor:
                                appointmentStatusDesign["Pinned Note"].bgColor,
                              ...calendarBox.appointmentCard,
                            }}
                          >
                            <Box sx={calendarBox.appointmentCardHeader}>
                              <Box sx={calendarBox.appointmentCardTitle}>
                                <AppointmentIcon color="#5E6062" size={24} />
                                <Typography sx={calendarBox.appointmentTitle}>
                                  {"Pinned Note"}
                                </Typography>
                              </Box>
                              {item.enableNotes && (
                                <Box sx={calendarBox.appointmentCardTitle}>
                                  <Button sx={calendarBox.viewNoteBtn}>
                                    View All Notes
                                  </Button>
                                </Box>
                              )}
                            </Box>
                            <Typography sx={calendarBox.appointmentDesc}>
                              {item.note}
                            </Typography>
                            <Box sx={calendarBox.appointmentCardFooter}>
                              <Box sx={calendarBox.appointmentBtnBox}>
                                <Button
                                  sx={calendarBox.appointmentEditBtn}
                                  onClick={() =>
                                    dispatch(
                                      handleOpenNoteModal({
                                        noteType: "appointment",
                                        editType: true,
                                        rowData: item,
                                      })
                                    )
                                  }
                                >
                                  <PencilSimple color="#4E45E5" size={16} />
                                  Edit
                                </Button>
                              </Box>
                              <Box sx={calendarBox.appointmentBtnBox}>
                                <Typography sx={calendarBox.appointmentStatus}>
                                  {"Added"}
                                </Typography>
                                <Typography sx={calendarBox.appointmentDate}>
                                  {dayjs(item.updatedAt).format("MMM DD, YYYY")}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                </Box>
                <Box sx={calendarBox.summaryBox}>
                  <Typography sx={calendarBox.appointmentSummary}>
                    Summary
                  </Typography>
                  {appointmentSummary(singleAppointment?.result?.data).map(
                    (item) => {
                      return (
                        <Box
                          key={item.id}
                          sx={{
                            backgroundColor: item.bgcolor && "#F8F7FE",
                            ...calendarBox.appointmentSummaryBox,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "48px",
                              justifyContent: {
                                md: "flex-start",
                                xs: "space-between",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                width: { xs: "92px" },
                                ...calendarBox.summaryLabel,
                              }}
                            >
                              <item.icon size={16} color="#4A4C4F" />
                              {item.title}
                            </Box>
                            <Box>{item.component}</Box>
                          </Box>
                          <Box sx={calendarBox.appointmentStatusLabel}>
                            {item.status}
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
                <Box sx={calendarBox.appointmentPricingHeader}>
                  <Box sx={calendarBox.appointmentPriceBox}>
                    <Typography sx={calendarBox.priceDetail}>
                      Price Details
                    </Typography>
                    <Box sx={calendarBox.pricingDetailBox}>
                      {appointmentPricingDetail(
                        singleAppointment?.result?.data
                      )?.map((item) => {
                        return (
                          <Box key={item.id} sx={calendarBox.priceLabel}>
                            <Typography sx={{ color: "#8F9193" }}>
                              {item.label}
                            </Typography>
                            <Typography sx={{ color: "#5E6062" }}>
                              {item.value}
                            </Typography>
                          </Box>
                        );
                      })}
                      <Divider color="#DADADB" sx={{ my: "4px" }} />
                      <Box sx={calendarBox.priceTotal}>
                        <Typography sx={{ fontWeight: 600 }}>Total</Typography>
                        <Typography sx={{ fontWeight: 600 }}>
                          $
                          {singleAppointment?.result?.data?.services[0]?.price +
                            (singleAppointment?.result?.data?.services[0]
                              ?.discount ?? 0)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Drawer>
        )}
        {slotOpenPopOver && (
          <SPAPopover
            width="230px"
            id={slotPopOverId}
            isOpen={slotOpenPopOver}
            anchorEl={slotPopoverOpen}
            handleClose={() => {
              setSlotPopoverOpen(null);
            }}
            anchorPosition={anchorPosition}
            anchorReference="anchorPosition"
            data={slotPopOverData}
          />
        )}

        {emptySlotOpenPopOver && (
          <Popover
            key={emptySlotPopOverId}
            open={emptySlotOpenPopOver}
            anchorEl={emptySlotPopoverOpen}
            onClose={() => {
              setEmptySlotPopoverOpen(null);
            }}
            anchorPosition={emptyAnchorPosition}
            anchorReference="anchorPosition"
            disableScrollLock
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{ sx: { ...styles.popOverStyle("230px") } }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ fontSize: "10px", fontWeight: "400", color: "#5E6062" }}
              >
                Free
              </Typography>
              <Typography
                sx={{ fontSize: "10px", fontWeight: "400", color: "#8F9193" }}
              >
                {dayjs(selectedSlotData?.start).format("hh:mm A")} -{" "}
                {dayjs(selectedSlotData?.end).format("hh:mm A")}{" "}
                {timeDifference(selectedSlotData?.start, selectedSlotData?.end)}
              </Typography>
              <Button
                variant="text"
                sx={{ textTransform: "capitalize", p: 0 }}
                onClick={() => setSlotPopoverOpen(true)}
              >
                <Plus size={16} style={{ marginRight: "4px" }} /> Add
              </Button>
            </Box>
          </Popover>
        )}
        {busyTimeModel && (
          <CreateBusyTimesModel
            busyTimeModel={busyTimeModel}
            handleClose={() => {
              setBusyTimeModel(false);
              setSelectedSlotData(null);
            }}
            setSuccessCreateModel={setSuccessCreateModel}
            selectedSlotData={selectedSlotData}
            selectedSlotType={calendarCurrentView}
          />
        )}
        {successCreateModel && (
          <SPAAlertDialog
            open={successCreateModel}
            handleClose={() => setSuccessCreateModel(false)}
            title={"Busy Times Successfully Created"}
            contentText={`Your busy times were successfully created, you can now view it in the calendar page.`}
            disagreeText={`Create New busy time`}
            agreeText={`Close`}
            handleNewTab={() => {
              setSuccessCreateModel(false);
              setBusyTimeModel(true);
            }}
          />
        )}
        {dropConfirmationModel && (
          <SPAAlertDialog
            open={dropConfirmationModel}
            handleClose={() => setDropConfirmationModel(false)}
            title={"Update Confirmation"}
            contentText={`Are you sure you want to change this?`}
            disagreeText={`Confirm`}
            agreeText={`Close`}
            handleNewTab={() => {
              handleConfirmationDrop();
            }}
          />
        )}
        <CancelAppointmentModel
          cancelAppointmentModel={cancelAppointmentModel}
          handleCancelAppointmentModel={handleCancelAppointmentModel}
          rowData={singleAppointment?.result?.data}
        />
        {openNoteModal && (
          <CreateNoteModel handleCreateNoteModel={handleCreateNoteModel} />
        )}
        {openEditContraindicationModal && (
          <EditContraindicationModel handleContraModel={handleContraModel} />
        )}
        {openAppointmentModal && (
          <NewAppointmentForm
            getAllClients={() => {}}
            isAppointmentOpen={openAppointmentModal}
            handleClose={() => {
              dispatch(handleCloseAppointmentModal());
              refetchCalendarData();
            }}
          />
        )}

        {openAppointmentAlert && (
          <SPAAlertDialog
            open={openAppointmentAlert}
            handleClose={() => dispatch(handleCloseCreateAppointmentAlert())}
            title={"New Appointment Created"}
            contentText={`New appointment for ${createdClientDetail?.clientName} was created. For the date ${createdClientDetail?.date}`}
            disagreeText={`Create another appointment`}
            agreeText={`Close`}
            handleNewTab={() => {
              dispatch(handleOpenAppointmentModal());
              dispatch(handleCloseCreateAppointmentAlert());
            }}
          />
        )}
        {/* sale model  */}
        <SPADialogue
          open={addSalesModel}
          handleClose={() => setAddSalesModel(false)}
          dialogTitle={`New Sale for Jane Doe`}
          cancelText="Back"
          submitText="Process Payment"
          handleSubmit={handleSubmit}
          onSubmit={handleSaleModelSubmit}
          methods={methods}
        >
          <SaleModelContent showCart={showCart} />
        </SPADialogue>
        {/* complete order dialog */}
        <SPADialogue
          open={showCompleteOrder}
          handleClose={() => setAddSalesModel(false)}
          dialogTitle={`New Sale for Jane Doe`}
          cancelText="Create New Sale"
          submitText="Close"
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          methods={methods}
        >
          <Box
            sx={{
              maxWidth: "1089px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                maxWidth: "1089px",
                width: "100%",
                padding: "16px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
                borderRadius: "16px",
                background: "#F8F7FE",
              }}
            >
              <Box>
                <img src="/images/cards.png" width="100%" alt="card" />
              </Box>
              <Typography
                sx={{
                  color: "#5E6062",
                  fontFamily: '"Open Sans"',
                  fontSize: "34px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "0.085px",
                }}
              >
                Purchase Complete
              </Typography>
              <Typography
                sx={{
                  color: "#5E6062",
                  fontFamily: '"Open Sans"',
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "0.03px",
                }}
              >
                Your sale has been successfully processed.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: "16px",
                }}
              >
                <Button
                  sx={{
                    display: "flex",
                    height: "48px",
                    padding: "0px 24px",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "8px",
                    background: "#EDECFC",
                    color: "#4E45E5",
                  }}
                >
                  <Printer size={24} color="#4E45E5" />
                  <Typography
                    sx={{
                      color: "#4E45E5",
                      fontFamily: '"Open Sans"',
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      letterSpacing: "0.175px",
                      textTransform: "uppercase",
                    }}
                  >
                    Print receipt{" "}
                  </Typography>
                </Button>
                <Button
                  onClick={() => setShowEmailInput(true)}
                  sx={{
                    display: "flex",
                    height: "48px",
                    padding: { xs: "0px 10px", sm: "0px 24px" },
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "8px",
                    background: "#EDECFC",
                  }}
                >
                  <Envelope size={24} color="#4E45E5" />
                  <Typography
                    sx={{
                      color: "#4E45E5",
                      fontFamily: '"Open Sans"',
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      letterSpacing: "0.175px",
                      textTransform: "uppercase",
                    }}
                  >
                    Email receipt
                  </Typography>
                </Button>
              </Box>
              {showEmailInput && (
                <Box sx={{ display: "flex", gap: "16px" }}>
                  <Box
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid  #DADADB",
                      background: "#FFF",
                      maxWidth: "316px",
                      width: "100%",
                      padding: "5px 12px",
                    }}
                  >
                    <Input
                      placeholder="Email"
                      sx={{ border: "none" }}
                      disableUnderline
                      startAdornment={
                        <InputAdornment position="start">
                          <Envelope size={"24px"} color="#C1C2C3" />
                        </InputAdornment>
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "48px",
                      height: "48px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                      background: "#4E45E5",
                    }}
                  >
                    <PaperPlaneTilt size={24} color="#fff" />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </SPADialogue>

        <SPAAlertDialog
          open={isAlertDialogOpen}
          type="normal"
          handleClose={() => setAlertDialogOpen(false)}
          handleNewTab={async () => {
            await updateSingleAppointment({
              id: singleAppointment?.result?.data?._id,
              payload: { status: "NO_SHOW" },
            });
            setSuccessNoShowModel(true);
            setAlertDialogOpen(false);
            reset();
          }}
          title={`Mark ${singleAppointment?.result?.data?.client?.firstName} As No Show`}
          contentText={`For the appointment ${
            singleAppointment?.result?.data?.services[0]?.name
          } at ${dayjs(singleAppointment?.result?.data?.startTime).format(
            "hh:mm A"
          )}. Marking ${
            singleAppointment?.result?.data?.client?.firstName
          } as no show will appear in their profile.`}
          disagreeText={`Mark as no show`}
          agreeText={`Close`}
        />
        <SPAAlertDialog
          open={successNoShowModel}
          handleNewTab={() => {
            setSuccessNoShowModel(false);
          }}
          handleClose={() => setSuccessNoShowModel(false)}
          title={`${singleAppointment?.result?.data?.client?.firstName} Marked As No Show`}
          contentText={`Successfully marked as no show for the appointment ${
            singleAppointment?.result?.data?.services[0]?.name
          } at ${dayjs(singleAppointment?.result?.data?.startTime).format(
            "hh:mm A"
          )}.`}
          disagreeText={`Close`}
        />

        <SPADialogue
          open={changeOptionModel}
          handleClose={() => setChangeOptionModel(false)}
          dialogTitle={`${
            changeOption === "Service"
              ? `Add ${changeOption}`
              : changeOption === "Staff"
              ? `Change ${changeOption}`
              : changeOption === "Room"
              ? `Change ${changeOption}`
              : `Add ${changeOption}`
          }`}
          cancelText="Cancel"
          submitText={`${
            changeOption === "Service"
              ? `Add ${changeOption}`
              : changeOption === "Staff"
              ? `Change ${changeOption}`
              : changeOption === "Room"
              ? `Change ${changeOption}`
              : `Save Changes`
          }`}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          methods={methods}
        >
          <Box
            sx={{
              width: "514px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={4}>
              {updateOptionForm.map((item, index) => {
                return (
                  <Grid item lg={item.gridLength} xs={12} key={index}>
                    <item.component {...item.otherOptions} control={control} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </SPADialogue>
      </Box>

      {isClientProfileModalOpen && (
        <Profile
          isClientProfileOpen={isClientProfileModalOpen}
          handleClose={handleCloseClientProfile}
        />
      )}
    </>
  );
};

export default CalendarSection;
