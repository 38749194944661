import SPAInputField from "../../../hook-form/SPAInputField";
import SPASelectField from "../../../hook-form/SPASelectField";

export const articleElements = () => {
  return [
    {
      gridLength: 12,
      otherOptions: {
        name: "title",
        label: "Article Title",
        placeholder: `Enter the article title here`,
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "template",
        label: "Template",
        labelHelperText: "(Optional)",
        options: [{ label: "Template 1", value: "Template 1" }],
        placeholder: `Select Template`,
      },
      component: SPASelectField,
    },
  ];
};

export const ProductView = (currentValues) => {
  return `<div style="display: flex; align-items: center; gap: 8px; flex-direction: column;">
  <div style="display: flex; width: 100%; padding-right: 8px; align-items: center; gap: 8px; background-color: #FCF5F3; justify-content: space-between; overflow: hidden;">
    <div style="display: flex; gap: 8px; align-items: center; background-color: #FCF5F3; flex-grow: 1;">
      <div style="max-width: 85px; overflow: hidden; height: 90px; flex-shrink: 0;">
        <img
          src="${
            currentValues?.imageUrl ??
            "https://qa-temple-day-spa-s3.s3.ap-southeast-2.amazonaws.com/e69da1af-ceff-49d5-b780-20bcd96e607d-images.jpg"
          }"
          alt="product"
          style="height: 100%; object-fit: cover;"
        />
      </div>
      <div>
        <div style="padding: 2px 4px; background: #070A0E; color: #FAFAFA; font-size: 10px; text-transform: uppercase; max-width: fit-content; font-weight: 500;">
          ${currentValues?.productType?.name}
        </div>
        <div style="color: #5E6062; font-size: 14px; font-weight: 600; letter-spacing: 0.014px;">
        ${currentValues?.name}
        </div>
        <div style="color: #5E6062; font-size: 12px; letter-spacing: 0.048px; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3; overflow: hidden; width: 200px;">
        ${currentValues?.description}
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; height: 100%; gap: 1.5rem;">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Heart">
        <path id="Vector" d="M17.1875 3.75C15.2516 3.75 13.5566 4.5825 12.5 5.98969C11.4434 4.5825 9.74844 3.75 7.8125 3.75C6.27146 3.75174 4.79404 4.36468 3.70436 5.45436C2.61468 6.54404 2.00174 8.02146 2 9.5625C2 16.125 11.7303 21.4369 12.1447 21.6562C12.2539 21.715 12.376 21.7458 12.5 21.7458C12.624 21.7458 12.7461 21.715 12.8553 21.6562C13.2697 21.4369 23 16.125 23 9.5625C22.9983 8.02146 22.3853 6.54404 21.2956 5.45436C20.206 4.36468 18.7285 3.75174 17.1875 3.75ZM12.5 20.1375C10.7881 19.14 3.5 14.5959 3.5 9.5625C3.50149 8.41921 3.95632 7.32317 4.76475 6.51475C5.57317 5.70632 6.66921 5.25149 7.8125 5.25C9.63594 5.25 11.1669 6.22125 11.8062 7.78125C11.8628 7.91881 11.9589 8.03646 12.0824 8.11926C12.2059 8.20207 12.3513 8.24627 12.5 8.24627C12.6487 8.24627 12.7941 8.20207 12.9176 8.11926C13.0411 8.03646 13.1372 7.91881 13.1937 7.78125C13.8331 6.21844 15.3641 5.25 17.1875 5.25C18.3308 5.25149 19.4268 5.70632 20.2353 6.51475C21.0437 7.32317 21.4985 8.41921 21.5 9.5625C21.5 14.5884 14.21 19.1391 12.5 20.1375Z" fill="#5E6062"/>
      </g>
    </svg>
      <div style="color: #5E6062; font-size: 14px; font-weight: 600; text-transform: uppercase;">
        $${currentValues?.price}
      </div>
    </div>
  </div>
</div>`;
};

export const TreatmentView = (currentValues) => {
  return `<div style="display: flex; align-items: center; gap: 8px; flex-direction: column;">
  <div style="display: flex; width: 100%; padding-right: 8px; align-items: center; gap: 8px; background-color: #FCF5F3; justify-content: space-between; overflow: hidden;">
    <div style="display: flex; gap: 8px; align-items: center; background-color: #FCF5F3; flex-grow: 1;">
      <div style="max-width: 85px; overflow: hidden; height: 90px; flex-shrink: 0;">
        <img
          src="${
            currentValues?.imageUrl ??
            "https://qa-temple-day-spa-s3.s3.ap-southeast-2.amazonaws.com/e69da1af-ceff-49d5-b780-20bcd96e607d-images.jpg"
          }"
          alt="treatment"
          style="height: 100%; object-fit: cover;"
        />
      </div>
      <div>
        <div style="padding: 2px 4px; background: #070A0E; color: #FAFAFA; font-size: 10px; text-transform: uppercase; max-width: fit-content; font-weight: 500;">
          ${currentValues?.category?.name}
        </div>
        <div style="color: #5E6062; font-size: 14px; font-weight: 600; letter-spacing: 0.014px;">
        ${currentValues?.name}
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; height: 100%; gap: 1.5rem;">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Heart">
        <path id="Vector" d="M17.1875 3.75C15.2516 3.75 13.5566 4.5825 12.5 5.98969C11.4434 4.5825 9.74844 3.75 7.8125 3.75C6.27146 3.75174 4.79404 4.36468 3.70436 5.45436C2.61468 6.54404 2.00174 8.02146 2 9.5625C2 16.125 11.7303 21.4369 12.1447 21.6562C12.2539 21.715 12.376 21.7458 12.5 21.7458C12.624 21.7458 12.7461 21.715 12.8553 21.6562C13.2697 21.4369 23 16.125 23 9.5625C22.9983 8.02146 22.3853 6.54404 21.2956 5.45436C20.206 4.36468 18.7285 3.75174 17.1875 3.75ZM12.5 20.1375C10.7881 19.14 3.5 14.5959 3.5 9.5625C3.50149 8.41921 3.95632 7.32317 4.76475 6.51475C5.57317 5.70632 6.66921 5.25149 7.8125 5.25C9.63594 5.25 11.1669 6.22125 11.8062 7.78125C11.8628 7.91881 11.9589 8.03646 12.0824 8.11926C12.2059 8.20207 12.3513 8.24627 12.5 8.24627C12.6487 8.24627 12.7941 8.20207 12.9176 8.11926C13.0411 8.03646 13.1372 7.91881 13.1937 7.78125C13.8331 6.21844 15.3641 5.25 17.1875 5.25C18.3308 5.25149 19.4268 5.70632 20.2353 6.51475C21.0437 7.32317 21.4985 8.41921 21.5 9.5625C21.5 14.5884 14.21 19.1391 12.5 20.1375Z" fill="#5E6062"/>
      </g>
    </svg>
      <div style="color: #5E6062; font-size: 14px; font-weight: 600; text-transform: uppercase;">
        $${currentValues?.price}
      </div>
    </div>
  </div>
</div>`;
};

export const ButtonView = (text, link) => {
  return ` <a
      href="${link}"
      style="color: #FFFFFF; background-color: #AE9671; padding: 16px; border: none; font-size: 14px; font-weight: 500; cursor: pointer; text-decoration: none; display: inline-block;"
    >
      ${text}
    </a>`;
};

export const ImageView = (link, altIndex) => {
  return `<div style="flex-shrink: 0; display: flex; align-items: center; justify-content:center">
  <img
    src="${link}"
    alt="${altIndex}"
    style="height: 300px; width:80%; object-fit: contain;"
  />
</div>`;
};

export const VideoView = (link, altIndex) => {
  return `<div style="flex-shrink: 0; display: flex; align-items: center; justify-content: center">
  <video
    src="${link}"
    alt="${altIndex}"
    controls
    loop
    autoPlay
    style="height: 300px; width: 80%; object-fit: contain;"
  >
    Your browser does not support the video tag.
  </video>
</div>`;
};

export const TitleView = (title) => {
  return `
  <div>
  <span style="font-size: 34px; color: #4A4C4F; letter-spacing: 0.085px;">${title}</span>
  </div>
  `;
};

export const ParagraphView = (title) => {
  return `
  <div>
  <span style="font-size: 14px; color: #25272B; letter-spacing: 0.035px;">${title}</span>
  </div>
  `;
};

export const truncateText = (text, limit) => {
  const words = text.split(" ");
  if (words?.length > limit) {
    return words?.slice(0, limit)?.join(" ") + "...";
  } else {
    return text;
  }
};
