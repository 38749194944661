import { baseAPI } from "../baseApi";

export const StaffApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllStaffs: builder.query({
      query: () => ({ url: "/user" }),
      providesTags: ["AllUsers"],
    }),
    getStaffs: builder.mutation({
      query: (body) => ({ url: "/user", method: "PATCH", body }),
      providesTags: ["Users"],
    }),
    createStaff: builder.mutation({
      query: (payload) => ({
        url: "/user",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Users"],
    }),
    getSingleStaff: builder.query({
      query: (staffId) => ({ url: "/user/" + staffId }),
      providesTags: ["singleUser"],
    }),
    updateSingleStaff: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/user/" + id,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["Users", "singleUser"],
    }),

    updateSingleStaffAddress: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/user/" + id,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Users", "singleUser"],
    }),
    deleteEmergencyContact: builder.mutation({
      query: ({ staffId, emergencyContactId }) => ({
        url: `/user/${staffId}/emergency-contact/${emergencyContactId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users", "singleUser"],
    }),
    updateEmergencyContact: builder.mutation({
      query: ({ userId, payload }) => ({
        url: `/user/update-emergency-contact/${userId}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Users", "singleUser"],
    }),
    resetPassword: builder.mutation({
      query: ({ payload }) => ({
        url: "/auth/reset-password",
        method: "PATCH",
        body: payload,
      }),
      // invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  useUpdateEmergencyContactMutation,
  useResetPasswordMutation,
  useGetAllStaffsQuery,
  useGetSingleStaffQuery,
  useGetStaffsMutation,
  useCreateStaffMutation,
  useUpdateSingleStaffMutation,
  useUpdateSingleStaffAddressMutation,
  useDeleteEmergencyContactMutation,
} = StaffApi;
