import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { Archive, Envelope, PaperPlaneTilt } from "@phosphor-icons/react";
import React from "react";
import EnvelopImg from "../../../../assets/svg/envelop-img";
import { customColors } from "../../../../theme";
import FormProvider from "../../../hook-form/FormProvider";
import SPACheckBox from "../../../hook-form/SPACheckBox";
import TemplateNameForm from "../template-form";
import { emailFormData } from "./email-form-data";
import { SubmitBtn } from "./emailStyle";
import useEmail from "./useEmail";

const Email = ({ clientDetail }) => {
  const {
    control,
    handleSubmit,
    onSubmit,
    methods,
    sendEmailLoader,
    isSuccessMsg,
    setIsSuccessMsg,
    isTemplateField,
    setIsTemplateField,
    handleTemplateForm,
    createTemplateLoader,
    templatesData,
    singleTemplateLoader,
    templatesDataLoader,
  } = useEmail({ clientDetail });
  return (
    <Box
      sx={{
        bgcolor: "#F8F7FE",
        m: "24px",
        p: "24px",
        borderRadius: "16px",
        height: "93%",
      }}
    >
      {(sendEmailLoader || singleTemplateLoader || templatesDataLoader) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={sendEmailLoader || singleTemplateLoader || templatesDataLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isSuccessMsg && (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            {emailFormData(templatesData?.result?.data).map((item, index) => {
              return (
                <Grid item lg={item.gridLength} xs={12} key={index}>
                  <item.component {...item.otherOptions} control={control} />
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              p: "24px 0px 12px 0px",
            }}
          >
            <Box>
              <SPACheckBox
                name="saveEmail"
                label=" Save client’s email for future communication"
                control={control}
              />
            </Box>
            <Button disabled={sendEmailLoader} type="submit" sx={SubmitBtn}>
              {sendEmailLoader ? (
                <Box sx={{ px: 4 }}>
                  <CircularProgress
                    sx={{ color: customColors.white }}
                    size={24}
                  />
                </Box>
              ) : (
                <>
                  <PaperPlaneTilt size={24} />
                  Send Email
                </>
              )}
            </Button>
          </Box>
        </FormProvider>
      )}
      {isSuccessMsg && (
        <Box
          sx={{
            display: "flex",
            maxWidth: "1089px",
            width: "100%",
            padding: "16px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            borderRadius: "16px",
            background: "#F8F7FE",
          }}
        >
          <Box sx={{ pt: 4 }}>
            <EnvelopImg />
          </Box>
          <Typography
            sx={{
              color: "#5E6062",
              fontFamily: '"Open Sans"',
              fontSize: "34px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "0.085px",
            }}
          >
            Email Sent
          </Typography>
          <Typography
            sx={{
              color: "#5E6062",
              fontFamily: '"Open Sans"',
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "0.03px",
            }}
          >
            Your email has been successfully sent.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "16px",
            }}
          >
            <Button
              onClick={() => setIsSuccessMsg(false)}
              sx={{
                display: "flex",
                height: "48px",
                padding: "0px 24px",
                alignItems: "center",
                gap: "8px",
                borderRadius: "8px",
                background: "#EDECFC",
                color: "#4E45E5",
              }}
            >
              <Envelope size={24} color="#4E45E5" />
              <Typography
                sx={{
                  color: "#4E45E5",
                  fontSize: "14px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                Create another email{" "}
              </Typography>
            </Button>
            <Button
              sx={{
                display: "flex",
                height: "48px",
                padding: { xs: "0px 10px", sm: "0px 24px" },
                alignItems: "center",
                gap: "8px",
                borderRadius: "8px",
                background: isTemplateField ? "#A7A2F2" : "#EDECFC",
                "&:hover": {
                  backgroundColor: isTemplateField ? "#A7A2F2" : "#EDECFC",
                },
              }}
              onClick={() => setIsTemplateField(true)}
            >
              <Archive size={24} color="#4E45E5" />
              <Typography
                sx={{
                  color: isTemplateField ? "#2F2989" : "#4E45E5",
                  fontSize: "14px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                Save as Template
              </Typography>
            </Button>
          </Box>
          {isTemplateField && (
            <TemplateNameForm
              createTemplateLoader={createTemplateLoader}
              onSaveTemplate={(templateName) => {
                handleTemplateForm(templateName);
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
export default Email;
