import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import React, { useState } from "react";
import { customColors, fs24 } from "../../../../theme";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import Appointment from "./components/appointment";
import ClientProfile from "./components/client-profile";
import SideBar from "./components/side-bar";
import BusyTime from "./components/busy-time";
import { useGetSingleStaffQuery } from "../../../../services/staff";
import { truncateText } from "../../../../utils/truncateText";

const Profile = ({ isStaffProfileOpen, handleClose }) => {
  const truncateFullName = truncateText();

  const [activeTabSideBar, setActiveTabSideBar] = useState("profile");
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("md"));

  const { clientDetail } = useSelector((state) => state.clientDetail);

  const {
    data: singleStaffData,
    isLoading: singleStaffDataLoader,
    refetch: singleStaffRefetching,
  } = useGetSingleStaffQuery(clientDetail._id, {
    skip: !clientDetail._id,
  });
  const renderTabContent = (tab) => {
    switch (tab) {
      case "profile":
        return (
          <ClientProfile
            isResponsive={isResponsive}
            singleStaffData={singleStaffData}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      // case "appointment":
      //   return <Appointment clientDetail={clientDetail} />;
      // case "busy":
      //   return <BusyTime clientDetail={clientDetail} />;
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <Dialog
      open={isStaffProfileOpen}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // maxWidth="lg"
      PaperProps={{
        sx: {
          maxWidth: "1232px",
          overflowY: "hidden",
          minWidth: { xs: "auto", lg: "1232px" },
          height: "90vh",
          borderRadius: "16px",
        },
      }}
    >
      {singleStaffDataLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={singleStaffDataLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {/* <ClientModal> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: customColors.darkestBlue,
          p: "24px",
        }}
      >
        <Typography sx={{ fontSize: fs24, color: customColors.white }}>
          {truncateFullName(`${clientDetail?.firstName} ${clientDetail?.lastName}
          `)}
        </Typography>
        <X
          size={24}
          style={{ cursor: "pointer" }}
          color={customColors.white}
          onClick={handleClose}
        />
      </Box>
      {/* side bar and other panel */}
      <Box sx={{ overflowY: "auto", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <SideBar
            setActiveItem={setActiveTabSideBar}
            activeItem={activeTabSideBar}
            isResponsive={isResponsive}
          />
          <Box sx={{ width: "100%" }}>{renderTabContent(activeTabSideBar)}</Box>
        </Box>
      </Box>
    </Dialog>
  );
};
export default Profile;
