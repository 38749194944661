import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Plus, UserPlus } from "@phosphor-icons/react";
import React from "react";
import NoClients from "../../../assets/svg/no-clients";
import { fs14, fs20, secondary } from "../../../theme";
import SPAPopover from "../../common/spa-popover";
import SPATable from "../../common/spa-table";
import NewServiceCategoryForm from "./new-service-category";
import { serviceCategoryPopOverData } from "./service-category-data";
import useServiceCategory from "./useServiceCategory";
/**
 * ServiceCategorySection component.
 *
 * @returns {JSX.Element} JSX code for rendering the ServiceCategorySection component.
 */
const ServiceCategorySection = () => {
  const {
    singleServiceCategoryId,
    setSingleServiceCategoryId,
    serviceCategoryTableHeader,
    isServiceCategoryDialogOpen,
    setIsServiceCategoryDialogOpen,
    serviceCategoryPopoverOpen,
    setServiceCategoryPopoverOpen,
    serviceCategoryPopOverId,
    openServiceCategoryPopOver,
    handleNewServiceCategory,
    serviceCategoryData,
    serviceCategoryDataLoader,
    currentPage,
    setCurrentPage,
    getAllServiceCategory,
  } = useServiceCategory();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            disableElevation
            variant="contained"
            sx={{
              background: secondary.main,
              display: "flex",
              gap: "8px",
              alignItems: "center",
              borderRadius: "8px",
              height: "48px",
              "&:hover": { background: secondary.main },
            }}
            onClick={handleNewServiceCategory}
          >
            <Plus size={24} color="currentColor" />
            Create Service Category
          </Button>
        </Box>
      </Box>
      <Box sx={{ pt: "16px" }}>
        {serviceCategoryDataLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={serviceCategoryDataLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {serviceCategoryData?.result?.data?.data?.length > 0 &&
          !serviceCategoryDataLoader && (
            <SPATable
              data={serviceCategoryData?.result?.data?.data}
              columns={serviceCategoryTableHeader}
              totalRecords={serviceCategoryData?.result?.data?.totalRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        {serviceCategoryData?.result?.data?.data?.length === 0 &&
          !serviceCategoryDataLoader && (
            <Box
              sx={{
                mt: "18px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <NoClients />
              <Typography sx={{ fontSize: fs20, color: "#595959" }}>
                There’s no service Category
              </Typography>
              <Typography sx={{ fontSize: fs14, color: "#595959" }}>
                Create a service Category by clicking on the button 'Create
                Service Category'
              </Typography>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleNewServiceCategory}
              >
                <UserPlus size={24} color="currentColor" />
                Create Service Category
              </Button>
            </Box>
          )}
      </Box>

      {isServiceCategoryDialogOpen && (
        <NewServiceCategoryForm
          singleServiceCategoryId={singleServiceCategoryId}
          isNewServiceCategoryOpen={isServiceCategoryDialogOpen}
          handleClose={() => {
            setIsServiceCategoryDialogOpen(false);
            setSingleServiceCategoryId(null);
            getAllServiceCategory();
          }}
        />
      )}
      {openServiceCategoryPopOver && (
        <SPAPopover
          id={serviceCategoryPopOverId}
          anchorEl={serviceCategoryPopoverOpen}
          width="215px"
          isOpen={openServiceCategoryPopOver}
          transformHorizontal="right"
          handleClose={() => {
            setServiceCategoryPopoverOpen(null);
          }}
          data={serviceCategoryPopOverData(
            setServiceCategoryPopoverOpen,
            setIsServiceCategoryDialogOpen
          )}
        />
      )}
    </>
  );
};

export default ServiceCategorySection;
