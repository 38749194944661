import { CalendarPlus, CalendarX, User } from "@phosphor-icons/react";

export const profileData = {
  label: "Profile",
  icon: User,
  link: "profile",
};

export const sidebarData = [
  {
    label: "Profile",
    icon: User,
    link: "profile",
  },
  // {
  //   label: "Busy Time",
  //   icon: CalendarX,
  //   link: "busy",
  // },
  // {
  //   label: "Appointment",
  //   icon: CalendarPlus,
  //   link: "appointment",
  // },
];
