import * as Yup from "yup";

export const validationAddressSchema = Yup.object().shape({
  street: Yup.string().required("Street is required"),
  suburb: Yup.string().required("Suburb is required"),
  state: Yup.string().required("State is required"),
  postalCode: Yup.number()
    .typeError("Post code must be a number")
    .required("Post code is required"),
});

export const productsValidationSchema = () => {
  let schema;
  schema = Yup.object().shape({
    products: Yup.array()
      .of(
        Yup.object().shape({
          product: Yup.string().required("Product name is required"),
          quantity: Yup.number()
            .typeError("Quantity must be a number")
            .required("Quantity is required")
            .min(1, "Quantity must be at least 1"),
        })
      )
      .min(1, "At least one Product must be selected")
      .required("Selection of Product is required."),
  });
  return schema;
};

export const qualificationValidationSchema = () => {
  let schema = Yup.object().shape({
    qualification: Yup.array()
      .of(Yup.string())
      .min(1, "At least one Qualification must be selected")
      .required("Selection of  Qualification is required."),
  });

  return schema;
};
