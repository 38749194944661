import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { CallBell } from "@phosphor-icons/react";
import React from "react";
import { customColors, fs10, fs16, fw600 } from "../../../../../../../../theme";
import theme from "../../../../../../../../theme/theme";
import SPAAlertDialog from "../../../../../../../common/spa-alert-dialogue";
import FormProvider from "../../../../../../../hook-form/FormProvider";
import ProfileImageUploader from "../../../../../../../profile-image-upload";
import BufferTime from "../../../../../buffer-time";
import {
  DisabledButton,
  EnabledButton,
} from "../../../../../new-service/new-services-style";
import { SubmitBtn } from "../../../../style";
import { OverviewElements } from "../../treatment-profile-data";
import useServiceOverview from "./useServiceOverview";
import { treatmentGeneralInformation } from "../../../../../services-data";

export default function Overview({
  singleServiceData,
  singleServiceRefetching,
}) {
  const {
    control,
    handleSubmit,
    onSubmit,
    methods,
    navigate,
    dispatch,
    serviceCategoryData,
    serviceCategoryDataLoader,
    toggleSwitch,
    enabled,
    handleDisableConfirm,
    setIsOpenDisableConfirmDialog,
    isOpenDisableConfirmDialog,
    isOpenNotDisableDialog,
    setIsOpenNotDisableConfirmDialog,
  } = useServiceOverview({ singleServiceData, singleServiceRefetching });

  return (
    <>
      <Grid container gap={3}>
        {serviceCategoryDataLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 3 }}
            open={serviceCategoryDataLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          xs={12}
          md={6}
          style={{ padding: "16px", borderRadius: "8px" }}
          bgcolor={theme.palette.purple10.main}
          container
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {treatmentGeneralInformation(
                false,
                serviceCategoryData?.result?.data?.data
              ).map((item) => {
                return (
                  <Box
                    sx={{
                      borderRadius: "8px",
                      background: "#F8F7FE",
                      display: "flex",
                      padding: "16px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "16px",
                      minHeight: "200px",
                      mb: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "4px" }}>
                        {item?.icon && <Box> {<item.icon size={24} />}</Box>}
                        <Typography
                          sx={{
                            color: "#4A4C4F",
                            fontSize: "16px",

                            fontWeight: 600,
                          }}
                        >
                          {" "}
                          {item?.title}
                        </Typography>
                      </Box>
                      {item?.description && (
                        <Typography
                          sx={{
                            color: "#5E6062",
                            fontSize: "12px",
                          }}
                        >
                          {" "}
                          {item?.description}
                        </Typography>
                      )}
                    </Box>
                    <Grid container spacing={1}>
                      {item?.rowChildren?.map((child, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sm={12}
                          md={child.gridLength}
                        >
                          {child?.colChildren && (
                            <Grid container spacing={1}>
                              {child?.colChildren?.map((subChild, index) => (
                                <Grid
                                  key={index}
                                  item
                                  xs={12}
                                  sm={12}
                                  md={subChild?.gridLength}
                                >
                                  {subChild?.colTitle && (
                                    <Typography
                                      sx={{
                                        color: "#5E6062",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                        letterSpacing: "0.035px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {subChild?.colTitle}
                                    </Typography>
                                  )}
                                  {subChild?.component && (
                                    <Box>
                                      <subChild.component
                                        control={control}
                                        {...subChild?.otherOptions}
                                      />
                                    </Box>
                                  )}
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                );
              })}
            </Grid>

            <Grid
              container
              bgcolor={customColors.white}
              sx={{
                padding: "4px",
                borderRadius: "8px",
                width: "auto",
                alignItems: "center",
              }}
              rowGap={1}
              xs={12}
              mt={1}
            >
              <Grid xs={3} lg={1.5}>
                <ProfileImageUploader
                  width={"48px"}
                  height={"48px"}
                  uploadBtnText={"edit"}
                  profileUpload={true}
                  isDisabled={true}
                  initals={`${singleServiceData?.name}`}
                  initialSize="48px"
                />
              </Grid>
              <Grid xs={6} lg={5}>
                <Typography
                  fontSize={fs10}
                  textTransform={"uppercase"}
                >{`id: ${singleServiceData?._id}`}</Typography>
              </Grid>
            </Grid>

            <Box sx={{ mt: "20px" }}>
              <Button fullWidth sx={SubmitBtn} type={"submit"}>
                <Typography>Save Changes</Typography>
              </Button>
            </Box>
          </FormProvider>
        </Grid>

        <Grid
          xs={12}
          md={5.7}
          style={{
            borderRadius: "8px",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              background: "#F8F7FE",
              display: "flex",
              padding: "16px",
              alignItems: "center",
              gap: "16px",
              mb: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", gap: "4px" }}>
                <Typography
                  sx={{
                    color: "#4A4C4F",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  Enable for client interaction
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    color: "#5E6062",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  When enabled clients can: view it, book an appointment, add it
                  to favorites, or share it.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "5px",
                backgroundColor: "#EDECFC",
                borderRadius: "8px  ",
              }}
            >
              <EnabledButton
                active={enabled === true ? true : null}
                onClick={toggleSwitch}
              >
                Enabled
              </EnabledButton>
              <DisabledButton
                active={enabled === false ? true : null}
                onClick={toggleSwitch}
              >
                Disabled
              </DisabledButton>
            </Box>
          </Box>
          <BufferTime
            serviceCategoryData={serviceCategoryData}
            control={control}
          />
          {isOpenNotDisableDialog && (
            <SPAAlertDialog
              open={isOpenNotDisableDialog}
              handleClose={() => setIsOpenNotDisableConfirmDialog(false)}
              handleNewTab={() => setIsOpenNotDisableConfirmDialog(false)}
              title={"Can’t Disable This Treatment"}
              contentText={`You cannot disable this treatment because there are pending appointments, discount codes, articles, bundles, or deals associated with it. Please cancel those pending actions before proceeding with the disabling of the treatment.`}
              disagreeText={`Go To The Treatment`}
              agreeText={`Cancel`}
              type="error"
            />
          )}

          {isOpenDisableConfirmDialog && (
            <SPAAlertDialog
              open={isOpenDisableConfirmDialog}
              handleClose={() => setIsOpenDisableConfirmDialog(false)}
              handleNewTab={handleDisableConfirm}
              title={"Treatment Disabling Confirmation"}
              contentText={`Clients will no longer be able to interact with this treatment. This means they won't be able to: view it, book an appointment, add it to favorites, or share it. Are you sure you want to proceed with the removal?`}
              disagreeText={`Disable The Treatment`}
              agreeText={`Cancel`}
              type="error"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
