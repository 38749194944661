import { Button, styled } from "@mui/material";

export const EnabledButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? "#13A10E" : "transparent",
  color: active ? "white" : "#4E45E5",
  borderRadius: "8px",
  flex: 1,
  "&:hover": {
    backgroundColor: active ? "darkgreen" : "rgba(0,0,0,0.1)",
  },
  border: "none",
  boxShadow: "none",
}));

export const DisabledButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? "#D13438" : "transparent",
  color: active ? "white" : "#4E45E5",
  borderRadius: "8px",

  flex: 1,
  "&:hover": {
    backgroundColor: active ? "darkred" : "rgba(0,0,0,0.1)",
  },
  border: "none",
  boxShadow: "none",
}));
