import { Box } from "@mui/material";
import React, { useState } from "react";
import SaleModelContent from "./sale-model-content";

const Sales = ({ clientDetail }) => {
  const [showCart, setShowCart] = useState(false);
  return (
    <Box
      sx={{
        p: "24px",
        height: "100%",
      }}
    >
      <SaleModelContent showCart={showCart} clientDetail={clientDetail} />
    </Box>
  );
};
export default Sales;
