import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useGetAllClientsMutation } from "../../../../../services/clients";
import { useUpdateAllProductMutation } from "../../../../../services/product";
import { useGetProductTypeMutation } from "../../../../../services/product-type";
import { useGetServiceCategoriesMutation } from "../../../../../services/service-category";
import { useGetServicesMutation } from "../../../../../services/services";
import { useGetStaffsMutation } from "../../../../../services/staff";
import { handleFilterSearch } from "../../../../../utils/searchFilter";
import { validationSchema } from "./validationSchema";

export default function useAddContent(
  handleClose,
  singleQualificationId,
  setIsOpenSuccessDialog,
  type,
  setAddQualificationData,
  initialData
) {
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const [searchTherapist, setSearchTherapist] = useState("");
  const validationSchemaInstance = validationSchema(type);

  const [getAllProduct, { data: productsData, isLoading: productsDataLoader }] =
    useUpdateAllProductMutation();
  const [
    getAllServices,
    { data: servicesData, isLoading: servicesDataLoader },
  ] = useGetServicesMutation();
  const [
    getAllProductType,
    { data: productTypeData, isLoading: productTypeDataLoader },
  ] = useGetProductTypeMutation();
  const [
    getAllServiceCategory,
    { data: serviceCategoryData, isLoading: serviceCategoryDataLoader },
  ] = useGetServiceCategoriesMutation();
  const [getAllClients, { data: clientsData, isLoading: clientsDataLoader }] =
    useGetAllClientsMutation();
  const [getAllStaffs, { data: staffsData, isLoading: staffsDataLoader }] =
    useGetStaffsMutation();

  const currentTypeValue = (item) => {
    if (item) {
      switch (item) {
        case "Treatments":
          return {
            key: "services",
            data: servicesData?.result?.data?.data,
            dataFetching: getAllServices,
            loader: servicesDataLoader,
          };
        case "Staff":
          return {
            key: "staff",
            data: staffsData?.result?.data?.data,
            dataFetching: getAllStaffs,
            loader: staffsDataLoader,
          };
        case "Customers":
          return {
            key: "client",
            data: clientsData?.result?.data?.data,
            dataFetching: getAllClients,
            loader: clientsDataLoader,
          };
        case "Treatment Categories":
          return {
            key: "serviceCategory",
            data: serviceCategoryData?.result?.data?.data,
            dataFetching: getAllServiceCategory,
            loader: serviceCategoryDataLoader,
          };
        case "Products":
          return {
            key: "products",
            data: productsData?.result?.data?.data,
            dataFetching: getAllProduct,
            loader: productsDataLoader,
          };
        case "Product Types":
          return {
            key: "productType",
            data: productTypeData?.result?.data?.data,
            dataFetching: getAllProductType,
            loader: productTypeDataLoader,
          };
        default:
          break;
      }
    }
  };

  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
    defaultValues: {
      [currentTypeValue(type)]: [],
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const onSubmit = async (values) => {
    setAddQualificationData(values);
    setIsOpenSuccessDialog(false);
    handleClose("Success");
  };

  const handleCloseForm = () => {
    handleClose("Deffer");
    reset();
  };

  const handleSearchContent = () => {
    let attributeArray = [];
    let finalUserData =
      type === "Staff"
        ? { roleType: "STAFF" }
        : type === "Customers"
        ? { roleType: "CLIENT" }
        : null;
    const filterProductPayload = handleFilterSearch(
      ["type=RETAIL_PRODUCT"],
      ["type"],
      "filter"
    );
    let finalData = type === "Products" ? filterProductPayload : null;
    if (searchTherapist !== "") {
      if (isNaN(searchTherapist)) {
        attributeArray = [
          type === "Staff" || type === "Customers" ? "firstName" : "name",
        ];
      }

      const searchPayload = handleFilterSearch(
        searchTherapist,
        attributeArray,
        "search"
      );

      currentTypeValue(type)?.dataFetching({
        ...searchPayload,
        ...finalData,
        ...finalUserData,
      });
    } else {
      currentTypeValue(type)?.dataFetching({ ...finalData, ...finalUserData });
    }
  };

  useEffect(() => {
    handleSearchContent();
  }, [searchTherapist]);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      ["type=RETAIL_PRODUCT"],
      ["type"],
      "filter"
    );
    getAllProduct({ ...searchPayload });
    getAllServices();
    getAllStaffs({ roleType: "STAFF" });
    getAllClients({ roleType: "CLIENT" });
    getAllServiceCategory();
    getAllProductType();
  }, []);

  useEffect(() => {
    setValue(currentTypeValue(type)?.key, initialData);
  }, [initialData]);

  return {
    searchTherapist,
    setSearchTherapist,
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    productsData,
    productsDataLoader,
    selectedStaffIds,
    currentTypeValue,
  };
}
