import SPAInputField from "../../../hook-form/SPAInputField";

export const promoCodeTabsArray = [
  { title: "ALL" },
  {
    title: "ENABLED",
  },
  { title: "DISABLED" },
];

export const usagePromoCodeFormData = () => {
  let finalData = [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "maximumLimitPerCostumer",
        label: "Maximum Per Customer Usage",
        placeholder: "Leave empty for no limit",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "maximumTotalLimit",
        label: "Maximum Total Usage",
        placeholder: "Leave empty for no limit",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "minimumSpend",
        label: "Minimum Spend",
        placeholder: "Leave empty for no limit",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "maximumDiscountAmount",
        label: "Maximum Total Discount Amount",
        placeholder: "Leave empty for no limit",
        labelHelperText: "(Optional)",
      },
      component: SPAInputField,
    },
  ];
  return finalData;
};

export const appliesToFormData = () => {
  let appliesToData = [
    {
      id: 1,
      label: "Staff",
      type: "allStaff",
      options: [
        {
          value: "ALL_STAFF",
          label: "ALL STAFF",
        },
        {
          value: "SELECTED",
          label: "SELECTED",
        },
      ],
    },
    {
      id: 2,
      label: "Customers",
      type: "allClients",
      options: [
        {
          value: "ALL_CLIENTS",
          label: "ALL CUSTOMERS",
        },
        {
          value: "SELECTED",
          label: "SELECTED",
        },
      ],
    },
    {
      id: 3,
      label: "Treatments",
      type: "allServices",
      options: [
        {
          value: "ALL_TREATMENTS",
          label: "ALL Treatments",
        },
        {
          value: "SELECTED",
          label: "SELECTED",
        },
      ],
    },
    {
      id: 4,
      label: "Treatment Categories",
      type: "allServiceCategories",
      options: [
        {
          value: "ALL_TREATMENTS_CATEGORIES",
          label: "ALL Treatment Categories",
        },
        {
          value: "SELECTED",
          label: "SELECTED",
        },
      ],
    },
    {
      id: 5,
      label: "Products",
      type: "allProducts",
      options: [
        {
          value: "ALL_PRODUCTS",
          label: "ALL Products",
        },
        {
          value: "SELECTED",
          label: "SELECTED",
        },
      ],
    },
    {
      id: 6,
      label: "Product Types",
      type: "allProductTypes",
      options: [
        {
          value: "ALL_PRODUCTS_TYPES",
          label: "ALL Product Types",
        },
        {
          value: "SELECTED",
          label: "SELECTED",
        },
      ],
    },
  ];
  return appliesToData;
};
