import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DotsIcon from "../../../assets/svg/dots-icon";
import { useGetQualificationsMutation } from "../../../services/qualifications";
import { fw600 } from "../../../theme";
import { handleFilterSearch } from "../../../utils/searchFilter";

const useQualifications = () => {
  const [searchQualification, setSearchQualification] = useState("");
  const [qualificationsPopoverOpen, setQualificationsPopoverOpen] =
    useState(null);
  const [isQualificationsDialogOpen, setIsQualificationsDialogOpen] =
    useState(false);
  const [singleQualificationId, setSingleQualificationId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);
  const [isOpenAddQualificationDialog, setIsOpenAddQualificationDialog] =
    useState(false);
  const [staffUpdateType, setStaffUpdateType] = useState("");
  // Variables for Qualifications popover state and IDs
  const openQualificationsPopOver = Boolean(qualificationsPopoverOpen);
  const qualificationsPopOverId = openQualificationsPopOver
    ? "simple-popover"
    : undefined;
  const [
    getAllQualifications,
    { data: qualificationsData, isLoading: qualificationsDataLoader },
  ] = useGetQualificationsMutation();

  const qualificationsTableHeader = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#4A4C4F",
                  fontSize: "14px",
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                {value}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#4A4C4F",
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}
              >
                {value?.name ?? "---"}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                setQualificationsPopoverOpen(e.currentTarget);
                setSingleQualificationId(rowData[tableMeta?.rowIndex]?._id);
              }}
            >
              <DotsIcon />
            </Box>
          );
        },
      },
    },
  ];
  const handleNewQualification = () => {
    setIsQualificationsDialogOpen(true);
  };

  const handleCloseSuccessPopup = () => {
    setIsOpenSuccessDialog(false);
  };

  const handleAddQualification = async () => {
    setIsOpenAddQualificationDialog(true);
    handleCloseSuccessPopup();
  };

  const handleSearchStaff = () => {
    let attributeArray = [];
    if (searchQualification !== "") {
      if (isNaN(searchQualification)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchQualification,
        attributeArray,
        "search"
      );
      getAllQualifications({ ...searchPayload });
    } else getAllQualifications({});
  };

  useEffect(() => {
    handleSearchStaff();
  }, [searchQualification]);

  useEffect(() => {
    getAllQualifications({});
  }, []);

  return {
    singleQualificationId,
    setSingleQualificationId,
    qualificationsTableHeader,
    isQualificationsDialogOpen,
    setIsQualificationsDialogOpen,
    qualificationsPopoverOpen,
    setQualificationsPopoverOpen,
    qualificationsPopOverId,
    openQualificationsPopOver,
    handleNewQualification,
    qualificationsData,
    qualificationsDataLoader,
    currentPage,
    setCurrentPage,
    isOpenSuccessDialog,
    setIsOpenSuccessDialog,
    handleCloseSuccessPopup,
    handleAddQualification,
    searchQualification,
    setSearchQualification,
    isOpenAddQualificationDialog,
    setIsOpenAddQualificationDialog,
    getAllQualifications,
    staffUpdateType,
    setStaffUpdateType,
  };
};

export default useQualifications;
