import React from "react";
import {
  Box,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { customColors, fw600, primary, secondary } from "../../../../theme";
import useClientProfile from "./useClientProfile";
import { clientProfileNavBarData } from "../client-profile/client-profile-data";

export default function ClientProfile({ isResponsive }) {
  const { activeItem, setActiveItem, componentMap } = useClientProfile();

  return (
    <Box sx={{ width: "100%", p: "24px" }}>
      <Box
        sx={{
          backgroundColor: primary.blue,
          borderRadius: "8px",
          width: "100%",
          p: "4px",
        }}
      >
        <Box
          sx={{
            height: "48px",
            display: "flex",
            backgroundColor: primary.blue,
            width: "max-content",
            overflow: "auto",
          }}
        >
          {clientProfileNavBarData.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setActiveItem(item.link);
              }}
              sx={{
                flexDirection: "row",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                gap: "6px",
                backgroundColor: activeItem?.includes(item.link)
                  ? secondary.main
                  : primary.blue,
                color: activeItem?.includes(item.link)
                  ? customColors.white
                  : secondary.main,
                "&:hover": {
                  backgroundColor: activeItem?.includes(item.link)
                    ? secondary.main
                    : "auto",
                },
              }}
            >
              {item.icon && (
                <ListItemIcon
                  sx={{
                    minWidth: "auto",
                  }}
                >
                  <item.icon
                    size={20}
                    color={
                      activeItem?.includes(item.link)
                        ? customColors.white
                        : secondary.main
                    }
                  />
                </ListItemIcon>
              )}

              {!isResponsive && (
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: fw600,
                      textTransform: "uppercase",
                      width: "max-content",
                    },
                  }}
                  primary={item.label}
                />
              )}
            </ListItemButton>
          ))}
        </Box>
      </Box>
      <Box sx={{ pt: "24px", minHeight: "90%" }}>
        {activeItem &&
          React.createElement(componentMap[activeItem], { setActiveItem })}
      </Box>
    </Box>
  );
}
