import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import React, { useState } from "react";
import NoNotifications from "../../../../../../../../assets/svg/no-notifications";
import DiscountTab from "./DiscountTab";
import FormProvider from "../../../../../../../hook-form/FormProvider";
import {
  activeDatesFormData,
  appliesToFormData,
  dicountDetailsFormData,
  discountCodeFormData,
  discountCodeTabsArray,
  usageFormData,
  couponData,
} from "./discount-code-form-data";
import { SubmitBtn } from "./discountCodeStyle";
import useDiscountCode from "./useDiscountCode";
import {
  ArrowSquareOut,
  PencilSimple,
  Plus,
  Ticket,
} from "@phosphor-icons/react";
import SPASwitch from "../../../../../../../common/spa-switch";
import SPAInputField from "../../../../../../../hook-form/SPAInputField";
import {
  customColors,
  fs18,
  fw600,
  primary,
  secondary,
} from "../../../../../../../../theme";
import ToggleComponent from "./ToggleComponent";

import { EnabledButton, DisabledButton } from "./Buttons";
import SPADialogue from "../../../../../../../common/spa-dialogue";
import CreateNewCoupon from "../../../../../../coupon/create-coupon";

export default function DiscountCodeSection({
  singleProductDetails,
  singleProductRefetching,
}) {
  const {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    // couponData,
    toggleSwitch,
    setEnabled,
    enabled,
    // activeHistoryTab,
    handleTabSelection,
    // historyId,
    handleSelectCoupon,
    couponId,
    isOpenEditPromoCodeDialog,
    Close,
    editPromoCodeHandleSubmit,
    editPromoCodeOnSubmit,
    editPromoCodeMethods,
    handleClosePromoCodeDialog,
    handleOpenEditPromoCodeDialog,
    openDialogCreateCoupon,
    handleCloseCreateCoupon,
    couponType,
    selectedCoupon,
    handleOpenCreateCoupon,
  } = useDiscountCode(singleProductDetails);

  const unPublish = true;

  const styles = {
    Enabled: {
      backgroundColor: "#E9FBF6",
      color: "#1AA37A",
      fontSize: "10px",
      p: "4px",
      borderRadius: "4px",
      // color:
      //   couponId === item?.id ? "#000000" : "#1AA37A",
    },
    Disabled: {
      backgroundColor: "#F8F7FE",
      color: "white",
      fontSize: "10px",
      p: "4px",
      borderRadius: "4px",
    },
    Used: {
      backgroundColor: "#E7EFFC",
      color: "#0D469E ",
      fontSize: "10px",
      p: "4px",
      borderRadius: "4px",
    },
  };

  return (
    <>
      <Box
        sx={{
          paddingBlock: "10px",
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnGap={2}
            sx={{
              gap: "10px",
            }}
          >
            {/* DISCOUNT LISTING TAB */}

            <Grid item xs={5} md={3}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "16px",
                }}
              >
                <Button
                  disableElevation
                  variant="contained"
                  sx={{
                    marginBlock: "24px",
                    background: secondary.main,
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    borderRadius: "8px",
                    height: "48px",
                    "&:hover": { background: secondary.main },
                  }}
                  onClick={handleOpenCreateCoupon}
                >
                  <Plus size={24} color="currentColor" />
                  CREATE PROMO CODE
                </Button>
              </Box>
              <DiscountTab
                tabsNameArray={discountCodeTabsArray}
                // activeTab={activeHistoryTab}
                setActiveTab={handleTabSelection}
              />
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  maxHeight: "61vh",
                  overflowY: "auto",
                  mb: 2,
                }}
              >
                {couponData?.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        borderRadius: "16px",
                        bgcolor: couponId === item?.id ? "#4E45E5" : "#F8F7FE",
                        padding: "16px",
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelectCoupon(item?.id)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          bgcolor:
                            couponId === item?.id ? "#4E45E5" : "#F8F7FE",
                        }}
                      >
                        <Typography sx={styles[item?.status]}>
                          {item?.status}
                        </Typography>
                        <Box>
                          <Typography
                            color={
                              couponId === item?.id ? "#ffffff" : "#8F9193"
                            }
                            fontSize={"10px"}
                            textTransform={"uppercase"}
                          >
                            {dayjs(item?.createAt).format("DD MMM, YYYY")}
                          </Typography>
                        </Box>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: couponId === item?.id ? "#ffffff" : "#4A4C4F",
                          mt: "8px",
                          fontWeight: "600",
                        }}
                      >
                        {item?.description}
                      </Typography>
                      {/* <Typography sx={{ fontSize: "12px", color: "#5E6062" }}>
                      Figma ipsum component variant main layer. Overflow select
                      create italic blur component.
                    </Typography> */}
                      {/* <Box
                        sx={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                        }}
                      >
                        <Ticket
                          color={couponId === item?.id ? "#ffffff" : "#8F9193"}
                        />
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color:
                              couponId === item?.id ? "#ffffff" : "#8F9193",
                          }}
                        >
                          Discount Code
                        </Typography>
                      </Box> */}
                    </Box>
                  );
                })}
                {couponData?.result?.data?.length === 0 && (
                  <Box
                    sx={{
                      mt: "18px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      gap: "24px",
                    }}
                  >
                    <NoNotifications />
                    <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                      No Discount Code Yet
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                      After creating Discount Code, they will be displayed here
                      as Discount Code.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>

            {/* DISCOUNT PREVIEW TAB */}

            {couponData?.result?.data?.length === 0 ? (
              <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                No Discount Code Yet For {singleProductDetails?.name}
              </Typography>
            ) : (
              <Grid
                item
                xs={5}
                md={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  maxHeight: "75vh",
                  overflowY: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        width: "min-content",
                        color: "#25272B",
                        bgcolor: "#FAFAFA",
                        p: "4px",
                        borderRadius: "4px",
                      }}
                    >
                      Disabled
                    </Typography>
                    <Typography sx={{ fontSize: "20px", color: "#5E6062" }}>
                      {singleProductDetails?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  // sx={{
                  //   p: "24px",
                  // }}
                  sx={{
                    borderRadius: "16px",
                    bgcolor: "#F8F7FE",
                    flexGrow: "1 !important",
                    maxWidth: "100% !important",
                    p: "24px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      lg={12}
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <SPAInputField
                        fullWidth={true}
                        name="discountCodeName"
                        label="Name"
                        // readOnly={true}
                        // placeholder="Enter the Promo Code here"
                        control={control}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={10}
                      xs={10}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <SPAInputField
                        fullWidth={true}
                        name="promoCode"
                        label="Promo Code"
                        readOnly={true}
                        placeholder="Enter the Promo Code here"
                        control={control}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={2}
                      xs={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <PencilSimple
                        size={30}
                        color="#4E45E5"
                        onClick={handleOpenEditPromoCodeDialog}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    bgcolor: "#F8F7FE",
                    borderRadius: "8px",
                  }}
                >
                  <Grid
                    container
                    spacing={1}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fw600,
                        marginBlock: "16px",
                        textAlign: "center",
                      }}
                    >
                      Discount Details
                    </Typography>
                    {dicountDetailsFormData().map((item, index) => {
                      return (
                        <Grid
                          item
                          lg={item.gridLength}
                          xs={5}
                          key={index}
                          sx={{ marginBottom: "16px" }}
                        >
                          <item.component
                            {...item.otherOptions}
                            control={control}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>

                <Box sx={{ bgcolor: "#F8F7FE", borderRadius: "8px" }}>
                  <Grid
                    container
                    spacing={1}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: fw600,
                        marginBlock: "16px",
                        textAlign: "center",
                      }}
                    >
                      Applies to
                    </Typography>
                    {appliesToFormData().map((item, index) => {
                      return (
                        <Grid
                          item
                          lg={item.gridLength}
                          xs={12}
                          key={index}
                          sx={{ marginBottom: "16px" }}
                        >
                          <item.component
                            {...item.otherOptions}
                            control={control}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
            )}

            {/* USAGE */}
            <Grid
              item
              xs={6}
              md={3.5}
              sx={{ height: "75vh", overflowY: "auto" }}
            >
              <Box sx={{ marginBottom: "15px" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button>View on promo codes</Button>
                  <ArrowSquareOut />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    backgroundColor: "#EDECFC",
                    borderRadius: "8px  ",
                  }}
                >
                  <EnabledButton active={enabled} onClick={toggleSwitch}>
                    Enabled
                  </EnabledButton>
                  <DisabledButton active={!enabled} onClick={toggleSwitch}>
                    Disabled
                  </DisabledButton>
                </Box>
              </Box>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  maxHeight: "66vh",
                  overflowY: "auto",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    borderRadius: "16px",
                    bgcolor: "#F8F7FE",
                    flexGrow: "1 !important",
                    maxWidth: "100% !important",
                    p: "24px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: fs18,
                      fontWeight: fw600,
                      padding: "16px",
                    }}
                  >
                    Usage
                  </Typography>
                  <Grid container spacing={1}>
                    {usageFormData().map((item, index) => {
                      return (
                        <Grid item lg={item.gridLength} xs={12} key={index}>
                          <item.component
                            {...item.otherOptions}
                            control={control}
                          />
                        </Grid>
                      );
                    })}
                    <Grid item lg={12} xs={12} sx={{ display: "flex" }}>
                      <Typography sx={{}}>Combine with Other Offers</Typography>
                      <SPASwitch name={"combineOffer"} control={control} />
                    </Grid>
                  </Grid>
                </Box>

                {couponData?.result?.data?.length === 0 && (
                  <Box
                    sx={{
                      mt: "18px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      gap: "24px",
                    }}
                  >
                    <NoNotifications />
                    <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                      No Discount Code Yet
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                      After creating Discount Code, they will be displayed here
                      as Discount Code.
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Box
                sx={{
                  borderRadius: "16px",
                  bgcolor: "#F8F7FE",
                  flexGrow: "1 !important",
                  maxWidth: "100% !important",
                  p: "24px",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: fs18,
                    fontWeight: fw600,
                    padding: "16px",
                  }}
                >
                  Active Dates
                </Typography>
                <Grid container spacing={1}>
                  {activeDatesFormData().map((item, index) => {
                    return (
                      <Grid item lg={item.gridLength} xs={12} key={index}>
                        <item.component
                          {...item.otherOptions}
                          control={control}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              mt: "16px",
            }}
          >
            <Button sx={SubmitBtn} type={"submit"}>
              <Typography>Save Changes</Typography>
            </Button>
          </Box>
          {isOpenEditPromoCodeDialog && (
            <>
              <SPADialogue
                open={isOpenEditPromoCodeDialog}
                handleClose={handleClosePromoCodeDialog}
                dialogTitle={`Update Promo Code`}
                cancelText="Cancel"
                submitText={"Save changes"}
                handleSubmit={editPromoCodeHandleSubmit}
                onSubmit={editPromoCodeOnSubmit}
                methods={editPromoCodeMethods}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "30vw",
                  }}
                >
                  <SPAInputField
                    fullWidth={true}
                    name="discountCodeEdit"
                    control={control} // control has to be changed according to the promo control
                  />
                </Box>
              </SPADialogue>
            </>
          )}
          {openDialogCreateCoupon && (
            <CreateNewCoupon
              open={openDialogCreateCoupon}
              handleClose={handleCloseCreateCoupon}
              couponId={selectedCoupon}
              couponType={couponType}
            />
          )}
        </FormProvider>
      </Box>
    </>
  );
}

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
