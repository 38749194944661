import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DotsIcon from "../../../assets/svg/dots-icon";
import { useGetProductTypeMutation } from "../../../services/product-type";
import { fw600 } from "../../../theme";
import { handleFilterSearch } from "../../../utils/searchFilter";

const useProductType = ({ getAllProducts, type }) => {
  const [searchRole, setSearchRole] = useState("");
  const [rolesPopoverOpen, setRolesPopoverOpen] = useState(null);
  const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false);
  const [singleRoleId, setSingleRoleId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);
  const [isOpenAddRoleDialog, setIsOpenAddRoleDialog] = useState(false);
  // Variables for Roles popover state and IDs
  const openRolesPopOver = Boolean(rolesPopoverOpen);
  const rolesPopOverId = openRolesPopOver ? "simple-popover" : undefined;
  const [getAllProductType, { data: rolesData, isLoading: rolesDataLoader }] =
    useGetProductTypeMutation();

  const productTypeTableHeader = [
    {
      name: "name",
      label: "Product Type Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#4A4C4F",
                  fontSize: "14px",
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                {value}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "productAssigned",
      label: "Product Assigned",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#4A4C4F",
                  fontSize: "14px",
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                {value ?? "---"}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                setRolesPopoverOpen(e.currentTarget);
                setSingleRoleId(rowData[tableMeta?.rowIndex]?._id);
              }}
            >
              <DotsIcon />
            </Box>
          );
        },
      },
    },
  ];
  const handleNewRole = () => {
    setIsRolesDialogOpen(true);
  };

  const handleCloseSuccessPopup = () => {
    setIsOpenSuccessDialog(false);
  };

  const handleAddRole = async () => {
    setIsOpenAddRoleDialog(true);
    handleCloseSuccessPopup();
  };

  const handleSearchStaff = () => {
    let attributeArray = [];
    if (searchRole !== "") {
      if (isNaN(searchRole)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchRole,
        attributeArray,
        "search"
      );
      getAllProductType({ ...searchPayload });
    } else getAllProductType({});
  };

  useEffect(() => {
    handleSearchStaff();
  }, [searchRole]);

  useEffect(() => {
    getAllProductType({});
    const searchPayload = handleFilterSearch(
      [`type=${type}`],
      ["type"],
      "filter"
    );
    getAllProducts({ ...searchPayload });
  }, []);

  return {
    singleRoleId,
    setSingleRoleId,
    productTypeTableHeader,
    isRolesDialogOpen,
    setIsRolesDialogOpen,
    rolesPopoverOpen,
    setRolesPopoverOpen,
    rolesPopOverId,
    openRolesPopOver,
    handleNewRole,
    rolesData,
    rolesDataLoader,
    currentPage,
    setCurrentPage,
    isOpenSuccessDialog,
    setIsOpenSuccessDialog,
    handleCloseSuccessPopup,
    handleAddRole,
    searchRole,
    setSearchRole,
    isOpenAddRoleDialog,
    setIsOpenAddRoleDialog,
    getAllProductType,
  };
};

export default useProductType;
