import React from "react";
import SPAImageUpload from "../../../../common/spa-image-upload";
import SPAInputField from "../../../../hook-form/SPAInputField";
import { QuestionMark } from "@mui/icons-material";
import { Box } from "@mui/material";

const ImageComponent = ({
  imageFile,
  index,
  control,
  handleFileUpload,
  focusedIndex,
  setFocusedIndex,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <SPAImageUpload
          initialSrc={imageFile[index]}
          fileUpload={(file) => handleFileUpload(file, index)}
        />
      </Box>
      <SPAInputField
        fullWidth
        prefixIcon={
          <QuestionMark sx={{ fontSize: "16px", color: "#C1C2C3" }} />
        }
        name={`alttext${index}`}
        placeholder="Alt Text here"
        control={control}
        autoFocus={focusedIndex === index ? true : false}
        onFocus={() => setFocusedIndex(index)}
      />
    </Box>
  );
};

export default ImageComponent;
