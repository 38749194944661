import React from "react";
import {
  Box,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  customColors,
  fw600,
  primary,
  secondary,
} from "../../../../../../theme";
import useStaffProfile from "./useStaffProfile";
import { StaffProfileNavBarData } from "./staff-profile-data";
import Overview from "./components/overview";
import Address from "./components/address";
import Appointment from "./components/appointments";
import Emergency from "./components/emergency";
import Roster from "./components/roster";
import Qualification from "./components/qualifications";

export default function StaffProfile({
  isResponsive,
  singleStaffData,
  singleStaffRefetching,
}) {
  const { activeItem, setActiveItem } = useStaffProfile();

  const renderTabContent = (tab) => {
    switch (tab) {
      case "Overview":
        return (
          <Overview
            singleStaffData={singleStaffData}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      case "Address":
        return (
          <Address
            singleStaffData={singleStaffData}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      case "Appointment":
        return (
          <Appointment
            singleStaffData={singleStaffData}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      case "Roster":
        return <Roster />;
      case "Emergency":
        return (
          <Emergency
            singleStaffData={singleStaffData}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      case "Qualification":
        return (
          <Qualification
            singleStaffData={singleStaffData}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <Box sx={{ width: "100%", p: "0px 24px 24px 24px" }}>
      <Box
        sx={{
          backgroundColor: primary.blue,
          borderRadius: "8px",
          width: "100%",
          p: "4px",
          position: "sticky",
          top: "24px",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            height: "48px",
            display: "flex",
            backgroundColor: primary.blue,
            width: "max-content",
            overflow: "auto",
          }}
        >
          {StaffProfileNavBarData.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setActiveItem(item.link);
              }}
              sx={{
                flexDirection: "row",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                gap: "6px",
                backgroundColor: activeItem?.includes(item.link)
                  ? secondary.main
                  : primary.blue,
                color: activeItem?.includes(item.link)
                  ? customColors.white
                  : secondary.main,
                "&:hover": {
                  backgroundColor: activeItem?.includes(item.link)
                    ? secondary.main
                    : "auto",
                },
              }}
            >
              {item.icon && (
                <ListItemIcon
                  sx={{
                    minWidth: "auto",
                  }}
                >
                  <item.icon
                    size={20}
                    color={
                      activeItem?.includes(item.link)
                        ? customColors.white
                        : secondary.main
                    }
                  />
                </ListItemIcon>
              )}

              {!isResponsive && (
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: fw600,
                      textTransform: "uppercase",
                      width: "max-content",
                    },
                  }}
                  primary={item.label}
                />
              )}
            </ListItemButton>
          ))}
        </Box>
      </Box>
      <Box sx={{ pt: "38px", maxHeight: "72vh", overflowY: "auto" }}>
        {renderTabContent(activeItem)}
      </Box>
    </Box>
  );
}
