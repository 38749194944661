import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Warning, X } from "@phosphor-icons/react";
import React from "react";
import { Controller } from "react-hook-form";
import NoClients from "../../../../../assets/svg/no-clients.jsx";
import SPADialogue from "../../../../common/spa-dialogue/index.jsx";
import SPATextField from "../../../../common/spa-textField/index.jsx";
import { staffStyle } from "../../../staff/staffStyle.js";
import useAddContent from "./useAddContent.js";

export default function AddContent({
  isAddQualificationOpen,
  handleClose,
  singleQualificationId,
  setIsOpenSuccessDialog,
  dialogTitle,
  contentTitle,
  subContentDescription,
  type,
  submitText = "Select",
  setAddQualificationData,
  initialData,
}) {
  const {
    searchTherapist,
    setSearchTherapist,
    methods,
    handleSubmit,
    onSubmit,
    handleCloseForm,
    updateAddQualificationLoader,
    errors,
    currentTypeValue,
  } = useAddContent(
    handleClose,
    singleQualificationId,
    setIsOpenSuccessDialog,
    type,
    setAddQualificationData,
    initialData
  );

  return (
    <>
      <SPADialogue
        open={isAddQualificationOpen}
        handleClose={handleCloseForm}
        dialogTitle={dialogTitle ?? "Title"}
        cancelText="Cancel"
        submitText={submitText}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        methods={methods}
        loading={updateAddQualificationLoader}
      >
        {currentTypeValue(type)?.loader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 3 }}
            open={currentTypeValue(type)?.loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={staffStyle.addQualificationHeaderMain}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "#4A4C4F",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {contentTitle ?? "Title"}
              </Typography>
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: "12px",
                }}
              >
                {subContentDescription ?? "Description Here"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <SPATextField
                placeholder={`Search ${contentTitle ?? "Title"}`}
                value={searchTherapist}
                fullWidth={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} color={"#C1C2C3"} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingLeft: "10px", cursor: "pointer" }}
                      onClick={() => {
                        setSearchTherapist("");
                      }}
                    >
                      {searchTherapist && <X size={24} />}
                    </InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchTherapist(event.target.value);
                }}
              />
            </Box>
            <Box
              sx={{
                width: "688px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                maxHeight: "43vh",
                overflowY: "auto",
              }}
            >
              {currentTypeValue(type)?.data?.map((item) => {
                return (
                  <Controller
                    key={item._id}
                    name={currentTypeValue(type)?.key}
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Box
                        sx={{
                          borderRadius: "4px",
                          bgcolor: "#FFFFFF",
                          px: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const currentValue = Array.isArray(value)
                            ? value
                            : [];
                          const newValue = currentValue.some(
                            (obj) => obj._id === item._id
                          )
                            ? currentValue.filter((obj) => obj._id !== item._id)
                            : [...currentValue, item];
                          onChange(newValue);
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#4E45E5",
                              },
                            }}
                            checked={
                              Array.isArray(value) &&
                              value.some((obj) => obj._id === item._id)
                            }
                            onChange={(e) => {
                              e.stopPropagation();
                            }}
                            onBlur={onBlur}
                          />
                          <Typography
                            sx={{ color: "#070A0E", fontSize: "12px" }}
                          >
                            {`${item?.name ? item?.name : item?.firstName}`}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  />
                );
              })}

              {currentTypeValue(type)?.data?.length === 0 &&
                !currentTypeValue(type)?.loader && (
                  <Box
                    sx={{
                      mt: "18px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    <NoClients />
                    <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                      There’s no {contentTitle ?? "Title"} yet
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                      After creating {contentTitle ?? "Title"}, they will be
                      displayed here as {contentTitle ?? "Title"}.
                    </Typography>
                  </Box>
                )}
            </Box>
          </Box>
          {errors[currentTypeValue(type)?.key] && (
            <Typography
              sx={{
                color: "#FFAA33",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                mt: "5px",
              }}
              color="error"
            >
              <Warning color={"#FFAA33"} size={16} />{" "}
              {errors[currentTypeValue(type)?.key].message}
            </Typography>
          )}
        </Box>
      </SPADialogue>
    </>
  );
}
