import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreatePackageMutation,
  useGetPackagesMutation,
  useGetSinglePackageQuery,
  useUpdateSinglePackageMutation,
} from "../../../../../../../../../../services/package";
import { useGetServiceCategoriesMutation } from "../../../../../../../../../../services/service-category";
import { handleFilterSearch } from "../../../../../../../../../../utils/searchFilter";
import { validationSchema } from "./validationSchema";
import { useGetProductTypeMutation } from "../../../../../../../../../../services/product-type";
import { useGetSingleProductPackageQuery } from "../../../../../../../../../../services/product";

export default function usePackage({
  singleProductDetails,
  singleProductRefetching,
}) {
  const [searchPackage, setSearchPackage] = useState("");
  const [activePackageTab, setActivePackageTab] = useState("ALL");
  const [singlePackageId, setSinglePackageId] = useState(null);
  const [contentTypeValue, setContentTypeValue] = useState(null);
  const [singleContentId, setSingleContentId] = useState(null);
  const [isOpenDisableConfirmDialog, setIsOpenDisableConfirmDialog] =
    useState(false);
  const [enabled, setEnabled] = useState(null);
  const [discountTypeValue, setDiscountTypeValue] = useState("PERCENTAGE");
  const [isOpenAddContentDialog, setIsOpenAddContentDialog] = useState(false);
  const [addContentData, setAddContentData] = useState([]);
  const [selectedServicesData, setSelectedServicesData] = useState([]);
  const [selectedProductsData, setSelectedProductsData] = useState([]);
  const [singleProductPackage, setSingleProductPackage] = useState([]);

  const toggleSwitch = () => {
    if (enabled === true) {
      setIsOpenDisableConfirmDialog(true);
    } else {
      setEnabled(true);
    }
  };

  const validationSchemaInstance = validationSchema(
    selectedProductsData,
    selectedServicesData
  );
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
  });
  const [
    getAllServiceCategory,
    { data: serviceCategoryData, isLoading: serviceCategoryDataLoader },
  ] = useGetServiceCategoriesMutation();

  const [
    getAllProductType,
    { data: productTypeData, isLoading: productTypeDataLoader },
  ] = useGetProductTypeMutation();

  const [
    getAllPackages,
    { data: packagesData, isLoading: packagesDataLoader },
  ] = useGetPackagesMutation();

  const [createPackage] = useCreatePackageMutation();
  const [updateSinglePackage] = useUpdateSinglePackageMutation();

  const {
    data: singlePackage,
    isLoading: singlePackageLoader,
    refetch: refetchSinglePackage,
  } = useGetSinglePackageQuery(singlePackageId, {
    skip: !singlePackageId,
  });

  const {
    data: singleFilterPackage,
    isLoading: singleFilterPackageLoader,
    refetch: refetchSingleFilterPackage,
  } = useGetSingleProductPackageQuery(
    {
      productId: singleProductDetails?._id,
      searchQuery: searchPackage,
      type: activePackageTab === "ALL" ? "" : activePackageTab,
    },
    {
      skip: !singleProductDetails?._id,
    }
  );

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const productListingData = watch("product");
  const servicesListingData = watch("services");

  const packageNameValue = watch("name");

  const onSubmit = async (values) => {
    let payload;
    let filterServiceData = selectedServicesData?.map((item) => {
      return {
        serviceId: item?._id,
        customPrice: values[`customPrice_${item?._id}`] ?? 200,
      };
    });

    let filterProductsData = selectedProductsData?.map((item) => {
      return {
        productId: item?._id,
        customPrice: values[`customPrice_${item?._id}`] ?? 200,
      };
    });
    payload = {
      name: values?.name,
      productType: values?.productType,
      description: values?.description ?? undefined,
      discountType: discountTypeValue,
      tax: values?.tax ?? undefined,
      percentageAmount: Number(values?.percentageAmount) ?? undefined,
      services: filterServiceData ?? undefined,
      product: filterProductsData ?? undefined,
    };

    let response;
    if (singlePackageId) {
      response = await updateSinglePackage({
        id: singlePackage?.result?.data?._id,
        payload: {
          ...payload,
          isEnablePackage:
            enabled === true
              ? "ENABLED"
              : enabled === false
              ? "DISABLED"
              : undefined,
        },
      });
    } else {
      response = await createPackage(payload);
    }
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    getAllPackages({});
    setSinglePackageId("");
    refetchSingleFilterPackage();
    reset({
      name: "",
      description: "",
      category: "",
      discountType: "",
      tax: "",
      percentageAmount: "",
      product: null,
      services: null,
      customPrice: null,
    });
    setSelectedProductsData([]);
    setSelectedServicesData([]);
  };

  useEffect(() => {
    if (contentTypeValue === "Product") {
      setSelectedProductsData(addContentData?.product ?? []);
      setValue(
        "product",
        addContentData?.product?.map((item) => item?._id)
      );
    }
    if (contentTypeValue === "Treatment") {
      setSelectedServicesData(addContentData?.services ?? []);
      setValue(
        "services",
        addContentData?.services?.map((item) => item?._id)
      );
    }
  }, [addContentData]);

  useEffect(() => {
    getAllServiceCategory();
    getAllProductType();
  }, []);

  const removeSelectedService = (id) => {
    setSelectedServicesData((prev) => prev.filter((item) => item._id !== id));
    const filterData = servicesListingData?.filter((item) => item !== id);
    setValue("services", filterData);
  };

  const removeSelectedProduct = (id) => {
    setSelectedProductsData((prev) => prev.filter((item) => item._id !== id));
    const filterData = productListingData?.filter((item) => item !== id);
    setValue("product", filterData);
  };

  const handleTabSelection = (item) => {
    setActivePackageTab(item);
    const finalFilter = item !== "ALL" ? item : "";
    const searchPayload = handleFilterSearch(
      [`isEnablePackage=${finalFilter}`],
      ["isEnablePackage"],
      "filter"
    );
    if (item === "ALL") {
      getAllPackages({});
    } else {
      getAllPackages({ ...searchPayload });
    }
  };

  const handleSinglePackage = (item) => {
    setSinglePackageId(item?._id);
  };

  const handleCreatePackage = () => {
    reset({
      name: "",
      description: "",
      category: "",
      discountType: "",
      tax: "",
      percentageAmount: "",
    });
    setSinglePackageId("");
    setSelectedProductsData([]);
    setSelectedServicesData([]);
  };

  useEffect(() => {
    if (singlePackage && singlePackageId) {
      const singlePackageFormat = singlePackage?.result?.data;
      setValue("name", singlePackageFormat?.name);
      setValue("productType", singlePackageFormat?.productType?._id);
      setValue("description", singlePackageFormat?.description);
      setValue("percentageAmount", singlePackageFormat?.percentageAmount);
      setValue("tax", singlePackageFormat?.tax);
      setDiscountTypeValue(singlePackageFormat?.discountType);
      setSelectedProductsData(
        singlePackageFormat?.product?.map((item) => ({
          ...item.productId,
          customPrice: item.customPrice,
        }))
      );

      setSelectedServicesData(
        singlePackageFormat?.services?.map((item) => ({
          ...item.serviceId,
          customPrice: item.customPrice,
        }))
      );

      singlePackageFormat?.product?.forEach((item) => {
        setValue(`customPrice_${item.productId._id}`, item.customPrice);
      });

      singlePackageFormat?.services?.forEach((item) => {
        setValue(`customPrice_${item.serviceId._id}`, item.customPrice);
      });

      setEnabled(
        singlePackageFormat?.isEnablePackage === "ENABLED"
          ? true
          : singlePackageFormat?.isEnablePackage === "DISABLED"
          ? false
          : null
      );
    }
  }, [singlePackage, singlePackageId]);

  const handleSearchPackage = () => {
    let attributeArray = [];
    if (searchPackage !== "") {
      if (isNaN(searchPackage)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchPackage,
        attributeArray,
        "search"
      );
      getAllPackages({ ...searchPayload });
    } else getAllPackages({});
  };

  useEffect(() => {
    handleSearchPackage();
  }, [searchPackage]);

  useEffect(() => {
    if (singlePackageId) {
      refetchSinglePackage();
    }
  }, [singlePackageId]);

  useEffect(() => {
    getAllPackages({});
  }, []);

  useEffect(() => {
    if (singlePackageId) {
      refetchSinglePackage();
    }
  }, [singlePackageId]);

  useEffect(() => {
    refetchSingleFilterPackage();
  }, [searchPackage]);

  useEffect(() => {
    if (singleFilterPackage?.result?.data?.package !== undefined) {
      setSingleProductPackage(singleFilterPackage?.result?.data?.package);
    } else {
      setSingleProductPackage([]);
    }
  }, [singleFilterPackage?.result?.data?.package]);

  const handleDisableConfirm = async () => {
    setEnabled(false);
    setIsOpenDisableConfirmDialog(false);
  };

  return {
    methods,
    handleSubmit,
    onSubmit,
    control,
    activePackageTab,
    handleTabSelection,
    packagesData,
    singlePackageId,
    handleCreatePackage,
    handleSinglePackage,
    serviceCategoryData,
    productTypeData,
    packagesDataLoader,
    singlePackageLoader,
    setSearchPackage,
    toggleSwitch,
    enabled,
    packageNameValue,
    setIsOpenDisableConfirmDialog,
    isOpenDisableConfirmDialog,
    handleDisableConfirm,

    discountTypeValue,
    setDiscountTypeValue,

    contentTypeValue,
    setContentTypeValue,
    isOpenAddContentDialog,
    setIsOpenAddContentDialog,
    setAddContentData,
    singleContentId,

    selectedServicesData,
    selectedProductsData,
    removeSelectedProduct,
    removeSelectedService,
    singleProductPackage,
  };
}
