import { Checkbox, FormControl, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

// SPACheckBox component
const SPACheckBox = ({ name, label, control, value, readOnly }) => {
  return (
    <FormControl>
      <Controller
        control={control}
        name={name}
        defaultValue={value || false}
        render={({
          field: { onChange, value },
          fieldState: { error: fieldError },
        }) => (
          <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
            <Typography
              sx={{ textWrap: "nowrap", fontSize: "12px", fontWeight: "400" }}
            >
              {label}
            </Typography>
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: "#4E45E5",
                },
              }}
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
              disabled={readOnly ? readOnly : false}
            />
            {fieldError && <Typography>Error: {fieldError.message}</Typography>}
          </Stack>
        )}
      />
    </FormControl>
  );
};

export default SPACheckBox;
