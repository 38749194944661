import React from "react";

const NoNotifications = () => {
  return (
    <svg
      width="281"
      height="280"
      viewBox="0 0 281 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M181.097 54.6069C181.097 54.6069 151.148 92.5425 103.229 69.9142C55.3104 47.2859 7.39143 108.516 48.6549 145.786C89.9184 183.056 49.539 203.536 77.5718 226.377C109.433 252.338 137.172 199.694 162.462 211.674C218.279 238.114 259.631 227.647 250.313 197.032C233.957 143.292 213.043 139.796 229.016 111.843C244.989 83.8905 209.736 25.7701 181.097 54.6069Z"
        fill="#DEE9FF"
      />
      <path
        d="M164.792 159.527L162.426 172.23L151.156 232.615L130.855 228.824L143.872 159.069L144.491 155.745L164.792 159.527Z"
        fill="#699CFF"
      />
      <path
        d="M214.787 129.149L207.788 166.677C207.767 166.787 207.725 166.892 207.664 166.986C207.603 167.08 207.524 167.16 207.432 167.224C207.339 167.287 207.235 167.331 207.126 167.354C207.016 167.377 206.903 167.379 206.793 167.358L108.449 149.016L120.024 86.9539L194.703 100.883C208.239 103.406 217.229 116.059 214.787 129.149Z"
        fill="#A2BDFF"
      />
      <path
        d="M141.029 116.555L134.084 153.792L82.4624 144.164L89.4075 106.927C91.9646 93.2164 105.594 84.2571 119.849 86.9157C134.103 89.5743 143.586 102.844 141.029 116.555Z"
        fill="#699CFF"
      />
      <path
        d="M165.062 133.962C161.257 133.252 158.748 129.555 159.464 125.716C160.18 121.876 163.854 119.332 167.658 120.042C168.87 120.269 170.001 120.814 170.935 121.621C171.869 122.427 172.572 123.467 172.974 124.634L221.026 133.596L219.728 140.556L171.676 131.594C170.88 132.537 169.85 133.254 168.688 133.67C167.526 134.085 166.275 134.186 165.062 133.962Z"
        fill="#418DF9"
      />
      <path
        d="M139.317 117.239L132.55 153.519L83.9204 144.449L90.6876 108.169C93.1789 94.8112 106.085 86.0124 119.514 88.517C132.942 91.0216 141.808 103.881 139.317 117.239Z"
        fill="#418DF9"
      />
      <path
        d="M166.424 130.446C168.379 130.446 169.964 128.861 169.964 126.905C169.964 124.95 168.379 123.365 166.424 123.365C164.469 123.365 162.884 124.95 162.884 126.905C162.884 128.861 164.469 130.446 166.424 130.446Z"
        fill="white"
      />
      <path
        d="M221.581 133.571L217.336 156.329C217.318 156.427 217.262 156.514 217.179 156.57C217.097 156.627 216.996 156.648 216.898 156.63L205.74 154.549C205.721 154.545 205.704 154.534 205.692 154.518C205.681 154.502 205.677 154.482 205.681 154.462L209.981 131.407C209.984 131.388 209.995 131.371 210.012 131.36C210.028 131.349 210.048 131.345 210.067 131.348L221.522 133.485C221.541 133.488 221.558 133.499 221.569 133.516C221.581 133.532 221.585 133.552 221.581 133.571Z"
        fill="#699CFF"
      />
      <path
        d="M100.013 131.43C100.483 134.886 104.276 138.995 104.276 138.995C98.4966 139.404 95.3666 136.136 94.0198 134.15C92.3547 134.631 90.6433 134.935 88.9143 135.056C77.0492 135.897 66.835 128.151 66.0974 117.745C65.3597 107.34 74.3792 98.2301 86.2444 97.3893C98.1095 96.5484 108.323 104.294 109.06 114.7C109.54 121.468 105.888 127.689 100.013 131.43Z"
        fill="white"
      />
      <path
        d="M134.134 153.815C133.597 155.175 123.771 195.69 91.9747 189.76C57.1079 183.857 82.3279 144.152 82.3279 144.152L134.134 153.815Z"
        fill="#A2BDFF"
      />
      <path
        d="M87.5785 119.792C89.2078 119.792 90.5286 118.471 90.5286 116.842C90.5286 115.213 89.2078 113.892 87.5785 113.892C85.9492 113.892 84.6284 115.213 84.6284 116.842C84.6284 118.471 85.9492 119.792 87.5785 119.792Z"
        fill="#699CFF"
      />
      <path
        d="M74.3717 118.506C76.0108 118.506 77.3396 117.177 77.3396 115.538C77.3396 113.899 76.0108 112.57 74.3717 112.57C72.7326 112.57 71.4038 113.899 71.4038 115.538C71.4038 117.177 72.7326 118.506 74.3717 118.506Z"
        fill="#699CFF"
      />
      <path
        d="M100.659 121.049C102.298 121.049 103.627 119.721 103.627 118.081C103.627 116.442 102.298 115.114 100.659 115.114C99.0202 115.114 97.6914 116.442 97.6914 118.081C97.6914 119.721 99.0202 121.049 100.659 121.049Z"
        fill="#699CFF"
      />
      <path
        d="M164.792 159.527L162.426 172.23L143.872 159.069L144.491 155.745L164.792 159.527Z"
        fill="#418DF9"
      />
    </svg>
  );
};

export default NoNotifications;
