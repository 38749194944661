import { Alarm, Archive, ClockCounterClockwise } from "@phosphor-icons/react";

export const communicationsTabs = [
  {
    label: "History",
    icon: ClockCounterClockwise,
    link: "History",
  },
  {
    label: "Reminders",
    icon: Alarm,
    link: "Reminders",
  },
  {
    label: "Templates Library",
    icon: Archive,
    link: "Templates Library",
  },
];
