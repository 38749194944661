import { useEffect, useState } from "react";
import { useGetAllArticlesMutation } from "../../../services/articles";
import { handleFilterSearch } from "../../../utils/searchFilter";

export default function useHistory() {
  const [activeHistoryTab, setActiveHistoryTab] = useState(0);
  const [openDialogCreateArticle, setOpenDialogCreateArticle] = useState(false);
  const [articleType, setArticleType] = useState("");
  const [searchArticle, setSearchArticle] = useState("");
  const [articleId, setArticleId] = useState();

  const [
    getAllArticles,
    { data: articlesData, isLoading: articlesDataLoading },
  ] = useGetAllArticlesMutation();

  const handleTabSelection = (item) => {
    setActiveHistoryTab(item);
    switch (item) {
      case 1:
        setArticleType("PUBLISHED");
        break;
      case 2:
        setArticleType("UNPUBLISHED");
        break;
      default:
        setArticleType("");
        break;
    }
  };

  const handleCloseCreateArticle = () => {
    setOpenDialogCreateArticle(false);
  };

  const handleCreateArticle = () => {
    setOpenDialogCreateArticle(!openDialogCreateArticle);
  };

  const handleSearchArticle = () => {
    let attributeArray = [];
    if (searchArticle !== "") {
      if (isNaN(searchArticle)) attributeArray = ["title", "status"];
      else {
        attributeArray = ["title", "status"];
      }

      const searchPayload = handleFilterSearch(
        searchArticle,
        attributeArray,
        "search"
      );
      getAllArticles({ ...searchPayload });
    } else getAllArticles({});
  };

  useEffect(() => {
    handleSearchArticle();
  }, [searchArticle]);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      [`status=${articleType}`],
      ["status"],
      "filter",
      "and"
    );
    if (articleType !== "") {
      getAllArticles({ ...searchPayload });
    } else {
      getAllArticles();
    }
  }, [articleType]);

  useEffect(() => {
    getAllArticles();
  }, []);

  return {
    activeHistoryTab,
    handleTabSelection,
    handleCreateArticle,
    openDialogCreateArticle,
    handleCloseCreateArticle,
    articlesData,
    articlesDataLoading,
    setSearchArticle,
    getAllArticles,
    articleId,
    setArticleId,
  };
}
