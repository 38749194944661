import SPAInputField from "../../../hook-form/SPAInputField";
import SPAMobileTimePicker from "../../../hook-form/SPAMobileTimerPicker";
import SPASelectField from "../../../hook-form/SPASelectField";

export const newRoomFormData = (singleRoomId, isServices, serviceData) => {
  let finalData = [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "name",
        label: "Zone Name",
        placeholder: "i.e.: Japanese Relax",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "status",
        label: "Status",
        options: [
          { label: "Active", value: "ACTIVE" },
          { label: "Inactive", value: "INACTIVE" },
          { label: "Draft", value: "DRAFT" },
        ],
        placeholder: "Select Status",
      },
      component: SPASelectField,
    },
    {
      key: "description",
      gridLength: 12,
      otherOptions: {
        name: "description",
        label: "Description",
        labelHelperText: "(Optional)",
        type: "textarea",
        placeholder: "Add the zone description here",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "services",
        label: "This zone can only service the following treatments",
        labelHelperText: "(Optional)",
        type: "multiple",
        isChips: true,
        options:
          serviceData &&
          serviceData?.map((item) => {
            return { label: item.name, value: item.name, id: item._id };
          }),
        placeholder: "Select Services",
      },
      component: SPASelectField,
    },
  ];
  return finalData;
};

export const busyTimFormData = (RepeatOnGrid) => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        name: "startTime",
        label: "From",
        placeholder: "Select Time",
      },
      component: SPAMobileTimePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "endTime",
        label: "To",
        placeholder: "Select Time",
      },
      component: SPAMobileTimePicker,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "repeatOn",
        label: "Repeat On",
      },
      component: RepeatOnGrid,
    },
    {
      key: "reason",
      gridLength: 12,
      otherOptions: {
        name: "reason",
        label: "Reason",
        options: [
          { label: "Maintenance", value: "MAINTENACE" },
          { label: "Cleaning", value: "CLEANING" },
          { label: "Time Off", value: "TIME_OFF" },
          { label: "Holiday Closure", value: "HOLIDAY_CLOUSER" },
          { label: "Special Events", value: "SEPICAL_EVENTS" },
          { label: "Shift Staff", value: "SHIFT_STAFF" },
          { label: "Custom Reason", value: "CUSTOM_REASON" },
        ],
        placeholder: "Select Reason",
      },
      component: SPASelectField,
    },
    {
      key: "additionalNotes",
      gridLength: 12,
      otherOptions: {
        name: "note",
        label: "Additional Notes",
        labelHelperText: "(Optional)",
        type: "textarea",
        placeholder: "Add any other additional notes here",
      },
      component: SPAInputField,
    },
  ];
};

export const weekDays = [
  { label: "MON", value: "MON" },
  { label: "TUE", value: "TUE" },
  { label: "WED", value: "WED" },
  { label: "THU", value: "THU" },
  { label: "FRI", value: "FRI" },
  { label: "SAT", value: "SAT" },
  { label: "SUN", value: "SUN" },
];
