import { baseAPI } from "../baseApi";

export const QualificationsApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getQualifications: builder.mutation({
      query: (body) => ({ url: "/qualification", method: "PATCH", body }),
      providesTags: ["Qualifications"],
    }),
    createQualification: builder.mutation({
      query: (payload) => ({
        url: "/qualification",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Qualifications"],
    }),
    getSingleQualification: builder.query({
      query: (qualificationId) => ({
        url: "/qualification/" + qualificationId,
      }),
      providesTags: ["singleQualification"],
    }),
    updateSingleQualification: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/qualification/" + id,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Qualifications"],
    }),
    addQualifications: builder.mutation({
      query: ({ id, payload }) => ({
        url: "/qualification/" + id,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Qualifications"],
    }),
  }),
});

export const {
  useGetSingleQualificationQuery,
  useCreateQualificationMutation,
  useUpdateSingleQualificationMutation,
  useAddQualificationsMutation,
  useGetQualificationsMutation,
} = QualificationsApi;
