import { Box, Button, FormHelperText, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '../../../assets/svg/error-icon';
import RightArrow from '../../../assets/svg/right-arrow-icon';
import FormProvider from '../../hook-form/FormProvider';
import SPAInputField from '../../hook-form/SPAInputField';
import useLogin from '../login/useLogin';

const ResetPasswordComponent = () => {
  const navigate = useNavigate();
  // for ui purposes use these methods from login we will make this own hook for this component
  const { methods, control, handleSubmit, onSubmit, responseError } =
    useLogin();
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <SPAInputField
            name='password'
            label='Password'
            type='password'
            placeholder='*******'
          />

          <SPAInputField
            name='confirm_password'
            label='Confirm Password'
            type='password'
            placeholder='*******'
          />
        </Box>
        {responseError && (
          <Box sx={{ display: 'flex', gap: '4px', marginTop: '20px' }}>
            <ErrorIcon />
            <FormHelperText sx={{ margin: '0px', padding: '0px' }} error>
              {responseError}
            </FormHelperText>
          </Box>
        )}
        <Button
          onClick={() => navigate('/login')}
          type='submit'
          sx={{
            display: 'flex',
            height: '48px',
            padding: '0px 24px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '8px',
            background: '#4E45E5',
            color: '#FFF',
            width: '100%',
            mt: responseError ? '10px' : '40px',
            '&:hover': {
              backgroundColor: '#2F2989',
              color: '#FFF',
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: '"Open Sans"',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              letterSpacing: '0.175px',
              textTransform: 'uppercase',
            }}
          >
            Reset Password
          </Typography>
          <RightArrow />
        </Button>
      </FormProvider>
      <Box
        sx={{
          mt: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '8px',
        }}
      ></Box>
    </>
  );
};

export default ResetPasswordComponent;
