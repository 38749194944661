import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Warning } from "@phosphor-icons/react";
import { Controller } from "react-hook-form";
import ErrorIcon from "../../assets/svg/error-icon";
import { customColors, fs14, fw400, secondary } from "../../theme";
import SPATextField from "../common/spa-textField";
import { HelperText } from "../../styleComponents/style";
import NoClients from "../../assets/svg/no-clients";

const SPASelectField = ({
  name,
  label,
  type,
  placeholder,
  helperText,
  error,
  control,
  readOnly,
  value,
  isChips = false,
  labelHelperText,
  ...rest
}) => {
  const handleChipClick = (event, chipValue) => {
    event.stopPropagation();
  };

  const renderLabel = (selectedValue) => {
    const selectedOptions = selectedValue.map((selectedItem) => {
      const option = rest?.options?.find(
        (option) => option.value === selectedItem || option.id === selectedItem
      );
      return option ? option.label : null;
    });

    return selectedOptions ? (
      <Box sx={{ display: "flex", gap: "8px" }}>
        {isChips &&
          selectedOptions?.map((item) => (
            <Chip
              key={item}
              label={item}
              size="small"
              sx={{
                bgcolor: "#EDECFC",
                color: "#070A0E",
                fontSize: "14px",
                p: "8px 2px 8px 2px",
              }}
              onDelete={(e) => handleChipClick(e, item)}
            />
          ))}
        {!isChips && selectedOptions.join(", ")}
      </Box>
    ) : (
      <span style={{ color: "#D0D5DD" }}>{placeholder}</span>
    );
  };

  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={name}
        defaultValue={value || (type === "multiple" ? [] : "")}
        render={({ field, fieldState: { error: fieldError } }) => (
          <>
            {type === "multiple" ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "6px" }}
              >
                {label && (
                  <FormLabel
                    sx={{
                      color: "#5E6062",
                      fontWeight: fw400,
                      fontSize: fs14,
                      whiteSpace: "nowrap",
                      display: "flex",
                    }}
                  >
                    {label}
                    {!labelHelperText && (
                      <Typography sx={{ color: "#D13438", ml: "3px" }}>
                        {" "}
                        *
                      </Typography>
                    )}
                    {/* {labelHelperText && (
                      <HelperText>{labelHelperText}</HelperText>
                    )} */}
                  </FormLabel>
                )}
                <Select
                  error={!!fieldError}
                  IconComponent={ExpandMore}
                  multiple
                  {...rest}
                  input={<OutlinedInput />}
                  displayEmpty
                  renderValue={(value) => (
                    <>
                      {value.length === 0 ? (
                        <span style={{ color: "#8F9193" }}>{placeholder}</span>
                      ) : (
                        renderLabel(value)
                      )}
                    </>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        background: customColors.white,
                        border: "1px solid #DADADB",
                        borderRadius: "8px",
                        boxShadow: "0px 12px 15px -4px rgba(0, 0, 0, 0.3)",
                        maxHeight: "250px",
                      },
                    },
                  }}
                  sx={{
                    backgroundColor: customColors.white,
                    borderRadius: "6px !important",
                    height: "44px !important",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${secondary.main} !important`,
                      borderWidth: "1px !important",
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFAA33  !important",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#D0D5DD",
                      opacity: 1,
                      fontSize: "14px",
                      fontWeight: "400px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${secondary.main} !important`,
                    },
                  }}
                  {...field}
                >
                  {rest?.options?.length === 0 && (
                    <MenuItem disabled value={null}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          height: "15vh",
                          width: "100%",
                        }}
                      >
                        <NoClients />
                        <Typography sx={{ fontSize: "16px", color: "#595959" }}>
                          No Data Found
                        </Typography>
                      </Box>
                    </MenuItem>
                  )}
                  {rest?.options?.length > 0 &&
                    rest?.options?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText
                          primary={option.label}
                          primaryTypographyProps={{
                            fontSize: fs14,
                            fontWeight: fw400,
                            color: secondary.main,
                          }}
                        />
                      </MenuItem>
                    ))}
                </Select>
                {fieldError && (
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      fontSize: "12px",
                      color: "#FFAA33",
                    }}
                  >
                    <Warning size={16} weight="fill" /> {fieldError?.message}
                  </Typography>
                )}
              </Box>
            ) : (
              <SPATextField
                error={!!fieldError}
                label={label}
                {...field}
                {...rest}
                type={type}
                placeholder={placeholder}
                disableunderline={true}
                control={control}
                readOnly={readOnly}
                labelHelperText={labelHelperText}
                helperText={
                  fieldError && (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        fontSize: "12px",
                        color: "#FFAA33",
                      }}
                    >
                      <Warning size={16} weight="fill" /> {fieldError?.message}
                    </Typography>
                  )
                }
                select
              />
            )}
          </>
        )}
      />

      {error && error[name] && error[name].message && (
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <ErrorIcon />
          <FormHelperText
            sx={{ margin: "0px", padding: "0px" }}
            style={{ color: customColors.warningError }}
            error
          >
            {error[name]?.message}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
};

export default SPASelectField;
