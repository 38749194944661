import { Box, List } from "@mui/material";
import React from "react";
import { changePassword, profileData } from "../profile-data";
import SPASideBarListButton from "../../common/spa-sidebar-list-button";

export default function SideBar({ setActiveItem, activeItem }) {
  return (
    <List
      component="nav"
      sx={{
        width: "auto",
        p: "16px 24px",
        background: "#F8F7FE",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <SPASideBarListButton
          key={"profile"}
          item={profileData}
          activeItem={activeItem}
          isLableShow={true}
          onClick={() => {
            setActiveItem(profileData.link);
          }}
        />
      </Box>

      <Box>
        <SPASideBarListButton
          key={"Change Password"}
          item={changePassword}
          activeItem={activeItem}
          isLableShow={true}
          onClick={() => {
            setActiveItem(changePassword.link);
          }}
        />
      </Box>
    </List>
  );
}
