import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useCreateAdditionNoteByIdMutation,
  useDeleteContraindicationByIdMutation,
  useGetSingleClientQuery,
  useUpdateContraindicationByIdMutation,
  useUpdateNoteByIdMutation,
} from "../../../../../../services/clients";
import { calculateTimeDifferenceWithCurrentTime } from "../../../../../../utils/convertTimeIOSFormat";
import AdditionalNote from "../../../../../addition-note-card";
import SPAContraindatCard from "../../../../../common/spa-contraditcation-card";

export default function useClientAdditionalNotes() {
  const clientDetail = useSelector((state) => state.clientDetail.clientDetail);

  //api working
  const { data: singleClient, isLoading: clientLoading } =
    useGetSingleClientQuery(clientDetail._id);
  const [updateContraindicationById, { isLoading: isLoadingUpdateContra }] =
    useUpdateContraindicationByIdMutation();
  const [addNotesByClientId, { isLoading: isLoadingCreateNote }] =
    useCreateAdditionNoteByIdMutation();
  const [updateNotes, { isLoading: isLoadingUpdateNote }] =
    useUpdateNoteByIdMutation();
  const [deleteContraindication] = useDeleteContraindicationByIdMutation();

  // useStates
  const [allNotes, setAllNotes] = useState([]);
  const [contraindicationArrayData, setContraindicationArrayData] = useState(
    []
  );
  const [contraindicationContent, setContraindicationContent] = useState("");
  const [isEnableUpdateContraindication, setIsEnableUpdateContraindication] =
    useState(true);
  const [additionalNoteContent, setAdditionalNoteContent] = useState("");
  const [isEnableCreateAdditionalContent, setIsEnableCreateAdditionalContent] =
    useState(true);
  const [noteError, setNoteError] = useState(false);

  // add new note
  const handleCreateAdditionalNotes = async () => {
    const inputValue = additionalNoteContent.trim();
    if (inputValue === "") {
      setNoteError(true);

      return;
    } else {
      setNoteError(false);
      const enteredValue = additionalNoteContent;
      const payload = { note: enteredValue, isPinned: false, type: "CLIENT" };

      try {
        const resp = await addNotesByClientId({
          id: clientDetail._id,
          payload,
        }).unwrap();

        if (resp.status) {
          setAdditionalNoteContent("");
          toast.success("New Note created successfully");
        }
      } catch (error) {
        toast.error(error.error);
      }

      setAdditionalNoteContent("");
    }
  };

  const handleAdditionalNoteContent = (event) => {
    let textValue = event.target.value;
    setAdditionalNoteContent(event.target.value);
    if (textValue.trim() !== "") {
      setIsEnableCreateAdditionalContent(false);
    } else setIsEnableCreateAdditionalContent(true);
  };

  //update Contraindication

  const handleContraindicationContent = (event) => {
    let textValue = event.target.value;
    let perviousText = contraindicationContent;
    setContraindicationContent(event.target.value);
    if (textValue.trim() !== "" && perviousText !== textValue) {
      setIsEnableUpdateContraindication(false);
    } else setIsEnableUpdateContraindication(true);
  };

  const handleContraindicationUpdate = useCallback(async () => {
    const resp = await updateContraindicationById({
      id: clientDetail?._id,
      payload: {
        contraindication: [{ text: contraindicationContent }],
      },
    });
    if (resp.data?.status) {
      toast.success(resp.data?.message);
    } else toast.error(resp.data.message);
  }, [contraindicationContent]);

  const handleDeleteContraindication = async () => {
    const resp = await deleteContraindication({
      id: clientDetail?._id,
    });
    if (resp.data?.status) {
      toast.success(resp.data?.message);
    } else toast.error(resp.data.message);
    setContraindicationContent("");
  };

  // functionality to change pin of notes
  const handlePineNotes = async (item) => {
    try {
      const response = await updateNotes({
        id: item.id,
        payload: {
          isPinned: !item.isPinned,
          type: "CLIENT",
          note: item.content,
        },
      }).unwrap();
      if (response.status) {
        toast.success("Note Pin Preference Updated Successfully");
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const handleInitialValues = () => {
    if (!singleClient) return;

    const { data } = singleClient.result || {};
    if (!data) return;

    const { user } = data;
    const { notes } = user;

    if (user.contraindication) {
      let contraindicationArray = user.contraindication;

      if (contraindicationArray?.length > 0) {
        const currentContraindication = contraindicationArray.find(
          (item) => item.isLatest
        );
        const outdatedContraindications = contraindicationArray.filter(
          (item) => !item.isLatest
        );

        // Sort outdated contraindications by createdAt time
        outdatedContraindications.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        // Create an array with current contraindication at index 0
        const contraindicationUpdateArray = [];
        if (currentContraindication?.text) {
          setContraindicationContent(currentContraindication.text);
          contraindicationUpdateArray.push({
            component: SPAContraindatCard,
            otherOptions: {
              isLatest: "Current",
              updated: calculateTimeDifferenceWithCurrentTime(
                currentContraindication.createdAt
              ),
              text: currentContraindication.text,
              id: currentContraindication._id,
            },
          });
        }

        // Concatenate current and sorted outdated contraindications
        const sortedContraindications = contraindicationUpdateArray.concat(
          outdatedContraindications.map((contraindicationHistory) => ({
            component: SPAContraindatCard,
            otherOptions: {
              isLatest: "Outdated",
              updated: calculateTimeDifferenceWithCurrentTime(
                contraindicationHistory.createdAt
              ),
              text: contraindicationHistory.text,
              id: contraindicationHistory._id,
            },
          }))
        );

        setContraindicationArrayData(sortedContraindications);
      }
    }

    if (notes?.length > 0) {
      const notesArray = notes.map((noteItem) => {
        const addedDate = calculateTimeDifferenceWithCurrentTime(
          noteItem.createdAt
        );
        return {
          component: AdditionalNote,
          otherOptions: {
            content: noteItem.note,
            id: noteItem._id,
            isPinned: noteItem.isPinned,
            added: addedDate,
          },
        };
      });
      setAllNotes(notesArray);
    }
  };

  useEffect(() => {
    handleInitialValues();
  }, [singleClient]);

  return {
    handleAdditionalNoteContent,
    handleCreateAdditionalNotes,
    isPinnedNotesArray: allNotes.filter((item) => item.otherOptions.isPinned),
    notPinnedNotesArray: allNotes.filter((item) => !item.otherOptions.isPinned),
    noteError,
    additionalNoteContent,
    handlePineNotes,
    isLoadingCreateNote,
    isLoadingUpdateNote,
    isLoadingUpdateContra,
    isEnableCreateAdditionalContent,
    isEnableUpdateContraindication,
    handleContraindicationContent,
    handleContraindicationUpdate,
    contraindicationContent,
    contraindicationArrayData,
    handleDeleteContraindication,
  };
}
