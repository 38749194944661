import { baseAPI } from "../baseApi";

export const TemplatesApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllTemplates: builder.query({
      query: (templateType) => ({ url: "/template?type=" + templateType }),
      providesTags: ["Templates"],
    }),
    getTemplates: builder.mutation({
      query: (payload) => ({
        url: "/template",
        method: "PATCH",
        body: payload,
      }),
    }),
    createTemplate: builder.mutation({
      query: (payload) => ({
        url: "/template",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Templates"],
    }),
    updateTemplate: builder.mutation({
      query: ({ id, payload }) => ({
        url: "/template/" + id,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Templates"],
    }),
    getSingleTemplate: builder.query({
      query: (templateId) => ({ url: "/template/" + templateId }),
      providesTags: ["singleTemplate"],
    }),
    deleteTemplate: builder.mutation({
      query: (templateId) => ({
        url: "/template/" + templateId,
        method: "DELETE",
      }),
      providesTags: ["Templates"],
    }),
  }),
});

export const {
  useGetAllTemplatesQuery,
  useGetTemplatesMutation,
  useGetSingleTemplateQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
} = TemplatesApi;
