import * as Yup from "yup";

export const validationSchema = (templateType) => {
  let schema = Yup.object().shape({
    name: Yup.string().required("Template Name is required"),
    message: Yup.string().required("Message is required"),
  });
  if (templateType === "EMAIL") {
    schema = schema.shape({
      // replyTo: Yup.string().email("Invalid email format"),
      subject: Yup.string().required("Subject is required"),
    })
  }
  return schema;
};
