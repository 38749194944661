import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { customColors, secondary } from "../../../theme/pallete.js";
import SPADialogue from "../../common/spa-dialogue/index.jsx";
import {
  availableSlotType,
  busyTimeTypeData,
  weekDays,
} from "../calendarData.js";
import {
  createBusyTimesFormData,
  oneTimeFormData,
  recurringFormData,
} from "./createBusyTimesData.js";
import {
  busyTimeModelStyle,
  repeatOnBoxStyle,
} from "./createBusyTimesStyle.js";
import useCreateBusyTimes from "./useCreateBusyTimes";

export default function CreateBusyTimesModel({
  busyTimeModel,
  handleClose,
  setSuccessCreateModel,
  selectedSlotType,
  selectedSlotData,
}) {
  const {
    control,
    servicesData,
    usersData,
    roomData,
    methods,
    handleSubmit,
    onSubmit,
    busyTimeType,
    setBusyTimeType,
    availableType,
    setAvailableType,
    repeatOn,
    setRepeatOn,
    isLoading,
    errors,
  } = useCreateBusyTimes(
    handleClose,
    setSuccessCreateModel,
    selectedSlotType,
    selectedSlotData
  );

  const formData = createBusyTimesFormData(
    servicesData,
    usersData?.result?.data?.data,
    roomData,
    availableType
  );
  return (
    <SPADialogue
      open={busyTimeModel}
      loading={isLoading}
      handleClose={handleClose}
      dialogTitle={
        <>
          Create Busy Times
          <Typography
            sx={{ fontSize: "14px", fontWeight: "400", color: "#5E6062" }}
          >
            During busy times, clients won’t be able to book at the selected
            times.
          </Typography>
        </>
      }
      cancelText="Cancel"
      submitText="Create Busy Times"
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      methods={methods}
    >
      <Box sx={busyTimeModelStyle.calendarHeaderMain}>
        <Box sx={busyTimeModelStyle.selectionGrid}>
          {busyTimeTypeData.map((item) => {
            return (
              <Box
                key={item.value}
                onClick={() => {
                  setBusyTimeType(item.value);
                }}
                sx={{
                  background: busyTimeType === item.value ? secondary.main : "",
                  color:
                    busyTimeType === item.value
                      ? customColors.white
                      : secondary.main,
                  ...busyTimeModelStyle.gridView,
                }}
              >
                <item.icon size={24} /> {item.label}
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            width: "688px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            p: "16px 12px",
          }}
        >
          <Grid container spacing={4}>
            {(busyTimeType === "ONE TIME"
              ? oneTimeFormData()
              : recurringFormData()
            )?.map((item, index) => {
              return (
                <Grid item lg={item.gridLength} xs={12} key={index}>
                  <item.component {...item.otherOptions} control={control} />
                </Grid>
              );
            })}
            <Grid>
              {busyTimeType === "RECURRING" && (
                <Box sx={{ ml: "32px", mt: "16px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#5E6062",
                      mb: "8px",
                    }}
                  >
                    Repeat On
                  </Typography>
                  <Box sx={repeatOnBoxStyle.calendarHeaderMain}>
                    <Box sx={repeatOnBoxStyle.selectionGrid}>
                      {weekDays.map((item) => {
                        const isSelected = repeatOn.includes(item?.value);
                        return (
                          <Box
                            key={item.value}
                            onClick={() => {
                              if (isSelected) {
                                setRepeatOn(
                                  repeatOn.filter((day) => day !== item.value)
                                );
                              } else {
                                setRepeatOn([...repeatOn, item.value]);
                              }
                            }}
                            sx={{
                              background: isSelected ? secondary.main : "",
                              color: isSelected
                                ? customColors.white
                                : secondary.main,
                              ...repeatOnBoxStyle.gridView,
                            }}
                          >
                            {item.label}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Typography
        sx={{ fontSize: "14px", fontWeight: "400", m: "24px 0px 8px 0px" }}
      >
        What’s not going to be available?
      </Typography>
      <Box sx={busyTimeModelStyle.calendarHeaderMain}>
        <Box sx={busyTimeModelStyle.selectionGrid}>
          {availableSlotType.map((item) => {
            return (
              <Box
                key={item.value}
                onClick={() => {
                  setAvailableType(item.value);
                }}
                sx={{
                  background:
                    availableType === item.value ? secondary.main : "",
                  color:
                    availableType === item.value
                      ? customColors.white
                      : secondary.main,
                  ...busyTimeModelStyle.gridView,
                }}
              >
                <item.icon size={24} /> {item.label}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          width: "688px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mt: "24px",
          pr: "8px",
        }}
      >
        <Grid container spacing={4}>
          {formData.map((item, index) => {
            return (
              <Grid item lg={item.gridLength} xs={12} key={index}>
                <item.component {...item.otherOptions} control={control} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </SPADialogue>
  );
}
