import SPAInputField from "../../../hook-form/SPAInputField";
import SPASelectField from "../../../hook-form/SPASelectField";

export const smsFormData = (templatesData) => {
  return [
    {
      gridLength: 3,
      otherOptions: {
        fullWidth: true,
        name: "client",
        label: "Client",
        placeholder: "John Doe",
        disabled: true,
      },
      component: SPAInputField,
    },
    {
      gridLength: 3,
      otherOptions: {
        fullWidth: true,
        name: "phoneNumber",
        label: "Client’s Mobile Number",
        placeholder: "",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "template",
        label: "Template",
        placeholder: "Select Template",
        labelHelperText: "(Optional)",
        options:
          templatesData &&
          templatesData.map((item) => {
            return { label: item.name, value: item.name, id: item._id };
          }),
      },
      component: SPASelectField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "message",
        label: "Message",
        placeholder: "Write Message",
        type: "textarea",
        multiline: true,
        rows: 12,
      },
      component: SPAInputField,
    },
  ];
};
