import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { customColors, fs14, fw600 } from "../../../../../../../../theme";
import theme from "../../../../../../../../theme/theme";

const ToggleComponent = ({
  options,
  correctOption,
  defaultOption,
  label,
  dropDownSelect,
  control,
}) => {
  const [currentOption, setCurrentOption] = useState(defaultOption);

  return (
    <Box>
      <Typography sx={{ fontSize: fs14, paddingBlock: "8px" }}>
        {label}
      </Typography>
      {/* <Box
        sx={{
          bgcolor: "#F8F7FE",
          borderRadius: "8px",
          border: "2px solid green",
          padding: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      > */}
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "8px",
          borderRadius: "8px",
          bgcolor: "#EDECFC",
        }}
      >
        {options?.map((option, index) => {
          return (
            <Box
              onClick={() => {
                setCurrentOption(option.value);
              }}
              key={index}
              sx={{
                paddingInline: "24px",
                paddingBlock: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
                fontWeight: fw600,
                background:
                  currentOption === option?.value
                    ? "#4E45E5"
                    : theme.palette.primaryColor.lightHover,
                color:
                  currentOption === option?.value
                    ? customColors.white
                    : "#4E45E5",
                gap: "15px",
                fontSize: "12px",
                borderRadius: "8px",

                "&:hover": {
                  cursor: "pointer",
                  background: theme.palette.primaryColor.lightHover,
                  color: "#4E45E5",
                },
              }}
            >
              {option?.suffixIcon && (
                <Box sx={{ fontSize: "16px", fontWeight: "600" }}>
                  {option?.suffixIcon}
                </Box>
              )}

              <Box>{option?.value}</Box>
            </Box>
          );
        })}
        {currentOption === correctOption && dropDownSelect && (
          <Grid
            item
            lg={dropDownSelect?.gridLength}
            xs={12}
            sx={{ marginBottom: "16px" }}
          >
            {dropDownSelect.component && (
              <dropDownSelect.component
                {...dropDownSelect?.otherOptions}
                control={control}
              />
            )}
          </Grid>
        )}
      </Grid>
      {/* </Box> */}
    </Box>
  );
};

export default ToggleComponent;
