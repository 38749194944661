import { useForm } from "react-hook-form";
import { validationSchemaCoupon } from "./form-validation";
import { useGetServicesMutation } from "../../../../services/services";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import {
  calculateDayDifference,
  convertTo12HourFormat,
} from "../../../../utils/convertTimeIOSFormat";
import {
  useCreateCouponMutation,
  useGetSingleCouponQuery,
  useUpdateSingleCouponMutation,
} from "../../../../services/coupon";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useGetProductsMutation } from "../../../../services/product";
import { handleFilterSearch } from "../../../../utils/searchFilter";

export default function useCreateCoupon(handleClose, id, couponType) {
  const initialValues = {
    startDate: "", // Add your initial values for other fields as needed
    endDate: "",
    discount: "",
    discountType: "DOLLAR",
    customCoupon: "auto", // Initial value for customCoupon
  };
  const methods = useForm({
    resolver: yupResolver(validationSchemaCoupon(couponType)),
    defaultValues: initialValues,
  });

  const [endDateStarting, setEndDateStarting] = useState(1);
  const [previousStartDate, setPreviousStartDate] = useState("");

  // form
  const { handleSubmit, control, reset, watch, setValue } = methods;

  const { startDate, customCoupon } = watch();
  // api working
  const [createCoupon, { isLoading: createCouponLoading }] =
    useCreateCouponMutation();
  const [updateCoupon, { isLoading: updateCouponLoading }] =
    useUpdateSingleCouponMutation();
  const { data: singleCouponData } = useGetSingleCouponQuery(id);
  const [getAllServices, { data: serviceData }] = useGetServicesMutation();
  const [getAllProducts, { data: productData }] = useGetProductsMutation();
  const listingData =
    couponType === "Product"
      ? productData?.result?.data?.data
      : serviceData?.result?.data?.data;

  const onSubmit = async (values) => {
    const payload = {
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      services: values.services ? values.services : null,
      products: values.products ? values.products : null,
      discount: Number(values.discount),
      discountType: values?.discountType,
      type: couponType === "Product" ? "PRODUCTS" : "SERVICES",
    };
    if (customCoupon === "custom") {
      payload["coupon"] = values?.code;
    }
    if (id) {
      const response = await updateCoupon({ id, payload });
      if (response.data.status) toast.success(response.data.message);
      else toast.error(response.data.message);
    } else {
      const response = await createCoupon(payload);
      if (response.data.status) toast.success(response.data.message);
      else toast.error(response.data.message);

      reset();
      handleClose();
    }
  };

  useMemo(() => {
    const currentDate = dayjs();
    let dayIC = currentDate.isSame(startDate, "day") ? 1 : 2;
    setEndDateStarting(calculateDayDifference(startDate) + dayIC);

    if (!dayjs(startDate).isSame(previousStartDate, "day") || !id) {
      setValue("endDate", "");
    }
  }, [startDate]);

  const handleInitialValues = () => {
    if (!singleCouponData) return;

    const { data } = singleCouponData.result || {};
    if (!data) return;

    Object.entries(data).forEach(([key, value]) => {
      if (key === "startTime" || key === "endTime") {
        let time = value.split("T");
        time =
          convertTo12HourFormat(time[1]).split(" ")[0].length > 4
            ? convertTo12HourFormat(time[1])
            : `0${convertTo12HourFormat(time[1])}`;
        setValue(key, time);
      } else {
        if (key === "startDate") setPreviousStartDate(value);
        setValue(key, value);
      }
    });
  };

  useEffect(() => {
    if (id) {
      handleInitialValues();
    }
  }, [singleCouponData]);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      ["status=ACTIVE"],
      ["status"],
      "filter"
    );
    getAllServices();
    getAllProducts({});
  }, []);

  return {
    methods,
    control,
    onSubmit,
    handleSubmit,
    endDateStarting,
    buttonLoading: createCouponLoading || updateCouponLoading,
    listingData,
    customCoupon,
  };
}
