import * as Yup from "yup";

export const validationSchema = () => {
  let schema = Yup.object().shape({
    client: Yup.string().required("Client is required"),
    recipients: Yup.string()
      .required("Client Email is required")
      .email("Invalid email format"),
    template: Yup.string(),
    replyToEmails: Yup.string()
      .required("Reply to email is required")
      .email("Invalid email format"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
    saveEmail: Yup.boolean(),
  });

  return schema;
};
