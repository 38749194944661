import { Box, Grid } from '@mui/material';
import React from 'react';
import SPAInputField from '../../components/hook-form/SPAInputField';
import SPASelectField from '../../components/hook-form/SPASelectField';
import useLogin from '../../components/auth/login/useLogin';

const AllComponent = () => {
  const { control } = useLogin();
  return (
    <Box>
      AllComponent
      <Grid container spacing={4} justifyContent={'center'}>
        <Grid item md={5}>
          input for text , number and passwrod:
          <SPAInputField
            name='email'
            label='Email'
            type='email'
            placeholder='email@email.com'
            error={{ message: 'Please enter' }}
            control={control}
          />
        </Grid>
        <Grid item md={5}>
          select input :
          <SPASelectField
            name='email'
            label='Email'
            options={[
              { label: 'John Doe', value: 'John Doe' },
              { label: 'aline', value: 'aline' },
            ]}
            placeholder='email@email.com'
            error={{ message: 'Please enter' }}
            control={control}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllComponent;
