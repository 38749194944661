import React from 'react';

const PinnedIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.7075 5.08547L10.9144 1.29297C10.8215 1.20009 10.7113 1.12641 10.5899 1.07614C10.4686 1.02587 10.3385 1 10.2072 1C10.0758 1 9.94578 1.02587 9.82444 1.07614C9.7031 1.12641 9.59285 1.20009 9.49999 1.29297L6.14811 4.65547C5.48186 4.44672 3.96061 4.19485 2.37311 5.47672C2.26369 5.56471 2.17402 5.67475 2.10995 5.79969C2.04588 5.92463 2.00883 6.06166 2.00122 6.20186C1.99362 6.34207 2.01562 6.4823 2.06581 6.61343C2.11599 6.74457 2.19323 6.86367 2.29249 6.96297L5.31249 9.98173L2.64624 12.6461C2.55242 12.7399 2.49971 12.8672 2.49971 12.9999C2.49971 13.1325 2.55242 13.2598 2.64624 13.3536C2.74006 13.4474 2.8673 13.5001 2.99999 13.5001C3.13267 13.5001 3.25992 13.4474 3.35374 13.3536L6.01811 10.6874L9.03624 13.7055C9.12902 13.7986 9.23927 13.8726 9.36067 13.9231C9.48207 13.9736 9.61224 13.9997 9.74374 13.9999C9.76749 13.9999 9.79061 13.9999 9.81436 13.9999C9.95691 13.99 10.0957 13.9495 10.2212 13.8813C10.3467 13.813 10.4561 13.7185 10.5419 13.6042C11.7694 11.973 11.6512 10.6467 11.3662 9.85423L14.7081 6.49985C14.801 6.40695 14.8746 6.29666 14.9248 6.1753C14.975 6.05394 15.0008 5.92387 15.0008 5.79253C15.0007 5.66119 14.9748 5.53115 14.9245 5.40983C14.8741 5.28851 14.8004 5.1783 14.7075 5.08547ZM14 5.79297L10.4206 9.38422C10.3467 9.45842 10.2979 9.55396 10.2812 9.65735C10.2645 9.76074 10.2807 9.86677 10.3275 9.96047C10.9187 11.1436 10.215 12.3724 9.74374 12.9992L2.99999 6.25485C3.75499 5.6461 4.47749 5.48547 5.02999 5.48547C5.38057 5.48069 5.72825 5.54946 6.05061 5.68735C6.14466 5.73442 6.25115 5.75063 6.35494 5.73369C6.45873 5.71674 6.55454 5.66751 6.62874 5.59297L10.2075 1.99985L14 5.79235V5.79297Z'
        fill='#4E45E5'
      />
    </svg>
  );
};

export default PinnedIcon;
