import { Button, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Gift, Percent } from "@phosphor-icons/react";
import React, { useState } from "react";
import GreaterThenIcon from "../../../../../assets/svg/greaterthen-icon";
import SPATextField from "../../../../common/spa-textField";
import FormProvider from "../../../../hook-form/FormProvider";
import SPAMobileDatePicker from "../../../../hook-form/SPAMobileDatePicker";
import ProductSaleCard from "../product-sale-card";
import { salesFormData } from "../sales-form-data";
import { SubmitBtn } from "../salesStyle";
import useSales from "../useSales";

// sale card products
const products = [
  {
    id: 1,
    imageSrc: "/images/alecs.png",
    category: "Electronics",
    productName: "Smartphone",
    seller: "ABC Electronics",
    unitPrice: 499.99,
  },
  {
    id: 2,
    imageSrc: "/images/alecs.png",
    category: "Clothing",
    productName: "T-Shirt",
    seller: "XYZ Fashion",
    unitPrice: 19.99,
  },
  // Add more products as needed
];

export default function SaleModelContent({ showCart, clientDetail }) {
  const [showSaleCard, setShowSaleCard] = useState(false);
  const { control, handleSubmit, onSubmit, methods } = useSales({
    clientDetail,
  });

  return (
    <FormProvider
      style={{ height: "100%" }}
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "100%",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: { md: "60%", sm: "100%" },
            padding: "16px",
            flexDirection: "column",
            gap: "16px",
            borderRadius: "16px",
            background: "#F8F7FE",
          }}
        >
          <Grid container spacing={1}>
            {salesFormData?.map((item, index) => {
              return (
                <Grid item lg={item.gridLength} xs={12} key={index}>
                  <item.component {...item.otherOptions} control={control} />
                </Grid>
              );
            })}
          </Grid>

          {/* items part  */}
          {!showCart ? (
            <Box sx={{ height: "100%" }}>
              {/* car box */}
              {showSaleCard ? (
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    mt: "16px",
                  }}
                >
                  {products.map((product) => (
                    <ProductSaleCard
                      key={product.id}
                      imageSrc={product.imageSrc}
                      category={product.category}
                      productName={product.productName}
                      seller={product.seller}
                      unitPrice={product.unitPrice}
                    />
                  ))}
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    height: "339px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <Box>
                    <img src="/images/cart_icon.svg" alt="cart" />
                  </Box>
                  <Typography
                    sx={{
                      color: "#5E6062",
                      textAlign: "center",
                      fontFamily: '"Open Sans"',
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "0.03px",
                    }}
                  >
                    Not Products Selected Yet
                  </Typography>
                  <Typography
                    sx={{
                      color: "#5E6062",
                      textAlign: "center",
                      fontFamily: '"Open Sans"',
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "0.035px",
                    }}
                  >
                    Search for products to add to the client’ sale.
                  </Typography>
                  <Button
                    onClick={() => setShowSaleCard(true)}
                    type="submit"
                    sx={{
                      backgroundColor: "#4E45E5",
                      color: "#FFFFFF",
                      paddingInline: "24px",
                      paddingBlock: "12px",
                      borderRadius: "8px",
                      fontWeight: 500,
                      "&:hover": {
                        backgroundColor: "#4E45E5",
                      },
                    }}
                  >
                    Search
                  </Button>
                </Box>
              )}{" "}
            </Box>
          ) : (
            <>
              {/* cart part  */}
              <Box sx={{ display: "flex", gap: "16px" }}>
                <SPAMobileDatePicker
                  isCalenderShow={true}
                  name="date"
                  label="Buy Date"
                />
                <SPAMobileDatePicker
                  isCalenderShow={true}
                  name="date"
                  label="Buy Time"
                />
              </Box>
              <Box>
                <SPATextField
                  name="textarea"
                  type="textarea"
                  placeholder="Add any other additional notes here"
                  label="Additional Notes (Optional)"
                />
              </Box>
              <Divider />

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
              >
                <Box>
                  <SPATextField
                    name="name"
                    placeholder="i.e.: Jane Doe"
                    label="Card Full Name"
                  />
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { md: "345px 245px", xs: "1fr" },
                    gap: "16px",
                  }}
                >
                  <SPATextField
                    name="name"
                    placeholder="i.e.: 1234 5678 9010 1112"
                    label="Card Number"
                  />
                  <Box sx={{ display: "flex", gap: "16px" }}>
                    <SPATextField
                      name="name"
                      placeholder="mm/yy"
                      label="Expire Date"
                    />
                    <SPATextField name="name" placeholder="***" label="CCV" />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: { md: "40%", sm: "100%" },
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              height: "100%",
              flexDirection: "column",
              gap: "16px",
              borderRadius: "16px",
              background: "#F8F7FE",
            }}
          >
            <Typography
              sx={{
                color: "#5E6062",
                fontFamily: '"Open Sans"',
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.03px",
              }}
            >
              Summary
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#5E6062",
                      fontFamily: '"Open Sans"',
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "0.08px",
                    }}
                  >
                    Products
                  </Typography>
                  <Typography
                    sx={{
                      color: "#4A4C4F",
                      fontFamily: '"Open Sans"',
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      letterSpacing: "0.024px",
                    }}
                  >
                    $0.0
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#5E6062",
                      fontFamily: '"Open Sans"',
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "0.08px",
                    }}
                  >
                    GST (10%)
                  </Typography>
                  <Typography
                    sx={{
                      color: "#4A4C4F",
                      fontFamily: '"Open Sans"',
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      letterSpacing: "0.024px",
                    }}
                  >
                    $0.0
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                  <Percent size={16} color="#4E45E5" />
                  <Typography
                    sx={{
                      color: "#4E45E5",
                      fontFamily: '"Open Sans"',
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "0.15px",
                      textTransform: "uppercase",
                    }}
                  >
                    Enter Discount Code
                  </Typography>
                  <GreaterThenIcon />
                </Box>
                <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                  <Gift size={16} color="#4E45E5" />
                  <Typography
                    sx={{
                      color: "#4E45E5",
                      fontFamily: '"Open Sans"',
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "0.15px",
                      textTransform: "uppercase",
                    }}
                  >
                    % Enter Gift Voucher Code
                  </Typography>
                  <GreaterThenIcon />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "16px",
                  padding: "16px",
                  marginTop: "16px",
                  alignItems: "center",
                  background: "#EDECFC",
                }}
              >
                <Typography>Total</Typography>
                <Typography
                  sx={{
                    color: "#2F2989",
                    fontFamily: '"Open Sans"',
                    fontSize: { md: "24px", xs: "16px" },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  $0.0
                </Typography>
              </Box>
            </Box>
          </Box>
          <Button type="submit" sx={SubmitBtn}>
            Continue
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
}
