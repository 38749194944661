export const cancelBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.light,
  color: theme.palette.primaryColor.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.lightHover,
  },
});
export const SubmitBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.main,
  color: theme.palette.white.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.dark,
  },
});
export const notesModelStyle = {
  calendarHeaderMain: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "8px",
    background: "#F8F7FE",
  },
  selectionGrid: {
    display: "flex",
    background: "#EDECFC",
    width: "100%",
    p: "2.5px 4px",
    borderRadius: "8px",
  },
  createNoteGrid: {
    display: "flex",
    background: "#EDECFC",
    width: "100%",
    p: "10px",
    borderRadius: "8px",
  },
  gridView: {
    borderRadius: "8px",
    cursor: "pointer",
    p: "8px 16px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    fontSize: "14px",
    fontWeight: "600",
  },
};

export const repeatOnBoxStyle = {
  calendarHeaderMain: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "8px",
    background: "#F8F7FE",
  },
  selectionGrid: {
    display: "flex",
    background: "#EDECFC",
    width: "100%",
    p: "2.5px 4px",
    borderRadius: "8px",
  },
  gridView: {
    borderRadius: "8px",
    cursor: "pointer",
    p: "8px 16px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    fontSize: "14px",
    fontWeight: "600",
  },
};