import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreateQualificationMutation,
  useGetSingleQualificationQuery,
  useUpdateSingleQualificationMutation,
} from "../../../../services/qualifications";
import {
  useCreateRoleMutation,
  useGetSingleRoleQuery,
} from "../../../../services/roles";
import { validationSchema } from "./validationSchema";
import {
  useGetAllServiceCategoryQuery,
  useGetServiceCategoriesMutation,
} from "../../../../services/service-category";

export default function useNewQualification(
  handleClose,
  singleQualificationId,
  setIsOpenSuccessDialog,
  getAllQualifications,
  setSingleQualificationId,
  setStaffUpdateType
) {
  const validationSchemaInstance = validationSchema();
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
  });
  const [
    getAllServiceCategory,
    { data: serviceCategoryData, isLoading: serviceCategoryDataLoader },
  ] = useGetServiceCategoriesMutation();

  const [createQualification, { isLoading: createQualificationLoader }] =
    useCreateQualificationMutation();
  const [updateSingleQualification, { isLoading: updateQualificationLoader }] =
    useUpdateSingleQualificationMutation();
  const { data: singleQualification, isLoading: singleQualificationLoader } =
    useGetSingleQualificationQuery(singleQualificationId, {
      skip: !singleQualificationId,
    });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const onSubmit = async (values) => {
    let payload;
    payload = {
      name: values?.name,
      category: values?.category,
    };
    let response;
    if (singleQualificationId) {
      response = await updateSingleQualification({
        id: singleQualification?.result?.data?._id,
        payload: payload,
      });
    } else {
      response = await createQualification(payload);
    }
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    setSingleQualificationId(response?.data?.result?.data?._id);
    toast.success(response?.data?.message);
    setStaffUpdateType("Staff")
    getAllQualifications();
    reset();
    setIsOpenSuccessDialog(true);
    handleClose();
  };
  const handleCloseForm = () => {
    handleClose();
    reset();
  };

  useEffect(() => {
    if (singleQualification) {
      setValue("name", singleQualification?.result?.data?.name);
      setValue("category", singleQualification?.result?.data?.category?._id);
    }
  }, [singleQualification]);

  useEffect(() => {
    getAllServiceCategory();
  }, []);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    singleQualificationLoader,
    createQualificationLoader,
    updateQualificationLoader,
    serviceCategoryData,
  };
}
