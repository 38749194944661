import { Avatar, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  customColors,
  fs10,
  fs12,
  fs14,
  fw600,
  primary,
  secondary,
} from "../../../../theme";
import { NotifyCard, profileImgStyle, Noted } from "../../style";
import PinIcon from "../../../../assets/svg/pin-icon";
import CalendarIcon from "../../../../assets/svg/calendar-icon";
import DeleteIcon from "../../../../assets/svg/delete-icon";
import { ActionButton } from "./style";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  handleSingleAppointmentData,
  handleSingleAppointmentModal,
} from "../../../../store/features/new-appointment";

/**
 * AppointmentCard component.
 * Renders a card displaying information about an appointment.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.type - The type of appointment, determines the styling.
 * @returns {JSX.Element} JSX code for rendering the AppointmentCard component.
 */

const AppointmentCard = ({ notifyItem }) => {
  const objectLength = Object.keys(notifyItem).length;
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      {objectLength > 5 ? (
        <Box
          sx={{
            background: notifyItem.backgroundColor,
            color: notifyItem.color,
            cursor: "pointer",
            ...NotifyCard.card,
          }}
          onClick={() => {
            if (notifyItem?.appointmentId) {
              navigate("/calendar");
              dispatch(handleSingleAppointmentModal());
              dispatch(handleSingleAppointmentData(notifyItem?.appointmentId));
            }
          }}
        >
          <Typography sx={NotifyCard.headerContent}>
            {notifyItem.headerContent}
          </Typography>

          <Box sx={NotifyCard.subContent}>
            <Typography sx={{ fontSize: fs12 }}>
              {notifyItem.subContentRight}
            </Typography>

            <Typography sx={{ fontSize: fs12 }}>
              {notifyItem.subContentLeft}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: "8px",
            }}
          >
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
              {notifyItem.imgURL ? (
                <img
                  src={notifyItem.imgURL}
                  alt={notifyItem.authorName}
                  style={profileImgStyle}
                />
              ) : (
                <Avatar
                  sx={{ width: "24px", height: "24px" }}
                  variant="rounded"
                />
              )}
              <Typography sx={{ fontSize: fs12, fontWeight: fw600 }}>
                {notifyItem.authorName}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: fs10 }}>
              {notifyItem.appointmentCreated}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            background: notifyItem.isPinned
              ? customColors.milkyWhite
              : customColors.greyWhite,
            ...NotifyCard.card,
          }}
          // onMouseEnter={() => {
          //   setIsHover(true);
          // }}
          // onMouseLeave={() => {
          //   setIsHover(false);
          // }}
        >
          {notifyItem.isPinned && (
            <Box sx={Noted.pinNote}>
              <PinIcon />
              <Typography sx={{ fontSize: fs12, ml: "8px", color: "#070A0E" }}>
                Pinned Note
              </Typography>
            </Box>
          )}
          <Box>
            <Typography sx={{ fontSize: fs14 }}>
              {notifyItem.headerContent}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: fs12 }}>
              {notifyItem.authorName}
            </Typography>

            <Typography sx={{ fontSize: fs12 }}>{notifyItem.addOn}</Typography>
          </Box>
          {isHover && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "5px",
              }}
            >
              <Box
                sx={ActionButton}
                style={{ backgroundColor: customColors.lightRed }}
              >
                <DeleteIcon />
              </Box>
              <Box sx={ActionButton} style={{ backgroundColor: primary.blue }}>
                <CalendarIcon />
                <Typography
                  sx={{
                    fontSize: fs14,
                    textTransform: "uppercase",
                    color: secondary.main,
                    fontWeight: fw600,
                  }}
                >
                  View in Calendar
                </Typography>
              </Box>
              <Box sx={ActionButton} style={{ backgroundColor: primary.blue }}>
                <PinIcon />
                <Typography
                  sx={{
                    fontSize: fs14,
                    textTransform: "uppercase",
                    color: secondary.main,
                    fontWeight: fw600,
                  }}
                >
                  Pin
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default AppointmentCard;
