import { createTheme } from '@mui/material/styles';

// Define your custom palette options
const customPalette = {
  primaryColor: {
    main: '#4E45E5',
    light: '#EDECFC',
    lightHover: '#d5d4e3',
    dark: '#3e37b7',
  },
  textPrimary900: {
    main: '#070A0E',
  },
  borderGray100: {
    main: '#DADADB',
  },
  textGrayPrimary900: {
    main: '#070A0E',
  },
  textGraySecondary: {
    main: '#4A4C4F',
  },
  purple10: {
    main: '#F8F7FE',
  },
  primaryColor2: {
    main: 'red',
  },
  linkColor: {
    main: '#3B37FF',
  },
  white: {
    main: '#fff',
  },
  black: {
    main: '#000',
  },
  blackTwo: {
    main: '#0A0A0B',
  },
};

// Define your custom typography options
const customTypography = {
  //   BakBak: "'Bakbak One', sans-serif",
  //   Azeret: "'Azeret Mono', monospace",
};

// Create the theme using createTheme
const theme = createTheme({
  palette: customPalette,
  typography: customTypography,
});

export default theme;
