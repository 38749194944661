import * as Yup from "yup";

export const validationSchema = (historyType) => {
  let schema = Yup.object().shape({
    client: Yup.string().required("Client is required"),
    message: Yup.string().required("Message is required"),
    template: Yup.string(),
  });
  if (historyType === "SMS") {
    schema = schema.shape({
      phoneNumber: Yup.string().required("Mobile Number is required"),
      savePhoneNumber: Yup.boolean(),
      // replyToSms: Yup.string()
      //   .nullable()
      //   .notRequired()
      //   .matches(
      //     /^\+?\d{10,14}$/,
      //     "Invalid format. Provide Valid Format of 10 to 14 digits"
      //   )
      //   .test(
      //     "mobile-format",
      //     "Invalid format. Provide Valid Format of 10 to 14 digits",
      //     function (value) {
      //       if (!value) return true; // Allows empty values to pass
      //       return /^\+?\d{10,14}$/.test(value);
      //     }
      //   ),
    });
  } else {
    schema = schema.shape({
      client: Yup.string().required("Client is required"),
      recipients: Yup.string()
        .required("Client Email is required")
        .email("Invalid email format"),
      // replyToEmails: Yup.string()
      //   .nullable()
      //   .notRequired()
      //   .required("Reply to email is required")
      //   .email("Invalid email format"),
      subject: Yup.string().required("Subject is required"),
      saveEmail: Yup.boolean(),
    });
  }
  return schema;
};
