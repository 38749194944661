import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, TextField, Typography } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { HelperText } from "../../styleComponents/style";
import { labelStyle, datePickerStyle } from "./style";
import { Warning } from "@phosphor-icons/react";

export default function SPAMobileTimePicker({
  helperText,
  label,
  value,
  name,
  control,
  readOnly,
  disableFuture = false,
  disablePast = false,
}) {
  // Correctly setting minTime and maxTime as dayjs objects
  // const minTime = dayjs().startOf("day").hour(8); // Today at 8:00 AM
  // const maxTime = dayjs().startOf("day").hour(22); // Today at 10:00 PM
  return (
    <FormControl fullWidth>
      <Typography sx={{ display: "flex", marginBottom: "8px", ...labelStyle }}>
        {label}
        {!helperText && (
          <Typography sx={{ color: "#D13438", pl: "3px" }}> *</Typography>
        )}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={name}
          control={control}
          defaultValue={value ? dayjs(value) : null}
          render={({ field, fieldState: { error: fieldError } }) => (
            <MobileTimePicker
              disableFuture={disableFuture}
              disablePast={disablePast}
              readOnly={readOnly}
              views={["hours", "minutes"]}
              {...field}
              // minTime={minTime}
              // maxTime={maxTime}
              value={field.value ? dayjs(field.value) : null}
              slotProps={{
                textField: {
                  error: fieldError,
                  readOnly: readOnly,
                  helperText: fieldError ? (
                    <Typography
                      sx={{
                        display: "flex",

                        alignItems: "center",
                        gap: "4px",
                        fontSize: "12px",
                        color: "#FFAA33",
                      }}
                    >
                      <Warning size={16} weight="fill" /> {fieldError?.message}
                    </Typography>
                  ) : (
                    ""
                  ),
                },
              }}
              sx={datePickerStyle}
            />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
