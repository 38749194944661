import { Button, Grid } from "@mui/material";
import React from "react";
import { UseRequirement } from "./use-requirements";
import FormProvider from "../../../../../../../hook-form/FormProvider";
import AddQualification from "./add-qualification";
import { customColors, secondary } from "../../../../../../../../theme";
import theme from "../../../../../../../../theme/theme";

export default function Qualification({
  singleStaffData,
  singleStaffRefetching,
}) {
  const {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    qualificationsData,
    qualificationsDataLoader,
    searchQualification,
    setSearchQualification,
  } = UseRequirement({ singleStaffData, singleStaffRefetching });

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <AddQualification
            errors={errors}
            qualificationsDataLoader={qualificationsDataLoader}
            qualificationsData={qualificationsData}
            searchQualification={searchQualification}
            setSearchQualification={setSearchQualification}
            control={control}
            methods={methods}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="submit"
            sx={{
              backgroundColor: theme.palette.primaryColor.main,
              position: "relative",
              color: theme.palette.white.main,
              flexShrink: 0,
              paddingInline: "24px",
              paddingBlock: "12px",
              borderRadius: "8px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: theme.palette.primaryColor.dark,
              },
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
