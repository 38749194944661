import { baseAPI } from "../baseApi";

export const RolesApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.mutation({
      query: (body) => ({ url: "/role", method: "PATCH", body }),
      providesTags: ["Roles"],
    }),
    createRole: builder.mutation({
      query: (payload) => ({
        url: "/role",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Roles"],
    }),
    updateRole: builder.mutation({
      query: ({ id, payload }) => ({
        url: "/role/" + id,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Roles"],
    }),
    getSingleRole: builder.query({
      query: (roleId) => ({ url: "/role/" + roleId }),
      providesTags: ["singleRole"],
    }),
    deleteRole: builder.mutation({
      query: (roleId) => ({
        url: "/role/" + roleId,
        method: "DELETE",
      }),
      providesTags: ["Roles"],
    }),
    updateStaffRole: builder.mutation({
      query: ({ id, payload }) => ({
        url: "/role/update-staff-role/" + id,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Roles"],
    }),
  }),
});

export const {
  useUpdateStaffRoleMutation,
  useGetRolesMutation,
  useGetSingleRoleQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = RolesApi;
