import { baseAPI } from "./baseApi";

export const authAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: "/auth/sign-in",
        method: "POST",
        body: { email, password },
      }),
      // invalidatesTags: ['Users'],
    }),
    register: builder.mutation({
      query: ({ ...userData }) => ({
        url: "/register",
        method: "POST",
        body: userData,
      }),
    }),
    logout: builder.mutation({
      query: () => ({ url: "/logout", method: "POST" }),
      invalidatesTags: ["Users"], // Invalidate cache on logout
    }),
    getUser: builder.query({
      query: () => ({ url: "/user", method: "GET" }),
      providesTags: ["Users"], // Mark user data as cached
    }),
  }),
});
export const {
  useLoginMutation,
  useRegisterMutation,
} = authAPI;
