export const legendStyle = () => ({
  color: '#070A0E',
  fontFamily: '"Open Sans"',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '0.024px',
});

export const labelStyle = (theme) => ({
  fontSize: '14px',
  color: theme.palette.textPrimary900.main,
  marginBottom: '8px',
});

export const fieldStyle = (theme) => ({
  height: '40px',
  border: `1px solid ${theme.palette.borderGray100.main}`,
  borderRadius: '6px',
  opacity: 1,
  '& input': {
    height: '44px',
    outline: 'none',
    paddingInlineStart: '1rem',
    paddingBlock: '0px',
    '::placeholder': {
      color: '#D0D5DD',
      opacity: 1,
      fontSize: '12px',
    },
    '&:-webkit-autofill': {
      backgroundClip: 'text',
    },
  },
});
export const textareaStyle = (theme) => ({
  border: `1px solid ${theme.palette.borderGray100.main}`,
  borderRadius: '8px',
  paddingInlineStart: '16px',
  paddingTop: '6px',
  fontSize: '10px !important',
});

export const selectStyle = (theme) => ({
  height: '40px',
  border: `1px solid ${theme.palette.borderGray100.main}`,
  borderRadius: '6px',
  '& fieldset': {
    height: '40px',
    border: `none !important`,
    outline: 'none !important',
    '&:hover': {
      border: `none !important`,
      outline: 'none !important',
    },
  },
  '& .MuiSelect-icon': {
    color: theme.palette.borderGray100.main,
  },
});
export const datePickerStyle = (theme) => ({
  paddingBlock: '0 !important',
  '& div': {
    border: `1px solid ${theme.palette.borderGray100.main} !important`,
    outline: 'none !important',
    borderRadius: '6px !important',
    '&:hover': {
      border: `1px solid ${theme.palette.borderGray100.main} !important`,
      outline: 'none !important',
    },
  },

  '& input': {
    margintTop: '0',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '40px',
    borderRadius: '6px',
  },
  '& fieldset': {
    border: `none !important`,
    outline: 'none !important',
    '&:hover': {
      border: `none !important`,
      outline: 'none !important',
    },
  },
});

export const itemFlex = (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.purple10.main,
  padding: '5px',
  borderRadius: '4px',
});

export const additionBoxFlex = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: "100%",
});
