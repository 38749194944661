/**
 * Font Sizes
 */
export const fs10 = "10px";
export const fs12 = "12px";
export const fs14 = "14px";
export const fs16 = "16px";
export const fs18 = "18px";
export const fs20 = "20px";
export const fs22 = "22px";
export const fs24 = "24px";
export const fs26 = "26px";
export const fs28 = "28px";
export const fs30 = "30px";
export const fs32 = "32px";
export const fs34 = "34px";
export const fs36 = "36px";
export const fs38 = "38px";
export const fs40 = "40px";
export const fs42 = "42px";
export const fs44 = "44px";
export const fs48 = "48px";

/**
 * Font Weights
 */
export const fw400 = 400;
export const fw500 = 500;
export const fw600 = 600;
export const fw700 = 700;
export const fw800 = 800;
