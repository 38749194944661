export const staffStyle = {
  addQualificationHeaderMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "8px",
    background: "#F8F7FE",
    p: "16px",
    gap: "8px",
  },
  statusInfo: {
    p: "4px 8px",
    width: "fit-content",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontSize: "10px",
  },
};
