import SPAMobileDatePicker from "../../hook-form/SPAMobileDatePicker";
import SPAMobileTimePicker from "../../hook-form/SPAMobileTimerPicker";
import SPASelectField from "../../hook-form/SPASelectField";

export const recurringFormData = () => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        name: "startDate",
        label: "Start Date",
      },
      component: SPAMobileDatePicker,
    },

    {
      gridLength: 6,
      otherOptions: {
        name: "endDate",
        label: "End Date",
        helperText: "(Optional)",
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "startTime",
        label: "To",
        placeholder: "Select Time",
      },
      component: SPAMobileTimePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "endTime",
        label: "From",
        placeholder: "Select Time",
      },
      component: SPAMobileTimePicker,
    },
  ];
};

export const oneTimeFormData = () => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        name: "startDate",
        label: "Start Date",
        isCalenderShow: true,
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "startTime",
        label: "From",
        placeholder: "Select Time",
      },
      component: SPAMobileTimePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "endDate",
        label: "End Date",
        isCalenderShow: true,
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "endTime",
        label: "To",
        placeholder: "Select Time",
      },
      component: SPAMobileTimePicker,
    },
  ];
};

export const createNoteFormData = (
  serviceData,
  usersData,
  roomData,
  availableType
) => {
  let formData = [
    {
      key: "ROOM",
      gridLength: 12,
      otherOptions: {
        name: "room",
        label: "Room",
        options: roomData?.map((item) => ({
          label: item.name,
          value: item.name,
          id: item?._id,
        })),
        placeholder: "Select Room",
      },
      component: SPASelectField,
    },
    {
      key: "STAFF",
      gridLength: 12,
      otherOptions: {
        name: "user",
        label: "Staff",
        options: usersData?.map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: `${item.firstName} ${item.lastName}`,
          id: item._id,
        })),
        placeholder: "Select Staff",
      },
      component: SPASelectField,
    },
    {
      key: "SERVICES",
      gridLength: 12,
      otherOptions: {
        name: "services",
        label: "Services",
        options: serviceData?.map((item) => ({
          label: item.name,
          value: item.name,
          id: item?._id,
        })),
        placeholder: "Select Service",
      },
      component: SPASelectField,
    },
  ];

  // Filter based on availableType
  if (availableType !== "ENTIRE_LOCATION") {
    formData = formData.filter((item) => item.key === availableType);
  }

  if (availableType === "ENTIRE_LOCATION") {
    formData = [];
  }

  return formData;
};
