import { List } from "@mui/material";
import React from "react";
import SPASideBarListButton from "../../common/spa-sidebar-list-button";
import { sidebarData } from "./dealsData";

export default function SideBar({ setActiveItem, activeItem }) {
  return (
    <List
      component="nav"
      sx={{
        width: "auto",
        p: "16px 24px",
        background: "#F8F7FE",
      }}
    >
      {sidebarData.map((item, index) => (
        <SPASideBarListButton
          key={index}
          item={item}
          activeItem={activeItem}
          isLableShow={true}
          onClick={() => {
            setActiveItem(item.link);
          }}
        />
      ))}
    </List>
  );
}
