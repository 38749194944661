import { At, ChatText, Envelope } from "@phosphor-icons/react";
import SPAInputField from "../../hook-form/SPAInputField";
import SPASelectField from "../../hook-form/SPASelectField";

export const remindersTabsArray = [
  { title: "ALL", icon: <At size={16} /> },
  {
    title: "EMAIL",
    icon: <Envelope size={16} />,
  },
  { title: "SMS", icon: <ChatText size={16} /> },
];

export const remindersDetailsFormData = () => {
  return [
    {
      gridLength: 3.7,
      otherOptions: {
        fullWidth: true,
        name: "appliesTo",
        label: "Applies To",
        placeholder: "Select Applies To",
        options: [
          { label: "All Clients", value: "ALL_CLIENTS" },
          { label: "NEW", value: "NEW" },
          { label: "VIP", value: "VIP" },
          { label: "LOST", value: "LOST" },
          { label: "RETURN", value: "RETURN" },
          { label: "ACTIVE", value: "ACTIVE" },
        ],
      },
      component: SPASelectField,
    },
    {
      gridLength: 3.7,
      otherOptions: {
        fullWidth: true,
        name: "when",
        label: "When",
        placeholder: "Select Value",
        options: [
          { label: "Before Appointment", value: "BEFORE_APPOINTMENT" },
          { label: "After Appointment", value: "AFTER_APPOINTMENT" },
        ],
      },
      component: SPASelectField,
    },
    {
      gridLength: 3.7,
      otherOptions: {
        fullWidth: true,
        name: "hours",
        label: "Time",
        placeholder: "Select Time",
        options: [
          { label: "1 Hour", value: 1 },
          { label: "24 Hours", value: 24 },
          { label: "48 Hours", value: 48 },
        ],
      },
      component: SPASelectField,
    },
    // {
    //   gridLength: 4,
    //   component: InsertButton,
    // },
  ];
};
export const remindersFormData = ({ InsertButton, reminderType }) => {
  let finalData;
  let EmailData = [
    {
      gridLength: 8,
      otherOptions: {
        fullWidth: true,
        name: "subject",
        label: "Subject",
        placeholder: "Write Subject",
      },
      component: SPAInputField,
    },
    // {
    //   gridLength: 4,
    //   otherOptions: {
    //     fullWidth: true,
    //     name: "replyTo",
    //     label: "Reply to",
    //     placeholder: "example@gmail.com",
    //     labelHelperText: "(Optional)",
    //   },
    //   component: SPAInputField,
    // },
    {
      gridLength: 4,
      component: InsertButton,
    },
  ];
  let SMSData = [
    // {
    //   gridLength: 12,
    //   otherOptions: {
    //     fullWidth: true,
    //     name: "replyTo",
    //     label: "Reply to",
    //     placeholder: "+91234567890",
    //     labelHelperText: "(Optional)",
    //   },
    //   component: SPAInputField,
    // },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "message",
        label: "Message",
        placeholder: "Write Message",
        type: "textarea",
        multiline: true,
        rows: 12,
      },
      component: SPAInputField,
    },
  ];
  if (reminderType === "SMS") {
    finalData = SMSData;
  } else {
    finalData = EmailData;
  }
  return finalData;
};
