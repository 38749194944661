import React from "react";
import SPAInputField from "../../../../hook-form/SPAInputField";
import { LinkSimpleHorizontal } from "@phosphor-icons/react";
import { Box } from "@mui/material";

const ButtonComponent = ({ control, index, focusedIndex, setFocusedIndex }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        flexDirection: "column",
      }}
    >
      <SPAInputField
        fullWidth
        name={`buttonlabel${index}`}
        placeholder="Enter the Button Label"
        control={control}
        autoFocus={focusedIndex === index ? true : false}
        onFocus={() => setFocusedIndex(index)}
      />
      <SPAInputField
        fullWidth
        prefixIcon={<LinkSimpleHorizontal size={16} color="#C1C2C3" />}
        name={`buttonlink${index}`}
        placeholder="Insert link here"
        control={control}
      />
    </Box>
  );
};

export default ButtonComponent;
