import { Pencil, Storefront } from "@phosphor-icons/react";

//popover mock data
export const productTypePopOverData = (
  setPopoverOpen,
  setIsRoleDialogOpen,
  setIsOpenAddRoleDialog
) => [
  {
    id: "1",
    label: "Edit",
    icon: Pencil,
    onClick: () => {
      setPopoverOpen(null);
      setIsRoleDialogOpen(true);
    },
  },
  {
    id: "2",
    label: "Add to a Retail Product",
    icon: Storefront,
    onClick: () => {
      setIsOpenAddRoleDialog(true);
      setPopoverOpen(null);
    },
  },
  {
    id: "3",
    label: "Add to a Professional Product",
    icon: Storefront,
    onClick: () => {
      setIsOpenAddRoleDialog(true);
      setPopoverOpen(null);
    },
  },
];
