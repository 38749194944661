import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import SPADialogue from "../../../../../common/spa-dialogue/index.jsx";
import { createProfessionalProductFormData } from "./new-professional-form-data.js";

export default function CreateProfessionalProduct({
  getAllProducts,
  isNewStaffOpen,
  setIsStaffDialogOpen,
  control,
  methods,
  handleSubmit,
  onSubmit,
  createStaffLoader,
  productTypeData,
  handleStaffCloseDialog,
}) {
  return (
    <>
      <SPADialogue
        open={isNewStaffOpen}
        handleClose={handleStaffCloseDialog}
        dialogTitle={`Create Professional Product`}
        cancelText="Cancel"
        submitText={`${"Create Professional Product"}`}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        methods={methods}
        loading={createStaffLoader}
        profileWidth={735}
      >
        {createStaffLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={createStaffLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
          }}
        >
          {createProfessionalProductFormData(
            productTypeData?.result?.data?.data
          ).map((item) => {
            return (
              <Box
                sx={{
                  borderRadius: "8px",
                  background: "#F8F7FE",
                  display: "flex",
                  padding: "16px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "16px",
                  minHeight: "200px",
                  mb: "16px",
                }}
              >
                <Box sx={{ display: "flex", gap: "4px" }}>
                  <Typography
                    sx={{
                      color: "#4A4C4F",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    {" "}
                    {item?.title}
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  {item?.rowChildren?.map((child) => (
                    <Grid item xs={12} sm={12} md={child.gridLength}>
                      {child?.colChildren && (
                        <Grid container spacing={1}>
                          {child?.colChildren?.map((subChild) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={subChild?.gridLength}
                            >
                              {subChild?.colTitle && (
                                <Typography
                                  sx={{
                                    color: "#5E6062",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    letterSpacing: "0.035px",
                                    textAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    justifyContent: "center",
                                  }}
                                >
                                  {subChild?.colTitle}{" "}
                                  {subChild?.isRequired && (
                                    <Typography
                                      sx={{ color: "#D13438", pl: "3px" }}
                                    >
                                      {" "}
                                      *
                                    </Typography>
                                  )}
                                </Typography>
                              )}
                              {subChild?.component && (
                                <Box>
                                  <subChild.component
                                    control={control}
                                    {...subChild?.otherOptions}
                                  />
                                </Box>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            );
          })}
        </Box>
      </SPADialogue>
    </>
  );
}
