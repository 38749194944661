import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreateArticleMutation,
  useDeleteArticleMutation,
  useGetSingleArticleQuery,
  useUpdateSingleArticleMutation,
} from "../../../../services/articles";
import { useGetProductsMutation } from "../../../../services/product";
import { useGetProductTypeMutation } from "../../../../services/product-type";
import { useGetServiceCategoriesMutation } from "../../../../services/service-category";
import { useGetServicesMutation } from "../../../../services/services";
import { validationSchemaArticle } from "./form-validation";

export default function useCreateArticle({
  createArticleButton,
  getAllArticles,
  articleId,
  setArticleId,
}) {
  const [isOpenDeleteArticleDialog, setIsOpenDeleteArticleDialog] =
    useState(false);
  const [openDialogCreatePreviewArticle, setOpenDialogCreatePreviewArticle] =
    useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedTreatment, setSelectedTreatment] = useState({});
  const [selectedValue, setSelectedValue] = useState([]);
  const [editorInstance, setEditorInstance] = useState(null);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [deleteValue, setDeleteValue] = useState(null);
  const [imageFile, setImageFile] = useState({});
  const [videoFile, setVideoFile] = useState({});

  const [searchProduct, setSearchProduct] = useState({});
  const [isSearchBox, setIsSearchBox] = useState({});
  const [activeItem, setActiveItem] = useState("ALL");

  const [searchTreatment, setSearchTreatment] = useState({});
  const [isSearchBoxTreatment, setIsSearchBoxTreatment] = useState({});
  const [activeItemTreatment, setActiveItemTreatment] = useState("ALL");

  const [articleStep, setArticleStep] = useState(1);
  const [thumbnailImg, setThumbnailImg] = useState("");
  const [articleFormData, setArticleFormData] = useState();

  const [isSinglePreviewActive, setIsSinglePreviewActive] = useState(false);
  const [showInHomeValue, setShowInHomeValue] = useState("ARTICLES_SECTION");
  const [focusedIndex, setFocusedIndex] = useState("");
  const [notifyClientsValue, setNotifyClientsValue] =
    useState("WHEN_PUBLISHED");

  const dragItem = useRef();
  const dragOverItem = useRef();
  const methods = useForm({
    resolver: yupResolver(validationSchemaArticle(selectedValues, articleStep)),
    mode: "onChange",
    defaultValues: {
      title: "",
      template: "",
      showInHomeArticle: false,
      featureArticle: false,
      notifyClientsArticle: false,
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const {
    title: articleTitle,
    alttext,
    videoalttext,
    featureArticle,
    showInHomeArticle,
    notifyClientsArticle,
  } = watch();

  const [createArticle, { isLoading: createArticleLoading }] =
    useCreateArticleMutation();
  const [updateArticle, { isLoading: updateArticleLoading }] =
    useUpdateSingleArticleMutation();
  const {
    data: singleArticleData,
    isLoading: singleArticleDataLoader,
    refetch: refetchingSingleArticle,
  } = useGetSingleArticleQuery(articleId, {
    skip: !articleId,
  });
  const [deleteArticle, { isLoading: deleteArticleLoader }] =
    useDeleteArticleMutation();

  const [
    getAllServices,
    { data: servicesData, isLoading: servicesDataLoading },
  ] = useGetServicesMutation();
  const [
    getAllProductCategory,
    { data: productCategoryData, isLoading: productCategoryDataLoader },
  ] = useGetProductTypeMutation();
  const [
    getAllServiceCategory,
    { data: serviceCategoryData, isLoading: serviceCategoryDataLoader },
  ] = useGetServiceCategoriesMutation();
  const [
    getAllProducts,
    { data: allProductData, isLoading: productDataLoading },
  ] = useGetProductsMutation();

  useEffect(() => {
    if (articleId) {
      refetchingSingleArticle();
    }
  }, [articleId]);

  const onSubmit = async (values) => {
    if (editorInstance) {
      const editorContent = editorInstance.getContent();
      const componentPayload = selectedValues?.map((val) => {
        const additionalProps = Object.keys(values)?.reduce((acc, key) => {
          if (key.includes(val.id)) {
            acc[key.replace(val.id, "").toLowerCase()] = values[key];
          }
          return acc;
        }, {});

        return {
          type: val.type,
          id: val.id,
          ...additionalProps,
        };
      });

      const transformedData = componentPayload?.map((component) => {
        if (component.type === "Product") {
          return {
            type: component.type,
            id: component.id,
            product: component.product._id,
          };
        } else if (component.type === "Treatment") {
          return {
            type: "Treatment",
            id: component.id,
            service: component.treatment._id,
          };
        } else {
          return component;
        }
      });

      const payload = {
        title: values.title,
        type: "PRODUCT",
        components: transformedData,
        preview: `${editorContent}`,
      };
      if (featureArticle) {
        payload["showUntil"] = new Date(values?.showUntil).toISOString();

        if (showInHomeArticle) {
          payload["showInHome"] = showInHomeValue;
        }
      }

      if (notifyClientsArticle) {
        payload["notifyClients"] = notifyClientsValue;
      }

      if (notifyClientsValue === "CUSTOM_DATE") {
        payload["notifyOn"] = new Date(values?.notifyOn).toISOString();
      }

      if (values?.submitButton === "PUBLISHED") {
        payload["thumbnail"] = values.thumbnail;
        payload["status"] = "PUBLISHED";
        handleSubmissionHandling(articleId, payload);
      }
      if (articleStep === 1 && values?.submitButton !== "PUBLISHED") {
        setArticleStep(2);
        setArticleFormData(payload);
        setOpenDialogCreatePreviewArticle(false);
        return;
      }
      if (articleStep === 2 && values?.submitButton !== "PUBLISHED") {
        let finalPayload = articleFormData;
        finalPayload["thumbnail"] = values.thumbnail;
        handleSubmissionHandling(articleId, finalPayload);
      }
    }
  };

  const handleDeleteArticle = async () => {
    let response;
    if (articleId) {
      response = await deleteArticle(articleId);
    }

    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      getAllArticles();
      handleCancelBtn();
      setIsOpenDeleteArticleDialog(false);
      return;
    }
  };

  const handleSubmissionHandling = async (articleId, payload) => {
    let response;

    if (articleId) {
      response = await updateArticle({
        id: articleId,
        payload: payload,
      });
    } else {
      response = await createArticle(payload);
    }
    if (response?.data?.status) {
      toast.success(response?.data?.message);
      getAllArticles();
      setArticleFormData();
      setArticleStep(1);
      setThumbnailImg("");
      setEditorInstance("");
      setSelectedValues([]);
      setSelectedValue([]);
      setArticleId("");
      reset({
        title: "",
        preview: "",
        components: [],
        thumbnail: "",
        showInHomeArticle: false,
        featureArticle: false,
        notifyClientsArticle: false,
        notifyOn: "",
        showUntil: "",
        submitButton: "",
        status: "",
      });
      setIsSinglePreviewActive(false);
      return;
    } else toast.error(response?.data?.message);
  };

  const handleAddProduct = useCallback(
    (product, index) => {
      setSelectedProduct((prev) => ({
        ...prev,
        [index]: {
          id: index,
          product: product,
        },
      }));
      methods.setValue(`product${index}`, product);
    },
    [methods]
  );

  const handleAddTreatment = useCallback(
    (treatment, index) => {
      setSelectedTreatment((prev) => ({
        ...prev,
        [index]: {
          id: index,
          treatment: treatment,
        },
      }));
      methods.setValue(`treatment${index}`, treatment);
    },
    [methods]
  );

  const handleFileUpload = (file, index) => {
    setImageFile((prev) => ({ ...prev, [index]: file }));
    methods.setValue(`image${index}`, file);
  };

  const handleVideoUpload = (file, index) => {
    setVideoFile((prev) => ({ ...prev, [index]: file }));
    methods.setValue(`video${index}`, file);
  };

  const handleThumbnailUpload = (file) => {
    methods.setValue("thumbnail", file);
    setThumbnailImg(file);
  };

  const handleInsertSelection = (value) => {
    const newValue = { id: Date.now(), type: value };
    const updatedValues = [...selectedValue, newValue];
    setSelectedValue(updatedValues);
    setSelectedValues(updatedValues);
  };

  const handleDeleteInsertValue = () => {
    const updatedValues = selectedValue.filter(
      (item) => item.id !== deleteValue
    );
    setImageFile((prev) => {
      const newImageFiles = { ...prev };
      delete newImageFiles[deleteValue];
      return newImageFiles;
    });
    setSelectedValue(updatedValues);
    setSelectedValues(updatedValues);
    setIsOpenDeleteDialog(false);
  };

  const handleSort = () => {
    let _selectedValue = [...selectedValue];
    const draggedItemContent = _selectedValue?.splice(dragItem.current, 1)[0];
    _selectedValue?.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setSelectedValue(_selectedValue);
    setSelectedValues(_selectedValue);
  };

  const handleCancelBtn = () => {
    reset({
      title: "",
      preview: "",
      components: [],
      thumbnail: "",
      showInHomeArticle: false,
      featureArticle: false,
      notifyClientsArticle: false,
      notifyOn: "",
      showUntil: "",
      submitButton: "",
      status: "",
    });
    setSelectedValue([]);
    setSelectedValues([]);
    setImageFile({});
    setVideoFile({});
    setArticleId("");
  };

  useEffect(() => {
    if (createArticleButton) {
      handleCancelBtn();
    }
  }, [createArticleButton]);

  useEffect(() => {
    getAllServices();
    getAllProducts({});
    getAllProductCategory();
    getAllServiceCategory();
  }, []);

  useEffect(() => {
    methods.reset((prevValues) => ({
      ...prevValues,
      ...selectedValues?.reduce((acc, val) => {
        acc[val.type.toLowerCase()] = prevValues[val.type.toLowerCase()] || "";
        return acc;
      }, {}),
    }));
    methods.resolver = yupResolver(validationSchemaArticle(selectedValues));
  }, [selectedValues]);

  useEffect(() => {
    if (singleArticleData?.result?.data && articleId) {
      const initialArticleData = singleArticleData?.result?.data;
      methods.setValue("title", initialArticleData?.title);
      methods.setValue("showUntil", initialArticleData?.showUntil);
      methods.setValue("notifyClients", initialArticleData?.notifyClients);
      methods.setValue("notifyOn", initialArticleData?.notifyOn);
      methods.setValue("status", initialArticleData?.status);
      let featureArticleInitialValue =
        initialArticleData?.showUntil || initialArticleData?.showInHome;
      methods.setValue(
        "featureArticle",
        featureArticleInitialValue ? true : false
      );
      methods.setValue(
        "showInHomeArticle",
        initialArticleData?.showInHome ? true : false
      );
      methods.setValue(
        "notifyClientsArticle",
        initialArticleData?.notifyClients ? true : false
      );
      setNotifyClientsValue(initialArticleData?.notifyClients);
      setShowInHomeValue(initialArticleData?.showInHome);
      methods.setValue("thumbnail", initialArticleData?.thumbnail);
      setThumbnailImg(initialArticleData?.thumbnail);

      const newSelectedValues = initialArticleData?.components?.map(
        (component) => {
          const { type, id, ...additionalProps } = component;
          Object.keys(additionalProps).forEach((key) => {
            const formattedKey = `${
              key === "service" ? "treatment" : key
            }${id}`;
            if (key === "product") {
              setSelectedProduct((prev) => ({
                ...prev,
                [id]: {
                  id: id,
                  product: key === "product" && additionalProps[key],
                },
              }));
            }
            if (key === "service") {
              setSelectedTreatment((prev) => ({
                ...prev,
                [id]: {
                  id: id,
                  treatment: key === "service" && additionalProps[key],
                },
              }));
            }
            setVideoFile((prev) => ({
              ...prev,
              [id]: key === "video" && additionalProps[key],
            }));
            setImageFile((prev) => ({
              ...prev,
              [id]: key === "image" && additionalProps[key],
            }));
            methods.setValue(formattedKey, additionalProps[key]);
          });

          return { type, id };
        }
      );
      setSelectedValue(newSelectedValues);
      setSelectedValues(newSelectedValues);
    }
  }, [singleArticleData, articleId]);

  useEffect(() => {
    reset({
      title: "",
      preview: "",
      components: [],
      thumbnail: "",
      showInHomeArticle: false,
      featureArticle: false,
      notifyClientsArticle: false,
      notifyOn: "",
      showUntil: "",
    });
    setSelectedValue([]);
    setSelectedValues([]);
    setImageFile({});
    setVideoFile({});
  }, [articleId]);

  return {
    errors,
    methods,
    control,
    onSubmit,
    handleSubmit,
    createArticleLoading,
    servicesData,
    articleTitle,
    setSelectedValues,
    alttext,
    productCategoryData,
    serviceCategoryData,
    allProductData,
    productDataLoading,
    handleAddProduct,
    selectedProduct,
    handleAddTreatment,
    selectedTreatment,
    servicesDataLoading,
    imageFile,
    selectedValue,
    editorInstance,
    handleInsertSelection,
    dragItem,
    dragOverItem,
    handleSort,
    setEditorInstance,
    isOpenDeleteDialog,
    setIsOpenDeleteDialog,
    handleDeleteInsertValue,
    setDeleteValue,
    handleFileUpload,
    handleVideoUpload,
    videoFile,
    videoalttext,

    searchProduct,
    setSearchProduct,
    isSearchBox,
    setIsSearchBox,
    activeItem,
    setActiveItem,
    searchTreatment,
    setSearchTreatment,
    isSearchBoxTreatment,
    setIsSearchBoxTreatment,
    activeItemTreatment,
    setActiveItemTreatment,

    openDialogCreatePreviewArticle,
    setOpenDialogCreatePreviewArticle,

    articleStep,
    setArticleStep,

    handleThumbnailUpload,
    thumbnailImg,
    singleArticleDataLoader,
    handleCancelBtn,

    featureArticle,
    showInHomeArticle,
    notifyClientsArticle,

    notifyClientsValue,
    setNotifyClientsValue,
    showInHomeValue,
    setShowInHomeValue,
    isSinglePreviewActive,
    setIsSinglePreviewActive,
    singleArticleData,
    handleDeleteArticle,
    isOpenDeleteArticleDialog,
    setIsOpenDeleteArticleDialog,
    focusedIndex,
    setFocusedIndex,
  };
}
