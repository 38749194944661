import { Box, Button, Grid, Typography } from "@mui/material";
import { Phone, Plus, Trash, UserPlus } from "@phosphor-icons/react";
import React from "react";
import NoClients from "../../../../../../assets/svg/no-clients";
import { fs16, fw600 } from "../../../../../../theme";
import theme from "../../../../../../theme/theme";
import SPAAlertDialog from "../../../../../common/spa-alert-dialogue";
import FormProvider from "../../../../../hook-form/FormProvider";
import { SubmitBtn, address } from "../style";
import { emergencyContactForm } from "./emergency-data";
import useStaffAddress from "./useStaffAddress";

export default function Emergency({ singleStaffData, singleStaffRefetching }) {
  const {
    onSubmit,
    handleSubmit,
    fields,
    control,
    methods,
    isOpenDeleteDialog,
    setIsOpenDeleteDialog,
    setEmergencyContactId,
    addContact,
    handleDeleteEmergencyContact,
  } = useStaffAddress({
    singleStaffData,
    singleStaffRefetching,
  });

  return (
    <>
      <Grid
        xs={12}
        style={{ height: "100%", padding: "16px", borderRadius: "8px" }}
        bgcolor={theme.palette.purple10.main}
      >
        <Box style={address.flexContainer} mb={2}>
          <Box style={address.flexContainer}>
            <Phone size={24} />
            <Typography
              fontSize={fs16}
              fontWeight={fw600}
              color={theme.palette.textGraySecondary.main}
            >
              Emergency Contact
            </Typography>
          </Box>

          <Box sx={address.flexContainer} onClick={addContact}>
            <Plus
              size={24}
              color={theme.palette.primary.main}
              cursor={"pointer"}
            />
            <Box
              sx={{
                fontSize: fs16,
                fontWeight: fw600,
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            >
              Add another Emergency Contact
            </Box>
          </Box>
        </Box>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((contact, index) => {
            return (
              <Grid container spacing={2} key={contact.id} alignItems="center">
                <Grid item xs={11}>
                  <Grid container spacing={1} sx={{ mb: 2 }}>
                    {emergencyContactForm(index)?.map((item, idx) => (
                      <Grid item lg={item.gridLength} xs={12} key={idx}>
                        <item.component
                          {...item.otherOptions}
                          control={control}
                          key={`${contact.id}-${item.otherOptions.name}`}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Trash
                    size={24}
                    color={theme.palette.error.main}
                    cursor={"pointer"}
                    onClick={() => {
                      setEmergencyContactId({ contact, index });
                      setIsOpenDeleteDialog(true);
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}

          {fields?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                mt: "8px",
              }}
            >
              <Button type="submit" sx={SubmitBtn}>
                Save Changes
              </Button>
            </Box>
          )}
        </FormProvider>
        {fields?.length === 0 && (
          <Box
            sx={{
              mt: "18px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <NoClients />
            <Typography sx={{ fontSize: "20px", color: "#595959" }}>
              There’s no Emergency Contact
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#595959" }}>
              Create an Emergency Contact by clicking on the button Create
              Emergency Contact
            </Typography>
            <Button
              disableElevation
              variant="contained"
              sx={{
                background: theme.palette.secondary.main,
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                "&:hover": { background: theme.palette.secondary.main },
              }}
              onClick={addContact}
            >
              <UserPlus size={24} color="currentColor" />
              Create Emergency Contact
            </Button>
          </Box>
        )}
      </Grid>
      {isOpenDeleteDialog && (
        <SPAAlertDialog
          open={isOpenDeleteDialog}
          handleClose={() => setIsOpenDeleteDialog(false)}
          handleNewTab={handleDeleteEmergencyContact}
          title={"Emergency Contact Removal Confirmation"}
          contentText={`Removing this Emergency Contact will permanently delete it from the system. This action cannot be undone. Are you sure you want to proceed with the removal?`}
          disagreeText={`Permanently Remove Emergency Contact`}
          agreeText={`Cancel`}
          type="error"
        />
      )}
    </>
  );
}
