export const PERMISSION = {
    CREATE_APPOINTMENT: "CREATE_APPOINTMENT",
    EDIT_APPOINTMENT: "EDIT_APPOINTMENT",
    CREATE_CLIENT: "CREATE_CLIENT",
    EDIT_CLIENT: "EDIT_CLIENT",
    SPA_MANAGEMENT: "SPA_MANAGEMENT",
    VIEW_CLIENT: "VIEW_CLIENT",
    DELETE_CLIENT: "DELETE_CLIENT",
    VIEW_SPA_MANAGEMENT: "VIEW_SPA_MANAGEMENT",
    VIEW_SALES: "VIEW_SALES",
    VIEW_COMMUNICATION: "VIEW_COMMUNICATION",
    VIEW_CALENDAR: "VIEW_CALENDAR",
  };