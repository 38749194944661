import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetSingleClientQuery } from "../../../../../../services/clients";
import { convertTo12HourFormat } from "../../../../../../utils/convertTimeIOSFormat";
import SpaAppointmentCard from "../../../../../common/spa-appointment-card";
import {
  handleCloseCreateAppointmentAlert,
  handleOpenAppointmentModal,
} from "../../../../../../store/features/new-appointment";
import { lightenHexColor } from "../../../../../../utils/convertLightenHexColor";
import dayjs from "dayjs";
export default function useClientAppointmentSale() {
  const clientDetail = useSelector((state) => state.clientDetail.clientDetail);
  const createdClientDetail = useSelector(
    (state) => state.appointmentModal.createdClientDetails
  );

  //api working
  const { data: singleClient, refetch: refetchClient } =
    useGetSingleClientQuery(clientDetail._id);
  const openAppointmentModal = useSelector(
    (state) => state?.appointmentModal?.openNewAppointmentModal
  );

  const dispatch = useDispatch();

  const [appointmentsArray, setAppointmentsArray] = useState([]);
  const [salesArray, setSalesArray] = useState([]);
  const navigate = useNavigate();

  //functions

  const handleCloseAppointmentDialog = () => {
    dispatch(handleCloseCreateAppointmentAlert());
    refetchClient();
  };

  const handleInitialValues = () => {
    if (!singleClient) return;

    const { data } = singleClient.result || {};
    if (!data) return;
    const { appointments, user } = data;
    setSalesArray(user.sales);
    if (appointments?.length > 0) {
      const appointmentArray = appointments.map((appointment) => {
        const inputDate = new Date(appointment.date);
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        const formattedDate = inputDate.toLocaleDateString("en-US", options);
        const isCurrentContradication =
          user.contraindication.length > 0 &&
          user.contraindication[user.contraindication.length - 1]?.text
            ? true
            : false;
        let time = dayjs(appointment.startTime).format("HH:mm");
        time = convertTo12HourFormat(time);
        return {
          title: formattedDate,
          children: [
            {
              otherOptions: {
                color: appointment.services[0].category?.color,
                backgroundColor: lightenHexColor(
                  appointment.services[0].category.color,
                  0.9
                ),
                locationName: "Sydney",
                author:
                  appointment.user === null
                    ? "No Staff"
                    : `${appointment.user.firstName} ${appointment.user.lastName}`,
                time: time,
                appointmentId: appointment?._id,
                condition: {
                  isDepositPaid: appointment.isPaidFull,
                  isInvoicePaid: false,
                  isBookedOnline: false,
                  isContraindication: isCurrentContradication,
                  isNotes: user.notes?.length > 0 ? true : false,
                  isRecurringAppointment: appointment.isRecurring,
                  isMaintenance: false,
                  isNewClient: user.clientStatus === "NEW" ? true : false,
                  isClientAttended:
                    appointment?.status === "COMPLETED" ? true : false,
                  isClientNoShow:
                    appointment?.status === "NO_SHOW" ? true : false,
                  isAppointmentCancelled:
                    appointment?.status === "CANCELLED" ? true : false,
                },
              },
              component: SpaAppointmentCard,
            },
          ],
        };
      });

      const objectsArray = Object.values(
        appointmentArray.reduce((acc, curr) => {
          const { title, children } = curr;
          acc[title] = acc[title] || { title, children: [] };
          acc[title].children.push(...children);
          return acc;
        }, {})
      );
      setAppointmentsArray(objectsArray);
    }
  };

  useEffect(() => {
    handleInitialValues();
  }, [singleClient]);

  return {
    clientDetail,
    appointmentsArray,
    salesArray,
    openAppointmentModal,
    dispatch,
    handleOpenAppointmentModal,
    handleCloseAppointmentDialog,
    navigate,
    createdClientDetail,
    singleClient,
  };
}
