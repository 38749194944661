import { useEffect, useState } from "react";
import { useGetAllCountriesQuery } from "../../../services/Countries";

export default function useCountryList() {
  const { data } = useGetAllCountriesQuery();
  const [countries, setCountries] = useState([]);

  const handleCountries = () => {
    if (data?.data.length > 0) {
      const countryArray = data?.data.map((singleCountry) => ({
        value: singleCountry.country,
        label: singleCountry.country,
      }));

      setCountries(countryArray);
    }
  };

  useEffect(() => {
    handleCountries();
  }, [data]);

  

  return {countries};
}
