import React from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import {
  ArrowRight,
  CalendarBlank,
  CallBell,
  Circle,
  Clock,
  Door,
  HandHeart,
  LinkBreak,
  ShootingStar,
  SketchLogo,
  StarHalf,
  User,
} from "@phosphor-icons/react";
import dayjs from "dayjs";
import NoNotifications from "../../../../../../../../assets/svg/no-notifications";
import {
  handleCloseAppointmentModal,
  handleCloseCreateAppointmentAlert,
  handleSingleAppointmentData,
  handleSingleAppointmentModal,
} from "../../../../../../../../store/features/new-appointment";
import { fs12, fs14, fs16, fw600 } from "../../../../../../../../theme";
import theme from "../../../../../../../../theme/theme";
import { nameInitialsAvatar } from "../../../../../../../../utils/nameInitialsAvatar";
import { calendarBox } from "../../../../../../../calendar/calendar-style";
import SPAAlertDialog from "../../../../../../../common/spa-alert-dialogue";
import NewAppointmentForm from "../../../../../../../new-appointment-form";
import { overview } from "../../../../style";
import useTreatmentAppointmentSale from "./useTreatmentAppointmentSale";
export default function Appointment({
  singleStaffData,
  singleStaffRefetching,
  singleServiceData,
  singleServiceRefetching,
}) {
  const {
    appointmentsArray,
    openAppointmentModal,
    dispatch,
    handleOpenAppointmentModal,
    clientDetail,
    createdClientDetail,
    handleCloseAppointmentDialog,
    summaryElements,
    commentDetails,
    singleAppointment,
    refetchSingleAppointment,
    singleAppointmentLoader,
    setSingleAppointmentId,
    singleAppointmentId,
    navigate,
  } = useTreatmentAppointmentSale({
    singleStaffData,
    singleStaffRefetching,
    singleServiceData,
    singleServiceRefetching,
  });

  const statusDesign = {
    OPEN: {
      icon: <ShootingStar size={16} />,
      color: "#1AA37A",
      bgColor: "#E9FBF6",
    },
    ACTIVE: {
      icon: <ShootingStar size={16} />,
      color: "#1AA37A",
      bgColor: "#E9FBF6",
    },
    VIP: {
      icon: <SketchLogo size={16} />,
      color: "#FA9200",
      bgColor: "#FFF9F0",
    },
    NEW: {
      icon: <LinkBreak size={16} />,
      color: "#4A4C4F",
      bgColor: "#FAFAFA",
    },
    LOST: {
      icon: <LinkBreak size={16} />,
      color: "#4A4C4F",
      bgColor: "#FAFAFA",
    },
    RETURN: {
      icon: <StarHalf size={16} />,
      color: "#115ACB",
      bgColor: "#E7EFFC",
    },
  };

  const appointmentSummary = (info) => {
    let formData = [
      {
        id: 1,
        title: "Client",
        icon: User,
        bgcolor: false,
        component: (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                cursor: "pointer",
              }}
              // onClick={() => {
              //   handleOpenClientProfile();
              //   dispatch(setClientDetails(info?.client));
              // }}
            >
              {info?.client?.profilePictureUrl ? (
                <Avatar
                  sx={{ width: "32px", height: "32px" }}
                  src={info?.client?.profilePictureUrl}
                  variant="rounded"
                />
              ) : (
                <Avatar
                  {...nameInitialsAvatar(
                    `${info?.client?.firstName} ${info?.client?.lastName}`,
                    "32px"
                  )}
                  variant="rounded"
                />
              )}
              <Typography
                sx={{
                  color: "#4E45E5",
                  fontSize: fs14,
                  fontWeight: fw600,
                  width: 100,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {`${info?.client?.firstName} ${info?.client?.lastName}`}
              </Typography>
            </Box>
          </>
        ),
        status: (
          <Box
            sx={{
              backgroundColor:
                statusDesign[info?.client?.clientStatus]?.bgColor,
              color: statusDesign[info?.client?.clientStatus]?.color,
              ...calendarBox.slotDetailsStatusInfo,
            }}
          >
            {statusDesign[info?.client?.clientStatus]?.icon}
            {info?.client?.clientStatus}
          </Box>
        ),
      },
      {
        id: 2,
        title: "Staff",
        icon: HandHeart,
        bgcolor: true,
        component: (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              {info?.user?.profilePictureUrl ? (
                <Avatar
                  sx={{ width: "32px", height: "32px" }}
                  src={info?.user?.profilePictureUrl}
                  variant="rounded"
                />
              ) : (
                <Avatar
                  {...nameInitialsAvatar(
                    `${info?.user?.firstName} ${info?.user?.lastName}`,
                    "32px"
                  )}
                  variant="rounded"
                />
              )}
              <Typography
                sx={{ color: "#4E45E5", fontSize: fs14, fontWeight: fw600 }}
              >
                {`${info?.user?.firstName} ${info?.user?.lastName}`}
              </Typography>
            </Box>
          </>
        ),
      },
      {
        id: 3,
        title: "Service",
        icon: CallBell,
        bgcolor: false,
        component: (
          <Box sx={calendarBox.appointmentServiceBox}>
            <Circle
              size={16}
              weight="fill"
              color={info?.services[0]?.category?.color}
            />
            {info?.services[0]?.name}
          </Box>
        ),
      },
      {
        id: 4,
        title: "Room",
        icon: Door,
        bgcolor: true,
        component: (
          <Box sx={calendarBox.appointmentServiceBox}>{info?.room?.name}</Box>
        ),
      },
      {
        id: 5,
        title: "Date",
        icon: CalendarBlank,
        bgcolor: false,
        component: (
          <Box sx={calendarBox.appointmentServiceBox}>
            {dayjs(info?.date).format("MMM DD, YYYY")}
          </Box>
        ),
      },
      {
        id: 6,
        title: "Time",
        icon: Clock,
        bgcolor: true,
        component: (
          <Box sx={calendarBox.appointmentServiceBox}>
            {dayjs(info?.startTime).format("hh:mm A")}
            <ArrowRight color="#8F9193" size={24} />{" "}
            {dayjs(info?.endTime).format("hh:mm A")}
          </Box>
        ),
      },
    ];

    return formData;
  };
  return (
    <Grid
      container
      columnGap={2}
      rowGap={{ xs: 3, md: 0 }}
      sx={{ height: "100%" }}
    >
      <Grid
        xs={12}
        md={6}
        style={{
          padding: "16px",
          borderRadius: "8px",
          gap: "16px",
          display: "flex",
          flexDirection: "column",
          height: "auto",
        }}
        bgcolor={theme.palette.purple10.main}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "4px" }}>
            <CalendarBlank size={18} />
            <Typography
              fontSize={fs14}
              fontWeight={fw600}
              color={theme.palette.textGraySecondary.main}
            >
              Appointments
            </Typography>
          </Box>
        </Box>
        {appointmentsArray?.length > 0 ? (
          <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
            {appointmentsArray?.map((item, index) => (
              <>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"8px"}
                  key={index}
                >
                  <Typography sx={overview.appointmentCardTitle}>
                    {item?.title}
                  </Typography>
                  {item?.children?.map((itemChild, c_index) => {
                    return (
                      <Box
                        key={`${index} ${c_index}`}
                        onClick={() => {
                          setSingleAppointmentId(itemChild?.appointmentId);
                        }}
                        sx={{
                          cursor: "pointer",
                          borderRadius: "8px",
                          // backgroundColor:
                          //   singleAppointmentId === itemChild?.appointmentId
                          //     ? "#4E45E5"
                          //     : "#FFFFFF",
                        }}
                        // color={
                        //   singleAppointmentId === itemChild?.appointmentId
                        //     ? "#FFFFFF"
                        //     : "#000000"
                        // }
                      >
                        <itemChild.component {...itemChild?.otherOptions} />
                      </Box>
                    );
                  })}
                </Box>
              </>
            ))}
          </Box>
        ) : (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            overflow={"hidden"}
          >
            <NoNotifications />
            <Typography sx={{ fontSize: fs16, color: "#595959" }}>
              No Appointment Yet
            </Typography>
            <Typography sx={{ fontSize: fs12, color: "#595959" }}>
              After creating Appointment, they will be displayed here as
              Appointment.
            </Typography>
          </Box>
        )}
      </Grid>

      {/* APPOINTMENTS PREVIEW */}

      {singleAppointment ? (
        <Grid
          xs={12}
          md={5.7}
          style={{
            padding: "16px",
            borderRadius: "8px",
            overflow: "auto",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
          }}
          // bgcolor={theme.palette.purple10.main}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: "4px", flexDirection: "column" }}>
              <Typography
                fontSize={fs14}
                fontWeight={fw600}
                color={theme.palette.textGraySecondary.main}
              >
                APPOINTMENT
              </Typography>
              <Typography
                fontSize={fs14}
                fontWeight={fw600}
                color={theme.palette.textGraySecondary.main}
              >
                {`${singleAppointment?.result?.data?.client?.firstName} ${singleAppointment?.result?.data?.client?.lastName}`}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "4px",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              <Typography
                fontSize={fs14}
                fontWeight={fw600}
                color={theme.palette.primaryColor.main}
                onClick={() => {
                  navigate("/calendar");
                  dispatch(handleSingleAppointmentModal());
                  dispatch(
                    handleSingleAppointmentData(
                      singleAppointment?.result?.data?._id
                    )
                  );
                }}
              >
                View in Calendar
              </Typography>
            </Box>
          </Box>

          <Box sx={{ backgroundColor: "#FFFFFF" }}>
            <Typography sx={calendarBox.appointmentSummary}>Summary</Typography>
            {appointmentSummary(singleAppointment?.result?.data).map((item) => {
              return (
                <Box
                  key={item.id}
                  sx={{
                    backgroundColor: item.bgcolor && "#F8F7FE",
                    ...calendarBox.appointmentSummaryBox,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "48px",
                      justifyContent: {
                        md: "flex-start",
                        xs: "space-between",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "92px" },
                        ...calendarBox.summaryLabel,
                      }}
                    >
                      <item.icon size={16} color="#4A4C4F" />
                      {item?.title}
                    </Box>
                    <Box>{item?.component}</Box>
                  </Box>
                  <Box sx={calendarBox.appointmentStatusLabel}>
                    {item?.status}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Grid>
      ) : (
        <Grid
          xs={12}
          md={5.7}
          style={{
            padding: "16px",
            borderRadius: "8px",
            overflow: "auto",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          bgcolor={theme.palette.purple10.main}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            overflow={"hidden"}
          >
            <NoNotifications />
            <Typography sx={{ fontSize: fs16, color: "#595959" }}>
              No Appointment chosen yet
            </Typography>
            <Typography sx={{ fontSize: fs12, color: "#595959" }}>
              Chose an Appointment, to view its summary.
            </Typography>
          </Box>
        </Grid>
      )}

      {/* appointment */}
      {openAppointmentModal && (
        <NewAppointmentForm
          getAllClients={() => {}}
          isAppointmentOpen={openAppointmentModal}
          handleClose={() => dispatch(handleCloseAppointmentModal())}
        />
      )}

      <SPAAlertDialog
        handleClose={handleCloseAppointmentDialog}
        title={"New Appointment Created"}
        contentText={`New appointment for ${createdClientDetail?.clientName} was created. For the date ${createdClientDetail?.date}`}
        disagreeText={`Create another appointment`}
        agreeText={`Close`}
        handleNewTab={() => {
          dispatch(handleOpenAppointmentModal(clientDetail));
          dispatch(handleCloseCreateAppointmentAlert());
        }}
      />
    </Grid>
  );
}
