import {
  Autocomplete,
  TextField,
  FormControl,
  Box,
  FormLabel,
  Divider,
  Typography,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { customColors, fs14, fw400, secondary } from "../../../theme";
import {
  LinkBreak,
  ShootingStar,
  SketchLogo,
  StarHalf,
  Warning,
} from "@phosphor-icons/react";
import { autoCompleteBox } from "./autocompleteStyle";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "270px",
    maxHeight: "307px",
    bgcolor: "#ffffff !important",
  },
});

const statusDesign = {
  OPEN: {
    icon: <ShootingStar size={16} />,
    color: "#1AA37A",
    bgColor: "#E9FBF6",
  },
  ACTIVE: {
    icon: <ShootingStar size={16} />,
    color: "#1AA37A",
    bgColor: "#E9FBF6",
  },
  VIP: {
    icon: <SketchLogo size={16} />,
    color: "#FA9200",
    bgColor: "#FFF9F0",
  },
  NEW: {
    icon: <LinkBreak size={16} />,
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  LOST: {
    icon: <LinkBreak size={16} />,
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  RETURN: {
    icon: <StarHalf size={16} />,
    color: "#115ACB",
    bgColor: "#E7EFFC",
  },
};

const renderLabel = (selectedValue, options) => {
  const selectedOption = options.find(
    (option) => option.value === selectedValue || option.id === selectedValue
  );
  return selectedOption ? selectedOption.label : null;
};

const CustomPaper = (props) => {
  return (
    <Box
      {...props}
      sx={{
        maxHeight: 300,
        overflowY: "auto",
        background: customColors.white,
        border: "1px solid #DADADB",
        borderRadius: "8px",
        boxShadow: "0px 12px 16px -4px #10182814",
      }}
    />
  );
};

export default function AutoCompleteDetails({
  control,
  name,
  placeholder,
  label,
  readOnly,
  helperText,
  labelHelperText,
  options = [], // Default to an empty array if options is not provided
  onChangeText,
  ...rest
}) {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, field, value },
          fieldState: { error: fieldError },
        }) => (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            {label && (
              <FormLabel
                sx={{
                  color: "#5E6062",
                  fontWeight: fw400,
                  fontSize: fs14,
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                  mb: labelHelperText && "4px",
                }}
              >
                {label}
                {!labelHelperText && (
                  <Typography sx={{ color: "#D13438", pl: "3px" }}>
                    {" "}
                    *
                  </Typography>
                )}
              </FormLabel>
            )}
            <Autocomplete
              {...field}
              readOnly={readOnly}
              sx={{
                backgroundColor: customColors.white,
                height: "44px !important",
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  borderRadius: "6px",
                  height: "44px",
                },
                "&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                  { padding: "0px 3px" },
              }}
              options={options}
              value={options.find((option) => option.id === value) || null}
              renderOption={(props, option) => {
                return (
                  <Box
                    key={option.label}
                    sx={{
                      background: customColors.white,
                      padding: "8px",
                      cursor: "pointer",
                    }}
                    {...props}
                  >
                    <Typography
                      sx={{
                        fontSize: fs14,
                        fontWeight: fw400,
                        color: secondary.main,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>
                        {option.label.includes("New Client") && option.icon}
                      </span>
                      <CustomWidthTooltip
                        title={
                          <Box sx={autoCompleteBox.slotDetailBox}>
                            <Box sx={autoCompleteBox.slotDetailsStatus}>
                              <Typography sx={autoCompleteBox.slotDetailsName}>
                                {option.value}
                              </Typography>
                              {option.status && (
                                <Box
                                  sx={{
                                    backgroundColor:
                                      statusDesign[
                                        option.status ? option.status : "NEW"
                                      ].bgColor,
                                    color:
                                      statusDesign[
                                        option.status ? option.status : "NEW"
                                      ].color,
                                    ...autoCompleteBox.slotDetailsStatusInfo,
                                  }}
                                >
                                  {
                                    statusDesign[
                                      option.status ? option.status : "NEW"
                                    ].icon
                                  }
                                  {option.status ? option.status : "NEW"}
                                </Box>
                              )}
                            </Box>
                            <Box sx={autoCompleteBox.slotDetailsStatus}>
                              <Typography sx={autoCompleteBox.slotDetailsName}>
                                {option.mobileNumber}
                              </Typography>
                            </Box>
                          </Box>
                        }
                        sx={{
                          "& .MuiTooltip-tooltip": {
                            bgcolor: "#ffffff",
                            borderRadius: "16px",
                            padding: "20px",
                            boxShadow:
                              "3px 2px 8px 0px rgba(78, 69, 229, 0.05), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -66px 62px 25px 0px rgba(78, 69, 229, 0.00);",
                          },
                        }}
                      >
                        {option.label}
                      </CustomWidthTooltip>{" "}
                    </Typography>
                    {option.label.includes("New Client") && (
                      <Divider
                        sx={{ px: "0px !important", paddingTop: "8px" }}
                      />
                    )}
                  </Box>
                );
              }}
              getOptionLabel={(option) => option.label || ""}
              onChange={(_, values) => {
                if (values?.id) onChange(values.id);
                else onChange(values.label);
              }}
              PaperComponent={CustomPaper}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  variant="outlined"
                  sx={{
                    backgroundColor: customColors.white,
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${secondary.main} !important`,
                      borderWidth: "1px !important",
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFAA33  !important",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#D0D5DD !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${secondary.main} !important`,
                    },
                  }}
                  helperText={
                    fieldError && (
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          fontSize: "12px",
                          color: "#FFAA33",
                        }}
                      >
                        <Warning size={16} weight="fill" />{" "}
                        {fieldError?.message}
                      </Typography>
                    )
                  }
                />
              )}
            />
          </Box>
        )}
      />
    </FormControl>
  );
}
