import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreateReminderMutation,
  useGetAllRemindersQuery,
  useGetRemindersMutation,
  useGetSingleReminderQuery,
  useUpdateReminderMutation,
} from "../../../services/reminders";
import { validationSchema } from "./validationSchema";
import { handleFilterSearch } from "../../../utils/searchFilter";

export default function useReminders() {
  const editorRef = useRef([]);
  const [searchReminder, setSearchReminder] = useState("");
  const [reminderType, setReminderType] = useState("");
  const [activeReminderTab, setActiveReminderTab] = useState(0);
  const [reminderDataType, setReminderDataType] = useState("");
  const [isSuccessMsg, setIsSuccessMsg] = useState(false);
  const [reminderId, setReminderId] = useState(null);
  const validationSchemaInstance = validationSchema(reminderType);
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
  });
  // const { data: remindersData, isLoading: remindersDataLoader } =
  //   useGetAllRemindersQuery(reminderType);
  const [createReminder, { isLoading: createReminderLoader }] =
    useCreateReminderMutation();
  const { data: singleReminder, isLoading: singleReminderLoader } =
    useGetSingleReminderQuery(reminderId, {
      skip: !reminderId,
    });
  const [updateReminder] = useUpdateReminderMutation();
  const [
    getReminders,
    { data: remindersData, isLoading: remindersDataLoader },
  ] = useGetRemindersMutation();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const onSubmit = async (values) => {
    setIsSuccessMsg(false);
    let payload;
    if (reminderType === "SMS") {
      payload = {
        message: values?.message,
        appliesTo: values?.appliesTo,
        hours: values?.hours,
        when: values?.when,
        isEnableReminder: values?.isEnableReminder,
        type: "SMS",
      };
    }
    if (reminderType === "EMAIL") {
      payload = {
        message: values?.message,
        appliesTo: values?.appliesTo,
        subject: values?.subject,
        hours: values?.hours,
        when: values?.when,
        isEnableReminder: values?.isEnableReminder,
        type: "EMAIL",
      };
    }
    let response;

    if (singleReminder?.result?.data?._id && reminderId) {
      response = await updateReminder({
        id: singleReminder?.result?.data?._id,
        payload,
      });
    } else {
      response = await createReminder(payload);
    }

    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    toast.success(response?.data?.message);
    setIsSuccessMsg(true);
    getReminders();
    reset({
      name: "",
      message: "",
      subject: "",
      appliesTo: "",
      hours: null,
      when: "",
      isEnableReminder: false,
    });
  };

  const handleTabSelection = (item) => {
    setActiveReminderTab(item);
    setReminderId("");
    switch (item) {
      case 1:
        setReminderType("EMAIL");
        setReminderDataType("EMAIL");
        break;
      case 2:
        setReminderType("SMS");
        setReminderDataType("SMS");
        break;
      default:
        setReminderType("");
        setReminderDataType("");
        break;
    }
  };

  const handleSingleReminder = (item) => {
    setReminderId(item?._id);
    setReminderType(item?.type);
  };
  const handleCreateReminder = () => {
    reset({
      name: "",
      message: "",
      subject: "",
      appliesTo: "",
      hours: null,
      when: "",
      isEnableReminder: false,
    });
    setReminderId("");
  };

  const handleSearchReminder = () => {
    let attributeArray = [];
    if (searchReminder !== "") {
      if (isNaN(searchReminder)) attributeArray = ["subject"];
      else {
        attributeArray = ["subject"];
      }

      const searchPayload = handleFilterSearch(
        searchReminder,
        attributeArray,
        "search"
      );
      getReminders({ ...searchPayload });
    } else {
      getReminders({});
    }
  };

  useEffect(() => {
    handleSearchReminder();
  }, [searchReminder]);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      [`type=${reminderType}`],
      ["type"],
      "filter",
      "and"
    );
    if (reminderType !== "") {
      getReminders({ ...searchPayload });
    } else {
      getReminders();
    }
  }, [reminderType]);

  useEffect(() => {
    if (singleReminder && reminderId) {
      setValue("message", singleReminder?.result?.data?.message);
      setValue("subject", singleReminder?.result?.data?.subject ?? "");
      setValue("name", singleReminder?.result?.data?.name);
      setValue("appliesTo", singleReminder?.result?.data?.appliesTo);
      setValue("hours", singleReminder?.result?.data?.hours);
      setValue("when", singleReminder?.result?.data?.when);
      setValue(
        "isEnableReminder",
        singleReminder?.result?.data?.isEnableReminder === true ? true : false
      );
    } else {
      setValue("message", "");
      setValue("subject", "");
      setValue("name", "");
      setValue("appliesTo", "");
      setValue("hours", null);
      setValue("when", "");
      setValue("isEnableReminder", false);
    }
  }, [singleReminder, reminderId]);

  useEffect(() => {
    getReminders();
  }, []);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    isSuccessMsg,
    setIsSuccessMsg,
    createReminderLoader,
    remindersData,
    singleReminderLoader,
    remindersDataLoader,
    activeReminderTab,
    reminderType,
    setReminderType,
    handleTabSelection,
    reminderId,
    handleSingleReminder,
    handleCreateReminder,
    reset,
    editorRef,
    searchReminder,
    setSearchReminder,
  };
}
