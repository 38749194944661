import { MapPin, UserFocus } from "@phosphor-icons/react";
import SPACheckBox from "../../../hook-form/SPACheckBox";
import SPAInputField from "../../../hook-form/SPAInputField";
import SPAMobileDatePicker from "../../../hook-form/SPAMobileDatePicker";
import SPAMobileTimePicker from "../../../hook-form/SPAMobileTimerPicker";
import SPASelectField from "../../../hook-form/SPASelectField";
import ProfileImageUploader from "../../../profile-image-upload";

export const newStaffFormData = (serviceData) => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "firstName",
        label: "First Name",
        placeholder: "i.e.: John Doe",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "lastName",
        label: "Last Name",
        placeholder: "i.e.: John Doe",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "role",
        label: "Role",
        labelHelperText: "(Optional)",
        options: [
          { label: "Staff", value: "Staff" },
          { label: "Manager", value: "Manager" },
          { label: "Receptionist", value: "Receptionist" },
          { label: "Cleaning", value: "Cleaning" },
        ],
        placeholder: "Select Role",
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "state",
        label: "State",
        placeholder: "",
        options: [
          {
            label: "New South Wales (NSW)",
            value: "New South Wales (NSW)",
          },
          {
            label: "Queensland (QLD)",
            value: "Queensland (QLD)",
          },
          {
            label: "South Australia (SA)",
            value: "South Australia (SA)",
          },
          {
            label: "Tasmania (TAS)",
            value: "Tasmania (TAS)",
          },
          {
            label: "Victoria (VIC)",
            value: "Victoria (VIC)",
          },
          {
            label: "Western Australia (WA)",
            value: "Western Australia (WA)",
          },
          {
            label: "Australian Capital Territory (ACT)",
            value: "Australian Capital Territory (ACT)",
          },
          {
            label: "Northern Territory (NT)",
            value: "Northern Territory (NT)",
          },
        ],
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "street",
        label: "Street",
        placeholder: "",
      },
      component: SPAInputField,
    },
  ];
};

export const busyTimFormData = (RepeatOnGrid) => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        name: "startTime",
        label: "From",
        placeholder: "Select Time",
      },
      component: SPAMobileTimePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "endTime",
        label: "To",
        placeholder: "Select Time",
      },
      component: SPAMobileTimePicker,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "repeatOn",
        label: "Repeat On",
      },
      component: RepeatOnGrid,
    },
    {
      key: "reason",
      gridLength: 12,
      otherOptions: {
        name: "reason",
        label: "Reason",
        options: [
          { label: "Maintenance", value: "MAINTENACE" },
          { label: "Cleaning", value: "CLEANING" },
          { label: "Time Off", value: "TIME_OFF" },
          { label: "Holiday Closure", value: "HOLIDAY_CLOUSER" },
          { label: "Special Events", value: "SEPICAL_EVENTS" },
          { label: "Shift Staff", value: "SHIFT_STAFF" },
          { label: "Custom Reason", value: "CUSTOM_REASON" },
        ],
        placeholder: "Select Reason",
      },
      component: SPASelectField,
    },
    {
      key: "additionalNotes",
      gridLength: 12,
      otherOptions: {
        name: "note",
        label: "Additional Notes",
        labelHelperText: "(Optional)",
        type: "textarea",
        placeholder: "Add any other additional notes here",
      },
      component: SPAInputField,
    },
  ];
};

export const weekDays = [
  { label: "MON", value: "MON" },
  { label: "TUE", value: "TUE" },
  { label: "WED", value: "WED" },
  { label: "THU", value: "THU" },
  { label: "FRI", value: "FRI" },
  { label: "SAT", value: "SAT" },
  { label: "SUN", value: "SUN" },
];
export const staffGeneralInformation = (
  WarningBox,
  rolesData,
  currentEmployment
) => {
  return [
    {
      title: "General Information",
      icon: UserFocus,
      rowChildren: [
        {
          gridLength: 7.3,
          colChildren: [
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "firstName",
                label: "First Name",
                placeholder: "i.e.: John Doe",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "lastName",
                label: "Last Name",
                placeholder: "i.e.: John Doe",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                name: "role",
                label: "Role",
                options:
                  rolesData &&
                  rolesData?.map((item) => {
                    return { id: item._id, label: item.name, value: item.name };
                  }),
                placeholder: "Select Role",
              },
              component: SPASelectField,
            },
            {
              gridLength: 6,
              otherOptions: {
                name: "gender",
                label: "Gender",
                options: [
                  { value: "MALE", label: "Male" },
                  { value: "FEMALE", label: "Female" },
                ],
                placeholder: "Enter gender",
              },
              component: SPASelectField,
            },
          ],
        },
        {
          gridLength: 4.7,
          colChildren: [
            {
              colTitle: "Profile Picture",
              otherOptions: {
                width: "100px",
                height: "100px",
                uploadBtnText: "upload",
                name: "profilePictureUrl",
                profileUpload: true,
              },

              component: ProfileImageUploader,
            },
          ],
        },
        {
          gridLength: 12,
          colChildren: [
            // {
            //   gridLength: 12,
            //   component: WarningBox,
            // },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "email",
                label: "Email",
                placeholder: "i.e.: example@gmail.com",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "password",
                label: "Password",
                placeholder: "********",
                type: "password",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                name: "dateOfBirth",
                label: "Date of Birth",
                placeholder: "End Date",
                helperText: "(Optional)",
                disableFuture: true,
                disableHighlightToday: true,
                isCalenderShow: true,
              },
              component: SPAMobileDatePicker,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "mobileNumber",
                label: "Mobile Number",
                placeholder: "+61 412 345 678",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                name: "status",
                label: "Status",
                options: [
                  { value: "ACTIVE", label: "Active" },
                  { value: "INACTIVE", label: "Inactive" },
                  { value: "DRAFT", label: "Draft" },
                  { value: "ON_LEAVE", label: "On Leave" },
                  { value: "ARCHIVED", label: "Archived" },
                ],
                placeholder: "Select Status",
              },
              component: SPASelectField,
            },
            {
              gridLength: 6,
              otherOptions: {
                name: "employmentStartDate",
                label: "Start Date",
                placeholder: "Start Date",
                helperText: "(Optional)",
                isCalenderShow: true,
              },
              component: SPAMobileDatePicker,
            },
            {
              gridLength: 6,
              otherOptions: {
                name: "currentEmployment",
                label: "Currently Employed",
                helperText: "(Optional)",
              },
              component: SPACheckBox,
            },
            {
              gridLength: 6,
              otherOptions: {
                name: "employmentEndDate",
                label: "End Date",
                placeholder: "End Date",
                helperText: "(Optional)",
                fullWidth: true,
                isCalenderShow: true,
              },
              component: !currentEmployment === true && SPAMobileDatePicker,
            },
          ],
        },
      ],
    },
    {
      title: "Address",
      icon: MapPin,
      rowChildren: [
        {
          gridLength: 12,
          colChildren: [
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "street",
                label: "Street",
                placeholder: "",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "suburb",
                label: "Suburb",
                placeholder: "",
                // labelHelperText: "(Optional)",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "state",
                label: "State",
                placeholder: "",
                // labelHelperText: "(Optional)",
                options: [
                  {
                    label: "New South Wales (NSW)",
                    value: "New South Wales (NSW)",
                  },
                  {
                    label: "Queensland (QLD)",
                    value: "Queensland (QLD)",
                  },
                  {
                    label: "South Australia (SA)",
                    value: "South Australia (SA)",
                  },
                  {
                    label: "Tasmania (TAS)",
                    value: "Tasmania (TAS)",
                  },
                  {
                    label: "Victoria (VIC)",
                    value: "Victoria (VIC)",
                  },
                  {
                    label: "Western Australia (WA)",
                    value: "Western Australia (WA)",
                  },
                  {
                    label: "Australian Capital Territory (ACT)",
                    value: "Australian Capital Territory (ACT)",
                  },
                  {
                    label: "Northern Territory (NT)",
                    value: "Northern Territory (NT)",
                  },
                ],
              },
              component: SPASelectField,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "postalCode",
                label: "Post Code",
                placeholder: "",
              },
              component: SPAInputField,
            },
          ],
        },
      ],
    },
  ];
};

export const permissionTabsData = [
  {
    id: 1,
    label: "STANDARD",
  },
  {
    id: 2,
    label: "ADMIN",
  },
];

export const emergencyContactForm = () => {
  return [
    {
      gridLength: 3,
      otherOptions: {
        fullWidth: true,
        name: "contactFirstName",
        label: "Contact First Name",
        placeholder: "i.e.: Jane",
      },
      component: SPAInputField,
    },
    {
      gridLength: 3,
      otherOptions: {
        fullWidth: true,
        name: "contactLastName",
        label: "Contact Last Name",
        placeholder: "i.e.: Doe",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "relationship",
        label: "Relationship",
        placeholder: "Select Relationship",
        options: [
          {
            label: "Spouse",
            value: "SPOUSE",
          },
          {
            label: "Partner",
            value: "PARTNER",
          },
          {
            label: "Parent",
            value: "PARENT",
          },
          {
            label: "Friend",
            value: "FRIEND",
          },
          {
            label: "Sibling",
            value: "SIBLING",
          },
          {
            label: "Other",
            value: "OTHER",
          },
        ],
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "contactPhoneNumber",
        label: "Mobile Number",
        placeholder: "+61 412 345 678",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "contactEmail",
        label: "Email",
        placeholder: "example@gmail.com",
        labelHelperText: "(Optional)",
      },
      component: SPAInputField,
    },
  ];
};
