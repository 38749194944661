import * as Yup from "yup";

export const validationSchemaArticle = (selectedValues, articleStep) => {
  let schema;
  if (articleStep === 1) {
    schema = Yup.object().shape({
      title: Yup.string().required("Article Title is required"),
      template: Yup.string(),
      submitButton: Yup.string(),

      featureArticle: Yup.boolean(),
      showUntil: Yup.string().nullable(),

      showInHomeArticle: Yup.boolean(),

      notifyClientsArticle: Yup.boolean(),
      notifyOn: Yup.string().nullable(),
      status: Yup.string().nullable(),
    });
    selectedValues?.forEach((value) => {
      switch (value.type) {
        case "Paragraph":
          schema = schema.shape({
            [`paragraph${value.id}`]: Yup.string().required(
              "Paragraph is required"
            ),
          });
          break;
        case "Button":
          schema = schema.shape({
            [`buttonlabel${value.id}`]: Yup.string().required(
              "Button Label is required"
            ),
            [`buttonlink${value.id}`]: Yup.string().required(
              "Button Link is required"
            ),
          });
          break;
        case "Image":
          schema = schema.shape({
            [`alttext${value.id}`]: Yup.string().required(
              "Alt Text is required"
            ),
          });
          break;
        case "Video":
          schema = schema.shape({
            [`videoalttext${value.id}`]: Yup.string().required(
              "Alt Text is required"
            ),
          });
          break;
        case "Product":
          schema = schema.shape({
            [`product${value.id}`]: Yup.object().required(
              "Product is required"
            ),
          });
          break;
        case "Treatment":
          schema = schema.shape({
            [`treatment${value.id}`]: Yup.object().required(
              "Treatment is required"
            ),
          });
          break;
        default:
          break;
      }
    });
  }
  if (articleStep === 2) {
    schema = Yup.object().shape({
      thumbnail: Yup.string().required("Thumbnail is required"),
    });
  }

  return schema;
};
