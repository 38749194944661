import { Pencil } from "@phosphor-icons/react";

//popover mock data
export const roomsPopOverData = (setPopoverOpen, setIsRoomDialogOpen) => [
  {
    id: "1",
    label: "Edit",
    icon: Pencil,
    onClick: () => {
      setPopoverOpen(null);
      setIsRoomDialogOpen(true);
    },
  },
];
