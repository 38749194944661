import * as Yup from "yup";

export const validationProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  status: Yup.string().required("Status is required"),
  dateOfBirth: Yup.string().nullable(),
  mobileNumber: Yup.string()
    .matches(
      /^\+?\d{10,14}$/,
      "Invalid format. Provide Valid Format of 10 to 14 digits"
    )
    .test(
      "mobile-format",
      "Invalid format. Provide Valid Format of 10 to 14 digits",
      function (value) {
        if (!value) return true;
        return /^\+?\d{10,14}$/.test(value);
      }
    )
    .required("Mobile Number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  role: Yup.string().required("Role is required"),
  gender: Yup.string().required("Gender is required"),
  note: Yup.string(),
  currentEmployment: Yup.boolean(),
});

export const validationAddressSchema = Yup.object().shape({
  postalCode: Yup.string().required("Post code is required"),
  country: Yup.string(),
  // city: Yup.string(),
  state: Yup.string().required("State is required"),
  street: Yup.string().required("Street is required"),
  suburb: Yup.string().required("Suburb is required"),
  _id: Yup.string(),
});

export const validationEmergencySchema = Yup.object().shape({
  contactFirstName: Yup.string().required("Emergency First Name is required"),
  contactLastName: Yup.string().required("Emergency Last Name is required"),
  relationship: Yup.string().required("Relationship is required"),
  contactPhoneNumber: Yup.string()
    .matches(
      /^\+?\d{10,14}$/,
      "Invalid format. Provide Valid Format of 10 to 14 digits"
    )
    .test(
      "mobile-format",
      "Invalid format. Provide Valid Format of 10 to 14 digits",
      function (value) {
        if (!value) return true;
        return /^\+?\d{10,14}$/.test(value);
      }
    ),
  contactEmail: Yup.string().email("Invalid email"),
});
