import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { DotsSixVertical, SortAscending } from "@phosphor-icons/react";
import React from "react";
import NoNotifications from "../../../../../assets/svg/no-notifications";
import { fs12, fs14, fs16, fw600 } from "../../../../../theme";
import SPAAlertDialog from "../../../../common/spa-alert-dialogue/index.jsx";
import SPADialogue from "../../../../common/spa-dialogue";
import FormProvider from "../../../../hook-form/FormProvider";
import SPAInputField from "../../../../hook-form/SPAInputField";
import { SubmitBtn } from "../../../../profile-model/components/sales/salesStyle";
import useOnboard from "../useOnboard";
import { DisabledButton, EnabledButton } from "../viewOnboarding/Buttons";
import NoPackage from "../../../../../assets/svg/no-package.jsx";

export const ViewOnboarding = ({
  singleOnboardingData,
  methods,
  control,
  handleSubmit,
  onSubmit,
  handleSortPositionHandling,
  enabled,
  toggleSwitch,
  handleOpenOnboardingDialogue,
  isOpenEditOnboardingDialogue,
  handleCloseOnboardingDialogue,
  handleSort,
  dragItem,
  dragOverItem,
  isOpenDisabledDialogue,
  handleDisableConfirm,
  setIsOpenDisabledDialogue,
  sortedOnboardingArray,
}) => {
  const { getOrdinalSuffix } = useOnboard();

  const Container = styled(Box)(({ theme, disabled }) => ({
    position: "relative",
    padding: theme.spacing(2),
    background: disabled ? "rgba(255, 255, 255, 0.7)" : "#FFFFFF",
    borderRadius: theme.shape.borderRadius,
    transition: "filter 0.3s ease-in-out",
  }));

  const OverlayText = styled(Box)(({ theme }) => ({
    fontWeight: fw600,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    zIndex: "2",
  }));

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            bgcolor: "#EDECFC",
            borderRadius: "8px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              padding: "16px 16px 0px 16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "10px", color: "#25272B" }}>
                {enabled ? "ENABLED" : "DISABLED"}
              </Typography>
              <Typography sx={{ fontSize: "20px", color: "#5E6062" }}>
                {singleOnboardingData?.title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#EDECFC",
                borderRadius: "8px  ",
              }}
            >
              <EnabledButton
                active={enabled === true ? true : null}
                onClick={toggleSwitch}
              >
                Enabled
              </EnabledButton>
              <DisabledButton
                active={enabled === false ? true : null}
                onClick={toggleSwitch}
              >
                Disabled
              </DisabledButton>
            </Box>
          </Box>

          <Box
            sx={{
              gap: 2,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "16px",
            }}
          >
            <Container disabled={!enabled}>
              {!enabled && (
                <OverlayText
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: fw600, fontSize: fs14, color: "#4A4C4F" }}
                  >
                    Enable onboarding screen to modify its position
                  </Typography>
                </OverlayText>
              )}
              <Typography
                sx={{ fontWeight: fw600, fontSize: fs14, color: "#4A4C4F" }}
              >
                Position in the arrangement
              </Typography>
              <Typography
                sx={{ fontWeight: fw600, fontSize: fs12, color: "#5E6062" }}
                mb={2}
              >
                This is the position where the onboarding screen will be shown
                to the client.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {enabled && (
                  <Button
                    disabled={
                      singleOnboardingData?.isEnableOnboarding !== "ENABLED"
                    }
                    variant="outlined"
                    disableElevation
                    disableRipple
                  >
                    {/* {singleOnboardingData?.positionArrangment
                      ? singleOnboardingData?.positionArrangment
                      : "0"} */}
                    {singleOnboardingData?.isEnableOnboarding === "ENABLED"
                      ? singleOnboardingData?.positionArrangment
                      : "0"}
                  </Button>
                )}

                <Button
                  disableElevation
                  sx={{ color: "#4E45E5", bgcolor: "#EDECFC" }}
                  startIcon={<SortAscending size={24} />}
                  onClick={handleOpenOnboardingDialogue}
                  disabled={!enabled}
                >
                  Arrange Onboarding
                </Button>
              </Box>
            </Container>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "16px",
                gap: "16px",
                bgcolor: "#FFFFFF",
                borderRadius: "8px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    paddingBottom: "16px",
                    fontWeight: fw600,
                    color: "#5E6062",
                  }}
                >
                  Content
                </Typography>
                <Box>
                  <SPAInputField
                    name="title"
                    label="Title"
                    type="text"
                    placeholder="Enter the title here"
                    control={control}
                  />
                  <SPAInputField
                    name="subtitle"
                    label="Subtitle"
                    type="textarea"
                    placeholder="Enter the subtitle here"
                    control={control}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "16px",
              padding: "16px",
            }}
          >
            <Button
              disableElevation
              variant="contained"
              sx={{
                background: "#EDECFC",
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                height: "48px",
                color: "#4E45E5",
                "&:hover": { background: "#EDECFC", color: "#4E45E5" },
              }}
            >
              Cancel
            </Button>
            <Button sx={SubmitBtn} type="submit">
              Save Changes
            </Button>
          </Box>
        </Box>
      </FormProvider>

      {isOpenEditOnboardingDialogue && (
        <>
          <SPADialogue
            open={isOpenEditOnboardingDialogue}
            handleClose={handleCloseOnboardingDialogue}
            dialogTitle={`Arrange onboarding screens`}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "30vw",
                flexDirection: "column",
                gap: "16px",
                borderRadius: "8px",
                bgcolor: "#F8F7FE",
                justifyContent: "flex-start",
                minHeight: "60vh",
                maxHeight: "60vh",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  paddingInline: "16px",
                  paddingTop: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#5E6062",
                  }}
                >
                  Drag and drop the onboarding screens to rearrange the order.
                </Typography>
              </Box>

              {sortedOnboardingArray?.map((item, index) => (
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "6px",
                  }}
                  key={index}
                  draggable
                  onDragStart={() => (dragItem.current = index)}
                  onDragEnter={() => (dragOverItem.current = index)}
                  onDragEnd={handleSort}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <Grid item md={2}>
                    <Button
                      variant="outlined"
                      disableElevation
                      disableRipple
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderColor: "#4E45E5",
                        color: "#5E6062",
                      }}
                    >
                      {/* {getOrdinalSuffix(item?.positionArrangment)} */}
                      {getOrdinalSuffix(index + 1)}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={9}
                    key={item?._id}
                    sx={{
                      borderRadius: "16px",
                      bgcolor: "#EDECFC",
                      padding: "16px",
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DotsSixVertical size={24} />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#4A4C4F",
                          fontWeight: "600",
                        }}
                      >
                        {item?.title}
                      </Typography>
                    </Box>
                    <Button
                      // onClick={() => setIsOpenDisabledDialogue(true)}
                      sx={{
                        fontSize: "14px",
                        color:
                          item?.isEnableOnboarding === "ENABLED"
                            ? "#1AA37A"
                            : "#D13438",
                        fontWeight: "600",
                      }}
                      variant="text"
                    >
                      {item?.isEnableOnboarding}
                    </Button>
                  </Grid>
                </Grid>
              ))}
              {sortedOnboardingArray?.length === 0 && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  overflow={"hidden"}
                >
                  <NoNotifications />
                  <Typography sx={{ fontSize: fs16, color: "#595959" }}>
                    No Onboardings Yet
                  </Typography>
                  <Typography sx={{ fontSize: fs12, color: "#595959" }}>
                    After creating Onboardinsgs, they will be displayed here as
                    onboardings.
                  </Typography>
                </Box>
              )}
              {sortedOnboardingArray?.length === 1 && (
                <Box
                  sx={{
                    mt: "18px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "24px",
                  }}
                >
                  <NoPackage />

                  <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                    Create more onboard screens to arrange them.
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "flex-end",
                mt: "8px",
              }}
            >
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: "#EDECFC",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  height: "48px",
                  color: "#4E45E5",
                  "&:hover": { background: "#EDECFC", color: "#4E45E5" },
                }}
                onClick={handleCloseOnboardingDialogue}
              >
                Cancel
              </Button>
              <Button sx={SubmitBtn} onClick={handleSortPositionHandling}>
                Save Changes
              </Button>
            </Box>
          </SPADialogue>
        </>
      )}
      {isOpenDisabledDialogue && (
        <>
          <SPAAlertDialog
            open={isOpenDisabledDialogue}
            handleClose={handleDisableConfirm}
            handleNewTab={() => setIsOpenDisabledDialogue(false)}
            title={"Onboarding Screen Disabled"}
            contentText={` Do you want to check the new onboarding screens arrangement?`}
            disagreeText={`Close`}
            agreeText={`Check New Arrangement`}
            type="info"
          />
        </>
      )}
    </>
  );
};
