import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import { Circle } from "@phosphor-icons/react";
import SPAInputField from "../../../hook-form/SPAInputField";

const ColorField = ({ control, methods, ...rest }) => {
  const handleColorChange = (event) => {
    const color = event.target.value;
    methods.setValue("color", color);
  };
  const currentColor = methods.watch("color");

  return (
    <Box sx={{ zIndex: "10000" }}>
      <Typography
        sx={{
          color: "#5E6062",
          fontSize: "14px",
          mb: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Color <Typography sx={{ color: "#D13438", pl: "3px" }}> *</Typography>
      </Typography>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <Button component="label" role={undefined} tabIndex={-1}>
          <Circle
            size={40}
            weight="fill"
            color={currentColor ? `${currentColor}` : "#000000"}
          />
          <VisuallyHiddenInput
            type="color"
            value={currentColor}
            onChange={handleColorChange}
          />
        </Button>
        <SPAInputField control={control} {...rest} />
      </Box>
    </Box>
  );
};

export default ColorField;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
