import { baseAPI } from "../baseApi";

export const OnboardingApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createOnboarding: builder.mutation({
      query: (payload) => ({
        url: `/onboarding`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["onboarding"],
    }),

    getAllOnboardings: builder.mutation({
      query: (payload) => ({
        url: `/onboarding`,
        method: "PATCH",
        body: payload,
      }),
      providesTags: ["onboarding"],
    }),

    getSingleOnboarding: builder.query({
      query: (id) => ({ url: `/onboarding/${id}` }),
      providesTags: ["onboarding"],
    }),

    updateSingleOnboarding: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/onboarding/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["onboarding"],
    }),
    updateOnboardingPosition: builder.mutation({
      query: (payload) => ({
        url: `/onboarding/update-position`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["onboarding"],
    }),
  }),
});

export const {
  useCreateOnboardingMutation,
  useUpdateSingleOnboardingMutation,
  useUpdateOnboardingPositionMutation,
  useGetAllOnboardingsMutation,
  useGetSingleOnboardingQuery,
} = OnboardingApi;
