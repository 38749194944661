import { FilterList } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Plus } from "@phosphor-icons/react";
import dayjs from "dayjs";
import React from "react";
import NoNotifications from "../../../assets/svg/no-notifications";
import { secondary } from "../../../theme";
import SPATabs from "../../common/spa-tabs";
import SPATextField from "../../common/spa-textField";
import CreateNewArticle from "./create-article";
import { articleTabsArray } from "./article-form-data";
import useArticles from "./useArticles";

export default function ArticlesSection() {
  const {
    activeHistoryTab,
    handleTabSelection,
    handleCreateArticle,
    openDialogCreateArticle,
    handleCloseCreateArticle,
    articlesData,
    setSearchArticle,
    articlesDataLoading,
    getAllArticles,
    articleId,
    setArticleId,
  } = useArticles();

  return (
    <>
      {articlesDataLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={articlesDataLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  height: "48px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleCreateArticle}
              >
                <Plus size={24} color="currentColor" />
                Create Article
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              <SPATextField
                placeholder="Search"
                sx={{
                  width: { md: "328px", xs: "180px" },
                  "& .MuiOutlinedInput-root": {
                    height: "48px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} color={"#C1C2C3"} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    ></InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchArticle(event.target.value);
                }}
              />

              <Box sx={styles.searchbox}>
                <FilterList sx={{ color: secondary.main }} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Grid
          container
          columnGap={3}
          sx={{
            height: "75vh",
            gap: "16px",
          }}
        >
          <Grid item xs={3}>
            <SPATabs
              tabsNameArray={articleTabsArray}
              activeTab={activeHistoryTab}
              setActiveTab={handleTabSelection}
            />
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                maxHeight: "66vh",
                overflowY: "auto",
                mb: 2,
              }}
            >
              {articlesData?.result?.data?.data?.map((item) => {
                return (
                  <Box
                    key={item?._id}
                    sx={{
                      borderRadius: "16px",
                      bgcolor: articleId === item?._id ? "#4E45E5" : "#F8F7FE",
                      padding: "16px",
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setArticleId(item?._id);
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color:
                            item?.status === "PUBLISHED"
                              ? "#1AA37A"
                              : "#25272B",
                          bgcolor:
                            item?.status === "PUBLISHED"
                              ? "#E9FBF6"
                              : "#FAFAFA",
                          p: "4px",
                          borderRadius: "4px",
                        }}
                      >
                        {item.status}
                      </Typography>
                      <Box>
                        <Typography
                          color={
                            articleId === item?._id ? "#FFFFFF" : "#8F9193"
                          }
                          fontSize={"10px"}
                          textTransform={"uppercase"}
                        >
                          CREATED{" "}
                          {dayjs(
                            item?.createdAt
                              ? item?.createdAt
                              : "2023-12-31T23:59:59.000Z"
                          ).format("DD MMM, YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: articleId === item?._id ? "#FFFFFF" : "#4A4C4F",
                        mt: "8px",
                        fontWeight: "600",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                );
              })}
              {articlesData?.result?.data?.data?.length === 0 && (
                <Box
                  sx={{
                    mt: "18px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "24px",
                  }}
                >
                  <NoNotifications />
                  <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                    No Article Yet
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                    After creating Article, they will be displayed here as
                    Article.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              borderRadius: "16px",
              bgcolor: "#F8F7FE",
              flexGrow: "1 !important",
              maxWidth: "100% !important",
            }}
          >
            <Box
              sx={{
                p: "24px",
              }}
            >
              <CreateNewArticle
                createArticleButton={openDialogCreateArticle}
                handleClose={handleCloseCreateArticle}
                getAllArticles={getAllArticles}
                articleId={articleId}
                setArticleId={setArticleId}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
