// form
import { FormProvider as Form } from "react-hook-form";

// ----------------------------------------------------------------------

export default function FormProvider({ children, onSubmit, methods, ...rest }) {
  return (
    <Form {...methods} {...rest}>
      <form {...rest} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
}
