export const autoCompleteBox = {
  slotDetailBox: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  slotDetailsStatus: {
    display: "flex",
    justifyContent: "space-between",
    gap: "4px",
  },
  slotDetailsName: { fontSize: "16px", color: "#5E6062" },
  slotDetailsStatusInfo: {
    p: "4px 8px",
    width: "fit-content",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  slotDetailsDuration: { display: "flex", justifyContent: "space-between" },
  slotDetailsTime: { display: "flex", alignItems: "center", gap: "2px" },
  slotDetailsLabel: {
    backgroundColor: "#F8F7FE",
    p: "4px 8px",
    color: "#5E6062",
    width: "fit-content",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontSize: "10px",
  },
  slotDetailsValue: { fontSize: "14px", color: "#5E6062" },
};
