import { FormControl, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import SPATextField from "../common/spa-textField";
import { Warning } from "@phosphor-icons/react";

const SPAInputField = ({
  name,
  label,
  type,
  placeholder,
  helperText,
  labelHelperText,
  control,
  defaultValue,
  readOnly,
  incDecButton = false,
  ...rest
}) => {
  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error: fieldError } }) => (
          <SPATextField
            error={!!fieldError}
            name={name}
            incDecButton={incDecButton}
            helperText={
              fieldError && (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    fontSize: "12px",
                    color: "#FFAA33",
                  }}
                >
                  <Warning size={16} weight="fill" /> {fieldError?.message}
                </Typography>
              )
            }
            label={label}
            labelHelperText={labelHelperText}
            {...field}
            {...rest}
            type={type}
            placeholder={placeholder}
            disableunderline={true}
            defaultValue={defaultValue}
            readOnly={readOnly}
          />
        )}
      />
    </FormControl>
  );
};
export default SPAInputField;
