import * as Yup from "yup";

export const validationSchema = (type) => {
  let schema;
  if (type === "Treatment") {
    schema = Yup.object().shape({
      services: Yup.array()
        .of(
          Yup.object().shape({
            _id: Yup.string().required("ID is required"),
            name: Yup.string(), // Add other params as needed
          })
        )
        .min(1, "At least one Treatment must be selected")
        .required("Selection of Treatment is required."),
    });
  } else {
    schema = Yup.object().shape({
      product: Yup.array()
        .of(
          Yup.object().shape({
            _id: Yup.string().required("ID is required"),
            name: Yup.string(),
          })
        )
        .min(1, "At least one product must be selected")
        .required("Selection of product is required."),
    });
  }

  return schema;
};
