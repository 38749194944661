// Invoice.js
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";

// Register a font if needed
Font.register({
  family: "Roboto",
  src: "https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 12,
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "auto",
    margin: "10px 0",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

// Create Document Component
const Invoice = ({ invoiceData }) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Invoice</Text>
          <Text>Invoice Number: {invoiceData?._id}</Text>
          <Text>Date: {dayjs(invoiceData?.buyDate).format("DD/MM/YYYY")}</Text>
        </View>
        <View style={styles.section}>
          <Text>Bill To:</Text>
          <Text>
            {invoiceData?.client.firstName} {invoiceData?.client.lastName}
          </Text>
          <Text>Email: {invoiceData?.client.email}</Text>
        </View>
        <View style={styles.section}>
          <Text>Items:</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Image</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Description</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Quantity</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Price</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Total</Text>
              </View>
            </View>
            {invoiceData?.products?.map((item) => (
              <View style={styles.tableRow} key={item?._id}>
                <View style={styles.tableCol}>
                  <Image style={styles.image} src={item?.product.imageUrl} />
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item?.product.name}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item?.quantity}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    ${item?.product.price.toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    ${(item?.quantity * item?.product.price).toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.section}>
          <Text>GST: ${invoiceData?.gst.toFixed(2)}</Text>
          <Text>
            Total Price with GST: ${invoiceData?.totalPriceWithGST.toFixed(2)}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
