export const handleFilterSearch = (
  searchContent,
  searchAttributesList,
  type,
  filterType = "or"
) => {
  let payload = {};
  const operator = filterType === "and" ? "$and" : "$or";
  if (type === "search") {
    let attrbArray = [];
    if (!isNaN(searchContent))
      attrbArray = searchAttributesList.map((searchAttribute) => ({
        [searchAttribute]: Number(searchContent),
      }));
    else
      attrbArray = searchAttributesList.map((searchAttribute) => ({
        [searchAttribute]: { $regex: searchContent, $options: "i" },
      }));
    payload = { condition: { [operator]: attrbArray } };
  } else if (type === "filter") {
    const objectArray = searchContent.map((item) => {
      const [key, value] = item.split("=");
      return { [key]: value.toUpperCase() };
    });
    payload = { condition: { [operator]: objectArray } };
  }
  return payload;
};

//   Usage example (please check end how to call function if you have any number values)

//   searchContent= string in case of search || array of string in case of filter
//   searchAttributesList= [ ] in both cases but in filter case must send toBeEmpty
//   type= string

// for filter
// searchContent= ["status=New", "status=lost" ....] must send all filter like this
// searchAttributesList= []
//type="filter"

// handleFilterSearch(filteredString, [], "filter");

// for seacrh
// searchContent= character or characters
// searchAttributesList= [firstName, lastName] or list of attributes you want to apply your search attribute name must be according to backend
//type="search"

// handleFilterSearch(
//     searchClient,
//     ["firstName", "lastName", "email", "telephone", "mobileNumber"],
//     "search"
//   );

// calling with number varibale only valid for search type instead of filter
// let attributeArray = [];
//   if (isNaN(searchContent)) attributeArray = ["name", "category"];
//   else {
//     attributeArray = ["price", "stock"];

// handleFilterSearch(
//     searchClient,
//     attributeArray,
//     "search"
//   );
