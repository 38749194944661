import { styled, Switch } from "@mui/material";

export const address = {
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "2px",
  },
};

export const SubmitBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.main,
  color: theme.palette.white.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.dark,
  },
});
