import { useNavigate } from "react-router-dom";
import FormProvider from "../../../hook-form/FormProvider";
import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import SPAInputField from "../../../hook-form/SPAInputField";
import ErrorIcon from "../../../../assets/svg/error-icon";
import useLogin from "./useLogin";
import { Eye } from "@phosphor-icons/react";
import { EyeClosed } from "@phosphor-icons/react";
import theme from "../../../../theme/theme";

const ChangePassword = () => {
  const navigate = useNavigate();
  // for ui purposes use these methods from login we will make this own hook for this component
  const { methods, control, handleSubmit, onSubmit, responseError } =
    useLogin();
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          lg={6}
          bgcolor={theme.palette.purple10.main}
          sx={{
            padding: "24px",
            margin: "24px",
            borderRadius: "16px",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <SPAInputField
                name="currentPassword"
                label="Current Password"
                type="password"
                placeholder="Enter current password"
              />
              <SPAInputField
                name="newPassword"
                label="New Password"
                type="password"
                placeholder="Enter new password"
              />

              <SPAInputField
                name="confirmedPassword"
                label="Confirm Password"
                type="password"
                placeholder="Re-enter your new password"
              />
            </Box>
            {responseError && (
              <Box sx={{ display: "flex", gap: "4px", marginTop: "20px" }}>
                <ErrorIcon />
                <FormHelperText sx={{ margin: "0px", padding: "0px" }} error>
                  {responseError}
                </FormHelperText>
              </Box>
            )}
            <Button
              type="submit"
              sx={{
                display: "flex",
                height: "48px",
                padding: "0px 24px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                borderRadius: "8px",
                background: "#4E45E5",
                color: "#FFF",
                width: "100%",
                mt: responseError ? "10px" : "40px",
                "&:hover": {
                  backgroundColor: "#2F2989",
                  color: "#FFF",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: '"Open Sans"',
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  letterSpacing: "0.175px",
                  textTransform: "uppercase",
                }}
              >
                Change Password
              </Typography>
            </Button>
          </FormProvider>
          <Box
            sx={{
              mt: "40px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "8px",
            }}
          ></Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePassword;
