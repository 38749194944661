export const linkButtonLight = (theme) => ({
  color: theme.palette.primaryColor.main,
  backgroundColor: theme.palette.primaryColor.light,
  paddingInline: '16px',
  paddingBlock: '10px',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: theme.palette.primaryColor.light,
  },
});
export const closeButtonFiled = (theme) => ({
  color: theme.palette.white.main,
  backgroundColor: theme.palette.primaryColor.main,
  paddingInline: '16px',
  paddingBlock: '10px',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: theme.palette.primaryColor.main,
  },
});
