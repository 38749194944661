import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openNewNoteModal: false,
  noteDetails: {},
};

export const openNoteModalSlice = createSlice({
  name: "notesModal",
  initialState,
  reducers: {
    handleOpenNoteModal: (state, action) => {
      state.openNewNoteModal = true;
      state.noteDetails = action.payload;
    },
    handleCloseNoteModal: (state) => {
      state.openNewNoteModal = false;
      state.noteDetails = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { handleOpenNoteModal, handleCloseNoteModal } =
  openNoteModalSlice.actions;

export default openNoteModalSlice.reducer;
