import * as Yup from "yup";

export const validationSchema = () => {
  let schema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    role: Yup.string().required("Role is required"),
    status: Yup.string().required("Status is required"),
    // note: Yup.string(),
    password: Yup.string().required("Password is required"),
    state: Yup.string().required("State is required"),
    street: Yup.string().required("Street is required"),
    suburb: Yup.string().required("Suburb is required"),
    // city: Yup.string(),
    dateOfBirth: Yup.string().nullable(),
    gender: Yup.string().required("Gender is required"),
    mobileNumber: Yup.string()
      .matches(
        /^\+?\d{10,14}$/,
        "Invalid format. Provide Valid Format of 10 to 14 digits"
      )
      .test(
        "mobile-format",
        "Invalid format. Provide Valid Format of 10 to 14 digits",
        function (value) {
          if (!value) return true;
          return /^\+?\d{10,14}$/.test(value);
        }
      )
      .required("Mobile Number is required"),
    currentEmployment: Yup.boolean(),
    // telephone: Yup.string()
    //   .matches(
    //     /^\+?\d{10,14}$/,
    //     "Invalid format. Provide Valid Format of 10 to 14 digits"
    //   )
    //   .test(
    //     "mobile-format",
    //     "Invalid format. Provide Valid Format of 10 to 14 digits",
    //     function (value) {
    //       if (!value) return true;
    //       return /^\+?\d{10,14}$/.test(value);
    //     }
    //   ),
    email: Yup.string().email("Invalid email").required("Email is required"),
    postalCode: Yup.string().required("Post code is required"),
    employmentStartDate: Yup.string().nullable(),
    employmentEndDate: Yup.string().nullable(),
    emergencyInfo: Yup.boolean(),
    contactFirstName: Yup.string().when("emergencyInfo", {
      is: true,
      then: (schema) => schema.required("Emergency First Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    contactLastName: Yup.string().when("emergencyInfo", {
      is: true,
      then: (schema) => schema.required("Emergency Last Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    relationship: Yup.string().when("emergencyInfo", {
      is: true,
      then: (schema) => schema.required("Relationship is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    contactPhoneNumber: Yup.string().when("emergencyInfo", {
      is: true,
      then: (schema) =>
        schema
          .matches(
            /^\+?\d{10,14}$/,
            "Invalid format. Provide Valid Format of 10 to 14 digits"
          )
          .test(
            "mobile-format",
            "Invalid format. Provide Valid Format of 10 to 14 digits",
            function (value) {
              if (!value) return true;
              return /^\+?\d{10,14}$/.test(value);
            }
          )
          .required("PhoneNumber is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    contactEmail: Yup.string().email("Invalid email"),
  });

  return schema;
};

export const qualificationValidationSchema = () => {
  let schema = Yup.object().shape({
    qualification: Yup.array().of(Yup.string()),
  });

  return schema;
};
