import SPAInputField from "../../../hook-form/SPAInputField";
import SPASelectField from "../../../hook-form/SPASelectField";

export const packageTabsArray = [
  { title: "ALL" },
  {
    title: "ENABLED",
  },
  { title: "DISABLED" },
];

export const packageFormData = (serviceCategoryData) => {
  let finalData = [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "name",
        label: "Name",
        placeholder: "",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "productType",
        label: "Category",
        placeholder: "Select Category",
        options: serviceCategoryData?.map((item) => {
          return { label: item.name, value: item.name, id: item._id };
        }),
      },
      component: SPASelectField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "description",
        label: "Description",
        placeholder: "Description here",
        labelHelperText: "(Optional)",
        type: "textarea",
      },
      component: SPAInputField,
    },
  ];
  return finalData;
};

