import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DotsIcon from "../../../assets/svg/dots-icon";
import { useGetRoomsMutation } from "../../../services/rooms";
import { fs14, fw400, fw600 } from "../../../theme";
import { statusDesign } from "../resource-management-data";
import { resourceBox } from "../resourceManagementStyle";
import { handleFilterSearch } from "../../../utils/searchFilter";

const useRooms = () => {
  const [searchRoom, setSearchRoom] = useState("");
  const [roomsPopoverOpen, setRoomsPopoverOpen] = useState(null);
  const [isRoomDialogOpen, setIsRoomDialogOpen] = useState(false);
  const [singleRoomId, setSingleRoomId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // Variables for rooms popover state and IDs
  const openRoomsPopOver = Boolean(roomsPopoverOpen);
  const roomsPopOverId = openRoomsPopOver ? "simple-popover" : undefined;
  const [getAllRooms, { data: roomsData, isLoading: roomsDataLoader }] =
    useGetRoomsMutation();
  const totalRecords = roomsData?.result?.data?.totalRecords;
  const roomTableHeader = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#4A4C4F",
                  fontSize: "16px",
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                {value}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: statusDesign[value]?.bgColor,
                  color: statusDesign[value]?.color,
                  ...resourceBox.statusInfo,
                }}
              >
                {value ?? "---"}
              </Box>
            </Box>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#070A0E",
                  fontSize: fs14,
                  fontWeight: fw400,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                {value ?? "---"}
              </Typography>
            </Box>
          );
        },
      },
    },
    // {
    //   name: "services",
    //   label: "Services",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return (
    //         <Box>
    //           {value &&
    //             value?.map((item) => {
    //               return (
    //                 <Typography
    //                   sx={{
    //                     color: "#5E6062",
    //                     fontSize: "10px",
    //                     fontWeight: "400",
    //                     cursor: "pointer",
    //                     textTransform: "uppercase",
    //                   }}
    //                 >
    //                   {item?.name}
    //                 </Typography>
    //               );
    //             })}
    //           {value?.length === 0 && "---"}
    //         </Box>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "hours",
    //   label: "Duration",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return (
    //         <Box
    //           sx={{
    //             display: "flex",
    //             gap: "16px",
    //             alignItems: "center",
    //           }}
    //         >
    //           <Typography
    //             sx={{
    //               color: "#5E6062",
    //               fontSize: fs14,
    //               fontWeight: "400",
    //               cursor: "pointer",
    //               textTransform: "capitalize",
    //             }}
    //           >
    //             {value ? `${value} Hour` : "---"}
    //           </Typography>
    //         </Box>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "product",
    //   label: "Product Usage",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       const productList = ["Himalayan Pink Salt", "Sparkling Citrus"];
    //       return (
    //         <Box>
    //           {productList?.map((item) => {
    //             return (
    //               <Typography
    //                 sx={{
    //                   color: "#5E6062",
    //                   fontSize: "10px",
    //                   fontWeight: "400",
    //                   cursor: "pointer",
    //                   textTransform: "uppercase",
    //                 }}
    //               >
    //                 {item}
    //               </Typography>
    //             );
    //           })}
    //         </Box>
    //       );
    //     },
    //   },
    // },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                setRoomsPopoverOpen(e.currentTarget);
                setSingleRoomId(rowData[tableMeta?.rowIndex]?._id);
              }}
            >
              <DotsIcon />
            </Box>
          );
        },
      },
    },
  ];

  const handleNewRoom = () => {
    setIsRoomDialogOpen(true);
  };

  const handleSearchRoom = () => {
    let attributeArray = [];
    if (searchRoom !== "") {
      if (isNaN(searchRoom)) attributeArray = ["name", "status"];
      else {
        attributeArray = ["name", "status"];
      }

      const searchPayload = handleFilterSearch(
        searchRoom,
        attributeArray,
        "search"
      );
      getAllRooms({ ...searchPayload });
    } else getAllRooms({});
  };

  useEffect(() => {
    handleSearchRoom();
  }, [searchRoom]);

  useEffect(() => {
    getAllRooms({ page: currentPage, pageSize: 10 });
  }, [currentPage]);

  return {
    searchRoom,
    setSearchRoom,
    singleRoomId,
    setSingleRoomId,
    roomTableHeader,
    isRoomDialogOpen,
    setIsRoomDialogOpen,
    roomsPopoverOpen,
    setRoomsPopoverOpen,
    roomsPopOverId,
    openRoomsPopOver,
    handleNewRoom,
    roomsData,
    roomsDataLoader,
    getAllRooms,
    currentPage,
    setCurrentPage,
    totalRecords,
  };
};

export default useRooms;
