import * as Yup from "yup";

export const validationSchema = () => {
  let schema = Yup.object().shape({
    client: Yup.string().required("Client is required"),
    phoneNumber: Yup.string().required("Mobile Number is required"),
    template: Yup.string(),
    message: Yup.string().required("Message is required"),
    savePhoneNumber: Yup.boolean(),
  });

  return schema;
};
