import React from "react";

const NoPackage = () => {
  return (
    <svg
      width="171"
      height="187"
      viewBox="0 0 171 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M160.209 61.7899C160.209 61.7899 135.17 83.7678 164.001 130.83C189.606 172.626 132.054 190.743 107.255 185.892C75.1284 179.607 56.6829 139.002 32.1149 151.868C7.54687 164.734 -16.7919 106.432 18.9447 84.9778C63.3994 58.2903 34.869 42.7818 38.8616 23.2137C41.7273 9.16991 82.5058 -12.5432 104.494 12.2249C122.996 33.0669 132.07 25.6105 140.527 22.8282C152.724 18.8152 179.347 39.6888 160.209 61.7899Z"
          fill="#CAC7F7"
        />
        <g id="Group_2">
          <g id="Group_3">
            <path
              id="Vector_2"
              d="M89.315 86.5564L81.1117 120.205C80.8072 121.453 80.7514 122.748 80.9478 124.018C81.1441 125.287 81.5886 126.505 82.2559 127.603C82.9232 128.7 83.8002 129.656 84.8368 130.414C85.8733 131.173 87.0492 131.72 88.2972 132.023L129.096 141.97C130.145 153.488 139.677 154.339 143.437 154.734C143.474 154.736 143.511 154.726 143.542 154.705C143.572 154.684 143.595 154.653 143.607 154.618C143.618 154.583 143.617 154.544 143.605 154.51C143.593 154.475 143.569 154.445 143.538 154.425C140.837 152.779 140.031 148.266 139.851 144.592L142.88 145.33C144.128 145.635 145.423 145.69 146.693 145.494C147.962 145.298 149.18 144.853 150.278 144.186C151.375 143.519 152.33 142.642 153.089 141.605C153.847 140.569 154.394 139.393 154.698 138.145L162.901 104.496C163.206 103.248 163.262 101.953 163.065 100.683C162.869 99.4139 162.424 98.1957 161.757 97.0981C161.09 96.0006 160.213 95.0453 159.176 94.2868C158.14 93.5284 156.964 92.9815 155.716 92.6776L101.133 79.3709C99.8856 79.0663 98.59 79.0106 97.3206 79.2069C96.0512 79.4032 94.833 79.8477 93.7355 80.515C92.6379 81.1823 91.6826 82.0593 90.9242 83.0959C90.1657 84.1325 89.6189 85.3084 89.315 86.5564Z"
              fill="#716AEA"
            />
            <g id="Group_4">
              <path
                id="Vector_3"
                d="M156.991 103.156L134.177 97.5942C134.027 97.5575 133.876 97.6496 133.839 97.7999L132.669 102.601C132.632 102.751 132.724 102.902 132.874 102.939L155.688 108.501C155.838 108.537 155.99 108.445 156.027 108.295L157.197 103.494C157.234 103.344 157.141 103.193 156.991 103.156Z"
                fill="white"
              />
              <path
                id="Vector_4"
                d="M153.734 116.518L91.9214 101.449C91.7712 101.412 91.6197 101.504 91.5831 101.654L90.4127 106.455C90.3761 106.605 90.4682 106.757 90.6184 106.793L152.431 121.863C152.581 121.899 152.732 121.807 152.769 121.657L153.939 116.856C153.976 116.706 153.884 116.555 153.734 116.518Z"
                fill="#A7A2F2"
              />
              <path
                id="Vector_5"
                d="M150.476 129.88L88.6639 114.811C88.5136 114.774 88.3622 114.866 88.3255 115.016L87.1552 119.817C87.1185 119.967 87.2106 120.119 87.3609 120.155L149.173 135.225C149.323 135.261 149.475 135.169 149.511 135.019L150.682 130.218C150.718 130.068 150.626 129.917 150.476 129.88Z"
                fill="#A7A2F2"
              />
            </g>
          </g>
          <g id="Group_5">
            <path
              id="Vector_6"
              d="M112.286 46.1922L125.7 89.2701C126.198 90.8676 126.376 92.5475 126.225 94.2139C126.073 95.8802 125.595 97.5004 124.817 98.9818C124.039 100.463 122.977 101.777 121.691 102.848C120.405 103.919 118.921 104.726 117.324 105.223L65.0927 121.487C64.67 136.547 52.3469 138.433 47.4899 139.253C47.4417 139.259 47.3928 139.249 47.3508 139.225C47.3089 139.2 47.2764 139.162 47.2585 139.117C47.2406 139.072 47.2383 139.022 47.252 138.975C47.2657 138.929 47.2946 138.888 47.3341 138.86C50.7104 136.499 51.39 130.566 51.3241 125.774L47.4461 126.982C45.8487 127.479 44.1689 127.658 42.5026 127.506C40.8363 127.355 39.2162 126.876 37.7348 126.098C36.2535 125.321 34.9399 124.258 33.8691 122.973C32.7983 121.687 31.9913 120.203 31.4942 118.606L18.0797 75.5276C17.5817 73.9302 17.4034 72.2502 17.5549 70.5839C17.7065 68.9175 18.1848 67.2973 18.9627 65.8159C19.7406 64.3344 20.8027 63.0207 22.0884 61.9499C23.3742 60.8791 24.8583 60.0721 26.456 59.575L96.333 37.8158C97.9304 37.3179 99.6103 37.1396 101.277 37.2911C102.943 37.4426 104.563 37.921 106.045 38.6988C107.526 39.4767 108.84 40.5389 109.911 41.8246C110.981 43.1103 111.788 44.5944 112.286 46.1922Z"
              fill="#3730A0"
            />
            <g id="Group_6">
              <path
                id="Vector_7"
                d="M62.9357 61.6207L25.5165 73.2579C25.3689 73.3038 25.2864 73.4608 25.3323 73.6085L27.2945 79.9178C27.3404 80.0654 27.4973 80.1479 27.645 80.102L65.0642 68.4648C65.2119 68.4189 65.2944 68.262 65.2484 68.1143L63.2863 61.805C63.2403 61.6573 63.0834 61.5748 62.9357 61.6207Z"
                fill="white"
              />
              <path
                id="Vector_8"
                d="M110.157 65.6194L30.8628 90.3123C30.7152 90.3582 30.6327 90.5152 30.6787 90.6628L32.643 96.9707C32.689 97.1183 32.846 97.2007 32.9936 97.1547L112.288 72.4619C112.436 72.4159 112.518 72.259 112.472 72.1113L110.508 65.8035C110.462 65.6559 110.305 65.5735 110.157 65.6194Z"
                fill="#A7A2F2"
              />
              <path
                id="Vector_9"
                d="M115.477 82.8135L36.1638 107.479C36.0161 107.525 35.9336 107.682 35.9795 107.83L37.9417 114.139C37.9876 114.287 38.1446 114.369 38.2922 114.323L117.605 89.6576C117.753 89.6117 117.835 89.4547 117.789 89.307L115.827 82.9977C115.781 82.8501 115.624 82.7676 115.477 82.8135Z"
                fill="#A7A2F2"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NoPackage;
