import dayjs from "dayjs";
import AutoCompleteDetails from "../common/autocomplete-details/autocomplete-details";
import SPAAutoComplete from "../common/spa-auto-complete";
import SPAInputField from "../hook-form/SPAInputField";
import SPAMobileDatePicker from "../hook-form/SPAMobileDatePicker";
import SPASelectField from "../hook-form/SPASelectField";

export const newAppointmentFormData = (
  serviceData,
  usersData,
  roomData,
  clientArray,
  filteredStartTimeArray,
  fullName,
  onChange
) => {
  const nameComponent = fullName
    ? {
        gridLength: 6,
        otherOptions: {
          name: "client",
          label: "Client Name",
          value: fullName,
          readOnly: "true",
        },
        component: SPAInputField,
        type: "input",
      }
    : {
        gridLength: 6,
        otherOptions: {
          name: "client",
          label: "Client Name",
          placeholder: "Select Client",
          onChangeText: onChange,
          options: clientArray,
        },
        component: AutoCompleteDetails,
      };
  return [
    nameComponent,
    {
      gridLength: 6,
      otherOptions: {
        name: "service",
        label: "Service",
        type: "multiple",
        isChips: true,
        options:
          serviceData &&
          serviceData?.map((item) => {
            return { label: item.name, value: item.name, id: item._id };
          }),
        placeholder: "Select Service",
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "user",
        label: "Staff",
        options:
          usersData &&
          usersData.map((item) => {
            return {
              label: `${item.firstName} ${item.lastName}`,
              value: `${item.firstName} ${item.lastName}`,
              id: item._id,
            };
          }),
        placeholder: "Select Staff",
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "room",
        label: "Room",
        options:
          roomData &&
          roomData.map((item) => {
            return { label: item.name, value: item.name, id: item._id };
          }),
        placeholder: "Select Room",
      },
      component: SPASelectField,
    },
    {
      gridLength: 3.4,
      otherOptions: {
        name: "date",
        label: "Date",
        disablePast: true,
        isCalenderShow: true,
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 2.6,
      otherOptions: {
        name: "startTime",
        label: "Start Time",
        options:
          filteredStartTimeArray &&
          filteredStartTimeArray?.map((item) => {
            return {
              label: item?.label,
              value: item?.value,
              disabled: item?.disabled,
            };
          }),
        placeholder: "Start Time",
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "giftVoucher",
        label: "Gift Voucher",
        placeholder: "Enter Gift Voucher Code",
        labelHelperText: "(Optional)",
      },
      component: SPAInputField,
      type: "input",
    },
  ];
};

export let startTimeData = [
  { label: "08:00 AM", value: "08:00 AM", disabled: false },
  { label: "08:30 AM", value: "08:30 AM", disabled: false },
  { label: "09:00 AM", value: "09:00 AM", disabled: false },
  { label: "09:30 AM", value: "09:30 AM", disabled: false },
  { label: "10:00 AM", value: "10:00 AM", disabled: false },
  { label: "10:30 AM", value: "10:30 AM", disabled: false },
  { label: "11:00 AM", value: "11:00 AM", disabled: false },
  { label: "11:30 AM", value: "11:30 AM", disabled: false },
  { label: "12:00 PM", value: "12:00 PM", disabled: false },
  { label: "12:30 PM", value: "12:30 PM", disabled: false },
  { label: "01:00 PM", value: "01:00 PM", disabled: false },
  { label: "01:30 PM", value: "01:30 PM", disabled: false },
  { label: "02:00 PM", value: "02:00 PM", disabled: false },
  { label: "02:30 PM", value: "02:30 PM", disabled: false },
  { label: "03:00 PM", value: "03:00 PM", disabled: false },
  { label: "03:30 PM", value: "03:30 PM", disabled: false },
  { label: "04:00 PM", value: "04:00 PM", disabled: false },
  { label: "04:30 PM", value: "04:30 PM", disabled: false },
  { label: "05:00 PM", value: "05:00 PM", disabled: false },
  { label: "05:30 PM", value: "05:30 PM", disabled: false },
  { label: "06:00 PM", value: "06:00 PM", disabled: false },
  { label: "06:30 PM", value: "06:30 PM", disabled: false },
  { label: "07:00 PM", value: "07:00 PM", disabled: false },
  { label: "07:30 PM", value: "07:30 PM", disabled: false },
  { label: "08:00 PM", value: "08:00 PM", disabled: false },
  { label: "08:30 PM", value: "08:30 PM", disabled: false },
];

export const newClientFormData = [
  {
    gridLength: 4,
    otherOptions: {
      fullWidth: true,
      name: "firstName",
      label: "First Name",
      placeholder: "i.e.: Jane",
    },
    component: SPAInputField,
  },
  {
    gridLength: 4,
    otherOptions: {
      fullWidth: true,
      name: "lastName",
      label: "Last Name",
      placeholder: "i.e.: Doe",
    },
    component: SPAInputField,
  },
  {
    gridLength: 4,
    otherOptions: {
      fullWidth: true,
      name: "postalCode",
      label: "Post Code",
      placeholder: "i.e.: 21451",
      labelHelperText: "(Optional)",
    },
    component: SPAInputField,
  },
  {
    gridLength: 4,
    otherOptions: {
      fullWidth: true,
      name: "mobileNumber",
      label: "Mobile Number",
      placeholder: "+61 412 345 678",
    },
    component: SPAInputField,
  },
  {
    gridLength: 4,
    otherOptions: {
      fullWidth: true,
      name: "email",
      label: "Email",
      placeholder: "",
    },
    component: SPAInputField,
  },
  {
    gridLength: 4,
    otherOptions: {
      fullWidth: true,
      name: "password",
      label: "Password",
      placeholder: "********",
      type: "password",
    },
    component: SPAInputField,
  },
];

// later use

// serviceData && serviceData?.data?.length >0 ?
// serviceData.data.map((item) => {
//   return { label: item.name, value: item.name, id: item._id };
// }) : [],
