import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Badge,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Warning, X } from "@phosphor-icons/react";
import NoClients from "../../../../assets/svg/no-clients.jsx";
import SPATextField from "../../../common/spa-textField/index.jsx";
import { TreatmentStyle } from "../new-service/new-services-style.js";

export default function AddQualification({
  errors,
  qualificationsDataLoader,
  qualificationsData,
  searchQualification,
  setSearchQualification,
  control,
  methods,
}) {
  const transformQualificationData = (data) => {
    const categoryMap = {};

    data?.forEach((item) => {
      const category = item?.category;
      const categoryId = category?._id;

      if (!categoryMap[categoryId]) {
        categoryMap[categoryId] = {
          _id: categoryId,
          name: category?.name,
          color: category?.color,
          qualificationArray: [],
        };
      }

      const qualification = {
        _id: item._id,
        name: item.name,
      };

      categoryMap[categoryId].qualificationArray.push(qualification);
    });

    return Object.values(categoryMap);
  };
  const qualificationInitialValue = methods.getValues("qualification");
  const [selectedQualifications, setSelectedQualifications] = useState([]);

  useEffect(() => {
    setSelectedQualifications(methods.getValues("qualification") || []);
  }, [qualificationInitialValue]);

  const calculateSelectedCount = (qualificationArray) => {
    const count = qualificationArray.reduce((count, qualification) => {
      if (selectedQualifications.includes(qualification._id)) {
        return count + 1;
      }
      return count;
    }, 0);
    return count;
  };

  const qualificationFormattedData = transformQualificationData(
    qualificationsData?.result?.data?.data
  );

  return (
    <>
      {qualificationsDataLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 3 }}
          open={qualificationsDataLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={TreatmentStyle.addQualificationHeaderMain}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: "#4A4C4F",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Qualifications
            </Typography>
            <Typography
              sx={{
                color: "#5E6062",
                fontSize: "12px",
              }}
            >
              Please provide details about the staff's qualifications and
              certifications.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <SPATextField
              placeholder="Search Qualification"
              value={searchQualification}
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                    <MagnifyingGlass size={24} color={"#C1C2C3"} />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    onClick={() => {
                      setSearchQualification("");
                    }}
                  >
                    {searchQualification && <X size={24} />}
                  </InputAdornment>
                ),
              }}
              onChangeValue={(event) => {
                setSearchQualification(event.target.value);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Controller
              name="qualification"
              control={control}
              render={({ field: { value, onChange } }) => {
                const selectedQualifications =
                  qualificationsData?.result?.data?.data?.filter((item) =>
                    value?.includes(item._id)
                  );
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    {selectedQualifications?.map((qualification) => {
                      return (
                        <Chip
                          key={qualification._id}
                          label={qualification.name}
                          onDelete={() => {
                            const newValue = value.filter(
                              (id) => id !== qualification._id
                            );
                            setSelectedQualifications(newValue);
                            onChange(newValue);
                          }}
                        />
                      );
                    })}
                  </Box>
                );
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "43vh",
              overflowY: "auto",
            }}
          >
            {qualificationFormattedData?.map((item) => {
              const selectedCount = calculateSelectedCount(
                item.qualificationArray
              );
              return (
                <Accordion
                  defaultExpanded
                  disableGutters
                  elevation={0}
                  key={item?._id}
                  sx={{
                    m: "0px",
                    p: 0,
                    "&.MuiAccordion-root": {
                      backgroundColor: "transparent",
                    },
                    "&.MuiPaper-root": {
                      boxShadow: "none",
                    },
                    "&.MuiAccordion-root::before": {
                      backgroundColor: "transparent",
                    },
                    "&.MuiPaper-root::before": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      color: "#3E37B7",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      p: 0,
                      minHeight: "30px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Typography sx={{ color: "#3E37B7", fontSize: "14px" }}>
                        {item?.name}
                      </Typography>
                      <Badge
                        color="primary"
                        badgeContent={selectedCount}
                      ></Badge>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      p: "0px",
                      bgcolor: "transparent",
                      display: "flex",
                      gap: "8px",
                      flexDirection: "column",
                    }}
                  >
                    {item?.qualificationArray?.map((subItem) => {
                      return (
                        <Controller
                          key={subItem._id}
                          name="qualification"
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => {
                            return (
                              <Box
                                sx={{
                                  borderRadius: "4px",
                                  bgcolor: "#FFFFFF",
                                  px: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const currentValue = Array.isArray(value)
                                    ? value
                                    : [];
                                  const newValue = currentValue.includes(
                                    subItem._id
                                  )
                                    ? currentValue.filter(
                                        (id) => id !== subItem._id
                                      )
                                    : [...currentValue, subItem._id];
                                  setSelectedQualifications(newValue);
                                  onChange(newValue);
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#4E45E5",
                                      },
                                    }}
                                    checked={
                                      Array.isArray(value) &&
                                      value.includes(subItem._id)
                                    }
                                    onChange={(e) => {
                                      e.stopPropagation();
                                    }}
                                    onBlur={onBlur}
                                  />
                                  <Typography
                                    sx={{
                                      color: "#070A0E",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {`${subItem.name}`}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          }}
                        />
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
            {qualificationsData &&
              qualificationsData?.result?.data?.data?.length === 0 &&
              !qualificationsDataLoader && (
                <Box
                  sx={{
                    mt: "18px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <NoClients />
                  <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                    There’s no Qualification yet
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                    After creating Qualification, they will be displayed here as
                    Qualification.
                  </Typography>
                </Box>
              )}
          </Box>
        </Box>
        {errors?.qualification && (
          <Typography
            sx={{
              color: "#FFAA33",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              mt: "5px",
            }}
            color="error"
          >
            <Warning color={"#FFAA33"} size={16} />{" "}
            {errors?.qualification.message}
          </Typography>
        )}
      </Box>
    </>
  );
}
