import * as Yup from "yup";

export const validationSchema = (isFormEnable) => {
  console.log("isFormEnable", isFormEnable);
  const baseSchema = {
    client: Yup.string().required("Client Name is required"),
  };

  const formSchema = {
    buyDate: Yup.string().required("Buy Date is required"),
    buyTime: Yup.string().required("Buy Time is required"),
    note: Yup.string(),
    markAsPaid: Yup.boolean(),
    cardName: Yup.string().nullable(),
    expireDate: Yup.string().nullable(),
    ccv: Yup.string()
      .matches(/^\d{3,4}$/, "CCV must be 3 or 4 digits")
      .nullable(),
    cardNumber: Yup.string()
      .matches(/^\d{16}$/, "Card Number must be exactly 16 digits")
      .nullable(),

    // cardName: Yup.string().when("markAsPaid", {
    //   is: false,
    //   then: Yup.string().required("Card Name is required"),
    //   otherwise: Yup.string().notRequired(),
    // }),
    // cardNumber: Yup.string().when("markAsPaid", {
    //   is: false,
    //   then: Yup.string()
    //     .required("Card Number is required")
    //     .matches(/^\d{16}$/, "Card Number must be exactly 16 digits"),
    //   otherwise: Yup.string().notRequired(),
    // }),
    // expireDate: Yup.string().when("markAsPaid", {
    //   is: false,
    //   then: Yup.string().required("Expire Date is required"),
    //   otherwise: Yup.string().notRequired(),
    // }),
    // ccv: Yup.string().when("markAsPaid", {
    //   is: false,
    //   then: Yup.string()
    //     .required("CCV is required")
    //     .matches(/^\d{3,4}$/, "CCV must be 3 or 4 digits"),
    //   otherwise: Yup.string().notRequired(),
    // }),
    giftVoucher: Yup.string(),
    discountCode: Yup.string(),
  };

  return Yup.object().shape(
    isFormEnable ? { ...baseSchema, ...formSchema } : baseSchema
  );
};
