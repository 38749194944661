import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  CalendarBlank,
  CallBell,
  Clock,
  Door,
  HandHeart,
  User,
} from "@phosphor-icons/react";
import { ArrowForward } from "@mui/icons-material";
import { getLocalStorageItem } from "../../../../../../../../utils/localStorage";
import { useGetSingleStaffQuery } from "../../../../../../../../services/staff";
import {
  handleCloseCreateAppointmentAlert,
  handleOpenAppointmentModal,
} from "../../../../../../../../store/features/new-appointment";
import { useGetSingleAppointmentQuery } from "../../../../../../../../services/appointment";
import {
  calculateTimeDifference,
  calculateTimeDifferenceWithCurrentTime,
  convertDate,
  convertTo12HourFormat,
  convertTo24HourFormat,
} from "../../../../../../../../utils/convertTimeIOSFormat";
import SpaAppointmentCard from "../../../../../../../common/spa-appointment-card";
import { customColors, secondary } from "../../../../../../../../theme";

export default function useStaffAppointmentSale({
  singleServiceData,
  singleServiceRefetching,
}) {
  const clientDetail = useSelector((state) => state.clientDetail.clientDetail);
  const createdClientDetail = useSelector(
    (state) => state.appointmentModal.createdClientDetails
  );

  //api working
  // const { data: singleStaff, refetch: refetchClient } = useGetSingleStaffQuery(
  //   clientDetail._id
  // );
  const userDetail = getLocalStorageItem("userData");

  // const { data: singleStaff, refetch: refetchClient } = useGetSingleStaffQuery(
  //   userDetail?.id
  // );

  const openAppointmentModal = useSelector(
    (state) => state?.appointmentModal?.openNewAppointmentModal
  );

  const dispatch = useDispatch();

  // useStates

  const [appointmentsArray, setAppointmentsArray] = useState([]);
  const [commentDetails, setCommentDetails] = useState({
    userName: "",
    note: { text: "", added: "" },
    contraindication: { text: "", edited: "" },
  });
  const [singleAppointmentId, setSingleAppointmentId] = useState();

  const navigate = useNavigate();

  //functions

  const handleCloseAppointmentDialog = () => {
    dispatch(handleCloseCreateAppointmentAlert());
    // refetchClient();
  };

  const {
    data: singleAppointment,
    refetch: refetchSingleAppointment,
    isLoading: singleAppointmentLoader,
  } = useGetSingleAppointmentQuery(singleAppointmentId);

  const handleInitialValues = () => {
    if (!singleServiceData) return;

    const { appointments } = singleServiceData || {};
    if (!appointments) return;

    if (appointments?.length > 0) {
      const appointmentArray = appointments.map((appointment) => {
        const inputDate = new Date(appointment.date);
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        const formattedDate = inputDate.toLocaleDateString("en-US", options);
        const isCurrentContraindication =
          appointment.contraindication && appointment.contraindication?.text
            ? true
            : false;

        return {
          title: formattedDate,
          children: [
            {
              appointmentId: appointment._id,
              otherOptions: {
                color: appointment.services[0].category?.color,
                // backgroundColor: lightenHexColor(
                //   appointment.services[0].color,
                //   0.9
                // ),
                locationName: "Sydney",
                author: appointment.user
                  ? `${appointment?.user?.firstName} ${appointment?.user?.lastName}`
                  : "No Staff",
                time: convertTo12HourFormat(appointment?.startTime),

                condition: {
                  isDepositPaid: appointment?.isPaidFull,
                  isInvoicePaid: false,
                  isBookedOnline: false,
                  isContraindication: isCurrentContraindication,
                  isNotes: appointment?.notes.length > 0 ? true : false,
                  isRecurringAppointment: appointment?.isRecurring,
                  isMaintenance: false,
                  isNewClient:
                    appointment?.client?.clientStatus === "NEW" ? true : false,
                  isClientAttended:
                    appointment?.status === "COMPLETED" ? true : false,
                  isClientNoShow: appointment?.status === "OPEN" ? true : false,
                  isAppointmentCancelled:
                    appointment?.status === "CANCELLED" ? true : false,
                },
              },
              component: SpaAppointmentCard,
            },
          ],
        };
      });

      const objectsArray = Object.values(
        appointmentArray.reduce((acc, curr) => {
          const { title, children } = curr;
          acc[title] = acc[title] || { title, children: [] };
          acc[title].children.push(...children);
          return acc;
        }, {})
      );
      setAppointmentsArray(objectsArray);
    }
  };

  const summaryElements = useMemo(() => {
    if (!singleServiceData) return;

    const { appointments } = singleServiceData || {};
    if (!appointments) return;

    if (singleAppointmentId !== "") {
      const filterElement = appointments.filter((item) => {
        return item._id == singleAppointmentId;
      });

      const singleElement = filterElement[0];

      setCommentDetails({
        userName: `${singleElement?.user?.firstName} ${singleElement?.user?.lastName}`,
        note: {
          text:
            singleElement?.notes?.length > 0
              ? singleElement?.notes[0]?.note
              : "",
          added:
            singleElement?.notes?.length > 0 &&
            calculateTimeDifferenceWithCurrentTime(
              singleElement?.notes[0]?.updatedAt
            ),
        },
        contraindication: {
          text: singleElement?.contraindication?.text
            ? singleElement?.contraindication?.text
            : "",
          edited: "",
        },
      });
      return [
        {
          title: "Client",
          icon: User,
          details: {
            img: singleElement?.client?.profilePictureUrl
              ? singleElement?.client?.profilePictureUrl
              : "",
            content: {
              text: `${singleElement?.client?.firstName} ${singleElement?.client?.lastName}`,
              color: secondary.main,
            },
          },
        },
        {
          title: "Staff",
          icon: HandHeart,
          details: {
            content: {
              text: `${singleElement?.user?.firstName} ${singleElement?.user?.lastName}`,
              color: customColors.lightBlack,
            },
          },
        },
        {
          title: "Service",
          icon: CallBell,
          details: {
            content: {
              text:
                singleElement?.services?.length > 0 &&
                `${singleElement?.services[0]?.name}`,
              circleColor:
                singleElement?.services?.length > 0 &&
                `${singleElement?.services[0]?.color}`,
              color: customColors.lightBlack,
            },
          },
        },
        {
          title: "Room",
          icon: Door,
          details: {
            content: {
              text: singleElement?.room && `${singleElement?.room?.name}`,
              color: customColors.lightBlack,
            },
          },
        },
        {
          title: "Date",
          icon: CalendarBlank,
          details: {
            content: {
              text: convertDate(singleElement?.date),
              color: customColors.lightBlack,
            },
          },
        },
        {
          title: "Time",
          icon: Clock,
          details: {
            icon: ArrowForward,
            content: {
              startText: convertTo24HourFormat(singleElement?.startTime),
              endText: convertTo24HourFormat(singleElement?.endTime),
              color: customColors.lightBlack,
            },
            difference: `${Math.floor(
              calculateTimeDifference(
                singleElement?.startTime,
                singleElement?.endTime
              ) / 60
            )} Hour`,
            differenceColor: "#8F9193",
          },
        },
      ];
    }

    return [];
  }, [singleAppointmentId]);

  useEffect(() => {
    handleInitialValues();
  }, [singleAppointmentId]);

  return {
    clientDetail,
    appointmentsArray,
    openAppointmentModal,
    dispatch,
    handleOpenAppointmentModal,
    handleCloseAppointmentDialog,
    navigate,
    createdClientDetail,
    summaryElements,
    commentDetails,
    setSingleAppointmentId,
    singleAppointmentId,
    singleAppointment,
    // refetchSingleAppointment,
    // singleAppointmentLoader,
  };
}
