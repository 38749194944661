import React from "react";
import theme from "../../../../../../theme/theme";
import { Grid, Box, Typography } from "@mui/material";
import { CalendarBlank, CalendarPlus, CreditCard } from "@phosphor-icons/react";
import { fs12, fs14, fs16, fw600 } from "../../../../../../theme";
import { overview } from "../../../../style";
import NoNotifications from "../../../../../../assets/svg/no-notifications";
import {
  handleCloseAppointmentModal,
  handleCloseCreateAppointmentAlert,
  handleSingleAppointmentData,
  handleSingleAppointmentModal,
} from "../../../../../../store/features/new-appointment";
import SPAAlertDialog from "../../../../../common/spa-alert-dialogue";
import NewAppointmentForm from "../../../../../new-appointment-form";
import useClientAppointmentSale from "./useClientAppointmentSale";
import dayjs from "dayjs";
export default function AppointmentSale() {
  const {
    appointmentsArray,
    salesArray,
    openAppointmentModal,
    dispatch,
    handleOpenAppointmentModal,
    clientDetail,
    navigate,
    createdClientDetail,
    handleCloseAppointmentDialog,
    singleClient,
  } = useClientAppointmentSale();

  return (
    <Grid
      container
      columnGap={2}
      rowGap={{ xs: 3, md: 0 }}
      sx={{ height: "100%" }}
    >
      <Grid
        xs={12}
        md={6}
        style={{
          padding: "16px",
          borderRadius: "8px",
          gap: "16px",
          display: "flex",
          flexDirection: "column",
          height: "auto",
        }}
        bgcolor={theme.palette.purple10.main}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "4px" }}>
            <CalendarBlank size={18} />
            <Typography
              fontSize={fs14}
              fontWeight={fw600}
              color={theme.palette.textGraySecondary.main}
            >
              Appointments
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "4px",
              cursor: "pointer",
              alignItems: "center",
            }}
            onClick={() => {
              dispatch(handleOpenAppointmentModal(clientDetail));
            }}
          >
            <CalendarPlus size={18} color={theme.palette.primaryColor.main} />
            <Typography
              fontSize={fs14}
              fontWeight={fw600}
              color={theme.palette.primaryColor.main}
            >
              Create
            </Typography>
          </Box>
        </Box>
        {appointmentsArray?.length > 0 ? (
          <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
            {appointmentsArray.map((item, index) => (
              <>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"8px"}
                  key={index}
                >
                  <Typography sx={overview.appointmentCardTitle}>
                    {item.title}
                  </Typography>
                  {item.children.map((itemChild, c_index) => {
                    return (
                      <Box
                        key={`${index} ${c_index}`}
                        onClick={() => {
                          navigate("/calendar");
                          dispatch(handleSingleAppointmentModal());
                          dispatch(
                            handleSingleAppointmentData(
                              itemChild?.otherOptions?.appointmentId
                            )
                          );
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <itemChild.component {...itemChild.otherOptions} />
                      </Box>
                    );
                  })}
                </Box>
              </>
            ))}
          </Box>
        ) : (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            overflow={"hidden"}
          >
            <NoNotifications />
            <Typography sx={{ fontSize: fs16, color: "#595959" }}>
              No Appointment Yet
            </Typography>
            <Typography sx={{ fontSize: fs12, color: "#595959" }}>
              After creating Appointment, they will be displayed here as
              Appointment.
            </Typography>
          </Box>
        )}
      </Grid>

      {/* All Notes */}
      <Grid
        xs={12}
        md={5.7}
        style={{
          padding: "16px",
          borderRadius: "8px",
          overflow: "auto",
          gap: "16px",
          display: "flex",
          flexDirection: "column",
        }}
        bgcolor={theme.palette.purple10.main}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "4px" }}>
            <CreditCard size={18} />
            <Typography
              fontSize={fs14}
              fontWeight={fw600}
              color={theme.palette.textGraySecondary.main}
            >
              Sales
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "4px",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <CreditCard size={18} color={theme.palette.primaryColor.main} />
            <Typography
              fontSize={fs14}
              fontWeight={fw600}
              color={theme.palette.primaryColor.main}
            >
              Create
            </Typography>
          </Box>
        </Box>

        <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
          {salesArray?.length > 0 ? (
            <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
              {salesArray?.map((sale) => {
                return (
                  <Box
                    sx={{
                      maxHeight: "67vh",
                      overflowY: "auto",
                      mt: "4px",
                    }}
                  >
                    {sale?.products?.map((item) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            p: "16px 0px",
                            width: "100%",
                          }}
                        >
                          <Box
                            key={item?.product?._id}
                            sx={{
                              display: "flex",
                              flexDirection: {
                                xs: "column",
                                md: "row",
                              },
                              width: "100%",
                              maxHeight: "100px",
                              alignItems: "center",
                              borderRadius: "8px",
                              background: "#FFFFFF",
                              justifyContent: "space-between",
                              overflow: "hidden",
                              cursor: "pointer",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                                borderRadius: "8px",
                                bgcolor: "#FFFFFF",
                              }}
                            >
                              <Box
                                sx={{
                                  maxWidth: "90px",
                                  overflow: "hidden",
                                  objectFit: "cover",
                                }}
                              >
                                <img
                                  src={
                                    item?.product?.imageUrl
                                      ? item?.product?.imageUrl
                                      : item?.profilePictureUrl
                                      ? item?.profilePictureUrl
                                      : "https://qa-temple-day-spa-s3.s3.ap-southeast-2.amazonaws.com/c70675c3-59da-46cb-bfa0-e4fc9800eaf3-power-icon.png"
                                  }
                                  alt="product"
                                />
                              </Box>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "4px 8px",
                                    background: "#FFF0F6",
                                    color: "#EB2F96",
                                    fontSize: "10px",
                                    textTransform: "uppercase",
                                    maxWidth: "fit-content",
                                    borderRadius: "4px",
                                  }}
                                >
                                  {item?.product?.productType
                                    ? item?.product?.productType?.name
                                    : item?.product?.category?.name ?? "---"}
                                </Box>
                                <Typography
                                  sx={{
                                    color: "#5E6062",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item?.product?.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#4E45E5",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    minWidth: "170px",
                                  }}
                                >
                                  {`${clientDetail?.firstName} ${clientDetail?.lastName}`}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#5E6062",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    minWidth: "170px",
                                  }}
                                >
                                  $ {item?.product?.price}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",

                                minWidth: "110px",
                                paddingInline: "8px",
                                height: "100px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#5E6062",
                                  fontSize: "10px",
                                  fontWeight: 600,
                                  pb: "20px",
                                }}
                              >
                                {dayjs(sale?.buyDate).format("MMM DD YYYY")}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              overflow={"hidden"}
            >
              <NoNotifications />
              <Typography sx={{ fontSize: fs16, color: "#595959" }}>
                No Sales Yet
              </Typography>
              <Typography sx={{ fontSize: fs12, color: "#595959" }}>
                After creating Sales, they will be displayed here as Sales.
              </Typography>
            </Box>
          )}

          {/* {salesElements().map((item, index) => (
            <item.component {...item.otherOptions} />
          ))} */}
        </Box>
      </Grid>

      {/* appointment */}
      {openAppointmentModal && (
        <NewAppointmentForm
          getAllClients={() => {}}
          isAppointmentOpen={openAppointmentModal}
          handleClose={() => dispatch(handleCloseAppointmentModal())}
        />
      )}

      <SPAAlertDialog
        handleClose={handleCloseAppointmentDialog}
        title={"New Appointment Created"}
        contentText={`New appointment for ${createdClientDetail?.clientName} was created. For the date ${createdClientDetail?.date}`}
        disagreeText={`Create another appointment`}
        agreeText={`Close`}
        handleNewTab={() => {
          dispatch(handleOpenAppointmentModal(clientDetail));
          dispatch(handleCloseCreateAppointmentAlert());
        }}
      />
    </Grid>
  );
}
