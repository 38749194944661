import {
  CalendarBlank,
  CaretDown,
  CurrencyDollar,
  CurrencyDollarSimple,
  PencilSimple,
  Percent,
} from "@phosphor-icons/react";
import SPAInputField from "../../../../../../../hook-form/SPAInputField";
import SPASelectField from "../../../../../../../hook-form/SPASelectField";
import SPAMobileDatePicker from "../../../../../../../hook-form/SPAMobileDatePicker";
import SPAMobileTimePicker from "../../../../../../../hook-form/SPAMobileTimerPicker";
import ToggleComponent from "./ToggleComponent";

export const discountCodeTabsArray = [
  { title: "ALL" },
  { title: "USED" },
  { title: "NOT USED" },
  { title: "DISABLED" },
];

export const couponData = [
  {
    id: 1,
    name: "Summer Sale",
    createAt: "2023-06-01T10:00:00Z",
    discountCode: "SUMMER21",
    description: "Get 20% off on summer collection",
    status: "Enabled",
  },
  {
    id: 2,
    name: "Winter Discount",
    createAt: "2023-12-15T10:00:00Z",
    discountCode: "WINTER20",
    description: "Enjoy 25% off on all winter items",
    status: "Used",
  },
  {
    id: 3,
    name: "New Year Special",
    createAt: "2024-01-01T00:00:00Z",
    discountCode: "NEWYEAR24",
    description: "Celebrate the new year with 30% off",
    status: "Disabled",
  },
  {
    id: 4,
    name: "Black Friday",
    createAt: "2023-11-24T10:00:00Z",
    discountCode: "BLACKFRIDAY",
    description: "Huge discounts up to 50% on selected items",
    status: "Used",
  },
  {
    id: 5,
    name: "Cyber Monday",
    createAt: "2023-11-27T10:00:00Z",
    discountCode: "CYBERMONDAY",
    description: "Exclusive online deals, 40% off",
    status: "Enabled",
  },
];

export const discountCodeFormData = () => {
  let finalData = [
    {
      gridLength: 10,
      otherOptions: {
        fullWidth: true,
        name: "promoCode",
        label: "Promo Code",
        placeholder: "Enter the Promo Code here",
      },
      component: SPAInputField,
    },
    {
      gridLength: 2,
      sx: {
        diplay: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "30px",
        color: "#4E45E5",
      },
      otherOptions: {
        fullWidth: true,
        name: "edit",
      },
      component: PencilSimple,
    },
  ];
  return finalData;
};

export const appliesToFormData = () => {
  return [
    {
      gridLength: 10,
      otherOptions: {
        options: [
          { label: "ALL_STAFF", value: "ALL STAFF" },
          { label: "SELECTED_STAFF", value: "SELECTED" },
        ],
        label: "Staff",
        correctOption: "SELECTED",
        defaultOption: "ALL STAFF",
        dropDownSelect: {
          gridLength: 12,
          otherOptions: {
            name: "staffType",
            placeholder: "Select Staff Type",
            label: "Staff Type",
            // labelHelperText: "Select the staff type.",
            options: [
              { value: "Massager", label: "Massager" },
              { value: "Therapist", label: "Therapist" },
              { value: "Receptionist", label: "Receptionist" },
              { value: "Manager", label: "Manager" },
            ],
          },
          component: SPASelectField,
        },
      },
      component: ToggleComponent,
    },
    {
      gridLength: 10,
      otherOptions: {
        options: [
          { label: "ALL_CUSTOMERS", value: "ALL CUSTOMERS" },
          { label: "VIP_CUSTOMERS", value: "VIP CUSTOMERS" },
        ],
        label: "Customers",
        correctOption: "VIP CUSTOMERS",
        defaultOption: "ALL CUSTOMERS",
        dropDownSelect: {
          gridLength: 12,
          otherOptions: {
            name: "customerType",
            placeholder: "Select Customer Type",
            label: "Customer Type",
            // labelHelperText: "Select the customer type.",
            options: [
              { value: "Standard", label: "Standard" },
              { value: "Premium", label: "Premium" },
              { value: "Platinum", label: "Platinum" },
            ],
          },
          component: SPASelectField,
        },
      },
      component: ToggleComponent,
    },
    {
      gridLength: 10,
      otherOptions: {
        options: [
          { label: "ALL_TREATMENTS", value: "ALL TREATMENTS" },
          { label: "SELECTED_TREATMENTS", value: "SELECTED" },
        ],
        label: "Treatments",
        correctOption: "SELECTED",
        defaultOption: "ALL TREATMENTS",
        dropDownSelect: {
          gridLength: 12,
          otherOptions: {
            name: "selectedTreatment",
            placeholder: "Select Treatment",
            label: "Treatment",
            // labelHelperText: "Select the treatment.",
            options: [
              { value: "Massage", label: "Massage" },
              { value: "Facial", label: "Facial" },
              { value: "Pedicure", label: "Pedicure" },
              { value: "Manicure", label: "Manicure" },
            ],
          },
          component: SPASelectField,
        },
      },
      component: ToggleComponent,
    },
    {
      gridLength: 10,
      otherOptions: {
        options: [
          { label: "ALL_SERVICE_CA", value: "ALL SERVICE CA" },
          { label: "SELECTED", value: "SELECTED" },
        ],
        label: "Service CA",
        correctOption: "SELECTED",
        defaultOption: "ALL SERVICE CA",
        dropDownSelect: {
          gridLength: 12,
          otherOptions: {
            name: "selectedServicesCA",
            placeholder: "Select Service CA",
            label: "Service CA",
            // labelHelperText: "Select the service CA.",
            options: [
              { value: "Service1", label: "Service 1" },
              { value: "Service2", label: "Service 2" },
              { value: "Service3", label: "Service 3" },
              { value: "Service4", label: "Service 4" },
            ],
          },
          component: SPASelectField,
        },
      },
      component: ToggleComponent,
    },
    {
      gridLength: 10,
      otherOptions: {
        options: [
          { label: "ALL_GROUP", value: "ALL GROUP" },
          { label: "SELECTED_GROUP", value: "SELECTED" },
        ],
        label: "Service Group",
        correctOption: "SELECTED",
        defaultOption: "ALL GROUP",
        dropDownSelect: {
          gridLength: 12,
          otherOptions: {
            name: "selectedServiceGroup",
            placeholder: "Select Service Group",
            label: "Service Group",
            // labelHelperText: "Select the service group.",
            options: [
              { value: "Group1", label: "Group 1" },
              { value: "Group2", label: "Group 2" },
              { value: "Group3", label: "Group 3" },
              { value: "Group4", label: "Group 4" },
            ],
          },
          component: SPASelectField,
        },
      },
      component: ToggleComponent,
    },
    {
      gridLength: 10,
      otherOptions: {
        options: [
          { label: "ALL_PRODUCT_TYPES", value: "ALL TYPES" },
          { label: "SELECTED", value: "SELECTED" },
        ],
        label: "Product Type",
        correctOption: "SELECTED",
        defaultOption: "ALL TYPES",
        dropDownSelect: {
          gridLength: 12,
          otherOptions: {
            name: "selectedProductType",
            placeholder: "Select Product Type",
            label: "Product Type",
            // labelHelperText: "Select the product type.",
            options: [
              { value: "Type1", label: "Type 1" },
              { value: "Type2", label: "Type 2" },
              { value: "Type3", label: "Type 3" },
              { value: "Type4", label: "Type 4" },
            ],
          },
          component: SPASelectField,
        },
      },
      component: ToggleComponent,
    },
  ];
};

export const usageFormData = () => {
  return [
    {
      gridLength: 12,
      otherOptions: {
        name: "maxPerCustomerUsage",
        suffixIcon: <CalendarBlank />,
        placeholder: "Leave empty for no limit",
        label: "Maximum per customer usage",
        labelHelperText: ".",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "maxTotalUsage",
        suffixIcon: <CalendarBlank />,
        placeholder: "Leave empty for no limit",
        label: "Maximum Total Usage",
        labelHelperText: ".",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "minimumSpend",
        suffixIcon: <CalendarBlank />,
        placeholder: "Leave empty for no limit",
        label: "Minimum Spend",
        labelHelperText: ".",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "maxTotalDiscount",
        suffixIcon: <CalendarBlank />,
        placeholder: "Leave empty for no limit",
        label: "Maximum Total Discount Amount",
        labelHelperText: ".",
      },
      component: SPAInputField,
    },
  ];
};

export const dicountDetailsFormData = () => {
  return [
    {
      gridLength: 10,
      otherOptions: {
        options: [
          { label: "PERCENTAGE", value: "PERCENTAGE", suffixIcon: <Percent /> },
          {
            label: "FIXED",
            value: "FIXED",
            suffixIcon: <CurrencyDollarSimple />,
          },
        ],
        label: "Discount Type",
        correctOption: "FIXED",
        defaultOption: "PERCENTAGE",
      },

      component: ToggleComponent,
    },

    {
      gridLength: 10,
      otherOptions: {
        name: "percentageAmount",
        suffixIcon: <Percent />,
        placeholder: "E.g 30%",
        label: "Percentage Amount",
        labelHelperText: ".",
      },
      component: SPAInputField,
    },
    {
      gridLength: 10,
      otherOptions: {
        name: "taxRate",
        placeholder: "Select Product Type",
        label: "Tax Rate",
        labelHelperText: ".",
        options: [
          { value: "Gst", label: "Gst" },
          { value: "Hst", label: "Hst" },
        ],
      },
      component: SPASelectField,
    },
  ];
};

export const activeDatesFormData = () => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        name: "startDate",
        label: "Start Date",
      },
      component: SPAMobileDatePicker,
    },

    {
      gridLength: 6,
      otherOptions: {
        name: "endDate",
        label: "End Date",
        helperText: "(Optional)",
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "startTime",
        label: "To",
        placeholder: "Select Time",
      },
      component: SPAMobileTimePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "endTime",
        label: "From",
        placeholder: "Select Time",
      },
      component: SPAMobileTimePicker,
    },
  ];
};
