import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetRolesMutation } from "../../../../../../../../services/roles";
import {
  useGetStaffsMutation,
  useUpdateSingleStaffMutation,
} from "../../../../../../../../services/staff";
import { setImageUrl } from "../../../../../../../../store/features/upload-file";
import { lightenHexColor } from "../../../../../../../../utils/convertLightenHexColor";
import { convertTo12HourFormat } from "../../../../../../../../utils/convertTimeIOSFormat";
import SpaAppointmentCard from "../../../../../../../common/spa-appointment-card";
import { validationProfileSchema } from "../../validationSchema";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const useStaffOverview = ({ singleStaffData, singleStaffRefetching }) => {
  const imageUrl = useSelector((state) => state?.uploadFile?.url);
  const navigate = useNavigate();
  //form methods for profile
  const methods = useForm({ resolver: yupResolver(validationProfileSchema) });
  const { handleSubmit, control, setValue, watch } = methods;
  const currentEmployment = watch("currentEmployment");
  
  const [getAllStaffs, { isLoading: staffsDataLoader }] =
    useGetStaffsMutation();
  const [updateSingleStaff, { isLoading: updatedLoader }] =
    useUpdateSingleStaffMutation();
  const [getAllRoles, { data: rolesData, isLoading: rolesDataLoader }] =
    useGetRolesMutation();

  const dispatch = useDispatch();
  const [staffDetailApi, setStaffDetailApi] = useState(null);
  const [singleAppointment, setSingleAppointment] = useState([]);

  const onSubmit = async (values) => {
    let payLoad = {
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender === "" ? undefined : values.gender,
      email: values.email,
      mobileNumber: values.mobileNumber,
      status: values?.status,
      roleType: "STAFF",
      profilePictureUrl: imageUrl ? imageUrl : "",
      role: values?.role,
      currentEmployment: values?.currentEmployment,
      employmentStartDate:
        new Date(values?.employmentStartDate).toISOString() ?? undefined,
      employmentEndDate:
        new Date(values?.employmentEndDate).toISOString() ?? undefined,
      dateOfBirth: values?.dateOfBirth ?? undefined,
      ...(values?.note && {
        note: {
          note: values?.note,
          isPinned: false,
          inCalendar: false,
        },
      }),
    };

    await updateSingleStaff({
      id: singleStaffData?.result?.data?.user?._id,
      payload: payLoad,
    })
      .unwrap()
      .then((response) => {
        if (response.status) {
          toast.success(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.data.error);
      });
    singleStaffRefetching();
    getAllStaffs({ roleType: "STAFF" });
  };

  const handleInitialValues = () => {
    if (!singleStaffData) return;
    const { data } = singleStaffData.result || {};
    if (!data) return;
    const { appointments, user } = data;
    setStaffDetailApi(user);
    setValue("note", user?.notes[0]?.note);
    Object.entries(user).forEach(([key, value]) => {
      setValue(key, value);
      if (key === "employmentStartDate" || key === "employmentEndDate") {
      }
      if (key === "profilePictureUrl" && !value.includes("example")) {
        dispatch(setImageUrl({ url: value }));
      } else if (key === "profilePictureUrl" && value.includes("example"))
        dispatch(setImageUrl({ url: "" }));
    });

    if (appointments?.length > 0) {
      appointments.map((appointment, index) => {
        const inputDate = new Date(appointment?.date);
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        const formattedDate = inputDate.toLocaleDateString("en-US", options);
        const isCurrentContradication =
          appointment.client?.contraindication?.length > 0 &&
          appointment.client?.contraindication[
            appointment.client?.contraindication?.length - 1
          ]?.text
            ? true
            : false;
        if (index === 0) {
          setSingleAppointment([
            {
              title: dayjs(formattedDate).format("DD MMM YYYY"),
              children: [
                {
                  otherOptions: {
                    color: appointment?.services[0]?.category?.color,
                    backgroundColor: lightenHexColor(
                      appointment?.services[0]?.category?.color,
                      0.9
                    ),
                    locationName: "Sydney",
                    author:
                      appointment?.user === null
                        ? "No Staff"
                        : `${appointment?.user?.firstName} ${appointment?.user?.lastName}`,
                    time: convertTo12HourFormat(appointment?.startTime),
                    appointmentId: appointment?._id,
                    condition: {
                      isDepositPaid: appointment.isPaidFull,
                      isInvoicePaid: false,
                      isBookedOnline: false,
                      isContraindication: isCurrentContradication,
                      isNotes: appointment.notes?.length > 0 ? true : false,
                      isRecurringAppointment: appointment?.isRecurring,
                      isMaintenance: false,
                      isNewClient:
                        appointment?.client?.status === "NEW" ||
                        appointment?.client?.status === "OPEN"
                          ? true
                          : false,
                      isClientAttended:
                        appointment?.status === "COMPLETED" ? true : false,
                      isClientNoShow:
                        appointment?.status === "NO_SHOW" ? true : false,
                      isAppointmentCancelled:
                        appointment?.status === "CANCELLED" ? true : false,
                    },
                  },
                  component: SpaAppointmentCard,
                },
              ],
            },
          ]);
        }
      });
    }
  };

  useEffect(() => {
    if (singleStaffData) {
      handleInitialValues();
      setValue(
        "currentEmployment",
        singleStaffData?.result?.data?.user?.currentEmployment
          ? singleStaffData?.result?.data?.user?.currentEmployment
          : false
      );
      setValue("role", singleStaffData?.result?.data?.user?.roleDetails?._id);
    }
  }, [singleStaffData]);

  useEffect(() => {
    getAllRoles();
  }, []);

  return {
    methods,
    control,
    handleSubmit,
    onSubmit,
    componentloading: staffsDataLoader || updatedLoader,
    staffDetailApi,
    singleAppointment,
    rolesData,
    navigate,
    dispatch,
    currentEmployment,
  };
};

export default useStaffOverview;
