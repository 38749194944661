import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { Overview } from "./components";
import { TreatmentViewNavBarData } from "./treatment-profile-data";
import useTreatmentView from "./useTreatmentView";
import { customColors, fw600, primary, secondary } from "../../../../theme";
import Address from "./components/address";
import Notification from "./components/notification";
import Emergency from "./components/emergency";
import AppointmentSale from "./components/appointments-sale";

export default function StaffDetails({
  isResponsive,
  singleStaffDetails,
  singleStaffRefetching,
}) {
  // comment
  const { activeItem, setActiveItem, roleBasedNavBarData } =
    useTreatmentView(singleStaffDetails);
  const renderTabContent = (tab) => {
    switch (tab) {
      case "Overview":
        return (
          <Overview
            singleStaffData={singleStaffDetails?.user}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      case "Address":
        return (
          <Address
            singleStaffData={singleStaffDetails?.user}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      case "Appointments":
        return (
          <AppointmentSale
            singleStaffData={singleStaffDetails}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      case "NotificationSettings":
        return (
          <Notification
            singleStaffData={singleStaffDetails?.user}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      case "EmergencyContacts":
        return (
          <Emergency
            singleStaffData={singleStaffDetails?.user}
            singleStaffRefetching={singleStaffRefetching}
          />
        );
      default:
        return <div>Select a tab</div>;
    }
  };
  return (
    <Box sx={{ width: "100%", p: "0px 24px 24px 24px" }}>
      <Box
        sx={{
          backgroundColor: primary.blue,
          borderRadius: "8px",
          width: "100%",
          p: "4px",
          position: "sticky",
          top: "24px",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            height: "48px",
            display: "flex",
            backgroundColor: primary.blue,
            width: "max-content",
            overflow: "auto",
          }}
        >
          {roleBasedNavBarData?.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setActiveItem(item.link);
              }}
              sx={{
                flexDirection: "row",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                gap: "6px",
                backgroundColor: activeItem?.includes(item.link)
                  ? secondary.main
                  : primary.blue,
                color: activeItem?.includes(item.link)
                  ? customColors.white
                  : secondary.main,
                "&:hover": {
                  backgroundColor: activeItem?.includes(item.link)
                    ? secondary.main
                    : "auto",
                },
              }}
            >
              {item.icon && (
                <ListItemIcon
                  sx={{
                    minWidth: "auto",
                  }}
                >
                  <item.icon
                    size={20}
                    color={
                      activeItem?.includes(item.link)
                        ? customColors.white
                        : secondary.main
                    }
                  />
                </ListItemIcon>
              )}

              {!isResponsive && (
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: fw600,
                      textTransform: "uppercase",
                      width: "max-content",
                    },
                  }}
                  primary={item.label}
                />
              )}
            </ListItemButton>
          ))}
        </Box>
      </Box>
      <Box sx={{ pt: "38px", maxHeight: "72vh", overflowY: "auto" }}>
        {renderTabContent(activeItem)}
      </Box>
    </Box>
  );
}
