import { Box, Typography } from "@mui/material";
import React from "react";

const TreatmentCard = ({
  servicesData,
  serviceLoader,
  handleSingleServiceSelect,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        p: "16px 0px",
        width: "100%",
      }}
    >
      {servicesData?.length > 0 &&
        servicesData?.map((item) => {
          return (
            <Box
              onClick={() => handleSingleServiceSelect(item)}
              key={item?._id}
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                width: "100%",
                height: "48px",
                paddingRight: "8px",
                alignItems: "center",
                gap: "8px",
                borderRadius: "8px",
                background: "#FFF",
                justifyContent: "space-between",
                overflow: "hidden",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  pl: "8px",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      padding: "4px 8px",
                      background: "#FAFAFA",
                      color: "#25272B",
                      fontSize: "10px",
                      textTransform: "uppercase",
                      maxWidth: "fit-content",
                      borderRadius: "4px",
                    }}
                  >
                    {item?.category?.name}
                  </Box>
                  <Typography
                    sx={{
                      color: "#5E6062",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {item?.name}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "row",
                    md: "column",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#5E6062",
                    fontSize: "14px",
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  ${item?.price}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#8F9193",
                      fontSize: "10px",
                      textTransform: "uppercase",
                    }}
                  >
                    {item?.hours} hour
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      {servicesData?.length === 0 && !serviceLoader && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Box>
            <img src="/images/cart_icon.svg" alt="cart" />
          </Box>
          <Typography
            sx={{
              color: "#5E6062",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Not Treatment Listing Yet
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TreatmentCard;
