import {
  LinkBreak,
  ShootingStar,
  SketchLogo,
  StarHalf,
} from "@phosphor-icons/react";
import SPAInputField from "../hook-form/SPAInputField";
import SPAMobileDatePicker from "../hook-form/SPAMobileDatePicker";
import SPAMobileTimePicker from "../hook-form/SPAMobileTimerPicker";
import AutoCompleteDetails from "../common/autocomplete-details/autocomplete-details";

export const newSalesFormData = (clientsData, showCart) => {
  let initialData = [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "client",
        label: "Client",
        placeholder: "i.e.: John Doe",
        options:
          clientsData &&
          clientsData?.map((item) => {
            return {
              label: `${item.firstName} ${item.lastName}`,
              value: `${item.firstName} ${item.lastName}`,
              id: item._id,
              mobileNumber: item?.mobileNumber,
              status: item?.clientStatus,
            };
          }),
      },
      component: AutoCompleteDetails,
    },
  ];
  if (showCart) {
    initialData.push(
      {
        gridLength: 6,
        otherOptions: {
          fullWidth: true,
          name: "buyDate",
          label: "Buy Date",
          disablePast: true,
          placeholder: "i.e.: 20/12/2024",
        },
        component: SPAMobileDatePicker,
      },
      {
        gridLength: 6,
        otherOptions: {
          fullWidth: true,
          name: "buyTime",
          label: "Buy Time",
          placeholder: "i.e.: 12:00",
          // disablePast: true,
        },
        component: SPAMobileTimePicker,
      },
      {
        key: "notes",
        gridLength: 12,
        otherOptions: {
          name: "note",
          label: "Additional Notes",
          labelHelperText: "(Optional)",
          type: "textarea",
          placeholder: "Add any other additional notes here",
        },
        component: SPAInputField,
      }
      // {
      //   gridLength: 12,
      //   otherOptions: {
      //     fullWidth: true,
      //     name: "cardName",
      //     label: "Card Full Name",
      //     placeholder: "i.e.: John Doe",
      //   },
      //   component: SPAInputField,
      // },
      // {
      //   gridLength: 12,
      //   otherOptions: {
      //     fullWidth: true,
      //     name: "cardNumber",
      //     label: "Card Number",
      //     placeholder: "i.e.: 1234 456 678 789 567",
      //   },
      //   component: SPAInputField,
      // },
      // {
      //   gridLength: 6,
      //   otherOptions: {
      //     fullWidth: true,
      //     name: "expireDate",
      //     label: "Expire Date",
      //     disablePast: true,
      //     placeholder: "i.e.: 20/12/2024",
      //     isCalenderShow: true,
      //   },
      //   component: SPAMobileDatePicker,
      // },
      // {
      //   gridLength: 6,
      //   otherOptions: {
      //     fullWidth: true,
      //     name: "ccv",
      //     label: "CCV",
      //     placeholder: "i.e.: ***",
      //   },
      //   component: SPAInputField,
      // }
    );
  }
  return initialData;
};

export const checkoutDetailsFormData = () => {
  let initialData = [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "cardName",
        label: "Card Full Name",
        placeholder: "i.e.: John Doe",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "cardNumber",
        label: "Card Number",
        placeholder: "i.e.: 1234 456 678 789 567",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "expireDate",
        label: "Expire Date",
        disablePast: true,
        placeholder: "i.e.: 20/12/2024",
        isCalenderShow: true,
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 5.9,
      otherOptions: {
        fullWidth: true,
        name: "ccv",
        label: "CCV",
        placeholder: "i.e.: ***",
      },
      component: SPAInputField,
    },
  ];
  return initialData;
};

export const productCategoryTabs = [
  { label: "All", value: "ALL", id: "ALL" },
  { label: "Skin Care", value: "SKIN_CARE", id: "SKIN_CARE" },
  { label: "Hair Care", value: "HAIR_CARE", id: "HAIR_CARE" },
  { label: "Nails Art", value: "NAILS_ART", id: "NAILS_ART" },
];

export const salePricingDetail = (info) => {
  return [
    { id: 1, label: "Product", value: `$${info?.totalPriceWithGST}` },
    {
      id: 2,
      label: "Voucher 10%",
      value: `$ 0`,
    },
    { id: 3, label: "Discount", value: "$0" },
  ];
};

export const statusDesign = {
  OPEN: {
    icon: <ShootingStar size={16} />,
    color: "#1AA37A",
    bgColor: "#E9FBF6",
  },
  ACTIVE: {
    icon: <ShootingStar size={16} />,
    color: "#1AA37A",
    bgColor: "#E9FBF6",
  },
  VIP: {
    icon: <SketchLogo size={16} />,
    color: "#FA9200",
    bgColor: "#FFF9F0",
  },
  NEW: {
    icon: <LinkBreak size={16} />,
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  LOST: {
    icon: <LinkBreak size={16} />,
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  RETURN: {
    icon: <StarHalf size={16} />,
    color: "#115ACB",
    bgColor: "#E7EFFC",
  },
};
