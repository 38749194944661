import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreateProductTypeMutation,
  useGetSingleProductTypeQuery,
  useUpdateProductTypeMutation,
} from "../../../../services/product-type";
import { validationSchema } from "./validationSchema";

export default function useCreateProductType(
  handleClose,
  singleRoleId,
  setIsOpenSuccessDialog,
  getAllProductType,
  setSingleRoleId
) {
  const validationSchemaInstance = validationSchema();
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
  });
  const [createProductType, { isLoading: createProductTypeLoader }] =
    useCreateProductTypeMutation();
  const [updateSingleProductType, { isLoading: updateRoleLoader }] =
    useUpdateProductTypeMutation();
  const { data: singleRole, isLoading: singleProductTypeLoader } =
    useGetSingleProductTypeQuery(singleRoleId, {
      skip: !singleRoleId,
    });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const onSubmit = async (values) => {
    let payload;
    payload = {
      name: values?.name,
    };

    let response;
    if (singleRoleId) {
      response = await updateSingleProductType({
        id: singleRole?.result?.data?._id,
        payload: payload,
      });
    } else {
      response = await createProductType(payload);
    }
    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    setSingleRoleId(response?.data?.result?.data?._id);
    toast.success(response?.data?.message);
    getAllProductType();
    reset();
    setIsOpenSuccessDialog(true);
    handleClose("Success");
  };

  const handleCloseForm = () => {
    handleClose("Deffer");
    reset();
  };

  useEffect(() => {
    if (singleRole) {
      setValue("name", singleRole?.result?.data?.name);
    }
  }, [singleRole]);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    singleProductTypeLoader,
    createProductTypeLoader,
    updateRoleLoader,
  };
}
