import React from "react";
import SPAInputField from "../../../../hook-form/SPAInputField";

const ParagraphComponent = ({
  index,
  control,
  focusedIndex,
  setFocusedIndex,
}) => {
  return (
    <SPAInputField
      // rows={1.5}
      // type="textarea"
      name={`paragraph${index}`}
      placeholder="Enter the Paragraph"
      control={control}
      autoFocus={focusedIndex === index ? true : false}
      onFocus={() => setFocusedIndex(index)}
      fullWidth
    />
  );
};

export default ParagraphComponent;
