import { Box, Button, Grid, InputAdornment, Typography } from "@mui/material";
import { MagnifyingGlass, Plus, X } from "@phosphor-icons/react";
import React from "react";
import SPATextField from "../../../../../../../../../common/spa-textField";
import NoClients from "../../../../../../../../../../assets/svg/no-clients";
import { statusDesign } from "../../../../../../../../resource-management-data";
import { staffStyle } from "../../../../../staffStyle";
import AddToQualification from "../../../../../../../../qualification/add-to-qualification";
import useTreatment from "./useTreatment";

export default function Treatments({
  singleProductData,
  singleProductRefetching,
}) {
  const {
    isOpenAddTreatmentDialog,
    setIsOpenAddTreatmentDialog,
    setAddTreatmentData,
    selectedServicesData,
    searchTreatment,
    setSearchTreatment,
  } = useTreatment({ singleProductData, singleProductRefetching });

  return (
    <Grid container gap={3}>
      <Grid
        xs={12}
        md={12}
        style={{
          borderRadius: "8px",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            background: "#F8F7FE",
            display: "flex",
            padding: "16px",
            alignItems: "center",
            gap: "16px",
            mb: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", gap: "4px" }}>
              <Typography
                sx={{
                  color: "#4A4C4F",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {" "}
                Treatments that uses this product
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", my: 1, justifyContent: "space-between" }}
            >
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: "#EDECFC",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  height: "32px",
                  color: "#4E45E5",
                  mb: "8px",
                  "&:hover": {
                    background: "#EDECFC",
                    color: "#4E45E5",
                  },
                }}
                onClick={() => {
                  setIsOpenAddTreatmentDialog(true);
                  // setStaffUpdateType("Treatment");
                }}
              >
                <Plus size={24} color="currentColor" />
                Add Treatment
              </Button>
              <SPATextField
                placeholder={`Search Treatment`}
                value={searchTreatment}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} color={"#C1C2C3"} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        paddingLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSearchTreatment("");
                      }}
                    >
                      {searchTreatment && <X size={24} />}
                    </InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchTreatment(event.target.value);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  borderRadius: "4px",
                  bgcolor: "#FFFFFF",
                  p: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      color: "#5E6062",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Name
                  </Typography>
                </Box>
              </Box>
              {selectedServicesData?.map((item) => {
                return (
                  <Box
                    key={item?._id}
                    sx={{
                      borderRadius: "4px",
                      bgcolor: "#FFFFFF",
                      p: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <Typography
                        sx={{
                          color: "#5E6062",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {item?.name}
                      </Typography>

                      {item?.clientInteraction && (
                        <Box
                          sx={{
                            backgroundColor:
                              statusDesign[item?.clientInteraction]?.bgColor,
                            color: statusDesign[item?.clientInteraction]?.color,
                            ...staffStyle.statusInfo,
                          }}
                        >
                          {item?.clientInteraction}
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}
              {selectedServicesData?.length === 0 && (
                <Box
                  sx={{
                    mt: "18px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <NoClients />
                  <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                    There’s no No selected Treatment yet
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                    After adding Treatment they will be displayed here.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {isOpenAddTreatmentDialog && (
          <AddToQualification
            setIsOpenSuccessDialog={() => {}}
            singleQualificationId={() => {}}
            isAddQualificationOpen={isOpenAddTreatmentDialog}
            setAddQualificationData={setAddTreatmentData}
            initialData={selectedServicesData}
            handleClose={(data) => {
              setIsOpenAddTreatmentDialog(false);
            }}
            dialogTitle={`Add Product to a Treatment`}
            contentTitle={`Treatments`}
            subContentDescription={`Add this product to a treatment.`}
            type={"Treatment"}
            submitText="Add Treatment"
          />
        )}
      </Grid>
    </Grid>
  );
}
