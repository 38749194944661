import dayjs from "dayjs";

export const timeDifference = (startTime, endTime) => {
  const duration = dayjs(endTime).diff(dayjs(startTime));
  const hours = Math.floor(duration / (60 * 60 * 1000));
  const minutes = Math.floor((duration % (60 * 60 * 1000)) / (60 * 1000));
  const formattedTimeDifference = `${hours > 0 ? `${hours}hr` : ""} ${
    minutes > 0 ? `${minutes} min` : ""
  }`;
  return formattedTimeDifference;
};
