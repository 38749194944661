import React from "react";
import ResourceManagementSection from "../../components/resource-management";

/**
 * ResourceManagement component.
 * Renders the Resource management page.
 *
 * @returns {JSX.Element} JSX code for rendering the ResourceManagement component.
 */

const ResourceManagement = () => {
  return <ResourceManagementSection />;
};

export default ResourceManagement;
