import { baseAPI } from "../baseApi";

export const RoomsApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllRooms: builder.query({
      query: () => ({ url: "/room" }),
      providesTags: ["Rooms"],
    }),
    getRooms: builder.mutation({
      query: (body) => ({ url: "/room", method: "PATCH", body }),
      providesTags: ["Rooms"],
    }),
    createRoom: builder.mutation({
      query: (payload) => ({
        url: "/room",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Rooms"],
    }),
    getSingleRoom: builder.query({
      query: (roomId) => ({ url: "/room/" + roomId }),
      providesTags: ["singleRoom"],
    }),
    updateSingleRoom: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/room/" + id,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["Rooms"],
    }),
  }),
});

export const {
  useGetAllRoomsQuery,
  useGetSingleRoomQuery,
  useGetRoomsMutation,
  useCreateRoomMutation,
  useUpdateSingleRoomMutation,
} = RoomsApi;
