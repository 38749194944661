import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { validationAddressSchema } from "../../validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import {
  useGetSingleClientQuery,
  useUpdateAddressesByIdMutation,
} from "../../../../../../services/clients";
import SPAInputField from "../../../../../hook-form/SPAInputField";
import SPASelectField from "../../../../../hook-form/SPASelectField";
import useCountryList from "../../../../../common/spa-hooks/useCountryList";
import { addressElements } from "../../client-profile-data";

export default function useClientAddress() {
  const clientDetail = useSelector((state) => state.clientDetail.clientDetail);

  //form methods for address
  const addressMethods = useForm({
    resolver: yupResolver(validationAddressSchema),
  });
  const {
    handleSubmit: handleAddressSubmit,
    control: addressControl,
    setValue: setAddressValue,
    reset: resetAddress,
  } = addressMethods;

  //api working
  const { data: singleClient } = useGetSingleClientQuery(clientDetail._id);
  const [updateAddress, { isLoading: addressLoading }] =
    useUpdateAddressesByIdMutation();
  const { countries: countryArray } = useCountryList();

  const [addressComponents, setAddressComponents] = useState([]);
  const [isAddressFieldReadOnly, setIsAddressFieldReadOnly] = useState(true);
  const [clientDetailApi, setClientDetailsApi] = useState(null);
  const [addressesArray, setAddressesArray] = useState([]);
  const [apiAddressesArray, setApiAddressesArray] = useState([]);
  const [addNewAddress, setAddNewAddress] = useState(0);
  const [selectedAddressId, setSelectedAddresId] = useState("");

  //functions for address
  const handleEditAddressButtonClick = (item) => {
    setIsAddressFieldReadOnly(false);

    if (typeof item === "number") {
      setAddNewAddress(item + 1);
    } else setSelectedAddresId(item.id);
  };

  const handleSingleAddress = useCallback(() => {
    if (selectedAddressId) {
      const selectedAddressDetails = apiAddressesArray.filter(
        (item) => item.id === selectedAddressId
      );

      setAddressValue("_id", selectedAddressId);

      if (selectedAddressDetails.length > 0) {
        let tempObje = selectedAddressDetails[0].componentArray.map((item) => {
          const name = item.otherOptions?.name;
          const value = item.otherOptions?.value;

          setAddressValue(name, value);

          return {
            gridLength: item.gridLength,
            otherOptions: {
              name: item.otherOptions.name,
              label: item.otherOptions.label,
              options:
                name === "state"
                  ? [
                      {
                        label: "New South Wales (NSW)",
                        value: "New South Wales (NSW)",
                      },
                      {
                        label: "Queensland (QLD)",
                        value: "Queensland (QLD)",
                      },
                      {
                        label: "South Australia (SA)",
                        value: "South Australia (SA)",
                      },
                      {
                        label: "Tasmania (TAS)",
                        value: "Tasmania (TAS)",
                      },
                      {
                        label: "Victoria (VIC)",
                        value: "Victoria (VIC)",
                      },
                      {
                        label: "Western Australia (WA)",
                        value: "Western Australia (WA)",
                      },
                      {
                        label: "Australian Capital Territory (ACT)",
                        value: "Australian Capital Territory (ACT)",
                      },
                      {
                        label: "Northern Territory (NT)",
                        value: "Northern Territory (NT)",
                      },
                    ]
                  : null,
              placeholder: ``,
            },
            component:
              item.otherOptions.name === "state"
                ? SPASelectField
                : SPAInputField,
          };
        });

        tempObje = { id: selectedAddressId, componentArray: tempObje };

        const addressArrayRevamp = apiAddressesArray.map((item) => {
          return item.id === selectedAddressId ? tempObje : item;
        });

        setAddressesArray(addressArrayRevamp);
      }
    }
  }, [selectedAddressId]);

  //onSubmit for address
  const onSubmitAddress = async (values) => {
    let payload = {};
    let perClientAddresses = clientDetailApi?.addresses;

    if (selectedAddressId && perClientAddresses?.length > 0) {
      const addressPayload = perClientAddresses.map((item) => {
        if (item._id === values._id) return { ...values };
        else return { ...item };
      });
      payload = { addresses: addressPayload };
    } else if (perClientAddresses?.length > 0) {
      payload = { addresses: [...perClientAddresses, { ...values }] };
    } else {
      payload = {
        addresses: [
          {
            suburb: values?.suburb,
            state: values.state,
            street: values.street,
            postalCode: values.postalCode,
          },
        ],
      };
    }

    setIsAddressFieldReadOnly(true);
    setSelectedAddresId("");
    setAddNewAddress(0);
    resetAddress();

    const response = await updateAddress({ id: clientDetail._id, payload });

    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    toast.success("Successfully Address Added!");
  };

  const handleInitialValues = () => {
    if (!singleClient) return;

    const { data } = singleClient.result || {};
    if (!data) return;

    const { user } = data;
    const { addresses } = user;
    setClientDetailsApi(user);

    if (addresses?.length > 0) {
      const addressComponents = addresses.map((address) => {
        const componentArray = [
          { key: "street", label: "Street" },
          { key: "state", label: "State" },
          { key: "suburb", label: "Suburb" },
          { key: "postalCode", label: "Post Code" },
        ].map(({ key, label }) => ({
          gridLength: 5.9,
          otherOptions: {
            name: key,
            label: label,
            value: address[key] || "",
            options:
              key === "state"
                ? [
                    {
                      label: "New South Wales (NSW)",
                      value: "New South Wales (NSW)",
                    },
                    {
                      label: "Queensland (QLD)",
                      value: "Queensland (QLD)",
                    },
                    {
                      label: "South Australia (SA)",
                      value: "South Australia (SA)",
                    },
                    {
                      label: "Tasmania (TAS)",
                      value: "Tasmania (TAS)",
                    },
                    {
                      label: "Victoria (VIC)",
                      value: "Victoria (VIC)",
                    },
                    {
                      label: "Western Australia (WA)",
                      value: "Western Australia (WA)",
                    },
                    {
                      label: "Australian Capital Territory (ACT)",
                      value: "Australian Capital Territory (ACT)",
                    },
                    {
                      label: "Northern Territory (NT)",
                      value: "Northern Territory (NT)",
                    },
                  ]
                : [],
          },
          component: key === "state" ? SPASelectField : SPAInputField,
        }));

        return { id: address._id, componentArray };
      });

      setApiAddressesArray(addressComponents);
      setAddressesArray(addressComponents);
    }
  };

  useEffect(() => {
    handleInitialValues();
  }, [singleClient]);

  useEffect(() => {
    const updatedAddressArray = addressElements(
      countryArray,
      addNewAddress,
      addressesArray
    );
    setAddressComponents(updatedAddressArray);
  }, [addressesArray, addNewAddress, countryArray]);

  useEffect(() => {
    if (addNewAddress > 0) {
      setSelectedAddresId("");
      setAddressValue("");
      setIsAddressFieldReadOnly(false);
    } else handleSingleAddress();
  }, [selectedAddressId, addNewAddress]);

  return {
    addressControl,
    addressesArray,
    addNewAddress,
    countryArray,
    addressLoading,
    addressMethods,
    onSubmitAddress,
    selectedAddressId,
    handleAddressSubmit,
    addressComponents,
    isAddressFieldReadOnly,
    handleEditAddressButtonClick,
  };
}
