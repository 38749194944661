import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openNewSalesModal: false,
  salesDetail: {},
};

export const openSalesModalSlice = createSlice({
  name: "salesModal",
  initialState,
  reducers: {
    handleOpenSalesModal: (state, action) => {
      state.openNewSalesModal = true;
      state.salesDetail = action.payload;
    },
    handleCloseSalesModal: (state) => {
      state.openNewSalesModal = false;
      state.salesDetail = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { handleOpenSalesModal, handleCloseSalesModal } =
  openSalesModalSlice.actions;

export default openSalesModalSlice.reducer;
