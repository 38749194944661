import React from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { AntSwitch, labelStyle } from "./new-appointment-style.js";
import {
  newAppointmentFormData,
  newClientFormData,
} from "./new-appointment-form-data";
import useNewAppointment from "./useNewAppointment";
import SPADialogue from "../common/spa-dialogue/index.jsx";
import { Controller } from "react-hook-form";

export default function NewAppointmentForm({
  getAllClients,
  isAppointmentOpen,
  handleClose,
}) {
  const {
    control,
    servicesData,
    usersData,
    roomData,
    methods,
    handleSubmit,
    onSubmit,
    fullName,
    clientArray,
    isLoading,
    filteredStartTimeArray,
    endTime,
    startTime,
    newClientPopup,
    handleCloseForm,
    onChangeClientText,
    clientsDataLoader,
    usersDataLoader,
    roomsDataLoader,
    servicesDataLoader,
  } = useNewAppointment({ handleClose, getAllClients });

  const formData = newAppointmentFormData(
    servicesData,
    usersData?.result?.data?.data,
    roomData,
    clientArray,
    filteredStartTimeArray,
    fullName,
    onChangeClientText
  );

  return (
    <SPADialogue
      open={isAppointmentOpen}
      handleClose={handleCloseForm}
      dialogTitle={
        fullName ? `New Appointment For ${fullName}` : `New Appointment`
      }
      cancelText="Cancel"
      submitText="Create New Appointment"
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      methods={methods}
      loading={isLoading}
    >
      {(clientsDataLoader ||
        usersDataLoader ||
        roomsDataLoader ||
        servicesDataLoader) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            clientsDataLoader ||
            usersDataLoader ||
            roomsDataLoader ||
            servicesDataLoader
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          width: "688px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Grid container spacing={4}>
          {formData.map((item, index) => {
            return (
              <Grid item lg={item.gridLength} xs={12} key={index}>
                <item.component
                  {...item.otherOptions}
                  control={control}
                  // error={item.type !== "input" ? errors : ""}
                />
              </Grid>
            );
          })}
        </Grid>

        {endTime && (
          <Grid xx={12} item>
            <Typography>{`${startTime} to ${endTime} Slot Booked For You`}</Typography>
          </Grid>
        )}

        <Box sx={{ display: "flex", gap: "12px" }}>
          <Typography sx={labelStyle}>Paid In Full</Typography>
          <Controller
            name="isPaidFull"
            control={control}
            render={({ field }) => (
              <AntSwitch
                defaultChecked
                inputProps={{ "aria-label": "ant design" }}
                {...field}
              />
            )}
          />
        </Box>

        {newClientPopup && (
          <Box sx={{ display: "flex", gap: "30px", flexDirection: "column" }}>
            <Divider />

            <Grid container spacing={4}>
              {newClientFormData.map((item, index) => {
                return (
                  <Grid item lg={item.gridLength} xs={12} key={index}>
                    <item.component {...item.otherOptions} control={control} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
      </Box>
    </SPADialogue>
  );
}
