import React, { useEffect, useState } from "react";
import { Typography, Button, Box, Avatar } from "@mui/material";
import { CircleAvatar } from "../../styleComponents/style";
import {
  profileImgStyle,
  uploadBtn,
  uploadImgWrapperStyle,
  title,
} from "./profile-image-upload-style";
import UserProfileIcon from "../../assets/svg/user-profile-icon";
import { useUploadFileMutation } from "../../services/upload";
import { useDispatch, useSelector } from "react-redux";
import { setImageUrl } from "../../store/features/upload-file";
import { nameInitialsAvatar } from "../../utils/nameInitialsAvatar";
import theme from "../../theme/theme";
import Loader from "../common/loader";

const ProfileImageUploader = ({
  titleText,
  width,
  height,
  uploadBtnText,
  initals,
  initialSize = "167px",
  isDisabled = false,
  profileUpload = false,
  isRequired = false,
}) => {
  const dispatch = useDispatch();
  const imageUrl = useSelector((state) => state?.uploadFile?.url);

  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const [image, setImage] = useState(imageUrl);
  const [error, setError] = useState("");

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setError("File size exceeds 2MB");
        return;
      }
      setError("");
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await uploadFile(formData);
        const resp = response.data;
        if (resp) {
          setImage(resp.result.data.url);
          dispatch(setImageUrl({ url: resp.result.data.url }));
        }
      } catch (err) {
        // setError("Error uploading file");
      }
    }
  };

  useEffect(() => {
    setImage(imageUrl);
  }, [imageUrl]);

  const buttonStyle = uploadBtn(theme, profileUpload);
  const uploadWrpperStyle = uploadImgWrapperStyle(profileUpload);

  return (
    <>
      <Box sx={uploadWrpperStyle}>
        {titleText && (
          <Typography sx={title}>
            {titleText}
            {isRequired && <Typography> *</Typography>}
          </Typography>
        )}

        {isLoading ? (
          <Box
            sx={{
              marginTop: "10px",
              height: height,
              width: width,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader size={"25px"} />
          </Box>
        ) : (
          <CircleAvatar width={width} height={height} variant="rounded">
            {image ? (
              <img
                src={image}
                alt="Profile"
                style={profileImgStyle}
                variant="rounded"
              />
            ) : initals ? (
              <Avatar
                {...nameInitialsAvatar(initals, initialSize)}
                variant="rounded"
              />
            ) : (
              <UserProfileIcon />
            )}
          </CircleAvatar>
        )}
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="upload-image"
          type="file"
          onChange={handleImageUpload}
        />
        {isDisabled === false && (
          <label htmlFor="upload-image">
            <Button
              component="span"
              sx={buttonStyle}
              disabled={isDisabled ? isDisabled : false}
            >
              {uploadBtnText}
            </Button>
          </label>
        )}
      </Box>
      {/* {error && ( */}
      <Typography
        color="#D13438"
        variant="body2"
        sx={{
          mt: "2px",
          visibility: error ? "visible" : "hidden",
          textAlign: "center",
        }}
      >
        {error || "space"}
      </Typography>
      {/* )} */}
    </>
  );
};

export default ProfileImageUploader;
