import { Box, CircularProgress, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useUploadFileMutation } from "../../../services/upload";
import BlockContent from "./block-content";
import { Warning } from "@phosphor-icons/react";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  padding: "25px 10px",
}));

// ----------------------------------------------------------------------

const SPAVideoUpload = ({
  error,
  files,
  onRemove,
  onRemoveAll,
  helperText,
  customContent,
  fileUpload,
  sx,
  initialSrc,
  ...other
}) => {
  const [filesUpload, setFilesUpload] = useState("");
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const handleVideoUpload = async (file) => {
    const files = file[0];
    if (files) {
      if (files.size > 10 * 1024 * 1024) {
        setErrorMessage("File size exceeds 10MB");
        return;
      }

      const formData = new FormData();
      formData.append("file", files);
      const response = await uploadFile(formData);
      const resp = response.data;
      if (resp) {
        setFilesUpload(resp.result.data.url);
        fileUpload(resp.result.data.url);
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: {
      "video/mp4": [".mp4"],
      "video/mkv": [".mkv"],
      "video/avi": [".avi"],
    },
    onDrop: (acceptedFiles) => {
      handleVideoUpload(acceptedFiles);
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        if (file.errors.some((e) => e.code === "file-too-large")) {
          setErrorMessage("File size exceeds 10MB");
        } else if (file.errors.some((e) => e.code === "file-invalid-type")) {
          setErrorMessage("Invalid file type. Only MP4, MKV, AVI are allowed.");
        }
      });
    },
    maxSize: 10 * 1024 * 1024, // 10MB
    ...other,
  });

  useEffect(() => {
    setFilesUpload(initialSrc);
  }, [initialSrc]);

  return (
    <DropZoneStyle
      {...getRootProps()}
      sx={{
        borderRadius: "8px",
        backgroundColor: filesUpload?.length > 0 ? "transparent" : "#FFFFFF",
        border: filesUpload?.length > 0 ? "none" : `1px dashed #4E45E5`,
        "&:hover": { opacity: 0.72, cursor: "pointer" },
        ...(isDragActive && { opacity: 0.72 }),
        ...((isDragReject || error) && {
          color: "error.main",
          borderColor: "error.light",
          bgcolor: "error.lighter",
        }),
        ...sx,
      }}
    >
      <input {...getInputProps()} />
      {isLoading &&
        (filesUpload === undefined || filesUpload?.length === 0) && (
          <Box
            sx={{
              height: "196px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
      {customContent ??
        ((filesUpload === undefined || filesUpload?.length === 0) &&
          !isLoading && <BlockContent filesUpload={filesUpload} />)}
      {filesUpload !== undefined && filesUpload?.length > 0 && !isLoading && (
        <video
          src={filesUpload}
          controls
          loop
          autoPlay
          style={{ height: "196px", width: "100%", borderRadius: "8px" }}
        />
      )}
      {errorMessage && (
        <Typography
          sx={{
            color: "#FFAA33",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "5px",
            justifyContent: "center",
          }}
          color="error"
        >
          <Warning color={"#FFAA33"} size={16} />
          {errorMessage}
        </Typography>
      )}
    </DropZoneStyle>
  );
};

export default SPAVideoUpload;
