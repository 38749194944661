import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import {
  ArrowsInSimple,
  ArrowsOutSimple,
  CallBell,
  CursorClick,
  DotsSixVertical,
  Eye,
  HandbagSimple,
  Image,
  Paragraph,
  Ticket,
  Trash,
  Video,
  Warning,
} from "@phosphor-icons/react";
import { Editor } from "@tinymce/tinymce-react";
import React, { useCallback, useEffect, useState } from "react";
import NoArticle from "../../../../assets/svg/no-article";
import SPAAlertDialog from "../../../common/spa-alert-dialogue";
import SPADialogue from "../../../common/spa-dialogue";
import SPAImageUpload from "../../../common/spa-image-upload";
import SPASwitch from "../../../common/spa-switch";
import FormProvider from "../../../hook-form/FormProvider";
import SPAMobileDatePicker from "../../../hook-form/SPAMobileDatePicker";
import { InsertBtn, SubmitBtn, articleViewStyle } from "../articleStyle";
import ButtonComponent from "./components/button";
import CreateArticleView from "./components/create-article-view";
import ImageComponent from "./components/image";
import ParagraphComponent from "./components/paragraph";
import ProductComponent from "./components/product";
import TreatmentComponent from "./components/treatment";
import VideoComponent from "./components/video";
import {
  ButtonView,
  ImageView,
  ParagraphView,
  ProductView,
  TitleView,
  TreatmentView,
  VideoView,
} from "./create-article-form-data";
import useCreateArticle from "./useCreateArticle";
import theme from "../../../../theme/theme";

export default function CreateNewArticle({
  createArticleButton,
  handleClose,
  articleId,
  getAllArticles,
  setArticleId,
}) {
  // articles focus issue
  const {
    errors,
    singleArticleLoader,
    control,
    onSubmit,
    methods,
    handleSubmit,
    createArticleLoading,
    articleTitle,
    alttext,
    productCategoryData,
    serviceCategoryData,
    allProductData,
    productDataLoading,
    handleAddProduct,
    selectedProduct,
    servicesData,
    handleAddTreatment,
    selectedTreatment,
    servicesDataLoading,
    videoFile,

    imageFile,
    selectedValue,
    editorInstance,
    handleInsertSelection,
    dragItem,
    dragOverItem,
    handleSort,
    setEditorInstance,
    isOpenDeleteDialog,
    setIsOpenDeleteDialog,
    handleDeleteInsertValue,
    setDeleteValue,
    handleFileUpload,
    handleVideoUpload,
    videoalttext,

    searchProduct,
    setSearchProduct,
    isSearchBox,
    setIsSearchBox,
    activeItem,
    setActiveItem,
    searchTreatment,
    setSearchTreatment,
    isSearchBoxTreatment,
    setIsSearchBoxTreatment,
    activeItemTreatment,
    setActiveItemTreatment,

    openDialogCreatePreviewArticle,
    setOpenDialogCreatePreviewArticle,

    articleStep,
    setArticleStep,

    handleThumbnailUpload,
    thumbnailImg,
    singleArticleDataLoader,
    handleCancelBtn,

    featureArticle,
    showInHomeArticle,
    notifyClientsArticle,
    notifyClientsValue,
    setNotifyClientsValue,
    showInHomeValue,
    setShowInHomeValue,
    isSinglePreviewActive,
    setIsSinglePreviewActive,
    singleArticleData,

    handleDeleteArticle,
    isOpenDeleteArticleDialog,
    setIsOpenDeleteArticleDialog,
    focusedIndex,
    setFocusedIndex,
  } = useCreateArticle({
    createArticleButton,
    handleClose,
    articleId,
    getAllArticles,
    setArticleId,
  });

  const InsertData = [
    { id: 1, value: "Paragraph", icon: Paragraph },
    { id: 2, value: "Button", icon: CursorClick },
    { id: 3, value: "Image", icon: Image },
    { id: 4, value: "Video", icon: Video },
    { id: 5, value: "Product", icon: HandbagSimple },
    { id: 6, value: "Treatment", icon: CallBell },
    { id: 7, value: "Bundle", icon: CallBell },
    { id: 8, value: "Discount Code", icon: Ticket },
  ];

  const renderComponent = (value, index) => {
    switch (value) {
      case "Paragraph":
        return (
          <ParagraphComponent
            index={index}
            control={control}
            focusedIndex={focusedIndex}
            setFocusedIndex={setFocusedIndex}
          />
        );
      case "Button":
        return (
          <ButtonComponent
            index={index}
            control={control}
            focusedIndex={focusedIndex}
            setFocusedIndex={setFocusedIndex}
          />
        );
      case "Image":
        return (
          <ImageComponent
            index={index}
            imageFile={imageFile}
            control={control}
            handleFileUpload={handleFileUpload}
            focusedIndex={focusedIndex}
            setFocusedIndex={setFocusedIndex}
          />
        );
      case "Video":
        return (
          <VideoComponent
            index={index}
            videoFile={videoFile}
            control={control}
            handleVideoUpload={handleVideoUpload}
            focusedIndex={focusedIndex}
            setFocusedIndex={setFocusedIndex}
          />
        );
      case "Product":
        return (
          <ProductComponent
            isSearchBox={isSearchBox}
            setIsSearchBox={setIsSearchBox}
            selectedProduct={selectedProduct}
            searchProduct={searchProduct}
            setSearchProduct={setSearchProduct}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            allProductData={allProductData}
            productDataLoading={productDataLoading}
            productCategoryData={productCategoryData}
            handleAddProduct={handleAddProduct}
            errors={errors}
            index={index}
          />
        );
      case "Treatment":
        return (
          <TreatmentComponent
            searchTreatment={searchTreatment}
            setSearchTreatment={setSearchTreatment}
            activeItemTreatment={activeItemTreatment}
            setActiveItemTreatment={setActiveItemTreatment}
            isSearchBoxTreatment={isSearchBoxTreatment}
            setIsSearchBoxTreatment={setIsSearchBoxTreatment}
            handleAddTreatment={handleAddTreatment}
            servicesDataLoading={servicesDataLoading}
            selectedTreatment={selectedTreatment}
            serviceCategoryData={serviceCategoryData}
            servicesData={servicesData}
            errors={errors}
            index={index}
          />
        );
      default:
        console.error("Invalid option selected");
        break;
    }
  };

  const renderViewComponent = (value, id) => {
    const currentValues = methods.getValues();
    switch (value) {
      case "Paragraph":
        if (currentValues[`paragraph${id}`]) {
          return ParagraphView(currentValues[`paragraph${id}`]);
        }
        return;
      case "Button":
        if (
          currentValues[`buttonlabel${id}`] ||
          currentValues[`buttonLink${id}`]
        ) {
          return ButtonView(
            currentValues[`buttonlabel${id}`],
            currentValues[`buttonLink${id}`]
          );
        }
        return;
      case "Image":
        if (imageFile[id] || alttext) {
          return ImageView(imageFile[id], alttext);
        }
        return;
      case "Video":
        if (videoFile[id] || videoalttext) {
          return VideoView(videoFile[id], videoalttext);
        }
        return;
      case "Product":
        if (currentValues[`product${id}`]) {
          return ProductView(currentValues[`product${id}`]);
        }
        return;
      case "Treatment":
        if (currentValues[`treatment${id}`]) {
          return TreatmentView(currentValues[`treatment${id}`]);
        }
        return;
      default:
        console.error("Invalid option selected");
        break;
    }
  };

  const getEditorContent = useCallback(() => {
    const titleViewContent = TitleView(
      articleTitle ?? "Restore your skin's natural glow"
    );
    const selectedComponentsContent = selectedValue
      ?.map((value) => renderViewComponent(value.type, value.id))
      ?.join(`<p style="margin: 0px"></p>`);

    return `${titleViewContent}${selectedComponentsContent}`;
  }, [articleTitle, selectedValue]);
  // const getEditorContent = () => {
  //   const titleViewContent = TitleView(
  //     articleTitle ?? "Restore your skin's natural glow"
  //   );
  //   const selectedComponentsContent = selectedValue
  //     ?.map((value) => renderViewComponent(value.type, value.id))
  //     ?.join(`<p style="margin: 0px"></p>`);

  //   return `${titleViewContent}${selectedComponentsContent}`;
  // };

  useEffect(() => {
    if (editorInstance) {
      editorInstance.setContent(getEditorContent());
    }
  }, [selectedValue, editorInstance]);

  const ArticleContentView = ({
    lg = 8,
    md = 8,
    isPreview = false,
    setIsSinglePreviewActive,
  }) => {
    return (
      <Grid item lg={lg} md={md} xs={12}>
        {!isPreview && (
          <Box sx={{ display: "flex", mb: "16px" }}>
            {" "}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                bgcolor: articleId ? "#EDECFC" : "lightgrey",
                borderRadius: "8px",
                p: "8px 6px 8px 6px",
                color: "#4E45E5",
                textTransform: "uppercase",
                fontWeight: 600,
                fontSize: "14px",
                width: "100%",
                cursor: articleId ? "pointer" : "",
              }}
              disabled={true}
              onClick={() => {
                if (articleId) {
                  setIsSinglePreviewActive(true);
                }
              }}
            >
              <Eye size={24} color="#4E45E5" />
              Preview
            </Box>
            <Button
              onClick={() => {
                setOpenDialogCreatePreviewArticle(true);
              }}
            >
              <ArrowsOutSimple size={24} color="#4E45E5" />
            </Button>
          </Box>
        )}
        <Box
          sx={{
            borderRadius: "16px",
            bgcolor: isPreview ? "#F8F7FE" : "#FFFFFF",
            p: "16px",
            height: isPreview ? "100%" : "90%",
          }}
        >
          {isPreview && (
            <Typography
              sx={{
                fontSize: "20px",
                color: "#4A4C4F",
                textAlign: "center",
              }}
            >
              Content
            </Typography>
          )}
          <Box
            sx={{
              minHeight: "50vh",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {selectedValue?.map((item, index) => (
              <Box
                key={index}
                draggable
                onDragStart={() => (dragItem.current = index)}
                onDragEnter={() => (dragOverItem.current = index)}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius: "8px",
                    p: "8px",
                    bgcolor: "#EDECFC",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <DotsSixVertical size={24} color="#4A4C4F" />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#5E6062",
                        }}
                      >
                        {item.type}
                      </Typography>
                      <Trash
                        size={16}
                        color="#D13438"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDeleteValue(item.id);
                          setIsOpenDeleteDialog(true);
                        }}
                      />
                    </Box>
                    <Box sx={{ mt: "4px" }}>
                      {renderComponent(item.type, item.id)}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
            {selectedValue?.length === 0 && (
              <Box
                sx={{
                  mt: "25px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <NoArticle />
                <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                  Start by inserting an element from the bar at the left
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    );
  };

  return (
    <Box>
      {singleArticleDataLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={singleArticleDataLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={"16px"}
          sx={{ display: !isSinglePreviewActive ? "" : "none" }}
        >
          <Grid item lg={12}>
            <Typography sx={{ color: "#070A0E", fontSize: "20px" }}>
              Create Article (Step {articleStep} of 2)
            </Typography>
          </Grid>
          {/* {articleStep === 1 && ( */}
          <Grid
            item
            lg={12}
            sx={{ display: articleStep === 1 ? "flex" : "none" }}
          >
            <CreateArticleView
              control={control}
              InsertData={InsertData}
              handleInsertSelection={handleInsertSelection}
            />
            <ArticleContentView
              isPreview={false}
              setIsSinglePreviewActive={setIsSinglePreviewActive}
            />
            <Grid item lg={4} xs={12} sx={{ display: "none" }}>
              <Box
                sx={{
                  border: "1px solid #C1C2C3",
                  borderRadius: "16px",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#4A4C4F",
                    textAlign: "center",
                  }}
                >
                  Preview
                </Typography>
                <Box
                  sx={{
                    height: "80%",
                    "& .tox-tinymce": {
                      border: "none",
                      "& .tox-edit-area::before": { border: "none" },
                    },
                  }}
                >
                  <Editor
                    apiKey="skb6117e0zohgmjyg8fws6ytucbykv0bcevzf4ntqhlwtt2f"
                    value={getEditorContent()}
                    onInit={(evt, editor) => {
                      setEditorInstance(editor);
                    }}
                    init={{
                      height: "100%",
                      menubar: false,
                      statusbar: false,
                      toolbar: false,
                      readonly: 1,
                      plugins: [
                        "advlist autolink charmap print preview anchor",
                        "searchreplace visualblocks fullscreen",
                        "insertdatetime media table paste help wordcount styleButton | styles",
                        "hr",
                        "image",
                        "bullist",
                        "lists",
                        "code",
                        "link",
                      ],
                      content_style: `
                        .highlighted { background-color: #E9FBF6; color: #1AA37A; }
                        .tox-tinymce { pointer-events: none; cursor: default; }
                        .tox-tinymce * { pointer-events: none; cursor: default; }
                      `,
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* {articleStep === 2 && ( */}
          <Box
            sx={{
              display: articleStep === 2 ? "flex" : "none",
              flexDirection: "column",
              gap: "8px",
              p: "16px 12px",
              width: "100%",
            }}
          >
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              Thumbnail <Typography sx={{ color: "#BE2F33" }}> *</Typography>
            </Typography>
            <Box sx={{ height: "50vh" }}>
              <SPAImageUpload
                fileUpload={handleThumbnailUpload}
                height="100%"
                initialSrc={thumbnailImg ?? undefined}
              />
            </Box>

            {errors?.thumbnail && (
              <Typography
                sx={{
                  color: "#FFAA33",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  mt: "5px",
                }}
                color="error"
              >
                <Warning color={"#FFAA33"} size={16} />{" "}
                {errors?.thumbnail.message}
              </Typography>
            )}
          </Box>

          <Grid
            item
            lg={12}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {articleStep === 2 && (
              <Button sx={InsertBtn} onClick={() => setArticleStep(1)}>
                Previous
              </Button>
            )}
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button sx={InsertBtn} onClick={handleCancelBtn}>
                Cancel
              </Button>
              <Button sx={SubmitBtn} type="submit">
                {articleStep === 1 ? "Next" : "Create Article"}
              </Button>
            </Box>
          </Grid>
        </Grid>

        {isSinglePreviewActive && articleId && (
          <Grid container>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                p: "16px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {singleArticleData?.result?.data?.status && (
                  <Typography
                    sx={{
                      fontSize: "10px",
                      // color: "#25272B",
                      color:
                        singleArticleData?.result?.data?.status === "PUBLISHED"
                          ? "#1AA37A"
                          : "#25272B",
                      bgcolor:
                        singleArticleData?.result?.data?.status === "PUBLISHED"
                          ? "#E9FBF6"
                          : "#FAFAFA",
                      width: "fit-content",
                      p: "4px",
                      borderRadius: "4px",
                    }}
                  >
                    {singleArticleData?.result?.data?.status}
                  </Typography>
                )}
                {articleTitle && (
                  <Typography sx={{ fontSize: "24px", color: "#5E6062" }}>
                    {articleTitle}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Button
                  sx={{
                    fontSize: "14px",
                    color: "#4E45E5",
                    fontWeight: "600",
                    p: "8px 16px 8px 16px",
                    borderRadius: "8px",
                    bgcolor: "#EDECFC",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#EDECFC",
                    },
                  }}
                  onClick={() => setIsSinglePreviewActive(false)}
                >
                  Edit
                </Button>
                <Button
                  sx={{
                    backgroundColor: theme.palette.primaryColor.main,
                    position: "relative",
                    color: theme.palette.white.main,
                    flexShrink: 0,
                    paddingInline: "16px",
                    paddingBlock: "6px",
                    borderRadius: "8px",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: theme.palette.primaryColor.dark,
                    },
                  }}
                  type="submit"
                  onClick={() => methods.setValue("submitButton", "PUBLISHED")}
                >
                  Publish
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#FAEBEB",
                    position: "relative",
                    color: theme.palette.white.main,
                    flexShrink: 0,
                    paddingInline: "8px",
                    paddingBlock: "8px",
                    borderRadius: "8px",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: "#FAEBEB",
                    },
                  }}
                  onClick={() => setIsOpenDeleteArticleDialog(true)}
                >
                  <Trash size={24} color="#D13438" />
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                p: "0px 16px 16px 16px",
                gap: "8px",
              }}
            >
              <Grid item lg={6}>
                <Box
                  sx={{
                    ...articleViewStyle.featureArticleHeaderMain,
                    bgcolor: "#FFFFFF",
                  }}
                >
                  <Box sx={articleViewStyle.selectionGrid}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: "16px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#4A4C4F",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Featured Article
                      </Typography>
                      <Typography
                        sx={{
                          color: "#5E6062",
                          fontSize: "12px",
                        }}
                      >
                        Will be shown in the ‘Articles’ page
                      </Typography>
                    </Box>
                    <Box>
                      <SPASwitch name={"featureArticle"} control={control} />
                    </Box>
                  </Box>
                  {featureArticle && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        p: "16px 12px",
                        width: "100%",
                      }}
                    >
                      <SPAMobileDatePicker
                        fullWidth={true}
                        name="showUntil"
                        label="Show Until"
                        placeholder="Pick Date"
                        isCalenderShow={true}
                        control={control}
                        helperText="(Optional)"
                      />
                      <Box sx={articleViewStyle.featureArticleHeaderMain}>
                        <Box sx={articleViewStyle.selectionGrid}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              p: "16px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#4A4C4F",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              Show In Home
                            </Typography>
                            <Typography
                              sx={{
                                color: "#5E6062",
                                fontSize: "12px",
                              }}
                            >
                              Will be shown in the ‘Articles’ page
                            </Typography>
                          </Box>
                          <Box>
                            <SPASwitch
                              name={"showInHomeArticle"}
                              control={control}
                            />
                          </Box>
                        </Box>
                        {showInHomeArticle && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                              p: "16px 12px",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                                bgcolor: "#EDECFC",
                                borderRadius: "8px",
                                p: "4px",
                              }}
                            >
                              {[
                                {
                                  value: "ARTICLES_SECTION",
                                  label: "ARTICLES SECTION",
                                },
                                {
                                  value: "FEATURED",
                                  label: "FEATURED",
                                },
                              ]?.map((item) => {
                                return (
                                  <Box
                                    key={item?.value}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "4px",
                                      bgcolor:
                                        showInHomeValue === item.value
                                          ? "#4E45E5"
                                          : "",
                                      borderRadius: "8px",
                                      p: "6.7px 16px 6.5px 16px",
                                      width: "50%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setShowInHomeValue(item.value)
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          showInHomeValue === item.value
                                            ? "#FFFFFF"
                                            : "#4E45E5",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      {item.label}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    ...articleViewStyle.featureArticleHeaderMain,
                    bgcolor: "#FFFFFF",
                  }}
                >
                  <Box sx={articleViewStyle.selectionGrid}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: "16px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#4A4C4F",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Notify Clients
                      </Typography>
                    </Box>
                    <Box>
                      <SPASwitch
                        name={"notifyClientsArticle"}
                        control={control}
                      />
                    </Box>
                  </Box>
                  {notifyClientsArticle && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        p: "16px 12px",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          bgcolor: "#EDECFC",
                          borderRadius: "8px",
                          p: "4px",
                        }}
                      >
                        {[
                          {
                            value: "WHEN_PUBLISHED",
                            label: "WHEN PUBLISHED",
                          },
                          {
                            value: "CUSTOM_DATE",
                            label: "CUSTOM DATE",
                          },
                        ]?.map((item) => {
                          return (
                            <Box
                              key={item?.value}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "4px",
                                bgcolor:
                                  notifyClientsValue === item.value
                                    ? "#4E45E5"
                                    : "",
                                borderRadius: "8px",
                                p: "6.7px 16px 6.5px 16px",
                                width: "50%",
                                cursor: "pointer",
                              }}
                              onClick={() => setNotifyClientsValue(item.value)}
                            >
                              <Typography
                                sx={{
                                  color:
                                    notifyClientsValue === item.value
                                      ? "#FFFFFF"
                                      : "#4E45E5",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "uppercase",
                                }}
                              >
                                {item.label}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                      {notifyClientsValue === "CUSTOM_DATE" && (
                        <SPAMobileDatePicker
                          fullWidth={true}
                          name="notifyOn"
                          label="Notify On"
                          placeholder="Pick Date"
                          isCalenderShow={true}
                          control={control}
                          helperText="(Optional)"
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item lg={6}>
                <Box
                  sx={{
                    borderRadius: "16px",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#4A4C4F",
                      textAlign: "center",
                    }}
                  >
                    Preview
                  </Typography>
                  <Box
                    sx={{
                      height: "60vh",
                      "& .tox-tinymce": {
                        border: "none",
                        "& .tox-edit-area::before": { border: "none" },
                      },
                    }}
                  >
                    <Editor
                      apiKey="skb6117e0zohgmjyg8fws6ytucbykv0bcevzf4ntqhlwtt2f"
                      value={getEditorContent()}
                      onInit={(evt, editor) => {
                        setEditorInstance(editor);
                      }}
                      init={{
                        height: "100%",
                        menubar: false,
                        statusbar: false,
                        toolbar: false,
                        readonly: 1,
                        plugins: [
                          "advlist autolink charmap print preview anchor",
                          "searchreplace visualblocks fullscreen",
                          "insertdatetime media table paste help wordcount styleButton | styles",
                          "hr",
                          "image",
                          "bullist",
                          "lists",
                          "code",
                          "link",
                        ],
                        content_style: `
                        .highlighted { background-color: #E9FBF6; color: #1AA37A; }
                        .tox-tinymce { pointer-events: none; cursor: default; }
                        .tox-tinymce * { pointer-events: none; cursor: default; }
                      `,
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>
        )}
      </FormProvider>
      {!singleArticleLoader && (
        <SPADialogue
          open={openDialogCreatePreviewArticle}
          handleClose={() => {
            handleClose();
            setOpenDialogCreatePreviewArticle(false);
          }}
          closeIcon={<ArrowsInSimple size={24} color="#4E45E5" />}
          dialogTitle={articleId ? "Edit Article" : `Create Article`}
          submitText={articleId ? "Save Changes" : `Done`}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          methods={methods}
          isLoading={createArticleLoading}
          sx={{ minWidth: { xl: "1400px", lg: "1274px", xs: "auto" } }}
        >
          <Grid container spacing={"16px"}>
            <CreateArticleView
              control={control}
              InsertData={InsertData}
              handleInsertSelection={handleInsertSelection}
            />
            <ArticleContentView lg={4} md={6} isPreview={true} />
            <Grid item lg={4} xs={12}>
              <Box
                sx={{
                  border: "1px solid #C1C2C3",
                  borderRadius: "16px",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#4A4C4F",
                    textAlign: "center",
                  }}
                >
                  Preview
                </Typography>
                <Box
                  sx={{
                    height: "80%",
                    "& .tox-tinymce": {
                      border: "none",
                      "& .tox-edit-area::before": { border: "none" },
                    },
                  }}
                >
                  <Editor
                    apiKey="skb6117e0zohgmjyg8fws6ytucbykv0bcevzf4ntqhlwtt2f"
                    value={getEditorContent()}
                    onInit={(evt, editor) => {
                      setEditorInstance(editor);
                    }}
                    init={{
                      height: "100%",
                      menubar: false,
                      statusbar: false,
                      toolbar: false,
                      readonly: 1,
                      plugins: [
                        "advlist autolink charmap print preview anchor",
                        "searchreplace visualblocks fullscreen",
                        "insertdatetime media table paste help wordcount styleButton | styles",
                        "hr",
                        "image",
                        "bullist",
                        "lists",
                        "code",
                        "link",
                      ],
                      content_style: `
                        .highlighted { background-color: #E9FBF6; color: #1AA37A; }
                        .tox-tinymce { pointer-events: none; cursor: default; }
                        .tox-tinymce * { pointer-events: none; cursor: default; }
                      `,
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </SPADialogue>
      )}
      {isOpenDeleteDialog && (
        <SPAAlertDialog
          open={isOpenDeleteDialog}
          handleClose={() => setIsOpenDeleteDialog(false)}
          handleNewTab={handleDeleteInsertValue}
          title={"Removal Confirmation"}
          contentText={`Removing this item will permanently delete it from the system. This action cannot be undone. Are you sure you want to proceed with the removal?`}
          disagreeText={`Remove`}
          agreeText={`Cancel`}
          type="error"
        />
      )}
      {isOpenDeleteArticleDialog && (
        <SPAAlertDialog
          open={isOpenDeleteArticleDialog}
          handleClose={() => setIsOpenDeleteArticleDialog(false)}
          handleNewTab={handleDeleteArticle}
          title={"Article Removal Confirmation"}
          contentText={`Removing this article will permanently delete it from the system. This action cannot be undone. Are you sure you want to proceed with the removal?`}
          disagreeText={`Permanently Remove Article`}
          agreeText={`Cancel`}
          type="error"
        />
      )}
    </Box>
  );
}
