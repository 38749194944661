import { useEffect, useState } from "react";
import { useGetClientEmailSMSHistoryQuery } from "../../../../../../services/clients";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export default function useEmailSms() {
  const clientDetail = useSelector((state) => state.clientDetail.clientDetail);

  const methods = useForm();
  const { control } = methods;

  const [smsEmailArray, setSmsEmailArray] = useState(null);
  const {
    data: emailSmsData,
    isLoading: emailSmsLoader,
    refetch: refetchingEmailSmsData,
  } = useGetClientEmailSMSHistoryQuery(clientDetail?._id);

  const handleArrayFormat = (data) => {
    const combinedData = [
      ...(data?.sentSms?.length > 0
        ? data?.sentSms?.map((sms) => ({
            content: sms?.message,
            media: "SMS",
            date: sms?.createdAt,
            id: sms?._id,
            subject: null,
          }))
        : []),
      ...(data?.sentEmails?.length > 0
        ? data?.sentEmails?.map((email) => ({
            media: "Email",
            date: email?.createdAt,
            id: email?._id,
            subject: email?.subject,
          }))
        : []),
      ...(data?.savedEmails?.length > 0
        ? data?.savedEmails?.map((email) => ({
            media: "Email",
            date: email?.createdAt,
            id: email?._id,
            subject: email?.subject,
          }))
        : []),
    ];
    setSmsEmailArray(combinedData);
  };

  useEffect(() => {
    if (emailSmsData?.result?.data) {
      handleArrayFormat(emailSmsData?.result?.data);
    }
  }, [emailSmsData?.result?.data]);

  useEffect(() => {
    refetchingEmailSmsData();
  }, []);

  return {
    control,
    smsEmailArray,
    emailSmsLoader,
  };
}
