import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSendClientSMSMutation } from "../../../../services/clients";
import { validationSchema } from "./validationSchema";
import {
  useCreateTemplateMutation,
  useGetAllTemplatesQuery,
  useGetSingleTemplateQuery,
} from "../../../../services/templates";

export default function useSMS({ clientDetail }) {
  const [isSuccessMsg, setIsSuccessMsg] = useState(false);
  const [isTemplateField, setIsTemplateField] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const validationSchemaInstance = validationSchema();
  const methods = useForm({ resolver: yupResolver(validationSchemaInstance) });
  const [sendSMS, { data: sendSMSData, isLoading: sendSMSLoader }] =
    useSendClientSMSMutation();
  const { data: templatesData, isLoading: templatesDataLoader } =
    useGetAllTemplatesQuery("SMS");
  const [createTemplate, { isLoading: createTemplateLoader }] =
    useCreateTemplateMutation();
  const { data: singleTemplate, isLoading: singleTemplateLoader } =
    useGetSingleTemplateQuery(templateId, {
      skip: !templateId,
    });

  const handleTemplateForm = async (templateName) => {
    setIsSuccessMsg(false);
    setIsTemplateField(false);
    const payload = {
      name: templateName,
      message: sendSMSData?.result?.data?.message ?? "Message here",
      type: "SMS",
    };

    let response;
    response = await createTemplate(payload);

    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    toast.success(response?.data?.message);
    setIsSuccessMsg(true);
  };
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const { template } = watch();

  const onSubmit = async (values) => {
    let payload = {
      phoneNumber: values?.phoneNumber,
      message: values?.message,
      savePhoneNumber: values?.savePhoneNumber,
    };
    let response;
    response = await sendSMS({ id: clientDetail?._id, payload });

    if (!response?.data?.status) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    setIsSuccessMsg(true);
    reset();
  };

  useEffect(() => {
    setTemplateId(template);
  }, [template]);

  useEffect(() => {
    if (singleTemplate) {
      const singleTemplateFormat = singleTemplate?.result?.data;
      setValue("message", singleTemplateFormat?.message);
      setValue("subject", singleTemplateFormat?.subject);
    }
  }, [singleTemplate]);

  useEffect(() => {
    if (clientDetail) {
      setValue(
        "client",
        `${clientDetail?.firstName} ${clientDetail?.lastName}`
      );
      setValue("phoneNumber", clientDetail?.mobileNumber);
    }
  }, [clientDetail]);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    sendSMSLoader,
    isSuccessMsg,
    setIsSuccessMsg,
    isTemplateField,
    setIsTemplateField,
    handleTemplateForm,
    createTemplateLoader,
    templatesData,
    singleTemplateLoader,
    templatesDataLoader,
  };
}
