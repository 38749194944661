import { Box, FormControl, FormHelperText, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { Warning } from "@phosphor-icons/react";
import dayjs from "dayjs";
import React from "react";
import { Controller } from "react-hook-form";
import ErrorIcon from "../../assets/svg/error-icon";
import { customColors } from "../../theme";
import { datePickerStyle, datePickerWrapper } from "./style";

export default function SPAMobileDatePicker({
  helperText,
  label,
  value,
  name,
  control,
  readOnly,
  placeholder,
  error,
  minDate,
  disablePast = false,
  isCalenderShow = false,
  disableFuture = false,
  disableHighlightToday = false,
}) {
  return (
    <FormControl fullWidth>
      <Typography
        sx={{
          display: "flex",
          fontSize: "14px !important",
          color: "#5E6062 !important",
          marginBottom: "8px",
        }}
      >
        {label}{" "}
        {!helperText && (
          <Typography sx={{ color: "#D13438", pl: "3px" }}> *</Typography>
        )}{" "}
        {/* {helperText && <HelperText>{helperText}</HelperText>} */}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["MobileDatePicker"]} sx={datePickerWrapper}>
          <DemoItem>
            <Controller
              name={name}
              control={control}
              defaultValue={value ? dayjs(value) : null}
              placeholder={placeholder}
              render={({ field, fieldState: { error: fieldError } }) =>
                isCalenderShow ? (
                  <DatePicker
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    minDate={minDate && dayjs().add(minDate, "day")}
                    disableHighlightToday={disableHighlightToday}
                    error={!!fieldError}
                    readOnly={readOnly}
                    {...field}
                    value={field.value ? dayjs(field.value) : null}
                    sx={datePickerStyle}
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        error: fieldError,
                        helperText: fieldError ? (
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              fontSize: "12px",
                              color: "#FFAA33",
                            }}
                          >
                            <Warning size={16} weight="fill" />{" "}
                            {fieldError?.message}
                          </Typography>
                        ) : (
                          ""
                        ),
                      },
                    }}
                  />
                ) : (
                  <MobileDatePicker
                    error={!!fieldError}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    minDate={minDate && dayjs().add(minDate, "day")}
                    disableHighlightToday={disableHighlightToday}
                    readOnly={readOnly}
                    {...field}
                    value={field.value ? dayjs(field.value) : null}
                    sx={datePickerStyle}
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        error: fieldError,
                        helperText: fieldError ? (
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              fontSize: "12px",
                              color: "#FFAA33",
                            }}
                          >
                            <Warning size={16} weight="fill" />{" "}
                            {fieldError?.message}
                          </Typography>
                        ) : (
                          ""
                        ),
                      },
                    }}
                  />
                )
              }
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
      {error && error[name] && error[name].message && (
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <ErrorIcon />
          <FormHelperText
            sx={{ margin: "0px", padding: "0px" }}
            style={{ color: customColors.warningError }}
            error
          >
            {error[name]?.message}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
}
