import { FilterList } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  CurrencyDollar,
  MagnifyingGlass,
  Percent,
  Plus,
  Ticket,
} from "@phosphor-icons/react";
import React from "react";
import NoNotifications from "../../../../../../../../assets/svg/no-notifications";
import { secondary } from "../../../../../../../../theme";
import SPAAlertDialog from "../../../../../../../common/spa-alert-dialogue";
import SPASwitch from "../../../../../../../common/spa-switch";
import SPATextField from "../../../../../../../common/spa-textField";
import FormProvider from "../../../../../../../hook-form/FormProvider";
import SPAInputField from "../../../../../../../hook-form/SPAInputField";
import SPAMobileDatePicker from "../../../../../../../hook-form/SPAMobileDatePicker";
import SPASelectField from "../../../../../../../hook-form/SPASelectField";
import AddContent from "./add-content";
import { DisabledButton, EnabledButton, SubmitBtn } from "./promoCodeStyle";
import {
  appliesToFormData,
  usagePromoCodeFormData,
} from "./promocode-form-data";
import usePromoCode from "./usePromoCode";

export default function PromoCodeSection({
  singleServiceData,
  singleServiceRefetching,
}) {
  const {
    methods,
    handleSubmit,
    onSubmit,
    control,
    activePromoCodeTab,
    handleTabSelection,
    promoCodeData,
    singlePromoCodeId,
    handleCreatePromoCode,
    handleSinglePromoCode,
    promoCodeDataLoader,
    singlePromoCodeLoader,
    setSearchPromoCode,
    toggleSwitch,
    enabled,
    couponNameValue,
    setIsOpenDisableConfirmDialog,
    isOpenDisableConfirmDialog,
    handleDisableConfirm,

    discountTypeValue,
    setDiscountTypeValue,

    contentTypeValue,
    setContentTypeValue,
    isOpenAddContentDialog,
    setIsOpenAddContentDialog,
    setAddContentData,
    singleContentId,

    appliesToTabValue,
    setAppliesToTabValue,

    selectedValueCounter,
    handleCheckboxChange,
    checkedItems,
    singleTreatmentPromoCode,
    singleProductPromoCode
  } = usePromoCode({
    singleServiceData,
    singleServiceRefetching,
  });

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  height: "48px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleCreatePromoCode}
              >
                <Plus size={24} color="currentColor" />
                Create Promo Code
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              <SPATextField
                placeholder="Search"
                sx={{
                  width: { md: "328px", xs: "180px" },
                  "& .MuiOutlinedInput-root": {
                    height: "48px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} color={"#C1C2C3"} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    ></InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchPromoCode(event?.target?.value);
                }}
              />

              <Box sx={styles.searchbox}>
                <FilterList sx={{ color: secondary.main }} />
              </Box>
            </Box>
          </Box>
        </Box>

        {(promoCodeDataLoader || singlePromoCodeLoader) && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={promoCodeDataLoader || singlePromoCodeLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Grid
          container
          columnGap={3}
          sx={{
            gap: "16px",
          }}
        >
          <Grid item xs={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                bgcolor: "#EDECFC",
                borderRadius: "8px",
                p: "4px",
              }}
            >
              {[
                {
                  value: "ALL",
                },
                {
                  value: "ENABLED",
                },
                {
                  value: "DISABLED",
                },
              ]?.map((item) => {
                return (
                  <Box
                    key={item?.value}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4px",
                      bgcolor:
                        activePromoCodeTab === item.value ? "#4E45E5" : "",
                      borderRadius: "8px",
                      p: "6.7px 16px 6.5px 16px",
                      width: "33%",
                      cursor: "pointer",
                    }}
                    onClick={() => handleTabSelection(item.value)}
                  >
                    <Typography
                      sx={{
                        color:
                          activePromoCodeTab === item.value
                            ? "#FFFFFF"
                            : "#4E45E5",
                        fontSize: "14px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                maxHeight: "66vh",
                overflowY: "auto",
                mb: 2,
                mt: 2,
              }}
            >
              {singleTreatmentPromoCode?.map((item) => {
                return (
                  <Box
                    key={item?._id}
                    sx={{
                      borderRadius: "16px",
                      bgcolor:
                        singlePromoCodeId === item?._id ? "#CAC7F7" : "#F8F7FE",
                      padding: "16px",
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSinglePromoCode(item)}
                  >
                    {item?.couponStatus && (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color:
                            item?.couponStatus === "ENABLED"
                              ? "#1AA37A"
                              : "#25272B",
                          bgcolor:
                            item?.couponStatus === "ENABLED"
                              ? "#E9FBF6"
                              : "#FAFAFA",
                          borderRadius: "4px",
                          p: "4px",
                          width: "fit-content",
                        }}
                      >
                        {item?.couponStatus}
                      </Typography>
                    )}
                    <Typography sx={{ fontSize: "20px", color: "#4A4C4F" }}>
                      {item?.name}
                    </Typography>
                  </Box>
                );
              })}
              {singleTreatmentPromoCode?.length === 0 && (
                <Box
                  sx={{
                    mt: "18px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "24px",
                  }}
                >
                  <NoNotifications />
                  <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                    No Promo Code Yet
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                    After creating Promo Code, they will be displayed here as
                    Promo Code.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              borderRadius: "16px",
              bgcolor: "#F8F7FE",
              flexGrow: "1 !important",
              maxWidth: "100% !important",
            }}
          >
            <Box
              sx={{
                p: "24px",
              }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {singlePromoCodeId && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      mb: "16px",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {enabled && (
                        <Typography
                          sx={{
                            fontSize: "10px",
                            // color: "#25272B",
                            color: enabled ? "#1AA37A" : "#25272B",
                            bgcolor: enabled ? "#E9FBF6" : "#FAFAFA",
                            width: "fit-content",
                            p: "4px",
                            borderRadius: "4px",
                          }}
                        >
                          {enabled === true
                            ? "ENABLED"
                            : enabled === false
                            ? "DISABLED"
                            : ""}
                        </Typography>
                      )}
                      <Typography sx={{ fontSize: "24px", color: "#5E6062" }}>
                        {couponNameValue}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px",
                        backgroundColor: "#EDECFC",
                        borderRadius: "8px  ",
                      }}
                    >
                      <EnabledButton
                        active={enabled === true ? true : null}
                        onClick={toggleSwitch}
                      >
                        Enabled
                      </EnabledButton>
                      <DisabledButton
                        active={enabled === false ? true : null}
                        onClick={toggleSwitch}
                      >
                        Disabled
                      </DisabledButton>
                    </Box>
                  </Box>
                )}
                <Grid container sx={{ display: "flex", gap: "24px" }}>
                  <Grid
                    item
                    lg={5.5}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                        mb: "24px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Promo Code
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item lg={12} xs={12}>
                          <SPAInputField
                            fullWidth={true}
                            control={control}
                            name="name"
                            label="Name"
                            placeholder=""
                          />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <SPAInputField
                            fullWidth={true}
                            control={control}
                            name="coupon"
                            label="Promo Code"
                            placeholder="i.e SPA2024"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                        mb: "24px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Discount Details
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item lg={12} xs={12}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#5E6062" }}
                          >
                            Discount Type
                          </Typography>
                          <Box
                            sx={{
                              bgcolor: "#F8F7FE",
                              borderRadius: "8px",
                              my: "4px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                                bgcolor: "#EDECFC",
                                borderRadius: "8px",
                                p: "4px",
                              }}
                            >
                              {[
                                {
                                  value: "PERCENTAGE",
                                  label: "PERCENTAGE",
                                  icon: Percent,
                                },
                                {
                                  value: "DOLLAR",
                                  label: "FIXED",
                                  icon: CurrencyDollar,
                                },
                              ]?.map((item) => {
                                return (
                                  <Box
                                    key={item?.value}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "4px",
                                      bgcolor:
                                        discountTypeValue === item.value
                                          ? "#4E45E5"
                                          : "",
                                      borderRadius: "8px",
                                      p: "6.7px 16px 6.5px 16px",
                                      width: "50%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setDiscountTypeValue(item.value)
                                    }
                                  >
                                    <item.icon
                                      size={24}
                                      color={
                                        discountTypeValue === item.value
                                          ? "#FFFFFF"
                                          : "#4E45E5"
                                      }
                                    />
                                    <Typography
                                      sx={{
                                        color:
                                          discountTypeValue === item.value
                                            ? "#FFFFFF"
                                            : "#4E45E5",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      {item.label}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                            <Box sx={{ p: "16px" }}>
                              <SPAInputField
                                fullWidth={true}
                                control={control}
                                name="discount"
                                label="Percentage Amount"
                                placeholder="i.e 40"
                                // labelHelperText="(Optional)"
                                suffixIcon={
                                  discountTypeValue === "PERCENTAGE" ? (
                                    <Percent />
                                  ) : (
                                    <CurrencyDollar />
                                  )
                                }
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <SPASelectField
                            fullWidth={true}
                            control={control}
                            name="tax"
                            label="Tax Rate"
                            placeholder="Select Tax Rate"
                            options={[{ label: "GST(Vend)", value: "VEND" }]}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                        mb: "24px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Applies To
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          lg={12}
                          xs={12}
                          sx={{
                            maxHeight: "40vh",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          {appliesToFormData()?.map((item) => {
                            return (
                              <Grid
                                item
                                lg={12}
                                xs={12}
                                key={item.id}
                                sx={{
                                  bgcolor: "#F8F7FE",
                                  borderRadius: "8px",
                                  p: "8px",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "14px", color: "#5E6062" }}
                                >
                                  <Checkbox
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#4E45E5",
                                      },
                                    }}
                                    checked={checkedItems[item.type] || false}
                                    onChange={(e) => {
                                      handleCheckboxChange(e, item.type);
                                    }}
                                  />{" "}
                                  {item.label}
                                </Typography>
                                {checkedItems[item.type] === true && (
                                  <Box
                                    sx={{
                                      bgcolor: "#FFFFFF",
                                      borderRadius: "8px",
                                      my: "4px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                        bgcolor: "#EDECFC",
                                        borderRadius: "8px",
                                        p: "4px",
                                      }}
                                    >
                                      {item.options?.map((subItem) => {
                                        return (
                                          <Box
                                            key={subItem?.value}
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              gap: "4px",
                                              bgcolor:
                                                appliesToTabValue[
                                                  item?.type
                                                ] === subItem.value
                                                  ? "#4E45E5"
                                                  : "",
                                              borderRadius: "8px",
                                              p: "6.7px 16px 6.5px 16px",
                                              width: "50%",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              setAppliesToTabValue(
                                                (prevState) => ({
                                                  ...prevState,
                                                  [item.type]: subItem.value,
                                                })
                                              )
                                            }
                                          >
                                            <Typography
                                              sx={{
                                                color:
                                                  appliesToTabValue[
                                                    item?.type
                                                  ] === subItem.value
                                                    ? "#FFFFFF"
                                                    : "#4E45E5",
                                                fontSize: "14px",
                                                fontWeight: "600",
                                                textTransform: "uppercase",
                                              }}
                                            >
                                              {subItem.label}
                                            </Typography>
                                          </Box>
                                        );
                                      })}
                                    </Box>
                                    {appliesToTabValue[item.type] ===
                                      "SELECTED" && (
                                      <Box
                                        sx={{
                                          p: "16px",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: "#4A4C4F",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {
                                            selectedValueCounter(item?.label)
                                              ?.data?.length
                                          }{" "}
                                          {item?.label} selected
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                            mt: "8px",
                                          }}
                                        >
                                          <Button
                                            sx={{
                                              backgroundColor: "#4E45E5",
                                              color: "#FFFFFF",
                                              p: "6.5px 16px 6.5px 16px",
                                              borderRadius: "8px",
                                              fontWeight: 500,
                                              textTransform: "uppercase",
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "8px",
                                              "&:hover": {
                                                backgroundColor: "#4E45E5",
                                              },
                                            }}
                                            onClick={() => {
                                              setContentTypeValue(item?.label);
                                              setIsOpenAddContentDialog(true);
                                            }}
                                          >
                                            {selectedValueCounter(item?.label)
                                              ?.data?.length > 0
                                              ? "Modify"
                                              : "Select"}{" "}
                                            {item?.label}
                                          </Button>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                        mb: "24px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Usage
                      </Typography>
                      <Box>
                        <Grid container spacing={1}>
                          {usagePromoCodeFormData().map((item, index) => {
                            return (
                              <Grid
                                item
                                lg={item.gridLength}
                                xs={12}
                                key={index}
                              >
                                <item.component
                                  {...item.otherOptions}
                                  control={control}
                                />
                              </Grid>
                            );
                          })}
                          <Grid
                            item
                            lg={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "#5E6062" }}
                            >
                              Combine with Other Offers
                            </Typography>
                            <SPASwitch
                              name={"combineOtherOffer"}
                              control={control}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Active Dates
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            fontSize: "14px",
                            color: "#5E6062",
                          }}
                        >
                          Date range that client can enter code in while booking
                          <Typography sx={{ color: "#D13438", pl: "3px" }}>
                            {" "}
                            *
                          </Typography>
                        </Typography>
                        <Box sx={{ display: "flex", gap: "8px" }}>
                          <Grid item lg={6} xs={12}>
                            <SPAMobileDatePicker
                              name="startDate"
                              label=""
                              disablePast={true}
                              isCalenderShow={true}
                              helperText="(Optional)"
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <SPAMobileDatePicker
                              name="endDate"
                              label=""
                              disablePast={true}
                              isCalenderShow={true}
                              helperText="(Optional)"
                            />
                          </Grid>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            fontSize: "14px",
                            color: "#5E6062",
                          }}
                        >
                          Date range that appointment must be in to be eligible
                          <Typography sx={{ color: "#D13438", pl: "3px" }}>
                            {" "}
                            *
                          </Typography>
                        </Typography>
                        <Box sx={{ display: "flex", gap: "8px" }}>
                          <Grid item lg={6} xs={12}>
                            <SPAMobileDatePicker
                              name="validForAppointmentStartDate"
                              label=""
                              disablePast={true}
                              isCalenderShow={true}
                              helperText="(Optional)"
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <SPAMobileDatePicker
                              name="validForAppointmentEndDate"
                              label=""
                              disablePast={true}
                              isCalenderShow={true}
                              helperText="(Optional)"
                            />
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    p: "24px 0px 12px 0px",
                  }}
                >
                  <Button type="submit" sx={SubmitBtn}>
                    <Ticket size={24} />
                    {singlePromoCodeId ? "Save Changes" : "Create Promo Code"}
                  </Button>
                </Box>
              </FormProvider>
            </Box>
          </Grid>
        </Grid>
        {isOpenAddContentDialog && (
          <AddContent
            setIsOpenSuccessDialog={() => {}}
            singleQualificationId={singleContentId}
            isAddQualificationOpen={isOpenAddContentDialog}
            setAddQualificationData={setAddContentData}
            initialData={selectedValueCounter(contentTypeValue)?.data}
            handleClose={(data) => {
              setIsOpenAddContentDialog(false);
            }}
            dialogTitle={`Add ${contentTypeValue}`}
            contentTitle={`${contentTypeValue ?? "Treatments"}`}
            subContentDescription={`Please select the ${contentTypeValue} you want to add this promo code to.`}
            type={contentTypeValue ?? "Products"}
          />
        )}
        {/* {isOpenNotDisableDialog && (
          <SPAAlertDialog
            open={isOpenNotDisableDialog}
            handleClose={() => setIsOpenNotDisableConfirmDialog(false)}
            handleNewTab={() => setIsOpenNotDisableConfirmDialog(false)}
            title={"Can’t Disable This Qualification"}
            contentText={`You cannot disable this Qualification because there are pending appointments, discount codes, articles, bundles, or deals associated with it. Please cancel those pending actions before proceeding with the disabling of the Product.`}
            disagreeText={`Go To The Product`}
            agreeText={`Cancel`}
            type="error"
          />
        )} */}
        {isOpenDisableConfirmDialog && (
          <SPAAlertDialog
            open={isOpenDisableConfirmDialog}
            handleClose={() => setIsOpenDisableConfirmDialog(false)}
            handleNewTab={handleDisableConfirm}
            title={"Promo Code Disabling Confirmation"}
            contentText={`Clients will no longer be able to interact with this Promo Code. This means they won't be able to: view it, book an appointment, add it to favorites, or share it. Are you sure you want to proceed with the removal?`}
            disagreeText={`Disable The Promo Code`}
            agreeText={`Cancel`}
            type="error"
          />
        )}
      </Box>
    </>
  );
}

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
