import { baseAPI } from "../baseApi";

export const SalesApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllSales: builder.query({
      query: () => ({ url: "/sale" }),
      providesTags: ["Sales"],
    }),
    getSales: builder.mutation({
      query: (body) => ({ url: "/sale", method: "PATCH", body }),
      providesTags: ["Sales"],
    }),
    createSale: builder.mutation({
      query: (payload) => ({
        url: "/sale",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Sales"],
    }),
    getSingleSale: builder.query({
      query: (salesId) => ({ url: "/sale/" + salesId }),
      providesTags: ["singleSale"],
    }),
    updateSingleSale: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/sale/" + id,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Sales"],
    }),
  }),
});

export const {
  useGetAllSalesQuery,
  useGetSingleSaleQuery,
  useGetSalesMutation,
  useCreateSaleMutation,
  useUpdateSingleSaleMutation,
} = SalesApi;
