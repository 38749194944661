import { ArrowForward } from "@mui/icons-material";
import {
  CalendarBlank,
  CallBell,
  Certificate,
  Clock,
  Door,
  HandHeart,
  MapPin,
  Phone,
  User,
  UserFocus,
  Warning,
} from "@phosphor-icons/react";
import { customColors, secondary } from "../../../../../../theme";
import SPACheckBox from "../../../../../hook-form/SPACheckBox";
import SPAInputField from "../../../../../hook-form/SPAInputField";
import SPAMobileDatePicker from "../../../../../hook-form/SPAMobileDatePicker";
import SPASelectField from "../../../../../hook-form/SPASelectField";

export const StaffProfileNavBarData = [
  {
    label: "Overview",
    icon: UserFocus,
    link: "Overview",
  },
  {
    label: "Address",
    icon: MapPin,
    link: "Address",
  },
  {
    label: "Qualification",
    icon: Certificate,
    link: "Qualification",
  },
  {
    label: "Appointments",
    icon: Clock,
    link: "Appointment",
  },
  {
    label: "Roster",
    icon: CalendarBlank,
    link: "Roster",
  },
  {
    label: "Emergency Contacts",
    icon: Phone,
    link: "Emergency",
  },
];

export const OverviewElements = (rolesData) => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "firstName",
        label: "First Name",
        type: "text",
        placeholder: "First Name",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "lastName",
        label: "Last Name",
        type: "text",
        placeholder: "Last Name",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "role",
        label: "Role",
        options:
          rolesData &&
          rolesData?.map((item) => {
            return { id: item._id, label: item.name, value: item.name };
          }),
        placeholder: "Select Role",
        disabled: true,
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "gender",
        label: "Gender",
        labelHelperText: " (Optional)",
        options: [
          { value: "MALE", label: "Male" },
          { value: "FEMALE", label: "Female" },
        ],
        placeholder: "Enter gender",
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "mobileNumber",
        label: "Mobile Number",
        type: "text",
        placeholder: "+61 412 345 678",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "status",
        label: "Status",
        options: [
          { value: "ACTIVE", label: "Active" },
          { value: "INACTIVE", label: "Inactive" },
          { value: "DRAFT", label: "Draft" },
          { value: "ON_LEAVE", label: "On Leave" },
          { value: "ARCHIVED", label: "Archived" },
        ],
        placeholder: "Select Status",
      },
      component: SPASelectField,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "email",
        label: "Email",
        type: "email",
        placeholder: "Enter email",
        disabled: true,
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "dateOfBirth",
        label: "Date of Birth",
        placeholder: "Date of Birth",
        helperText: "(Optional)",
        isCalenderShow: true,
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "employmentStartDate",
        label: "Start Date",
        placeholder: "Start Date",
        helperText: "(Optional)",
        isCalenderShow: true,
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "currentEmployment",
        label: "Currently Employed",
        helperText: "(Optional)",
      },
      component: SPACheckBox,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "employmentEndDate",
        label: "End Date",
        placeholder: "End Date",
        helperText: "(Optional)",
        fullWidth: true,
        isCalenderShow: true,
      },
      component: SPAMobileDatePicker,
    },
    // {
    //   gridLength: 6,
    //   otherOptions: {
    //     name: "employmentEndDate",
    //     label: "End Date",
    //     placeholder: "End Date",
    //     helperText: "(Optional)",
    //   },
    //   component: SPAMobileDatePicker,
    // },
    {
      gridLength: 12,
      otherOptions: {
        name: "note",
        label: "Additional Notes",
        placeholder: "Add additional notes here",
        type: "textarea",
        rows: 3,
        labelHelperText: " (Optional)",
      },
      component: SPAInputField,
    },
  ];
};

export const addressElements = (addNewAddress, addressesArray) => {
  const newComponent = {
    id: addNewAddress,
    componentArray: [
      {
        gridLength: 6,
        otherOptions: {
          fullWidth: true,
          name: "state",
          label: "State",
          // labelHelperText: " (Optional)",
          placeholder: "",
        },
        component: SPAInputField,
      },
      {
        gridLength: 3,
        otherOptions: {
          name: "postalCode",
          label: "Post Code",
          placeholder: "",
        },
        component: SPAInputField,
      },
      {
        gridLength: 8.9,
        otherOptions: {
          name: "street",
          label: "Street",
          // labelHelperText: " (Optional)",
          placeholder: "",
        },
        component: SPAInputField,
      },
    ],
  };

  return addNewAddress > addressesArray.length
    ? [...addressesArray, newComponent]
    : addressesArray;
};

export const addressFormData = () => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        name: "street",
        label: "Street",
        placeholder: "",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "suburb",
        label: "Suburb",
        placeholder: "",
        // labelHelperText: "(Optional)",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "state",
        label: "State",
        // labelHelperText: " (Optional)",
        placeholder: "",
        options: [
          {
            label: "New South Wales (NSW)",
            value: "New South Wales (NSW)",
          },
          {
            label: "Queensland (QLD)",
            value: "Queensland (QLD)",
          },
          {
            label: "South Australia (SA)",
            value: "South Australia (SA)",
          },
          {
            label: "Tasmania (TAS)",
            value: "Tasmania (TAS)",
          },
          {
            label: "Victoria (VIC)",
            value: "Victoria (VIC)",
          },
          {
            label: "Western Australia (WA)",
            value: "Western Australia (WA)",
          },
          {
            label: "Australian Capital Territory (ACT)",
            value: "Australian Capital Territory (ACT)",
          },
          {
            label: "Northern Territory (NT)",
            value: "Northern Territory (NT)",
          },
        ],
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "postalCode",
        label: "Post Code",
        placeholder: "",
      },
      component: SPAInputField,
    },
  ];
};

export const appointmentSummaryElements = () => {
  return [
    {
      title: "Client",
      icon: User,
      details: {
        img: "",
        content: { text: "Jane Doe", color: secondary.main },
      },
    },
    {
      title: "Staff",
      icon: HandHeart,
      details: {
        img: "",
        content: { text: "Noah Levi", color: customColors.lightBlack },
      },
    },
    {
      title: "Service",
      icon: CallBell,
      details: {
        content: { text: "Radiance Vitamin", color: customColors.lightBlack },
      },
    },
    {
      title: "Room",
      icon: Door,
      details: {
        content: { text: "Endermologie", color: customColors.lightBlack },
      },
    },
    {
      title: "Date",
      icon: CalendarBlank,
      details: {
        content: { text: "12 Feb, 2024", color: customColors.lightBlack },
      },
    },
    {
      title: "Time",
      icon: Clock,
      details: {
        icon: ArrowForward,
        content: {
          startText: `9:00 am`,
          endText: `10:00 am`,
          color: customColors.lightBlack,
        },
        difference: "(1 hour)",
        differenceColor: "#8F9193",
      },
    },
  ];
};
