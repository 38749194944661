import React from "react";

const NoTemplates = () => {
  return (
    <svg
      width="280"
      height="281"
      viewBox="0 0 280 281"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M214.459 109.29C214.459 109.29 189.42 131.268 218.251 178.33C243.855 220.126 186.304 238.243 161.504 233.392C129.378 227.107 110.933 186.502 86.3648 199.368C61.7967 212.234 37.458 153.932 73.1946 132.478C117.649 105.79 89.1189 90.2818 93.1115 70.7137C95.9772 56.6699 136.756 34.9568 158.744 59.7249C177.246 80.5669 186.32 73.1105 194.777 70.3282C206.974 66.3152 233.597 87.1888 214.459 109.29Z"
        fill="#CAC7F7"
      />
      <path
        d="M143.565 134.056L135.362 167.705C135.057 168.953 135.002 170.248 135.198 171.518C135.394 172.787 135.839 174.005 136.506 175.103C137.173 176.2 138.05 177.156 139.087 177.914C140.123 178.673 141.299 179.22 142.547 179.523L183.346 189.47C184.395 200.988 193.927 201.839 197.688 202.234C197.725 202.236 197.761 202.226 197.792 202.205C197.822 202.184 197.845 202.153 197.857 202.118C197.868 202.083 197.868 202.044 197.855 202.01C197.843 201.975 197.819 201.945 197.788 201.925C195.087 200.279 194.281 195.766 194.101 192.092L197.13 192.83C198.378 193.135 199.673 193.19 200.943 192.994C202.212 192.798 203.43 192.353 204.528 191.686C205.625 191.019 206.581 190.142 207.339 189.105C208.098 188.069 208.644 186.893 208.948 185.645L217.151 151.996C217.456 150.748 217.512 149.453 217.315 148.183C217.119 146.914 216.675 145.696 216.007 144.598C215.34 143.501 214.463 142.545 213.427 141.787C212.39 141.028 211.214 140.482 209.966 140.178L155.384 126.871C154.136 126.566 152.84 126.511 151.571 126.707C150.301 126.903 149.083 127.348 147.986 128.015C146.888 128.682 145.933 129.559 145.174 130.596C144.416 131.633 143.869 132.808 143.565 134.056Z"
        fill="#716AEA"
      />
      <path
        d="M211.241 150.656L188.427 145.094C188.277 145.058 188.126 145.15 188.089 145.3L186.919 150.101C186.882 150.251 186.974 150.402 187.124 150.439L209.938 156.001C210.088 156.037 210.24 155.945 210.276 155.795L211.447 150.994C211.483 150.844 211.391 150.693 211.241 150.656Z"
        fill="white"
      />
      <path
        d="M207.984 164.018L146.171 148.949C146.021 148.912 145.87 149.004 145.833 149.154L144.663 153.955C144.626 154.105 144.718 154.257 144.868 154.293L206.681 169.363C206.831 169.399 206.982 169.307 207.019 169.157L208.189 164.356C208.226 164.206 208.134 164.055 207.984 164.018Z"
        fill="#A7A2F2"
      />
      <path
        d="M204.726 177.38L142.914 162.311C142.763 162.274 142.612 162.366 142.575 162.516L141.405 167.317C141.368 167.467 141.46 167.619 141.611 167.655L203.423 182.725C203.573 182.761 203.724 182.669 203.761 182.519L204.931 177.718C204.968 177.568 204.876 177.417 204.726 177.38Z"
        fill="#A7A2F2"
      />
      <path
        d="M166.536 93.6922L179.95 136.77C180.448 138.368 180.627 140.047 180.475 141.714C180.324 143.38 179.845 145 179.067 146.482C178.289 147.963 177.227 149.277 175.941 150.348C174.656 151.419 173.172 152.226 171.574 152.723L119.343 168.987C118.92 184.047 106.597 185.933 101.74 186.753C101.692 186.759 101.643 186.749 101.601 186.725C101.559 186.7 101.527 186.662 101.509 186.617C101.491 186.572 101.489 186.522 101.502 186.475C101.516 186.429 101.545 186.388 101.584 186.36C104.961 183.999 105.64 178.066 105.574 173.274L101.696 174.482C100.099 174.979 98.4192 175.158 96.7529 175.006C95.0866 174.855 93.4665 174.376 91.9851 173.598C90.5038 172.821 89.1902 171.758 88.1194 170.473C87.0486 169.187 86.2416 167.703 85.7445 166.106L72.33 123.028C71.832 121.43 71.6537 119.75 71.8053 118.084C71.9568 116.417 72.4351 114.797 73.213 113.316C73.9909 111.834 75.053 110.521 76.3388 109.45C77.6245 108.379 79.1086 107.572 80.7063 107.075L150.583 85.3158C152.181 84.8179 153.861 84.6396 155.527 84.7911C157.193 84.9426 158.814 85.421 160.295 86.1988C161.776 86.9767 163.09 88.0389 164.161 89.3246C165.232 90.6103 166.039 92.0944 166.536 93.6922Z"
        fill="#3730A0"
      />
      <path
        d="M117.186 109.121L79.7664 120.758C79.6187 120.804 79.5363 120.961 79.5822 121.108L81.5443 127.418C81.5903 127.565 81.7472 127.648 81.8949 127.602L119.314 115.965C119.462 115.919 119.544 115.762 119.498 115.614L117.536 109.305C117.49 109.157 117.333 109.075 117.186 109.121Z"
        fill="white"
      />
      <path
        d="M164.407 113.119L85.113 137.812C84.9654 137.858 84.883 138.015 84.929 138.163L86.8933 144.471C86.9392 144.618 87.0962 144.701 87.2439 144.655L166.538 119.962C166.686 119.916 166.768 119.759 166.722 119.611L164.758 113.304C164.712 113.156 164.555 113.073 164.407 113.119Z"
        fill="#A7A2F2"
      />
      <path
        d="M169.727 130.314L90.4139 154.979C90.2662 155.025 90.1837 155.182 90.2296 155.33L92.1918 161.639C92.2377 161.787 92.3947 161.869 92.5424 161.823L171.855 137.158C172.003 137.112 172.085 136.955 172.04 136.807L170.077 130.498C170.031 130.35 169.874 130.268 169.727 130.314Z"
        fill="#A7A2F2"
      />
    </svg>
  );
};

export default NoTemplates;
