import { FilterList } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  Archive,
  CurrencyDollar,
  MagnifyingGlass,
  Percent,
  Plus,
  Trash,
} from "@phosphor-icons/react";
import React from "react";
import NoNotifications from "../../../../../../../../assets/svg/no-notifications";
import NoPackage from "../../../../../../../../assets/svg/no-package";
import { secondary } from "../../../../../../../../theme";
import { truncateFullName } from "../../../../../../../../utils/truncateText";
import SPAAlertDialog from "../../../../../../../common/spa-alert-dialogue";
import SPATextField from "../../../../../../../common/spa-textField";
import FormProvider from "../../../../../../../hook-form/FormProvider";
import SPAInputField from "../../../../../../../hook-form/SPAInputField";
import SPASelectField from "../../../../../../../hook-form/SPASelectField";
import AddContent from "./add-content";
import { packageFormData } from "./package-form-data";
import { DisabledButton, EnabledButton, SubmitBtn } from "./packageStyle";
import usePackage from "./usePackage";

export default function PackageSection({
  singleServiceData,
  singleServiceRefetching,
}) {
  const {
    methods,
    handleSubmit,
    onSubmit,
    control,
    activePackageTab,
    handleTabSelection,
    packagesData,
    singlePackageId,
    handleCreatePackage,
    handleSinglePackage,
    productTypeData,
    packagesDataLoader,
    singlePackageLoader,
    setSearchPackage,
    toggleSwitch,
    enabled,
    packageNameValue,
    setIsOpenDisableConfirmDialog,
    isOpenDisableConfirmDialog,
    handleDisableConfirm,

    discountTypeValue,
    setDiscountTypeValue,

    contentTypeValue,
    setContentTypeValue,
    isOpenAddContentDialog,
    setIsOpenAddContentDialog,
    setAddContentData,
    singleContentId,
    selectedServicesData,
    selectedProductsData,
    removeSelectedProduct,
    removeSelectedService,
    singleTreatmentPackage,
  } = usePackage({ singleServiceData, singleServiceRefetching });

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  height: "48px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleCreatePackage}
              >
                <Plus size={24} color="currentColor" />
                Create Package
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              <SPATextField
                placeholder="Search"
                sx={{
                  width: { md: "328px", xs: "180px" },
                  "& .MuiOutlinedInput-root": {
                    height: "48px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} color={"#C1C2C3"} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    ></InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchPackage(event?.target?.value);
                }}
              />

              <Box sx={styles.searchbox}>
                <FilterList sx={{ color: secondary.main }} />
              </Box>
            </Box>
          </Box>
        </Box>

        {(packagesDataLoader || singlePackageLoader) && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={packagesDataLoader || singlePackageLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Grid
          container
          columnGap={3}
          sx={{
            // height: "75vh",
            gap: "16px",
          }}
        >
          <Grid item xs={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                bgcolor: "#EDECFC",
                borderRadius: "8px",
                p: "4px",
              }}
            >
              {[
                {
                  value: "ALL",
                },
                {
                  value: "ENABLED",
                },
                {
                  value: "DISABLED",
                },
              ]?.map((item) => {
                return (
                  <Box
                    key={item?.value}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4px",
                      bgcolor: activePackageTab === item.value ? "#4E45E5" : "",
                      borderRadius: "8px",
                      p: "6.7px 16px 6.5px 16px",
                      width: "33%",
                      cursor: "pointer",
                    }}
                    onClick={() => handleTabSelection(item.value)}
                  >
                    <Typography
                      sx={{
                        color:
                          activePackageTab === item.value
                            ? "#FFFFFF"
                            : "#4E45E5",
                        fontSize: "14px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                maxHeight: "66vh",
                overflowY: "auto",
                mb: 2,
                mt: "8px",
              }}
            >
              {singleTreatmentPackage?.map((item) => {
                return (
                  <Box
                    key={item?._id}
                    sx={{
                      borderRadius: "16px",
                      bgcolor:
                        singlePackageId === item?._id ? "#CAC7F7" : "#F8F7FE",
                      padding: "16px",
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSinglePackage(item)}
                  >
                    {item?.isEnablePackage && (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color:
                            item?.isEnablePackage === "ENABLED"
                              ? "#1AA37A"
                              : "#25272B",
                          bgcolor:
                            item?.isEnablePackage === "ENABLED"
                              ? "#E9FBF6"
                              : "#FAFAFA",
                          borderRadius: "4px",
                          p: "4px",
                          width: "fit-content",
                        }}
                      >
                        {item?.isEnablePackage}
                      </Typography>
                    )}
                    <Typography sx={{ fontSize: "20px", color: "#4A4C4F" }}>
                      {item?.name}
                    </Typography>
                  </Box>
                );
              })}
              {singleTreatmentPackage?.length === 0 && (
                <Box
                  sx={{
                    mt: "18px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "24px",
                  }}
                >
                  <NoNotifications />
                  <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                    No Package Yet
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                    After creating Package, they will be displayed here as
                    Package.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              borderRadius: "16px",
              bgcolor: "#F8F7FE",
              flexGrow: "1 !important",
              maxWidth: "100% !important",
            }}
          >
            <Box
              sx={{
                p: "24px",
              }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {singlePackageId && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      mb: "16px",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontSize: "10px",
                          // color: "#25272B",
                          color: enabled ? "#1AA37A" : "#25272B",
                          bgcolor: enabled ? "#E9FBF6" : "#FAFAFA",
                          width: "fit-content",
                          p: "4px",
                          borderRadius: "4px",
                        }}
                      >
                        {enabled === true
                          ? "ENABLED"
                          : enabled === false
                          ? "DISABLED"
                          : ""}
                      </Typography>
                      <Typography sx={{ fontSize: "24px", color: "#5E6062" }}>
                        {packageNameValue}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px",
                        backgroundColor: "#EDECFC",
                        borderRadius: "8px  ",
                      }}
                    >
                      <EnabledButton
                        active={enabled === true ? true : null}
                        onClick={toggleSwitch}
                      >
                        Enabled
                      </EnabledButton>
                      <DisabledButton
                        active={enabled === false ? true : null}
                        onClick={toggleSwitch}
                      >
                        Disabled
                      </DisabledButton>
                    </Box>
                  </Box>
                )}
                <Grid
                  container
                  sx={{ display: "flex", gap: "24px", flexDirection: "column" }}
                >
                  <Grid
                    item
                    lg={12}
                    sx={{
                      display: "flex",
                      gap: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Package Information
                      </Typography>
                      <Grid container spacing={1}>
                        {packageFormData(
                          productTypeData?.result?.data?.data
                        ).map((item, index) => {
                          return (
                            <Grid item lg={item.gridLength} xs={12} key={index}>
                              <item.component
                                {...item.otherOptions}
                                control={control}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Package Price
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item lg={12} xs={12}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#5E6062" }}
                          >
                            Discount Type
                          </Typography>
                          <Box
                            sx={{
                              bgcolor: "#F8F7FE",
                              borderRadius: "8px",
                              my: "4px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                                bgcolor: "#EDECFC",
                                borderRadius: "8px",
                                p: "4px",
                              }}
                            >
                              {[
                                {
                                  value: "PERCENTAGE",
                                  label: "PERCENTAGE",
                                  icon: Percent,
                                },
                                {
                                  value: "DOLLAR",
                                  label: "FIXED",
                                  icon: CurrencyDollar,
                                },
                              ]?.map((item) => {
                                return (
                                  <Box
                                    key={item?.value}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "4px",
                                      bgcolor:
                                        discountTypeValue === item.value
                                          ? "#4E45E5"
                                          : "",
                                      borderRadius: "8px",
                                      p: "6.7px 16px 6.5px 16px",
                                      width: "50%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setDiscountTypeValue(item.value)
                                    }
                                  >
                                    <item.icon
                                      size={24}
                                      color={
                                        discountTypeValue === item.value
                                          ? "#FFFFFF"
                                          : "#4E45E5"
                                      }
                                    />
                                    <Typography
                                      sx={{
                                        color:
                                          discountTypeValue === item.value
                                            ? "#FFFFFF"
                                            : "#4E45E5",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      {item.label}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                            <Box sx={{ p: "16px" }}>
                              <SPAInputField
                                fullWidth={true}
                                control={control}
                                name="percentageAmount"
                                label="Percentage Amount"
                                placeholder="i.e 40"
                                labelHelperText="(Optional)"
                                suffixIcon={
                                  discountTypeValue === "PERCENTAGE" ? (
                                    <Percent />
                                  ) : (
                                    <CurrencyDollar />
                                  )
                                }
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <SPASelectField
                            fullWidth={true}
                            control={control}
                            name="tax"
                            label="Tax Rate"
                            placeholder="Select Tax Rate"
                            labelHelperText="(Optional)"
                            options={[{ label: "GST(Vend)", value: "VEND" }]}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item lg={12}>
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Package Content
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: { lg: "16px", md: "8px" },
                        }}
                      >
                        {[
                          { value: "Product", label: "Add Product" },
                          { value: "Treatment", label: "Add Treatment" },
                          { value: "Treatment", label: "Add Gift Card" },
                        ]?.map((item) => {
                          return (
                            <Button
                              key={item.value}
                              disableElevation
                              startIcon={<Plus />}
                              variant="contained"
                              sx={{
                                fontSize: "12px",
                                bgcolor: "#EDECFC",
                                color: "#4E45E5",
                                fontWeight: "600",
                                borderRadius: "8px",
                                "&:hover": {
                                  bgcolor: "#EDECFC",
                                },
                              }}
                              onClick={() => {
                                if (item.label !== "Add Gift Card") {
                                  setContentTypeValue(item.value);
                                  setIsOpenAddContentDialog(true);
                                }
                              }}
                            >
                              {item?.label}
                            </Button>
                          );
                        })}
                      </Box>
                      <Box
                        sx={{ maxHeight: "67vh", overflowY: "auto", mt: "4px" }}
                      >
                        {[
                          ...selectedProductsData,
                          ...selectedServicesData,
                        ]?.map((item) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                                p: "16px 0px",
                                width: "100%",
                              }}
                            >
                              <Box
                                key={item?._id}
                                sx={{
                                  display: "flex",
                                  flexDirection: {
                                    xs: "column",
                                    md: "row",
                                  },
                                  width: "100%",
                                  maxHeight: "100px",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  background: "#EDECFC",
                                  justifyContent: "space-between",
                                  overflow: "hidden",
                                  cursor: "pointer",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                    borderRadius: "8px",
                                    bgcolor: "#FCF5F3",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      maxWidth: "90px",
                                      overflow: "hidden",
                                      objectFit: "cover",
                                    }}
                                  >
                                    <img
                                      src={
                                        item?.imageUrl
                                          ? item?.imageUrl
                                          : item?.profilePictureUrl
                                          ? item?.profilePictureUrl
                                          : "https://qa-temple-day-spa-s3.s3.ap-southeast-2.amazonaws.com/c70675c3-59da-46cb-bfa0-e4fc9800eaf3-power-icon.png"
                                      }
                                      alt="product"
                                    />
                                  </Box>
                                  <Box>
                                    <Box
                                      sx={{
                                        padding: "4px 8px",
                                        background: "#070A0E",
                                        color: "#FAFAFA",
                                        fontSize: "10px",
                                        textTransform: "uppercase",
                                        maxWidth: "fit-content",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {item?.productType
                                        ? item?.productType?.name
                                        : item?.category?.name ?? "---"}
                                    </Box>
                                    <Typography
                                      sx={{
                                        color: "#5E6062",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {item?.name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#5E6062",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        minWidth: "170px",
                                      }}
                                    >
                                      {item?.description &&
                                        truncateFullName(item?.description, 25)}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: {
                                      xs: "row",
                                      md: "column",
                                    },
                                    minWidth: "110px",
                                    paddingInline: "8px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#5E6062",
                                      fontSize: "12px",
                                      fontWeight: 600,
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Custom Price
                                  </Typography>

                                  <SPAInputField
                                    defaultValue={
                                      item?.customPrice
                                        ? item?.customPrice
                                        : item?.price
                                    }
                                    name={`customPrice_${item?._id}`}
                                    placeholder="Enter the Price here"
                                    control={control}
                                    prefixIcon={<CurrencyDollar size={18} />}
                                  />
                                  <Typography
                                    sx={{
                                      color: "#5E6062",
                                      fontSize: "10px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Default price: ${item?.price}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingRight: "8px",
                                  }}
                                  onClick={() => {
                                    if (item?.type === "RETAIL_PRODUCT") {
                                      removeSelectedProduct(item?._id);
                                    } else {
                                      removeSelectedService(item?._id);
                                    }
                                  }}
                                >
                                  <Trash size={16} color="#D13438" />
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>

                      {[...selectedProductsData, ...selectedServicesData]
                        ?.length === 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "16px",
                            height: "40vh",
                          }}
                        >
                          <Box>
                            <NoPackage />
                          </Box>
                          <Typography
                            sx={{
                              color: "#5E6062",
                              textAlign: "center",
                              fontSize: "20px",
                            }}
                          >
                            Start by inserting an element from the bar at the
                            top
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    p: "24px 0px 12px 0px",
                  }}
                >
                  <Button type="submit" sx={SubmitBtn}>
                    <Archive size={24} />
                    {singlePackageId ? "Save Changes" : "Create Package"}
                  </Button>
                </Box>
              </FormProvider>
            </Box>
          </Grid>
        </Grid>
        {isOpenAddContentDialog && (
          <AddContent
            setIsOpenSuccessDialog={() => {}}
            singleQualificationId={singleContentId}
            isAddQualificationOpen={isOpenAddContentDialog}
            setAddQualificationData={setAddContentData}
            initialData={
              contentTypeValue === "Treatment"
                ? selectedServicesData
                : selectedProductsData
            }
            handleClose={(data) => {
              setIsOpenAddContentDialog(false);
            }}
            dialogTitle={`Add ${contentTypeValue}`}
            contentTitle={`${contentTypeValue ?? "Treatment"}s`}
            subContentDescription={`Please select the ${contentTypeValue} you want to add to this package.`}
            type={contentTypeValue ?? "Product"}
          />
        )}
        {/* {isOpenNotDisableDialog && (
          <SPAAlertDialog
            open={isOpenNotDisableDialog}
            handleClose={() => setIsOpenNotDisableConfirmDialog(false)}
            handleNewTab={() => setIsOpenNotDisableConfirmDialog(false)}
            title={"Can’t Disable This Qualification"}
            contentText={`You cannot disable this Qualification because there are pending appointments, discount codes, articles, bundles, or deals associated with it. Please cancel those pending actions before proceeding with the disabling of the Product.`}
            disagreeText={`Go To The Product`}
            agreeText={`Cancel`}
            type="error"
          />
        )} */}
        {isOpenDisableConfirmDialog && (
          <SPAAlertDialog
            open={isOpenDisableConfirmDialog}
            handleClose={() => setIsOpenDisableConfirmDialog(false)}
            handleNewTab={handleDisableConfirm}
            title={"Package Disabling Confirmation"}
            contentText={`Clients will no longer be able to interact with this Package. This means they won't be able to: view it, book an appointment, add it to favorites, or share it. Are you sure you want to proceed with the removal?`}
            disagreeText={`Disable The Package`}
            agreeText={`Cancel`}
            type="error"
          />
        )}
      </Box>
    </>
  );
}

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
