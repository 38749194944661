import { At, Bell, House, Play } from "@phosphor-icons/react";

export const sidebarData = [
  {
    label: "Home",
    icon: House,
    link: "home",
  },
  {
    label: "Notifications",
    icon: Bell,
    link: "notifications",
  },
  {
    label: "Onboardings",
    icon: Play,
    link: "onboarding",
  },
];
