import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useDeleteEmergencyContactMutation,
  useUpdateEmergencyContactMutation,
  useUpdateSingleStaffMutation,
} from "../../../../../../../../services/staff";
import { validationEmergencySchema } from "./emergency-data";

export default function useStaffAddress({
  singleStaffData,
  singleStaffRefetching,
}) {
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(null);
  const [emergencyContactId, setEmergencyContactId] = useState();

  const methods = useForm({
    resolver: yupResolver(validationEmergencySchema),
    defaultValues: {
      emergencyContact: [
        {
          contactFirstName: "",
          contactLastName: "",
          email: "",
          phoneNumber: "",
          relationship: "",
        },
      ],
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "emergencyContact",
  });

  const [updateSingleStaff, { isLoading: updatedLoader }] =
    useUpdateSingleStaffMutation();

  const [deleteEmergencyContact] = useDeleteEmergencyContactMutation();
  const [updateEmergencyContact] = useUpdateEmergencyContactMutation();

  const onSubmit = async (values) => {
    const response = await updateEmergencyContact({
      userId: singleStaffData?.result?.data?.user?._id,
      payload: values,
    });
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    if (response?.data?.status === true) {
      toast.success(
        response?.data?.message ?? "Updated the Emergency Contact Successfully"
      );
    }
    singleStaffRefetching();
  };

  const handleDeleteEmergencyContact = async () => {
    if (emergencyContactId?.contact?._id) {
      let response = await deleteEmergencyContact({
        staffId: singleStaffData?.result?.data?.user?._id,
        emergencyContactId: emergencyContactId?.contact?._id,
      });
      if (response?.data?.status === false) {
        toast.error(response?.data?.message);
        return;
      }
      if (response?.data?.status === true) {
        toast.success(response?.data?.message);
        remove(emergencyContactId?.index);
        singleStaffRefetching();
      }
    } else {
      remove(emergencyContactId?.index);
      toast.success("Emergency Contact Deleted Successfully!");
    }
    setIsOpenDeleteDialog(false);
  };

  const addContact = () => {
    methods.trigger().then((isValid) => {
      if (isValid) {
        insert(0, {
          contactFirstName: "",
          contactLastName: "",
          email: "",
          phoneNumber: "",
          relationship: "",
        });
      }
    });
  };

  useEffect(() => {
    if (singleStaffData) {
      const initialData = singleStaffData?.result?.data?.user?.emergencyContact;
      if (initialData && initialData.length > 0) {
        setValue("emergencyContact", initialData);
      }
    }
  }, [singleStaffData, setValue]);

  return {
    insert,
    control,
    onSubmit,
    fields,
    handleSubmit,
    methods,
    append,
    remove,
    updatedLoader,
    isOpenDeleteDialog,
    setIsOpenDeleteDialog,
    emergencyContactId,
    setEmergencyContactId,
    addContact,
    handleDeleteEmergencyContact,
  };
}
