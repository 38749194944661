import { baseAPI } from "../baseApi";

export const ProductTypeApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProductType: builder.mutation({
      query: (body) => ({ url: "/product-type", method: "PATCH", body }),
      providesTags: ["ProductType"],
    }),
    createProductType: builder.mutation({
      query: (payload) => ({
        url: "/product-type",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["ProductType"],
    }),
    updateProductType: builder.mutation({
      query: ({ id, payload }) => ({
        url: "/product-type/" + id,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["ProductType"],
    }),
    getSingleProductType: builder.query({
      query: (productTypeId) => ({ url: "/product-type/" + productTypeId }),
      providesTags: ["SingleProductType"],
    }),
    deleteProductType: builder.mutation({
      query: (productTypeId) => ({
        url: "/product-type/" + productTypeId,
        method: "DELETE",
      }),
      providesTags: ["ProductType"],
    }),
    addProductTypeInProduct: builder.mutation({
      query: ({ id, payload }) => ({
        url: "/product-type/" + id,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["ProductType"],
    }),
  }),
});

export const {
  useAddProductTypeInProductMutation,
  useGetProductTypeMutation,
  useGetSingleProductTypeQuery,
  useCreateProductTypeMutation,
  useUpdateProductTypeMutation,
  useDeleteProductTypeMutation,
} = ProductTypeApi;
