import { countriesBaseAPI } from "../contriesBaseApi";

export const countriesApi = countriesBaseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllCountries: builder.query ({ query: () => ({ url: 'countries' }),}),
    fetchAllStates: builder.mutation({
      query: (payload) => ({
        url: "countries/states",
        method: "POST",
        body: payload,
      }),
    }),
    fetchAllStateCities: builder.mutation({
      query: (payload) => ({
        url: "countries/state/cities",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useFetchAllStatesMutation, useFetchAllStateCitiesMutation, useGetAllCountriesQuery } = countriesApi;
