import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Plus, X } from "@phosphor-icons/react";
import React from "react";
import NoClients from "../../../assets/svg/no-clients";
import { secondary } from "../../../theme";
import SPAAlertDialog from "../../common/spa-alert-dialogue";
import SPAPopover from "../../common/spa-popover";
import SPATable from "../../common/spa-table";
import SPATextField from "../../common/spa-textField";
import AddQualification from "./add-qualification";
import NewQualificationForm from "./new-qualification";
import { qualificationsPopOverData } from "./qualifications-data";
import useQualifications from "./useQualifications";
import UpdateStaffDialog from "../staff/update-staff-dialog";
/**
 * QualificationsSection component.
 *
 * @returns {JSX.Element} JSX code for rendering the QualificationsSection component.
 */
const QualificationsSection = () => {
  const {
    singleQualificationId,
    setSingleQualificationId,
    qualificationsTableHeader,
    isQualificationsDialogOpen,
    setIsQualificationsDialogOpen,
    qualificationsPopoverOpen,
    setQualificationsPopoverOpen,
    qualificationsPopOverId,
    openQualificationsPopOver,
    handleNewQualification,
    qualificationsData,
    qualificationsDataLoader,
    currentPage,
    setCurrentPage,
    isOpenSuccessDialog,
    setIsOpenSuccessDialog,
    handleCloseSuccessPopup,
    handleAddQualification,
    searchQualification,
    setSearchQualification,
    isOpenAddQualificationDialog,
    setIsOpenAddQualificationDialog,
    getAllQualifications,
    staffUpdateType,
    setStaffUpdateType,
  } = useQualifications();
  return (
    <Box sx={{ minWidth: "688px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            disableElevation
            variant="contained"
            sx={{
              background: secondary.main,
              display: "flex",
              gap: "8px",
              alignItems: "center",
              borderRadius: "8px",
              height: "48px",
              "&:hover": { background: secondary.main },
            }}
            onClick={handleNewQualification}
          >
            <Plus size={24} color="currentColor" />
            Create Qualification
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            flexWrap: "wrap",
          }}
        >
          <SPATextField
            placeholder="Search"
            value={searchQualification}
            sx={{
              width: { md: "328px", xs: "180px" },
              "& .MuiOutlinedInput-root": {
                height: "48px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                  <MagnifyingGlass size={24} color={"#C1C2C3"} />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ paddingLeft: "10px", cursor: "pointer" }}
                  onClick={() => {
                    setSearchQualification("");
                  }}
                >
                  {searchQualification && <X size={24} />}
                </InputAdornment>
              ),
            }}
            onChangeValue={(event) => {
              setSearchQualification(event.target.value);
            }}
          />
        </Box>
      </Box>
      <Box sx={{ pt: "16px" }}>
        {qualificationsDataLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={qualificationsDataLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {qualificationsData?.result?.data?.data?.length > 0 &&
          !qualificationsDataLoader && (
            <SPATable
              data={qualificationsData?.result?.data?.data}
              columns={qualificationsTableHeader}
              totalRecords={qualificationsData?.result?.data?.totalRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        {qualificationsData?.result?.data?.data?.length === 0 &&
          !qualificationsDataLoader && (
            <Box
              sx={{
                mt: "18px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <NoClients />
              <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                There’s no Qualification yet
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                After creating Qualification, they will be displayed here as
                Qualification.
              </Typography>
            </Box>
          )}
      </Box>

      {isQualificationsDialogOpen && (
        <NewQualificationForm
          getAllQualifications={getAllQualifications}
          setSingleQualificationId={setSingleQualificationId}
          setIsOpenSuccessDialog={setIsOpenSuccessDialog}
          singleQualificationId={singleQualificationId}
          isNewQualificationOpen={isQualificationsDialogOpen}
          setStaffUpdateType={setStaffUpdateType}
          handleClose={(data) => {
            setIsQualificationsDialogOpen(false);
            setSingleQualificationId(null);
          }}
        />
      )}
      {isOpenAddQualificationDialog && (
        <UpdateStaffDialog
          setIsOpenSuccessDialog={setIsOpenSuccessDialog}
          singleQualificationId={singleQualificationId}
          isAddQualificationOpen={isOpenAddQualificationDialog}
          handleClose={(data) => {
            setIsOpenAddQualificationDialog(false);
            setSingleQualificationId(null);
          }}
          dialogTitle={`Add ‘Qualification’ to a ${staffUpdateType ?? "Staff"}`}
          contentTitle={`${staffUpdateType ?? "Staff"}s`}
          subContentDescription={`Please select the ${
            staffUpdateType ?? "Staff"
          } you want to add this qualification to.`}
          type={staffUpdateType ?? "Staff"}
        />
      )}
      {openQualificationsPopOver && (
        <SPAPopover
          id={qualificationsPopOverId}
          anchorEl={qualificationsPopoverOpen}
          width="225px"
          isOpen={openQualificationsPopOver}
          transformHorizontal="right"
          handleClose={(data) => {
            setQualificationsPopoverOpen(null);
          }}
          data={qualificationsPopOverData(
            setQualificationsPopoverOpen,
            setIsQualificationsDialogOpen,
            setIsOpenAddQualificationDialog,
            setStaffUpdateType
          )}
        />
      )}
      {isOpenSuccessDialog && (
        <SPAAlertDialog
          open={isOpenSuccessDialog}
          handleClose={handleCloseSuccessPopup}
          handleNewTab={handleAddQualification}
          title={"Qualification Created"}
          contentText={`Do you want to add this qualification to a treatment and/or User?`}
          disagreeText={`Add Qualification`}
          agreeText={`Close`}
        />
      )}
    </Box>
  );
};

export default QualificationsSection;
