import { styled, Switch } from "@mui/material";

export const StaffModal = styled("div")(() => {
  return {
    width: "auto",
    height: "auto",
  };
});

export const StaffNavTab = {
  display: "flex",
  height: "40px",
  padding: "0px 16px",
  justifyContent: "center",
  alignItems: "center",
};

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#4E45E5",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2, // Adjusted border-radius
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export const textareaStyle = (theme) => ({
  border: `1px solid ${theme.palette.borderGray100.main}`,
  borderRadius: "8px",
  fontSize: "12px",
  padding: "6px",
  backgroundColor: "#fff !important",
  height: "auto",
});

export const overview = {
  appointmentCardTitle: {
    color: "#4A4C4F",
    paddingTop: "16px",
    fontSize: "10px",
    fontWeight: 400,
    textTransform: "uppercase",
  },
  pinnedStyle: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    gap: "8px",
    backgroundColor: "#EDECFC",
    borderRadius: "8px",
  },
  ContraindicationsStyle: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    gap: "8px",
    backgroundColor: "#FEFFE6",
    borderRadius: "8px",
  },
};

export const address = {
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "2px",
  },
};

export const SubmitBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.main,
  color: theme.palette.white.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.dark,
  },
});
