import React from "react";

const NoClients = () => {
  return (
    <svg
      width="221"
      height="228"
      viewBox="0 0 221 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.663 100.029C23.663 100.029 62.4339 98.4768 70.8124 34.7457C78.2527 -21.8536 143.631 3.90831 162.529 26.4702C187.01 55.6971 173.96 105.974 205.629 112.325C237.298 118.676 216.618 189.293 168.546 182.533C108.746 174.125 123.214 209.069 105.262 223.86C92.3785 234.475 39.5513 224.256 37.7907 185.723C36.3091 153.297 22.6314 153.4 12.9365 149.723C-1.04572 144.421 -9.85605 106.017 23.663 100.029Z"
        fill="#DEE9FF"
      />
      <path
        d="M155.462 170.807L117.371 133.168L109.828 140.801L147.92 178.44L155.462 170.807Z"
        fill="#699CFF"
      />
      <path
        d="M170.013 180.92L140.378 151.637C140.213 151.474 139.947 151.476 139.784 151.641L128.567 162.992C128.404 163.157 128.406 163.423 128.571 163.586L158.206 192.869C158.371 193.032 158.637 193.031 158.8 192.866L170.017 181.514C170.18 181.349 170.178 181.083 170.013 180.92Z"
        fill="#0E84E5"
      />
      <path
        d="M83.6106 152.097C109.816 152.097 131.06 130.853 131.06 104.648C131.06 78.4421 109.816 57.1982 83.6106 57.1982C57.405 57.1982 36.1611 78.4421 36.1611 104.648C36.1611 130.853 57.405 152.097 83.6106 152.097Z"
        fill="#0E84E5"
      />
      <path
        d="M83.6105 144.355C105.54 144.355 123.318 126.578 123.318 104.648C123.318 82.7182 105.54 64.9407 83.6105 64.9407C61.6808 64.9407 43.9033 82.7182 43.9033 104.648C43.9033 126.578 61.6808 144.355 83.6105 144.355Z"
        fill="#D4E5FF"
      />
      <path
        d="M163.829 61.7531C152.699 58.1454 140.671 64.247 137.049 75.4228C133.441 86.5528 139.543 98.5809 150.719 102.203C161.895 105.825 173.891 99.664 177.5 88.5334C181.108 77.4028 174.96 65.3606 163.829 61.7531ZM160.471 72.1145C161.717 72.5183 162.815 73.283 163.625 74.3117C164.436 75.3403 164.923 76.5865 165.024 77.8922C165.125 79.1979 164.837 80.5043 164.194 81.6456C163.552 82.787 162.585 83.7119 161.417 84.3031C160.248 84.8943 158.93 85.1251 157.63 84.9662C156.33 84.8074 155.107 84.266 154.115 83.4108C153.123 82.5556 152.408 81.4251 152.059 80.1627C151.711 78.9003 151.745 77.5628 152.158 76.3199C153.273 72.8813 157.032 70.9999 160.471 72.1145ZM152.068 98.0405C148.37 96.8481 145.202 94.4072 143.106 91.1359C147.028 88.9072 151.129 88.2864 154.84 89.489C158.55 90.6916 161.567 93.5693 163.376 97.7058C159.76 99.1257 155.762 99.244 152.068 98.0405Z"
        fill="#418DF9"
      />
      <path
        d="M71.1911 163.176C69.2578 164.163 67.6606 165.701 66.6014 167.595C65.5423 169.49 65.0687 171.656 65.2405 173.82C65.4124 175.983 66.222 178.047 67.567 179.751C68.912 181.455 70.7319 182.721 72.7967 183.39C74.8615 184.06 77.0783 184.101 79.1669 183.511C81.2555 182.92 83.1221 181.723 84.5305 180.072C85.9389 178.42 86.826 176.388 87.0795 174.233C87.3331 172.077 86.9417 169.895 85.9549 167.961C84.6293 165.371 82.3304 163.413 79.5625 162.516C76.7947 161.618 73.784 161.856 71.1911 163.176ZM73.7472 168.184C74.3493 167.876 75.0291 167.755 75.7005 167.834C76.3718 167.914 77.0044 168.191 77.518 168.63C78.0316 169.07 78.403 169.652 78.5852 170.303C78.7674 170.954 78.7522 171.645 78.5414 172.287C78.3306 172.93 77.9337 173.495 77.4012 173.911C76.8687 174.328 76.2245 174.577 75.5503 174.626C74.8761 174.676 74.2023 174.525 73.6143 174.191C73.0264 173.857 72.5508 173.356 72.2479 172.752C71.8465 171.946 71.7787 171.015 72.0594 170.16C72.34 169.305 72.9465 168.594 73.7472 168.183V168.184ZM80.1428 180.714C78.358 181.628 76.3107 181.893 74.3517 181.464C74.9698 179.219 76.2402 177.496 78.0337 176.581C79.8272 175.665 81.9783 175.615 84.1485 176.464C83.3465 178.302 81.9305 179.804 80.1428 180.713V180.714Z"
        fill="#418DF9"
      />
    </svg>
  );
};

export default NoClients;
