import { FilterList } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, UserPlus, X } from "@phosphor-icons/react";
import React from "react";
import NoClients from "../../assets/svg/no-clients";
import { fs14, fs20, fs34, fw600, secondary } from "../../theme";
import SPAAlertDialog from "../common/spa-alert-dialogue";
import SPANestedMenu from "../common/spa-nested-menu";
import SPAPopover from "../common/spa-popover";
import SPATable from "../common/spa-table";
import SPATextField from "../common/spa-textField";
import { NewClientForm } from "../new-client-form/index";
import { clientsFilterData, clientsPopOverData } from "./clients-data";
import useViewClient from "./useViewClient";
import NewAppointmentForm from "../new-appointment-form";
import {
  handleCloseAppointmentModal,
  handleCloseCreateAppointmentAlert,
  handleOpenAppointmentModal,
} from "../../store/features/new-appointment";
import Profile from "../profile-model";
import { PermissionProtected } from "../../guards/permission-protected";
import { PERMISSION } from "../../enums/permissions";
/**
 * ClientsSection component.
 * Renders a section for managing clients, including a table of clients and an option to add a new client.
 *
 * @returns {JSX.Element} JSX code for rendering the ClientsSection component.
 */
const ClientsSection = () => {
  const {
    clientsPopoverOpen,
    setClientsPopoverOpen,
    isFilterPopOverOpen,
    setIsFilterPopoverOpen,
    filteredList,
    setFilteredList,
    dispatch,
    openClientsPopOver,
    clientsPopOverId,
    filterPopOverId,
    finalFilteredArray,
    handleDeleteFilterItem,
    column,
    clientsData,
    filterOpenPopOver,
    openAppointmentModal,
    openAppointmentAlert,
    createdClientDetail,
    handleNewClient,
    setIsClientDialogOpen,
    isClientDialogOpen,
    isAlertDialogOpen,
    setAlertDialogOpen,
    setClientFullName,
    clientFullName,
    isNotifyPanelOpen,
    searchClient,
    setSearchClient,
    selectedClient,
    isClientProfileModalOpen,
    handleCloseClientProfile,
    handleOpenClientProfile,
    clientsDataLoader,
    dialogExistClient,
    setDialogExistClient,
    totalRecords,
    setCurrentPage,
    currentPage,
    handleCloseAppointmentDialog,
    getAllClients,
    navigate,
  } = useViewClient();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography sx={{ fontSize: fs34 }}>Clients</Typography>
          <PermissionProtected permission={PERMISSION.CREATE_CLIENT} disabled>
            <Button
              disableElevation
              variant="contained"
              sx={{
                background: secondary.main,
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                height: "48px",
                "&:hover": { background: secondary.main },
              }}
              onClick={handleNewClient}
            >
              <UserPlus size={24} color="currentColor" />
              Create Client
            </Button>
          </PermissionProtected>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            flexWrap: "wrap",
            marginRight: isNotifyPanelOpen ? "0" : "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            <SPATextField
              placeholder="Search"
              value={searchClient}
              sx={{
                width: { md: "328px", xs: "180px" },
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                    <MagnifyingGlass size={24} />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    onClick={() => {
                      setSearchClient("");
                    }}
                  >
                    {searchClient && <X size={24} />}
                  </InputAdornment>
                ),
              }}
              onChangeValue={(event) => {
                setSearchClient(event.target.value);
              }}
            />
          </Box>

          <Box
            sx={styles.searchbox}
            onClick={(e) => setIsFilterPopoverOpen(e.currentTarget)}
          >
            <FilterList sx={{ color: secondary.main }} />
          </Box>
        </Box>
      </Box>
      {filteredList.length > 0 && (
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pt: "16px" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            {finalFilteredArray.map((item, i) => (
              <Box
                key={i}
                sx={{
                  p: "6.5px 8px",
                  borderRadius: "24px",
                  background: "#EDECFC",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Box sx={{ fontSize: fs14, color: "#070A0E" }}>
                    {item.label}: {item?.children?.label}
                  </Box>

                  <X
                    size={24}
                    color="#070A0E"
                    onClick={() => handleDeleteFilterItem(item)}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
          <Typography
            sx={{
              fontSize: fs14,
              fontWeight: fw600,
              color: secondary.main,
              cursor: "pointer",
            }}
            onClick={() => setFilteredList([])}
          >
            Clear all filters
          </Typography>
        </Box>
      )}
      <Box sx={{ pt: "16px" }}>
        {clientsDataLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={clientsDataLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {clientsData?.result?.data?.totalRecords > 0 && !clientsDataLoader && (
          <SPATable
            data={clientsData?.result?.data?.data}
            columns={column}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
          />
        )}
        {clientsData?.result?.data?.totalRecords === 0 &&
          !clientsDataLoader && (
            <Box
              sx={{
                mt: "18px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <NoClients />
              <Typography sx={{ fontSize: fs20, color: "#595959" }}>
                There’s no clients
              </Typography>
              <Typography sx={{ fontSize: fs14, color: "#595959" }}>
                {searchClient
                  ? "No Client Find"
                  : " Create a new client by clicking on the button Create Client’"}
              </Typography>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleNewClient}
              >
                <UserPlus size={24} color="currentColor" />
                Create Client
              </Button>
            </Box>
          )}
      </Box>

      {isClientDialogOpen && (
        <NewClientForm
          getAllClients={getAllClients}
          isDialogOpen={isClientDialogOpen}
          setDialogOpen={setIsClientDialogOpen}
          setAlertOpen={setAlertDialogOpen}
          setClientFullName={setClientFullName}
          setDialogExistClient={setDialogExistClient}
        />
      )}

      {filterOpenPopOver && (
        <SPANestedMenu
          id={filterPopOverId}
          setFilteredList={setFilteredList}
          anchorEl={isFilterPopOverOpen}
          isOpen={filterOpenPopOver}
          handleClose={() => {
            setIsFilterPopoverOpen(null);
          }}
          data={clientsFilterData()}
        />
      )}
      {openClientsPopOver && (
        <SPAPopover
          id={clientsPopOverId}
          anchorEl={clientsPopoverOpen}
          width="215px"
          isOpen={openClientsPopOver}
          transformHorizontal="right"
          handleClose={() => {
            setClientsPopoverOpen(null);
          }}
          data={clientsPopOverData(
            setClientsPopoverOpen,
            dispatch,
            selectedClient,
            navigate
          )}
        />
      )}

      {/* appointment */}
      {openAppointmentModal && (
        <NewAppointmentForm
          getAllClients={getAllClients}
          isAppointmentOpen={openAppointmentModal}
          handleClose={() => dispatch(handleCloseAppointmentModal())}
        />
      )}

      {openAppointmentAlert && (
        <SPAAlertDialog
          open={openAppointmentAlert}
          handleClose={handleCloseAppointmentDialog}
          title={"New Appointment Created"}
          contentText={`New appointment for ${createdClientDetail?.clientName} was created. For the date ${createdClientDetail?.date}`}
          disagreeText={`Create another appointment`}
          agreeText={`Close`}
          handleNewTab={() => {
            dispatch(handleOpenAppointmentModal(createdClientDetail));
            dispatch(handleCloseCreateAppointmentAlert());
          }}
        />
      )}

      {isAlertDialogOpen && (
        <SPAAlertDialog
          open={isAlertDialogOpen}
          handleClose={() => setAlertDialogOpen(false)}
          handleNewTab={() => {
            handleOpenClientProfile();
            setAlertDialogOpen(false);
          }}
          title={"New Client Card Created!"}
          contentText={`Client card for ${clientFullName} was successfully created. Now you can see her profile in the Clients table.`}
          disagreeText={`Open Client Profile`}
          agreeText={`Close`}
        />
      )}

      {isClientProfileModalOpen && (
        <Profile
          isClientProfileOpen={isClientProfileModalOpen}
          handleClose={handleCloseClientProfile}
        />
      )}
      {/* // if client exists */}

      {dialogExistClient && (
        <SPAAlertDialog
          open={dialogExistClient}
          handleClose={() => setDialogExistClient(false)}
          handleNewTab={() => {
            handleOpenClientProfile();
            setDialogExistClient(false);
          }}
          title={"Client Already Exists"}
          contentText={`The information you provided matches an existing client profile. Please verify the details and try again. For assistance, contact our support team.`}
          disagreeText={`View Client`}
          agreeText={`Close`}
          type="error"
        />
      )}
    </>
  );
};

export default ClientsSection;

// Styles for the search box
const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
