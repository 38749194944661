import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import React from "react";
import SPADialogue from "../../../common/spa-dialogue/index.jsx";
import { newQualificationFormData } from "./new-qualification-form-data.js";
import useNewQualification from "./useNewQualification.js";

export default function NewQualificationForm({
  isNewQualificationOpen,
  handleClose,
  singleQualificationId,
  setIsOpenSuccessDialog,
  getAllQualifications,
  setSingleQualificationId,
  setStaffUpdateType,
}) {
  const {
    control,
    methods,
    handleSubmit,
    onSubmit,
    handleCloseForm,
    singleQualificationLoader,
    createQualificationLoader,
    updateQualificationLoader,
    serviceCategoryData,
  } = useNewQualification(
    handleClose,
    singleQualificationId,
    setIsOpenSuccessDialog,
    getAllQualifications,
    setSingleQualificationId,
    setStaffUpdateType
  );

  return (
    <>
      {singleQualificationLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={singleQualificationLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!singleQualificationLoader && (
        <SPADialogue
          open={isNewQualificationOpen}
          handleClose={handleCloseForm}
          dialogTitle={
            singleQualificationId
              ? "Edit Qualification"
              : `Create Qualification`
          }
          cancelText="Cancel"
          submitText={
            singleQualificationId ? "Save Changes" : "Create Qualification"
          }
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          methods={methods}
          loading={createQualificationLoader || updateQualificationLoader}
        >
          <Box
            sx={{
              width: "688px",
              display: "flex",
              flexDirection: "column",
              gap: "0px",
            }}
          >
            <Grid container spacing={1}>
              {newQualificationFormData(
                serviceCategoryData?.result?.data?.data
              ).map((item, index) => {
                return (
                  <Grid item lg={item.gridLength} xs={12} key={index}>
                    <item.component {...item.otherOptions} control={control} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </SPADialogue>
      )}
    </>
  );
}
