import { Gift, Percent, Stack, Ticket, User } from "@phosphor-icons/react";

export const profileData = {
  label: "Profile",
  icon: User,
  link: "profile",
};

export const sidebarData = [
  {
    label: "Offers",
    icon: Percent,
    link: "Offers",
  },
  {
    label: "Promo Codes",
    icon: Ticket,
    link: "Promo Codes",
  },
  {
    label: "Gift Cards",
    icon: Gift,
    link: "Gift Cards",
  },
  {
    label: "Packages",
    icon: Stack,
    link: "Packages",
  },
];
