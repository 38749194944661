import { Avatar, Box, Button, Typography } from "@mui/material";
import {
  CalendarPlus,
  LinkBreak,
  ShootingStar,
  SketchLogo,
  StarHalf,
} from "@phosphor-icons/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DotsIcon from "../../assets/svg/dots-icon";
import { fs14, fw400, fw600, secondary } from "../../theme";
import { clientsFilterData } from "./clients-data";
import { nameInitialsAvatar } from "../../utils/nameInitialsAvatar";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCloseCreateAppointmentAlert,
  handleOpenAppointmentModal,
  handleSingleAppointmentData,
  handleSingleAppointmentModal,
} from "../../store/features/new-appointment";
import { useGetAllClientsMutation } from "../../services/clients";

import { getLocalStorageItem } from "../../utils/localStorage";
import {
  calculateTimeDifference,
  convertTo12HourFormat,
} from "../../utils/convertTimeIOSFormat";
import { setClientDetails } from "../../store/features/client-details";
import { handleFilterSearch } from "../../utils/searchFilter";
import dayjs from "dayjs";
import { PermissionProtected } from "../../guards/permission-protected";
import { PERMISSION } from "../../enums/permissions";
import { truncateText } from "../../utils/truncateText";

const statusDesign = {
  OPEN: {
    icon: <ShootingStar size={16} />,
    color: "#1AA37A",
    bgColor: "#E9FBF6",
  },
  ACTIVE: {
    icon: <ShootingStar size={16} />,
    color: "#1AA37A",
    bgColor: "#E9FBF6",
  },
  NEW: {
    icon: <ShootingStar size={16} />,
    color: "#1AA37A",
    bgColor: "#E9FBF6",
  },
  VIP: {
    icon: <SketchLogo size={16} />,
    color: "#FA9200",
    bgColor: "#FFF9F0",
  },
  LOST: {
    icon: <LinkBreak size={16} />,
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  RETURN: {
    icon: <StarHalf size={16} />,
    color: "#115ACB",
    bgColor: "#E7EFFC",
  },
};

const useViewClient = () => {
  const truncateFullName = truncateText();

  const [clientsPopoverOpen, setClientsPopoverOpen] = useState(null);
  const [isFilterPopOverOpen, setIsFilterPopoverOpen] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [searchClient, setSearchClient] = useState("");
  const [isClientDialogOpen, setIsClientDialogOpen] = useState(false);
  const [isAlertDialogOpen, setAlertDialogOpen] = useState(false);
  const [clientFullName, setClientFullName] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [isClientProfileModalOpen, setIsClientProfileModalOpen] =
    useState(false);
  const [dialogExistClient, setDialogExistClient] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const isNotifyPanelOpen = getLocalStorageItem("isNotifyOpen");

  const [getAllClients, { data: clientsData, isLoading: clientsDataLoader }] =
    useGetAllClientsMutation();
  const totalRecords = clientsData?.result?.data?.totalRecords;

  const createdClientDetail = useSelector(
    (state) => state.appointmentModal.createdClientDetails
  );

  const openAppointmentModal = useSelector(
    (state) => state?.appointmentModal?.openNewAppointmentModal
  );

  const openAppointmentAlert = useSelector(
    (state) => state?.appointmentModal?.openCreatedAlertModal
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedClientDetail = useSelector(
    (state) => state?.clientDetail?.clientDetail
  );

  const column = [
    {
      name: "firstName",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;
          const lastName = rowData[tableMeta?.rowIndex]?.lastName;
          const firstName = rowData[tableMeta?.rowIndex]?.firstName;
          const imgSource = rowData[tableMeta?.rowIndex]?.profilePictureUrl;

          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              {imgSource ? (
                <Avatar
                  sx={{ width: "48px", height: "48px", cursor: "pointer" }}
                  src={imgSource}
                  onClick={() => {
                    handleOpenClientProfile();
                    dispatch(setClientDetails(rowData[tableMeta?.rowIndex]));
                  }}
                  variant="rounded"
                />
              ) : (
                <Avatar
                  {...nameInitialsAvatar(`${firstName} ${lastName}`)}
                  onClick={() => {
                    handleOpenClientProfile();
                    dispatch(setClientDetails(rowData[tableMeta?.rowIndex]));
                  }}
                  variant="rounded"
                />
              )}
              <Typography
                sx={{
                  color: "#070A0E",
                  fontSize: fs14,
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  handleOpenClientProfile();
                  dispatch(setClientDetails(rowData[tableMeta?.rowIndex]));
                }}
              >
                {truncateFullName(`${value} ${lastName}`)}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "clientStatus",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Box
                sx={{
                  backgroundColor: statusDesign[value ? value : "OPEN"].bgColor,
                  p: "4px 8px",
                  color: statusDesign[value ? value : "OPEN"].color,
                  width: "fit-content",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {statusDesign[value ? value : "OPEN"].icon}
                {value ? value : "NEW"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleOpenClientProfile();
                dispatch(setClientDetails(rowData[tableMeta?.rowIndex]));
              }}
            >
              {value || "..."}
            </Typography>
          );
        },
      },
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Typography
              sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
              onClick={() => {
                handleOpenClientProfile();
                dispatch(setClientDetails(rowData[tableMeta?.rowIndex]));
              }}
            >
              {value || "...."}
            </Typography>
          );
        },
      },
    },
    {
      name: "nextAppointment",
      label: "Next Appointment",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;

          const originalDate = new Date(
            rowData[tableMeta?.rowIndex]?.upcomingAppointments?.date
          );
          const formattedDate = originalDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });

          let startTime =
            rowData[tableMeta?.rowIndex]?.upcomingAppointments?.startTime;
          let endTime =
            rowData[tableMeta?.rowIndex]?.upcomingAppointments?.endTime;

          let difference;
          if (startTime && endTime)
            difference = calculateTimeDifference(startTime, endTime);

          // if (startTime) startTime = convertTo12HourFormat(startTime);
          difference = `${dayjs(startTime).format(
            "h:mm A"
          )} (${difference} Minutes)`;

          return (
            <>
              {formattedDate !== "Invalid Date" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: fs14,
                    fontWeight: fw400,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/calendar");
                    dispatch(handleSingleAppointmentModal());
                    dispatch(
                      handleSingleAppointmentData(
                        rowData[tableMeta?.rowIndex]?.latestAppointment?._id
                      )
                    );
                  }}
                >
                  <Typography>
                    {" "}
                    {dayjs(
                      rowData[tableMeta?.rowIndex]?.upcomingAppointments?.date
                    ).format("DD MMM YYYY")}
                  </Typography>
                  <Typography> {difference}</Typography>
                </Box>
              ) : (
                <PermissionProtected
                  permission={PERMISSION.CREATE_APPOINTMENT}
                  disabled
                >
                  <Button
                    disableRipple
                    disableElevation
                    disableFocusRipple
                    sx={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                      cursor: "pointer",
                      color: secondary.main,
                      "&:disabled": {
                        color: "#757575",
                      },
                    }}
                    onClick={() => {
                      dispatch(
                        handleOpenAppointmentModal(rowData[tableMeta?.rowIndex])
                      );
                    }}
                  >
                    <CalendarPlus size={24} color="currentColor" />

                    <Typography
                      sx={{
                        fontSize: fs14,
                        whiteSpace: "nowrap",
                        color: "currentColor",
                      }}
                    >
                      New Appointment
                    </Typography>
                  </Button>
                </PermissionProtected>
              )}
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                setClientsPopoverOpen(e.currentTarget);
                setSelectedClient(rowData[tableMeta?.rowIndex]);
              }}
            >
              <DotsIcon />
            </Box>
          );
        },
      },
    },
  ];

  const handleNewClient = () => {
    setIsClientDialogOpen(true);
  };

  const handleOpenClientProfile = () => {
    setIsClientProfileModalOpen(true);
  };
  const handleCloseClientProfile = () => {
    setIsClientProfileModalOpen(false);
    dispatch(setClientDetails({}));
  };

  // Variables for clients popover state and IDs
  const openClientsPopOver = Boolean(clientsPopoverOpen);
  const clientsPopOverId = openClientsPopOver ? "simple-popover" : undefined;
  // Variables for filter popover state and IDs
  const filterOpenPopOver = Boolean(isFilterPopOverOpen);
  const filterPopOverId = filterOpenPopOver ? "simple-popover" : undefined;

  // Filtered array for displaying selected filter items

  const finalFilteredArray = useMemo(() => {
    const uniqueLabels = new Set();

    const uniqueFilterList = filteredList.filter((item) => {
      if (!uniqueLabels.has(item.label)) {
        // Check if label is already in the set
        uniqueLabels.add(item.label);
        return true;
      }
      return false;
    });

    return uniqueFilterList
      .flatMap((item, index) => {
        return clientsFilterData().flatMap((parentItem) => {
          return parentItem.children.flatMap((childernItem) => {
            if (childernItem.label === item.label) {
              return {
                id: index,
                label: parentItem.label,
                children: childernItem,
              };
            }
            return null;
          });
        });
      })
      .filter((item) => item !== null);
  }, [filteredList]);

  const handleApiCall = useCallback(() => {
    // set the string for filter search based on Label and category
    const filteredString = finalFilteredArray.map((filterItem) => {
      if (filterItem.label === "Referral Source")
        return "referralSource" + "=" + filterItem.children.label.toLowerCase();
      return filterItem.label.toLowerCase() + "=" + filterItem.children.label;
    });
    const filterPayload = handleFilterSearch(
      filteredString,
      [],
      "filter",
      "and"
    );
    getAllClients({
      ...filterPayload,
      page: currentPage,
      pageSize: 10,
      roleType: "CLIENT",
    });
  }, [finalFilteredArray]);

  /**
   * Handles the action when a filter item is deleted.
   * @param {Object} item - The filter item to be deleted.
   */
  const handleDeleteFilterItem = (item) => {
    // Filter out the item from filteredListArray based on its label
    const updatedArray = filteredList?.filter(
      (filteredItem) => filteredItem?.label !== item?.children?.label
    );
    // Update the filteredList state with the updated array
    setFilteredList(updatedArray);
  };

  const handleCloseAppointmentDialog = () => {
    getAllClients({ roleType: "CLIENT" });
    dispatch(handleCloseCreateAppointmentAlert());
  };

  useEffect(() => {
    if (finalFilteredArray.length > 0) {
      handleApiCall();
    } else
      getAllClients({ page: currentPage, pageSize: 10, roleType: "CLIENT" });
  }, [finalFilteredArray, handleApiCall, currentPage]);

  useEffect(() => {
    if (searchClient !== "") {
      const searchPayload = handleFilterSearch(
        searchClient,
        ["firstName", "lastName", "email", "telephone", "mobileNumber"],
        "search"
      );

      getAllClients({
        ...searchPayload,
        page: currentPage,
        pageSize: 10,
        roleType: "CLIENT",
      });
    } else
      getAllClients({ page: currentPage, pageSize: 10, roleType: "CLIENT" });
  }, [searchClient, currentPage]);

  return {
    clientsPopoverOpen,
    setClientsPopoverOpen,
    isFilterPopOverOpen,
    setIsFilterPopoverOpen,
    filteredList,
    setFilteredList,
    navigate,
    openClientsPopOver,
    clientsPopOverId,
    filterPopOverId,
    finalFilteredArray,
    handleDeleteFilterItem,
    column,
    filterOpenPopOver,
    clientsData,
    openAppointmentModal,
    openAppointmentAlert,
    selectedClientDetail,
    dispatch,
    createdClientDetail,
    handleNewClient,
    isClientDialogOpen,
    setIsClientDialogOpen,
    isAlertDialogOpen,
    setAlertDialogOpen,
    setClientFullName,
    clientFullName,
    isNotifyPanelOpen,
    searchClient,
    setSearchClient,
    selectedClient,
    isClientProfileModalOpen,
    handleCloseClientProfile,
    clientsDataLoader,
    handleOpenClientProfile,
    dialogExistClient,
    setDialogExistClient,
    setCurrentPage,
    currentPage,
    totalRecords,
    handleCloseAppointmentDialog,
    getAllClients,
  };
};

export default useViewClient;
