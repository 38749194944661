import { FilterList } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  Archive,
  ChatText,
  CursorClick,
  Envelope,
  Image,
  MagnifyingGlass,
  PaperPlaneTilt,
  Plus,
  X,
} from "@phosphor-icons/react";
import { Editor } from "@tinymce/tinymce-react";
import dayjs from "dayjs";
import React, { useState } from "react";
import NoTemplates from "../../../assets/svg/no-templates";
import { customColors, fs20, secondary } from "../../../theme";
import SPATabs from "../../common/spa-tabs";
import SPATextField from "../../common/spa-textField";
import FormProvider from "../../hook-form/FormProvider";
import EditorField from "./EditorField";
import { InsertBtn, SubmitBtn } from "./historyStyle";
import { historyFormData, historyTabsArray } from "./history-form-data";
import useHistory from "./useHistory";
import SPACheckBox from "../../hook-form/SPACheckBox";
import NoNotifications from "../../../assets/svg/no-notifications";

export default function HistorySection() {
  const {
    editorRef,
    methods,
    handleSubmit,
    onSubmit,
    control,
    activeHistoryTab,
    historyType,
    setHistoryType,
    handleTabSelection,
    historyData,
    historyId,
    historyDataLoader,
    handleSingleHistory,
    singleHistoryLoader,
    handleCreateCommunication,
    clientsData,
    templatesData,
    searchHistory,
    setSearchHistory,
  } = useHistory();

  const [editorInstance, setEditorInstance] = useState(null);
  const handleInsertButton = () => {
    if (editorInstance) {
      const content =
        '<button style="background-color: #4E45E5; color: #FFFFFF;height: 40px; padding: 8px; border: none; border-radius: 8px;">Call To Action</button>';
      editorInstance.insertContent(content);
    }
  };
  const InsertButton = () => {
    return (
      <Box>
        {" "}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "8px",
          }}
        >
          <Typography sx={{ color: "#5E6062", fontSize: "14px", mb: "4px" }}>
            Insert
          </Typography>
        </Box>
        <Box
          sx={{
            borderRadius: "8px",
            width: "100%",
            display: "flex",
            gap: "8px",
          }}
        >
          <Button sx={InsertBtn}>
            <Image size={24} />
            Image
          </Button>
          <Button sx={InsertBtn} onClick={() => handleInsertButton()}>
            {" "}
            <CursorClick size={24} />
            Button
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  height: "48px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleCreateCommunication}
              >
                <Plus size={24} color="currentColor" />
                Create Communication
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              <SPATextField
                placeholder="Search"
                value={searchHistory}
                sx={{
                  width: { md: "328px", xs: "180px" },
                  "& .MuiOutlinedInput-root": {
                    height: "48px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} color={"#C1C2C3"} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingLeft: "10px", cursor: "pointer" }}
                      onClick={() => {
                        setSearchHistory("");
                      }}
                    >
                      {searchHistory && <X size={24} />}
                    </InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchHistory(event.target.value);
                }}
              />

              <Box sx={styles.searchbox}>
                <FilterList sx={{ color: secondary.main }} />
              </Box>
            </Box>
          </Box>
        </Box>

        {(historyDataLoader || singleHistoryLoader) && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={historyDataLoader || singleHistoryLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Grid
          container
          columnGap={3}
          sx={{
            height: "75vh",
            gap: "16px",
          }}
        >
          {/* {historyData?.result?.data?.length > 0 && ( */}
          <Grid item xs={3}>
            <SPATabs
              tabsNameArray={historyTabsArray}
              activeTab={activeHistoryTab}
              setActiveTab={handleTabSelection}
            />
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                maxHeight: "66vh",
                overflowY: "auto",
                mb: 2,
              }}
            >
              {historyData?.result?.data?.map((item) => {
                return (
                  <Box
                    key={item?._id}
                    sx={{
                      borderRadius: "16px",
                      bgcolor: historyId === item?._id ? "#CAC7F7" : "#F8F7FE",
                      padding: "16px",
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSingleHistory(item)}
                  >
                    <Typography sx={{ fontSize: "20px", color: "#4A4C4F" }}>
                      {item?.name}
                    </Typography>
                    <Typography
                      sx={{ fontSize: "14px", color: "#4A4C4F", mt: "8px" }}
                    >
                      {item?.subject ?? "---"}
                    </Typography>
                    <Typography sx={{ fontSize: "12px", color: "#5E6062" }}>
                      {/* {item?.type === "EMAIL" && (
                          <Box
                            sx={{
                              overflow: "hidden",
                              height: "50px",
                              bgcolor:
                                historyId === item?._id ? "#CAC7F7" : "#F8F7FE",
                              "& .tox-tinymce": {
                                border: "none",
                                bgcolor:
                                  historyId === item?._id
                                    ? "#CAC7F7"
                                    : "#F8F7FE",
                                "& .tox-edit-area::before": { border: "none" },
                                "& .tox-edit-area__iframe": {
                                  backgroundColor:
                                    historyId === item?._id
                                      ? "#CAC7F7"
                                      : "#F8F7FE",
                                  height: "100px",
                                },
                              },
                            }}
                          >
                            <Editor
                              apiKey="skb6117e0zohgmjyg8fws6ytucbykv0bcevzf4ntqhlwtt2f"
                              disabled={true}
                              initialValue={item?.message}
                              init={{
                                // height: "100%",
                                menubar: false,
                                statusbar: false,
                                toolbar: false,
                              }}
                            />
                          </Box>
                        )}{" "} */}
                      {item?.type === "SMS" && item?.message}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Envelope color="#8F9193" size={16} />

                        <Typography
                          fontSize={"10px"}
                          color={"#8F9193"}
                          textTransform={"uppercase"}
                        >
                          {`Via ${item?.type}`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          color={"#8F9193"}
                          fontSize={"10px"}
                          textTransform={"uppercase"}
                        >
                          CREATED {dayjs(item?.createAt).format("DD MMM, YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              {historyData?.result?.data?.length === 0 && (
                <Box
                  sx={{
                    mt: "18px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "24px",
                  }}
                >
                  <NoNotifications />
                  <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                    No Communication Yet
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                    After creating communication, they will be displayed here as
                    communication.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          {/* )} */}
          <Grid
            item
            xs={historyData?.result?.data?.length > 0 ? 8 : 8}
            sx={{
              borderRadius: "16px",
              bgcolor: "#F8F7FE",
              flexGrow: "1 !important",
              maxWidth: "100% !important",
            }}
          >
            {!historyType ? (
              <Box
                sx={{
                  mt: "40px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                  height: "60vh",
                }}
              >
                <NoTemplates />

                <Typography sx={{ fontSize: "34px", color: "#5E6062" }}>
                  Create Communication
                </Typography>
                <Typography sx={{ fontSize: "20px", color: "#5E6062" }}>
                  This Communication Will Be Sent:
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{
                      background: secondary.main,
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      borderRadius: "8px",
                      "&:hover": { background: secondary.main },
                    }}
                    onClick={() => setHistoryType("EMAIL")}
                  >
                    <Envelope size={24} color="currentColor" />
                    VIA EMAIL
                  </Button>
                  <Typography sx={{ fontSize: "20px", color: "#8F9193" }}>
                    or
                  </Typography>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{
                      background: secondary.main,
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      borderRadius: "8px",
                      "&:hover": { background: secondary.main },
                    }}
                    onClick={() => setHistoryType("SMS")}
                  >
                    <ChatText size={24} color="currentColor" />
                    VIA SMS
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  p: "24px",
                }}
              >
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={1}>
                    {historyFormData({
                      InsertButton,
                      historyType,
                      clientData: clientsData?.result?.data?.data,
                      templateData: templatesData?.result?.data,
                    }).map((item, index) => {
                      return (
                        <Grid item lg={item.gridLength} xs={12} key={index}>
                          <item.component
                            {...item.otherOptions}
                            control={control}
                          />
                        </Grid>
                      );
                    })}
                    {historyType === "EMAIL" && (
                      <Grid item lg={12} xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#5E6062",
                              fontSize: "14px",
                              mb: "4px",
                            }}
                          >
                            Message
                          </Typography>
                        </Box>
                        <EditorField
                          control={control}
                          setEditorInstance={setEditorInstance}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      p: "24px 0px 12px 0px",
                    }}
                  >
                    <Box>
                      <SPACheckBox
                        name={
                          historyType === "EMAIL"
                            ? "saveEmail"
                            : "savePhoneNumber"
                        }
                        label={
                          historyType === "EMAIL"
                            ? "Save client’s email for future communication"
                            : "Save client’s mobile Number for future communication"
                        }
                        control={control}
                      />
                    </Box>
                    <Button
                      // disabled={sendEmailLoader}
                      type="submit"
                      sx={SubmitBtn}
                    >
                      {false ? (
                        <Box sx={{ px: 4 }}>
                          <CircularProgress
                            sx={{ color: customColors.white }}
                            size={24}
                          />
                        </Box>
                      ) : (
                        <>
                          <PaperPlaneTilt size={24} />
                          {historyType === "EMAIL" ? "Send Email" : "Send SMS"}
                        </>
                      )}
                    </Button>
                  </Box>
                  {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      p: "24px 0px 12px 0px",
                    }}
                  >
                    <Button type="submit" sx={SubmitBtn}>
                      <Archive size={24} />
                      Create Reminder
                    </Button>
                  </Box> */}
                </FormProvider>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
