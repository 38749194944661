import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useForm } from "react-hook-form";
import { validationSchema } from "./validationSchema";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  useCreateRoomMutation,
  useGetSingleRoomQuery,
  useUpdateSingleRoomMutation,
} from "../../../../services/rooms";
import { useGetServicesMutation } from "../../../../services/services";
import { handleFilterSearch } from "../../../../utils/searchFilter";

dayjs.extend(utc);

export default function useNewRoom(
  handleClose,
  singleRoomId,
  getAllRooms,
  currentPage
) {
  const [repeatOn, setRepeatOn] = useState([]);
  const [isServices, setIsServices] = useState(0);
  const validationSchemaInstance = validationSchema();
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
  });
  const [createRoom, { isLoading: createRoomLoader }] = useCreateRoomMutation();
  const [updateSingleRoom, { isLoading: updateRoomLoader }] =
    useUpdateSingleRoomMutation();
  const [
    getAllServices,
    { data: servicesData, isLoading: servicesDataLoader },
  ] = useGetServicesMutation();

  const { data: singleRoom, isLoading: singleRoomLoader } =
    useGetSingleRoomQuery(singleRoomId, {
      skip: !singleRoomId,
    });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;
  const onSubmit = async (values) => {
    let payload;
    payload = {
      name: values?.name,
      description: values?.description,
      status: values?.status,
      services: values?.services,
    };

    let response;
    if (singleRoomId) {
      response = await updateSingleRoom({
        id: singleRoom?.result?.data?._id,
        payload: payload,
      });
    } else {
      response = await createRoom(payload);
    }

    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    getAllRooms({ page: currentPage, pageSize: 10 });
    toast.success(response?.data?.message);
    reset();
    handleClose();
  };

  const handleCloseForm = () => {
    handleClose();
    reset();
  };

  useEffect(() => {
    if (singleRoom) {
      setValue("name", singleRoom?.result?.data?.name);
      setValue("description", singleRoom?.result?.data?.description);
      setValue("status", singleRoom?.result?.data?.status);
      setValue(
        "services",
        singleRoom?.result?.data?.services.map((service) => service._id)
      );
    }
  }, [singleRoom]);

  useEffect(() => {
    const servicesFilterPayload = handleFilterSearch(
      ["clientInteraction=ENABLED"],
      ["clientInteraction"],
      "filter"
    );
    getAllServices({ ...servicesFilterPayload });
  }, []);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    singleRoomLoader,
    createRoomLoader,
    updateRoomLoader,
    servicesData,
    isServices,
    servicesDataLoader,
  };
}
