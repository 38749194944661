import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useUpdateContraindicationMutation } from "../../../services/notes";
import { handleCloseEditContraModal } from "../../../store/features/new-appointment";

export default function UseEditContraindication(handleContraModel) {
  const editContraindicationsModel = useSelector(
    (state) => state?.appointmentModal?.openEditContraModal
  );

  const contraindicationData = useSelector(
    (state) => state?.appointmentModal?.contraindicationData
  );
  const dispatch = useDispatch();

  const methods = useForm();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const [editContraindication] = useUpdateContraindicationMutation();

  const onSubmit = async (values) => {
    let payload = { contraindication: [{ text: values.contraindication }] };
    const response = await editContraindication({
      id: contraindicationData?.rowData?._id,
      payload,
    });
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    handleContraModel();
    contraindicationData?.getUpdatedData();
    dispatch(handleCloseEditContraModal());
    reset();
  };

  useEffect(() => {
    setValue(
      "contraindication",
      contraindicationData?.rowData?.contraindication[
        contraindicationData?.rowData?.contraindication?.length - 1
      ]?.text
    );
  }, [contraindicationData]);

  return {
    control,
    errors,
    handleSubmit,
    onSubmit,
    methods,
    editContraindicationsModel,
    contraindicationData,
  };
}
