import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useCreateServiceCategoryMutation,
  useGetSingleServiceCategoryQuery,
  useUpdateServiceCategoryMutation,
} from "../../../../services/service-category";
import { validationSchema } from "./validationSchema";

dayjs.extend(utc);

export default function useNewServiceCategory(
  handleClose,
  singleServiceCategoryId
) {
  const validationSchemaInstance = validationSchema();
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
  });
  const [createServiceCategory, { isLoading: createServiceCategoryLoader }] =
    useCreateServiceCategoryMutation();
  const [
    updateSingleServiceCategory,
    { isLoading: updateServiceCategoryLoader },
  ] = useUpdateServiceCategoryMutation();

  const {
    data: singleServiceCategory,
    isLoading: singleServiceCategoryLoader,
    refetch,
  } = useGetSingleServiceCategoryQuery(singleServiceCategoryId, {
    skip: !singleServiceCategoryId,
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const currentColor = watch("color");

  const onSubmit = async (values) => {
    let payload;
    payload = {
      name: values?.name,
      color: values?.color,
    };

    let response;
    if (singleServiceCategoryId) {
      response = await updateSingleServiceCategory({
        id: singleServiceCategory?.result?.data?._id,
        payload: payload,
      });
      refetch();
    } else {
      response = await createServiceCategory(payload);
    }

    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    toast.success(response?.data?.message);
    reset();
    handleClose();
  };

  const handleCloseForm = () => {
    handleClose();
    reset();
  };

  useEffect(() => {
    if (singleServiceCategory) {
      setValue("name", singleServiceCategory?.result?.data?.name);
      setValue("color", singleServiceCategory?.result?.data?.color);
    }
  }, [singleServiceCategory]);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    singleServiceCategoryLoader,
    createServiceCategoryLoader,
    updateServiceCategoryLoader,
    currentColor,
  };
}
