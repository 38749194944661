import { ArrowForward } from "@mui/icons-material";
import {
  CalendarBlank,
  CallBell,
  Clock,
  Door,
  HandHeart,
  User,
  UserFocus
} from "@phosphor-icons/react";
import { customColors, secondary } from "../../../../../../../../theme";
import SPAInputField from "../../../../../../../hook-form/SPAInputField";
import SPAMobileDatePicker from "../../../../../../../hook-form/SPAMobileDatePicker";
import SPASelectField from "../../../../../../../hook-form/SPASelectField";

export const TreatmentViewNavBarData = [
  {
    label: "Overview",
    icon: UserFocus,
    link: "Overview",
  },
  {
    label: "Treatments",
    icon: CallBell,
    link: "Treatments",
  },
  // {
  //   label: "Publishing",
  //   icon: Clock,
  //   link: "Publishing",
  // },
  // {
  //   label: "Articles",
  //   icon: CalendarBlank,
  //   link: "Articles",
  // },
  // {
  //   label: "Promo Code",
  //   icon: Ticket,
  //   link: "DiscountCode",
  // },
  // {
  //   label: "Deals & Bundles",
  //   icon: Warning,
  //   link: "DealsAndBundles",
  // },
];

export const OverviewElements = (serviceCategoryData) => {
  return [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "name",
        label: "Product Name",
        placeholder: "i.e.: Soap for Face",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "category",
        label: "Product Type",
        options:
          serviceCategoryData &&
          serviceCategoryData?.map((item) => {
            return { label: item.name, value: item.name, id: item._id };
          }),
        placeholder: "Select Product Type",
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "price",
        label: "Unity Price",
        placeholder: "i.e.: $5.00",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "stock",
        label: "Stock",
        placeholder: "i.e.: 0",
      },
      component: SPAInputField,
    },
    {
      key: "description",
      gridLength: 12,
      otherOptions: {
        name: "description",
        label: "Description",
        labelHelperText: "(Optional)",
        type: "textarea",
        placeholder: "Add description here",
      },
      component: SPAInputField,
    },
  ];
};

export const addressElements = (addNewAddress, addressesArray) => {
  const newComponent = {
    id: addNewAddress,
    componentArray: [
      {
        gridLength: 5.9,
        otherOptions: {
          fullWidth: true,
          name: "state",
          label: "State",
          placeholder: "",
        },
        component: SPAInputField,
      },
      {
        gridLength: 5.9,
        otherOptions: {
          fullWidth: true,
          name: "city",
          label: "City",
          placeholder: "",
        },
        component: SPAInputField,
      },
      {
        gridLength: 3,
        otherOptions: {
          name: "postalCode",
          label: "Post Code",
          placeholder: "",
        },
        component: SPAInputField,
      },
      {
        gridLength: 8.9,
        otherOptions: {
          name: "street",
          label: "Street",
          placeholder: "",
        },
        component: SPAInputField,
      },
    ],
  };

  return addNewAddress > addressesArray.length
    ? [...addressesArray, newComponent]
    : addressesArray;
};

export const publishingFormData = () => {
  let finalData = [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "offerType",
        label: "Offer Type",
        placeholder: "Select Offer Type",
        options: [{ label: "Discount", value: "DISCOUNT" }],
      },
      component: SPASelectField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "price",
        label: "Price",
        placeholder: "$ i.e: $5.00",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "validOffer",
        label: "Offer Valid Until",
        placeholder: "Pick Date",
      },
      component: SPAMobileDatePicker,
    },
  ];
  return finalData;
};

export const addressFormData = () => {
  return [
    {
      gridLength: 5.9,
      otherOptions: {
        fullWidth: true,
        name: "state",
        label: "State",
        placeholder: "",
      },
      component: SPAInputField,
    },
    {
      gridLength: 5.9,
      otherOptions: {
        fullWidth: true,
        name: "city",
        label: "City",
        placeholder: "",
      },
      component: SPAInputField,
    },
    {
      gridLength: 3,
      otherOptions: {
        name: "postalCode",
        label: "Post Code",
        placeholder: "",
      },
      component: SPAInputField,
    },
    {
      gridLength: 8.9,
      otherOptions: {
        name: "street",
        label: "Street",
        placeholder: "",
      },
      component: SPAInputField,
    },
  ];
};

export const appointmentSummaryElements = () => {
  return [
    {
      title: "Client",
      icon: User,
      details: {
        img: "",
        content: { text: "Jane Doe", color: secondary.main },
      },
    },
    {
      title: "Staff",
      icon: HandHeart,
      details: {
        img: "",
        content: { text: "Noah Levi", color: customColors.lightBlack },
      },
    },
    {
      title: "Service",
      icon: CallBell,
      details: {
        content: { text: "Radiance Vitamin", color: customColors.lightBlack },
      },
    },
    {
      title: "Room",
      icon: Door,
      details: {
        content: { text: "Endermologie", color: customColors.lightBlack },
      },
    },
    {
      title: "Date",
      icon: CalendarBlank,
      details: {
        content: { text: "12 Feb, 2024", color: customColors.lightBlack },
      },
    },
    {
      title: "Time",
      icon: Clock,
      details: {
        icon: ArrowForward,
        content: {
          startText: `9:00 am`,
          endText: `10:00 am`,
          color: customColors.lightBlack,
        },
        difference: "(1 hour)",
        differenceColor: "#8F9193",
      },
    },
  ];
};
