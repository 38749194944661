import {
  CalendarPlus,
  ChatText,
  CreditCard,
  Envelope,
  Gift,
  LinkBreak,
  ShootingStar,
  SketchLogo,
  StarHalf,
} from "@phosphor-icons/react";
import { handleOpenAppointmentModal } from "../../store/features/new-appointment";
import { handleOpenSalesModal } from "../../store/features/sales";

//popover mock data
export const clientsPopOverData = (
  setPopoverOpen,
  dispatch,
  client,
  navigate
) => [
  {
    id: "1",
    label: "Add Appointment",
    icon: CalendarPlus,
    onClick: () => {
      setPopoverOpen(null);
      dispatch(handleOpenAppointmentModal(client));
    },
  },
  {
    id: "2",
    label: "Add Sale",
    icon: CreditCard,
    onClick: () => {
      setPopoverOpen(null);
      dispatch(
        handleOpenSalesModal({ clientId: client?._id, getAllSales: () => {} })
      );
    },
  },
  {
    id: "3",
    label: "Add Gift Voucher",
    icon: Gift,
    onClick: () => {
      setPopoverOpen(null);
    },
  },
  {
    id: "4",
    label: "Send Email",
    icon: Envelope,
    onClick: () => {
      setPopoverOpen(null);
      navigate("/communications");
    },
  },
  {
    id: "5",
    label: "Send SMS",
    icon: ChatText,
    onClick: () => {
      setPopoverOpen(null);
      navigate("/communications");
    },
  },
];
//popover data from api
export const clientsFilterData = () => {
  return [
    {
      id: "1",
      label: "Status",
      children: [
        { label: "VIP", icon: SketchLogo },
        { label: "NEW", icon: ShootingStar },
        { label: "LOST", icon: LinkBreak },
        { label: "RETURN", icon: StarHalf },
        { label: "ACTIVE", icon: ShootingStar },
      ],
    },
    {
      id: "5",
      label: "Referral Source",
      children: [
        {
          label: "TV ADS",
        },
        {
          label: "SOCIAL MEDIA",
        },
        {
          label: "FACEBOOK",
        },
        {
          label: "TWITTER",
        },
        {
          label: "GOOGLE",
        },
        {
          label: "FRIEND",
        },
      ],
    },
    {
      id: "7",
      label: "Gender",
      children: [{ label: "Female" }, { label: "Male" }],
    },
  ];
};
