import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  url: "",
};

export const uploadFileOperationsSlice = createSlice({
  name: "uploadFile",
  initialState,
  reducers: {
    setImageUrl: (state, action) => {
      state.url = action.payload.url;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setImageUrl} = uploadFileOperationsSlice.actions;

export default uploadFileOperationsSlice.reducer;
