import React from "react";
import { Controller } from "react-hook-form";
import { AntSwitch } from "./style";
import { FormControl } from "@mui/material";
export default function SPASwitch({ name, control }) {
  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <AntSwitch inputProps={{ "aria-label": "ant design" }} {...field} checked={field.value} />
          );
        }}
      />
    </FormControl>
  );
}
