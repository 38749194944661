import { baseAPI } from "../baseApi";

export const NotesApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createUserNote: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/user/add-note/` + id,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["allNotes", "Calendar"],
    }),
    createRoomNote: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/room/add-note/` + id,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["allNotes"],
    }),
    getAllNotes: builder.query({
      query: (type) => ({ url: "note/get-all?type=" + type }),
      providesTags: ["allNotes"],
    }),
    updateNote: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/note/` + id,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["allNotes", "singleAppointment"],
    }),
    updateContraindication: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/user/update-contraindication/` + id,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["allNotes", "singleAppointment"],
    }),
  }),
});

export const {
  useCreateUserNoteMutation,
  useCreateRoomNoteMutation,
  useGetAllNotesQuery,
  useUpdateNoteMutation,
  useUpdateContraindicationMutation,
} = NotesApi;
