import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSendClientEmailMutation } from "../../../../services/clients";
import { validationSchema } from "./validationSchema";
import {
  useCreateTemplateMutation,
  useGetAllTemplatesQuery,
  useGetSingleTemplateQuery,
} from "../../../../services/templates";

export default function useEmail({ clientDetail }) {
  const [isSuccessMsg, setIsSuccessMsg] = useState(false);
  const [isTemplateField, setIsTemplateField] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const validationSchemaInstance = validationSchema();
  const methods = useForm({ resolver: yupResolver(validationSchemaInstance) });
  const [sendEmail, { data: sendEmailData, isLoading: sendEmailLoader }] =
    useSendClientEmailMutation();
  const { data: templatesData, isLoading: templatesDataLoader } =
    useGetAllTemplatesQuery("EMAIL");
  const [createTemplate, { isLoading: createTemplateLoader }] =
    useCreateTemplateMutation();
  const { data: singleTemplate, isLoading: singleTemplateLoader } =
    useGetSingleTemplateQuery(templateId, {
      skip: !templateId,
    });

  const handleTemplateForm = async (templateName) => {
    setIsSuccessMsg(false);
    setIsTemplateField(false);
    const payload = {
      name: templateName,
      message: sendEmailData?.result?.data?.message,
      subject: sendEmailData?.result?.data?.subject,
      type: "EMAIL",
    };

    let response;
    response = await createTemplate(payload);

    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    toast.success(response?.data?.message);
    setIsSuccessMsg(true);
  };
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const { template } = watch();

  const onSubmit = async (values) => {
    let payload = {
      subject: values?.subject,
      message: values?.message,
      saveEmail: values?.saveEmail,
      recipients: [values?.recipients],
      replyToEmails: [values?.replyToEmails],
    };
    let response;
    response = await sendEmail({ id: clientDetail?._id, payload });

    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    toast.success(response?.data?.message);
    setIsSuccessMsg(true);
    reset();
  };

  useEffect(() => {
    setTemplateId(template);
  }, [template]);

  useEffect(() => {
    if (singleTemplate) {
      const singleTemplateFormat = singleTemplate?.result?.data;
      setValue("message", singleTemplateFormat?.message);
      setValue("subject", singleTemplateFormat?.subject);
    }
  }, [singleTemplate]);

  useEffect(() => {
    if (clientDetail) {
      setValue(
        "client",
        `${clientDetail?.firstName} ${clientDetail?.lastName}`
      );
      setValue("recipients", clientDetail?.email);
    }
  }, [clientDetail]);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    sendEmailLoader,
    isSuccessMsg,
    setIsSuccessMsg,
    isTemplateField,
    setIsTemplateField,
    handleTemplateForm,
    createTemplateLoader,
    templatesData,
    singleTemplateLoader,
    templatesDataLoader,
  };
}
