export const SubmitBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.main,
  color: theme.palette.white.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.dark,
  },
});

export const InsertBtn = (theme) => ({
  backgroundColor: "#EDECFC",
  color: "#4E45E5",
  paddingInline: "24px",
  paddingBlock: "10px",
  borderRadius: "8px",
  fontWeight: 500,
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    backgroundColor: "#EDECFC",
  },
});

export const templateStyle = {
  ".tox-tinymce": {
    border: "none !important",
  },
};


export const articleViewStyle = {
  featureArticleHeaderMain: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "8px",
    background: "#F8F7FE",
    mt: "12px",
  },
  selectionGrid: {
    display: "flex",
    background: "#EDECFC",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    p: "2.5px 4px",
    borderRadius: "8px",
  },
};