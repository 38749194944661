import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import FormProvider from "../../hook-form/FormProvider";
import SPAMobileTimePicker from "../../hook-form/SPAMobileTimerPicker";
import { SubmitBtn } from "./timingStyle";
import useTimings from "./useTimings";

export const TimingsSection = () => {
  const { handleSubmit, control, onSubmit, methods, updateTimingLoader } =
    useTimings();
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {updateTimingLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={updateTimingLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid
        container
        sx={{
          gap: "16px",
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            borderRadius: "16px",
            bgcolor: "#F8F7FE",
            flexGrow: "1 !important",
            p: "24px",
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
          }}
        >
          <Typography sx={{ color: "#070A0E", fontSize: "20px" }}>
            SPA Timing
          </Typography>
          <Box
            sx={{
              display: "flex",
              mt: 2,
              gap: "16px",
              flexDirection: "column",
            }}
          >
            <SPAMobileTimePicker
              name="startTime"
              label="Start Time"
              placeholder="Pick Date"
              isCalenderShow={true}
              control={control}
            />
            <SPAMobileTimePicker
              name="endTime"
              label="End Time"
              placeholder="Pick Date"
              isCalenderShow={true}
              control={control}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button sx={SubmitBtn} type="submit">
                Save Changes
              </Button>
            </Box>
          </Box>
          {/* <Box>
            <Button sx={SubmitBtn} type="submit">
              Save Changes
            </Button>
          </Box> */}
        </Grid>
      </Grid>
    </FormProvider>
  );
};
