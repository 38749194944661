import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateBusyTimeMutation } from "../../../services/appointment";
import { useGetRoomsMutation } from "../../../services/rooms";
import { useGetServicesMutation } from "../../../services/services";
import { useGetStaffsMutation } from "../../../services/staff";
import { handleFilterSearch } from "../../../utils/searchFilter";
import { busyTimeValidationSchema } from "../validationSchema";

export default function useCreateBusyTimes(
  handleClose,
  setSuccessCreateModel,
  selectedSlotType,
  selectedSlotData
) {
  const [busyTimeType, setBusyTimeType] = useState("ONE_TIME");
  const [availableType, setAvailableType] = useState(
    selectedSlotType?.toUpperCase() ?? "ENTIRE_LOCATION"
  );
  const validationSchemaInstance = busyTimeValidationSchema(
    busyTimeType,
    availableType
  );
  const [repeatOn, setRepeatOn] = useState([""]);
  const methods = useForm({ resolver: yupResolver(validationSchemaInstance) });
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const [getAllUsers, { data: usersData, isLoading: usersDataLoader }] =
    useGetStaffsMutation();
  const [getAllRooms, { data: roomData, isLoading: roomDataLoader }] =
    useGetRoomsMutation();
  const [
    getAllServices,
    { data: servicesData, isLoading: servicesDataLoader },
  ] = useGetServicesMutation();
  const [createBusyTimeInfo, { error: errorCreateBusyTime, isLoading }] =
    useCreateBusyTimeMutation();

  // Combine date and time
  const timeFormatter = (date, time) => {
    const hours = new Date(time).getHours();
    const minutes = new Date(time).getMinutes();
    let dateObj = new Date(date);
    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);
    return dateObj.toISOString();
  };

  const onSubmit = async (values) => {
    let payload = {
      from: {
        date: new Date(values?.startDate).toISOString(),
        time: timeFormatter(values?.startDate, values?.startTime),
      },
      to: {
        date: new Date(values?.endDate).toISOString(),
        time: timeFormatter(values?.endDate, values?.endTime),
      },
      reason: values.reason,
      note: values.additionalNotes,
      type: busyTimeType,
      moduleType:
        availableType?.toUpperCase() === "STAFF"
          ? "USER"
          : availableType?.toUpperCase(),
    };
    if (busyTimeType !== "ENTIRE_LOCATION") {
      payload["setBusyTime"] =
        availableType?.toLowerCase() === "staff" ? "USER" : availableType;
      payload["id"] = values[availableType.toLowerCase()];
      payload[
        availableType?.toLowerCase() === "staff"
          ? "user"
          : availableType?.toLowerCase()
      ] =
        values[
          availableType?.toLowerCase() === "staff"
            ? "user"
            : availableType?.toLowerCase()
        ];
    }
    if (busyTimeType === "RECURRING") {
      payload["recurring"] = repeatOn;
    }
    const response = await createBusyTimeInfo({ payload });
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    handleClose();
    setSuccessCreateModel(true);
    reset();
  };

  useEffect(() => {
    if (selectedSlotType?.toLowerCase() === "staff") {
      setValue("user", selectedSlotData?.resourceId);
    }
    if (selectedSlotType?.toLowerCase() === "room") {
      setValue("room", selectedSlotData?.resourceId);
    }
    setValue("startDate", selectedSlotData?.start);
    setValue("endDate", selectedSlotData?.end);
    setValue("startTime", selectedSlotData?.slots[0]);
    setValue("endTime", selectedSlotData?.slots?.at(-1));
    const searchPayload = handleFilterSearch(
      ["status=ACTIVE"],
      ["status"],
      "filter"
    );
    getAllServices();
    getAllRooms({ ...searchPayload });
    getAllUsers({ roleType: "STAFF" });
  }, []);

  return {
    control,
    handleSubmit,
    onSubmit,
    methods,
    busyTimeType,
    setBusyTimeType,
    availableType,
    setAvailableType,
    repeatOn,
    isLoading,
    setRepeatOn,
    servicesData: servicesData?.result?.data?.data,
    usersData,
    roomData: roomData?.result?.data?.data,
  };
}
