import { baseAPI } from "../baseApi";

export const ServicesApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllServices: builder.query({
      query: () => ({ url: "/service" }),
      providesTags: ["Services"],
    }),
    getServices: builder.mutation({
      query: (body) => ({ url: "/service", method: "PATCH", body }),
      providesTags: ["Services"],
    }),
    createService: builder.mutation({
      query: (payload) => ({
        url: "/service",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Services"],
    }),
    getSingleService: builder.query({
      query: (serviceId) => ({ url: "/service/" + serviceId }),
      providesTags: ["singleService"],
    }),
    updateSingleService: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/service/" + id,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["Services"],
    }),
    deleteService: builder.mutation({
      query: (serviceId) => ({
        url: "/service/" + serviceId,
        method: "DELETE",
      }),
      providesTags: ["Services"],
    }),
    getSingleServicePackage: builder.query({
      query: ({ serviceId, searchQuery, type }) => ({
        url: "/service/" + serviceId + `?search=${searchQuery}&type=${type}`,
      }),
      providesTags: ["singleService"],
    }),
  }),
});

export const {
  useGetSingleServicePackageQuery,
  useGetAllServicesQuery,
  useGetSingleServiceQuery,
  useGetServicesMutation,
  useCreateServiceMutation,
  useUpdateSingleServiceMutation,
  useDeleteServiceMutation,
} = ServicesApi;
