import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";
import { Circle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import DotsIcon from "../../../assets/svg/dots-icon";
import { useGetServicesMutation } from "../../../services/services";
import { fs14, fw600 } from "../../../theme";
import { statusDesign } from "../resource-management-data";
import { handleFilterSearch } from "../../../utils/searchFilter";
import { nameInitialsAvatar } from "../../../utils/nameInitialsAvatar";
import { handleOpenRetailProductViewModal } from "../../../store/features/products";
import { useDispatch } from "react-redux";
import { resourceBox } from "../resourceManagementStyle";

const useServices = () => {
  const dispatch = useDispatch();
  const [searchService, setSearchService] = useState("");
  const [servicesPopoverOpen, setServicesPopoverOpen] = useState(null);
  const [isServicesDialogOpen, setIsServicesDialogOpen] = useState(false);
  const [singleServiceId, setSingleServiceId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  // Variables for Services popover state and IDs
  const openServicesPopOver = Boolean(servicesPopoverOpen);
  const servicesPopOverId = openServicesPopOver ? "simple-popover" : undefined;
  const [
    getAllServices,
    { data: servicesData, isLoading: servicesDataLoader },
  ] = useGetServicesMutation();
  const totalRecords = servicesData?.result?.data?.totalRecords;

  const servicesTableHeader = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSingleServiceId(rowData[tableMeta?.rowIndex]?._id);
                handleOpenProfileOpen();
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "#4A4C4F",
                  fontSize: "16px",
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                {" "}
                <Circle
                  size={24}
                  weight="fill"
                  color={rowData[tableMeta?.rowIndex]?.category?.color}
                />{" "}
                {value}{" "}
                {rowData[tableMeta?.rowIndex]?.clientInteraction && (
                  <Box
                    sx={{
                      backgroundColor:
                        statusDesign[
                          rowData[tableMeta?.rowIndex]?.clientInteraction
                            ? rowData[tableMeta?.rowIndex]?.clientInteraction
                            : "ENABLED"
                        ]?.bgColor,
                      color:
                        statusDesign[
                          rowData[tableMeta?.rowIndex]?.clientInteraction
                            ? rowData[tableMeta?.rowIndex]?.clientInteraction
                            : "ENABLED"
                        ]?.color,
                      ...resourceBox.statusInfo,
                    }}
                  >
                    {rowData[tableMeta?.rowIndex]?.clientInteraction
                      ? rowData[tableMeta?.rowIndex]?.clientInteraction
                      : "ENABLED"}
                  </Box>
                )}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography
              sx={{
                color: "#5E6062",
                fontSize: "10px",
                fontWeight: "400",
                cursor: "pointer",
                textTransform: "uppercase",
              }}
            >
              {value?.name ?? "---"}
            </Typography>
          );
        },
      },
    },
    {
      name: "hours",
      label: "Duration",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: fs14,
                  fontWeight: "400",
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                {value} hour
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "beforeBufferTimeMinutes",
      label: "Buffer Time",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              {(rowData[tableMeta?.rowIndex]?.beforeBufferTimeMinutes ||
                rowData[tableMeta?.rowIndex]?.afterBufferTimeMinutes) > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {rowData[tableMeta?.rowIndex]?.beforeBufferTimeMinutes >
                    0 && (
                    <Typography
                      sx={{
                        color: "#5E6062",
                        fontSize: fs14,
                        fontWeight: "400",
                        cursor: "pointer",
                      }}
                    >
                      {`${
                        rowData[tableMeta?.rowIndex]?.beforeBufferTimeMinutes
                      } min before treatment`}
                    </Typography>
                  )}
                  {rowData[tableMeta?.rowIndex]?.afterBufferTimeMinutes > 0 && (
                    <Typography
                      sx={{
                        color: "#5E6062",
                        fontSize: fs14,
                        fontWeight: "400",
                        cursor: "pointer",
                      }}
                    >
                      {`${
                        rowData[tableMeta?.rowIndex]?.afterBufferTimeMinutes
                      } min after treatment`}
                    </Typography>
                  )}
                </Box>
              ) : (
                "---"
              )}
            </Box>
          );
        },
      },
    },
    {
      name: "products",
      label: "Products",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <AvatarGroup total={value?.length} variant="rounded">
                {value?.map((item, index) => {
                  return item?.product?.imageUrl ? (
                    <Avatar
                      key={index}
                      src={item?.product?.imageUrl}
                      onClick={() => {
                        if (item?.product?._id) {
                          dispatch(
                            handleOpenRetailProductViewModal({
                              singleProductId: item?.product?._id,
                              getAllProducts: () => {},
                              getStockProducts: () => {},
                            })
                          );
                        }
                      }}
                    />
                  ) : (
                    <Avatar
                      key={index}
                      {...nameInitialsAvatar(`${item?.product?.name}`)}
                      onClick={() => {
                        if (item?.product?._id) {
                          dispatch(
                            handleOpenRetailProductViewModal({
                              singleProductId: item?.product?._id,
                              getAllProducts: () => {},
                              getStockProducts: () => {},
                            })
                          );
                        }
                      }}
                    />
                  );
                })}
                {value?.length === 0 ?? "--"}
              </AvatarGroup>
            </Box>
          );
        },
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#2F2989",
                  fontSize: "16px",
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                ${value}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta?.tableData;
          return (
            <Box
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                setServicesPopoverOpen(e.currentTarget);
                setSingleServiceId(rowData[tableMeta?.rowIndex]?._id);
              }}
            >
              <DotsIcon />
            </Box>
          );
        },
      },
    },
  ];

  const handleOpenProfileOpen = () => {
    setIsProfileOpen(true);
  };
  const handleCloseProfileOpen = () => {
    setIsProfileOpen(false);
    setSingleServiceId("");
    getAllServices({ page: currentPage, pageSize: 10 });
  };

  const handleNewServices = () => {
    setIsServicesDialogOpen(true);
  };

  const handleSearchService = () => {
    let attributeArray = [];
    if (searchService !== "") {
      if (isNaN(searchService)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchService,
        attributeArray,
        "search"
      );
      getAllServices({ ...searchPayload });
    } else getAllServices({});
  };

  useEffect(() => {
    handleSearchService();
  }, [searchService]);

  useEffect(() => {
    getAllServices({ page: currentPage, pageSize: 10 });
  }, []);

  useEffect(() => {
    getAllServices({ page: currentPage, pageSize: 10 });
  }, [currentPage]);

  return {
    searchService,
    setSearchService,
    singleServiceId,
    setSingleServiceId,
    servicesTableHeader,
    isServicesDialogOpen,
    setIsServicesDialogOpen,
    servicesPopoverOpen,
    setServicesPopoverOpen,
    servicesPopOverId,
    openServicesPopOver,
    handleNewServices,
    servicesData,
    servicesDataLoader,
    getAllServices,
    currentPage,
    setCurrentPage,
    totalRecords,
    handleCloseProfileOpen,
    handleOpenProfileOpen,
    isProfileOpen,
  };
};

export default useServices;
