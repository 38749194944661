import { baseAPI } from "../baseApi";

export const PromoCodeApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getPromoCodes: builder.mutation({
      query: (body) => ({ url: "/coupon", method: "PATCH", body }),
      providesTags: ["PromoCode"],
    }),
    createPromoCode: builder.mutation({
      query: (payload) => ({
        url: "/coupon",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["PromoCode"],
    }),
    getSinglePromoCode: builder.query({
      query: (promoCodeId) => ({
        url: "/coupon/" + promoCodeId,
      }),
      providesTags: ["singlePromoCode"],
    }),
    updateSinglePromoCode: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/coupon/" + id,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["PromoCode"],
    }),
  }),
});

export const {
  useGetSinglePromoCodeQuery,
  useCreatePromoCodeMutation,
  useUpdateSinglePromoCodeMutation,
  useGetPromoCodesMutation,
} = PromoCodeApi;
