import styled from "@emotion/styled";
import Avatar from '@mui/material/Avatar';

export const HelperText = styled.span`
  color: #8f9193;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 400;
  letter-spacing: 0.5px;
`;

export const CircleAvatar = styled(Avatar)(({ width, height }) => ({
  width: width || '172px', 
  height: height || '172px', 
  backgroundColor: '#F0F0F1',
}));