import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useUpdateSingleProductMutation } from "../../../../../../../../../../services/product";
import { useGetProductTypeMutation } from "../../../../../../../../../../services/product-type";
import { setImageUrl } from "../../../../../../../../../../store/features/upload-file";
import { productValidationSchema } from "../../../../../validationSchema";

const useServiceOverview = ({ singleProductData, singleProductRefetching }) => {
  const [isOpenDisableConfirmDialog, setIsOpenDisableConfirmDialog] =
    useState(false);
  const [isOpenNotDisableDialog, setIsOpenNotDisableConfirmDialog] =
    useState(false);
  const [isRemoveActive, setIsRemoveActive] = useState(false);
  const methods = useForm({
    resolver: yupResolver(productValidationSchema()),
  });
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue, watch, reset } = methods;
  const measurementValue = watch("measurement");
  const addStockValue = watch("addStock");
  const removeStockValue = watch("removeStock");

  const [updateSingleProduct, { isLoading: updatedLoader }] =
    useUpdateSingleProductMutation();

  const [
    getAllProductType,
    { data: productCategoryData, isLoading: productCategoryDataLoader },
  ] = useGetProductTypeMutation();
  const imageUrl = useSelector((state) => state?.uploadFile?.url);

  const [enabled, setEnabled] = useState(null);

  const toggleSwitch = () => {
    if (enabled === true) {
      setIsOpenDisableConfirmDialog(true);
    } else {
      setEnabled(true);
    }
  };

  const handleDisableConfirm = async () => {
    const response = await updateSingleProduct({
      id: singleProductData?._id,
      payload: {
        isEnableProduct: enabled === true ? "ENABLED" : "DISABLED",
      },
    });
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      singleProductRefetching();
      setEnabled(
        response?.data?.result?.data?.isEnableProduct === "ENABLED"
          ? true
          : response?.data?.result?.data?.isEnableProduct === "DISABLED"
          ? false
          : null
      );
      setIsOpenDisableConfirmDialog(false);
    }
  };

  const onSubmit = async (values) => {
    let payload = {
      type: "RETAIL_PRODUCT",
      ...(enabled && {
        isEnableProduct: enabled === true ? "ENABLED" : "DISABLED",
      }),
      ...values,
      stock: singleProductData?.stock,
    };
    if (imageUrl) {
      payload["imageUrl"] = imageUrl;
    }
    const response = await updateSingleProduct({
      id: singleProductData?._id,
      payload: payload,
    });
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      singleProductRefetching();
    }
  };

  const stockPercentageDataHandling = (value) => {
    const totalStock = value || 0;
    const usedStock = 0;
    const availableStock = totalStock - usedStock;
    const stockPercentage = (availableStock / totalStock) * 100;
    return stockPercentage;
  };

  const handleInitialValues = () => {
    if (!singleProductData) return;
    setValue("name", singleProductData?.name);
    setValue("price", singleProductData?.price);
    setValue("measurement", singleProductData?.measurement);
    setValue("stock", singleProductData?.stock);
    setValue("description", singleProductData?.description);
    setEnabled(
      singleProductData?.isEnableProduct === "ENABLED"
        ? true
        : singleProductData?.isEnableProduct === "DISABLED"
        ? false
        : null
    );
    setValue("productType", singleProductData?.productType?._id);
    dispatch(setImageUrl({ url: singleProductData?.imageUrl }));
  };

  const handleStockHandling = async (value) => {
    let finalStockValue = singleProductData?.stock;
    if (value === "Add") {
      finalStockValue =
        Number(singleProductData?.stock) + Number(addStockValue);
    }
    if (value === "Remove") {
      finalStockValue =
        Number(singleProductData?.stock) - Number(removeStockValue);
    }

    const response = await updateSingleProduct({
      id: singleProductData?._id,
      payload: {
        stock: finalStockValue,
      },
    });
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      singleProductRefetching();
      reset({
        addStock: "",
        removeStock: "",
      });
    }
  };

  useEffect(() => {
    if (singleProductData) {
      handleInitialValues();
    }
  }, [singleProductData]);

  useEffect(() => {
    getAllProductType();
  }, []);

  return {
    methods,
    control,
    handleSubmit,
    onSubmit,
    productCategoryData,
    productCategoryDataLoader,
    singleProductData,

    toggleSwitch,
    enabled,
    setIsOpenDisableConfirmDialog,
    isOpenDisableConfirmDialog,
    isOpenNotDisableDialog,
    setIsOpenNotDisableConfirmDialog,
    handleDisableConfirm,

    stockPercentageDataHandling,
    measurementValue,
    isRemoveActive,
    setIsRemoveActive,
    handleStockHandling,
  };
};

export default useServiceOverview;
