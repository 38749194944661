import { User } from "@phosphor-icons/react";

//popover mock data
export const staffPopOverData = (setPopoverOpen, handleOpenProfileOpen) => [
  {
    id: "1",
    label: "View Product",
    icon: User,
    onClick: () => {
      setPopoverOpen(null);
      handleOpenProfileOpen();
    },
  },
];
