import SPAInputField from "../../../../../../../hook-form/SPAInputField";
import SPASelectField from "../../../../../../../hook-form/SPASelectField";
import * as Yup from "yup";

export const validationEmergencySchema = Yup.object().shape({
  emergencyContact: Yup.array().of(
    Yup.object().shape({
      contactFirstName: Yup.string().required(
        "Emergency First Name is required"
      ),
      contactLastName: Yup.string().required("Emergency Last Name is required"),
      relationship: Yup.string().required("Relationship is required"),
      phoneNumber: Yup.string()
        .matches(
          /^\+?\d{10,14}$/,
          "Invalid format. Provide Valid Format of 10 to 14 digits"
        )
        .test(
          "mobile-format",
          "Invalid format. Provide Valid Format of 10 to 14 digits",
          function (value) {
            if (!value) return true;
            return /^\+?\d{10,14}$/.test(value);
          }
        )
        .required("Mobile Number is required"),
      email: Yup.string().email("Invalid email"),
    })
  ),
});

export const emergencyContactForm = (index) => {
  return [
    {
      gridLength: 3,
      otherOptions: {
        fullWidth: true,
        name: `emergencyContact[${index}].contactFirstName`,
        label: "Contact First Name",
        placeholder: "i.e.: Jane",
      },
      component: SPAInputField,
    },
    {
      gridLength: 3,
      otherOptions: {
        fullWidth: true,
        name: `emergencyContact[${index}].contactLastName`,
        label: "Contact Last Name",
        placeholder: "i.e.: Doe",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: `emergencyContact[${index}].relationship`,
        label: "Relationship",
        placeholder: "Select Relationship",
        options: [
          {
            label: "Spouse",
            value: "SPOUSE",
          },
          {
            label: "Partner",
            value: "PARTNER",
          },
          {
            label: "Parent",
            value: "PARENT",
          },
          {
            label: "Friend",
            value: "FRIEND",
          },
          {
            label: "Sibling",
            value: "SIBLING",
          },
          {
            label: "Other",
            value: "OTHER",
          },
        ],
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: `emergencyContact[${index}].phoneNumber`,
        label: "Mobile Number",
        placeholder: "",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: `emergencyContact[${index}].email`,
        label: "Email",
        placeholder: "example@gmail.com",
        labelHelperText: "(Optional)",
      },
      component: SPAInputField,
    },
  ];
};
