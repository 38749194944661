import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Loader = ({size}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
      
      }}
    >
      <CircularProgress color="success" size={size}/>
    </Box>
  );
};

export default Loader;
