import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAddQualificationsMutation } from "../../../../services/qualifications";
import { useGetStaffsMutation } from "../../../../services/staff";
import { handleFilterSearch } from "../../../../utils/searchFilter";
import { validationSchema } from "./validationSchema";
import { useGetServicesMutation } from "../../../../services/services";
import { useUpdateStaffRoleMutation } from "../../../../services/roles";

export default function useUpdateStaff(
  handleClose,
  singleQualificationId,
  setIsOpenSuccessDialog,
  type
) {
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const [searchTherapist, setSearchTherapist] = useState("");
  const validationSchemaInstance = validationSchema(type);
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
    defaultValues: {
      [type === "Staff"
        ? "userIds"
        : type === "Staff"
        ? "serviceIds"
        : "userIds"]: [],
    },
  });
  const [updateAddQualification, { isLoading: updateQualificationLoader }] =
    useAddQualificationsMutation();
  const [getAllUsers, { data: usersData, isLoading: usersDataLoader }] =
    useGetStaffsMutation();
  const [
    getAllServices,
    { data: servicesData, isLoading: servicesDataLoader },
  ] = useGetServicesMutation();
  const [updateStaffRole, { isLoading: updateRoleLoader }] =
    useUpdateStaffRoleMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (values) => {
    let response;
    if (singleQualificationId && type === "Staff") {
      response = await updateStaffRole({
        id: singleQualificationId,
        payload: { userIds: values?.userIds },
      });
    }
    if (singleQualificationId && type !== "Staff") {
      response = await updateAddQualification({
        id: singleQualificationId,
        payload: values,
      });
    }
    if (response?.error?.data?.status === false) {
      toast.error(response?.error?.data?.error);
      return;
    }
    toast.success(response?.data?.message);
    reset();
    setIsOpenSuccessDialog(false);
    handleClose("Success");
  };

  const handleCloseForm = () => {
    handleClose("Deffer");
    reset();
  };

  const handleSearchStaff = () => {
    let attributeArray = [];
    if (searchTherapist !== "") {
      if (type === "Staff") {
        if (isNaN(searchTherapist)) {
          attributeArray = ["firstName", "lastName", "roles"];
        } else {
          attributeArray = ["firstName", "lastName", "roles"];
        }
      } else {
        if (isNaN(searchTherapist)) attributeArray = ["name"];
        else {
          attributeArray = ["name"];
        }
      }

      const searchPayload = handleFilterSearch(
        searchTherapist,
        attributeArray,
        "search"
      );
      getAllServices({ ...searchPayload });
    } else getAllServices({});
  };

  useEffect(() => {
    if (type === "Staff") {
      getAllUsers({ roleType: "STAFF" });
    }
    if (type === "Treatment") {
      handleSearchStaff();
    }
  }, [searchTherapist]);

  useEffect(() => {
    getAllUsers({ roleType: "STAFF" });
    getAllServices();
  }, []);

  const toggleStaffSelection = (id) => {
    setSelectedStaffIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return {
    searchTherapist,
    setSearchTherapist,
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    updateQualificationLoader,
    usersData,
    usersDataLoader,
    selectedStaffIds,
    toggleStaffSelection,
    servicesData,
    servicesDataLoader,
  };
}
