import * as Yup from "yup";

export const validationSchema = () => {
  let schema = Yup.object().shape({
    newSales: Yup.boolean(),
    newAppointments: Yup.boolean(),
    newBusyTime: Yup.boolean(),
    mention: Yup.boolean(),
    profileModification: Yup.boolean(),
  });

  return schema;
};
