import * as Yup from "yup";

export const validationSchema = () => {
  let schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    coupon: Yup.string().required("Promo Code is required"),
    tax: Yup.string().required("Tax Rate is required"),
    discount: Yup.number()
      .typeError("Percentage should be a number")
      .required("Percentage Amount is required"),
    maximumLimitPerCostumer: Yup.number()
      .typeError("Limit should be a number")
      .required("Max Limit is required"),
    maximumTotalLimit: Yup.number()
      .typeError("Limit should be a number")
      .required("Max Amount is required"),
    minimumSpend: Yup.number()
      .typeError("Limit should be a number")
      .required("Min Spend is required"),
    maximumDiscountAmount: Yup.number().typeError("Amount should be a number"),
    combineOtherOffer: Yup.boolean(),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
    validForAppointmentStartDate: Yup.string().required(
      "Start Date is required"
    ),
    validForAppointmentEndDate: Yup.string().required("End Date is required"),
    products: Yup.array().of(Yup.string()),
    services: Yup.array().of(Yup.string()),
    staff: Yup.array().of(Yup.string()),
    client: Yup.array().of(Yup.string()),
    serviceCategory: Yup.array().of(Yup.string()),
    productType: Yup.array().of(Yup.string()),
  });

  return schema;
};
