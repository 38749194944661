import SPAAutoComplete from "../../../common/spa-auto-complete";
import SPAInputField from "../../../hook-form/SPAInputField";

export const Products = [
  { label: "Smartphone", phone: "376" },
  { label: "T-Shirt", phone: "971" },
];

export const salesFormData = [
  {
    gridLength: 12,
    otherOptions: {
      fullWidth: true,
      name: "client",
      label: "Client",
      placeholder: "John Doe",
      disabled: true,
    },
    component: SPAInputField,
  },
  {
    gridLength: 12,
    otherOptions: {
      name: "products",
      label: "Select Products",
      placeholder: "Search Product",
      options: Products,
    },
    component: SPAAutoComplete,
  },
];
