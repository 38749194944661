import React from "react";
import { cardStyle, flexItem } from "./style";
import { Box, Typography } from "@mui/material";
import { customColors, fs10, fs12, fs14, fw400, fw600 } from "../../../theme";
import theme from "../../../theme/theme";
import { WarningAmberSharp } from "@mui/icons-material";
export default function SPAContraindatCard({ text, isLatest, updated, key }) {
    const cardStl = cardStyle(customColors, isLatest);

  return (
    <Box sx={cardStl} key={key}>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: "3px", alignItems: "center" }}>
          <WarningAmberSharp sx={{ color: "#876800" }} />
          <Typography fontSize={fs14} fontWeight={fw600} color={"#876800"}>
            Contraindications
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: isLatest === "Current" ? "#E7F6E7" : "#FFF9F0",
            padding: "4px 8px",
            borderRadius: "8px",
          }}
        >
          <Typography
            sx={{
              fontSize: fs10,
              fontWeight: fw400,
              textTransform: "uppercase",
              color: isLatest === "Current" ? "#0D720A" : "#DB8000",
            }}
          >
            {isLatest}
          </Typography>
        </Box>
      </Box>
      <Typography
        fontSize={fs14}
        fontWeight={fw400}
        color={theme.palette.textGraySecondary.main}
      >
        {text}
      </Typography>

      <Box sx={flexItem}>
      
        <Typography fontSize={fs12}>
          {updated === 0 ? "Recently Edited" : `Edited ${updated} ago`}
        </Typography>
      </Box>
    </Box>
  );
}
