import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openNewAppointmentModal: false,
  openEditContraModal: false,
  openCreatedAlertModal: false,
  clientDetails: {},
  createdClientDetails: {},
  singleAppointmentModel: false,
  singleAppointmentData: "",
  calendarCurrentView: "Staff",
  contraindicationData: {},
};

export const openAppointmentModalSlice = createSlice({
  name: "appointmentModal",
  initialState,
  reducers: {
    handleOpenAppointmentModal: (state, action) => {
      state.openNewAppointmentModal = true;
      state.clientDetails = action.payload;
    },
    handleCloseAppointmentModal: (state) => {
      state.openNewAppointmentModal = false;
    },
    handleOpenEditContraModal: (state, action) => {
      state.openEditContraModal = true;
      state.contraindicationData = action.payload;
    },
    handleCloseEditContraModal: (state) => {
      state.openEditContraModal = false;
    },
    handleOpenCreateAppointmentAlert: (state, action) => {
      state.openCreatedAlertModal = true;
      state.createdClientDetails = action.payload;
    },
    handleCloseCreateAppointmentAlert: (state) => {
      state.openCreatedAlertModal = false;
    },
    handleSingleAppointmentModal: (state, action) => {
      state.singleAppointmentModel = !state.singleAppointmentModel;
    },
    handleSingleAppointmentData: (state, action) => {
      state.singleAppointmentData = action.payload;
    },
    handleCalendarCurrentView: (state, action) => {
      state.calendarCurrentView = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handleOpenAppointmentModal,
  handleCloseAppointmentModal,
  handleOpenCreateAppointmentAlert,
  handleCloseCreateAppointmentAlert,
  handleSingleAppointmentModal,
  handleSingleAppointmentData,
  handleCalendarCurrentView,
  handleOpenEditContraModal,
  handleCloseEditContraModal,
} = openAppointmentModalSlice.actions;

export default openAppointmentModalSlice.reducer;
