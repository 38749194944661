import {
  CalendarPlus,
  CalendarX,
  CallBell,
  Newspaper,
  Ticket,
  Trash,
} from "@phosphor-icons/react";

export const profileData = {
  label: "Treatment",
  icon: CallBell,
  link: "treatment",
};

export const removeData = {
  label: "Remove",
  icon: Trash,
};

export const sidebarData = [
  {
    label: "Busy Time",
    icon: CalendarX,
    link: "busy",
  },
  {
    label: "Appointment",
    icon: CalendarPlus,
    link: "appointment",
  },
  {
    label: "Articles",
    icon: Newspaper,
    link: "articles",
  },
  {
    label: "Discount Code",
    icon: Ticket,
    link: "discountCode",
  },
  {
    label: "Deal & Bundles",
    icon: Ticket,
    link: "dealAndBundles",
  },
];
