import {
  Clock,
  MapPin,
  NoteBlank,
  PaperPlaneTilt,
  UserFocus,
} from "@phosphor-icons/react";
import { textareaStyle } from "../../style";
import SPAInputField from "../../../hook-form/SPAInputField";
import SPAMobileDatePicker from "../../../hook-form/SPAMobileDatePicker";
import SPASelectField from "../../../hook-form/SPASelectField";
import SPAEmailCard from "../../../common/spa-email-card";
import { TextField, Typography } from "@mui/material";
import { fs12, fw600 } from "../../../../theme/fontStyles";
import { customColors } from "../../../../theme";
import SPAAutoComplete from "../../../common/spa-auto-complete";

export const clientProfileNavBarData = [
  {
    label: "Overview",
    icon: UserFocus,
    link: "Overview",
  },
  {
    label: "Address",
    icon: MapPin,
    link: "Address",
  },
  {
    label: "Notes",
    icon: NoteBlank,
    link: "AdditionalNotes",
  },
  {
    label: "Appointments & Sales",
    icon: Clock,
    link: "AppointmentSale",
  },
  {
    label: "Email & SMS",
    icon: PaperPlaneTilt,
    link: "EmailSms",
  },
];

export const OverviewElements = (
  friendReferralArray,
  friendFieldEnable,
  onChange
) => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "firstName",
        label: "First Name",
        type: "text",
        placeholder: "First Name",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "lastName",
        label: "Last Name",
        type: "text",
        placeholder: "Last Name",
      },
      component: SPAInputField,
    },

    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "occupation",
        label: "Occupation",
        type: "text",
        placeholder: "i.e.: Accountant",
        labelHelperText: " (Optional)",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "company",
        label: "Company",
        type: "text",
        placeholder: "i-e.: Apple",
        labelHelperText: " (Optional)",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "gender",
        label: "Gender",
        options: [
          { value: "MALE", label: "Male" },
          { value: "FEMALE", label: "Female" },
        ],
        placeholder: "Enter gender",
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "dateOfBirth",
        label: "Date of Birth",
        helperText: " (Optional)",
        disableFuture: true,
        disableHighlightToday: true,
        isCalenderShow: true,
      },
      component: SPAMobileDatePicker,
    },

    {
      gridLength: 6,
      otherOptions: {
        name: "mobileNumber",
        label: "Mobile Number",
        type: "text",
        placeholder: "+61 412 345 678",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "telephone",
        label: "Telephone",
        type: "text",
        placeholder: "",
        labelHelperText: " (Optional)",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        name: "email",
        label: "Email",
        type: "email",
        placeholder: "Enter email",
        labelHelperText: " (Optional)",
      },
      component: SPAInputField,
    },
    {
      gridLength: !friendFieldEnable ? 12 : 6,
      margin: true,
      otherOptions: {
        name: "referralSource",
        label: "Referral Source",
        placeholder: "Select Media",
        options: [
          {
            label: "TV",
            value: "TV",
          },
          {
            label: "SOCIAL_MEDIA",
            value: "SOCIAL_MEDIA",
          },
          {
            label: "FACEBOOK",
            value: "FACEBOOK",
          },
          {
            label: "TWITTER",
            value: "TWITTER",
          },
          {
            label: "GOOGLE",
            value: "GOOGLE",
          },
          {
            label: "FRIEND",
            value: "FRIEND",
          },
        ],
        labelHelperText: " (Optional)",
      },
      component: SPASelectField,
    },
    friendFieldEnable
      ? {
          gridLength: 6,
          margin: true,
          otherOptions: {
            name: "friend",
            label: "Friend",
            placeholder: "Select Friend",
            options: friendReferralArray,
            onChangeText: onChange,
            labelHelperText: " (Optional)",
          },
          component: SPAAutoComplete,
        }
      : { gridLength: 0 },
  ];
};

export const editNotes = (handleAdditionalNotes, notesContent) => {
  return [
    {
      component: TextField,
      otherOptions: {
        fullWidth: true,
        multiline: true,
        value: notesContent.content,
        rows: 5,
        InputLabelProps: { shrink: false },
        InputProps: {
          placeholder: "Add any other additional notes here",
          disableUnderline: true,
          style: {
            fontSize: "14px",
          },
        },
        variant: "standard",
        sx: textareaStyle,
        onChange: handleAdditionalNotes,
      },
    },
  ];
};

export const addNotes = (
  handleAdditionalNoteContent,
  error,
  additionalNoteContent
) => {
  return [
    {
      gridLength: 12,
      componentArray: [
        {
          component: TextField,
          otherOptions: {
            value: additionalNoteContent,
            fullWidth: true,
            multiline: true,
            rows: 3,
            InputLabelProps: { shrink: false },
            InputProps: {
              placeholder: "Add any other additional notes here",
              disableUnderline: true,

              style: {
                fontSize: "14px",
                padding: "3px",
              },
            },
            variant: "standard",
            sx: textareaStyle,
            onChange: handleAdditionalNoteContent,
          },
        },
        error && {
          component: Typography,
          title: "Empty Note not allowed. Provide note in it!",
          otherOptions: {
            fontWeight: fw600,
            fontSize: fs12,
            color: customColors.danger,
            p: "3px 6px",
          },
        },
      ].filter(Boolean),
    },
  ];
};

export const addContraindications = (
  handleContraindicationContent,
  error,
  content
) => {
  return [
    {
      gridLength: 12,
      componentArray: [
        {
          component: TextField,
          otherOptions: {
            value: content,
            fullWidth: true,
            multiline: true,
            rows: 3,
            InputLabelProps: { shrink: false },
            InputProps: {
              placeholder: "Update your contraindication",
              disableUnderline: true,
              style: {
                fontSize: "14px",
                padding: "3px",
              },
            },
            variant: "standard",
            sx: textareaStyle,
            onChange: handleContraindicationContent,
          },
        },
        error && {
          component: Typography,
          title: "Empty Note not allowed. Provide note in it!",
          otherOptions: {
            fontWeight: fw600,
            fontSize: fs12,
            color: customColors.danger,
            p: "3px 6px",
          },
        },
      ].filter(Boolean),
    },
  ];
};

export const EmailElemnts = () => {
  return [
    {
      component: SPAEmailCard,
      otherOptions: {
        content:
          "Figma ipsum component variant main layer. Overflow select create italic blur component content underline connection. Link frame bullet object italic. Layout flows layout bold select line. Font line arrange pencil text group.",

        media: "email",
        date: "Feb 23, 2023",
      },
    },
    {
      component: SPAEmailCard,
      otherOptions: {
        content:
          "Figma ipsum component variant main layer. Overflow select create italic blur component content underline connection. Link frame bullet object italic. Layout flows layout bold select line. Font line arrange pencil text group.",

        media: "email",
        date: "Feb 23, 2023",
      },
    },
    {
      component: SPAEmailCard,
      otherOptions: {
        content:
          "Figma ipsum component variant main layer. Overflow select create italic blur component content underline connection. Link frame bullet object italic. Layout flows layout bold select line. Font line arrange pencil text group.",

        media: "email",
        date: "Feb 23, 2023",
      },
    },
    {
      component: SPAEmailCard,
      otherOptions: {
        content:
          "Figma ipsum component variant main layer. Overflow select create italic blur component content underline connection. Link frame bullet object italic. Layout flows layout bold select line. Font line arrange pencil text group.",

        media: "email",
        date: "Feb 23, 2023",
      },
    },
  ];
};
export const addressElements = (
  countryArray,
  addNewAddress,
  addressesArray
) => {
  const newComponent = {
    id: addNewAddress,
    componentArray: [
      {
        gridLength: 5.8,
        otherOptions: {
          name: "street",
          label: "Street",
          placeholder: "",
        },
        component: SPAInputField,
      },
      {
        gridLength: 5.8,
        otherOptions: {
          name: "suburb",
          label: "Suburb",
          placeholder: "",
        },
        component: SPAInputField,
      },
      {
        gridLength: 6,
        otherOptions: {
          fullWidth: true,
          name: "state",
          label: "State",
          placeholder: "",
          options: [
            {
              label: "New South Wales (NSW)",
              value: "New South Wales (NSW)",
            },
            {
              label: "Queensland (QLD)",
              value: "Queensland (QLD)",
            },
            {
              label: "South Australia (SA)",
              value: "South Australia (SA)",
            },
            {
              label: "Tasmania (TAS)",
              value: "Tasmania (TAS)",
            },
            {
              label: "Victoria (VIC)",
              value: "Victoria (VIC)",
            },
            {
              label: "Western Australia (WA)",
              value: "Western Australia (WA)",
            },
            {
              label: "Australian Capital Territory (ACT)",
              value: "Australian Capital Territory (ACT)",
            },
            {
              label: "Northern Territory (NT)",
              value: "Northern Territory (NT)",
            },
          ],
        },
        component: SPASelectField,
      },
      {
        gridLength: 5.8,
        otherOptions: {
          name: "postalCode",
          label: "Post Code",
          placeholder: "",
        },
        component: SPAInputField,
      },
      // {
      //   gridLength: 6,
      //   otherOptions: {
      //     name: "country",
      //     label: "Country",
      //     options: countryArray,
      //     placeholder: "",
      //     defaultValue: "Australia",
      //   },
      //   component: SPASelectField,
      // },
    ],
  };

  return addNewAddress > addressesArray.length
    ? [...addressesArray, newComponent]
    : addressesArray;
};
