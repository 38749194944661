import { Box, Grid, Typography } from "@mui/material";
import { Package } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import ProfessionalsProductListing from "./professional-product-listing";
import SPALinearProgress from "../../../common/spa-linear-progress";
import { useGetProductsMutation } from "../../../../services/product";
import { handleFilterSearch } from "../../../../utils/searchFilter";
import NoNotifications from "../../../../assets/svg/no-notifications";
import { handleOpenProfessionalProductViewModal } from "../../../../store/features/products";
import { useDispatch } from "react-redux";

const ProfessionalProducts = () => {
  const dispatch = useDispatch();
  const [
    getStockProducts,
    { data: productStockData, isLoading: productStockDataLoader },
  ] = useGetProductsMutation();

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      ["type=PROFESSIONAL_PRODUCT"],
      ["type"],
      "filter"
    );
    getStockProducts({ ...searchPayload });
  }, []);

  return (
    <Grid container columnGap={2}>
      <Grid
        item
        sm={12}
        md={3.8}
        sx={{ bgcolor: "#FFFFFF", p: "16px", borderRadius: "8px" }}
      >
        <Typography sx={{ color: "#5E6062", fontSize: "24px" }}>
          Stock Status
        </Typography>
        <Grid item spacing={2} sx={{ maxHeight: "70vh", overflowY: "auto" }}>
          {productStockData?.result?.data?.data?.length > 0 &&
            productStockData?.result?.data?.data?.map((item) => {
              const totalStock = item?.stock || 0;
              const usedStock = 0;
              const availableStock = totalStock - usedStock;
              const stockPercentage = (availableStock / totalStock) * 100;
              return (
                <Grid
                  key={item?._id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    my: 1,
                    bgcolor: "#F8F7FE",
                    p: "8px",
                    borderRadius: "8px",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(
                      handleOpenProfessionalProductViewModal({
                        singleProductId: item?._id,
                        getAllProducts: () => {},
                        getStockProducts: () => {},
                      })
                    );
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ color: "#716AEA", fontSize: "10px" }}>
                      {item?.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Package size={12} color="#8F9193" />
                      <Typography sx={{ color: "#5E6062", fontSize: "10px" }}>
                        {item?.stock}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%", my: 1 }}>
                    <SPALinearProgress
                      value={stockPercentage}
                      color="#4E45E5"
                    />
                  </Box>
                </Grid>
              );
            })}
          {productStockData?.result?.data?.data?.length === 0 && (
            <Box
              sx={{
                mt: "18px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                gap: "24px",
              }}
            >
              <NoNotifications />
              <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                No Stock Yet
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                After creating Product, they will be displayed here as Stock.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        md={8}
        sx={{ bgcolor: "#FFFFFF", p: "16px", borderRadius: "8px" }}
      >
        <ProfessionalsProductListing
          getStockProducts={getStockProducts}
          productStockDataLoader={productStockDataLoader}
        />
      </Grid>
    </Grid>
  );
};

export default ProfessionalProducts;
