import { Button, Grid } from "@mui/material";
import React from "react";
import { UseRequirement } from "./use-requirements";
import AddQualification from "../../../../../add-qualification";
import ProductStep from "../../../../../product-step";
import FormProvider from "../../../../../../../hook-form/FormProvider";
import { customColors, secondary } from "../../../../../../../../theme";
import TreatmentInfo from "../../../../../treatment-info";
import theme from "../../../../../../../../theme/theme";

export default function Requirements({
  singleServiceData,
  singleServiceRefetching,
}) {
  const {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    allProductData,
    productDataLoading,
    qualificationsData,
    qualificationsDataLoader,
    searchQualification,
    setSearchQualification,
    // Product section
    searchProduct,
    setSearchProduct,
    activeItem,
    setActiveItem,
    isSearchBox,
    setIsSearchBox,
    selectedProduct,
    handleAddProduct,
    handleQuantityChange,
    serviceCategoryData,
    productCategoryData,
    toggleSwitch,
    handleRemove,
    handleRequiredOptional,
    getMeasurementAbbreviation,
    productTypeData,
    setActiveHistoryTab,
    handleRemoveProduct,
  } = UseRequirement({ singleServiceData, singleServiceRefetching });

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <AddQualification
            errors={errors}
            qualificationsDataLoader={qualificationsDataLoader}
            qualificationsData={qualificationsData}
            searchQualification={searchQualification}
            setSearchQualification={setSearchQualification}
            control={control}
            methods={methods}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={12}>
            <ProductStep
              handleRequiredOptional={handleRequiredOptional}
              handleQuantityChange={handleQuantityChange}
              handleAddProduct={handleAddProduct}
              selectedProduct={selectedProduct}
              allProductData={allProductData}
              productDataLoading={productDataLoading}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
              isSearchBox={isSearchBox}
              setIsSearchBox={setIsSearchBox}
              setSearchProduct={setSearchProduct}
              searchProduct={searchProduct}
              errors={errors}
              productCategoryData={productCategoryData?.result?.data?.data}
              handleRemove={handleRemove}
              toggleSwitch={toggleSwitch}
              getMeasurementAbbreviation={getMeasurementAbbreviation}
              isRequirements={true}
              setActiveHistoryTab={setActiveHistoryTab}
              handleRemoveProduct={handleRemoveProduct}
            />
            {/* <ProductStep
              handleQuantityChange={handleQuantityChange}
              handleAddProduct={handleAddProduct}
              selectedProduct={selectedProduct}
              allProductData={allProductData}
              productDataLoading={productDataLoading}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
              isSearchBox={isSearchBox}
              setIsSearchBox={setIsSearchBox}
              setSearchProduct={setSearchProduct}
              searchProduct={searchProduct}
              errors={errors}
              isRequirements={true}
              productCategoryData={productCategoryData?.result?.data?.data}
            /> */}
          </Grid>
          {/* <Grid item xs={12} md={12} sx={{ mt: 1 }}>
            <TreatmentInfo
              isView={true}
              serviceCategoryData={serviceCategoryData}
              control={control}
            />
          </Grid> */}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="submit"
            sx={{
              backgroundColor: theme.palette.primaryColor.main,
              position: "relative",
              color: theme.palette.white.main,
              flexShrink: 0,
              paddingInline: "24px",
              paddingBlock: "12px",
              borderRadius: "8px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: theme.palette.primaryColor.dark,
              },
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
