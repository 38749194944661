import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQueryCountries = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_COUNTRIES_URL,
  prepareHeaders: (headers, { getState }) => {

    return headers;
  },
});

export const countriesBaseAPI = createApi({
  reducerPath: "countriesBaseAPI",
  baseQuery: baseQueryCountries,
  // tagTypes: TAGS,
  endpoints: () => ({}),
});
