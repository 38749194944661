import { baseAPI } from "../baseApi";

export const HistoryApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllHistory: builder.query({
      query: ({ type, searchQuery }) => ({
        url: `/history?type=${type}&search=${searchQuery}`,
      }),
      providesTags: ["History"],
    }),
    getSingleHistory: builder.query({
      query: (historyId) => ({ url: "/history/" + historyId }),
      providesTags: ["singleHistory"],
    }),
    getHistory: builder.mutation({
      query: (payload) => {
        return {
          url: `/history`,
          method: "PATCH",
          body: payload,
        };
      },
      providesTags: ["AllHistory"],
    }),
  }),
});

export const {
  useGetAllHistoryQuery,
  useGetSingleHistoryQuery,
  useGetHistoryMutation,
} = HistoryApi;
