import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useUploadFileMutation } from "../../../services/upload";
import BlockContent from "./block-content";
import { Image, Minus, Warning } from "@phosphor-icons/react";
import { fw600 } from "../../../theme";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme, hasImage }) => ({
  outline: "none",
  padding: "25px 10px",
  position: "relative",
  "&:hover": {
    border: hasImage ? "none" : "2px solid #4E45E5",
  },
}));

const DefaultView = () => (
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image style={{ fontSize: 48, marginBottom: 16 }} color="#4E45E5" />
      <Typography
        variant="body1"
        align="center"
        sx={{ color: "#000000", fontWeight: fw600 }}
      >
        Drag your file(s) to change image
      </Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
      }}
    >
      <Minus size={32} color="#fafafa" />
      <Typography sx={{ color: "#424243", fontWeight: fw600 }}>OR</Typography>
      <Minus size={32} color="#fafafa" />
    </Box>
    <Box>
      <Button
        variant="contained"
        sx={{
          color: "#4E45E5",
          bgcolor: "#ffffff",
          fontWeight: fw600,
          "&:hover": {
            backgroundColor: "#4E45E5",
            color: "#ffffff",
          },
        }}
      >
        Browse Files
      </Button>
    </Box>
  </Box>
);

const Overlay = styled("Box")({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  opacity: 0,
  transition: "opacity 0.3s ease-in-out",
  "&:hover": {
    opacity: 1,
  },
  borderRadius: "8px",
  border: "2px solid #4E45E5",
});

const SPAImageUpload = ({
  error,
  files,
  onRemove,
  onRemoveAll,
  helperText,
  customContent,
  fileUpload,
  sx,
  initialSrc,
  height = "196px",
  ...other
}) => {
  const [filesUpload, setFilesUpload] = useState("");
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageUpload = async (file) => {
    const files = file[0];
    if (files) {
      if (files.size > 2 * 1024 * 1024) {
        setErrorMessage("File size exceeds 2MB");
        return;
      }

      const formData = new FormData();
      formData.append("file", files);
      const response = await uploadFile(formData);
      const resp = response.data;
      if (resp) {
        setFilesUpload(resp.result.data.url);
        fileUpload(resp.result.data.url);
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      handleImageUpload(acceptedFiles);
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        if (file.errors.some((e) => e.code === "file-too-large")) {
          setErrorMessage("File size exceeds 2MB");
        } else if (file.errors.some((e) => e.code === "file-invalid-type")) {
          setErrorMessage(
            "Invalid file type. Only PNG, JPG, JPEG are allowed."
          );
        }
      });
    },
    maxSize: 2 * 1024 * 1024, // 2MB
    ...other,
  });

  useEffect(() => {
    setFilesUpload(initialSrc);
  }, [initialSrc]);

  return (
    <DropZoneStyle
      {...getRootProps()}
      hasImage={!!filesUpload}
      sx={{
        borderRadius: "8px",
        backgroundColor: filesUpload?.length > 0 ? "transparent" : "#FFFFFF",
        border: filesUpload?.length > 0 ? "none" : `1px dashed #4E45E5`,
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative", // Ensure the overlay is positioned relative to this container
        "&:hover": { opacity: 0.72, cursor: "pointer" },
        ...(isDragActive && { opacity: 0.72 }),
        ...((isDragReject || error) && {
          color: "error.main",
          borderColor: "error.light",
          bgcolor: "error.lighter",
        }),
        ...sx,
      }}
    >
      <input {...getInputProps()} />
      {isLoading &&
        (filesUpload === undefined || filesUpload?.length === 0) && (
          <Box
            sx={{
              height: height,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
      {customContent ??
        ((filesUpload === undefined || filesUpload?.length === 0) &&
          !isLoading && (
            <BlockContent filesUpload={filesUpload} height={height} />
          ))}
      {filesUpload !== undefined && filesUpload?.length > 0 && !isLoading && (
        <Box position="relative" width="100%" height={height}>
          <img
            srcSet={filesUpload}
            src={filesUpload}
            alt={"item.title"}
            loading="lazy"
            style={{ height: "100%", width: "100%", borderRadius: "8px" }}
          />
          {/* Overlay */}
          <Overlay>
            <DefaultView />
          </Overlay>
        </Box>
      )}
      {errorMessage && (
        <Typography
          sx={{
            color: "#FFAA33",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            mt: "5px",
            justifyContent: "center",
          }}
          color="error"
        >
          <Warning color={"#FFAA33"} size={16} />
          {errorMessage}
        </Typography>
      )}
    </DropZoneStyle>
  );
};

export default SPAImageUpload;
