import { baseAPI } from "../baseApi";

export const ArticlesApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllArticles: builder.mutation({
      query: (body) => ({ url: "/article", method: "PATCH", body }),
      providesTags: ["AllArticles"],
    }),

    getSingleArticle: builder.query({
      query: (id) => ({ url: `/article/${id}` }),
      providesTags: ["singleArticle"],
    }),

    updateSingleArticle: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/article/${id}`,
        body: payload,
        method: "PATCH",
      }),
      invalidatesTags: ["AllArticles", "singleArticle"],
    }),

    deleteArticle: builder.mutation({
      query: (articleId) => ({
        url: "/article/" + articleId,
        method: "DELETE",
      }),
      providesTags: ["AllArticles"],
    }),

    createArticle: builder.mutation({
      query: (payload) => {
        return {
          url: `/article`,
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useGetAllArticlesMutation,
  useGetSingleArticleQuery,
  useUpdateSingleArticleMutation,
  useCreateArticleMutation,
  useDeleteArticleMutation,
} = ArticlesApi;
