import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import AppLogo from '../../assets/images/outline-dark-logo.png';
import SideImg from '../../assets/images/side-img-spa.png';
import { customColors } from '../../theme';

const AuthLayout = ({ heading, subHeading, children }) => {
  return (
    <Grid container>
      <Grid item xl={4} md={6} xs={12}>
        <Box
          sx={{
            background: '#EDECFC',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: { md: '52px', xs: '40px' },
            px: '30px',
            boxShadow:
              '-3px 2px 8px 0px rgba(78, 69, 229, 0.05), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -66px 62px 25px 0px rgba(78, 69, 229, 0.00)',
          }}
        >
          <img src={AppLogo} alt='app-logo' />
          <Box
            sx={{
              p: { md: '48px', sm: '40px', xs: '20px' },
              width: { md: '458px', sm: '400px', xs: '100%' },
              background: customColors.white,
              borderRadius: '24px',
              boxShadow:
                '-3px 2px 8px 0px rgba(78, 69, 229, 0.05), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -66px 62px 25px 0px rgba(78, 69, 229, 0.00)',
            }}
          >
            <Box sx={{ pb: '40px', textAlign: 'center' }}>
              <Typography
                sx={{
                  color: '#4A4C4F',
                  fontFamily: '"Open Sans"',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >
                {heading}
              </Typography>
              <Typography
                sx={{
                  color: '#4A4C4F',
                  fontFamily: '"Open Sans"',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  letterSpacing: '0.08px',
                }}
              >
                {subHeading}
              </Typography>
            </Box>
            {children}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xl={8}
        md={6}
        sx={{
          display: { xs: 'none', md: 'block' },
          backgroundImage: `url(${SideImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
        }}
      ></Grid>
    </Grid>
  );
};

export default AuthLayout;
