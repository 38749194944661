import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Plus, X } from "@phosphor-icons/react";
import React from "react";
import NoClients from "../../../assets/svg/no-clients";
import { secondary } from "../../../theme";
import SPAAlertDialog from "../../common/spa-alert-dialogue";
import SPAPopover from "../../common/spa-popover";
import SPATable from "../../common/spa-table";
import SPATextField from "../../common/spa-textField";
import CreateProductTypeFormData from "./create-product-type";
import { productTypePopOverData } from "./product-type-data";
import useProductType from "./useProductType";
import UpdateProductDialog from "../product/retails-products/retails-product-listing/update-product-dialog";
/**
 * ProductTypeSection component.
 *
 * @returns {JSX.Element} JSX code for rendering the ProductTypeSection component.
 */
const ProductTypeSection = ({ getAllProducts, type = "RETAIL_PRODUCT" }) => {
  const {
    singleRoleId,
    setSingleRoleId,
    productTypeTableHeader,
    isRolesDialogOpen,
    setIsRolesDialogOpen,
    rolesPopoverOpen,
    setRolesPopoverOpen,
    rolesPopOverId,
    openRolesPopOver,
    handleNewRole,
    rolesData,
    rolesDataLoader,
    currentPage,
    setCurrentPage,
    isOpenSuccessDialog,
    setIsOpenSuccessDialog,
    handleCloseSuccessPopup,
    handleAddRole,
    searchRole,
    setSearchRole,
    isOpenAddRoleDialog,
    setIsOpenAddRoleDialog,
    getAllProductType,
  } = useProductType({ getAllProducts, type });

  return (
    <Box sx={{ minWidth: "688px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            disableElevation
            variant="contained"
            sx={{
              background: secondary.main,
              display: "flex",
              gap: "8px",
              alignItems: "center",
              borderRadius: "8px",
              height: "48px",
              "&:hover": { background: secondary.main },
            }}
            onClick={handleNewRole}
          >
            <Plus size={24} color="currentColor" />
            Create Product Type
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            flexWrap: "wrap",
          }}
        >
          <SPATextField
            placeholder="Search"
            value={searchRole}
            sx={{
              width: { md: "328px", xs: "180px" },
              "& .MuiOutlinedInput-root": {
                height: "48px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                  <MagnifyingGlass size={24} color={"#C1C2C3"} />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ paddingLeft: "10px", cursor: "pointer" }}
                  onClick={() => {
                    setSearchRole("");
                  }}
                >
                  {searchRole && <X size={24} />}
                </InputAdornment>
              ),
            }}
            onChangeValue={(event) => {
              setSearchRole(event.target.value);
            }}
          />
        </Box>
      </Box>
      <Box sx={{ pt: "16px" }}>
        {rolesDataLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={rolesDataLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {rolesData?.result?.data?.data?.length > 0 && !rolesDataLoader && (
          <SPATable
            data={rolesData?.result?.data?.data}
            columns={productTypeTableHeader}
            totalRecords={rolesData?.result?.data?.totalRecords}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
        {rolesData?.result?.data?.data?.length === 0 && !rolesDataLoader && (
          <Box
            sx={{
              mt: "18px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <NoClients />
            <Typography sx={{ fontSize: "20px", color: "#595959" }}>
              There’s no Product Type yet
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#595959" }}>
              After creating Product Type, they will be displayed here as
              Product Type.
            </Typography>
          </Box>
        )}
      </Box>

      {isRolesDialogOpen && (
        <CreateProductTypeFormData
          getAllProductType={getAllProductType}
          setSingleRoleId={setSingleRoleId}
          setIsOpenSuccessDialog={setIsOpenSuccessDialog}
          singleRoleId={singleRoleId}
          isNewRoleOpen={isRolesDialogOpen}
          handleClose={(data) => {
            setIsRolesDialogOpen(false);
            setSingleRoleId(null);
          }}
        />
      )}
      {isOpenAddRoleDialog && (
        <UpdateProductDialog
          setIsOpenSuccessDialog={setIsOpenSuccessDialog}
          singleQualificationId={singleRoleId}
          isAddQualificationOpen={isOpenAddRoleDialog}
          handleClose={(data) => {
            setIsOpenAddRoleDialog(false);
            setSingleRoleId(null);
            getAllProducts();
            getAllProductType();
          }}
          dialogTitle={`Add ‘Product Type’ to a Retail Product`}
          contentTitle={`Retail Products`}
          subContentDescription={`Please select the Retail Products you want to add this product type to.`}
          type={"Product"}
          submitText="Change Product Type"
        />
      )}

      {openRolesPopOver && (
        <SPAPopover
          id={rolesPopOverId}
          anchorEl={rolesPopoverOpen}
          width="320px"
          isOpen={openRolesPopOver}
          transformHorizontal="right"
          handleClose={(data) => {
            setRolesPopoverOpen(null);
          }}
          data={productTypePopOverData(
            setRolesPopoverOpen,
            setIsRolesDialogOpen,
            setIsOpenAddRoleDialog
          )}
        />
      )}
      {isOpenSuccessDialog && (
        <SPAAlertDialog
          open={isOpenSuccessDialog}
          handleClose={handleCloseSuccessPopup}
          handleNewTab={handleAddRole}
          title={"Product Type Created"}
          contentText={`Do you want to add this Product Type to a Retail Product?`}
          disagreeText={`Change Product Type`}
          agreeText={`Close`}
        />
      )}
    </Box>
  );
};

export default ProductTypeSection;
