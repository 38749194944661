import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ArrowFatLinesDown } from "@phosphor-icons/react";

const CustomSnackbar = ({ open, message, onClose }) => {
  if (!open) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "0%",
        left: "48%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#2f2989a9",
        zIndex: 1,
        borderRadius: "8px",
        width: "240px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px 16px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: "#ffffff",
        }}
      >
        {message}
      </Typography>
      <ArrowFatLinesDown size={16} color="#ffffff" />
    </Box>
  );
};

export default CustomSnackbar;
