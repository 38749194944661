export const cancelBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.light,
  color: theme.palette.primaryColor.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.lightHover,
  },
});
export const SubmitBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.main,
  position: "relative",
  color: theme.palette.white.main,
  flexShrink: 0,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.dark,
  },
});
export const ContinueBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.main,
  color: theme.palette.white.main,
  flexShrink: 0,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  width: { sm: "260px", lg: "280px" },
  mb: "20px",
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.dark,
  },
});
