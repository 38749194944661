import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useSendClientEmailMutation,
  useSendClientSMSMutation,
  useGetAllClientsMutation,
} from "../../../services/clients";
import {
  useGetAllHistoryQuery,
  useGetSingleHistoryQuery,
} from "../../../services/history";
import {
  useCreateTemplateMutation,
  useGetAllTemplatesQuery,
  useGetSingleTemplateQuery,
} from "../../../services/templates";
import { validationSchema } from "./validationSchema";
import { handleFilterSearch } from "../../../utils/searchFilter";

export default function useHistory() {
  const editorRef = useRef([]);
  const [searchHistory, setSearchHistory] = useState("");
  const [historyType, setHistoryType] = useState("");
  const [activeHistoryTab, setActiveHistoryTab] = useState(0);
  const [historyDataType, setHistoryDataType] = useState("");
  const [isSuccessMsg, setIsSuccessMsg] = useState(false);
  const [historyId, setHistoryId] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const validationSchemaInstance = validationSchema(historyType);
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
  });
  const {
    data: historyData,
    isLoading: historyDataLoader,
    refetch: historyDataRefetching,
  } = useGetAllHistoryQuery({
    type: historyDataType,
    searchQuery: searchHistory,
  });
  const [createTemplate, { isLoading: createCommunicationLoader }] =
    useCreateTemplateMutation();
  const { data: singleHistory, isLoading: singleHistoryLoader } =
    useGetSingleHistoryQuery(historyId, {
      skip: !historyId,
    });
  const [getAllClients, { data: clientsData }] = useGetAllClientsMutation();
  const { data: templatesData, isLoading: templatesDataLoader } =
    useGetAllTemplatesQuery(historyType);
  const { data: singleTemplate, isLoading: singleTemplateLoader } =
    useGetSingleTemplateQuery(templateId, {
      skip: !templateId,
    });
  const [sendEmail, { data: sendEmailData, isLoading: sendEmailLoader }] =
    useSendClientEmailMutation();
  const [sendSMS, { data: sendSMSData, isLoading: sendSMSLoader }] =
    useSendClientSMSMutation();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const { template, client } = watch();

  useEffect(() => {
    if (client) {
      const filteredData = filterEmailAndMobile(
        client,
        clientsData?.result?.data?.data
      );
      setValue("recipients", filteredData[0]?.email);
      setValue("phoneNumber", filteredData[0]?.mobileNumber);
    }
  }, [client]);

  useEffect(() => {}, []);

  // Function to filter out email and mobileNumber
  const filterEmailAndMobile = (clientId, clientsData) => {
    return clientsData?.filter((client) => {
      return client?._id === clientId;
    });
  };

  const onSubmit = async (values) => {
    setIsSuccessMsg(false);
    let payload;
    if (historyType === "SMS") {
      payload = {
        phoneNumber: values?.phoneNumber,
        message: values?.message,
        savePhoneNumber: values?.savePhoneNumber,
      };
    }
    if (historyType === "EMAIL") {
      payload = {
        recipients: [values?.recipients],
        message: values?.message,
        subject: values?.subject,
        saveEmail: values?.saveEmail,
      };
    }
    let response;
    if (historyType === "EMAIL") {
      response = await sendEmail({ id: values?.client, payload });
    } else {
      response = await sendSMS({ id: values?.client, payload });
    }
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    setIsSuccessMsg(true);
    historyDataRefetching();
    reset({
      client: "",
      recipients: [],
      message: "",
      subject: "",
      saveEmail: false,
      phoneNumber: "",
      savePhoneNumber: false,
    });
  };

  const handleTabSelection = (item) => {
    setActiveHistoryTab(item);
    switch (item) {
      case 1:
        setHistoryType("EMAIL");
        setHistoryDataType("EMAIL");
        break;
      case 2:
        setHistoryType("SMS");
        setHistoryDataType("SMS");
        break;
      default:
        setHistoryType("");
        setHistoryDataType("");
        break;
    }
  };

  const handleSingleHistory = (item) => {
    setHistoryId(item?._id);
    setHistoryType(item?.type);
  };
  const handleCreateCommunication = () => {
    reset({
      client: "",
      recipients: [],
      message: "",
      subject: "",
      saveEmail: false,
      phoneNumber: "",
      savePhoneNumber: false,
    });
    setHistoryId("");
  };

  const handleSearchHistory = () => {
    let attributeArray = [];
    if (searchHistory !== "") {
      if (isNaN(searchHistory)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchHistory,
        attributeArray,
        "search"
      );
      // getAllHistory({ ...searchPayload });
    } else {
      // getAllHistory({});
    }
  };

  useEffect(() => {
    // handleSearchHistory();
    historyDataRefetching();
  }, [searchHistory]);

  useEffect(() => {
    if (singleHistory && historyId) {
      const singleHistoryFormat = singleHistory?.result?.data[0];
      // const singleHistoryFormat = singleHistory?.result?.data;
      setValue("client", singleHistoryFormat?.clientId ?? "");
      setValue("message", singleHistoryFormat?.message);
      // if (singleHistoryFormat?.type === "EMAIL") {
      setValue(
        "recipients",
        singleHistoryFormat?.recipients && singleHistoryFormat?.recipients[0]
      );
      setValue("subject", singleHistoryFormat?.subject);
      setValue("saveEmail", singleHistoryFormat?.saveEmail);
      // } else {
      setValue("savePhoneNumber", singleHistoryFormat?.savePhoneNumber);
      setValue("phoneNumber", singleHistoryFormat?.phoneNumber);
      // }
    } else {
      setValue("message", "");
      setValue("subject", "");
      // setValue("replyToEmails", "");
      setValue("recipients", "");
      // setValue("replyToSms", "");
      setValue("savePhoneNumber", false);
      setValue("phoneNumber", "");
    }
  }, [singleHistory, historyId]);

  useEffect(() => {
    if (singleTemplate) {
      const singleTemplateFormat = singleTemplate?.result?.data;
      setValue("message", singleTemplateFormat?.message);
      if (singleTemplateFormat?.type === "EMAIL") {
        setValue(
          "recipients",
          singleTemplateFormat?.recipients &&
            singleTemplateFormat?.recipients[0]
        );
        setValue("subject", singleTemplateFormat?.subject);
        setValue("saveEmail", singleTemplateFormat?.saveEmail);
      } else {
        setValue("savePhoneNumber", singleTemplateFormat?.savePhoneNumber);
        setValue("phoneNumber", singleTemplateFormat?.phoneNumber);
      }
    }
  }, [singleTemplate]);

  useEffect(() => {
    setTemplateId(template);
  }, [template]);

  useEffect(() => {
    getAllClients({ roleType: "CLIENT" });
  }, []);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    isSuccessMsg,
    setIsSuccessMsg,
    createCommunicationLoader,
    historyData,
    singleHistoryLoader,
    historyDataLoader,
    activeHistoryTab,
    historyType,
    setHistoryType,
    handleTabSelection,
    historyId,
    handleSingleHistory,
    handleCreateCommunication,
    reset,
    editorRef,
    clientsData,
    templatesData,
    searchHistory,
    setSearchHistory,
  };
}
