import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  CssBaseline,
  Drawer,
  Grid,
  List,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import {
  CalendarPlus,
  CreditCard,
  Gift,
  Lightning,
  MagnifyingGlass,
  SignOut,
  User,
  UserPlus,
} from "@phosphor-icons/react";
import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import AppLogo from "../../assets/images/app-logo.png";
import AvatarUserDetails from "../../components/avatar-user-details";
import SPAPopover from "../../components/common/spa-popover";
import SPASideBarListButton from "../../components/common/spa-sidebar-list-button";
import { secondary } from "../../theme";
import { permissionData, routePermissions, sidebarData } from "./sidebar.data";
import { SPADrawer } from "./sidebar.styles";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage";
import { nameInitialsAvatar } from "../../utils/nameInitialsAvatar";
import { useDispatch, useSelector } from "react-redux";
import {
  handleOpenAppointmentModal,
  handleCloseAppointmentModal,
  handleCloseCreateAppointmentAlert,
} from "../../store/features/new-appointment";
import NewAppointmentForm from "../../components/new-appointment-form";
import SPAAlertDialog from "../../components/common/spa-alert-dialogue";
import { NewClientForm } from "../../components/new-client-form";
import NotificationPanel from "../../components/notification-panel";
import BellIcon from "../../assets/svg/bell-icon";
import GlobalSearch from "../../components/global-search";
import RetailsProductView from "../../components/resource-management/product/retails-products/retails-product-listing/retails-product-view";
import UserProfileView from "../../components/user-profile";
import { handleOpenUserProfileViewModal } from "../../store/features/user-profile";
import { useGetBusinessLocationsQuery } from "../../services/business-location";
import ProfessionalProductView from "../../components/resource-management/product/professional-products/professional-product-listing/professional-product-view";
import { handleOpenSalesModal } from "../../store/features/sales";
import CreateSalesDialogue from "../../components/sales/create-sales";
/**
 * DashboardLayout component.
 * Layout component for the dashboard page.
 * Manages the layout including sidebar, app bar, user profile, and main content.
 *
 * @returns {JSX.Element} JSX code for rendering the DashboardLayout component.
 */

export default function DashboardLayout() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(location.pathname);
  const isSidebarResponsive = useMediaQuery("(max-width:1165px)");
  const isSmallResponsive = useMediaQuery("(max-width:400px)");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [profilePopoverOpen, setProfilePopoverOpen] = useState(null);
  const [quickPopoverOpen, setQuickPopoverOpen] = useState(null);
  const [isClientDialogOpen, setIsClientDialogOpen] = useState(false);
  const [isAlertDialogOpen, setAlertDialogOpen] = useState(false);
  const [clientFullName, setClientFullName] = useState("");
  const [newClientId, setNewClientID] = useState("");
  const [searchModel, setSearchModel] = useState(false);
  const [notifyPanelIsOpen, setNotifyPaneIsOpen] = useState(false);
  const {
    data: getAllBusinessLocation,
    isLoading: getAllBusinessLocationLoader,
  } = useGetBusinessLocationsQuery();

  const userDetails = getLocalStorageItem("userData");

  const openAppointmentModal = useSelector(
    (state) => state?.appointmentModal?.openNewAppointmentModal
  );
  const openAppointmentAlert = useSelector(
    (state) => state?.appointmentModal.openAppointmentAlert
  );
  const createdClientDetail = useSelector(
    (state) => state?.appointmentModal.createdClientDetails
  );

  const openRetailProductViewModal = useSelector(
    (state) => state?.retailProducts?.openRetailProductViewModal
  );

  const openProfessionalProductViewModal = useSelector(
    (state) => state?.retailProducts?.openProfessionalProductViewModal
  );

  const openUserProfileViewModal = useSelector(
    (state) => state?.userProfile?.openUserProfileViewModal
  );
  const openNewSalesModal = useSelector(
    (state) => state?.salesModal?.openNewSalesModal
  );

  const profileOpenPopOver = Boolean(profilePopoverOpen);
  const profilePopOverId = profileOpenPopOver ? "simple-popover" : undefined;

  const quickOpenPopOver = Boolean(quickPopoverOpen);
  const quickPopOverId = quickOpenPopOver ? "simple-popover" : undefined;

  const activeUserDetail = getLocalStorageItem("userData");

  const avatarIcon = () => {
    const firstName = activeUserDetail?.userName;
    const lastName = "Test"; // update it with original name
    const initials = nameInitialsAvatar(`${firstName} ${lastName}`);

    return activeUserDetail && activeUserDetail?.profileUserImage ? (
      <Avatar
        sx={{ width: "48px", height: "48px" }}
        src={activeUserDetail?.profileUserImage}
        variant="rounded"
      />
    ) : (
      <Avatar variant="rounded" src={initials.src} sx={initials.sx} />
    );
  };

  //Profile popover mock data
  const profilePopOverData = [
    { id: "1", component: <AvatarUserDetails popOverDetails /> },
    {
      id: "2",
      label: "View Profile",
      icon: User,
      onClick: () => {
        dispatch(
          handleOpenUserProfileViewModal({
            singleProductId: "123",
            getAllProducts: () => {},
          })
        );
        setProfilePopoverOpen(null);
      },
    },
    {
      id: "3",
      label: "Logout",
      icon: SignOut,
      onClick: () => {
        setProfilePopoverOpen(null);
        removeLocalStorageItem("userData");
        removeLocalStorageItem("auth-token");
        removeLocalStorageItem("businessLocation");
        navigate("/login");
      },
    },
  ];

  //Quick actions popover mock data
  const quickPopOverData = [
    {
      id: "1",
      label: "Create Client",
      icon: UserPlus,
      onClick: () => {
        setQuickPopoverOpen(null);
        setIsClientDialogOpen(true);
      },
    },
    {
      id: "2",
      label: "Create Appointment",
      icon: CalendarPlus,
      onClick: () => {
        setQuickPopoverOpen(null);
        dispatch(handleOpenAppointmentModal());
      },
    },
    {
      id: "3",
      label: "Create Sale",
      icon: CreditCard,
      onClick: () => {
        dispatch(handleOpenSalesModal({ getAllSales: () => {} }));
        setQuickPopoverOpen(null);
      },
    },
    {
      id: "4",
      label: "Create Gift Voucher",
      icon: Gift,
      onClick: () => {
        setQuickPopoverOpen(null);
      },
    },
  ];

  /**
   * Handles the action toggle Drawer.
   */
  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleNotifyPanel = () => {
    setNotifyPaneIsOpen(true);
  };

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    setLocalStorageItem("isNotifyOpen", notifyPanelIsOpen);
  }, [notifyPanelIsOpen]);
  const combinedPermissions = permissionData.concat(
    userDetails?.rolesDetails?.permissions
  );
  const finalSidebarData =
    userDetails?.role === "SUPER_ADMIN"
      ? sidebarData
      : sidebarData?.filter((item) =>
          combinedPermissions?.includes(routePermissions[item.link])
        );

  useEffect(() => {
    if (getAllBusinessLocation?.result?.data) {
      setLocalStorageItem(
        "businessLocation",
        getAllBusinessLocation?.result?.data
      );
    }
  }, [getAllBusinessLocation?.result?.data]);

  /**
   * Drawer Content.
   */
  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: [1],
            pb: "40px",
          }}
        >
          <img
            src={AppLogo}
            alt="app-logo"
            onClick={handleToggleDrawer}
            style={{ width: isDrawerOpen ? "auto" : "79px", cursor: "pointer" }}
          />
        </Toolbar>
        <List component="nav">
          {finalSidebarData?.map((item, index) => {
            return (
              <Fragment key={index}>
                {item?.toShow?.includes(userDetails?.role) && (
                  <SPASideBarListButton
                    item={item}
                    activeItem={activeItem}
                    isDrawerOpen={isDrawerOpen}
                    onClick={() => {
                      navigate(item.link);
                      setActiveItem(item.link);
                    }}
                  />
                )}
              </Fragment>
            );
          })}
        </List>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SPASideBarListButton
          isProfile={false}
          item={{
            label: "Quick Action",
            icon: Lightning,
          }}
          activeItem={activeItem}
          isDrawerOpen={isDrawerOpen}
          onClick={(e) => setQuickPopoverOpen(e.currentTarget)}
        />
        <SPASideBarListButton
          isProfile={false}
          item={{
            label: "Search",
            icon: MagnifyingGlass,
          }}
          activeItem={activeItem}
          isDrawerOpen={isDrawerOpen}
          onClick={() => {
            setSearchModel(true);
          }}
        />
        <SPASideBarListButton
          isProfile={true}
          item={{
            label: "Profile",
            icon: avatarIcon,
            link: "/settings",
          }}
          activeItem={activeItem}
          isDrawerOpen={isDrawerOpen}
          onClick={(e) => {
            setProfilePopoverOpen(e.currentTarget);
          }}
        />
      </Box>
    </Box>
  );
  return (
    <>
      <Box className="value-container" sx={{ position: "relative" }}>
        <Box sx={{ display: "flex", minHeight: { xs: "79vh", md: "100vh" } }}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <SPADrawer
              variant={"permanent"}
              open={isSidebarResponsive ? false : isDrawerOpen}
            >
              {drawerContent}
            </SPADrawer>
            {isSidebarResponsive && isDrawerOpen && (
              <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={handleToggleDrawer}
                transitionDuration={1000}
                PaperProps={{
                  sx: {
                    position: "relative",
                    whiteSpace: "nowrap",
                    background: secondary.main,
                    minHeight: "100vh",
                    padding: { sm: "24px", xs: "24px 12px" },
                    boxShadow: "0px 0px 5px 0px #33303026",
                    boxSizing: "border-box",
                    width: { sm: 320, xs: 220 },
                  },
                }}
              >
                {drawerContent}
              </Drawer>
            )}
          </Box>
          <Box
            component="main"
            sx={{
              width: "100%",
              height: "100vh",
              overflowY: "scroll",
              position: "relative",
              padding: {
                lg: "24px 32px 10px",
                md: "24px 32px 10px",
                sm: "24px 12px 10px",
                xs: isSmallResponsive ? "24px 8px 32px" : "24px 12px 32px",
              },
            }}
          >
            {/* Render the notify icon  */}
            {!notifyPanelIsOpen && (
              <Box
                sx={{
                  position: "absolute",
                  top: 25,
                  right: 10,
                  zIndex: 999,
                  padding: "12px",
                  height: "48px",
                  width: "48px",
                  background: "#EDECFC",
                  borderRadius: "8px",
                  cursor: "pointer !important",
                }}
                onClick={handleNotifyPanel}
              >
                <BellIcon />
              </Box>
            )}

            <Grid container spacing="24px">
              <Grid item xs={12} lg={notifyPanelIsOpen ? 9 : 12}>
                <Suspense fallback={<div>Loading...</div>}>
                  {getAllBusinessLocationLoader && (
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={getAllBusinessLocationLoader}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  )}
                  <Outlet />
                </Suspense>
              </Grid>
              {/* Render the close icon */}

              {notifyPanelIsOpen && (
                <Grid item lg={3} xs={12}>
                  <NotificationPanel setIsOpened={setNotifyPaneIsOpen} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      {openAppointmentModal && (
        <NewAppointmentForm
          getAllClients={() => {}}
          isAppointmentOpen={openAppointmentModal}
          handleClose={() => dispatch(handleCloseAppointmentModal())}
        />
      )}
      {openRetailProductViewModal && <RetailsProductView />}
      {openProfessionalProductViewModal && <ProfessionalProductView />}
      {openNewSalesModal && <CreateSalesDialogue />}

      {openUserProfileViewModal && <UserProfileView />}

      {isClientDialogOpen && (
        <NewClientForm
          getAllClients={() => {}}
          isDialogOpen={isClientDialogOpen}
          setDialogOpen={setIsClientDialogOpen}
          setAlertOpen={setAlertDialogOpen}
          setClientFullName={setClientFullName}
          setNewClientID={setNewClientID}
        />
      )}
      {profileOpenPopOver && (
        <SPAPopover
          anchorOriginVertical="top"
          anchorOriginHorizontal="right"
          transformVertical="bottom"
          transformHorizontal="left"
          width="220px"
          id={profilePopOverId}
          isOpen={profileOpenPopOver}
          anchorEl={profilePopoverOpen}
          handleClose={() => {
            setProfilePopoverOpen(null);
          }}
          data={profilePopOverData}
        />
      )}
      {quickOpenPopOver && (
        <SPAPopover
          anchorOriginVertical="top"
          anchorOriginHorizontal="right"
          transformVertical="bottom"
          transformHorizontal="left"
          width="220px"
          id={quickPopOverId}
          isOpen={quickOpenPopOver}
          anchorEl={quickPopoverOpen}
          handleClose={() => {
            setQuickPopoverOpen(null);
          }}
          data={quickPopOverData}
        />
      )}

      {isAlertDialogOpen && (
        <SPAAlertDialog
          open={isAlertDialogOpen}
          handleClose={() => setAlertDialogOpen(false)}
          handleNewTab={() => {
            // navigate(`/clients/profile/${newClientId}`);
            setAlertDialogOpen(false);
          }}
          title={"New Client Card Created!"}
          contentText={`Client card for ${clientFullName} was successfully created. Now you can see her profile in the Clients table.`}
          disagreeText={`Open Client Profile`}
          agreeText={`Close`}
        />
      )}
      {openAppointmentAlert && (
        <SPAAlertDialog
          open={openAppointmentAlert}
          handleClose={() => dispatch(handleCloseCreateAppointmentAlert())}
          title={"New Appointment Created"}
          contentText={`New appointment for ${createdClientDetail?.clientName} was created. For the date ${createdClientDetail?.date}`}
          disagreeText={`Create another appointment`}
          agreeText={`Close`}
          handleNewTab={() => {
            dispatch(handleOpenAppointmentModal({}));
            dispatch(handleCloseCreateAppointmentAlert());
          }}
        />
      )}
      {searchModel && (
        <GlobalSearch
          searchModel={searchModel}
          handleCloseSearchModel={() => {
            setSearchModel(false);
          }}
        />
      )}
    </>
  );
}
