import SPAInputField from "../../../hook-form/SPAInputField";

export const createProductTypeFormData = () => {
  return [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "name",
        label: "Product Type Name",
        placeholder: "i.e.: Hair",
      },
      component: SPAInputField,
    }
  ];
};
