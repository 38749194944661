import * as Yup from "yup";

export const validationSchema = (
  selectedProductsData,
  selectedServicesData
) => {
  // Generate dynamic schema for custom prices
  const productCustomPrices = selectedProductsData.reduce((acc, product) => {
    acc[`customPrice_${product._id}`] = Yup.number()
      .typeError("Custom price should be a number")
      .nullable();
    return acc;
  }, {});

  const serviceCustomPrices = selectedServicesData.reduce((acc, service) => {
    acc[`customPrice_${service._id}`] = Yup.number()
      .typeError("Custom price should be a number")
      .nullable();
    return acc;
  }, {});

  let schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    productType: Yup.string().required("Category is required"),
    description: Yup.string(),
    product: Yup.array().of(Yup.string()).nullable(),
    services: Yup.array().of(Yup.string()).nullable(),
    tax: Yup.string(),
    percentageAmount: Yup.number()
      .typeError("Percentage should be a number")
      .nullable(),
    ...productCustomPrices,
    ...serviceCustomPrices,
  });

  return schema;
};
