import React from 'react';
import ForgetPasswordComponent from '../../../components/auth/forgot-password';
import AuthLayout from '../../../layout/auth-layout';

const ForgotPassword = () => {
  return (
    <AuthLayout
      heading='Forgot Your Password?'
      subHeading='Please enter your email'
    >
      <ForgetPasswordComponent />
    </AuthLayout>
  );
};

export default ForgotPassword;
