import { baseAPI } from "../baseApi";

export const CalendarApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: () => ({ url: "/service" }),
      providesTags: ["Services"],
    }),
    getTherapist: builder.query({
      query: () => ({ url: "/therapist" }),
      providesTags: ["Therapist"],
    }),
    getRoom: builder.query({
      query: () => ({ url: "/room" }),
      providesTags: ["Room"],
    }),
    getCalendarInfo: builder.query({
      query: (date) => ({ url: "/calendar?date=" + date }),
      providesTags: ["Calendar"],
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetTherapistQuery,
  useGetRoomQuery,
  useGetCalendarInfoQuery,
} = CalendarApi;
