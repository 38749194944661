import { fs12 } from "../../../theme";


export const calendarBox = {
    slotIconBox: { display: "flex", alignItems: "center", gap: "4px" },
    eventTitle: { fontWeight: "400", fontSize: fs12, color: "#8F9193" },
    title: { fontWeight: "600", fontSize: fs12, color: "#5E6062" },
    time :{fontWeight: "400", fontSize: fs12, color: "#5E6062"},

}
