import * as Yup from "yup";

export const busyTimeValidationSchema = (recurringValue, availabilityType) => {
  let schema = Yup.object().shape({
    startDate: Yup.string().required("Start Date is Required"),
    endDate: Yup.string().required("End Date is Required"),
    startTime: Yup.string().required("Start Time is Required"),
    endTime: Yup.string().required("End Time is Required"),
    reason: Yup.string().required("Reason is required"),
    note: Yup.string(),
  });

  if (availabilityType === "STAFF") {
    schema = schema.shape({
      user: Yup.string().required("Select any Staff"),
    });
  }
  if (availabilityType === "ROOM") {
    schema = schema.shape({
      room: Yup.string().required("Select any Room"),
    });
  }
  if (availabilityType === "SERVICES") {
    schema = schema.shape({
      room: Yup.string().required("Select any Service"),
    });
  }

  return schema;
};

export const notesValidationSchema = (availabilityType, isAppointmentNote) => {
  let schema = Yup.object().shape({
    notes: Yup.string().required("Note is Required"),
  });

  if (isAppointmentNote !== "appointment") {
    schema = schema.shape({
      startDate: Yup.string().required("Start Date is Required"),
      endDate: Yup.string().required("End Date is Required"),
      startTime: Yup.string().required("Start Time is Required"),
      endTime: Yup.string().required("End Time is Required"),
    });
  }
  if (isAppointmentNote !== "appointment" && availabilityType === "STAFF") {
    schema = schema.shape({
      user: Yup.string().required("Select any Staff"),
    });
  }
  if (isAppointmentNote !== "appointment" && availabilityType === "ROOM") {
    schema = schema.shape({
      room: Yup.string().required("Select any Room"),
    });
  }

  return schema;
};
