import { Box, Button, Typography } from "@mui/material";
import React from "react";
import RightArrow from "../../../assets/svg/right-arrow-icon";
import FormProvider from "../../hook-form/FormProvider";
import SPAInputField from "../../hook-form/SPAInputField";
import { useNavigate } from "react-router";
import useLogin from "../login/useLogin";

const ForgetPasswordComponent = () => {
  const navigate = useNavigate();
  // for ui purposes use these methods from login we will make this own hook for this component
  const { methods, control, handleSubmit, onSubmit, responseError } =
    useLogin();
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <SPAInputField
            name="email"
            label="Email"
            type="email"
            placeholder="email@email.com"
          />
        </Box>

        <Button
          onClick={() => navigate("/reset-password-email")}
          type="submit"
          sx={{
            display: "flex",
            height: "48px",
            padding: "0px 24px",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            borderRadius: "8px",
            background: "#4E45E5",
            color: "#FFF",
            width: "100%",
            mt: "20px",
            "&:hover": {
              backgroundColor: "#2F2989",
              color: "#FFF",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: '"Open Sans"',
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "0.175px",
              textTransform: "uppercase",
            }}
          >
            Send
          </Typography>
          <RightArrow />
        </Button>
      </FormProvider>
      <Box
        sx={{
          mt: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography
            sx={{
              color: "#8F9193",
              fontFamily: '"Open Sans"',
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "0.048px",
            }}
          >
            Don’t have an account?
          </Typography>
          <Typography
            sx={{
              color: "#4E45E5",
              fontFamily: '"Open Sans"',
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "0.048px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/register")}
          >
            Create An Account
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ForgetPasswordComponent;
