import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useUpdateAllProductMutation } from "../../../../../services/product";
import { useGetServicesMutation } from "../../../../../services/services";
import { handleFilterSearch } from "../../../../../utils/searchFilter";
import { validationSchema } from "./validationSchema";

export default function useAddContent(
  handleClose,
  singleQualificationId,
  setIsOpenSuccessDialog,
  type,
  setAddQualificationData,
  initialData
) {
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const [searchTherapist, setSearchTherapist] = useState("");
  const validationSchemaInstance = validationSchema(type);
  const methods = useForm({
    resolver: yupResolver(validationSchemaInstance),
    mode: "onChange",
    defaultValues: {
      [type === "Product"
        ? "product"
        : type === "Treatment"
        ? "services"
        : "product"]: [],
    },
  });
  const [getAllProduct, { data: productsData, isLoading: productsDataLoader }] =
    useUpdateAllProductMutation();
  const [
    getAllServices,
    { data: servicesData, isLoading: servicesDataLoader },
  ] = useGetServicesMutation();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const onSubmit = async (values) => {
    setAddQualificationData(values);
    setIsOpenSuccessDialog(false);
    handleClose("Success");
  };

  const handleCloseForm = () => {
    handleClose("Deffer");
    reset();
  };

  const handleSearchTreatment = () => {
    let attributeArray = [];
    if (searchTherapist !== "") {
      if (isNaN(searchTherapist)) {
        attributeArray = ["name"];
      } else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchTherapist,
        attributeArray,
        "search"
      );
      getAllServices({ ...searchPayload });
    } else getAllServices({});
  };

  const handleSearchProduct = () => {
    let attributeArray = [];
    const filterPayload = handleFilterSearch(
      ["type=RETAIL_PRODUCT"],
      ["type"],
      "filter"
    );
    if (searchTherapist !== "") {
      if (isNaN(searchTherapist)) {
        attributeArray = ["name"];
      } else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchTherapist,
        attributeArray,
        "search"
      );
      getAllProduct({ ...searchPayload });
    } else getAllProduct({ ...filterPayload });
  };

  useEffect(() => {
    if (type === "Product") {
      handleSearchProduct();
    }
    if (type === "Treatment") {
      handleSearchTreatment();
    }
  }, [searchTherapist]);

  useEffect(() => {
    const searchPayload = handleFilterSearch(
      ["type=RETAIL_PRODUCT"],
      ["type"],
      "filter"
    );
    getAllProduct({ ...searchPayload });
    getAllServices();
  }, []);

  useEffect(() => {
    if (type === "Treatment") {
      setValue("services", initialData);
    } else {
      setValue("product", initialData);
    }
  }, [initialData]);

  const toggleStaffSelection = (id) => {
    setSelectedStaffIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return {
    searchTherapist,
    setSearchTherapist,
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    handleCloseForm,
    productsData,
    productsDataLoader,
    selectedStaffIds,
    toggleStaffSelection,
    servicesData,
    servicesDataLoader,
  };
}
