import { useState } from "react";
import { getLocalStorageItem } from "../../../../utils/localStorage";
import { TreatmentViewNavBarData } from "./treatment-profile-data";

export default function useTreatmentView() {
  const [activeItem, setActiveItem] = useState("Overview");

  const userDetail = getLocalStorageItem("userData");
  const role = userDetail?.role;

  const roleBasedNavBarData = TreatmentViewNavBarData.filter((item) => {
    if (
      (role === "ADMIN" || role === "SUPER_ADMIN") &&
      item.link === "Appointments"
    )
      return false;
    if (
      role !== "ADMIN" &&
      role !== "SUPER_ADMIN" &&
      item.link === "NotificationSettings"
    )
      return false;
    return true;
  });

  return {
    activeItem,
    setActiveItem,
    roleBasedNavBarData,
  };
}
