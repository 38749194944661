import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../../theme/theme";
import { Envelope } from "@phosphor-icons/react/dist/ssr";
import { customColors, fs10, fs12, fs14, fw600 } from "../../../theme";
import dayjs from "dayjs";

export default function SPAEmailCard({ content, media, date, id, subject }) {
  return (
    <Box
      key={id}
      sx={{
        background: theme.palette.white.main,
        mb: "10px",
        borderRadius: "8px",
        p: "8px",
      }}
    >
      <Typography
        fontSize={fs14}
        fontWeight={fw600}
        color={theme.palette.textGraySecondary.main}
      >
        {subject}
      </Typography>
      {content && (
        <Typography fontSize={fs12} color={customColors.lightBlack}>
          {content}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Envelope color="#8F9193" size={16} />

          <Typography
            fontSize={fs10}
            color={"#8F9193"}
            textTransform={"uppercase"}
          >
            {`Via ${media}`}
          </Typography>
        </Box>
        <Box>
          <Typography color={"#8F9193"} fontSize={fs10}>
            {dayjs(date).format("DD MMM, YYYY")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
