import React from "react";

export const truncateText = () => {
  function truncateFullName(fullName, maxLength = 25) {
    if (fullName.length > maxLength) {
      const truncated = fullName.slice(0, maxLength).trim();
      const lastSpaceIndex = truncated.lastIndexOf(" ");
      if (lastSpaceIndex > 0) {
        return truncated.slice(0, lastSpaceIndex) + "...";
      }
      return truncated + "...";
    }
    return fullName;
  }
  return truncateFullName;
};

export const truncateFullName = (fullName, maxLength = 25) => {
  if (fullName.length > maxLength) {
    const truncated = fullName.slice(0, maxLength).trim();
    const lastSpaceIndex = truncated.lastIndexOf(" ");
    if (lastSpaceIndex > 0) {
      return truncated.slice(0, lastSpaceIndex) + "...";
    }
    return truncated + "...";
  }
  return fullName;
};
