import { useTheme } from "@emotion/react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useDeleteProductMutation,
  useGetSingleProductQuery,
} from "../../../../../../services/product";
import { handleCloseRetailProductViewModal } from "../../../../../../store/features/products";
import { customColors } from "../../../../../../theme";
import { handleFilterSearch } from "../../../../../../utils/searchFilter";
import SPAAlertDialog from "../../../../../common/spa-alert-dialogue";
import Appointment from "./components/appointment";
import Articles from "./components/articles";
import BusyTime from "./components/busy-time";
import DealAndBundles from "./components/deal-and-bundles";
import DiscountCodeSection from "./components/discount-code";
import SideBar from "./components/side-bar";
import ProductDetails from "./components/treatment-overview";

const RetailsProductView = ({ handleClose }) => {
  const retailsProductsDetails = useSelector(
    (state) => state?.retailProducts?.retailsProductsDetails
  );
  const openRetailProductViewModal = useSelector(
    (state) => state?.retailProducts?.openRetailProductViewModal
  );
  const dispatch = useDispatch();
  const [activeTabSideBar, setActiveTabSideBar] = useState("product");
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("md"));
  const {
    data: singleProductData,
    isLoading: singleProductDataLoader,
    refetch: singleProductRefetching,
  } = useGetSingleProductQuery(retailsProductsDetails?.singleProductId, {
    skip: !retailsProductsDetails?.singleProductId,
  });

  const [deleteProduct, { data: deleteData, isLoading: deleteServiceLoader }] =
    useDeleteProductMutation();

  const renderTabContent = (tab) => {
    switch (tab) {
      case "product":
        return (
          <ProductDetails
            singleProductDetails={singleProductData?.result?.data}
            isResponsive={isResponsive}
            singleProductRefetching={singleProductRefetching}
          />
        );
      case "appointment":
        return <Appointment />;
      case "busy":
        return <BusyTime />;
      case "articles":
        return <Articles />;
      case "discountCode":
        return (
          <DiscountCodeSection
            singleProductDetails={singleProductData?.result?.data}
            isResponsive={isResponsive}
            singleProductRefetching={singleProductRefetching}
          />
        );
      case "dealAndBundles":
        return <DealAndBundles />;
      default:
        return <div>Select a tab</div>;
    }
  };

  const handleRemoveItem = () => {
    setIsOpenDeleteDialog(true);
  };

  const handleDeleteService = async () => {
    let response;
    if (retailsProductsDetails?.singleProductId) {
      response = await deleteProduct(retailsProductsDetails?.singleProductId);
    }
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    handleCloseProduct();
  };

  const handleCloseProduct = () => {
    dispatch(handleCloseRetailProductViewModal());
    const searchPayload = handleFilterSearch(
      ["type=RETAIL_PRODUCT"],
      ["type"],
      "filter"
    );
    retailsProductsDetails?.getAllProducts({ ...searchPayload });
    retailsProductsDetails?.getStockProducts({ ...searchPayload });
  };

  return (
    <Dialog
      open={openRetailProductViewModal}
      onClose={handleCloseProduct}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: "1232px",
          overflowY: "hidden",
          minWidth: { xs: "auto", lg: "1232px" },
          height: "90vh",
          borderRadius: "16px",
        },
      }}
    >
      {deleteServiceLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={deleteServiceLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: customColors.darkestBlue,
          p: "24px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography
            sx={{
              fontSize: "24px",
              color: customColors.white,
            }}
          >
            {`${singleProductData?.result?.data?.name}`}
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ bgcolor: "#FFFFFF" }}
          />
          <Typography
            sx={{
              fontSize: "24px",
              color: "#716AEA",
            }}
          >
            Retail Product
          </Typography>
        </Box>
        <X
          size={24}
          style={{ cursor: "pointer" }}
          color={customColors.white}
          onClick={handleCloseProduct}
        />
      </Box>
      {/* side bar and other panel */}
      <Box sx={{ overflowY: "auto", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <SideBar
            setActiveItem={setActiveTabSideBar}
            activeItem={activeTabSideBar}
            handleRemoveItem={handleRemoveItem}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {renderTabContent(activeTabSideBar)}
          </Box>
        </Box>
      </Box>
      {isOpenDeleteDialog && (
        <SPAAlertDialog
          open={isOpenDeleteDialog}
          handleClose={() => setIsOpenDeleteDialog(false)}
          handleNewTab={handleDeleteService}
          title={"Product Removal Confirmation"}
          contentText={`Removing this product will permanently delete it from the system. This action cannot be undone. Are you sure you want to proceed with the removal?`}
          disagreeText={`Permanently Remove Product`}
          agreeText={`Cancel`}
          type="error"
        />
      )}
    </Dialog>
  );
};

export default RetailsProductView;
