import {
  Box,
  ClickAwayListener,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Heart, MagnifyingGlass, Warning, X } from "@phosphor-icons/react";
import React from "react";
import TreatmentCard from "../../treatment-card";
import ProductCategoryTab from "../../../Treatments/products-tab";
import SPATextField from "../../../../common/spa-textField";

const TreatmentComponent = ({
  searchTreatment,
  setSearchTreatment,
  activeItemTreatment,
  setActiveItemTreatment,
  isSearchBoxTreatment,
  setIsSearchBoxTreatment,
  handleAddTreatment,
  servicesDataLoading,
  selectedTreatment,
  serviceCategoryData,
  servicesData,
  errors,
  index,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        flexDirection: "column",
      }}
    >
      <Grid
        item
        lg={12}
        xs={12}
        sx={{
          position: "relative",
          width: "100%",
        }}
      >
        <SPATextField
          fullWidth
          name="search"
          placeholder="Search Treatments"
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "48px",
            },
          }}
          onClick={() =>
            setIsSearchBoxTreatment((prev) => ({ ...prev, [index]: true }))
          }
          onFocus={() =>
            setIsSearchBoxTreatment((prev) => ({ ...prev, [index]: true }))
          }
          value={searchTreatment[index] || ""}
          onChangeValue={(event) => {
            setSearchTreatment((prev) => ({
              ...prev,
              [index]: event.target.value,
            }));
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                <MagnifyingGlass size={24} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ paddingLeft: "10px", cursor: "pointer" }}
                onClick={() => {
                  setSearchTreatment((prev) => ({ ...prev, [index]: "" }));
                }}
              >
                {searchTreatment[index] && <X size={24} />}
              </InputAdornment>
            ),
          }}
        />
        {isSearchBoxTreatment[index] && (
          <ClickAwayListener
            onClickAway={() => {
              setIsSearchBoxTreatment((prev) => ({ ...prev, [index]: false }));
              setActiveItemTreatment("ALL");
            }}
          >
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                width: "100%",
                paddingRight: "16px",
                gap: "16px",
                borderRadius: "16px",
                background: "#FAFAFA",
                boxShadow:
                  "-66px 62px 25px 0px rgba(78, 69, 229, 0.00), -42px 39px 23px 0px rgba(78, 69, 229, 0.01), -25px 22px 19px 0px rgba(78, 69, 229, 0.03), -11px 10px 14px 0px rgba(78, 69, 229, 0.05), -3px 2px 8px 0px rgba(78, 69, 229, 0.05)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "164px",
                  padding: "8px",
                  flexDirection: "column",
                  gap: "4px",
                  borderRadius: "16px 0px 0px 16px",
                  background: "#FFF",
                }}
              >
                <ProductCategoryTab
                  activeItem={activeItemTreatment}
                  setActiveItem={setActiveItemTreatment}
                  tabsData={serviceCategoryData?.result?.data?.data}
                />
              </Box>
              <TreatmentCard
                servicesData={servicesData?.result?.data?.data}
                serviceLoaderLoader={servicesDataLoading}
                handleSingleServiceSelect={(item) =>
                  handleAddTreatment(item, index)
                }
              />
            </Box>
          </ClickAwayListener>
        )}
        {errors[`treatment${index}`] && (
          <Typography
            sx={{
              color: "#FFAA33",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              mt: "5px",
            }}
            color="error"
          >
            <Warning color={"#FFAA33"} size={16} />
            {errors[`treatment${index}`]?.message}
          </Typography>
        )}
      </Grid>
      {selectedTreatment[index] && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            paddingRight: "8px",
            alignItems: "center",
            gap: "8px",
            backgroundColor: "#FCF5F3",
            justifyContent: "space-between",
            overflow: "hidden",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              backgroundColor: "#FCF5F3",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                maxWidth: "85px",
                overflow: "hidden",
                height: "90px",
                flexShrink: 0,
              }}
            >
              <img
                src={
                  "https://qa-temple-day-spa-s3.s3.ap-southeast-2.amazonaws.com/e69da1af-ceff-49d5-b780-20bcd96e607d-images.jpg"
                }
                alt="treatment"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  padding: "2px 4px",
                  background: "#FAFAFA",
                  color: "#25272B",
                  fontSize: "10px",
                  textTransform: "uppercase",
                  maxWidth: "fit-content",
                  fontWeight: "500",
                }}
              >
                {selectedTreatment[index]?.treatment?.category?.name}
              </Box>
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: "14px",
                  fontWeight: "600",
                  letterSpacing: "0.014px",
                }}
              >
                {selectedTreatment[index]?.treatment?.name}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: "1.5rem",
            }}
          >
            <Heart size={24} color="#5E6062" />
            <Typography
              sx={{
                color: "#5E6062",
                fontSize: "14px",
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              ${selectedTreatment[index]?.treatment?.price}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TreatmentComponent;
