import { useTheme } from "@emotion/react";
import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import SideBar from "./SideBar";
import PackageSection from "./package";
import PromoCodeSection from "./promo-code";

const DealsAndBundles = ({ isClientProfileOpen, handleClose }) => {
  const [activeTabSideBar, setActiveTabSideBar] = useState("Promo Codes");
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down("md"));

  const renderTabContent = (tab) => {
    switch (tab) {
      case "Promo Codes":
        return <PromoCodeSection />;
      case "Offers":
        return <div>Select a tab</div>;
      case "Gifts":
        return <div>Select a tab</div>;
      case "Packages":
        return <PackageSection />;
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          gap: "24px",
        }}
      >
        <SideBar
          setActiveItem={setActiveTabSideBar}
          activeItem={activeTabSideBar}
          isResponsive={isResponsive}
        />
        <Box sx={{ width: "100%" }}>{renderTabContent(activeTabSideBar)}</Box>
      </Box>
    </>
  );
};
export default DealsAndBundles;
