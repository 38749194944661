import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAddProductToServiceMutation } from "../../../../../../../../../../services/product";

const useTreatment = ({ singleProductData, singleProductRefetching }) => {
  const [addTreatmentData, setAddTreatmentData] = useState();
  const [isOpenAddTreatmentDialog, setIsOpenAddTreatmentDialog] =
    useState(false);
  const [selectedServicesData, setSelectedServicesData] = useState([]);
  const [searchTreatment, setSearchTreatment] = useState("");
  const [addProductToServices] = useAddProductToServiceMutation();

  useEffect(() => {
    const addProductAsync = async () => {
      if (addTreatmentData && singleProductData?._id) {
        let finalData = addTreatmentData?.services?.map((item) => item?._id);
        let payload = {
          serviceIds: finalData,
        };

        const response = await addProductToServices({ 
          payload: payload,
          productId: singleProductData?._id,
        });
        if (response?.data?.status === false) {
          toast.error(response?.data?.message);
        }
        if (response?.data?.status === true) {
          singleProductRefetching();
          toast.success(response?.data?.message);
          setIsOpenAddTreatmentDialog(false);
        }
      }
    };

    addProductAsync();
  }, [addTreatmentData]);

  const handleSearchTreatment = () => {
    if (searchTreatment) {
      const filteredTreatment = selectedServicesData?.filter((user) =>
        `${user.name}`.toLowerCase().includes(searchTreatment.toLowerCase())
      );
      setSelectedServicesData(filteredTreatment);
    } else {
      setSelectedServicesData(singleProductData?.services ?? []);
    }
  };

  useEffect(() => {
    handleSearchTreatment();
  }, [searchTreatment]);

  useEffect(() => {
    setSelectedServicesData(singleProductData?.services);
  }, [singleProductData]);

  return {
    isOpenAddTreatmentDialog,
    setIsOpenAddTreatmentDialog,
    setAddTreatmentData,
    selectedServicesData,
    searchTreatment,
    setSearchTreatment,
  };
};

export default useTreatment;
