// @mui
import { Box, Button, Divider, Typography } from "@mui/material";
import { Image } from "@phosphor-icons/react";
// assets
// import { UploadIllustration } from "@root/assets/svg";

// ----------------------------------------------------------------------

export default function BlockContent({ filesUpload, height }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          flexDirection: "column",
        }}
      >
        <Image size={42} color="#4E45E5" />
        <Typography
          sx={{ color: "#070A0E", fontSize: "14px", textAlign: "center" }}
        >
          Drag your file(s) to start uploading
        </Typography>
        <Box sx={{}}>
          <Divider variant="middle" sx={{ color: "#8F9193", fontSize: "12px" }}>
            OR
          </Divider>
        </Box>
        <Button
          sx={{
            display: "flex",
            height: "0px 24px",
            alignItems: "center",
            justifyItems: "center",
            gap: "8px",
            borderRadius: "8px",
            background: "#EDECFC",
            color: "#4E45E5",
            "&:hover": {
              background: "#EDECFC",
            },
          }}
        >
          Browse Files
        </Button>
      </Box>
    </>
  );
}
