import React from "react";
import { nameInitialsAvatar } from "../../utils/nameInitialsAvatar";
import { customColors, fs14, fw600 } from "../../theme";
import { Avatar, Box, Typography } from "@mui/material";
import {
  LinkBreak,
  ShootingStar,
  SketchLogo,
  StarHalf,
} from "@phosphor-icons/react";

export default function ClientCard({ item, onClick }) {
  let imgSource = "";
  const statusDesign = {
    OPEN: {
      icon: <ShootingStar size={16} />,
      color: "#1AA37A",
      bgColor: "#E9FBF6",
    },
    ACTIVE: {
      icon: <ShootingStar size={16} />,
      color: "#1AA37A",
      bgColor: "#E9FBF6",
    },
    NEW: {
      icon: <ShootingStar size={16} />,
      color: "#1AA37A",
      bgColor: "#E9FBF6",
    },
    VIP: {
      icon: <SketchLogo size={16} />,
      color: "#FA9200",
      bgColor: "#FFF9F0",
    },
    LOST: {
      icon: <LinkBreak size={16} />,
      color: "#4A4C4F",
      bgColor: "#FAFAFA",
    },
    RETURN: {
      icon: <StarHalf size={16} />,
      color: "#115ACB",
      bgColor: "#E7EFFC",
    },
  };
  return (
    <Box
      sx={{
        backgroundColor: customColors.floralWhite,
        padding: "8px 16px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {item.imgSource ? (
            <Avatar
              sx={{ width: "48px", height: "48px", cursor: "pointer" }}
              src={item.imgSource}
              variant="rounded"
            />
          ) : (
            <Avatar {...nameInitialsAvatar(item.name)} variant="rounded" />
          )}
          <Typography
            sx={{
              color: "#070A0E",
              fontSize: fs14,
              fontWeight: fw600,
              cursor: "pointer",
              textTransform: "capitalize",
            }}
          >
            {item.name}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: statusDesign[item.clientStatus].bgColor,
          p: "4px 8px",
          color: statusDesign[item.clientStatus].color,
          width: "fit-content",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {statusDesign[item.clientStatus].icon}
        {item.clientStatus}
      </Box>
    </Box>
  );
}
