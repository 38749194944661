import { Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { X } from "@phosphor-icons/react";
import { customColors } from "../../../theme";
import FormProvider from "../../hook-form/FormProvider";
import { cancelBtn, SubmitBtn } from "./spa-dialogue-styles";
import { useEffect, useRef, useState } from "react";

/**
 * SPADialogue component.
 * Renders a dialog for creating a new client with form fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.open - If true, the dialog is open.
 * @param {Function} props.handleClose - The function to handle closing the dialog.
 * @returns {JSX.Element} JSX code for rendering the SPADialogue component.
 */
// loader set
export default function SPADialogue({
  open,
  handleClose,
  dialogTitle,
  cancelText,
  submitText,
  children,
  isPadding = true,
  loading = false,
  handleSubmit = null,
  methods = null,
  onSubmit = null,
  profileWidth = 0,
  module = null,
  closeIcon,
  ...rest
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          ...rest.sx,
          maxWidth: profileWidth > 0 ? `${profileWidth}px` : "1000px",
          borderRadius: "16px",
        },
      }}
    >
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit ? handleSubmit(onSubmit) : null}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: dialogTitle ? "space-between" : "flex-end",
            alignItems: "center",
            pr: 2,
            position: "sticky",
            top: 0,
            background: "#fff",
            zIndex: 1,
          }}
        >
          {dialogTitle && (
            <DialogTitle
              sx={{ maxWidth: "80%", fontWeight: "700" }}
              id="scroll-dialog-title"
            >
              {dialogTitle}
            </DialogTitle>
          )}
          {handleClose && (
            <Box sx={{ cursor: "pointer", pt: "8px" }} onClick={handleClose}>
              {closeIcon ? closeIcon : <X onClick={handleClose} size={24} />}
            </Box>
          )}
        </Box>
        <DialogContent
          sx={{
            p: "0px 24px 20px 24px",
            flex: "1 1 auto",
            overflowY: "auto",
            maxHeight: "76vh",
          }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          ></DialogContentText>
          {children}
        </DialogContent>
        {(submitText || cancelText) && (
          <DialogActions
            sx={{
              paddingInline: "24px",
              py: 2.5,
              position: "sticky",
              bottom: 0,
              background: "#fff",
              zIndex: 1,
            }}
          >
            {cancelText && (
              <Button onClick={handleClose} sx={cancelBtn}>
                {cancelText}
              </Button>
            )}

            {submitText && (
              <Button disabled={loading} type="submit" sx={SubmitBtn}>
                <span
                  style={{
                    visibility: loading ? "hidden" : "none",
                    display: loading ? "block" : "none",
                    whiteSpace: "nowrap",
                  }}
                >
                  {submitText}
                </span>
                {loading ? (
                  <CircularProgress
                    style={{ color: "#fff", position: "absolute" }}
                    size={24}
                  />
                ) : (
                  submitText
                )}
              </Button>
            )}
          </DialogActions>
        )}
      </FormProvider>
    </Dialog>
  );
}
