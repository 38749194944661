import { baseAPI } from "../baseApi";

export const PublishingApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllPublishing: builder.mutation({
      query: (body) => ({ url: "/publish", method: "PATCH", body }),
      providesTags: ["Publishing"],
    }),
    createPublishing: builder.mutation({
      query: (payload) => ({
        url: "/publish",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Publishing"],
    }),
    getSinglePublishing: builder.query({
      query: (publishingId) => ({
        url: "/publish/" + publishingId,
      }),
      providesTags: ["singlePublishing"],
    }),
    updateSinglePublishing: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/publish/" + id,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["singlePublishing", "Publishing"],
    }),
  }),
});

export const {
  useGetSinglePublishingQuery,
  useCreatePublishingMutation,
  useUpdateSinglePublishingMutation,
  useGetAllPublishingMutation,
} = PublishingApi;
