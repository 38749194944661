import React from "react";
import { calendarBox } from "./global-style";
import { Box, Typography } from "@mui/material";
import {
  Cloud,
  Door,
  Money,
  Note,
  Prohibit,
  SealCheck,
  ShootingStar,
  Warning,
  XCircle,
} from "@phosphor-icons/react";
import { lightenHexColor } from "../../utils/convertLightenHexColor";
export default function AppointmentCard({ cardDetail, id, onClick }) {
  const slotIcon = [
    {
      id: 1,
      icon: Money,
      tooltip: "Deposit Paid",
      condition: cardDetail.condition?.isDepositPaid,
    },
    {
      id: 2,
      icon: Cloud,
      tooltip: "Booked Online",
      condition: cardDetail.condition?.isBookedOnline,
    },
    {
      id: 3,
      icon: Warning,
      tooltip: "Contraindication",
      condition: cardDetail.condition?.isContraindication,
    },
    {
      id: 4,
      icon: ShootingStar,
      tooltip: "New Client",
      condition: cardDetail.condition?.isNewClient,
    },
    {
      id: 5,
      icon: SealCheck,
      tooltip: "Client Attended",
      condition: cardDetail.condition?.isClientAttended,
    },
    {
      id: 6,
      icon: XCircle,
      tooltip: "Client didn't show",
      condition: cardDetail.condition?.isClientNoShow,
    },
    {
      id: 7,
      icon: Note,
      tooltip: "Notes",
      condition: cardDetail.condition?.isNotes,
    },
    {
      id: 8,
      icon: Prohibit,
      tooltip: "Cancelled",
      condition: cardDetail.condition?.isAppointmentCancelled,
    },
  ];

  return (
    <>
      <Box
        key={id}
        sx={{
          backgroundColor: lightenHexColor(cardDetail.color, 0.9),
          borderRadius: "8px",
          padding: "8px",
          borderLeftWidth: "10px",
          borderLeftColor: cardDetail.color,
          borderLeftStyle: "solid",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <Box sx={calendarBox.slotIconBox}>
          {slotIcon.map((item, index) => {
            const Slot = item.icon;
            return (
              <>
                {item.condition ? (
                  <Slot key={index} color={cardDetail.color} size={16} />
                ) : null}
              </>
            );
          })}
        </Box>
        <Typography sx={calendarBox.eventTitle}>{cardDetail.name}</Typography>
        <Typography sx={calendarBox.eventDesc}>
          {cardDetail.services}
        </Typography>

        <Typography sx={calendarBox.eventTag}>
          <Door color="#8F9193" size={12} /> {cardDetail.roomName}
        </Typography>
        <Box sx={calendarBox.dateSlot}>
          <Typography sx={calendarBox.dateStart}>
            {cardDetail.serviceStartTime}
          </Typography>
          <Typography sx={calendarBox.dateRange}>
            {`${cardDetail.serviceTime} Hour`}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
