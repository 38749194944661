import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoginMutation } from "../../../services/authApi";
import * as yup from "yup";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../utils/localStorage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetBusinessLocationsQuery } from "../../../services/business-location";
const validationSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const useLogin = () => {
  const [responseError, setResponseError] = useState(null);
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;
  const [loginTrigger, { isLoading }] = useLoginMutation();
  const [triggerGetBusinessLocations, setTriggerGetBusinessLocations] =
    useState(false);
  const {
    data: getAllBusinessLocation,
    isLoading: getAllBusinessLocationLoader,
  } = useGetBusinessLocationsQuery(undefined, {
    skip: !triggerGetBusinessLocations,
  });

  const businessLocationData = getLocalStorageItem("businessLocation");

  const onSubmit = async (values) => {
    try {
      const res = await loginTrigger(values);

      if (res?.data?.status === false) {
        setResponseError(res?.data?.message);
      } else {
        setLocalStorageItem("auth-token", res?.data?.result?.data?.accessToken);
        setLocalStorageItem("userData", res?.data?.result?.data?.user);
        setTriggerGetBusinessLocations(true);
        setLocalStorageItem("businessLocation", [
          res?.data?.result?.data?.user?.businessLocation,
        ]);
        // if (tempRoute) {
        //   navigate(`${tempRoute}`);
        // } else {
        navigate("/calendar");
        // }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (getAllBusinessLocation?.result?.data) {
      setLocalStorageItem(
        "businessLocation",
        getAllBusinessLocation?.result?.data
      );
    }
  }, [getAllBusinessLocation?.result?.data]);

  // const handleBusinessLocation = () => {
  //   if (businessLocationData?.length > 0 && businessLocationData[0]?._id) {
  //     navigate("/calendar");
  //   }
  // };

  // useEffect(() => {
  //   handleBusinessLocation();
  // }, [businessLocationData]);

  return {
    methods,
    handleSubmit,
    onSubmit,
    control,
    errors,
    isLoading,
    responseError,
  };
};

export default useLogin;
