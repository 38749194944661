import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { Warning } from "@phosphor-icons/react";
import React from "react";
import SPADialogue from "../../common/spa-dialogue/index.jsx";
import SPAInputField from "../../hook-form/SPAInputField.jsx";
import { contraModelStyle } from "./editContraStyle.js";
import UseEditContraindication from "./useEditContraindication.js";

export default function EditContraindicationModel({ handleContraModel }) {
  const {
    control,
    errors,
    methods,
    handleSubmit,
    onSubmit,
    editContraindicationsModel,
  } = UseEditContraindication(handleContraModel);

  return (
    <SPADialogue
      open={editContraindicationsModel}
      handleClose={handleContraModel}
      cancelText="Cancel"
      submitText="Save Changes"
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      methods={methods}
    >
      <Box
        sx={{
          mb: 1.5,
          py: "16px",
          ...contraModelStyle.calendarHeaderMain,
        }}
      >
        <Box
          sx={{
            ...contraModelStyle.createNoteGrid,
          }}
        >
          <Grid item xs={12} mt={1}>
            <Typography>
              <FormLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#5E6062",
                  fontWeight: "600",
                  fontSize: "16px",
                  marginBottom: "8px",
                }}
              >
                <Warning size={24} /> Contraindication
              </FormLabel>
            </Typography>
            <SPAInputField
              name="contraindication"
              type="textarea"
              errors={errors}
              placeholder="Write the annotation here"
              control={control}
            />
          </Grid>
        </Box>
      </Box>
    </SPADialogue>
  );
}
