import React from "react";
import ClientsSection from "../../components/clients";

/**
 * Clients component.
 * Renders the Clients page.
 *
 * @returns {JSX.Element} JSX code for rendering the Clients component.
 */
const Clients = () => {
  return (
    <div>
      <ClientsSection />
    </div>
  );
};

export default Clients;
