import SPAInputField from "../../hook-form/SPAInputField";
import SPASelectField from "../../hook-form/SPASelectField";

export const articleTabsArray = [
  { title: "ALL" },
  {
    title: "PUBLISHED",
  },
  { title: "UNPUBLISHED" },
];

export const articleFormData = ({
  InsertButton,
  historyType,
  templateData,
}) => {
  let finalData = [
    {
      gridLength: 4,
      otherOptions: {
        fullWidth: true,
        name: "name",
        label: "Article Title",
        placeholder: "Enter the article title here",
      },
      component: SPAInputField,
    },
    {
      gridLength: 4,
      otherOptions: {
        fullWidth: true,
        name: "template",
        label: "Template",
        placeholder: "Select Template",
        labelHelperText: "(Optional)",
        options:
          templateData &&
          templateData.map((item) => {
            return { label: item.name, value: item.name, id: item._id };
          }),
      },
      component: SPASelectField,
    },
  ];
  return finalData;
};
