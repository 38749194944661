import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { Package } from "@phosphor-icons/react";
import React from "react";
import theme from "../../../../../../../../../../theme/theme";
import FormProvider from "../../../../../../../../../hook-form/FormProvider";
import SPASelectField from "../../../../../../../../../hook-form/SPASelectField";
import { updateProfessionalProductFormData } from "../../../../../create-professional-product/new-professional-form-data";
import {
  CancelBtn,
  DisabledButton,
  EnabledButton,
  SubmitBtn,
} from "../../../../style";
import useProductOverview from "./useProductOverview";
import SPAInputField from "../../../../../../../../../hook-form/SPAInputField";
import SPALinearProgress from "../../../../../../../../../common/spa-linear-progress";
import SPAAlertDialog from "../../../../../../../../../common/spa-alert-dialogue";

export default function Overview({
  singleProductData,
  singleProductRefetching,
}) {
  const {
    control,
    handleSubmit,
    onSubmit,
    methods,
    productCategoryData,
    productCategoryDataLoader,

    toggleSwitch,
    enabled,
    handleDisableConfirm,
    setIsOpenDisableConfirmDialog,
    isOpenDisableConfirmDialog,
    isOpenNotDisableDialog,
    setIsOpenNotDisableConfirmDialog,

    stockPercentageDataHandling,
    measurementValue,

    isRemoveActive,
    setIsRemoveActive,
    handleStockHandling,
  } = useProductOverview({ singleProductData, singleProductRefetching });

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container gap={3}>
          {productCategoryDataLoader && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 3 }}
              open={productCategoryDataLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Grid
            xs={12}
            md={6}
            style={{ padding: "16px", borderRadius: "8px" }}
            bgcolor={theme.palette.purple10.main}
            container
          >
            <Grid container spacing={2}>
              {updateProfessionalProductFormData(
                productCategoryData?.result?.data?.data
              ).map((item) => {
                return (
                  <Box
                    sx={{
                      borderRadius: "8px",
                      background: "#F8F7FE",
                      display: "flex",
                      padding: "16px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "16px",
                      minHeight: "200px",
                      mb: "16px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "4px" }}>
                      <Typography
                        sx={{
                          color: "#4A4C4F",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                        }}
                      >
                        {" "}
                        {item?.title}
                      </Typography>
                    </Box>
                    <Grid container spacing={1}>
                      {item?.rowChildren?.map((child, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sm={12}
                          md={child.gridLength}
                        >
                          {child?.colChildren && (
                            <Grid container spacing={1}>
                              {child?.colChildren?.map((subChild, index) => (
                                <Grid
                                  key={index}
                                  item
                                  xs={12}
                                  sm={12}
                                  md={subChild?.gridLength}
                                >
                                  {subChild?.colTitle && (
                                    <Typography
                                      sx={{
                                        color: "#5E6062",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                        letterSpacing: "0.035px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {subChild?.colTitle}
                                    </Typography>
                                  )}
                                  {subChild?.component && (
                                    <Box>
                                      <subChild.component
                                        control={control}
                                        {...subChild?.otherOptions}
                                      />
                                    </Box>
                                  )}
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                );
              })}
            </Grid>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button sx={SubmitBtn} type={"submit"}>
                <Typography>Save Changes</Typography>
              </Button>
            </Box>
          </Grid>
          <Grid
            xs={12}
            md={5.7}
            style={{
              borderRadius: "8px",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                borderRadius: "8px",
                background: "#F8F7FE",
                display: "flex",
                padding: "16px",
                alignItems: "center",
                gap: "16px",
                mb: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex", gap: "4px" }}>
                  <Typography
                    sx={{
                      color: "#4A4C4F",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    Enable for interaction
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      color: "#5E6062",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    When enabled, staff can use this product for treatments.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                  backgroundColor: "#EDECFC",
                  borderRadius: "8px  ",
                }}
              >
                <EnabledButton
                  active={enabled === true ? true : null}
                  onClick={toggleSwitch}
                >
                  Enabled
                </EnabledButton>
                <DisabledButton
                  active={enabled === false ? true : null}
                  onClick={toggleSwitch}
                >
                  Disabled
                </DisabledButton>
              </Box>
            </Box>
            <Box
              sx={{
                // width: "688px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  borderRadius: "8px",
                  background: "#F8F7FE",
                  display: "flex",
                  padding: "16px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "16px",
                  minHeight: "200px",
                  mb: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "4px" }}>
                    <Box>
                      {" "}
                      <Package size={16} color="#5E6062" />
                    </Box>
                    <Typography
                      sx={{
                        color: "#4A4C4F",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      Stock
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#5E6062",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    Manage the stock of this product
                  </Typography>
                </Box>
                <Grid
                  container
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <SPASelectField
                      control={control}
                      fullWidth={true}
                      name={"measurement"}
                      label="Measurement"
                      placeholder="Select Measurement"
                      options={[
                        { label: "Units", value: "UNIT" },
                        { label: "Pack", value: "PACK" },
                        { label: "Millilitre", value: "MILLILITRE" },
                        { label: "Each", value: "EACH" },
                        { label: "Litre", value: "LITRE" },
                      ]}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        p: "8px",
                        borderRadius: "8px",
                        bgcolor: "#FFFFFF",
                        my: "16px",
                      }}
                    >
                      <Grid
                        item
                        sm={12}
                        sx={{
                          bgcolor: "#FFFFFF",
                          p: "8px",
                          borderRadius: "8px",
                        }}
                      >
                        <Typography sx={{ color: "#5E6062", fontSize: "14px" }}>
                          Stock Status
                        </Typography>
                        <Grid item spacing={2}>
                          <Grid
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              my: 1,
                              bgcolor: "#F8F7FE",
                              p: "8px",
                              borderRadius: "8px",
                              width: "100%",
                              gap: "4px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                gap: "4px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  width: isRemoveActive ? "100%" : "65%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    <Package size={16} color="#8F9193" />
                                    <Typography
                                      sx={{
                                        color: "#5E6062",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {singleProductData?.stock}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#5E6062",
                                        fontSize: "14px",
                                        textTransform: "capitalize !important",
                                      }}
                                    >
                                      {singleProductData?.measurement}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box sx={{ width: "100%", my: 1 }}>
                                  <SPALinearProgress
                                    value={stockPercentageDataHandling(
                                      singleProductData?.stock ?? 0
                                    )}
                                    color="#4E45E5"
                                  />
                                </Box>
                              </Box>
                              {!isRemoveActive && (
                                <Box sx={{}}>
                                  <Button
                                    sx={CancelBtn}
                                    onClick={() => setIsRemoveActive(true)}
                                  >
                                    Remove Stock
                                  </Button>
                                </Box>
                              )}
                            </Box>

                            {isRemoveActive && (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <SPAInputField
                                  control={control}
                                  fullWidth={true}
                                  name={"removeStock"}
                                  label="Stock to Remove"
                                  placeholder="Remove Stock"
                                  labelHelperText="Optional"
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    mt: "4px",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    sx={CancelBtn}
                                    onClick={() => setIsRemoveActive(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    sx={{
                                      backgroundColor:
                                        theme.palette.primaryColor.main,
                                      color: theme.palette.white.main,
                                      paddingInline: "8px",
                                      paddingBlock: "8px",
                                      borderRadius: "8px",
                                      fontWeight: 500,
                                      textTransform: "uppercase",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "8px",
                                      "&:hover": {
                                        backgroundColor:
                                          theme.palette.primaryColor.dark,
                                      },
                                    }}
                                    onClick={() =>
                                      handleStockHandling("Remove")
                                    }
                                  >
                                    Remove Stock
                                  </Button>
                                </Box>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <SPAInputField
                        control={control}
                        fullWidth={true}
                        name={"addStock"}
                        label="Add Stock"
                        placeholder="Add Stock"
                        labelHelperText="Optional"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          mt: "4px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button sx={CancelBtn}>Cancel</Button>
                        <Button
                          sx={{
                            backgroundColor: theme.palette.primaryColor.main,
                            color: theme.palette.white.main,
                            paddingInline: "8px",
                            paddingBlock: "8px",
                            borderRadius: "8px",
                            fontWeight: 500,
                            textTransform: "uppercase",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            "&:hover": {
                              backgroundColor: theme.palette.primaryColor.dark,
                            },
                          }}
                          onClick={() => handleStockHandling("Add")}
                        >
                          Add Stock
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Box>
            {isOpenNotDisableDialog && (
              <SPAAlertDialog
                open={isOpenNotDisableDialog}
                handleClose={() => setIsOpenNotDisableConfirmDialog(false)}
                handleNewTab={() => setIsOpenNotDisableConfirmDialog(false)}
                title={"Can’t Disable This Product"}
                contentText={`You cannot disable this Product because there are pending appointments, discount codes, articles, bundles, or deals associated with it. Please cancel those pending actions before proceeding with the disabling of the Product.`}
                disagreeText={`Go To The Product`}
                agreeText={`Cancel`}
                type="error"
              />
            )}
            {isOpenDisableConfirmDialog && (
              <SPAAlertDialog
                open={isOpenDisableConfirmDialog}
                handleClose={() => setIsOpenDisableConfirmDialog(false)}
                handleNewTab={handleDisableConfirm}
                title={"Product Disabling Confirmation"}
                contentText={`Clients will no longer be able to interact with this Product. This means they won't be able to: view it, book an appointment, add it to favorites, or share it. Are you sure you want to proceed with the removal?`}
                disagreeText={`Disable The Product`}
                agreeText={`Cancel`}
                type="error"
              />
            )}
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
