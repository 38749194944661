/**
 * Primary color palette with various shades.
 */
export const primary = {
  contrast: "#E8EBEE", //mostly used as background-color
  lightest: "#A2ABB7",
  lighter: "#7C889A", //mostly used as outlined-btn bg hover color
  light: "#43556E", //mostly used as outlined-btn bg hover color
  main: "#1D3250", //mostly used as text colors
  dark: "#142338",
  darkest: "#121F31",
  blue: "#EDECFC",
};

/**
 * Secondary color palette with various shades.
 */

export const secondary = {
  contrast: "#E6F2F8", //mostly used as background-color
  lightest: "#97C8E2",
  lighter: "#6CB1D6", //mostly used as outlined-btn bg hover color
  light: "#2C90C5", //mostly used as outlined-btn bg hover color
  main: "#4E45E5", //mostly used as text colors
  dark: "#015582",
  darkest: "#014A71",

};

/**
 * Tertiary color palette with various shades.
 */

export const tertiary = {
  contrast: "#E6E9F0", //mostly used as background-color
  lightest: "#97A4C1",
  lighter: "#6C7EA7", //mostly used as outlined-btn bg hover color
  light: "#2C4782", //mostly used as outlined-btn bg hover color
  main: "#012168", //mostly used as text colors
  dark: "#011749",
  darkest: "#01143F",
};

/**
 * Custom color constants for specific use cases.
 */

export const customColors = {
  success: "#27AE60",
  danger: "#B70101",
  error: "#d32f2f",
  palePink: "#FFE4E4",
  lightGreen: "#E3FFE0",
  white: "#FFFFFF",
  steelBlue: "#F2F5F8",
  darkBlue: "#017ABB",
  paleGrey: "#EFEFEF",
  darkGrey: "#3A3A3A",
  lightGrey: "#747474",
  grey: "#CDCDCD",
  silverGrey: "#F8F8F8",
  foundationBlack: "#01070F",
  warningError: "#FA3",
  bluishGrey: "#dadadb",
  milkyWhite: "#FEFFE6",
  greyWhite: "#FAFAFA",
  lightRed:"#FAEBEB",
  floralWhite:"#FFF9F0",
  darkestBlue: "#2F2989",
  lightBlack: "#5E6062",
  lighterGrey: "#C1C2C3",
  darkRed:"#D13438"
};
