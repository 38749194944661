import {
  Bell,
  CalendarBlank,
  MapPin,
  Phone,
  Trash,
  UserFocus,
  Warning,
} from "@phosphor-icons/react";
import SPAInputField from "../../../hook-form/SPAInputField";
import SPASelectField from "../../../hook-form/SPASelectField";
import SPAMobileDatePicker from "../../../hook-form/SPAMobileDatePicker";
import { customColors, secondary } from "../../../../theme";
import { getLocalStorageItem } from "../../../../utils/localStorage";

let userDetails = getLocalStorageItem("userData");

export const SUPER_ADMIN = userDetails?.role === "SUPER_ADMIN";

export const TreatmentViewNavBarData = [
  {
    label: "Overview",
    icon: UserFocus,
    link: "Overview",
  },
  {
    label: "Address",
    icon: MapPin,
    link: "Address",
  },
  {
    label: "Appointments",
    icon: CalendarBlank,
    link: "Appointments",
  },
  {
    label: "Emergency Contacts",
    icon: Phone,
    link: "EmergencyContacts",
  },
  {
    label: "Notification Settings",
    icon: Bell,
    link: "NotificationSettings",
  },
];

export const OverviewElements = (rolesData) => {
  const elements = [
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "firstName",
        label: "First Name",
        type: "text",
        placeholder: "First Name",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "lastName",
        label: "Last Name",
        type: "text",
        placeholder: "Last Name",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "role",
        label: "Role",
        options:
          rolesData &&
          rolesData?.map((item) => {
            if (SUPER_ADMIN) {
              return { label: "Super Admin", value: "SUPER_ADMIN" };
            } else {
              return { id: item._id, label: item.name, value: item.name };
            }
          }),
        placeholder: "Select Role",
        disabled: true,
      },
      component: SPASelectField,
    },
    {
      gridLength: 3,
      otherOptions: {
        name: "gender",
        label: "Gender",
        labelHelperText: " (Optional)",
        options: [
          { value: "MALE", label: "Male" },
          { value: "FEMALE", label: "Female" },
        ],
        placeholder: "Enter gender",
      },
      component: SPASelectField,
    },
    {
      gridLength: 3,
      otherOptions: {
        name: "dateOfBirth",
        label: "Date of Birth",
        placeholder: "Date of Birth",
        helperText: "(Optional)",
        disableFuture: true,
        disableHighlightToday: true,
      },
      component: SPAMobileDatePicker,
    },
    {
      gridLength: 3,
      otherOptions: {
        name: "mobileNumber",
        label: "Mobile Number",
        type: "text",
        placeholder: "+61 412 345 678",
        labelHelperText: " (Optional)",
      },
      component: SPAInputField,
    },
    // {
    //   gridLength: 3,
    //   otherOptions: {
    //     name: "telephone",
    //     label: "Telephone",
    //     type: "text",
    //     placeholder: "",
    //     labelHelperText: " (Optional)",
    //   },
    //   component: SPAInputField,
    // },
    {
      gridLength: 6,
      otherOptions: {
        name: "email",
        label: "Email",
        type: "email",
        placeholder: "Enter email",
        disabled: true,
      },
      component: SPAInputField,
    },
  ];

  if (userDetails?.role !== "SUPER_ADMIN") {
    elements.push({
      gridLength: 12,
      otherOptions: {
        name: "note",
        label: "Additional Notes",
        placeholder: "Add additional notes here",
        type: "textarea",
        rows: 3,
        labelHelperText: " (Optional)",
      },
      component: SPAInputField,
    });
  }

  return elements;
};

export const addressElements = (addNewAddress, addressesArray) => {
  const newComponent = {
    id: addNewAddress,
    componentArray: [
      {
        gridLength: 5.9,
        otherOptions: {
          fullWidth: true,
          name: "state",
          label: "State",
          placeholder: "",
          options: [
            {
              label: "New South Wales (NSW)",
              value: "New South Wales (NSW)",
            },
            {
              label: "Queensland (QLD)",
              value: "Queensland (QLD)",
            },
            {
              label: "South Australia (SA)",
              value: "South Australia (SA)",
            },
            {
              label: "Tasmania (TAS)",
              value: "Tasmania (TAS)",
            },
            {
              label: "Victoria (VIC)",
              value: "Victoria (VIC)",
            },
            {
              label: "Western Australia (WA)",
              value: "Western Australia (WA)",
            },
            {
              label: "Australian Capital Territory (ACT)",
              value: "Australian Capital Territory (ACT)",
            },
            {
              label: "Northern Territory (NT)",
              value: "Northern Territory (NT)",
            },
          ],
        },
        component: SPASelectField,
      },
      {
        gridLength: 3,
        otherOptions: {
          name: "postalCode",
          label: "Post Code",
          placeholder: "",
        },
        component: SPAInputField,
      },
      {
        gridLength: 8.9,
        otherOptions: {
          name: "street",
          label: "Street",
          placeholder: "",
        },
        component: SPAInputField,
      },
    ],
  };

  return addNewAddress > addressesArray.length
    ? [...addressesArray, newComponent]
    : addressesArray;
};

export const publishingFormData = () => {
  let finalData = [
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "offerType",
        label: "Offer Type",
        placeholder: "Select Offer Type",
        options: [{ label: "Discount", value: "DISCOUNT" }],
      },
      component: SPASelectField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "price",
        label: "Price",
        placeholder: "$ i.e: $5.00",
      },
      component: SPAInputField,
    },
    {
      gridLength: 12,
      otherOptions: {
        fullWidth: true,
        name: "validOffer",
        label: "Offer Valid Until",
        placeholder: "Pick Date",
      },
      component: SPAMobileDatePicker,
    },
  ];
  return finalData;
};

export const addressFormData = () => {
  return [
    {
      gridLength: 6,
      otherOptions: {
        name: "street",
        label: "Street",
        // labelHelperText: " (Optional) ",
        placeholder: "",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        fullWidth: true,
        name: "suburb",
        label: "Suburb",
        placeholder: "",
        // labelHelperText: "(Optional)",
      },
      component: SPAInputField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "state",
        label: "State",
        placeholder: "",
        // labelHelperText: " (Optional)",
        options: [
          {
            label: "New South Wales (NSW)",
            value: "New South Wales (NSW)",
          },
          {
            label: "Queensland (QLD)",
            value: "Queensland (QLD)",
          },
          {
            label: "South Australia (SA)",
            value: "South Australia (SA)",
          },
          {
            label: "Tasmania (TAS)",
            value: "Tasmania (TAS)",
          },
          {
            label: "Victoria (VIC)",
            value: "Victoria (VIC)",
          },
          {
            label: "Western Australia (WA)",
            value: "Western Australia (WA)",
          },
          {
            label: "Australian Capital Territory (ACT)",
            value: "Australian Capital Territory (ACT)",
          },
          {
            label: "Northern Territory (NT)",
            value: "Northern Territory (NT)",
          },
        ],
      },
      component: SPASelectField,
    },
    {
      gridLength: 6,
      otherOptions: {
        name: "postalCode",
        label: "Post Code",
        placeholder: "",
      },
      component: SPAInputField,
    },
  ];
};
