export const SubmitBtn = (theme) => ({
  backgroundColor: theme.palette.primaryColor.main,
  color: theme.palette.white.main,
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.dark,
  },
});

export const DeleteBtn = (theme) => ({
  backgroundColor: "#FAEBEB",
  color: "#D13438",
  paddingInline: "24px",
  paddingBlock: "12px",
  borderRadius: "8px",
  fontWeight: 500,
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    backgroundColor: "#FAEBEB",
  },
});

export const InsertBtn = (theme) => ({
  backgroundColor: "#EDECFC",
  color: "#4E45E5",
  paddingInline: "24px",
  paddingBlock: "10px",
  borderRadius: "8px",
  fontWeight: 500,
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    backgroundColor: "#EDECFC",
  },
});

export const templateStyle = {
  ".tox-tinymce": {
    border: "none !important",
  },
};
