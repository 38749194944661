import { Pencil } from "@phosphor-icons/react";

//popover mock data
export const serviceCategoryPopOverData = (
  setPopoverOpen,
  setIsServiceCategoryDialogOpen
) => [
  {
    id: "1",
    label: "Edit",
    icon: Pencil,
    onClick: () => {
      setPopoverOpen(null);
      setIsServiceCategoryDialogOpen(true);
    },
  },
];
