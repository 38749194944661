import React from "react";
import theme from "../../../../../../theme/theme";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { fs12, fs14, fs20, fw600 } from "../../../../../../theme";
import { GearSix, PaperPlaneTilt } from "@phosphor-icons/react";
import { Envelope } from "@phosphor-icons/react/dist/ssr";
import { AntSwitch } from "../../../../style";
import { Controller } from "react-hook-form";
import SPAEmailCard from "../../../../../common/spa-email-card";
import NoClients from "../../../../../../assets/svg/no-clients";

import useEmailSms from "./useEmailSms";
export default function EmailSms() {
  const { control, emailSmsLoader, smsEmailArray } = useEmailSms();
  return (
    <Grid
      container
      columnGap={2}
      rowGap={{ xs: 3, md: 0 }}
      sx={{ height: "100%" }}
    >
      <Grid
        xs={12}
        md={7}
        style={{
          padding: "16px",
          borderRadius: "8px",
          gap: "16px",
          display: "flex",
          flexDirection: "column",
          height: "auto",
        }}
        bgcolor={theme.palette.purple10.main}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "4px" }}>
            <PaperPlaneTilt size={24} />
            <Typography
              fontSize={fs14}
              fontWeight={fw600}
              color={theme.palette.textGraySecondary.main}
            >
              Email & SMS Sent
            </Typography>
          </Box>
          {emailSmsLoader && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={emailSmsLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                gap: "4px",
                cursor: "pointer",
                alignItems: "center",
                marginRight: "8px",
              }}
            >
              <Envelope size={16} color={theme.palette.primaryColor.main} />
              <Typography
                fontSize={fs12}
                color={theme.palette.primaryColor.main}
              >
                Send Email
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "4px",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              <Envelope size={16} color={theme.palette.primaryColor.main} />
              <Typography
                fontSize={fs12}
                color={theme.palette.primaryColor.main}
              >
                Send SMS
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ height: "400px", overflow: "auto" }}>
          {smsEmailArray?.length > 0 &&
            smsEmailArray?.map((item) => {
              return (
                <SPAEmailCard
                  content={item?.content}
                  media={item?.media}
                  date={item?.date}
                  id={item?.id}
                  subject={item?.subject}
                />
              );
            })}
          {smsEmailArray?.length === 0 && !emailSmsLoader && (
            <Box
              sx={{
                mt: "18px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <NoClients />
              <Typography sx={{ fontSize: fs20, color: "#595959" }}>
                There’s no history of SMS/Email
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>

      {/* All Notes */}
      <Grid
        xs={12}
        md={4.7}
        style={{
          padding: "16px",
          borderRadius: "8px",
          overflow: "auto",
          gap: "16px",
          display: "flex",
          flexDirection: "column",
        }}
        bgcolor={theme.palette.purple10.main}
      >
        <Box sx={{ display: "flex", gap: "4px" }}>
          <GearSix size={24} />
          <Typography
            fontSize={fs14}
            fontWeight={fw600}
            color={theme.palette.textGraySecondary.main}
          >
            Notification Preferences
          </Typography>
        </Box>

        <Typography fontSize={fs12}>
          Configure client preferences for receiving updates. Choose to enable
          or disable promotions and newsletters via email or SMS
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography
            fontSize={fs14}
            fontWeight={fw600}
            mb={1}
            color={theme.palette.textGraySecondary.main}
          >
            Via Email
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              fontSize={fs14}
              color={theme.palette.textGraySecondary.main}
            >
              Promotions
            </Typography>
            <Controller
              name=""
              control={control}
              render={({ field }) => (
                <AntSwitch
                  defaultChecked
                  inputProps={{ "aria-label": "ant design" }}
                  {...field}
                />
              )}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              fontSize={fs14}
              color={theme.palette.textGraySecondary.main}
            >
              NewsLetter
            </Typography>
            <Controller
              name=""
              control={control}
              render={({ field }) => (
                <AntSwitch
                  defaultChecked
                  inputProps={{ "aria-label": "ant design" }}
                  {...field}
                />
              )}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography
            fontSize={fs14}
            fontWeight={fw600}
            my={1}
            color={theme.palette.textGraySecondary.main}
          >
            Via SMS
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              fontSize={fs14}
              color={theme.palette.textGraySecondary.main}
            >
              Promotions
            </Typography>
            <Controller
              name=""
              control={control}
              render={({ field }) => (
                <AntSwitch
                  defaultChecked
                  inputProps={{ "aria-label": "ant design" }}
                  {...field}
                />
              )}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
