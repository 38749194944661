import { Box } from "@mui/material";
import MUIDataTable from "mui-datatables";
import * as React from "react";
import { fs16, fw600 } from "../../../theme";
import SPAPagination from "../spa-pagination";

/**
 * SPATable component.
 * Renders a customized table using MUI DataTable with various options.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array<Object>} props.data - The data to be displayed in the table.
 * @param {Array<Object>} props.columns - The columns configuration for the table.
 *  *  - name: string - The unique identifier for the column. Should be same as key in tableData.
 *   - label: string - Display label for the column header.
 *   - options?: Object - custom configuration of specific column.
 *   *  - filter?: boolean - If true, column filter functionality enable.
 *      - sort?: boolean - If true, cells in this column are sortable.
 *      - setCellProps?: function - A function to customize cell style like width etc.
 *      - customBodyRender: function -  A function to customize the rendering of the cell content in this column.
 *        * - value: any - The value of the cell being rendered.
 *          - tableMeta: object - Metadata about the cell and its row.
 *          - tableMeta.updateValue:: object - A function to update the value of the cell.
 * @param {boolean} [props.print=false] - If true, enables the print option for the table.
 * @param {boolean} [props.download=false] - If true, enables the download option for the table.
 * @param {boolean} [props.viewColumns=false] - If true, enables the view columns option for the table.
 * @param {boolean} [props.filter=false] - If true, enables the filter option for the table.
 * @param {boolean} [props.search=false] - If true, enables the search option for the table.
 * @param {boolean} [props.pagination=true] - If true, displays pagination for the table.
 * @param {boolean} [props.selectableRows=false] - If true, enables selectable rows in the table.
 * @returns {JSX.Element} JSX code for rendering the CustomTable component.
 */

export default function SPATable({
  data,
  columns,
  totalRecords = 10,
  print = false,
  download = false,
  viewColumns = false,
  filter = false,
  search = false,
  pagination = true,
  selectableRows = "none",
  currentPage = 1,
  setCurrentPage = () => {},
  rowsPerPage = 10,
  // onChangePage = () => {},
}) {
  // const [currentPage, setCurrentPage] = useState(0);
  // const [pageData, setPageData] = useState([]);

  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  // useEffect(() => {
  //   // Calculate the slice of data to display
  //   const dataStart = currentPage * rowsPerPage;
  //   const dataEnd = dataStart + rowsPerPage;
  //   setPageData(data.slice(dataStart, dataEnd));
  // }, [currentPage, data]);

  const options = {
    print,
    download,
    viewColumns,
    filter,
    search,
    pagination,
    selectableRows,
    responsive: "standard",
    customFooter: () => (
      <Box sx={{ display: "flex", justifyContent: "center", pt: "16px" }}>
        <SPAPagination
          page={currentPage}
          count={totalPages}
          onChange={(_, newPage) => setCurrentPage(newPage)}
        />
      </Box>
    ),
  };
  return (
    <Box sx={styles.tableRoot}>
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
      />
    </Box>
  );
}
// Styles for the table
const styles = {
  tableRoot: {
    "& .MuiPaper-root": {
      boxShadow: "none !important",
    },
    "& thead": {
      mb: "16px",
      "& tr": {
        "& th": {
          background: "#F8F7FE",
          whiteSpace: "nowrap",
          border: "none",
          p: "8px 16px",
          fontSize: fs16,
          fontWeight: fw600,
          color: "#5E6062",
          "&:first-of-type": {
            borderRadius: "8px 0 0 8px",
          },
          "&:last-of-type": {
            borderRadius: "0 8px 8px 0",
          },
        },
      },
    },
    "& tbody": {
      "& tr": {
        "&:nth-of-type(even)": {
          background: "#F8F7FE",
          borderRadius: "8px",
          "& td": {
            "&:first-of-type": {
              borderRadius: "8px 0 0 8px",
            },
            "&:last-of-type": {
              borderRadius: "0 8px 8px 0",
            },
          },
        },
        "&:nth-of-type(odd)": {
          pt: "8px",
        },
        "& th": {
          display: "none", // hide column names for smaller screens
        },
        "&.MuiTableRow-hover:hover": {
          background: "#F8F7FE",
        },
      },
      "& .MuiTableCell-root": {
        borderBottom: "none",
      },
    },
  },
};
