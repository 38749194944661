import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useGetQualificationsMutation } from "../../../../../../../../services/qualifications";
import { useUpdateSingleStaffMutation } from "../../../../../../../../services/staff";
import { handleFilterSearch } from "../../../../../../../../utils/searchFilter";
import { qualificationValidationSchema } from "../../../../../validationSchema";

export const UseRequirement = ({ singleStaffData, singleStaffRefetching }) => {
  const [searchQualification, setSearchQualification] = useState("");
  const methods = useForm({
    resolver: yupResolver(qualificationValidationSchema()),
  });

  const [
    getAllQualifications,
    { data: qualificationsData, isLoading: qualificationsDataLoader },
  ] = useGetQualificationsMutation();
  const [updateStaff] = useUpdateSingleStaffMutation();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  const handleSearchQualification = () => {
    let attributeArray = [];
    if (searchQualification !== "") {
      attributeArray = ["name"];
      const searchPayload = handleFilterSearch(
        searchQualification,
        attributeArray,
        "search"
      );
      getAllQualifications({ ...searchPayload });
    } else {
      getAllQualifications({});
    }
  };

  useEffect(() => {
    handleSearchQualification();
  }, [searchQualification]);

  const onSubmit = async (values) => {
    const response = await updateStaff({
      id: singleStaffData?.result?.data?.user?._id,
      payload: { ...values, roleType: "STAFF" },
    });

    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    singleStaffRefetching();
  };

  useEffect(() => {
    getAllQualifications();
  }, []);

  const handleInitialValues = () => {
    if (!singleStaffData) return;
    setValue(
      "qualification",
      singleStaffData?.result?.data?.user?.qualification?.map(
        (item) => item?._id
      )
    );
  };

  useEffect(() => {
    if (singleStaffData?.result?.data?.user) {
      handleInitialValues();
    }
  }, [singleStaffData?.result?.data?.user]);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
    qualificationsData,
    qualificationsDataLoader,
    searchQualification,
    setSearchQualification,
  };
};
