import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  useCreateOnboardingMutation,
  useGetAllOnboardingsMutation,
  useGetSingleOnboardingQuery,
  useUpdateOnboardingPositionMutation,
  useUpdateSingleOnboardingMutation,
} from "../../../../services/onboarding";
import { handleFilterSearch } from "../../../../utils/searchFilter";

const validationSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  subtitle: yup.string().required("Subtitle is required"),
});

const useOnboard = () => {
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const [showCreateOnboarding, setShowCreateOnboarding] = useState(true);
  const [isOpenDeleteDialogue, setIsOpenDeleteDialogue] = useState(false);
  const [enabled, setEnabled] = useState(null);
  const [isOpenEditOnboardingDialogue, setIsOpenEditOnboardingDialogue] =
    useState(false);
  const [onboardId, setOnboardId] = useState();
  const [enableOnboardId, setEnableOnboardId] = useState();
  const [searchOnboarding, setSearchOnboarding] = useState("");
  const [activeHistoryTab, setActiveHistoryTab] = useState(0);
  const [isOpenCreationDialogue, setIsOpenCreationDialogue] = useState(false);

  const [createOnboarding, { isLoading: createOnboardingLoading }] =
    useCreateOnboardingMutation();

  const [UpdateSingleOnboarding, { isLoading: UpdateSingleOnboardingLoading }] =
    useUpdateSingleOnboardingMutation();
  const [
    updateOnboardingPosition,
    { isLoading: updateOnboardingPositionLoading },
  ] = useUpdateOnboardingPositionMutation();

  const [
    getAllOnboardings,
    { data: getAllOnboardingsData = [], isLoading: getAllOnboardingsLoading },
  ] = useGetAllOnboardingsMutation();

  const { data: getSingleOnboarding, isLoading: getSingleOnboardingLoading } =
    useGetSingleOnboardingQuery(onboardId, {
      skip: !onboardId,
    });
  const onboardingArray = getAllOnboardingsData?.result?.data;
  const singleOnboardingData = getSingleOnboarding?.result?.data;

  const [isOpenDisabledDialogue, setIsOpenDisabledDialogue] = useState(false);
  const [data, setData] = useState([]);
  const [finalSortedData, setFinalSortedData] = useState([]);
  const [sortedOnboardingArray, setSortedOnboardingArray] = useState([]);

  useEffect(() => {
    if (getAllOnboardingsData?.result?.data) {
      const enabledOnboardingArray = getAllOnboardingsData.result.data.filter(
        (item) => item.isEnableOnboarding === "ENABLED"
      );
      const disabledOnboardingArray = getAllOnboardingsData.result.data.filter(
        (item) => item.isEnableOnboarding !== "ENABLED"
      );

      setSortedOnboardingArray(
        [...enabledOnboardingArray].sort(
          (a, b) => a.positionArrangment - b.positionArrangment
        )
      );
      setData([
        ...enabledOnboardingArray.sort(
          (a, b) => a.positionArrangment - b.positionArrangment
        ),
        ...disabledOnboardingArray,
      ]);
    }
  }, [getAllOnboardingsData]);

  const handleOpenDisabledDialogue = () => {
    setIsOpenDisabledDialogue(true);
  };
  const handleCloseDisabledDialogue = () => {
    setIsOpenDisabledDialogue(false);
  };
  const handleOpenOnboardingDialogue = () => {
    setIsOpenEditOnboardingDialogue(true);
  };

  const handleCloseOnboardingDialogue = () => {
    setIsOpenEditOnboardingDialogue(false);
  };

  const toggleSwitch = () => {
    if (enabled === true) {
      setIsOpenDisabledDialogue(true);
    } else {
      setEnabled(true);
    }
  };

  const handleDisableConfirm = async () => {
    setEnabled(false);
    setIsOpenDisabledDialogue(false);
  };

  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = methods;

  useEffect(() => {
    if (getSingleOnboarding && onboardId) {
      setValue("title", getSingleOnboarding?.result?.data?.title);
      setValue("subtitle", getSingleOnboarding?.result?.data?.subtitle);
      setEnabled(
        getSingleOnboarding?.result?.data?.isEnableOnboarding === "ENABLED"
          ? true
          : false
      );
    }
  }, [getSingleOnboarding, onboardId]);

  const onSubmit = async (values) => {
    let response;
    let payload;
    if (onboardId) {
      payload = {
        ...values,
        isEnableOnboarding: enabled === true ? "ENABLED" : "DISABLED",
        positionArrangment: singleOnboardingData?.positionArrangment,
      };
      response = await UpdateSingleOnboarding({
        id: onboardId,
        payload: payload,
      });
    } else {
      payload = {
        ...values,
        isEnableOnboarding: "DISABLED",
        positionArrangment: Number(onboardingArray?.length + 1 || 1),
      };
      response = await createOnboarding(payload);
      setEnableOnboardId(response?.data?.result?.data?._id);
      setIsOpenCreationDialogue(true);
    }
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success(response?.data?.message);
    getAllOnboardings({});
    setOnboardId(null);
    reset({
      title: "",
      subtitle: "",
    });
  };

  const handleEnableNow = async () => {
    if (enableOnboardId) {
      const response = await UpdateSingleOnboarding({
        id: enableOnboardId,
        payload: { isEnableOnboarding: "ENABLED" },
      });
      if (response?.data?.status === false) {
        toast.error(response?.data?.message);
        return;
      }
      toast.success("Onboarding enabled successfully");
      getAllOnboardings({});
      setEnableOnboardId(null);
      setIsOpenCreationDialogue(false);
    }
  };

  const handleEnableLater = () => {
    setIsOpenCreationDialogue(false);
    setEnableOnboardId(null);
  };

  const handleSort = () => {
    let _data = [...sortedOnboardingArray];
    const draggedItemContent = _data[dragItem.current];

    _data.splice(dragItem.current, 1);
    _data.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    let arr = _data.map((item, index) => ({
      positionArrangment: index + 1,
      onBoardingId: item._id,
    }));

    setSortedOnboardingArray(_data);
    setFinalSortedData(arr);
  };

  const handleSortPositionHandling = async () => {
    let response = await updateOnboardingPosition({
      changePositionArrangment: finalSortedData,
    });
    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    getAllOnboardings({});
    toast.success(response?.data?.message);
    setIsOpenEditOnboardingDialogue(false);
  };

  const handleCreateOnBoard = () => {
    setOnboardId(null);
    reset({
      title: "",
      subtitle: "",
    });
    setShowCreateOnboarding(true);
  };

  const handleSelectOnBoard = (id) => {
    setOnboardId(id);
    setShowCreateOnboarding(false);
  };

  const handleDeleteOnboard = async () => {
    setIsOpenDeleteDialogue(false);
    setOnboardId("");
  };

  const handleSearchOnboarding = () => {
    let attributeArray = [];
    if (searchOnboarding !== "") {
      if (isNaN(searchOnboarding)) attributeArray = ["title"];
      else {
        attributeArray = ["title"];
      }
      const searchPayload = handleFilterSearch(
        searchOnboarding,
        attributeArray,
        "search"
      );
      getAllOnboardings({ ...searchPayload });
    } else getAllOnboardings({});
  };

  const getOrdinalSuffix = (num) => {
    if (num === 1) {
      return "1st";
    } else if (num === 2) {
      return "2nd";
    } else if (num === 3) {
      return "3rd";
    } else {
      return num + "th";
    }
  };

  useEffect(() => {
    handleSearchOnboarding();
  }, [searchOnboarding]);

  const handleTabSelection = (item) => {
    setActiveHistoryTab(item);
    const finalFilter = item === 1 ? "ENABLED" : item === 2 ? "DISABLED" : "";
    const searchPayload = handleFilterSearch(
      [`isEnableOnboarding=${finalFilter}`],
      ["isEnableOnboarding"],
      "filter"
    );
    if (item === 0) {
      getAllOnboardings({});
    } else {
      getAllOnboardings({ ...searchPayload });
    }
  };

  return {
    methods,
    handleSubmit,
    onSubmit,
    handleCreateOnBoard,
    handleSelectOnBoard,
    setShowCreateOnboarding,
    control,
    errors,
    reset,
    onboardId,
    setOnboardId,
    handleDeleteOnboard,
    setIsOpenDeleteDialogue,
    isOpenDeleteDialogue,
    watch,
    enabled,
    setEnabled,
    toggleSwitch,
    handleOpenOnboardingDialogue,
    isOpenEditOnboardingDialogue,
    handleCloseOnboardingDialogue,
    handleSort,
    getAllOnboardingsData,
    dragItem,
    dragOverItem,
    data,
    onboardingArray,
    singleOnboardingData,
    getAllOnboardingsLoading,
    UpdateSingleOnboardingLoading,
    createOnboardingLoading,
    isOpenDisabledDialogue,
    handleCloseDisabledDialogue,
    handleOpenDisabledDialogue,
    setSearchOnboarding,
    handleTabSelection,
    getSingleOnboardingLoading,
    getOrdinalSuffix,
    handleDisableConfirm,
    setIsOpenDisabledDialogue,
    isOpenCreationDialogue,
    setIsOpenCreationDialogue,
    sortedOnboardingArray,
    handleSortPositionHandling,
    handleEnableNow,
    handleEnableLater,
  };
};

export default useOnboard;
