import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useUpdateSingleStaffMutation } from "../../../../../../../../services/staff";
import { validationAddressSchema } from "../../validationSchema";
import { useUpdateAddressesByIdMutation } from "../../../../../../../../services/clients";

export default function useStaffAddress({
  singleStaffData,
  singleStaffRefetching,
}) {
  const methods = useForm({
    resolver: yupResolver(validationAddressSchema),
  });
  const { handleSubmit, control, setValue, reset } = methods;
  const [updateAddress, { isLoading: addressLoading }] =
    useUpdateAddressesByIdMutation();

  const [updateSingleStaff, { isLoading: updatedLoader }] =
    useUpdateSingleStaffMutation();

  const onSubmit = async (values) => {
    let payload = {
      addresses: [
        {
          // city: values.city,
          state: values.state,
          street: values.street,
          postalCode: values.postalCode,
          suburb: values.suburb,
        },
      ],
    };
    const response = await updateAddress({
      id: singleStaffData?.result?.data?.user?._id,
      payload,
    });
    // const response = await updateSingleStaff({
    //   id: singleStaffData?.result?.data?.user?._id,
    //   payload: payload,
    // });

    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    toast.success("Successfully Address Added!");
    singleStaffRefetching();
  };

  useEffect(() => {
    if (singleStaffData) {
      setValue(
        "postalCode",
        singleStaffData?.result?.data?.user?.addresses[0]?.postalCode
      );
      setValue(
        "country",
        singleStaffData?.result?.data?.user?.addresses[0]?.country
      );
      setValue("city", singleStaffData?.result?.data?.user?.addresses[0]?.city);
      setValue(
        "state",
        singleStaffData?.result?.data?.user?.addresses[0]?.state
      );
      setValue(
        "street",
        singleStaffData?.result?.data?.user?.addresses[0]?.street
      );
      setValue(
        "suburb",
        singleStaffData?.result?.data?.user?.addresses[0]?.suburb
      );
    }
  }, [singleStaffData]);

  return {
    control,
    onSubmit,
    handleSubmit,
  };
}
