import { FilterList } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  Archive,
  MagnifyingGlass,
  Plus,
  Trash,
  X,
  ArrowFatLinesDown,
} from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import NoClients from "../../../assets/svg/no-clients";
import NoNotifications from "../../../assets/svg/no-notifications";
import { secondary } from "../../../theme";
import SPATabs from "../../common/spa-tabs";
import SPATextField from "../../common/spa-textField";
import FormProvider from "../../hook-form/FormProvider";
import AddToQualification from "./add-to-qualification";
import {
  qualificationFormData,
  qualificationTabsArray,
} from "./qualification-form-data";
import { DisabledButton, EnabledButton, SubmitBtn } from "./qualificationStyle";
import useQualification from "./useQualification";
import SPAAlertDialog from "../../common/spa-alert-dialogue";
import CustomSnackbar from "./CustomSnackbar";

export default function QualificationSection() {
  const {
    methods,
    handleSubmit,
    onSubmit,
    control,
    activeHistoryTab,
    handleTabSelection,
    qualificationsData,
    singleQualificationId,
    handleCreateCommunication,
    handleSingleQualification,
    serviceCategoryData,
    qualificationsDataLoader,
    singleQualificationLoader,
    setSearchQualification,
    searchTherapist,
    setSearchTherapist,
    setIsOpenSuccessDialog,
    isOpenAddQualificationDialog,
    setIsOpenAddQualificationDialog,
    staffUpdateType,
    setStaffUpdateType,
    setAddQualificationData,
    selectedServicesData,
    selectedUsersData,
    toggleSwitch,
    enabled,
    qualificationNameValue,
    removeSelectedService,
    removeSelectedUser,

    setIsOpenDisableConfirmDialog,
    isOpenDisableConfirmDialog,
    handleDisableConfirm,
    searchTreatment,
    setSearchTreatment,
  } = useQualification();

  const [showServicePopup, setShowServicePopup] = useState(false);
  const [showUserPopup, setShowUserPopup] = useState(false);
  const [isServiceScrolledToBottom, setIsServiceScrolledToBottom] =
    useState(false);
  const [isUserScrolledToBottom, setIsUserScrolledToBottom] = useState(false);

  const serviceListingRef = useRef(null);
  const userListingRef = useRef(null);

  useEffect(() => {
    const handleServiceScroll = () => {
      const element = serviceListingRef.current;
      if (element) {
        const { scrollTop, scrollHeight, clientHeight } = element;
        if (scrollTop > 0) {
          setShowServicePopup(true);
        }
        const atBottom = scrollTop + clientHeight + 1 >= scrollHeight;
        setIsServiceScrolledToBottom(atBottom);
      }
    };

    const serviceElement = serviceListingRef.current;
    if (serviceElement) {
      serviceElement.addEventListener("scroll", handleServiceScroll);
    }

    return () => {
      if (serviceElement) {
        serviceElement.removeEventListener("scroll", handleServiceScroll);
      }
    };
  }, []);
  // view updated

  useEffect(() => {
    const handleUserScroll = () => {
      const element = userListingRef.current;
      if (element) {
        const { scrollTop, scrollHeight, clientHeight } = element;
        if (scrollTop > 0) {
          setShowUserPopup(true);
        }
        const atBottom = scrollTop + clientHeight + 1 >= scrollHeight;
        setIsUserScrolledToBottom(atBottom);
      }
    };

    const userElement = userListingRef.current;
    if (userElement) {
      userElement.addEventListener("scroll", handleUserScroll);
    }

    return () => {
      if (userElement) {
        userElement.removeEventListener("scroll", handleUserScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isServiceScrolledToBottom) {
      setShowServicePopup(false);
    }
  }, [isServiceScrolledToBottom]);

  useEffect(() => {
    if (isUserScrolledToBottom) {
      setShowUserPopup(false);
    }
  }, [isUserScrolledToBottom]);

  useEffect(() => {
    const checkScrollbar = () => {
      const element = serviceListingRef.current;
      if (element) {
        const hasScrollbar = element.scrollHeight > element.clientHeight;
        if (hasScrollbar) {
          setShowServicePopup(true);
        } else {
          setShowServicePopup(false);
        }
      }
    };

    checkScrollbar();
  }, [selectedServicesData]);

  useEffect(() => {
    const checkScrollbar = () => {
      const element = userListingRef.current;
      if (element) {
        const hasScrollbar = element.scrollHeight > element.clientHeight;
        if (hasScrollbar) {
          setShowUserPopup(true);
        } else {
          setShowUserPopup(false);
        }
      }
    };

    checkScrollbar();
  }, [selectedUsersData]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  height: "48px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleCreateCommunication}
              >
                <Plus size={24} color="currentColor" />
                Create Qualification
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              <SPATextField
                placeholder="Search"
                sx={{
                  width: { md: "328px", xs: "180px" },
                  "& .MuiOutlinedInput-root": {
                    height: "48px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} color={"#C1C2C3"} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    ></InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchQualification(event?.target?.value);
                }}
              />

              <Box sx={styles.searchbox}>
                <FilterList sx={{ color: secondary.main }} />
              </Box>
            </Box>
          </Box>
        </Box>

        {(qualificationsDataLoader || singleQualificationLoader) && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={qualificationsDataLoader || singleQualificationLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Grid
          container
          columnGap={3}
          sx={{
            height: "75vh",
            gap: "16px",
          }}
        >
          <Grid item xs={3}>
            <SPATabs
              tabsNameArray={qualificationTabsArray}
              activeTab={activeHistoryTab}
              setActiveTab={handleTabSelection}
            />
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                maxHeight: "66vh",
                overflowY: "auto",
                mb: 2,
              }}
            >
              {qualificationsData?.result?.data?.data?.map((item) => {
                return (
                  <Box
                    key={item?._id}
                    sx={{
                      borderRadius: "16px",
                      bgcolor:
                        singleQualificationId === item?._id
                          ? "#CAC7F7"
                          : "#F8F7FE",
                      padding: "16px",
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSingleQualification(item)}
                  >
                    {item?.showQualification && (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color:
                            item?.showQualification === "ENABLED"
                              ? "#1AA37A"
                              : "#25272B",
                          bgcolor:
                            item?.showQualification === "ENABLED"
                              ? "#E9FBF6"
                              : "#FAFAFA",
                          borderRadius: "4px",
                          p: "4px",
                          width: "fit-content",
                        }}
                      >
                        {item?.showQualification}
                      </Typography>
                    )}
                    <Typography sx={{ fontSize: "20px", color: "#4A4C4F" }}>
                      {item?.name}
                    </Typography>
                  </Box>
                );
              })}
              {qualificationsData?.result?.data?.data?.length === 0 && (
                <Box
                  sx={{
                    mt: "18px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "24px",
                  }}
                >
                  <NoNotifications />
                  <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                    No Qualification Yet
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                    After creating Qualification, they will be displayed here as
                    Qualification.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              borderRadius: "16px",
              bgcolor: "#F8F7FE",
              flexGrow: "1 !important",
              maxWidth: "100% !important",
            }}
          >
            <Box
              sx={{
                p: "24px",
              }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {singleQualificationId && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      mb: "16px",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={{ fontSize: "10px", color: "#25272B" }}>
                        {enabled ? "ENABLED" : "DISABLED"}
                      </Typography>
                      <Typography sx={{ fontSize: "24px", color: "#5E6062" }}>
                        {qualificationNameValue}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px",
                        backgroundColor: "#EDECFC",
                        borderRadius: "8px  ",
                      }}
                    >
                      <EnabledButton
                        active={enabled === true ? true : null}
                        onClick={toggleSwitch}
                      >
                        Enabled
                      </EnabledButton>
                      <DisabledButton
                        active={enabled === false ? true : null}
                        onClick={toggleSwitch}
                      >
                        Disabled
                      </DisabledButton>
                    </Box>
                  </Box>
                )}
                <Grid container sx={{ display: "flex", gap: "24px" }}>
                  <Grid item lg={4}>
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Qualification Information
                      </Typography>
                      <Grid container spacing={1}>
                        {qualificationFormData(
                          serviceCategoryData?.result?.data?.data
                        ).map((item, index) => {
                          return (
                            <Grid item lg={item.gridLength} xs={12} key={index}>
                              <item.component
                                {...item.otherOptions}
                                control={control}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item lg={3.5}>
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                        position: "relative",
                        zIndex: 12,
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Treatments
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                        // sx={qualificationsStyle.addQualificationHeaderMain}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              flexWrap: "wrap",
                              width: "100%",
                              mb: "8px",
                            }}
                          >
                            <SPATextField
                              placeholder={`Search Treatment`}
                              value={searchTreatment}
                              fullWidth={true}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    sx={{ paddingLeft: "10px" }}
                                  >
                                    <MagnifyingGlass
                                      size={24}
                                      color={"#C1C2C3"}
                                    />
                                  </InputAdornment>
                                ),

                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{
                                      paddingLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setSearchTreatment("");
                                    }}
                                  >
                                    {searchTreatment && <X size={24} />}
                                  </InputAdornment>
                                ),
                              }}
                              onChangeValue={(event) => {
                                setSearchTreatment(event.target.value);
                              }}
                            />
                          </Box>
                          <Button
                            disableElevation
                            variant="contained"
                            sx={{
                              background: "#EDECFC",
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                              borderRadius: "8px",
                              height: "32px",
                              color: "#4E45E5",
                              mb: "8px",
                              "&:hover": {
                                background: "#EDECFC",
                                color: "#4E45E5",
                              },
                            }}
                            onClick={() => {
                              setIsOpenAddQualificationDialog(true);
                              setStaffUpdateType("Treatment");
                            }}
                          >
                            <Plus size={24} color="currentColor" />
                            Add Treatment
                          </Button>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                              maxHeight: "43vh",
                              overflowY: "auto",
                            }}
                            ref={serviceListingRef}
                          >
                            {selectedServicesData?.map((item) => {
                              return (
                                <>
                                  <Box
                                    sx={{
                                      borderRadius: "4px",
                                      bgcolor: "#F8F7FE",
                                      p: "12px",
                                      display: "flex",
                                      alignItems: "center",
                                      // justifyContent: "space-between",
                                    }}
                                    onClick={() =>
                                      removeSelectedService(item._id)
                                    }
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#070A0E",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {`${item.name}`}
                                      </Typography>
                                      <Button
                                        sx={{
                                          display: "flex",
                                          gap: "4px",
                                          alignItems: "center",
                                          borderRadius: "8px",
                                          height: "32px",
                                          color: "#D13438",
                                          fontSize: "10px",
                                          "&:hover": {
                                            color: "#D13438",
                                          },
                                        }}
                                      >
                                        <Trash size={16} color="currentColor" />{" "}
                                        Remove
                                      </Button>
                                    </Box>
                                  </Box>
                                </>
                              );
                            })}
                            {selectedServicesData?.length === 0 && (
                              <Box
                                sx={{
                                  mt: "18px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  gap: "24px",
                                }}
                              >
                                <NoClients />
                                <Typography
                                  sx={{ fontSize: "20px", color: "#595959" }}
                                >
                                  Start by adding a Treatment from the bottom at
                                  the top
                                </Typography>
                              </Box>
                            )}
                            {selectedServicesData?.length > 0 && (
                              // <Snackbar
                              //   open={showServicePopup}
                              //   message="Scroll down to find more options"
                              //   anchorOrigin={{
                              //     vertical: "bottom",
                              //     horizontal: "center",
                              //   }}
                              //   ContentProps={{
                              //     sx: {
                              //       backgroundColor: "#2f2989a9",
                              //       zIndex: -1,
                              //       borderRadius: "16px",
                              //       width: "200px",
                              //     },
                              //   }}
                              //   sx={{
                              //     position: "absolute",
                              //     bottom: 0,
                              //     right: 0,
                              //   }}
                              //   action={
                              //     <ArrowFatLinesDown
                              //       size={16}
                              //       color="#ffffff"
                              //     />
                              //   }
                              // />

                              <CustomSnackbar
                                open={showServicePopup}
                                message="Scroll down"
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={3.5}>
                    <Box
                      sx={{
                        p: "16px",
                        bgcolor: "#FFFFFF",
                        borderRadius: "8px",
                        position: "relative",
                        zIndex: 2,
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600", mb: "12px" }}
                      >
                        Therapists
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          flexWrap: "wrap",
                          width: "100%",
                          mb: "8px",
                        }}
                      >
                        <SPATextField
                          placeholder={`Search Staff`}
                          value={searchTherapist}
                          fullWidth={true}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ paddingLeft: "10px" }}
                              >
                                <MagnifyingGlass size={24} color={"#C1C2C3"} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{
                                  paddingLeft: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSearchTherapist("");
                                }}
                              >
                                {searchTherapist && <X size={24} />}
                              </InputAdornment>
                            ),
                          }}
                          onChangeValue={(event) => {
                            setSearchTherapist(event.target.value);
                          }}
                        />
                      </Box>
                      <Button
                        disableElevation
                        variant="contained"
                        sx={{
                          background: "#EDECFC",
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          borderRadius: "8px",
                          height: "32px",
                          color: "#4E45E5",
                          mb: "8px",
                          "&:hover": {
                            background: "#EDECFC",
                            color: "#4E45E5",
                          },
                        }}
                        onClick={() => {
                          setIsOpenAddQualificationDialog(true);
                          setStaffUpdateType("Staff");
                        }}
                      >
                        <Plus size={24} color="currentColor" />
                        Add Therapist
                      </Button>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          maxHeight: "43vh",
                          overflowY: "auto",
                        }}
                        ref={userListingRef}
                      >
                        {selectedUsersData?.map((item) => {
                          return (
                            <Box
                              sx={{
                                borderRadius: "4px",
                                bgcolor: "#F8F7FE",
                                p: "12px",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                              }}
                              onClick={() => removeSelectedUser(item._id)}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#070A0E",
                                    fontSize: "12px",
                                  }}
                                >
                                  {`${item.firstName} ${item.lastName}`}
                                </Typography>
                                <Button
                                  sx={{
                                    display: "flex",
                                    gap: "4px",
                                    alignItems: "center",
                                    borderRadius: "8px",
                                    height: "32px",
                                    color: "#D13438",
                                    fontSize: "10px",
                                    "&:hover": {
                                      color: "#D13438",
                                    },
                                  }}
                                >
                                  <Trash size={16} color="currentColor" />{" "}
                                  Remove
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}
                        {selectedUsersData?.length === 0 && (
                          <Box
                            sx={{
                              mt: "18px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "24px",
                            }}
                          >
                            <NoClients />
                            <Typography
                              sx={{ fontSize: "20px", color: "#595959" }}
                            >
                              Start by adding a Staff from the bottom at the top
                            </Typography>
                          </Box>
                        )}{" "}
                        {selectedUsersData?.length > 0 && (
                          <CustomSnackbar
                            open={showUserPopup}
                            message="Scroll down"
                          />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    p: "24px 0px 12px 0px",
                  }}
                >
                  <Button type="submit" sx={SubmitBtn}>
                    <Archive size={24} />
                    {singleQualificationId
                      ? "Save Changes"
                      : "Create Qualification"}
                  </Button>
                </Box>
              </FormProvider>
            </Box>
          </Grid>
        </Grid>
        {isOpenAddQualificationDialog && (
          <AddToQualification
            setIsOpenSuccessDialog={setIsOpenSuccessDialog}
            singleQualificationId={singleQualificationId}
            isAddQualificationOpen={isOpenAddQualificationDialog}
            setAddQualificationData={setAddQualificationData}
            initialData={
              staffUpdateType === "Treatment"
                ? selectedServicesData
                : selectedUsersData
            }
            handleClose={(data) => {
              setIsOpenAddQualificationDialog(false);
            }}
            dialogTitle={`Add ‘Qualification’ to a ${
              staffUpdateType ?? "Staff"
            }`}
            contentTitle={`${staffUpdateType ?? "Staff"}s`}
            subContentDescription={`Please select the ${
              staffUpdateType ?? "Staff"
            } you want to add this qualification to.`}
            type={staffUpdateType ?? "Staff"}
          />
        )}
        {/* {isOpenNotDisableDialog && (
          <SPAAlertDialog
            open={isOpenNotDisableDialog}
            handleClose={() => setIsOpenNotDisableConfirmDialog(false)}
            handleNewTab={() => setIsOpenNotDisableConfirmDialog(false)}
            title={"Can’t Disable This Qualification"}
            contentText={`You cannot disable this Qualification because there are pending appointments, discount codes, articles, bundles, or deals associated with it. Please cancel those pending actions before proceeding with the disabling of the Product.`}
            disagreeText={`Go To The Product`}
            agreeText={`Cancel`}
            type="error"
          />
        )} */}
        {isOpenDisableConfirmDialog && (
          <SPAAlertDialog
            open={isOpenDisableConfirmDialog}
            handleClose={() => setIsOpenDisableConfirmDialog(false)}
            handleNewTab={handleDisableConfirm}
            title={"Qualification Disabling Confirmation"}
            contentText={`Clients will no longer be able to interact with this Qualification. This means they won't be able to: view it, book an appointment, add it to favorites, or share it. Are you sure you want to proceed with the removal?`}
            disagreeText={`Disable The Qualification`}
            agreeText={`Cancel`}
            type="error"
          />
        )}
      </Box>
    </>
  );
}

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
