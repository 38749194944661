import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { validationSchema } from "../validationSchema";
import { useGetAllClientsMutation } from "../../../services/clients";
import { useUpdateAllProductMutation } from "../../../services/product";
import { handleFilterSearch } from "../../../utils/searchFilter";
import { useCreateSaleMutation } from "../../../services/sales";
import { toast } from "react-toastify";
import { useGetProductTypeMutation } from "../../../services/product-type";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { handleCloseSalesModal } from "../../../store/features/sales";

const useCreateSales = () => {
  const [searchProduct, setSearchProduct] = useState("");
  const [activeItem, setActiveItem] = useState("ALL");
  const [isSearchBox, setIsSearchBox] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isFormEnable, setIsFormEnable] = useState(false);
  const [isDiscountField, setIsDiscountField] = useState(false);
  const [isVoucherField, setIsVoucherField] = useState(false);
  const [isSuccessView, setIsSuccessView] = useState(false);
  const [activeHistoryTab, setActiveHistoryTab] = useState("");
  const [invoicePayload, setInvoicePayload] = useState(null);
  const salesDetail = useSelector((state) => state?.salesModal?.salesDetail);
  const openNewSalesModal = useSelector(
    (state) => state?.salesModal?.openNewSalesModal
  );
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(validationSchema(isFormEnable)),
    defaultValues: {
      markAsPaid: false,
    },
  });

  const [getAllClients, { data: clientsData, isLoading: clientsDataLoader }] =
    useGetAllClientsMutation();
  const [
    getAllProducts,
    { data: productsData, isLoading: productsDataLoader },
  ] = useUpdateAllProductMutation();
  const [
    getAllProductCategory,
    { data: productCategoryData, isLoading: productCategoryDataLoader },
  ] = useGetProductTypeMutation();

  const [createSale, { isLoading: createSaleDataLoader }] =
    useCreateSaleMutation();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const { markAsPaid } = watch();

  const productsSummary = useCallback(
    (total, gst) => [
      { id: 1, label: "Products", value: `$ ${total}` },
      { id: 2, label: "GST (10%)", value: `$ ${gst}` },
    ],
    []
  );

  const handleSearchProduct = useCallback(() => {
    const attributeArray = isNaN(searchProduct) ? ["name"] : ["name"];
    const searchPayload = handleFilterSearch(
      searchProduct,
      attributeArray,
      "search"
    );
    getAllProducts(searchProduct ? { ...searchPayload } : {});
  }, [searchProduct, getAllProducts]);

  const handleCloseSalesForm = useCallback(() => {
    // setAddSalesModel(false);
    dispatch(handleCloseSalesModal());
    setIsDiscountField(false);
    setIsVoucherField(false);
    setIsSuccessView(false);
    reset();
    setIsFormEnable(false);
  }, [reset, handleCloseSalesModal]);

  const handleAddProduct = useCallback((product) => {
    setSelectedProduct((prevSelectedProduct) => {
      const productIndex = prevSelectedProduct.findIndex(
        (item) => item._id === product._id
      );
      if (productIndex === -1)
        return [...prevSelectedProduct, { ...product, quantity: 1 }];

      const updatedProduct = [...prevSelectedProduct];
      updatedProduct[productIndex].quantity += 1;
      return updatedProduct;
    });
  }, []);

  const handleQuantityChange = useCallback((productId, change) => {
    setSelectedProduct((prevSelectedProduct) =>
      prevSelectedProduct
        .map((item) =>
          item._id === productId
            ? { ...item, quantity: item.quantity + change }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  }, []);

  const handleRemoveProduct = (productId) => {
    const filteredArr = selectedProduct.filter(
      (item) => item._id !== productId
    );
    setSelectedProduct(filteredArr);
  };

  const calculateTotal = useCallback(() => {
    const total = selectedProduct.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    const gst = total * 0.1;
    return {
      total: total.toFixed(2),
      gst: gst.toFixed(2),
      totalWithGst: (total + gst).toFixed(2),
    };
  }, [selectedProduct]);

  const handleContinue = useCallback(() => {
    if (selectedProduct.length > 0) setIsFormEnable(true);
    setValue("buyDate", dayjs());
    setValue("buyTime", dayjs());
  }, [selectedProduct]);

  const salesDialogueClose = useCallback(() => {
    handleCloseSalesForm();
    setIsFormEnable(false);
    setIsSearchBox(false);
    setSelectedProduct([]);
  }, [handleCloseSalesForm]);

  const onSubmit = async (values) => {
    console.log("afas", values);
    const transformedProducts = selectedProduct.map((product) => ({
      product: product._id,
      quantity: product.quantity,
    }));
    const payload = {
      ...values,
      buyTime: new Date(values?.buyTime).toISOString(),
      buyDate: new Date(values?.buyDate).toISOString(),
      expireDate: new Date(values?.expireDate).toISOString() ?? undefined,
      totalPriceWithGST: calculateTotal().totalWithGst,
      gst: calculateTotal().gst,
      products: transformedProducts,
      note: values?.note
        ? { note: values?.note, isPinned: false, inCalendar: false }
        : undefined,
    };
    const response = await createSale(payload);

    if (response?.data?.status === false) {
      toast.error(response?.data?.message);
      return;
    }
    if (response?.data?.status === true) {
      toast.success(response?.data?.message);
      setInvoicePayload(response.data.result.data);

      salesDetail?.getAllSales();
      setIsSuccessView(true);
      reset();
      return;
    }
  };

  useEffect(() => {
    handleSearchProduct();
  }, [searchProduct, handleSearchProduct]);

  useEffect(() => {
    getAllClients({ roleType: "CLIENT" });
    const searchPayload = handleFilterSearch(
      ["type=RETAIL_PRODUCT"],
      ["type"],
      "filter"
    );
    getAllProducts({ ...searchPayload });
    getAllProductCategory();
  }, [getAllClients, getAllProducts, getAllProductCategory]);

  const handleProductTypeSelection = () => {
    const retailAndProductType = {
      condition: {
        productTypeId: activeHistoryTab,
        type: "RETAIL_PRODUCT",
      },
    };
    let retailProductsSearchPayload = handleFilterSearch(
      ["type=RETAIL_PRODUCT"],
      ["type"],
      "filter"
    );

    if (activeItem === "ALL") {
      getAllProducts({ ...retailProductsSearchPayload });
    } else {
      getAllProducts({
        ...retailAndProductType,
      });
    }
  };

  useEffect(() => {
    handleProductTypeSelection();
  }, [activeHistoryTab]);

  useEffect(() => {
    if (salesDetail?.clientId) {
      setValue("client", salesDetail?.clientId);
    }
  }, [salesDetail]);

  return {
    isSuccessView,
    onSubmit,
    handleSubmit,
    methods,
    productsData,
    productsDataLoader,
    clientsData,
    control,
    setActiveItem,
    activeItem,
    productsSummary,
    searchProduct,
    setSearchProduct,
    handleCloseSalesForm,
    salesDialogueClose,
    isFormEnable,
    setIsSearchBox,
    isSearchBox,
    handleAddProduct,
    selectedProduct,
    handleQuantityChange,
    handleContinue,
    calculateTotal,
    isVoucherField,
    setIsVoucherField,
    isDiscountField,
    setIsDiscountField,
    productCategoryData,
    setActiveHistoryTab,
    handleRemoveProduct,
    openNewSalesModal,
    productCategoryDataLoader,
    clientsDataLoader,
    invoicePayload,
    markAsPaid,
  };
};

export default useCreateSales;
