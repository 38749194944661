import * as Yup from "yup";

export const validationSchemaCoupon = (couponType) => {
  let schema = Yup.object().shape({
    startDate: Yup.string().required("Start Date is Required"),
    endDate: Yup.string().required("End Date is Required"),
    discount: Yup.string().required("Discount"),
    discountType: Yup.string(),
    customCoupon: Yup.string(),
    code: Yup.string().when("customCoupon", {
      is: "custom",
      then: (schema) => schema.required("Code is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
  if (couponType === "Product") {
    schema = schema.shape({
      products: Yup.array()
        .min(1, "Select at least one Product")
        .required("Select any Product"),
    });
  } else {
    schema = schema.shape({
      services: Yup.array()
        .min(1, "Select at least one Service")
        .required("Select any Service"),
    });
  }
  return schema;
};
