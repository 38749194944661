export const cardStyle = (theme, isPinned) => ({
  padding: '16px',
  borderRadius: '16px',
  backgroundColor: isPinned
    ? theme.palette.primaryColor.light
    : theme.palette.white.main,
  mb: 3,
});

export const flexItem = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
