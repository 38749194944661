import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Warning, X } from "@phosphor-icons/react";
import React from "react";
import { Controller } from "react-hook-form";
import NoClients from "../../../../assets/svg/no-clients.jsx";
import SPADialogue from "../../../common/spa-dialogue/index.jsx";
import SPATextField from "../../../common/spa-textField/index.jsx";
import { staffStyle } from "../staffStyle.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AddQualification({
  isOpenAddQualificationDialog,
  setIsOpenAddQualificationDialog,
  qualificationOnSubmit,
  qualificationError,
  qualificationHandleSubmit,
  qualificationsDataLoader,
  qualificationsData,
  searchQualification,
  setSearchQualification,
  QualificationMethods,
  handleQualificationCloseDialog,
}) {
  // badge has to be fixed
  const transformQualificationData = (data) => {
    const categoryMap = {};

    data?.forEach((item) => {
      const category = item?.category;
      const categoryId = category?._id;

      if (!categoryMap[categoryId]) {
        categoryMap[categoryId] = {
          _id: categoryId,
          name: category?.name,
          color: category?.color,
          qualificationArray: [],
        };
      }

      const qualification = {
        _id: item._id,
        name: item.name,
      };

      categoryMap[categoryId].qualificationArray.push(qualification);
    });

    return Object.values(categoryMap);
  };

  const qualificationFormattedData = transformQualificationData(
    qualificationsData?.result?.data?.data
  );

  return (
    <>
      <SPADialogue
        open={isOpenAddQualificationDialog}
        handleClose={handleQualificationCloseDialog}
        dialogTitle={`Create Staff Member`}
        cancelText="Cancel"
        submitText={"Create Staff Member"}
        handleSubmit={qualificationHandleSubmit}
        onSubmit={qualificationOnSubmit}
        methods={QualificationMethods}
      >
        {qualificationsDataLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 3 }}
            open={qualificationsDataLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={staffStyle.addQualificationHeaderMain}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // p: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "#4A4C4F",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Qualifications
              </Typography>
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: "12px",
                }}
              >
                Please provide details about the staff's qualifications and
                certifications.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <SPATextField
                placeholder="Search Qualification"
                value={searchQualification}
                fullWidth={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <MagnifyingGlass size={24} color={"#C1C2C3"} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ paddingLeft: "10px", cursor: "pointer" }}
                      onClick={() => {
                        setSearchQualification("");
                      }}
                    >
                      {searchQualification && <X size={24} />}
                    </InputAdornment>
                  ),
                }}
                onChangeValue={(event) => {
                  setSearchQualification(event.target.value);
                }}
              />
            </Box>
            <Box
              sx={{
                width: "688px",
                display: "flex",
                flexDirection: "column",
                maxHeight: "43vh",
                overflowY: "auto",
              }}
            >
              {qualificationFormattedData?.map((item) => {
                return (
                  <Accordion
                    defaultExpanded
                    disableGutters
                    elevation={0}
                    key={item?._id}
                    sx={{
                      m: "0px",
                      p: 0,
                      "&.MuiAccordion-root": {
                        backgroundColor: "transparent",
                      },
                      "&.MuiPaper-root": {
                        boxShadow: "none",
                      },
                      "&.MuiAccordion-root::before": {
                        backgroundColor: "transparent",
                      },
                      "&.MuiPaper-root::before": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        color: "#3E37B7",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        p: 0,
                        minHeight: "30px",
                      }}
                    >
                      {item?.name}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        p: "0px",
                        bgcolor: "transparent",
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                      }}
                    >
                      {item?.qualificationArray?.map((subItem) => {
                        return (
                          <Controller
                            key={subItem._id}
                            name="qualification"
                            control={QualificationMethods.control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Box
                                sx={{
                                  borderRadius: "4px",
                                  bgcolor: "#FFFFFF",
                                  px: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const currentValue = Array.isArray(value)
                                    ? value
                                    : [];
                                  const newValue = currentValue.includes(
                                    subItem._id
                                  )
                                    ? currentValue.filter(
                                        (id) => id !== subItem._id
                                      )
                                    : [...currentValue, subItem._id];
                                  onChange(newValue);
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Checkbox
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#4E45E5",
                                      },
                                    }}
                                    checked={
                                      Array.isArray(value) &&
                                      value.includes(subItem._id)
                                    }
                                    onChange={(e) => {
                                      e.stopPropagation();
                                    }}
                                    onBlur={onBlur}
                                  />
                                  <Typography
                                    sx={{ color: "#070A0E", fontSize: "12px" }}
                                  >
                                    {`${subItem.name}`}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          />
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
              {qualificationsData &&
                qualificationsData?.result?.data?.data?.length === 0 &&
                !qualificationsDataLoader && (
                  <Box
                    sx={{
                      mt: "18px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    <NoClients />
                    <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                      There’s no Qualification yet
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                      After creating Qualification, they will be displayed here
                      as Qualification.
                    </Typography>
                  </Box>
                )}
            </Box>
          </Box>
          {qualificationError.qualification && (
            <Typography
              sx={{
                color: "#FFAA33",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                mt: "5px",
              }}
              color="error"
            >
              <Warning color={"#FFAA33"} size={16} />{" "}
              {qualificationError.qualification.message}
            </Typography>
          )}
        </Box>
      </SPADialogue>
    </>
  );
}
