import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  customColors,
  fs12,
  fs14,
  fw600,
  primary,
  secondary,
} from "../../../theme";

/**
 * SPASideBarListButton component.
 * Renders a list item button for the sidebar with customizable styles.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.item - The item object containing information about the sidebar item.
 * @param {Function} props.onClick - The function to call when the button is clicked.
 * @param {string} props.activeItem - The currently active item in the sidebar.
 * @param {boolean} props.isDrawerOpen - Indicates whether the drawer is open or closed.
 * @returns {JSX.Element} JSX code for rendering the SPASideBarListButton component.
 */

const SPASideBarListButton = ({
  isProfile,
  item,
  onClick,
  activeItem,
  isDrawerOpen,
  isLableShow,
  isRemove = false,
  padding = "8px 16px",
}) => {
  const isResponsive = useMediaQuery("(max-width:599px)");
  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        flexDirection: isLableShow ? "column" : "row",
        padding: isResponsive ? "8px 11px" : padding,
        marginBottom: "24px",
        height: isLableShow ? "auto" : isResponsive ? "45px" : "64px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        gap: "4px",
        color:
          activeItem.includes(item.link) && isLableShow
            ? customColors.white
            : isLableShow
            ? secondary.main
            : activeItem.includes(item.link)
            ? secondary.main
            : customColors.white,
        background: isRemove
          ? "#FAEBEB"
          : activeItem.includes(item.link) && isLableShow
          ? secondary.main
          : isLableShow
          ? primary.blue
          : activeItem.includes(item.link)
          ? customColors.white
          : "",
        "&:hover": {
          background: isRemove
            ? "#FAEBEB"
            : activeItem.includes(item.link) && isLableShow
            ? secondary.main
            : isLableShow
            ? primary.blue
            : activeItem.includes(item.link)
            ? customColors.white
            : "",
          color: isRemove
            ? "#D13438"
            : activeItem.includes(item.link) && isLableShow
            ? customColors.white
            : isLableShow
            ? secondary.main
            : activeItem.includes(item.link)
            ? secondary.main
            : customColors.white,
        },
      }}
    >
      {item.icon && (
        <ListItemIcon
          sx={{
            minWidth: "auto",
          }}
        >
          {isProfile && (
            <item.icon
              color={
                isRemove
                  ? "#D13438"
                  : activeItem.includes(item.link)
                  ? secondary.main
                  : customColors.white
              }
            />
          )}
          {!isProfile && (
            <item.icon
              size={24}
              color={
                isRemove
                  ? "#D13438"
                  : activeItem.includes(item.link) && isLableShow
                  ? customColors.white
                  : isLableShow
                  ? secondary.main
                  : activeItem.includes(item.link)
                  ? secondary.main
                  : customColors.white
              }
            />
          )}
        </ListItemIcon>
      )}

      {isLableShow && (
        <ListItemText
          primaryTypographyProps={{
            style: {
              fontSize: isResponsive ? fs12 : fs14,
              fontWeight: fw600,
              textTransform: "uppercase",
              width: "max-content",
              color: isRemove && "#D13438",
            },
          }}
          primary={item.label}
        />
      )}
      {isDrawerOpen && (
        <ListItemText
          sx={{ "& span": { fontWeight: fw600 } }}
          primary={item.label}
        />
      )}
    </ListItemButton>
  );
};

export default SPASideBarListButton;

// Example Usage
// //  <SPASideBarListButton
// item={{
//   label: "Settings",
//   icon: SettingsIcon,
//   link: "/settings",
// }}
// activeItem={activeItem}
// isDrawerOpen={isDrawerOpen}
// onClick={() => {
//   navigate("/settings");
//   setActiveItem("/settings");
// }}
// />
