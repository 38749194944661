import { WarningAmber } from "@mui/icons-material";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { CalendarBlank, CalendarPlus } from "@phosphor-icons/react";
import React from "react";
import NoNotifications from "../../../../../../../../assets/svg/no-notifications";
import {
  handleCloseAppointmentModal,
  handleCloseCreateAppointmentAlert,
  handleSingleAppointmentData,
  handleSingleAppointmentModal,
} from "../../../../../../../../store/features/new-appointment";
import { CircleAvatar } from "../../../../../../../../styleComponents/style";
import {
  customColors,
  fs10,
  fs12,
  fs14,
  fs16,
  fs20,
  fw400,
  fw600,
  secondary,
} from "../../../../../../../../theme";
import theme from "../../../../../../../../theme/theme";
import { nameInitialsAvatar } from "../../../../../../../../utils/nameInitialsAvatar";
import SPAAlertDialog from "../../../../../../../common/spa-alert-dialogue";
import NewAppointmentForm from "../../../../../../../new-appointment-form";
import { overview } from "../../../../style";
import useStaffAppointmentSale from "./useStaffAppointmentSale";

export default function Appointment({
  singleStaffData,
  singleStaffRefetching,
}) {
  const {
    appointmentsArray,
    openAppointmentModal,
    dispatch,
    handleOpenAppointmentModal,
    clientDetail,
    selectedAppointmentId,
    setSelectedAppointmentId,
    createdClientDetail,
    handleCloseAppointmentDialog,
    summaryElements,
    commentDetails,
    navigate,
  } = useStaffAppointmentSale({ singleStaffData, singleStaffRefetching });

  return (
    <>
      {appointmentsArray && appointmentsArray.length > 0 ? (
        <Grid
          container
          columnGap={2}
          rowGap={{ xs: 3, md: 0 }}
          sx={{
            height: "100%",
          }}
        >
          <Grid
            xs={12}
            md={6}
            style={{
              gap: "16px",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              borderRadius: "8px",
            }}
            bgcolor={theme.palette.purple10.main}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "4px" }}>
                <CalendarBlank size={18} />
                <Typography
                  fontSize={fs14}
                  fontWeight={fw600}
                  color={theme.palette.textGraySecondary.main}
                >
                  Appointments
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => {
                  dispatch(handleOpenAppointmentModal(clientDetail));
                }}
              >
                <CalendarPlus
                  size={18}
                  color={theme.palette.primaryColor.main}
                />
                <Typography
                  fontSize={fs14}
                  fontWeight={fw600}
                  color={theme.palette.primaryColor.main}
                >
                  Create
                </Typography>
              </Box>
            </Box>
            <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
              {appointmentsArray.map((item, index) => (
                <>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"8px"}
                    key={index}
                  >
                    <Typography sx={overview.appointmentCardTitle}>
                      {item.title}
                    </Typography>
                    {item.children.map((itemChild, c_index) => {
                      return (
                        <Box
                          key={`${index} ${c_index}`}
                          onClick={() =>
                            setSelectedAppointmentId(itemChild.appointmentId)
                          }
                          // onClick={() => {
                          //   navigate("/calendar");
                          //   dispatch(handleSingleAppointmentModal());
                          //   dispatch(
                          //     handleSingleAppointmentData(
                          //       itemChild?.otherOptions?.appointmentId
                          //     )
                          //   );
                          // }}
                          sx={{ cursor: "pointer" }}
                        >
                          <itemChild.component {...itemChild.otherOptions} />
                        </Box>
                      );
                    })}
                  </Box>
                </>
              ))}
            </Box>
          </Grid>

          {/* Overview */}
          {selectedAppointmentId !== "" ? (
            <Grid
              xs={12}
              md={5.8}
              style={{
                padding: "16px",
                borderRadius: "8px",
                overflow: "auto",
                gap: "16px",
                display: "flex",
                flexDirection: "column",
              }}
              bgcolor={customColors.white}
            >
              {/* header container */}
              <Box>
                <Box sx={{ display: "flex", gap: "4px" }}>
                  <Typography
                    fontSize={fs10}
                    fontWeight={fw600}
                    color={theme.palette.textGraySecondary.main}
                    textTransform={"uppercase"}
                  >
                    Appointment
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "4px" }}>
                    <Typography
                      fontSize={fs20}
                      fontWeight={fw600}
                      color={theme.palette.textGraySecondary.main}
                    >
                      {commentDetails.userName}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "4px",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize={fs14}
                      fontWeight={fw600}
                      color={theme.palette.primaryColor.main}
                      onClick={() => {
                        navigate("/calendar");
                        dispatch(handleSingleAppointmentModal());
                        dispatch(
                          handleSingleAppointmentData(selectedAppointmentId)
                        );
                      }}
                    >
                      View in Calendar
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* notes */}
              <Grid
                container
                gap={2}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                {/* Contraindications */}
                <Grid
                  item
                  xs={12}
                  md={5.8}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "8px",
                    gap: "8px",
                    backgroundColor: "#FFF2E0",
                    padding: "8px",
                    height: "auto",
                  }}
                >
                  <Box>
                    <Box display={"flex"} gap={"4px"}>
                      <WarningAmber
                        sx={{ fontSize: fs16 }}
                        color={customColors.lightBlack}
                      />

                      <Typography
                        fontSize={fs12}
                        fontWeight={fw600}
                        color="#4A4C4F"
                      >
                        Contraindications
                      </Typography>
                    </Box>
                  </Box>

                  <Typography
                    fontSize={fs12}
                    fontWeight={fw400}
                    textAlign={"justify"}
                    height="auto"
                    width="100%" // Set width to allow text wrapping
                    wrap="wrap"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {commentDetails.contraindication.text !== ""
                      ? commentDetails.contraindication.text
                      : "No contraindication added"}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize={fs10}
                      fontWeight={fw400}
                      color={customColors.lightBlack}
                      textTransform={"uppercase"}
                    >
                      Edited
                    </Typography>
                    <Typography
                      fontSize={fs12}
                      fontWeight={fw400}
                      marginLeft={"10px"}
                    >
                      {commentDetails.contraindication.edited !== ""
                        ? commentDetails.contraindication.edited
                        : "Today"}
                    </Typography>
                  </Box>
                </Grid>
                {/* Pinned Notes*/}
                <Grid
                  item
                  xs={12}
                  md={5.7}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "8px",
                    gap: "8px",
                    backgroundColor: "#EDECFC",
                    padding: "8px",
                  }}
                >
                  <Box>
                    <Box display={"flex"} gap={"4px"}>
                      <WarningAmber
                        sx={{ fontSize: fs16 }}
                        color={customColors.lightBlack}
                      />

                      <Typography
                        fontSize={fs12}
                        fontWeight={fw600}
                        color="#4A4C4F"
                      >
                        Pinned Note
                      </Typography>
                    </Box>
                  </Box>

                  <Typography
                    fontSize={fs12}
                    fontWeight={fw400}
                    textAlign={"justify"}
                    height="auto"
                    width="100%" // Set width to allow text wrapping
                    wrap="wrap"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {commentDetails.note.text !== ""
                      ? commentDetails.note.text
                      : "No contraindication added"}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize={fs10}
                      fontWeight={fw400}
                      color={customColors.lightBlack}
                      textTransform={"uppercase"}
                    >
                      Added
                    </Typography>
                    <Typography
                      fontSize={fs12}
                      fontWeight={fw400}
                      marginLeft={"10px"}
                    >
                      {commentDetails.note.added !== ""
                        ? commentDetails.note.added
                        : "Today"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* Summary */}
              {selectedAppointmentId && (
                <Box
                  sx={{ display: "flex", flexDirection: "column" }}
                  gap={2.5}
                >
                  <Typography
                    fontSize={fs16}
                    fontWeight={fw600}
                    color={secondary.lightBlack}
                  >
                    Summary
                  </Typography>

                  {summaryElements?.length > 0 &&
                    summaryElements.map((summaryItem) => {
                      const initals =
                        (summaryItem.title === "Client" ||
                          summaryItem.title === "Staff") &&
                        summaryItem.details.content.text.split(" ");

                      return (
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          gap={10}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              width: "25%",
                            }}
                          >
                            <summaryItem.icon fontSize={fs16} color="#4A4C4F" />
                            <Typography
                              fontSize={fs10}
                              fontWeight={fw400}
                              color={secondary.lightBlack}
                              textTransform={"uppercase"}
                            >
                              {summaryItem.title}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            {summaryItem.title === "Time" ? (
                              <Typography
                                fontSize={fs12}
                                color={summaryItem.details.content.color}
                                sx={{
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {summaryItem.details.content.startText}
                                <summaryItem.details.icon />
                                {summaryItem.details.content.endText}
                                <Typography
                                  fontSize={fs12}
                                  color={summaryItem.details.differenceColor}
                                  marginLeft={1}
                                >
                                  {summaryItem.details.difference}
                                </Typography>
                              </Typography>
                            ) : (
                              <>
                                {summaryItem.details?.img === "" && (
                                  <CircleAvatar width={24} height={24}>
                                    {summaryItem.details?.img ? (
                                      <img
                                        src={summaryItem.details?.img}
                                        alt="Profile"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "fill",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    ) : (
                                      <Avatar
                                        {...nameInitialsAvatar(
                                          `${initals[0]} ${initals[1]}`,
                                          "24px"
                                        )}
                                        variant="rounded"
                                      />
                                    )}
                                  </CircleAvatar>
                                )}
                                {summaryItem.details?.content?.circleColor && (
                                  <Box
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                      background:
                                        summaryItem.details?.content
                                          ?.circleColor,
                                      borderRadius: "50%",
                                    }}
                                  />
                                )}
                                <Typography
                                  fontSize={fs12}
                                  color={summaryItem.details.content.color}
                                >
                                  {summaryItem.details.content.text}
                                </Typography>
                              </>
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              )}
            </Grid>
          ) : (
            <Grid
              xs={12}
              md={5.7}
              style={{
                padding: "16px",
                borderRadius: "8px",
                overflow: "auto",
                gap: "16px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              bgcolor={theme.palette.purple10.main}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                overflow={"hidden"}
              >
                <NoNotifications />
                <Typography sx={{ fontSize: fs16, color: "#595959" }}>
                  No Appointment chosen yet
                </Typography>
                <Typography sx={{ fontSize: fs12, color: "#595959" }}>
                  Chose an Appointment, to view its summary.
                </Typography>
              </Box>
            </Grid>
          )}

          {/* appointment */}
          {openAppointmentModal && (
            <NewAppointmentForm
              getAllClients={() => {}}
              isAppointmentOpen={openAppointmentModal}
              handleClose={() => dispatch(handleCloseAppointmentModal())}
            />
          )}

          <SPAAlertDialog
            handleClose={handleCloseAppointmentDialog}
            title={"New Appointment Created"}
            contentText={`New appointment for ${createdClientDetail?.clientName} was created. For the date ${createdClientDetail?.date}`}
            disagreeText={`Create another appointment`}
            agreeText={`Close`}
            handleNewTab={() => {
              dispatch(handleOpenAppointmentModal(clientDetail));
              dispatch(handleCloseCreateAppointmentAlert());
            }}
          />
        </Grid>
      ) : (
        <>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            overflow={"hidden"}
          >
            <NoNotifications />
            <Typography sx={{ fontSize: fs16, color: "#595959" }}>
              No Appointment Yet
            </Typography>
            <Typography sx={{ fontSize: fs12, color: "#595959" }}>
              After creating Appointment, they will be displayed here as
              Appointment.
            </Typography>
          </Box>
        </>
      )}
    </>
  );
}
