import * as Yup from "yup";

export const validationProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  mobileNumber: Yup.string()
    .matches(
      /^\+?\d{10,14}$/,
      "Invalid format. Provide Valid Format of 10 to 14 digits"
    )
    .test(
      "mobile-format",
      "Invalid format. Provide Valid Format of 10 to 14 digits",
      function (value) {
        if (!value) return true;
        return /^\+?\d{10,14}$/.test(value);
      }
    ),
  email: Yup.string().email("Invalid email format"),
  dateOfBirth: Yup.string().nullable(),
  occupation: Yup.string(),
  company: Yup.string(),
  gender: Yup.string(),
  referralSource: Yup.string(),
  friend: Yup.string().when("referralSource", {
    is: "friend",
    then: Yup.string().required(
      "Friend's name is required when referral source is friend"
    ),
  }),
});

export const validationAddressSchema = Yup.object().shape({
  postalCode: Yup.string().required("Post code is required"),
  suburb: Yup.string().required("Suburb is required"),
  state: Yup.string().required("State is required"),
  street: Yup.string().required("Street is required"),
  _id: Yup.string(),
});
