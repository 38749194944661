import { Box, Button, Grid, Typography } from "@mui/material";
import { Play } from "@phosphor-icons/react";
import React from "react";
import { fw600 } from "../../../../../theme";
import { SubmitBtn } from "../../../../profile-model/components/sales/salesStyle";
import SPAInputField from "../../../../hook-form/SPAInputField";
import FormProvider from "../../../../hook-form/FormProvider";

import SPADialogue from "../../../../common/spa-dialogue";
import NoNotifications from "../../../../../assets/svg/no-notifications";
import SPAAlertDialog from "../../../../common/spa-alert-dialogue/index.jsx";

export const CreateNewOnboarding = ({
  control,
  handleSubmit,
  onSubmit,
  isOpenCreationDialogue,
  handleEnableNow,
  handleEnableLater,
}) => {
  return (
    <>
      {isOpenCreationDialogue && (
        <>
          <SPAAlertDialog
            open={isOpenCreationDialogue}
            handleClose={handleEnableNow}
            title={"Onboarding Screen Created"}
            contentText={`  This onboarding screen is not viewable for the client yet. Do you
              want to enable this onboarding screen?`}
            disagreeText={`Enable Later`}
            agreeText={`Enbale Now`}
            handleNewTab={() => {
              handleEnableLater();
            }}
          />
          {/* <SPADialogue
            open={isOpenCreationDialogue}
            handleClose={handleEnableLater}
            dialogTitle={"Onboarding Screen Created"}
          >
            <Typography
              sx={{
                fontSize: "16px",
                paddingBottom: "16px",
                fontWeight: fw600,
                color: "#5E6062",
              }}
            >
              This onboarding screen is not viewable for the client yet. Do you
              want to enable this onboarding screen?
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "flex-end",
                mt: "8px",
              }}
            >
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: "#EDECFC",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  height: "48px",
                  color: "#4E45E5",
                  "&:hover": { background: "#EDECFC", color: "#4E45E5" },
                }}
                onClick={handleEnableLater}
              >
                Enable Later
              </Button>
              <Button sx={SubmitBtn} onClick={handleEnableNow}>
                Enable Now
              </Button>
            </Box>
          </SPADialogue> */}
        </>
      )}
      <FormProvider onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            bgcolor: "#EDECFC",
            borderRadius: "8px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              gap: 2,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "16px",
                gap: "16px",
                bgcolor: "#FFFFFF",
                borderRadius: "8px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    paddingBottom: "16px",
                    fontWeight: fw600,
                    color: "#5E6062",
                  }}
                >
                  Content
                </Typography>
                <Box>
                  <SPAInputField
                    name="title"
                    label="Title"
                    type="text"
                    placeholder="Enter the title here"
                    control={control}
                  />
                  <SPAInputField
                    name="subtitle"
                    label="Subtitle"
                    type="textarea"
                    placeholder="Enter the subtitle here"
                    control={control}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "16px",
              padding: "16px",
            }}
          >
            <Button
              disableElevation
              variant="contained"
              sx={{
                background: "#EDECFC",
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                height: "48px",
                color: "#4E45E5",
                "&:hover": { background: "#EDECFC", color: "#4E45E5" },
              }}
            >
              Cancel
            </Button>
            <Button sx={SubmitBtn} type="submit" startIcon={<Play />}>
              Create onboarding Screen
            </Button>
          </Box>
        </Box>
        {/* {isOpenEditOnboardingDialog && (
          <>
            <SPADialogue
              open={isOpenEditOnboardingDialog}
              handleClose={handleCloseOnboardingDialog}
              dialogTitle={`Arrange onboarding screens`}
              cancelText="Cancel"
              submitText={"Save changes"}
              handleSubmit={editOnboardingHandleSubmit}
              onSubmit={editOnboardingOnSubmit}
              methods={editOnboardingMethods}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "30vw",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                {data?.map((item, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "30vw",
                      gap: "16px",
                    }}
                    key={index}
                    draggable
                    onDragStart={() => (dragItem.current = index)}
                    onDragEnter={() => (dragOverItem.current = index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <Box>
                      <Button variant="outlined" disableElevation disableRipple>
                        {index + 1}
                      </Button>
                    </Box>
                    <Box
                      key={item?._id}
                      sx={{
                        borderRadius: "16px",
                        bgcolor: "#F8F7FE",
                        padding: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#4A4C4F",
                          mt: "8px",
                          fontWeight: "600",
                        }}
                      >
                        {item?.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color:
                            item.status === "enabled" ? "darkgreen" : "red",
                          mt: "8px",
                          fontWeight: "600",
                        }}
                      >
                        {item?.status}
                      </Typography>
                    </Box>
                  </Box>
                ))}
                {dummyData?.length === 0 && (
                  <Box
                    sx={{
                      mt: "18px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      gap: "24px",
                    }}
                  >
                    <NoNotifications />
                    <Typography sx={{ fontSize: "20px", color: "#595959" }}>
                      No Onboardings Yet
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "#595959" }}>
                      After creating onbaording, they will be displayed here as
                      onboarding.
                    </Typography>
                  </Box>
                )}
              </Box>
            </SPADialogue>
          </>
        )} */}
      </FormProvider>
    </>
  );
};
