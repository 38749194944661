import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { routes } from "./routes";
import "./App.css";
import { useEffect } from "react";

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === "/") {
      navigate("/calendar");
    }
  }, [pathname]);
  const pages = useRoutes(routes);
  return <>{pages}</>;
}

export default App;
