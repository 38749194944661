import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  useGetSalesMutation,
  useGetSingleSaleQuery,
} from "../../services/sales";
import { fs14, fw600 } from "../../theme";
import { nameInitialsAvatar } from "../../utils/nameInitialsAvatar";
import { handleFilterSearch } from "../../utils/searchFilter";
import { useUpdateAllProductMutation } from "../../services/product";
import { handleOpenRetailProductViewModal } from "../../store/features/products";
import { useDispatch, useSelector } from "react-redux";
import { handleOpenSalesModal } from "../../store/features/sales";

const useSales = () => {
  const dispatch = useDispatch();
  const [searchSales, setSearchSales] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [singleSaleId, setSingleSaleId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [addSalesModel, setAddSalesModel] = useState(false);
  const [singleSaleDrawer, setSingleSaleDrawer] = useState(false);
  const [isSuccessDialogue, setIsSuccessDialogue] = useState(false);
  const [isClientProfileModalOpen, setIsClientProfileModalOpen] =
    useState(false);
  const [getAllSales, { data: salesData, isLoading: salesDataLoader }] =
    useGetSalesMutation();
  const [
    getAllProducts,
    { data: productsData, isLoading: productsDataLoader },
  ] = useUpdateAllProductMutation();
  const {
    data: singleSale,
    refetch: refetchSingleSale,
    isLoading: singleSaleLoader,
  } = useGetSingleSaleQuery(singleSaleId, {
    skip: !singleSaleId,
  });
  const openNewSalesModal = useSelector(
    (state) => state?.salesModal?.openNewSalesModal
  );

  const singleSaleData = singleSale?.result?.data;
  const totalRecords = salesData?.result?.data?.totalRecords;
  const salesTableHeader = [
    {
      name: "client",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta?.tableData;
          const lastName = rowData[tableMeta?.rowIndex]?.client?.lastName;
          const firstName = rowData[tableMeta?.rowIndex]?.client?.firstName;
          const imgSource =
            rowData[tableMeta?.rowIndex]?.client?.profilePictureUrl;
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
              onClick={() => {
                setSingleSaleId(rowData[tableMeta?.rowIndex]?._id);
                setSingleSaleDrawer(true);
              }}
            >
              {imgSource ? (
                <Avatar
                  sx={{ width: "48px", height: "48px", cursor: "pointer" }}
                  src={imgSource}
                  onClick={() => {}}
                  variant="rounded"
                />
              ) : (
                <Avatar
                  {...nameInitialsAvatar(`${firstName} ${lastName}`)}
                  onClick={() => {}}
                  variant="rounded"
                />
              )}
              <Typography
                sx={{
                  color: "#070A0E",
                  fontSize: fs14,
                  fontWeight: fw600,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
                onClick={() => {}}
              >
                {firstName} {lastName}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "totalPriceWithGST",
      label: "Total Spent",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: fs14,
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                {value ? `$ ${value}` : "---"}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "buyDate",
      label: "Sale Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.tableData;
          const buyTime = rowData[tableMeta?.rowIndex]?.buyTime;
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#5E6062",
                  fontSize: fs14,
                  fontWeight: "600",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography>
                  {" "}
                  {dayjs(value).format("DD/MM/YYYY") ?? "---"}
                </Typography>
                <Typography>
                  {" "}
                  {dayjs(buyTime).format("hh:MM A") ?? " "}
                </Typography>
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "products",
      label: "Products",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <AvatarGroup total={value?.length}>
                {value?.map((item, index) => {
                  return item?.product?.imageUrl ? (
                    <Avatar
                      key={index}
                      src={item?.product?.imageUrl}
                      onClick={() => {
                        if (item?.product?._id) {
                          dispatch(
                            handleOpenRetailProductViewModal({
                              singleProductId: item?.product?._id,
                              getAllProducts: () => {},
                              getStockProducts: () => {},
                            })
                          );
                        }
                      }}
                      variant="rounded"
                    />
                  ) : (
                    <Avatar
                      key={index}
                      {...nameInitialsAvatar(`${item?.product?.name}`)}
                      onClick={() => {
                        if (item?.product?._id) {
                          dispatch(
                            handleOpenRetailProductViewModal({
                              singleProductId: item?.product?._id,
                              getAllProducts: () => {},
                              getStockProducts: () => {},
                            })
                          );
                        }
                      }}
                      variant="rounded"
                    />
                  );
                })}
              </AvatarGroup>
            </Box>
          );
        },
      },
    },
  ];

  const handleSearchSales = () => {
    if (searchSales !== "") {
      getAllSales({
        condition: {
          clientName: searchSales,
        },
      });
    } else getAllSales({});
  };

  useEffect(() => {
    handleSearchSales();
  }, [searchSales]);

  const handleSaleModel = () => {
    setAddSalesModel(true);
    dispatch(handleOpenSalesModal({ getAllSales: getAllSales }));
  };

  const handleOpenClientProfile = () => {
    setIsClientProfileModalOpen(true);
  };
  const handleCloseClientProfile = () => {
    setIsClientProfileModalOpen(false);
  };

  const handleSearchProduct = () => {
    let attributeArray = [];
    const filterPayload = handleFilterSearch(
      ["type=RETAIL_PRODUCT"],
      ["type"],
      "filter"
    );
    if (searchProduct !== "") {
      if (isNaN(searchProduct)) attributeArray = ["name"];
      else {
        attributeArray = ["name"];
      }

      const searchPayload = handleFilterSearch(
        searchProduct,
        attributeArray,
        "search"
      );
      getAllProducts({ ...searchPayload });
    } else getAllProducts({ ...filterPayload });
  };

  useEffect(() => {
    handleSearchProduct();
  }, [searchProduct]);

  useEffect(() => {
    getAllSales();
    const filterPayload = handleFilterSearch(
      ["type=RETAIL_PRODUCT"],
      ["type"],
      "filter"
    );
    getAllProducts({ ...filterPayload });
  }, []);

  useEffect(() => {
    getAllSales({ page: currentPage, pageSize: 10 });
  }, [currentPage]);

  return {
    dispatch,
    getAllSales,
    searchSales,
    setSearchSales,
    singleSaleId,
    setSingleSaleId,
    salesTableHeader,
    salesData,
    salesDataLoader,
    currentPage,
    setCurrentPage,
    totalRecords,
    handleSaleModel,
    addSalesModel,
    setAddSalesModel,
    productsData,
    productsDataLoader,
    searchProduct,
    setSearchProduct,
    singleSaleDrawer,
    setSingleSaleDrawer,
    singleSaleData,
    isSuccessDialogue,
    setIsSuccessDialogue,
    singleSaleLoader,
    openNewSalesModal,
    isClientProfileModalOpen,
    setIsClientProfileModalOpen,
    handleCloseClientProfile,
    handleOpenClientProfile,
  };
};

export default useSales;
