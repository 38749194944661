import React from "react";
import useNewRole from "./useNewRole.js";
import { Box, Grid, Typography } from "@mui/material";
import SPASwitch from "../../../../../common/spa-switch/index.jsx";
import { Bell } from "@phosphor-icons/react";
const Notification = () => {
  const { permissionsData, control } = useNewRole();
  return (
    <div>
      <Grid container>
        <Grid
          item
          lg={6}
          xs={12}
          sx={{
            bgcolor: "#F8F7FE",
            borderRadius: "8px",
            mt: "16px",
            p: "16px",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              color: "#4A4C4F",
              fontWeight: "600",
            }}
          >
            <Bell size={24} color="#5E6062" /> Via Platform
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              flexDirection: "column",
              mt: "8px",
            }}
          >
            {permissionsData?.map((item) => {
              return (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#5E6062", fontSize: "14px" }}>
                    {item.label}
                  </Typography>{" "}
                  <SPASwitch name={item.value} control={control} />
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Notification;
