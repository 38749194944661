import { FilterList } from "@mui/icons-material";
import { Box, InputAdornment, Typography } from "@mui/material";
import { MagnifyingGlass, X } from "@phosphor-icons/react";
import React from "react";
import { secondary } from "../../../theme";
import SPATextField from "../../common/spa-textField";
import ProductCard from "../product-card";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleOpenRetailProductViewModal } from "../../../store/features/products";

const ProductListing = ({
  productsData,
  productsDataLoader,
  searchProduct,
  setSearchProduct,
  handleSingleProductDeselect,
  handleRemoveProduct,
  selectedProduct,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        bgcolor: "#F8F7FE",
        borderRadius: "16px",
        height: "100%",
        p: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ fontSize: "24px", color: "#4A4C4F" }}>
              Products
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#4E45E5",
                textTransform: "uppercase",
                fontWeight: "600",
                mr: "12px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/resource-management")}
            >
              View In Management
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "4px",
              width: "100%",
              mt: "16px",
            }}
          >
            <SPATextField
              placeholder="Search Product"
              value={searchProduct}
              fullWidth={true}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                    <MagnifyingGlass size={24} color={"#C1C2C3"} />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    onClick={() => {
                      setSearchProduct("");
                    }}
                  >
                    {searchProduct && <X size={24} />}
                  </InputAdornment>
                ),
              }}
              onChangeValue={(event) => {
                setSearchProduct(event.target.value);
              }}
            />

            <Box sx={{ ...styles.searchbox, p: "6px 8px 4px 8px" }}>
              <FilterList sx={{ color: secondary.main }} />
            </Box>
          </Box>
          <ProductCard
            productsData={productsData?.result?.data?.data}
            productLoader={productsDataLoader}
            handleSingleProductSelect={(item) => {
              dispatch(
                handleOpenRetailProductViewModal({
                  singleProductId: item?._id,
                  getAllProducts: () => {},
                  getStockProducts: () => {},
                })
              );
            }}
            handleSingleProductDeselect={(item) => handleRemoveProduct(item)}
            selectedProducts={selectedProduct}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProductListing;

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
