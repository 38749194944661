import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openRetailProductViewModal: false,
  retailsProductsDetails: {},
  openProfessionalProductViewModal: false,
  professionalProductsDetails: {},
};

export const retailsProductSlice = createSlice({
  name: "retailsProducts",
  initialState,
  reducers: {
    handleOpenRetailProductViewModal: (state, action) => {
      state.openRetailProductViewModal = true;
      state.retailsProductsDetails = action.payload;
    },
    handleCloseRetailProductViewModal: (state) => {
      state.openRetailProductViewModal = false;
      state.retailsProductsDetails = {};
    },
    handleOpenProfessionalProductViewModal: (state, action) => {
      state.openProfessionalProductViewModal = true;
      state.professionalProductsDetails = action.payload;
    },
    handleCloseProfessionalProductViewModal: (state) => {
      state.openProfessionalProductViewModal = false;
      state.professionalProductsDetails = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handleOpenRetailProductViewModal,
  handleCloseRetailProductViewModal,
  handleOpenProfessionalProductViewModal,
  handleCloseProfessionalProductViewModal,
} = retailsProductSlice.actions;

export default retailsProductSlice.reducer;
