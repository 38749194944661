import { Check, Delete, WarningAmberOutlined } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { NoteBlank } from "@phosphor-icons/react/dist/ssr";
import React from "react";
import NoNotifications from "../../../../../../assets/svg/no-notifications";
import {
  customColors,
  fs12,
  fs14,
  fs16,
  fw400,
  fw600,
  secondary,
} from "../../../../../../theme";
import theme from "../../../../../../theme/theme";
import Loader from "../../../../../common/loader";
import { addContraindications, addNotes } from "../../client-profile-data";
import useClientAdditionalNotes from "./useClientAdditionalNotes";
import { SubmitBtn } from "../../../sales/salesStyle";

export default function AdditionalNotes() {
  const {
    handleCreateAdditionalNotes,
    handleAdditionalNoteContent,
    additionalNoteContent,
    noteError,
    isPinnedNotesArray,
    notPinnedNotesArray,
    handlePineNotes,
    isLoadingCreateNote,
    isLoadingUpdateNote,
    isLoadingUpdateContra,
    isEnableCreateAdditionalContent,
    handleContraindicationContent,
    isEnableUpdateContraindication,
    handleContraindicationUpdate,
    contraindicationContent,
    contraindicationArrayData,
    handleDeleteContraindication,
  } = useClientAdditionalNotes();

  return (
    <Box sx={{ height: "100%" }}>
      {(isLoadingCreateNote ||
        isLoadingUpdateNote ||
        isLoadingUpdateContra) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            isLoadingCreateNote || isLoadingUpdateNote || isLoadingUpdateContra
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid
        container
        columnGap={2}
        rowGap={{ xs: 3, md: 0 }}
        sx={{ height: "100%" }}
      >
        <Grid
          xs={12}
          md={5.7}
          style={{
            padding: "16px",
            borderRadius: "8px",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
          }}
          bgcolor={theme.palette.purple10.main}
        >
          <Box sx={{ display: "flex", gap: "4px" }}>
            <NoteBlank size={24} />
            <Typography
              fontSize={fs16}
              fontWeight={fw600}
              color={theme.palette.textGraySecondary.main}
            >
              Additional Notes
            </Typography>
          </Box>
          {/* New Note */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography
              fontSize={fs14}
              fontWeight={fw400}
              color={customColors.lightBlack}
            >
              Create Note
            </Typography>

            <Box>
              <React.Fragment>
                {addNotes(
                  handleAdditionalNoteContent,
                  noteError,
                  additionalNoteContent
                ).map((item) =>
                  item.componentArray.map((child, childIndex) => (
                    <child.component key={childIndex} {...child.otherOptions} />
                  ))
                )}
              </React.Fragment>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                sx={SubmitBtn}
                onClick={handleCreateAdditionalNotes}
                type="button"
              >
                Create Note
              </Button>
            </Box>
          </Box>
          <Divider />
          <Typography
            sx={{
              color: customColors.lightBlack,
              fontSize: fs14,
              fontWeight: fw400,
            }}
          >
            All Notes
          </Typography>
          <Box
            sx={{
              height: isPinnedNotesArray?.length === 0 ? "auto" : "250px",
              overflow: "auto",
            }}
          >
            {isPinnedNotesArray?.length > 0 &&
              isPinnedNotesArray.map((pinnedNote) => (
                <pinnedNote.component
                  {...pinnedNote.otherOptions}
                  handlePinNotes={() =>
                    handlePineNotes(pinnedNote.otherOptions)
                  }
                />
              ))}

            {notPinnedNotesArray?.length > 0 &&
              notPinnedNotesArray.map((pinnedNote) => (
                <pinnedNote.component
                  {...pinnedNote.otherOptions}
                  handlePinNotes={() =>
                    handlePineNotes(pinnedNote.otherOptions)
                  }
                />
              ))}

            {isPinnedNotesArray?.length === 0 &&
              notPinnedNotesArray?.length === 0 && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  overflow={"hidden"}
                >
                  <NoNotifications />
                  <Typography sx={{ fontSize: fs16, color: "#595959" }}>
                    No Notes Yet
                  </Typography>
                  <Typography sx={{ fontSize: fs12, color: "#595959" }}>
                    After creating Pinned Notes, they will be displayed here as
                    Pinned Notes.
                  </Typography>
                </Box>
              )}
          </Box>
        </Grid>

        {/* New Cond */}

        <Grid
          xs={12}
          md={6}
          style={{
            padding: "16px",
            borderRadius: "8px",
            overflow: "auto",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
          }}
          bgcolor={theme.palette.purple10.main}
        >
          <Box sx={{ display: "flex", gap: "4px" }}>
            <WarningAmberOutlined size={24} />
            <Typography
              fontSize={fs16}
              fontWeight={fw600}
              color={theme.palette.textGraySecondary.main}
            >
              Contraindications
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography
              fontSize={fs14}
              fontWeight={fw400}
              color={customColors.lightBlack}
            >
              Edit Contraindication
            </Typography>

            <Box>
              <React.Fragment>
                {addContraindications(
                  handleContraindicationContent,
                  noteError,
                  contraindicationContent
                ).map((item) =>
                  item.componentArray.map((child, childIndex) => (
                    <child.component key={childIndex} {...child.otherOptions} />
                  ))
                )}
              </React.Fragment>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: contraindicationContent
                  ? "space-between"
                  : "flex-end",
                alignItems: "center",
              }}
            >
              {contraindicationContent ? (
                <Box
                  sx={{
                    background: customColors.lightRed,
                    padding: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleDeleteContraindication}
                >
                  <Delete
                    sx={{ color: customColors.darkRed }}
                    fontSize="16px"
                  />
                </Box>
              ) : null}
              <Button
                sx={SubmitBtn}
                onClick={handleContraindicationUpdate}
                type="button"
              >
                Save Changes
              </Button>
              {/* <Button
                disabled={isEnableUpdateContraindication}
                sx={{
                  display: "flex",
                  height: "0px 24px",
                  alignItems: "center",
                  justifyItems: "center",
                  gap: "8px",
                  borderRadius: "8px",
                  background: isEnableUpdateContraindication
                    ? `${secondary.light}`
                    : `${secondary.main}`,
                  "&:hover": {
                    backgroundColor: customColors.bluishGrey,
                  },
                }}
                onClick={handleContraindicationUpdate}
                type="button"
              >
                <>
                  <Typography sx={{ color: customColors.white }}>
                    Save Changes
                  </Typography>
                  <Check size={16} sx={{ color: customColors.white }} />
                </>
              </Button> */}
            </Box>
          </Box>

          <Divider />
          <Typography
            sx={{
              color: customColors.lightBlack,
              fontSize: fs14,
              fontWeight: fw400,
            }}
          >
            Edit History
          </Typography>

          <Box
            sx={{
              height: notPinnedNotesArray?.length === 0 ? "auto" : "300px",
              overflow: "auto",
            }}
          >
            {contraindicationArrayData?.length > 0 ? (
              <>
                {contraindicationArrayData.map((item, index) => (
                  <item.component {...item.otherOptions} key={index} />
                ))}
              </>
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                overflow={"hidden"}
              >
                <NoNotifications />
                <Typography sx={{ fontSize: fs16, color: "#595959" }}>
                  No Contraindication Yet
                </Typography>
                <Typography sx={{ fontSize: fs12, color: "#595959" }}>
                  After creating Contraindication, they will be displayed here
                  as History.
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
