import { ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { customColors, secondary } from "../../../theme";

export default function ProductCategoryTab({
  activeItem,
  setActiveItem,
  tabsData,
  setActiveHistoryTab,
}) {
  const allCategory = { name: "ALL", _id: "" };
  tabsData = [allCategory, ...tabsData];
  return (
    <>
      {tabsData?.map((item, index) => {
        return (
          <ListItemButton
            key={index}
            onClick={() => {
              setActiveItem(item.name);
              setActiveHistoryTab(item?._id);
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "8px 16px",
              height: "48px !important",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              gap: "4px",
              color: activeItem.includes(item.name)
                ? customColors.white
                : secondary.main,
              background: activeItem.includes(item.name) ? secondary.main : "",
              "&:hover": {
                color: activeItem.includes(item.name)
                  ? customColors.white
                  : secondary.main,
                background: activeItem.includes(item.name)
                  ? secondary.main
                  : "",
              },
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                style: {
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  width: "max-content",
                  height: "48px !important",
                },
              }}
              primary={item?.name}
            />
          </ListItemButton>
        );
      })}
    </>
  );
}
