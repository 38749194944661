import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  profilePictureUrl: Yup.string(),
  password: Yup.string().required("Password is required"),
  postalCode: Yup.string()
    // .matches(/^\d{4}$/, "Invalid Format. Valid Format Four Characters 0000")
    .required("Post code is required"),
  mobileNumber: Yup.string()
    .matches(
      /^\+?\d{10,14}$/,
      "Invalid format. Provide Valid Format of 10 to 14 digits"
    )
    .test(
      "mobile-format",
      "Invalid format. Provide Valid Format of 10 to 14 digits",
      function (value) {
        if (!value) return true;
        return /^\+?\d{10,14}$/.test(value);
      }
    )
    .required("Mobile number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  dateOfBirth: Yup.string().nullable(),
  occupation: Yup.string(),
  company: Yup.string(),
  gender: Yup.string().required("Gender is required"),
  state: Yup.string().required("State is required"),
  street: Yup.string().required("Street is required"),
  suburb: Yup.string().required("Suburb is required"),
  // city: Yup.string().required("City is required"),
  newsLetter: Yup.boolean(),
  promotionEmail: Yup.boolean(),
  promotionSMS: Yup.boolean(),
  note: Yup.string(),
  isPinned: Yup.boolean(),
  referralSource: Yup.string(),
  friend: Yup.string(),
  // friend: Yup.string().when("referralSource", {
  //   is: "FRIEND",
  //   then: (schema) => schema.required("Friend is required"),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
});
