import React from "react";

const NoArticle = () => {
  return (
    <svg
      width="171"
      height="187"
      viewBox="0 0 171 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M159.959 61.7914C159.959 61.7914 134.92 83.7693 163.751 130.832C189.355 172.627 131.804 190.745 107.004 185.894C74.8783 179.609 56.4328 139.003 31.8648 151.869C7.29675 164.736 -17.042 106.433 18.6946 84.9793C63.1493 58.2918 34.6189 42.7833 38.6115 23.2152C41.4772 9.17138 82.2557 -12.5418 104.244 12.2263C122.746 33.0684 131.82 25.612 140.277 22.8296C152.474 18.8167 179.097 39.6903 159.959 61.7914Z"
          fill="#CAC7F7"
        />
        <g id="Group_2">
          <g id="Group_3">
            <path
              id="Vector_2"
              d="M89.0651 86.5581L80.8618 120.207C80.5573 121.455 80.5016 122.75 80.6979 124.02C80.8942 125.289 81.3387 126.507 82.006 127.605C82.6733 128.702 83.5503 129.657 84.5869 130.416C85.6235 131.174 86.7994 131.721 88.0473 132.025L128.846 141.971C129.895 153.489 139.427 154.341 143.188 154.735C143.225 154.737 143.261 154.727 143.292 154.706C143.322 154.685 143.345 154.655 143.357 154.62C143.368 154.584 143.368 154.546 143.355 154.511C143.343 154.476 143.319 154.446 143.288 154.426C140.587 152.78 139.781 148.268 139.601 144.593L142.63 145.332C143.878 145.636 145.173 145.692 146.443 145.496C147.712 145.299 148.93 144.855 150.028 144.188C151.125 143.52 152.081 142.643 152.839 141.607C153.598 140.57 154.144 139.394 154.448 138.146L162.651 104.498C162.956 103.25 163.012 101.954 162.815 100.685C162.619 99.4156 162.175 98.1974 161.507 97.0999C160.84 96.0023 159.963 95.047 158.927 94.2885C157.89 93.5301 156.714 92.9832 155.466 92.6793L100.884 79.3726C99.6357 79.068 98.3401 79.0123 97.0707 79.2086C95.8014 79.4049 94.5831 79.8494 93.4856 80.5167C92.3881 81.184 91.4328 82.061 90.6743 83.0976C89.9158 84.1342 89.369 85.3101 89.0651 86.5581Z"
              fill="#716AEA"
            />
            <g id="Group_4">
              <path
                id="Vector_3"
                d="M156.741 103.159L133.927 97.5976C133.777 97.5609 133.626 97.653 133.589 97.8033L132.419 102.604C132.382 102.754 132.474 102.906 132.624 102.942L155.438 108.504C155.588 108.541 155.74 108.449 155.776 108.298L156.947 103.498C156.983 103.347 156.891 103.196 156.741 103.159Z"
                fill="white"
              />
              <path
                id="Vector_4"
                d="M153.484 116.522L91.6714 101.453C91.5212 101.416 91.3697 101.509 91.3331 101.659L90.1627 106.459C90.1261 106.61 90.2182 106.761 90.3684 106.798L152.181 121.867C152.331 121.904 152.482 121.812 152.519 121.661L153.689 116.861C153.726 116.71 153.634 116.559 153.484 116.522Z"
                fill="#A7A2F2"
              />
              <path
                id="Vector_5"
                d="M150.226 129.882L88.4136 114.812C88.2634 114.776 88.1119 114.868 88.0753 115.018L86.9049 119.819C86.8683 119.969 86.9604 120.121 87.1106 120.157L148.923 135.227C149.073 135.263 149.224 135.171 149.261 135.021L150.431 130.22C150.468 130.07 150.376 129.918 150.226 129.882Z"
                fill="#A7A2F2"
              />
            </g>
          </g>
          <g id="Group_5">
            <path
              id="Vector_6"
              d="M112.036 46.1956L125.45 89.2736C125.948 90.871 126.127 92.5509 125.975 94.2173C125.824 95.8836 125.345 97.5038 124.567 98.9853C123.789 100.467 122.727 101.78 121.441 102.851C120.156 103.922 118.672 104.729 117.074 105.226L64.843 121.49C64.4203 136.551 52.0972 138.436 47.2402 139.256C47.192 139.263 47.1431 139.253 47.1011 139.228C47.0592 139.204 47.0267 139.166 47.0088 139.12C46.9909 139.075 46.9886 139.025 47.0023 138.979C47.016 138.932 47.0449 138.891 47.0844 138.863C50.4608 136.502 51.1403 130.569 51.0744 125.778L47.1964 126.985C45.599 127.483 43.9192 127.661 42.2529 127.51C40.5866 127.358 38.9665 126.88 37.4851 126.102C36.0038 125.324 34.6902 124.262 33.6194 122.976C32.5486 121.691 31.7416 120.207 31.2445 118.609L17.83 75.531C17.332 73.9336 17.1537 72.2537 17.3053 70.5873C17.4568 68.9209 17.9351 67.3007 18.713 65.8193C19.4909 64.3378 20.553 63.0242 21.8388 61.9533C23.1245 60.8825 24.6086 60.0755 26.2063 59.5784L96.0833 37.8192C97.6807 37.3213 99.3606 37.143 101.027 37.2945C102.693 37.446 104.314 37.9244 105.795 38.7023C107.276 39.4801 108.59 40.5423 109.661 41.828C110.732 43.1137 111.539 44.5979 112.036 46.1956Z"
              fill="#3730A0"
            />
            <g id="Group_6">
              <path
                id="Vector_7"
                d="M62.6856 61.6234L25.2664 73.2606C25.1187 73.3065 25.0363 73.4635 25.0822 73.6111L27.0443 79.9204C27.0903 80.0681 27.2472 80.1506 27.3949 80.1047L64.8141 68.4675C64.9618 68.4216 65.0442 68.2647 64.9983 68.117L63.0362 61.8077C62.9902 61.66 62.8333 61.5775 62.6856 61.6234Z"
                fill="white"
              />
              <path
                id="Vector_8"
                d="M109.907 65.6224L30.613 90.3152C30.4654 90.3612 30.383 90.5181 30.429 90.6658L32.3933 96.9736C32.4392 97.1212 32.5962 97.2037 32.7439 97.1577L112.038 72.4649C112.186 72.4189 112.268 72.2619 112.222 72.1143L110.258 65.8064C110.212 65.6588 110.055 65.5764 109.907 65.6224Z"
                fill="#A7A2F2"
              />
              <path
                id="Vector_9"
                d="M115.227 82.8135L35.9139 107.479C35.7662 107.525 35.6837 107.682 35.7296 107.83L37.6918 114.139C37.7377 114.287 37.8947 114.369 38.0424 114.323L117.355 89.6576C117.503 89.6117 117.585 89.4547 117.54 89.307L115.577 82.9977C115.531 82.8501 115.374 82.7676 115.227 82.8135Z"
                fill="#A7A2F2"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NoArticle;
