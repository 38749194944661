import { baseAPI } from '../baseApi';

export const NotificationApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => ({ url: '/notification' }),
      providesTags: ['Notifications'],
    }),
})
});

export const {
useGetNotificationsQuery
} = NotificationApi;
