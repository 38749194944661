import {
  Autocomplete,
  TextField,
  FormControl,
  Box,
  FormLabel,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { customColors, fs14, fw400, secondary } from "../../../theme";
import { Warning } from "@phosphor-icons/react";

export default function SPAAutoComplete({
  control,
  name,
  placeholder,
  label,
  readOnly,
  helperText,
  labelHelperText,
  defaultValue,
  options,
  onChangeText,
  ...rest
}) {
  const CustomPaper = (props) => {
    return (
      <Box
        {...props}
        sx={{
          maxHeight: 300,
          overflowY: "auto",
          background: customColors.white,
          border: "1px solid #DADADB",
          borderRadius: "8px",
          boxShadow: "0px 12px 16px -4px #10182814",
        }}
      />
    );
  };
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, field, value },
          fieldState: { error: fieldError },
        }) => (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            {label && (
              <FormLabel
                sx={{
                  color: "#5E6062",
                  fontWeight: fw400,
                  fontSize: fs14,
                  whiteSpace: "nowrap",
                }}
              >
                {label}
              </FormLabel>
            )}
            <Autocomplete
              {...field} // Spread field object to Autocomplete
              readOnly={readOnly}
              sx={{
                backgroundColor: customColors.white,
                height: "44px !important",
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  borderRadius: "6px",
                  height: "44px",
                },
                "&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                  { padding: "0px 3px" },
              }}
              options={options}
              value={value}
              renderOption={(props, option) => {
                return (
                  <>
                    <Box
                      key={option.label}
                      sx={{
                        background: customColors.white,
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      {...props}
                    >
                      <Typography
                        sx={{
                          fontSize: fs14,
                          fontWeight: fw400,
                          color: secondary.main,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ marginRight: "5px" }}>
                          {option.label.includes("New Client") && option.icon}
                        </span>
                        {option.label}
                      </Typography>
                      {option.label.includes("New Client") && (
                        <Divider
                          sx={{ px: "0px !important", paddingTop: "8px" }}
                        />
                      )}
                    </Box>
                  </>
                );
              }}
              getOptionLabel={(option) => option.label || value}
              onChange={(_, values) => {
                if (values?.id) onChange(values.id);
                else onChange(values.label);
              }}
              PaperComponent={CustomPaper}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  variant="outlined"
                  value={value} // Pass the value prop here
                  onChange={onChangeText}
                  sx={{
                    backgroundColor: customColors.white,

                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${secondary.main} !important`,
                      borderWidth: "1px !important",
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFAA33  !important",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#D0D5DD !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${secondary.main} !important`,
                    },
                  }}
                  helperText={
                    fieldError && (
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          fontSize: "12px",
                          color: "#FFAA33",
                        }}
                      >
                        <Warning size={16} weight="fill" />{" "}
                        {fieldError?.message}
                      </Typography>
                    )
                  }
                />
              )}
            />
          </Box>
        )}
      />
    </FormControl>
  );
}
