import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openUserProfileViewModal: false,
  userProfileDetails: {},
};

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    handleOpenUserProfileViewModal: (state, action) => {
      state.openUserProfileViewModal = true;
      state.userProfileDetails = action.payload;
    },
    handleCloseUserProfileViewModal: (state) => {
      state.openUserProfileViewModal = false;
      state.userProfileDetails = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handleOpenUserProfileViewModal,
  handleCloseUserProfileViewModal,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
