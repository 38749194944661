import React from "react";
import { profileData, removeData, sidebarData } from "../profile-data";
import { List, Typography } from "@mui/material";
import { fs16, fw600 } from "../../../../../theme";
import SPASideBarListButton from "../../../../common/spa-sidebar-list-button";

export default function SideBar({
  setActiveItem,
  activeItem,
  handleRemoveItem,
}) {
  return (
    <List
      component="nav"
      sx={{
        width: "auto",
        p: "16px 24px",
        background: "#F8F7FE",
      }}
    >
      <SPASideBarListButton
        key={"treatment"}
        item={profileData}
        activeItem={activeItem}
        isLableShow={true}
        onClick={() => {
          setActiveItem(profileData.link);
        }}
      />

      <Typography
        sx={{
          marginBottom: "10px",
          color: "#5E6062",
          fontSize: fs16,
          fontWeight: fw600,
        }}
      >
        Create:
      </Typography>
      {sidebarData.map((item, index) => (
        <SPASideBarListButton
          key={index}
          item={item}
          activeItem={activeItem}
          isLableShow={true}
          onClick={() => {
            setActiveItem(item.link);
          }}
        />
      ))}
      <SPASideBarListButton
        key={"Remove"}
        item={removeData}
        activeItem={activeItem}
        isLableShow={true}
        isRemove={true}
        sx={{ color: "#D13438" }}
        onClick={() => {
          handleRemoveItem();
        }}
      />
    </List>
  );
}
