import * as Yup from "yup";

export const validationSchema = (type) => {
  let schema;
  if (type === "Treatments") {
    schema = Yup.object().shape({
      services: Yup.array()
        .of(
          Yup.object().shape({
            _id: Yup.string().required("ID is required"),
            name: Yup.string(),
          })
        )
        .min(1, "At least one Treatment must be selected")
        .required("Selection of Treatment is required."),
    });
  } else if (type === "Products") {
    schema = Yup.object().shape({
      products: Yup.array()
        .of(
          Yup.object().shape({
            _id: Yup.string().required("ID is required"),
            name: Yup.string(),
          })
        )
        .min(1, "At least one product must be selected")
        .required("Selection of product is required."),
    });
  } else if (type === "Staff") {
    schema = Yup.object().shape({
      staff: Yup.array()
        .of(
          Yup.object().shape({
            _id: Yup.string().required("ID is required"),
            firstName: Yup.string(),
            lastName: Yup.string(),
          })
        )
        .min(1, "At least one staff must be selected")
        .required("Selection of staff is required."),
    });
  } else if (type === "Customers") {
    schema = Yup.object().shape({
      client: Yup.array()
        .of(
          Yup.object().shape({
            _id: Yup.string().required("ID is required"),
            firstName: Yup.string(),
            lastName: Yup.string(),
          })
        )
        .min(1, "At least one Customer must be selected")
        .required("Selection of Customer is required."),
    });
  } else if (type === "Treatment Categories") {
    schema = Yup.object().shape({
      serviceCategory: Yup.array()
        .of(
          Yup.object().shape({
            _id: Yup.string().required("ID is required"),
            name: Yup.string(),
          })
        )
        .min(1, "At least one Category must be selected")
        .required("Selection of Category is required."),
    });
  } else if (type === "Product Types") {
    schema = Yup.object().shape({
      productType: Yup.array()
        .of(
          Yup.object().shape({
            _id: Yup.string().required("ID is required"),
            name: Yup.string(),
          })
        )
        .min(1, "At least one Product Type must be selected")
        .required("Selection of Product Type is required."),
    });
  }

  return schema;
};
