import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Grow,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  LinkBreak,
  ShootingStar,
  SketchLogo,
  StarHalf,
} from "@phosphor-icons/react";
import { useState } from "react";
import { fs10, fs12, fw400, fw600 } from "../../theme";
import theme from "../../theme/theme";

const statusDesign = {
  ACTIVE: {
    icon: <ShootingStar size={16} />,
    color: "#1AA37A",
    bgColor: "#E9FBF6",
  },
  NEW: {
    icon: <ShootingStar size={16} />,
    color: "#1AA37A",
    bgColor: "#E9FBF6",
  },
  VIP: {
    icon: <SketchLogo size={16} />,
    color: "#FA9200",
    bgColor: "#FFF9F0",
  },
  LOST: {
    icon: <LinkBreak size={16} />,
    color: "#4A4C4F",
    bgColor: "#FAFAFA",
  },
  RETURN: {
    icon: <StarHalf size={16} />,
    color: "#115ACB",
    bgColor: "#E7EFFC",
  },
};

/**
 * StatusMenu component.
 * Renders a menu to select and display different status options.
 *
 * @returns {JSX.Element} JSX code for rendering the StatusMenu component.
 */

export default function StatusMenu({selectedStatus, setSelectedStatus, isDisabled}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  /**
   * Handles the click event for opening the menu.
   * @param {Object} event - The event object.
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the close event for the menu.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };


  /**
   * Handles the click event for selecting a status option from the menu.
   * @param {string} status - The selected status option.
   */
  const handleMenuItemClick = (status) => {
    setSelectedStatus(status);
    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          {selectedStatus && (
            <Box
              sx={{
                bgcolor: statusDesign[selectedStatus].bgColor,
                color: statusDesign[selectedStatus].color,
                paddingBlock: "4px",
                paddingInline: "8px",
                borderRadius: "4px",
              }}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Typography sx={{ alignSelf: "center", lineHeight: "0" }}>
                  {statusDesign[selectedStatus].icon}
                </Typography>
                <Typography fontSize={fs10} fontWeight={fw400}>
                  {selectedStatus}
                </Typography>
              </Stack>
            </Box>
          )}
        </Stack>
        <Button
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          // aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{ color: theme.palette.primaryColor.main, fontSize: fs10, textTransform: "capitalize" }}
          disabled= {isDisabled? isDisabled : false}
        >
          Change Status
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        TransitionComponent={Grow}
        // PaperProps={{
        //     style: {
        //       width: anchorEl ? anchorEl.clientWidth : "auto",
        //     },
        //   }}
      >
        {Object.entries(statusDesign).map(
          ([status, { icon, color, bgColor }]) => (
            <Box key={status}>
              <MenuItem
                sx={{ bgcolor: bgColor, color: color }}
                onClick={() => handleMenuItemClick(status)}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ alignItems: "center" }}
                >
                  <Typography>{icon}</Typography>
                  <Typography fontSize={fs12} fontWeight={fw600}>
                    {status}
                  </Typography>
                </Stack>
              </MenuItem>
            </Box>
          )
        )}
      </Menu>
    </>
  );
}
