import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import React from "react";
import NewRoomForm from "./new-room";
import { roomsPopOverData } from "./rooms-data";
import useRooms from "./useRooms";
import { fs14, fs20, fs34, secondary } from "../../../theme";
import {
  Door,
  MagnifyingGlass,
  Plus,
  UserPlus,
  X,
} from "@phosphor-icons/react";
import SPATable from "../../common/spa-table";
import NoClients from "../../../assets/svg/no-clients";
import SPAPopover from "../../common/spa-popover";
import SPATextField from "../../common/spa-textField";
import { FilterList } from "@mui/icons-material";
/**
 * RoomsSection component.
 *
 * @returns {JSX.Element} JSX code for rendering the RoomsSection component.
 */
const RoomsSection = () => {
  const {
    searchRoom,
    setSearchRoom,
    singleRoomId,
    setSingleRoomId,
    roomTableHeader,
    isRoomDialogOpen,
    setIsRoomDialogOpen,
    roomsPopoverOpen,
    setRoomsPopoverOpen,
    roomsPopOverId,
    openRoomsPopOver,
    handleNewRoom,
    roomsData,
    roomsDataLoader,
    getAllRooms,
    currentPage,
    setCurrentPage,
    totalRecords,
  } = useRooms();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            disableElevation
            variant="contained"
            sx={{
              background: secondary.main,
              display: "flex",
              gap: "8px",
              alignItems: "center",
              borderRadius: "8px",
              height: "48px",
              "&:hover": { background: secondary.main },
            }}
            onClick={handleNewRoom}
          >
            <Plus size={24} color="currentColor" />
            Create Zone
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            <SPATextField
              placeholder="Search"
              value={searchRoom}
              sx={{
                width: { md: "328px", xs: "180px" },
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                    <MagnifyingGlass size={24} color={"#C1C2C3"} />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    onClick={() => {
                      setSearchRoom("");
                    }}
                  >
                    {searchRoom && <X size={24} />}
                  </InputAdornment>
                ),
              }}
              onChangeValue={(event) => {
                setSearchRoom(event.target.value);
              }}
            />

            <Box sx={styles.searchbox}>
              <FilterList sx={{ color: secondary.main }} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ pt: "16px" }}>
        {roomsDataLoader && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={roomsDataLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {roomsData?.result?.data?.totalRecords > 0 && !roomsDataLoader && (
          <SPATable
            data={roomsData?.result?.data?.data}
            columns={roomTableHeader}
            totalRecords={totalRecords}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
        {!roomsData?.result?.data?.data?.length && !roomsDataLoader && (
          <Box
            sx={{
              mt: "18px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <NoClients />
            <Typography sx={{ fontSize: fs20, color: "#595959" }}>
              There’s no Zone
            </Typography>
            <Typography sx={{ fontSize: fs14, color: "#595959" }}>
              Create a new zone by clicking on the button Create Zone
            </Typography>
            <Button
              disableElevation
              variant="contained"
              sx={{
                background: secondary.main,
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                "&:hover": { background: secondary.main },
              }}
              onClick={handleNewRoom}
            >
              <UserPlus size={24} color="currentColor" />
              Create Zone
            </Button>
          </Box>
        )}
      </Box>

      {isRoomDialogOpen && (
        <NewRoomForm
          currentPage={currentPage}
          getAllRooms={getAllRooms}
          singleRoomId={singleRoomId}
          isNewRoomOpen={isRoomDialogOpen}
          handleClose={() => {
            setIsRoomDialogOpen(false);
            setSingleRoomId(null);
          }}
        />
      )}
      {openRoomsPopOver && (
        <SPAPopover
          id={roomsPopOverId}
          anchorEl={roomsPopoverOpen}
          width="215px"
          isOpen={openRoomsPopOver}
          transformHorizontal="right"
          handleClose={() => {
            setRoomsPopoverOpen(null);
          }}
          data={roomsPopOverData(setRoomsPopoverOpen, setIsRoomDialogOpen)}
        />
      )}
    </>
  );
};

export default RoomsSection;

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
