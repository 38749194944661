import { FilterList } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Plus, UserPlus, X } from "@phosphor-icons/react";
import React from "react";
import NoClients from "../../../../../assets/svg/no-clients";
import { fs14, fs20, secondary } from "../../../../../theme";
import SPADialogue from "../../../../common/spa-dialogue";
import SPAPopover from "../../../../common/spa-popover";
import SPATable from "../../../../common/spa-table";
import SPATextField from "../../../../common/spa-textField";
import ProductTypeSection from "../../../product-type";
import CreateProfessionalProduct from "./create-professional-product";
import { staffPopOverData } from "./staff-data";
import useProfessionalProduct from "./useProfessionalProduct";
import AddToQualification from "../../../qualification/add-to-qualification";
import SPAAlertDialog from "../../../../common/spa-alert-dialogue";
/**
 * ProfessionalsProductListing component.
 *
 * @returns {JSX.Element} JSX code for rendering the ProfessionalsProductListing component.
 */
const ProfessionalsProductListing = ({
  getStockProducts,
  productStockDataLoader,
}) => {
  const {
    staffTableHeader,
    isStaffDialogOpen,
    staffPopoverOpen,
    setStaffPopoverOpen,
    staffPopOverId,
    openStaffPopOver,
    handleNewStaff,
    productsData,
    productsDataLoader,
    currentPage,
    setCurrentPage,
    handleOpenProfileOpen,
    searchStaff,
    setSearchStaff,
    isRolesDialogOpen,
    setIsRolesDialogOpen,
    setIsStaffDialogOpen,
    control,
    methods,
    handleSubmit,
    onSubmit,
    createStaffLoader,
    productTypeData,
    handleStaffCloseDialog,
    getAllProducts,
    getAllProductType,

    isOpenAddTreatmentDialog,
    setIsOpenAddTreatmentDialog,
    setAddTreatmentData,

    handleAddTreatment,
    setIsOpenSuccessProductDialog,
    isOpenSuccessProductDialog,
  } = useProfessionalProduct({ getStockProducts });
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px" }}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                background: secondary.main,
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                height: "48px",
                "&:hover": { background: secondary.main },
              }}
              onClick={handleNewStaff}
            >
              <Plus size={24} color="currentColor" />
              Create Professional Product
            </Button>
            <Button
              disableElevation
              variant="contained"
              sx={{
                background: "#EDECFC",
                display: "flex",
                gap: "8px",
                alignItems: "center",
                borderRadius: "8px",
                height: "48px",
                color: "#4E45E5",
                "&:hover": { background: "#EDECFC", color: "#4E45E5" },
              }}
              onClick={() => setIsRolesDialogOpen(true)}
            >
              Product Type
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            <SPATextField
              placeholder="Search"
              value={searchStaff}
              sx={{
                width: { md: "328px", xs: "180px" },
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ paddingLeft: "10px" }}>
                    <MagnifyingGlass size={24} color={"#C1C2C3"} />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ paddingLeft: "10px", cursor: "pointer" }}
                    onClick={() => {
                      setSearchStaff("");
                    }}
                  >
                    {searchStaff && <X size={24} />}
                  </InputAdornment>
                ),
              }}
              onChangeValue={(event) => {
                setSearchStaff(event.target.value);
              }}
            />

            <Box sx={styles.searchbox}>
              <FilterList sx={{ color: secondary.main }} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ pt: "16px" }}>
        {(productsDataLoader || productStockDataLoader) && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={productsDataLoader || productStockDataLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {productsData &&
          productsData?.result?.data?.data?.length > 0 &&
          !productsDataLoader && (
            <SPATable
              data={productsData?.result?.data?.data}
              columns={staffTableHeader}
              totalRecords={productsData?.result?.data?.totalRecords}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        {productsData &&
          productsData?.result?.data?.data?.length === 0 &&
          !productsDataLoader && (
            <Box
              sx={{
                mt: "18px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <NoClients />
              <Typography sx={{ fontSize: fs20, color: "#595959" }}>
                There’s no Professional Product
              </Typography>
              <Typography sx={{ fontSize: fs14, color: "#595959" }}>
                Create a create Professional Product by clicking on the button
                Create Professional Product
              </Typography>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  background: secondary.main,
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                  "&:hover": { background: secondary.main },
                }}
                onClick={handleNewStaff}
              >
                <UserPlus size={24} color="currentColor" />
                Create Professional Product
              </Button>
            </Box>
          )}
      </Box>
      {isStaffDialogOpen && (
        <CreateProfessionalProduct
          getAllProducts={getAllProducts}
          handleStaffCloseDialog={handleStaffCloseDialog}
          isNewStaffOpen={isStaffDialogOpen}
          setIsStaffDialogOpen={setIsStaffDialogOpen}
          control={control}
          methods={methods}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          createStaffLoader={createStaffLoader}
          productTypeData={productTypeData}
        />
      )}
      {openStaffPopOver && (
        <SPAPopover
          id={staffPopOverId}
          anchorEl={staffPopoverOpen}
          width="215px"
          isOpen={openStaffPopOver}
          transformHorizontal="right"
          handleClose={() => {
            setStaffPopoverOpen(null);
          }}
          data={staffPopOverData(setStaffPopoverOpen, handleOpenProfileOpen)}
        />
      )}
      {isRolesDialogOpen && (
        <SPADialogue
          dialogTitle={"Product Type"}
          open={isRolesDialogOpen}
          handleClose={() => {
            setIsRolesDialogOpen(false);
            getAllProductType();
          }}
        >
          <ProductTypeSection
            getAllProducts={getAllProducts}
            type="PROFESSIONAL_PRODUCT"
          />
        </SPADialogue>
      )}
      {isOpenAddTreatmentDialog && (
        <AddToQualification
          setIsOpenSuccessDialog={() => {}}
          singleQualificationId={() => {}}
          isAddQualificationOpen={isOpenAddTreatmentDialog}
          setAddQualificationData={setAddTreatmentData}
          handleClose={(data) => {
            setIsOpenAddTreatmentDialog(false);
            // setCreatedProductData(null);
          }}
          dialogTitle={`Add Product to a Treatment`}
          contentTitle={`Treatments`}
          subContentDescription={`Add this product to a treatment.`}
          type={"Treatment"}
          submitText="Add Treatment"
        />
      )}
      {isOpenSuccessProductDialog && (
        <SPAAlertDialog
          open={isOpenSuccessProductDialog}
          handleClose={() => setIsOpenSuccessProductDialog(false)}
          handleNewTab={handleAddTreatment}
          title={"Professional Product Created"}
          contentText={`Do you want to add this product to a treatment?`}
          disagreeText={`Add to treatment`}
          agreeText={`Close`}
        />
      )}
    </>
  );
};

export default ProfessionalsProductListing;

const styles = {
  searchbox: {
    cursor: "pointer",
    background: "#DAD8FC",
    p: "12px 12px 6px 12px",
    borderRadius: "8px",
    "&:hover": {
      background: "#A7A2F2",
    },
    "&:focused": {
      background: "#A7A2F2",
    },
  },
};
