import SPAAutoComplete from "../common/spa-auto-complete";
import SPAInputField from "../hook-form/SPAInputField";
import SPAMobileDatePicker from "../hook-form/SPAMobileDatePicker";
import SPASelectField from "../hook-form/SPASelectField";
import ProfileImageUploader from "../profile-image-upload";

export const setupClientFormData = (
  countriesArray,
  friendReferralArray,
  friendFieldEnable,
  onChange
) => {
  return [
    {
      title: "Basic Information",
      gridLength: 12,
      children: [
        {
          gridLength: 6,
          otherOptions: {
            fullWidth: true,
            name: "firstName",
            label: "First Name",
            placeholder: "i.e.: Jane",
          },
          component: SPAInputField,
        },
        {
          gridLength: 6,
          otherOptions: {
            fullWidth: true,
            name: "lastName",
            label: "Last Name",
            placeholder: "i.e.: Doe",
          },
          component: SPAInputField,
        },
      ],
    },
    {
      gridLength: 8,
      children: [
        {
          title: "Contact Details",
          gridLength: 12,
          children: [
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "mobileNumber",
                label: "Mobile Number",
                placeholder: "+61 412 345 678",
              },
              component: SPAInputField,
            },
            // {
            //   gridLength: 6,
            //   otherOptions: {
            //     fullWidth: true,
            //     name: "telephone",
            //     label: "Telephone",
            //     placeholder: "",
            //     labelHelperText: " (Optional)",
            //   },
            //   component: SPAInputField,
            // },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "email",
                label: "Email",
                placeholder: "abc@example.com",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "dateOfBirth",
                label: "Date of Birth",
                placeholder: "DD/MM/YYYY",
                helperText: " (Optional)",
                disableFuture: true,
                disableHighlightToday: true,
                isCalenderShow: true,
              },
              component: SPAMobileDatePicker,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "password",
                label: "Password",
                placeholder: "********",
                type: "password",
              },
              component: SPAInputField,
            },
          ],
        },
        {
          title: "Personal Information",
          gridLength: 12,
          children: [
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "occupation",
                label: "Occupation",
                placeholder: "Enter occupation",
                labelHelperText: " (Optional)",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "company",
                label: "Company",
                placeholder: "Enter company",
                labelHelperText: " (Optional)",
              },
              component: SPAInputField,
            },
            {
              gridLength: 6,
              otherOptions: {
                fullWidth: true,
                name: "gender",
                label: "Gender",
                select: true,
                placeholder: "Select Gender",
                options: [
                  { value: "MALE", label: "Male" },
                  { value: "FEMALE", label: "Female" },
                ],
              },
              component: SPASelectField,
            },
            {
              gridLength: 6,
              margin: true,
              labelHelperText: " (Optional)",
              otherOptions: {
                name: "referralSource",
                label: "Referral Source",
                placeholder: "Select Media",
                labelHelperText: " (Optional)",
                options: [
                  {
                    label: "TV",
                    value: "TV",
                  },
                  {
                    label: "SOCIAL_MEDIA",
                    value: "SOCIAL_MEDIA",
                  },
                  {
                    label: "FACEBOOK",
                    value: "FACEBOOK",
                  },
                  {
                    label: "TWITTER",
                    value: "TWITTER",
                  },
                  {
                    label: "GOOGLE",
                    value: "GOOGLE",
                  },
                  {
                    label: "FRIEND",
                    value: "FRIEND",
                  },
                ],
              },
              component: SPASelectField,
            },
            friendFieldEnable
              ? {
                  gridLength: 6,
                  margin: true,
                  otherOptions: {
                    name: "friend",
                    label: "Friend",
                    placeholder: "Select Friend",
                    options: friendReferralArray,
                    onChangeText: onChange,
                    labelHelperText: " (Optional)",
                  },
                  component: SPAAutoComplete,
                }
              : { gridLength: 0 },
          ],
        },
      ],
    },
    {
      title: "Profile Picture",
      gridLength: 4,
      otherOptions: {
        width: "172px",
        height: "172px",
        uploadBtnText: "upload",
        name: "profilePictureUrl",
      },

      component: ProfileImageUploader,
    },
    {
      title: "Address",
      gridLength: 12,
      children: [
        {
          gridLength: 6,
          otherOptions: {
            fullWidth: true,
            name: "street",
            label: "Street",
            placeholder: "",
          },
          component: SPAInputField,
        },
        {
          gridLength: 6,
          otherOptions: {
            fullWidth: true,
            name: "suburb",
            label: "Suburb",
            placeholder: "",
          },
          component: SPAInputField,
        },
        {
          gridLength: 6,
          otherOptions: {
            name: "state",
            label: "State",
            placeholder: "",
            options: [
              {
                label: "New South Wales (NSW)",
                value: "New South Wales (NSW)",
              },
              {
                label: "Queensland (QLD)",
                value: "Queensland (QLD)",
              },
              {
                label: "South Australia (SA)",
                value: "South Australia (SA)",
              },
              {
                label: "Tasmania (TAS)",
                value: "Tasmania (TAS)",
              },
              {
                label: "Victoria (VIC)",
                value: "Victoria (VIC)",
              },
              {
                label: "Western Australia (WA)",
                value: "Western Australia (WA)",
              },
              {
                label: "Australian Capital Territory (ACT)",
                value: "Australian Capital Territory (ACT)",
              },
              {
                label: "Northern Territory (NT)",
                value: "Northern Territory (NT)",
              },
            ],
          },
          component: SPASelectField,
        },
        {
          gridLength: 6,
          otherOptions: {
            fullWidth: true,
            name: "postalCode",
            label: "Post Code",
            placeholder: "",
          },
          component: SPAInputField,
        },
        // {
        //   gridLength: 6,
        //   otherOptions: {
        //     fullWidth: true,
        //     name: "country",
        //     label: "Country",
        //     select: true,
        //     placeholder: "",
        //     labelHelperText: " (Optional)",
        //     options: countriesArray,
        //     defaultValue: "Australia",
        //   },
        //   component: SPASelectField,
        // },
      ],
    },
  ];
};
