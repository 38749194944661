import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useForm } from "react-hook-form";
import { validationSchema } from "./validationSchema";
import { useEffect } from "react";

dayjs.extend(utc);

export default function useSales({ clientDetail }) {
  const validationSchemaInstance = validationSchema();
  const methods = useForm({ resolver: yupResolver(validationSchemaInstance) });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const onSubmit = async (values) => {
    let payload = values;
  };

  useEffect(() => {
    if (clientDetail) {
      setValue(
        "client",
        `${clientDetail?.firstName} ${clientDetail?.lastName}`
      );
    }
  }, [clientDetail]);

  return {
    errors,
    control,
    handleSubmit,
    onSubmit,
    methods,
  };
}
