import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreateClientMutation,
  useGetAllClientsMutation,
} from "../../services/clients";
import { useState, useEffect, useMemo } from "react";
// import useStateCitiesList from "../common/spa-hooks/useStateCitiesList";
// import useStateList from "../common/spa-hooks/useStateList";
import { validationSchema } from "./validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { setImageUrl } from "../../store/features/upload-file";
import useCountryList from "../common/spa-hooks/useCountryList";
import { setClientDetails } from "../../store/features/client-details";
import { toast } from "react-toastify";
import { handleFilterSearch } from "../../utils/searchFilter";
import { convertDate } from "../../utils/convertTimeIOSFormat";

const useCreateClient = (
  setDialogOpen,
  setAlertOpen,
  setClientFullName,
  setDialogExistClient,
  getAllClients
) => {
  const [friendReferralArray, setFriendReferralArray] = useState([]);
  const [searchFriend, setSearchFriend] = useState("");
  const [friendFieldEnable, setFriendFieldEnable] = useState(false);
  const dispatch = useDispatch();

  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const imageUrl = useSelector((state) => state?.uploadFile?.url);

  const [getClients, { data: clientsData }] = useGetAllClientsMutation();
  const { referralSource } = watch();
  const { countries: countriesArray } = useCountryList();
  const [createClientTrigger] = useCreateClientMutation();

  // using if need state and city from api
  // const { states: statesArray } = useStateList(country);
  // const { stateCities: stateCityArray } = useStateCitiesList(country, state);

  const handleCloseClientDialog = () => {
    setDialogOpen(false);
    reset();
  };

  const onSubmit = async (values) => {
    setClientFullName(`${values.firstName} ${values.lastName}`);
    const { newsLetter, promotionEmail, promotionSMS } = values;
    const promotion =
      promotionEmail && promotionSMS
        ? ["EMAIL"]
        : promotionEmail
        ? ["EMAIL"]
        : promotionSMS
        ? ["SMS"]
        : "";

    // const countryName =
    //   values.state !== "" && values.country === ""
    //     ? "Australia"
    //     : values.country === ""
    //     ? undefined
    //     : values.country;

    let payLoad = {
      firstName: values.firstName && values.firstName,
      lastName: values.lastName && values.lastName,

      referralSource:
        values.referralSource === "" ? undefined : values.referralSource,
      friend: values.friend,
      gender: values.gender === "" ? undefined : values.gender,
      mobileNumber: values.mobileNumber && values.mobileNumber,
      dateOfBirth:
        values.dateOfBirth == null
          ? undefined
          : convertDate(values.dateOfBirth),
      email: values.email && values.email,
      password: values?.password,
      occupation: values.occupation && values.occupation,
      company: values.company && values.company,
      profilePictureUrl: imageUrl ? imageUrl : "",
      roleType: "CLIENT",
      note: values?.note
        ? { note: values?.note, isPinned: values.isPinned, inCalendar: false }
        : undefined,
      addresses: [
        {
          postalCode: Number(values.postalCode),
          // country: countryName,
          suburb: values?.suburb,
          state: values.state === "" ? undefined : values.state,
          street: values.street && values.street,
        },
      ],
    };

    if (promotion.length > 0 && newsLetter) {
      payLoad = { ...payLoad, promotions: promotion, newsLetter: "EMAIL" };
    } else if (promotion.length > 0) {
      payLoad = { ...payLoad, promotions: promotion };
    } else if (newsLetter) {
      payLoad = { ...payLoad, newsLetter: "EMAIL" };
    }

    await createClientTrigger(payLoad)
      .unwrap()
      .then((resp) => {
        if (resp.status) {
          if (resp?.message.includes("already exists")) {
            setDialogExistClient(true);
            dispatch(setClientDetails(resp.result.data));
          } else {
            toast.success(resp?.message);
            dispatch(setClientDetails(resp.result.data));
            setAlertOpen(true);
          }
          reset();
          setDialogOpen(false);
          getAllClients({ roleType: "CLIENT" });
        } else {
          if (resp?.message.includes("duplicate"))
            toast.error(
              "Please change your Contact number as it is saved in our system"
            );
        }
      })
      .catch((error) => {
        let errorString = "";
        if (error.data?.error?.errors && error.data?.error?.errors.length > 0) {
          errorString = error.data.error.errors
            .map((item) => {
              return `Invalid ${item.param} Value`;
            })
            .join(""); // Join the array into a single string
        }
        toast.error(errorString);
      });
  };

  const onChange = (event) => {
    setSearchFriend(event.target.value);
  };

  useMemo(() => {
    if (clientsData) {
      const friendFilterArray = clientsData?.result?.data.data.map(
        (selectedItem) => ({
          label: `${selectedItem?.firstName} ${selectedItem?.lastName}`,
          value: `${selectedItem?.firstName} ${selectedItem?.lastName}`,
        })
      );
      setFriendReferralArray(friendFilterArray);
    }
  }, [clientsData]);

  useEffect(() => {
    if (referralSource === "FRIEND") {
      setFriendFieldEnable(true);
    } else setFriendFieldEnable(false);
  }, [referralSource]);

  useEffect(() => {
    if (searchFriend) {
      const payload = handleFilterSearch(
        searchFriend,
        ["firstName", "lastName", "email"],
        "search"
      );
      getClients({ payload, roleType: "CLIENT" });
    } else getClients({ roleType: "CLIENT" });
  }, [searchFriend]);

  useEffect(() => {
    dispatch(setImageUrl({ url: "" }));
    const friendFilterArray = clientsData?.result?.data?.data?.map(
      (selectedItem) => ({
        label: `${selectedItem?.firstName} ${selectedItem?.lastName}`,
        value: `${selectedItem?.firstName} ${selectedItem?.lastName}`,
      })
    );
    setFriendReferralArray(friendFilterArray);
    getClients({ roleType: "CLIENT" });
  }, []);

  // useEffect(() => {
  //   // setValue("city", "");
  //   setValue("state", "");
  // }, [country]);

  return {
    handleSubmit,
    control,
    onSubmit,
    handleCloseClientDialog,
    friendReferralArray,
    countriesArray,
    onChange,
    friendFieldEnable,
  };
};

export default useCreateClient;
