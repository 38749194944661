export const profileImgStyle = {
  width: "100%",
  height: "100%",
  objectFit: "fill",
  borderRadius: "50%",
};
export const uploadBtn = (theme, profileUpload) => ({
  backgroundColor: theme.palette.primaryColor.light,
  color: theme.palette.primaryColor.main,
  paddingInline: profileUpload ? "0px" : "24px",
  paddingBlock: profileUpload ? "5px" : "12px",
  borderRadius: "8px",
  fontSize: profileUpload && "10px",
  fontWeight: profileUpload ? 400 : 500,
  "&:hover": {
    backgroundColor: theme.palette.primaryColor.lightHover,
  },
});

export const uploadImgWrapperStyle = (profileUpload) => ({
  marginTop: profileUpload ? "5px" : "24px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});

export const title = (theme) => ({
  fontWeight: 500,
  color: theme.palette.textPrimary900.main,
});
