import { baseAPI } from "../baseApi";

export const PackageApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getPackages: builder.mutation({
      query: (body) => ({ url: "/package", method: "PATCH", body }),
      providesTags: ["Package"],
    }),
    createPackage: builder.mutation({
      query: (payload) => ({
        url: "/package",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Package"],
    }),
    getSinglePackage: builder.query({
      query: (packageId) => ({
        url: "/package/" + packageId,
      }),
      providesTags: ["singlePackage"],
    }),
    updateSinglePackage: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: "/package/" + id,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["Package"],
    }),
  }),
});

export const {
  useGetSinglePackageQuery,
  useCreatePackageMutation,
  useUpdateSinglePackageMutation,
  useGetPackagesMutation,
} = PackageApi;
