import { Box, Button, Grid, Typography } from "@mui/material";
import { MapPin } from "@phosphor-icons/react";
import React from "react";
import { fs16, fw600 } from "../../../../../../theme";
import theme from "../../../../../../theme/theme";
import FormProvider from "../../../../../hook-form/FormProvider";
import { SubmitBtn } from "../../../../../new-appointment-form/new-appointment-style";
import { addressFormData } from "../../treatment-profile-data";
import useStaffOverview from "./useServiceOverview";
export default function Address({ singleStaffData, singleStaffRefetching }) {
  const {
    control,
    handleSubmit,
    onSubmit,
    methods,
    staffDetailApi,
    rolesData,
  } = useStaffOverview({ singleStaffData, singleStaffRefetching });

  const addressDataFields = addressFormData();

  return (
    <>
      <Grid container gap={3} sx={{ height: "100%" }}>
        {/* // user information */}

        <Grid
          xs={12}
          md={12}
          style={{
            padding: "16px",
            borderRadius: "8px",
          }}
          bgcolor={theme.palette.purple10.main}
          container
        >
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: "100%" }}
          >
            <Box display={"flex"} gap={1} mb={2}>
              <MapPin size={24} />
              <Typography fontSize={fs16} fontWeight={fw600}>
                Address
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
              // display="flex"
              // justifyContent="space-between"
              // alignItems="center"
              sx={{ width: "100%" }}
            >
              {addressDataFields.map((item) => (
                <>
                  <Grid item xs={12} md={item.gridLength}>
                    <item.component {...item.otherOptions} control={control} />
                  </Grid>
                </>
              ))}
            </Grid>
            <Box sx={{ mt: "20px" }}>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button sx={SubmitBtn} type={"submit"}>
                  <Typography>Save Changes</Typography>
                </Button>
              </Grid>
            </Box>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
}
