import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import React from "react";
import SPADialogue from "../../../common/spa-dialogue/index.jsx";
import { createProductTypeFormData } from "./new-role-form-data.jsx";
import useCreateProductType from "./useCreateProductType.js";

export default function NewRoleForm({
  isNewRoleOpen,
  handleClose,
  singleRoleId,
  setIsOpenSuccessDialog,
  getAllProductType,
  setSingleRoleId,
}) {
  const {
    control,
    methods,
    handleSubmit,
    onSubmit,
    handleCloseForm,
    createProductTypeLoader,
    createRoleLoader,
    updateRoleLoader,
  } = useCreateProductType(
    handleClose,
    singleRoleId,
    setIsOpenSuccessDialog,
    getAllProductType,
    setSingleRoleId
  );

  return (
    <>
      {createProductTypeLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={createProductTypeLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!createProductTypeLoader && (
        <SPADialogue
          open={isNewRoleOpen}
          handleClose={handleCloseForm}
          dialogTitle={
            singleRoleId ? "Edit Product Type" : `Create Product Type`
          }
          cancelText="Cancel"
          submitText={
            singleRoleId ? "Save Changes" : "Create Product Type"
          }
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          methods={methods}
          loading={createRoleLoader || updateRoleLoader}
        >
          <Box
            sx={{
              width: "688px",
              display: "flex",
              flexDirection: "column",
              gap: "0px",
            }}
          >
            <Grid container spacing={1}>
              {createProductTypeFormData().map((item, index) => {
                return (
                  <Grid item lg={item.gridLength} xs={12} key={index}>
                    <item.component {...item.otherOptions} control={control} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </SPADialogue>
      )}
    </>
  );
}
